import React from "react";
import { Box } from "@mui/material";

interface SpeedRangeProps {
  best: string;
  worst: string;
}

/**
 * Shows speed range for products that have FTTC style responses
 * @constructor
 */
export const SpeedRange = ({ best, worst }: SpeedRangeProps) => {
  return (
    <Box>
      <Box sx={styles.range}>
        <Box sx={{ ...styles.chip, ...styles.best }}>Best</Box>
        {best} Mbps
      </Box>
      <Box>
        <Box sx={{ ...styles.chip, ...styles.worst }}>Worst</Box>
        {worst} Mbps
      </Box>
    </Box>
  );
};

const styles = {
  range: {
    marginBottom: "2px",
  },
  chip: {
    display: "inline-block",
    padding: "1px 4px",
    borderRadius: "4px",
    marginRight: 0.5,
    fontSize: ".8em",
    fontWeight: "bold",
    textAlign: "center",
    minWidth: "45px",
  },
  best: {
    backgroundColor: "success.main",
    color: "success.contrastText",
  },
  worst: {
    backgroundColor: "error.main",
    color: "error.contrastText",
  },
};
