import React, { ReactNode } from "react";
import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { HostEnv } from "../../../Cpq";
import { ActionButton } from "./ActionButton";
import { CheckCircleAnimated } from "./CheckCircleAnimated";

interface CompleteMessageProps {
  hostEnv: HostEnv;
  provisioningBundleId: any;
  orderId: string;
  uploadedContractId: any;
  pendingContractId: any;
  baseUrl: string;
  accountId: string;
  approvalRequired: any;
  approvalSubmitted: boolean;
  orderContact: {
    full_name: string;
  };
  platformButton: ReactNode;
  canAccessVfDirect: boolean;
  customerName: string;
}

export const CompleteMessage: React.FC<CompleteMessageProps> = ({
  provisioningBundleId,
  hostEnv,
  baseUrl,
  accountId,
  approvalRequired,
  approvalSubmitted,
  orderId,
  uploadedContractId,
  pendingContractId,
  orderContact,
  platformButton,
  canAccessVfDirect,
  customerName,
}: CompleteMessageProps) => {
  const classes = useStyles();

  // VF Direct has different logic around the complete message. TP59352

  if (canAccessVfDirect) {
    if (!approvalRequired) {
      return (
        <div className={classes.root} data-cy="CompleteMessage">
          <CheckCircleAnimated />
          <div className={classes.message}>
            <Typography variant="h3">Thank you for order {orderId}.</Typography>
            {uploadedContractId ? (
              <Typography variant="subtitle1">
                The contract has been successfully uploaded. <br />
                Your order for <b>{customerName}</b> will be provisioned.
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                The contract has been successfully sent to{" "}
                <b>{orderContact?.full_name}</b>.
                <br />
                Your order for <b>{customerName}</b> will be provisioned once
                the contract has been signed by the customer.
              </Typography>
            )}
            {platformButton}
          </div>
        </div>
      );
    }

    if (approvalRequired) {
      return (
        <div
          className={`${classes.root} ${classes.amber}`}
          data-cy="CompleteMessage"
        >
          <CheckCircleAnimated />
          <div className={classes.message}>
            <Typography variant="h3">Thank you for order {orderId}.</Typography>
            {uploadedContractId ? (
              <Typography variant="subtitle1">
                The contract has been successfully uploaded and your order has
                been submitted to Giacom for approval.
                <br />
                Your order for <b>{customerName}</b> will be provisioned once
                the order has been approved.
              </Typography>
            ) : (
              <Typography variant="subtitle1">
                The order has been submitted to Giacom for approval. <br />
                Your order for <b>{customerName}</b> will be provisioned once
                the order has been approved and the contract signed by the
                customer.
              </Typography>
            )}
          </div>
        </div>
      );
    }
  }

  // All other scenarios...

  return (
    <div className={classes.root} data-cy="CompleteMessage">
      <CheckCircleAnimated />
      <div className={classes.message}>
        <Typography variant="h3">
          Congratulations! Your {provisioningBundleId ? "order" : "sale"} is
          complete.
        </Typography>
        {pendingContractId && (
          <Typography variant="subtitle1">
            Contract successfully sent to <b>{orderContact?.full_name}</b> for
            Sales ID {orderId}. <br />
          </Typography>
        )}
        {uploadedContractId && (
          <Typography variant="subtitle1">
            Contract successfully uploaded for Sales ID {orderId}. <br />
          </Typography>
        )}
        {provisioningBundleId && (
          <Typography variant="subtitle1">
            Provisioning successful. ID {orderId}. <br />
            Bundle ID: {provisioningBundleId}
          </Typography>
        )}
        {approvalSubmitted && (
          <Typography variant="subtitle1">
            Order successful. ID {orderId}. <br />
            Your order / request has been submitted for approval.
          </Typography>
        )}
        {hostEnv === "platform" && platformButton}
        {hostEnv === "dc" && (
          <ActionButton
            variant="contained"
            href={`${baseUrl}CustomerService/AccountOverview/${accountId}`}
          >
            Go to Account Overview
          </ActionButton>
        )}
        {hostEnv === "my_account" && (
          <ActionButton variant="contained" onClick={() => window.close()}>
            Close window and return to My Account
          </ActionButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.success.main,
    borderRadius: 4,
    padding: "32px 16px",
    marginTop: 16,
    color: "white",
    transform: "scale(1)",
  },
  amber: {
    background: theme.palette.warning.main,
  },

  message: {
    marginLeft: 16,
  },
}));
