import React from "react";

import { Button, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import {
  ReduxWlrLocation,
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../../types";
import { WlrLocationNewLineBroadbandCellModalProductTable } from "../../../../../components/WlrLocation/NewLine/BroadbandCell/Modal/ProductTable";
import { BroadbandTotalPrice } from "../../../../../components/WlrLocation/NewLine/BroadbandCell/Modal/BroadbandTotalPrice";

export interface WlrLocationNewLineBroadbandCellModalProps {
  open: boolean;
  locationIndex: number;
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  productConfigurations: WlrLocationProductConfig[];
  contractLength: number;
  totalPrice: string;
  handleClose: () => void;
}

export const WlrLocationNewLineBroadbandCellModal = ({
  open,
  locationIndex,
  location,
  product,
  contractLength,
  productConfigurations,
  totalPrice,
  handleClose,
}: WlrLocationNewLineBroadbandCellModalProps) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
    >
      <DialogTitle>{`Add Broadband to ${productConfigurations.length} line(s)`}</DialogTitle>
      <DialogContent>
        <WlrLocationNewLineBroadbandCellModalProductTable
          locationIndex={locationIndex}
          location={location}
          product={product}
          configurations={productConfigurations}
          contractLength={contractLength}
        />
        <BroadbandTotalPrice totalPrice={totalPrice} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Save & Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    maxWidth: "75vw",
    width: "75vw",
    maxHeight: "650px",
    height: "80vh",
  },
  actions: {
    padding: theme.spacing(1.5),
    paddingTop: 0,
  },
}));
