import React from "react";
import { useSelector } from "react-redux";

import {
  getIsMPFLine,
  makeGetTargetConfigs,
} from "../../../../../store/wlrBroadband/selectors";
import { getSelectedProductData } from "../../../../../store/wlrBroadband/actions/configurations";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import { SelectedConfigs } from "../../../components/wlrBroadband/SelectedConfigs";
import { useCanAccessCommission } from "../../../../../shared/hooks/useAccountSettings";

export const SelectedConfigsContainer = () => {
  const { targetConfigs } = useTargetConfigsContext();

  const configs = useSelector((state: any) =>
    // @ts-ignore
    makeGetTargetConfigs()(state, { targetConfigs })
  );

  const standardConfigs = configs.filter(
    (config: any) => !config.productInstanceId
  );

  const resignConfigs = configs.filter(
    (config: any) => config.productInstanceId
  );

  const showCommissionType = useCanAccessCommission("wlr");

  return (
    <SelectedConfigs
      standardConfigs={standardConfigs}
      resignConfigs={resignConfigs}
      getIsMPFLine={(config) => getIsMPFLine(config)}
      getSelectedProductData={getSelectedProductData}
      showCommissionType={showCommissionType}
    />
  );
};
