import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleSogeaTermsConfirmation } from "../../../../store/wlrBroadband/actions/locations";
import { SogeaTerms } from "../components/SogeaTerms";

export interface SogeaTermsInterface {
  locationIndex: number;
}

export const SogeaTermsContainer = ({ locationIndex }: SogeaTermsInterface) => {
  const dispatch = useDispatch();
  const sogeaTermsAccepted = useSelector<any, boolean>(
    (state) => state.wlrBroadband.locations[locationIndex].sogeaTermsAccepted
  );

  const handleChange = () =>
    dispatch(toggleSogeaTermsConfirmation(locationIndex));

  return (
    <SogeaTerms
      sogeaTermsAccepted={sogeaTermsAccepted}
      handleChange={handleChange}
    />
  );
};
