export const boltOnFields = [
  {
    id: "data",
    label: "Data",
  },
  {
    id: "voice",
    label: "Voice",
  },
  {
    id: "additional_bundle_1",
    label: "International",
  },
  {
    id: "text",
    label: "Text",
  },
];
