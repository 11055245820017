import React from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Alert } from "@akj-dev/shared-components";
import HelpIcon from "@mui/icons-material/Help";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { getDynamicPropertyValue } from "../../../../store/wlrBroadband/selectors";

export const VoiceService = ({ targetConfigs }: any) => {
  const voiceServiceSelected = useSelector((state) =>
    getDynamicPropertyValue(
      state,
      "broadband",
      targetConfigs[0],
      "bb.voice_service"
    )
  );
  return (
    <Section title="Voice Service" avatar={<RecordVoiceOverIcon />}>
      <Box display="flex" alignItems="center">
        <p>Do you require a voice service?</p>
        <Tooltip
          title={"Does the customer require to keep the phone number"}
          placement="right"
        >
          <IconButton aria-label="voice tooltip" size="small">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.voice_service"
        required={true}
      />
      {voiceServiceSelected === "1" && (
        <Alert
          type="info"
          message="Please make sure you submit a manual voice order for this customer, so
        they do not lose service or the phone number."
        />
      )}
    </Section>
  );
};
