import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { CheckIcon } from "@akj-dev/shared-components";
import ErrorIcon from "@mui/icons-material/ErrorOutline";

interface StatusLargeProps {
  type: "success" | "error" | "success" | "info";
  title?: string;
  message?: string;
}

export function StatusLarge({ type, title, message }: StatusLargeProps) {
  return (
    <Outer>
      <EnlargeIcon>
        {type === "success" && (
          <CheckIcon style={{ width: 100, height: 100 }} />
        )}
        {type === "error" && (
          <ErrorIcon style={{ width: 100, height: 100 }} color="error" />
        )}
      </EnlargeIcon>
      {title && <h4>{title}</h4>}
      {message && <p>{message}</p>}
    </Outer>
  );
}

StatusLarge.propTypes = {
  type: PropTypes.oneOf(["loading", "error", "success", "info"]).isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
};

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
`;

const EnlargeIcon = styled.div`
  animation: ${fadeIn} 0.5s linear;
`;

const Outer = styled.div`
  text-align: center;
`;
