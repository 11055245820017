import React, { ReactElement, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HardwareDeliverySplitRow } from "../../components/Hardware/DeliverySplitRow";
import { Table, TableBody } from "@mui/material";
import {
  setDeliveryAddressId,
  setDeliveryAddressType,
} from "../../../../store/mobile/actions";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";

interface SplitDeliveryMobileSIMSplitListProps {
  productId: string;
  productName: string;
}

export const SplitDeliveryMobileSIMSplitList = ({
  productId,
  productName,
}: SplitDeliveryMobileSIMSplitListProps) => {
  const dispatch = useDispatch();
  const configs = useSelector<any, Record<string, any>[]>(
    (state) => state.mobile.configs
  );

  const configIndexes = useMemo(
    () =>
      configs
        .map((c: Record<string, any>, i: number) =>
          // eslint-disable-next-line eqeqeq
          c.productId === productId && c.properties.is_sim_required == 1
            ? i
            : ""
        )
        .filter(String) as number[],
    [configs, productId]
  );

  return (
    <Table>
      <TableBody>
        {configIndexes.map((index) => (
          <HardwareDeliverySplitRow
            addressType={configs[index].delivery.addressType}
            addressId={configs[index].delivery.addressId}
            handleChangeAddressType={(event) => {
              dispatch(setDeliveryAddressType(event.target.value, [index]));
              dispatch(setDeliveryAddressId("", [index]));
            }}
            handleClickExistingAddressSelect={() => {
              dispatch(setDeliveryAddressType(addressTypes.EXISTING, [index]));
            }}
            handleSelectAccountAddress={(_, child) => {
              dispatch(
                setDeliveryAddressId((child as ReactElement).props.value, [
                  index,
                ])
              );
            }}
          />
        ))}
      </TableBody>
    </Table>
  );
};
