import { StatusChip } from "@akj-dev/shared-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  SlideProps,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { usePrevious } from "../hooks/usePrevious";
import React, { useEffect, useState } from "react";
import { DeleteDraftForm } from "./DeleteDraftForm";
import { Cancel as CancelOrder } from "../../../api/v1/orders";
import { CancelOrderReason } from "./types";

interface Props {
  open: boolean;
  onClose: (reason: "cancelled" | "success") => void;
  orderId: string;
  accountId?: string;
  leadId?: string;
}

export const DeleteDraftModal = ({
  open,
  onClose,
  orderId,
  accountId,
  leadId,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // reset state when opened (avoids reset being visible during transition)
  const prevOpen = usePrevious(open);
  useEffect(() => {
    if (!prevOpen && open) {
      setLoading(false);
      setError("");
    }
  }, [open, prevOpen, setLoading, setError]);

  const handleCancel = () => {
    if (loading) {
      return;
    }
    onClose("cancelled");
  };

  const handleSubmit = async (reasons: CancelOrderReason) => {
    setLoading(true);
    let account;
    if (leadId) {
      account = {
        leadId: leadId as string,
      };
    } else if (accountId) {
      account = {
        accountId: accountId as string,
      };
    }

    try {
      const response = await CancelOrder({ orderId, ...reasons, ...account });
      if (response.status === "error") {
        throw new Error(response.message);
      }
      onClose("success");
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("Something went wrong");
      }
      setLoading(false);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={handleCancel}
      aria-labelledby="delete-draft-order"
    >
      {error ? (
        <Box padding={2}>
          <StatusChip type="error" message={error} />
        </Box>
      ) : loading ? (
        <DialogContent sx={styles.dialogContent}>
          <Loading />
        </DialogContent>
      ) : (
        <>
          <DialogTitle id="delete-draft-order">
            Please tell us the reasons
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <DeleteDraftForm onCancel={handleCancel} onSubmit={handleSubmit} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const Loading = () => (
  <Box
    marginTop={2}
    marginBottom={3}
    display="flex"
    alignItems="center"
    flexDirection="column"
  >
    <CircularProgress />
    <Box marginTop={1}>
      <Typography variant="h3">Progressing, please wait</Typography>
    </Box>
  </Box>
);

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  dialogContent: {
    minWidth: 400,
  },
};
