import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

interface EmailNotificationProps {
  hasContacts: boolean;
  useEBillingAddress: () => void;
}

export const EmailNotification = ({
  hasContacts,
  useEBillingAddress,
}: EmailNotificationProps) => (
  <div>
    <Button
      variant="contained"
      disabled={!hasContacts}
      onClick={useEBillingAddress}
    >
      Use e-billing address
    </Button>
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <EmailIcon />
      </Box>
      <BulkDynamicField propertyName="notification_contact" />
    </Box>
  </div>
);
