import { constants } from "../../../helpers";

export interface Contact {
  id: number;
  email: string;
  name: string;
}

export type NotificationType = typeof constants.notificationTypes[number];

export interface Notification {
  contact: Contact;
  fields: Array<Trigger>;
  id: number;
  is_active: number;
  type: NotificationType;
}

export interface Query {
  account_id?: string | null;
  name?: string | null;
  email?: string | null;
  type?: NotificationType;
  values?: Array<TriggerValue>;
  only_active?: number | null;
  sort?: string;
  order: typeof constants.sortDirections[number];
  limit?: number;
  page: number;
}

const triggerValues = constants.triggers.map((trigger) => trigger.value);

export type TriggerValue = typeof triggerValues[number];

export interface Trigger {
  id: number;
  types?: Array<string>;
  value: TriggerValue;
}

export const NotificationTypeQueryParam = {
  encode: (value: NotificationType) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as NotificationType,
};

export type SortDirection = typeof constants.sortDirections[number];

export const SortDirectionQueryParam = {
  encode: (value: SortDirection) => value,
  decode: (value: string | (string | null)[] | null | undefined) =>
    value as SortDirection,
};

export const TriggerValueQueryParam = {
  encode: (value: TriggerValue) => value,
  decode: (input: string | (string | null)[] | null | undefined) =>
    (input instanceof Array
      ? input
      : input === "" || input === undefined
      ? undefined
      : [input]) as TriggerValue[],
};
