import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import {
  makeGetDynamicPropertyInstances,
  makeGetDynamicPropertyValues,
  makeGetHasProductDataError,
  makeGetInitialWlrChangeValues,
} from "../../../../../store/wlrBroadband/selectors";
import {
  doRemoteValidation,
  fetchProductData,
  setProductProperty,
  validateProductProperty,
} from "../../../../../store/wlrBroadband/actions/configurations";
import Select from "../../../components/wlrBroadband/WlrBBDynamicField/Select";
import Text from "../../../components/wlrBroadband/WlrBBDynamicField/Text";
import Boolean from "../../../components/wlrBroadband/WlrBBDynamicField/Boolean";
import PseudoBoolean from "../../../components/wlrBroadband/WlrBBDynamicField/PseudoBoolean";
import DateField from "../../../components/wlrBroadband/WlrBBDynamicField/DateField";
import MultiValueWarning from "../../../components/wlrBroadband/WlrBBDynamicField/MultiValueWarning";
import Number from "../../../components/wlrBroadband/WlrBBDynamicField/Number";
import { StatusChip } from "@akj-dev/shared-components";

interface FieldDataWrapperOwnProps {
  productType: "wlr" | "broadband";
  propertyName: string;
  isRootProperty?: boolean;
  targetConfigs: number[];
  disabled?: boolean;
  required?: boolean;
  margin?: "normal" | "none" | "dense";
  minDate?: string;
  maxDate?: string;
  shouldDisableDate?: boolean;
}

interface FieldDataWrapperDispatchProps {
  setProductProperty: (
    targetConfigs: number[],
    productType: "wlr" | "broadband",
    propertyName: string,
    value?: any
  ) => void;
  fetchProductData: ({
    configurationIndex,
    productType,
  }: {
    configurationIndex: number;
    productType: "wlr" | "broadband";
  }) => void;
  doRemoteValidation: (params: any) => void;
  validateProductProperty: (
    targetConfigs: number[],
    productType: "wlr" | "broadband",
    propertyName: string,
    value?: any
  ) => void;
}

interface FieldDataWrapperStateProps {
  hasProductDataError: boolean;
  propertyValidation: Record<string, any>;
  initialWlrChangeValues: Record<string, any>[];
  propertyValues: Record<string, any>[];
  dynamicPropertyInstances: Record<string, any>[];
}

type FieldDataWrapperProps = FieldDataWrapperStateProps &
  FieldDataWrapperDispatchProps &
  FieldDataWrapperOwnProps;

interface FieldDataWrapperState {
  showMultipleValueWarning: boolean;
}

class FieldDataWrapper extends Component<
  FieldDataWrapperOwnProps &
    FieldDataWrapperStateProps &
    FieldDataWrapperDispatchProps,
  FieldDataWrapperState
> {
  // NOTE: This is similar to src/js/containers/StepCustomise/Mobile/ConfigurationForm/BulkDynamicField.js
  // Mobile order flow is way different to wlr+bb, by necessity, hence the duplication.

  static propTypes = {
    productType: PropTypes.oneOf(["wlr", "broadband"]),
    propertyName: PropTypes.string.isRequired,
    isRootProperty: PropTypes.bool, // Only required for call_tariff_id which doesn't sit on the component dynamic props. Selector usage
    targetConfigs: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
  };

  state = {
    showMultipleValueWarning: true,
  };

  hideMultipleValueWarning = () => {
    this.setState({ showMultipleValueWarning: false });
  };

  shouldComponentUpdate(
    nextProps: FieldDataWrapperProps,
    nextState: FieldDataWrapperState
  ) {
    // Stops weird form things happening where dynamic fields don't update
    // properly if there is a product data error. TP29811
    if (nextProps.hasProductDataError) return true;

    // Local state change should always re-render, obviously.
    if (nextState !== this.state) return true;
    // This stops a re-render of every field every time any product property is altered in the store.
    // Quick fix for something that should be soled by better state shape probably.
    if (
      _.isEqual(this.props.propertyValues, nextProps.propertyValues) &&
      this.props.propertyValidation === nextProps.propertyValidation
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const {
      propertyName,
      targetConfigs,
      productType,
      disabled,
      dynamicPropertyInstances,
      propertyValues,
      propertyValidation,
      initialWlrChangeValues,
      setProductProperty,
      fetchProductData,
      doRemoteValidation,
      validateProductProperty,
      margin,
      minDate,
      maxDate,
      shouldDisableDate,
    } = this.props;

    // Check the property actually exists in the selected configs.
    // There are some that won't in cases like MPF installs that require less address fields than BB+WLR.
    if (dynamicPropertyInstances.length < 1) return false;

    // Check for non-equal available options (select fields only)
    // This makes the field entirely uneditable in bulk.
    let optionsEqual = true;
    dynamicPropertyInstances.forEach((p: any) => {
      if (optionsEqual) {
        optionsEqual = _.isEqual(
          _.get(dynamicPropertyInstances[0], "available_options"),
          _.get(p, "available_options")
        );
      }
    });
    if (!optionsEqual)
      return (
        <StatusChip
          type="info"
          title="Available options cannot be bulk edited."
          message="Please choose them per line."
        />
      );

    // Check for non-equal property values (all field types)
    let valuesEqual = true;
    propertyValues.forEach((p: any) => {
      if (valuesEqual) {
        valuesEqual = propertyValues[0] === p;
      }
    });

    const dynamicProperty = dynamicPropertyInstances[0];

    if (!valuesEqual && this.state.showMultipleValueWarning)
      return (
        <MultiValueWarning
          onClick={this.hideMultipleValueWarning}
          dynamicProperty={dynamicProperty}
        />
      );

    // If everything's ok (editing single config, uniform values, or after waring clicked off),
    // build properties for rendering the field.

    const isWlrChangeField = dynamicProperty.name.includes("wlr_change");

    const fieldProps = {
      dynamicProperty,
      propertyValidation,
      disabled,
      margin,
      minDate,
      maxDate,
      shouldDisableDate,

      propertyValue: valuesEqual
        ? propertyValues[0] !== undefined
          ? propertyValues[0]
          : isWlrChangeField
          ? initialWlrChangeValues[0] // TODO: Don't need full array?
          : dynamicPropertyInstances[0].current_value ||
            dynamicPropertyInstances[0].default_value
        : null,

      // If this property is a "WLR Change" one and been changed by the user, show indicator.
      showWlrChangeIcon: isWlrChangeField && propertyValues[0] !== undefined,

      setProperty: (value: any) => {
        // If changing the site visit reason (only applicable to SoGEA), reset the selected router as available
        // router options are dependant on this.
        if (propertyName === "bb.site_visit_reason")
          setProductProperty(targetConfigs, "broadband", "router_id", null);
        setProductProperty(targetConfigs, productType, propertyName, value);
      },
      recalculatePrices: () => {
        // Looks like 9/10 times any dynamic property that has an available_option_details node influences price.
        // These nodes said contain pricing data too (used in field display components below).
        // TODO: Pretty sue these can only be on select fields, in which case we can simplify. Check.
        if (
          dynamicPropertyInstances[0].available_option_details ||
          dynamicPropertyInstances[0].name === "number_of_channels"
        ) {
          targetConfigs.forEach((t) => {
            fetchProductData({ configurationIndex: t, productType });
          });
        }
      },

      validateConfig: () => {
        validateProductProperty(
          targetConfigs,
          productType,
          propertyName,
          dynamicProperty
        );
        targetConfigs.forEach((t) => {
          doRemoteValidation({ configurationIndex: t });
        });
      },
    };

    // Render the appropriate component for field type
    if (dynamicProperty.is_text) return <Text {...fieldProps} />;
    if (dynamicProperty.is_boolean) return <Boolean {...fieldProps} />;
    // Some fields (MPF Voice opts) are is_select with boolean options.
    // Probably because they have a price associated with them, which Select can describe, unlike normal is_boolean
    if (_.isEqual(dynamicProperty.available_options, ["TRUE", "FALSE"]))
      return <PseudoBoolean {...fieldProps} />;
    if (dynamicProperty.is_select)
      return <Select required={this.props.required} {...fieldProps} />;
    if (dynamicProperty.is_date) return <DateField {...fieldProps} />;
    if (dynamicProperty.is_integer) return <Number {...fieldProps} />;
    if (dynamicProperty.is_decimal) return <Number {...fieldProps} />;

    return (
      <div>
        Unhandled property:{" "}
        <pre>{JSON.stringify(dynamicPropertyInstances, null, 2)}</pre>
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getDynamicPropertyInstances = makeGetDynamicPropertyInstances();
  const getDynamicPropertyValues = makeGetDynamicPropertyValues();
  const getInitialWlrChangeValues = makeGetInitialWlrChangeValues();
  const getHasProductDataError = makeGetHasProductDataError();

  return (state: any, ownProps: FieldDataWrapperOwnProps) => ({
    //@ts-ignore
    dynamicPropertyInstances: getDynamicPropertyInstances(state, ownProps),
    //@ts-ignore
    propertyValues: getDynamicPropertyValues(state, ownProps),
    //@ts-ignore
    initialWlrChangeValues: getInitialWlrChangeValues(state, ownProps),
    propertyValidation:
      state.wlrBroadband.configurations[ownProps.targetConfigs[0]].validation[
        ownProps.propertyName
      ],
    //@ts-ignore
    hasProductDataError: getHasProductDataError(state, ownProps),
  });
};

export default connect<
  FieldDataWrapperStateProps,
  FieldDataWrapperDispatchProps,
  FieldDataWrapperOwnProps
>(makeMapStateToProps, {
  setProductProperty,
  fetchProductData,
  doRemoteValidation,
  validateProductProperty,
  // @ts-ignore
})(FieldDataWrapper);
