import { addressTypes } from "./constants";
import { DCContactAddress } from "../../api/v1/account";

/**
 * Get the hardware delivery address parameters for an OrderProduct call
 * based on user selection in delivery details page.
 * @returns {*}
 */
export function getDeliveryParams(state: any, row: any) {
  const split = state.hardwareDelivery.splitDelivery;
  const addressType = split
    ? row?.addressType
    : state.hardwareDelivery.addressType;
  const addressId = split ? row?.addressId : state.hardwareDelivery.addressId;

  switch (addressType) {
    case addressTypes.BILLING:
      return {
        use_billing_address: 1,
      };

    case addressTypes.EXISTING:
      const accountAddresses =
        state.account.accountAddresses.response.data.addresses;

      switch (typeof addressId) {
        case "string":
          // We have a DC contact UUID that we can pass direct to OrderProduct calls
          return {
            delivery_contact_id: addressId,
          };
        case "number":
          // We have a legacy account address array index that we need to copy over line by line
          return prefixAddress(accountAddresses[addressId]);
        default:
          // We have a problem
          console.error("No delivery address ID");
          return {};
      }

    default:
      console.error("No delivery address type");
      return {};
  }
}

/**
 * Add "site_address_" prefix to fields
 * @param address
 */
function prefixAddress(address: DCContactAddress) {
  let siteAddress: any = {};
  Object.entries(address).forEach(([key, value]) => {
    siteAddress[`site_address_${key}`] = value;
  });
  return siteAddress;
}
