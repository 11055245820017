import React from "react";
import { useSelector } from "react-redux";
import { getIsVfDirectCreditVetPassed } from "../../../store/account/selectors";
import { DeclinedCreditVetWarning } from "../components/CreditVetStatus/DeclinedCreditVetWarning";

export const DeclinedCreditVetWarningContainer = () => {
  const creditVetPassed = useSelector<any, boolean>((state) =>
    getIsVfDirectCreditVetPassed(state)
  );
  return <DeclinedCreditVetWarning creditVetPassed={creditVetPassed} />;
};
