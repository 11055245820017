import { QuoteState } from "./types";

const initialState: QuoteState = {
  quoteContactId: "",
  generateQuote: {
    fetching: false,
    response: {},
  },
};
export default initialState;
