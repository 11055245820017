import React, { useMemo, useState } from "react";

import {
  Box,
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

import { IndexedTitle } from "@akj-dev/shared-components";

import { VirtualTable } from "../../../../../../../shared/components/VirtualTable";
import { ActiveSort } from "../../../../../../../shared/components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../../../../shared/components/VirtualTable/enums";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState,
} from "../../../../../../../shared/components/VirtualTable/utils";
import {
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../types";
import { WlrLocationNewLineTableRenderRow } from "../../../../containers/WlrLocation/NewLine/Table/RenderRow";
import { ItemsSelected } from "../../../../../../../shared/components/ItemsSelected";

export const MAX_QUANTITY_BROADBAND = 50;

export interface WlrLocationNewLineTableProps {
  locationIndex: number;
  products: WlrLocationProduct[];
  configurations: WlrLocationProductConfig[];
}

export const WlrLocationNewLineTable = ({
  locationIndex,
  products,
  configurations,
}: WlrLocationNewLineTableProps) => {
  const classes = useStyles();
  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.DESC,
  });
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter,
  } = useDebouncedState();

  const locationConfigsCount = configurations.length;

  const filteredProducts = useMemo(() => {
    return products
      .filter(
        ({ name }) =>
          name.toLowerCase().indexOf(debouncedProductFilter.toLowerCase()) !==
          -1
      )
      .sort(sortVirtualTableData(activeSort));
  }, [products, debouncedProductFilter, activeSort]);

  const productsCount = products.length;

  const handleSort = useMemo(
    () => handleActiveSort(setActiveSort),
    [setActiveSort]
  );

  return (
    <>
      <Typography variant="h4" className={classes.title}>
        <IndexedTitle
          index="C"
          title="Choose a Product"
          withMargin={true}
          negative={true}
        />
      </Typography>
      <div className={classes.root}>
        <Box className={classes.searchBox}>
          <TextField
            data-cy="tableSearch"
            className={classes.search}
            variant="outlined"
            name="filter"
            value={productFilter}
            onChange={handleProductFilter}
            placeholder={`Search ${productsCount} Available Products`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {!!locationConfigsCount && (
            <ItemsSelected
              maxQuantity={MAX_QUANTITY_BROADBAND}
              configsCount={locationConfigsCount}
            />
          )}
        </Box>
        <VirtualTable
          withScrollButton={false}
          headers={tableHeaders}
          activeSort={activeSort}
          handleSort={handleSort}
          data={{
            locationIndex,
            products: filteredProducts,
          }}
          tableSize={500}
          itemSize={80}
          itemCount={filteredProducts.length}
          RenderTableRow={WlrLocationNewLineTableRenderRow}
        />
      </div>
    </>
  );
};

export const tableHeaders = [
  { label: "Product", sortIndex: "name", minWidth: 200 },
  {
    label: "Monthly Line Rental",
    sortIndex: "price.first_bill_recurring_price_with_promotions",
    width: 250,
  },
  { label: "Quantity", sortIndex: "qty", width: 250, disabled: true },
  { label: "Broadband", sortIndex: "bb", width: 250, disabled: true },
  { label: "Monthly Broadband", sortIndex: "mb", width: 250, disabled: true },
];

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginLeft: theme.spacing(2),
  },
  root: {
    width: "100%",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  searchBox: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "400px",
  },
}));
