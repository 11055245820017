import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Step,
  StepButton,
  Stepper as MuiStepper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ProductTypesEnum, SaleStepEnum } from "../../../shared/enums";
import {
  saleStepLabelObject,
  saleSubStepChooseLabelObject,
} from "../../../shared/constants";
import { isStepDisabled } from "../../../shared/utils/uiFunctions";

export interface StepperProps {
  activeStep?: SaleStepEnum;
  visited?: boolean[];
  steps?: SaleStepEnum[];
  activeSubStepChoose?: ProductTypesEnum;
  handleActiveSteps: (stepId: SaleStepEnum) => void;
  disableStepButton?: boolean;
}

export const Stepper = ({
  activeStep,
  activeSubStepChoose,
  steps,
  visited,
  handleActiveSteps,
  disableStepButton = false,
}: StepperProps) => {
  const classes = useStyles();
  const uiState = useSelector<any, any>((state) => state.uiState);

  return (
    <Box className={classes.root}>
      <MuiStepper className={classes.stepper} activeStep={1}>
        {steps?.map((stepId, i) => {
          const active = stepId === activeStep;
          const completed = !active && !!visited?.[i];

          const label = saleStepLabelObject[stepId];
          const optionalLabel =
            saleSubStepChooseLabelObject[
              activeSubStepChoose ?? ProductTypesEnum.SELECT
            ];
          // show optional subtitle only when the step is active and "Choose"
          const optional =
            stepId === activeStep &&
            stepId === SaleStepEnum.STEP_CHOOSE &&
            optionalLabel;
          const handleClick = () => handleActiveSteps(stepId);

          return (
            <Step key={stepId} completed={completed} active={active}>
              <StepButton
                disabled={disableStepButton || isStepDisabled(stepId, uiState)}
                onClick={handleClick}
              >
                <Typography className={classes.stepLabel} variant="body1">
                  {label}
                </Typography>
                {optional && (
                  <Typography variant="inherit">{optional}</Typography>
                )}
              </StepButton>
            </Step>
          );
        })}
      </MuiStepper>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    flexGrow: 1,
  },
  stepper: {
    padding: 0,
  },
  stepLabel: {
    display: "flex",
  },
});
