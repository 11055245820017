import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setReservedNumber } from "../../../../../../../store/wlrBroadband/actions/configurations";
import { fetchAvailableNumbers } from "../../../../../../../store/wlrBroadband/actions/locations";
import { StatusChip } from "@akj-dev/shared-components";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { ChooseAvailable } from "../../../../../components/wlrBroadband/ChooseAvailable";

export const ChooseAvailableContainer = () => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();

  const config = useSelector(
    (state: any) => state.wlrBroadband.configurations[targetConfigs[0]]
  );

  const locationIndex = config.locationIndex;
  const numberReservation = config.numberReservation;
  const numbers = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex].numbers
  );

  useEffect(() => {
    if (
      _.get(numbers.response, "available_telephone_numbers.string", []).length <
      1
    ) {
      dispatch(fetchAvailableNumbers(locationIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (numbers.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please Wait"
        message="Loading Available Numbers"
      />
    );

  if (_.get(numbers.response, "status") === "error")
    return (
      <StatusChip
        type="error"
        title="Sorry"
        message="Something went wrong finding available numbers."
        retry={() => dispatch(fetchAvailableNumbers(locationIndex))}
      />
    );

  const availableNumbers: string[] = _.get(
    numbers.response,
    "available_telephone_numbers.string",
    []
  );

  if (availableNumbers.length)
    return (
      <ChooseAvailable
        availableNumbers={availableNumbers}
        numberReservation={numberReservation}
        onChooseNumberChange={(value) =>
          dispatch(setReservedNumber(targetConfigs[0], value))
        }
      />
    );

  return null;
};
