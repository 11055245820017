import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAvailableRouters,
  updateEthernetConfiguration,
} from "../../../../store/ethernetProducts/actions";
import { ReduxEthernetConfiguration } from "../types";
import { EthernetConfigurationRouterSelect } from "../components/EthernetConfigurationRouterSelect";

export interface EthernetConfigurationRouterSelectProps {
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
}

export const EthernetConfigurationRouterSelectContainer = ({
  configIndex,
  configuration,
}: EthernetConfigurationRouterSelectProps) => {
  const dispatch = useDispatch();

  const routersFetch = configuration?.availableRouters;
  const selectedRouterId = configuration.selectedRouterId;
  const backup_broadband = configuration.backup_broadband;

  const allRouters = routersFetch?.response?.results?.all;
  const routerOptions = useMemo(() => {
    return (allRouters ?? [])
      .slice()
      .sort((a, b) => {
        if (!a.prices) return 0;
        if (a.prices.recurring > b.prices.recurring) return 1;
        if (a.prices.recurring < b.prices.recurring) return -1;
        return 0;
      })
      .filter(
        (router) =>
          !backup_broadband || router.suitable_for_adsl_fttc_backup === "1"
      );
  }, [allRouters, backup_broadband]);

  const handleRouterChange = (routerId: string) =>
    dispatch(
      updateEthernetConfiguration(configIndex, "selectedRouterId", routerId)
    );

  useEffect(() => {
    dispatch(fetchAvailableRouters(configIndex));
  }, [dispatch, configIndex]);

  return (
    <EthernetConfigurationRouterSelect
      routersFetch={routersFetch}
      selectedRouterId={selectedRouterId}
      routerOptions={routerOptions}
      handleRouterChange={handleRouterChange}
      onRetry={() => dispatch(fetchAvailableRouters(configIndex))}
    />
  );
};
