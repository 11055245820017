import {
  MobileConfigRowDisplayData,
  TypeOfConnection,
} from "../../../../../shared/types/MobileProductConfig";
import { getIsConfigValid } from "../../../../../store/mobile/selectors/productConfig";

/**
 * Transform mobile config store data for display
 * @param config
 */
export const transformMobileConfig = (
  config: any,
  productInstances: any,
  cliBoltOnSearch: any
): MobileConfigRowDisplayData => {
  // Product data related to this config
  const configProductData = config.productData?.response || {};

  // Resign product instance for this config
  const resign = config.resignId
    ? productInstances.response.results?.find(
        (r: { id: string }) => r.id === config.resignId
      )
    : {};

  // Resign without change? This means lots of exceptions to the rules
  const isResignWithoutChanges =
    config.resignType === TypeOfConnection.RESIGN_WITHOUT_CHANGES;

  return {
    cli: resign.pin, // todo: or assigned cli?

    /**
     * Get total recurring price of a configuration including selected CLI level bolt-ons
     * Note: Total price isn't calculated in productData responses so has to be client side.
     * CLI bolt-ons and Airtime credit (?) are technically separate entities
     */
    price: isResignWithoutChanges
      ? resign.computedPrice
      : ((): string => {
          const selectedCliBoltOns = config.selectedCliBoltOns;
          const perConfig =
            configProductData?.overall_price
              ?.first_bill_recurring_price_with_promotions;
          let total = parseFloat(
            perConfig ||
              configProductData?.overall_price
                ?.first_bill_recurring_price_with_promotions
          );

          if (!selectedCliBoltOns) return total.toFixed(2);

          const cliBoltOns =
            cliBoltOnSearch[config.productId]?.response?.products;

          for (let type in selectedCliBoltOns) {
            if (selectedCliBoltOns[type] && cliBoltOns) {
              total += parseFloat(
                cliBoltOns.find(
                  (p: { id: number }) => p.id === selectedCliBoltOns[type].id
                ).price.first_bill_recurring_price_with_promotions
              );
            }
          }
          return total.toFixed(2);
        })(),
    monthlyAirtimeCredit: config?.properties?.airtime_credit_amount,
    monthlyAirtimeCreditDuration: config?.properties?.airtime_credit_duration,
    oneOffAirtimeCredit: config?.properties?.airtime_credit_amount_oneoff,
    sharedDiscount: config?.properties?.shared_discount,
    commissionSacrifice: config?.properties?.commission_sacrifice,
    connectionType: config.resignType || config.properties?.acquisition_method,
    commissionType: config?.properties?.commission_type,
    valid: getIsConfigValid(config, resign),
    status: configProductData.status,
  };
};
