import React from "react";
import _ from "lodash";
import { Box, CircularProgress } from "@mui/material";

interface VerifyingPacProps {
  targetConfigs: number[];
  configs: any[];
}

export const VerifyingPac = ({ targetConfigs, configs }: VerifyingPacProps) => (
  <div>
    {targetConfigs
      .filter((id) => _.get(configs[id], "pacCodeCheck.fetching"))
      .map((id) => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          m={2}
          key={id}
        >
          <Box mb={1}>
            <CircularProgress />
          </Box>
          <Box>
            Verifying <b>{configs[id].properties.pac}</b> /{" "}
            <b>{configs[id].properties.mobile_number}</b>
          </Box>
        </Box>
      ))}
  </div>
);
