import React from "react";
import { Theme, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export interface ItemsSelectedProps {
  maxQuantity: number;
  configsCount: number;
}

export const ItemsSelected = ({
  maxQuantity,
  configsCount,
}: ItemsSelectedProps) => {
  const classes = useStyles();

  const isError = configsCount > maxQuantity;
  const itemsSelectedLabel = `${configsCount} Item${
    configsCount === 1 ? "" : "s"
  } Selected`;
  const errorLabel = `Maximum of ${maxQuantity} items allowed`;

  return (
    <div className={classes.root}>
      {isError ? (
        <Typography className={classes.error}>{errorLabel}</Typography>
      ) : (
        <Typography className={classes.text}>{itemsSelectedLabel}</Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    marginRight: theme.spacing(1.5),
  },
  error: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.error.main,
  },
}));
