import React from "react";
import { format } from "date-fns";
import Icon from "@mui/icons-material/DateRange";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { StatusChip } from "@akj-dev/shared-components";
import { DC_DATE_FORMAT, isNotWorkingDay } from "../../../../shared/utils/date";

interface RequiredByDateProps {
  isFTTP: boolean;
}

export const RequiredByDate = ({ isFTTP }: RequiredByDateProps) => (
  <Section title="Required By Date" avatar={<Icon />}>
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.required_by_date"
      minDate={format(new Date(), DC_DATE_FORMAT)}
      shouldDisableDate={isNotWorkingDay}
    />
    {isFTTP && (
      <StatusChip
        type="info"
        title="An engineer appointment isn’t required."
        message="As there is an existing ONT present at this address, the FTTP service will be activated on the existing ONT on the Required By Date selected."
      />
    )}
  </Section>
);
