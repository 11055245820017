import React from "react";
import { StatusChip, Alert } from "@akj-dev/shared-components";
import { createAccountAndUpdate } from "../../../store/account/actions";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";

interface RequestStateProps {
  accountResponse: Record<string, any>;
  accountFetching: boolean;
  isAccountError: boolean;
  accountErrors?: {
    message: string;
  }[];
  contactFetching: boolean;
  isContactError: boolean;
  contactErrorMessage: string;
}

export const RequestState = ({
  accountResponse,
  accountFetching,
  isAccountError,
  isContactError,
  contactFetching,
  accountErrors,
  contactErrorMessage,
}: RequestStateProps) => {
  const canAccessVfDirect = useCanAccessVfDirect();

  return (
    <>
      {accountFetching ? (
        <StatusChip
          type="loading"
          title={
            canAccessVfDirect
              ? "Creating Account. Credit vetting is being processed"
              : "Creating Account"
          }
          message="Please wait..."
        />
      ) : (
        isAccountError && (
          <Alert
            type="error"
            message={
              typeof accountResponse.message === "string" ? (
                accountResponse.message
              ) : (
                <div>
                  Account creation failed with the following errors:
                  <ul>
                    {accountErrors?.map((error) =>
                      !!error ? <li>{error.message}</li> : ""
                    )}
                  </ul>
                </div>
              )
            }
          />
        )
      )}
      {contactFetching ? (
        <StatusChip
          type="loading"
          title="Creating Contact"
          message="Please wait..."
        />
      ) : (
        isContactError && (
          <StatusChip
            type="error"
            message={contactErrorMessage}
            retry={() => createAccountAndUpdate({})}
          />
        )
      )}
    </>
  );
};
