import React, { ReactNode } from "react";

import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { ResultsEntity } from "../../../../../api/v1/product";
import { getNonGenericInstanceName } from "../../../../../store/mobile/selectors/resigns";
import { ProductTitle } from "../../../../../shared/components/MobileProduct/ProductTitle";

import { CliCell } from "./CliCell";
import { formatCurrency } from "../../../../../shared/utils/formatCurrency";
import { Network } from "../../../../../shared/types/MobileProductConfig";

interface SelectedChangesRowProps {
  isChecked: boolean;
  productInstance: ResultsEntity;
  actions: ReactNode;
  newProduct: Record<string, any>;
  toggleRow: () => void;
}
export const SelectedChangesRow = ({
  isChecked,
  toggleRow,
  productInstance,
  newProduct,
  actions,
}: SelectedChangesRowProps) => (
  <TableRow selected={isChecked}>
    <TableCell>
      <Checkbox color="secondary" onChange={toggleRow} checked={isChecked} />
    </TableCell>
    <CliCell productInstance={productInstance} />
    <TableCell>
      <ProductTitle network={productInstance.network.name as Network}>
        {getNonGenericInstanceName(productInstance)}
      </ProductTitle>
    </TableCell>
    <TableCell>
      <ArrowForwardIcon />
    </TableCell>
    <TableCell>
      <ProductTitle network={newProduct.first_mobile_component.supplier}>
        {newProduct.name}
      </ProductTitle>
    </TableCell>
    <TableCell>
      {formatCurrency(
        parseFloat(newProduct.price.first_bill_recurring_price_with_promotions)
      )}
    </TableCell>
    <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
    <TableCell align="right" style={{ paddingRight: 0 }}>
      {actions}
    </TableCell>
  </TableRow>
);
