import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useState } from "react";
import { useAccountAutoComplete } from "./hooks/useAccountAutocomplete";
import { useDebounce } from "../../helpers/hooks/useDebounce";
import { StatusChip } from "@akj-dev/shared-components";

interface AccountSelectProps {
  account: any;
  setAccount: any;
}

/**
 * Account Select Autocomplete
 *
 * TODO: This should be in orders itself but that's impossible without refactoring so the CPQ is
 *  exported in a separate package to this order initiation screen, for which there is no time
 *  currently.
 *
 * @constructor
 */
export const AccountSelect = ({ account, setAccount }: AccountSelectProps) => {
  const [inputValue, setInputValue] = useState("");
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const { isLoading, error, data, status } =
    useAccountAutoComplete(debouncedInputValue);

  useEffect(() => {
    if (initialFetchComplete) {
      return;
    }

    if (status === "success") {
      // if only 1 option, autoselect it.
      const results = data?.data?.results;
      if (results?.length === 1) {
        setAccount(data?.data?.results[0]);
      }
      setInitialFetchComplete(true);
    }
  }, [status, data, initialFetchComplete, setAccount]);

  return (
    <div>
      <Autocomplete
        disabled={!initialFetchComplete}
        id="account-select-ac"
        options={data?.data?.results || []}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              initialFetchComplete
                ? "Account Select"
                : "Please wait, loading options..."
            }
            variant="outlined"
            size="small"
          />
        )}
        value={account}
        onChange={(event: any, newValue) => setAccount(newValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        loading={isLoading}
      />
      {error && <StatusChip type="error" message={error.message} />}
    </div>
  );
};
