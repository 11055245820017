import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchProductSearch,
  addProduct,
  removeProduct,
} from "../../../../store/universalProducts/actions";
import { getProductConfigCount } from "../../../../store/universalProducts/selectors";

import { ProductTable } from "../components/ProductTable";

export const ProductTableContainer = () => {
  const dispatch = useDispatch();

  const products = useSelector(
    (state: any) => state.universalProducts.productSearch
  );
  const productCount = useSelector<any, number>(
    (state) =>
      (state.universalProducts.productSearch.response.products || []).length
  );
  const configs = useSelector((state: any) => state.universalProducts.configs);

  useEffect(() => {
    if (productCount < 1) {
      dispatch(fetchProductSearch());
    }
  }, [dispatch, productCount]);

  return (
    <ProductTable
      selectedCount={configs.length}
      productCount={productCount}
      isProductError={products.response.status === "error"}
      products={products.response.products}
      showLoader={products.fetching}
      getProductConfigCount={(itemId) => getProductConfigCount(configs, itemId)}
      onRetryClick={() => dispatch(fetchProductSearch())}
      onAddProduct={(itemId) => dispatch(addProduct(itemId))}
      onRemoveProduct={(itemId) => dispatch(removeProduct(itemId))}
    />
  );
};
