import React from "react";
import _ from "lodash";

import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { IndexedTitle, StatusChip } from "@akj-dev/shared-components";

import { WlrLocationBroadbandProduct } from "../types";
import { ONTInfo } from "./ONTInfo";
import { WlrLocationTypeEnum } from "../enums";

interface ChooseSingleProductProps {
  broadbandSearch: Record<string, any>;
  lineAvailability: Record<string, any>;
  products: WlrLocationBroadbandProduct[];
  selectedProductId: string;
  onSelectProduct: (productId: string) => void;
  productType: WlrLocationTypeEnum.NEW_SOGEA | WlrLocationTypeEnum.NEW_FTTP;
}

/**
 * Choose Single Connectivity Product
 * eg. FTTP or SoGEA, where the user doesn't need a WLR line for it to sit on too.
 */
export const ChooseSingleProduct = ({
  broadbandSearch,
  lineAvailability,
  products,
  selectedProductId,
  onSelectProduct,
  productType,
}: ChooseSingleProductProps) => {
  const classes = useStyles();

  if (broadbandSearch.fetching || lineAvailability.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Checking Line & Broadband Availability"
      />
    );

  if (_.get(lineAvailability.response, "status") === "error")
    return (
      <StatusChip
        type="error"
        title="Error"
        message={lineAvailability.response.message}
      />
    );

  if (_.get(broadbandSearch.response, "status") === "error")
    return (
      <StatusChip
        type="error"
        title="Error"
        message={broadbandSearch.response.message}
      />
    );

  if (!broadbandSearch.response.products)
    return (
      <StatusChip
        type="info"
        message={
          productType === WlrLocationTypeEnum.NEW_FTTP
            ? "FTTP doesn’t require separate line rental. An existing line can be used to check FTTP availability at the premises but the FTTP install will be independent of any existing line at the premises. If a line for voice is required, please order this as a separate item on the wizard."
            : // SOGEA
              "This is a broadband only line which does NOT include a WLR voice service. If the customer requires a voice service,please quote for a VoIP service separately."
        }
      />
    );

  if (products.length < 1 && broadbandSearch.response.status === "success")
    return (
      <StatusChip
        type="error"
        title={`No ${
          productType === WlrLocationTypeEnum.NEW_FTTP ? "FTTP" : "SoGEA"
        } products available at this address.`}
        message="Please try again."
      />
    );

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        <IndexedTitle
          index="C"
          title="Choose a Product"
          withMargin={true}
          negative={true}
        />
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          {productType === WlrLocationTypeEnum.NEW_FTTP && (
            <ONTInfo
              ontDetails={_.get(lineAvailability.response, "ont_details", {})}
            />
          )}
        </Grid>
        {products.map((product) => (
          <Grid item sm={4} key={product.id}>
            <Card
              onClick={() => onSelectProduct(product.id)}
              style={{ cursor: "pointer" }}
            >
              <CardHeader title={product.name} />
              <Box className={classes.footer}>
                <Checkbox
                  color="secondary"
                  checked={product.id === selectedProductId}
                />
                <Box className={classes.price}>
                  £{product.price.first_bill_recurring_price_with_promotions}
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  price: {
    fontSize: ".8rem",
    color: theme.palette.success.main,
    fontWeight: "bold",
  },
}));
