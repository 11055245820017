import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { FileIcon } from "./FileIcon";
import Checkbox from "@mui/material/Checkbox";
import { filetype } from "../../utils/files";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";

interface UserDocumentChipProps {
  file: File;
  sendPref?: boolean;
  removeAction: () => void;
  sendPrefAction: (newPref: boolean) => void;
}

export const UserDocumentChip = ({
  file,
  sendPref,
  removeAction,
  sendPrefAction,
}: UserDocumentChipProps) => {
  const isPDF = filetype(file.name) === ".pdf";

  return (
    <Outer>
      <Container>
        <IconOuter>
          <FileIcon filename={file.name} />
        </IconOuter>
        {file.name}
      </Container>
      <Actions>
        <FormControlLabel
          label={`Send with contract${isPDF ? "" : " - PDF only"}`}
          control={
            <Checkbox
              color="secondary"
              checked={sendPref || false}
              disabled={!isPDF}
              onChange={(_, checked) => sendPrefAction(checked)}
            />
          }
        />
        <IconButton onClick={removeAction} size="large">
          <DeleteIcon />
        </IconButton>
      </Actions>
    </Outer>
  );
};

UserDocumentChip.propTypes = {
  file: PropTypes.object.isRequired,
  sendPref: PropTypes.bool,
  removeAction: PropTypes.func.isRequired,
  sendPrefAction: PropTypes.func.isRequired,
};

const Outer = styled(Paper)`
  margin-top: 16px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconOuter = styled.div`
  width: 70px;
  padding: 10px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.palette.grey[50]};
  padding: 10px;
`;
