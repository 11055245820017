import React, { ReactNode } from "react";
import { Chip } from "@mui/material";
import { DeviceHub } from "@mui/icons-material";

interface MultipleIndicatorProps {
  multiple?: boolean;
  value: ReactNode;
}

export const MultiIndicator = ({ multiple, value }: MultipleIndicatorProps) => {
  if (multiple) {
    return (
      <Chip
        label="Multiple"
        variant="filled"
        color="default"
        icon={<DeviceHub />}
      />
    );
  } else {
    return <>{value}</>;
  }
};
