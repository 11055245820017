import { addProduct, addUserDocument, removeProduct } from "./actions";
import { trackSimpleEvent } from "../../shared/utils/reduxBeacon";

const gaEventsMap = {
  ...trackSimpleEvent(addProduct.type),
  ...trackSimpleEvent(removeProduct.type),
  ...trackSimpleEvent(addUserDocument.type),
};

export default gaEventsMap;
