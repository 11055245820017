import React from "react";
import { Button, Dialog, DialogTitle, Box } from "@mui/material";
import { ReactNode } from "react";

interface UploadUniversalDocumentsProps {
  openDialog: () => void;
  closeDialog: () => void;
  showDialog: boolean;
  isUploadButtonDisabled: boolean;
  contentChildren: ReactNode;
}

export const UploadUniversalDocuments = ({
  showDialog,
  isUploadButtonDisabled,
  contentChildren,
  openDialog,
  closeDialog,
}: UploadUniversalDocumentsProps) => (
  <>
    <Dialog open={showDialog} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Supporting Universal Documents</DialogTitle>
      {contentChildren}
    </Dialog>
    <Box my={1}>
      <Button
        variant="outlined"
        color="inherit"
        onClick={openDialog}
        disabled={isUploadButtonDisabled}
      >
        Upload Supporting Universal Documents
      </Button>
    </Box>
  </>
);
