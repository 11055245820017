import React, { FocusEvent } from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Grid, InputAdornment } from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";

import { Section } from "../../../../shared/components/Section";
import { UPConfig } from "../../../../store/universalProducts/types";
import { TextFieldLowUpdate } from "../../../../shared/components/Pricing/TextFieldLowUpdate";
import { isValidMonetaryAmount } from "../../../../shared/utils/validation";

interface PricingProps {
  oneOffPrice: string;
  recurringPrice: string;
  hasValidPrices: boolean;
  onChangePrice: (properyName: keyof UPConfig, value: string) => void;
}
export const Pricing = ({
  oneOffPrice,
  recurringPrice,
  hasValidPrices,
  onChangePrice,
}: PricingProps) => {
  return (
    <Section title="Pricing" avatar={<MonetizationOnIcon />}>
      {hasValidPrices ? (
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item sm={6}>
            <TextFieldLowUpdate
              variant="standard"
              label="One Off Price"
              type="number"
              value={oneOffPrice}
              onBlur={(event: FocusEvent<HTMLInputElement>) => {
                onChangePrice("oneOffPrice", event.target.value);
              }}
              inputProps={{ min: 0, step: 1 }}
              data-cy="PricingOneOff"
              fullWidth
              validate={(value: string) => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";
                return "";
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextFieldLowUpdate
              variant="standard"
              label="Recurring Price"
              type="number"
              value={recurringPrice}
              onBlur={(event: FocusEvent<HTMLInputElement>) => {
                onChangePrice("recurringPrice", event.target.value);
              }}
              inputProps={{ min: 0, step: 1 }}
              data-cy="PricingRecurring"
              fullWidth
              validate={(value: string) => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";
                return "";
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <StatusChip
          type="error"
          message={"No pricing available for this product."}
        />
      )}
    </Section>
  );
};
