import React from "react";
import { CommissionType } from "../types/MobileProductConfig";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";

type CommissionRadioProps = {
  disabled: boolean | undefined;
  commissionType: string;
  onCommissionSelect?: (e: any) => void;
};

export const CommissionRadio = ({
  disabled,
  commissionType,
  onCommissionSelect,
}: CommissionRadioProps) => (
  <Tooltip
    title={
      disabled
        ? "This functionality is not available for 1 month contracts"
        : ""
    }
  >
    <FormControl disabled={disabled}>
      <RadioGroup
        row
        onChange={onCommissionSelect}
        value={disabled ? CommissionType.RESIDUAL : commissionType}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormControlLabel
            value={CommissionType.UPFRONT}
            control={
              <Tooltip title="Upfront">
                <Radio />
              </Tooltip>
            }
            sx={{ marginLeft: 2 }}
            label={""}
          />
          <FormControlLabel
            value={CommissionType.RESIDUAL}
            control={
              <Tooltip title="Residual">
                <Radio />
              </Tooltip>
            }
            sx={{ marginRight: 2 }}
            label={""}
          />
        </div>
      </RadioGroup>
    </FormControl>
  </Tooltip>
);
