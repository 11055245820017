import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  selectConfig,
  removeConfig,
  fetchProductDocuments,
} from "../../../../../store/universalProducts/actions";
import {
  getConfigDocuments,
  getIsUniversalProductConfigValid,
  makeGetConfigProductName,
} from "../../../../../store/universalProducts/selectors";
import { getHostEnv } from "../../../../../store/order/selectors/getHostEnv";
import { getDoesUniversalProductHaveDocuments } from "../../../../../store/universalProducts/selectors";
import { ConfigTableRow } from "../../../components/UniversalProducts/ConfigTableRow";

interface ConfigTableRowProps {
  configIndex: number;
  hideAction?: boolean;
}

export const ConfigTableRowContainer = ({
  configIndex,
  hideAction,
}: ConfigTableRowProps) => {
  const dispatch = useDispatch();
  const hostEnv = useSelector(getHostEnv);

  const getConfigProductName = makeGetConfigProductName();

  const config = useSelector(
    (state: any) => state.universalProducts.configs[configIndex]
  );
  const productName = useSelector<any, string>((state) =>
    // @ts-ignore
    getConfigProductName(state, { configIndex })
  );
  const configDocuments = useSelector((state: any) =>
    getConfigDocuments(state, configIndex)
  );

  useEffect(() => {
    if (
      !_.get(configDocuments, "fetching") &&
      !_.get(configDocuments, "response.documents")
    ) {
      dispatch(fetchProductDocuments(config.productId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigTableRow
      productName={productName}
      oneOffPrice={config.oneOffPrice}
      recurringPrice={config.recurringPrice}
      hideAction={hideAction}
      onSelectConfig={() => dispatch(selectConfig(configIndex))}
      onRemoveConfig={() => dispatch(removeConfig(configIndex))}
      isConfigValid={
        getIsUniversalProductConfigValid(config) &&
        (getDoesUniversalProductHaveDocuments(config) || hostEnv === "dc")
      }
    />
  );
};
