import React from "react";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";

import { LocationDescriptorObject } from "history";
import { Theme } from "@mui/material";

type Props = {
  title: string;
  to: LocationDescriptorObject | string;
};
//@todo = fix any
export const OrdersSummaryCard: React.FC<Props> = ({ title, to, children }) => {
  const styles = {
    header: {
      textTransform: "capitalize",
      padding: (theme: Theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      borderBottom: (theme: Theme) => `1px solid ${theme.palette.grey[200]}`,
      textAlign: "center",
    },
    actions: {
      cursor: "pointer",
      textTransform: "capitalize",
      transition: (theme: Theme) =>
        `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      fontWeight: 600,
      padding: (theme: Theme) => `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      "&:hover": {
        backgroundColor: "primary.dark",
      },
    },
  };
  return (
    <Card raised>
      <CardHeader sx={styles.header} title={title} />
      <Box display="flex">{children}</Box>
      <Link to={to} style={{ textDecoration: "none" }}>
        <CardActions sx={styles.actions}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <div>View All</div>
            <ChevronRightIcon fontSize="small" />
          </Box>
        </CardActions>
      </Link>
    </Card>
  );
};
