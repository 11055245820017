// what needs passing in, components for the credit values and the price?
export function getOneOffPriceWithCredits(products: any[], orderprice: number) {
  let price = 0;
  let creditUsed = 0;
  products.forEach((product) => {
    if (product.components[0] && product.components[0].credit_used)
      creditUsed += parseFloat(product.components[0].credit_used);
    if (product?.pricing?.product)
      price += parseFloat(
        product.pricing.product.promotion_id
          ? product.pricing.product.one_off_price_with_promotions
          : product.pricing.product.one_off_price_without_promotions
      );
  });
  return (price - creditUsed).toFixed(2);
}
