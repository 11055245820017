import {
  dateStringSubWeek,
  dateStringSubMonth,
  dateStringSubQuarterYear,
  dateStringSubYear,
} from "../../../helpers/dates";

const dateStringOptions = [
  dateStringSubWeek,
  dateStringSubMonth,
  dateStringSubQuarterYear,
  dateStringSubYear,
];

export function isValidDashboardDateString(input: String): boolean {
  return dateStringOptions.find((dateString) => dateString === input) || false;
}
