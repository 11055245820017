import { postJSON } from "../helpers/core";

type VodaFoneProcessCreditVetParams = {
  account_id: string;
  number_of_connections: number;
};

// we're only interested in whether the call itself succeeded
export type VodafoneProcessCreditVetResponse = Partial<{ status: "success" }>;

// requests credit vet to be performed against an account for a specified number of connections
// results are retrieved from a seperate endpoint
export const VodaFoneProcessCreditVet = (
  params: VodaFoneProcessCreditVetParams
) =>
  postJSON<VodafoneProcessCreditVetResponse>(
    "/Vodafone/Process",
    params,
    undefined,
    undefined,
    false
  );
