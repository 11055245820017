import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchBroadbandProductInstances,
  fetchBroadbandResignProductSearch,
  fetchLineProductInstances,
  fetchLineResignProductSearch,
  fetchResignProductSearch,
} from "../../../../../../store/wlrBroadband/actions/resigns";
import { ResignsTableStatuses } from "../../../components/Resigns/Table/Statuses";

export const ResignsTableStatusesContainer = () => {
  const dispatch = useDispatch();

  const lineResignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.lineResignProductSearch
  );
  const broadbandResignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.broadbandResignProductSearch
  );
  const resignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.resignProductSearch
  );

  const lineProductInstances = useSelector(
    (state: any) => state.wlrBroadband.lineProductInstances
  );
  const broadbandProductInstances = useSelector(
    (state: any) => state.wlrBroadband.broadbandProductInstances
  );

  return (
    <ResignsTableStatuses
      lineResignProductSearch={lineResignProductSearch}
      broadbandResignProductSearch={broadbandResignProductSearch}
      resignProductSearch={resignProductSearch}
      lineProductInstances={lineProductInstances}
      broadbandProductInstances={broadbandProductInstances}
      requestBroadbandProductInstances={() =>
        dispatch(fetchBroadbandProductInstances({}))
      }
      requestBroadbandResignProduct={() =>
        dispatch(fetchBroadbandResignProductSearch())
      }
      requestLineProductInstances={() =>
        dispatch(fetchLineProductInstances({}))
      }
      requestLineResignProduct={() => dispatch(fetchLineResignProductSearch())}
      requestResignProduct={() => dispatch(fetchResignProductSearch())}
    />
  );
};
