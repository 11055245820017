import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Chip = styled.div`
  display: block;
  padding: 16px 10px;
  margin: 20px 0;
  border-radius: 5px;
  background: ${(p) => p.theme.palette.grey[50]};
  cursor: pointer;
  span {
    text-decoration: underline;
  }
`;
const MultiValueWarning = ({ onClick, dynamicProperty }) => {
  return (
    <Chip onClick={onClick}>
      <b>{dynamicProperty.label}</b> Multiple values. <span>Click to edit</span>
    </Chip>
  );
};

MultiValueWarning.propTypes = {
  onClick: PropTypes.func.isRequired,
  dynamicProperty: PropTypes.object.isRequired,
};

export default MultiValueWarning;
