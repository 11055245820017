import React from "react";

import { Theme, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { StatusChip } from "@akj-dev/shared-components";

import { Spinner } from "../../../../shared/components/Spinner";
import { ProductSelect } from "../../../../shared/components/ProductSelect";

export interface EthernetConfigurationRouterSelectProps {
  routersFetch: Record<string, any>;
  selectedRouterId: string;
  routerOptions: Record<string, any>[];
  handleRouterChange: (routerId: string) => void;
  onRetry: () => void;
}

export const EthernetConfigurationRouterSelect = ({
  routersFetch,
  selectedRouterId,
  routerOptions,
  handleRouterChange,
  onRetry,
}: EthernetConfigurationRouterSelectProps) => {
  const classes = useStyles();

  if (routersFetch.fetching) return <Spinner />;

  if (!routersFetch.response?.results)
    return (
      <StatusChip
        type="error"
        message="Problem fetching router options."
        retry={onRetry}
      />
    );

  return (
    <ProductSelect
      dataCy="ethernet-router"
      value={selectedRouterId}
      handleChange={handleRouterChange}
    >
      {routerOptions.map((router) => (
        <MenuItem value={router.id} key={router.id}>
          <div>
            <b>{router.name}</b>
            <div className={classes.description}>{router.description}</div>
          </div>
        </MenuItem>
      ))}
    </ProductSelect>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: "0.9em",
    color: theme.palette.text.disabled,
  },
}));
