import React, { PropsWithChildren, CSSProperties } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Container = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  i {
    margin-right: 8px;
  }
`;

interface SmallStatusProps {
  type: "error" | "success" | "info";
  style?: CSSProperties;
}

export const SmallStatus = ({
  type,
  style,
  children,
}: PropsWithChildren<SmallStatusProps>) => {
  const theme = useTheme();

  let color, icon;
  switch (type) {
    case "error":
      color = theme.palette.error.main;
      icon = <ErrorOutlineIcon />;
      break;
    case "info":
      color = theme.palette.info.main;
      icon = <InfoOutlinedIcon />;
      break;
    case "success":
      color = theme.palette.success.main;
      icon = <CheckIcon />;
      break;
    default:
  }
  return (
    <Container color={color as string} style={style}>
      <Box mr={1}>{icon}</Box>
      {children}
    </Container>
  );
};

SmallStatus.propTypes = {
  style: PropTypes.object,
  type: PropTypes.oneOf(["error", "success", "info"]),
};
