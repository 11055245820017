import initialState from "./state";
import accountSettingsDefault from "../../config/accountSettings.default";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountSettingsPayload } from "./types";
import {
  fetchAvailableHardwareCredit,
  fetchAccountSettings,
  fetchBillingAddress,
  uptdateBillingAddress,
  fetchAllAccountAddresses,
  fetchAllContacts,
  addContact,
  fetchContactTypes,
  processCreditVet,
  fetchCreditVetStatus,
  resetAccountState,
  resetAddContact,
  createAccount,
} from "./actions";
import u from "updeep";
import _ from "lodash";

export let accountSettings: AccountSettingsPayload = {};

const account: any = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetAccountState, (state) => {
        state = initialState;
        state.accountId = false;
      })

      // Available Hardware Credit
      .addCase(fetchAvailableHardwareCredit.pending, (state) => {
        state.isFetchingAvailableHardwareCredit = true;
      })
      .addCase(fetchAvailableHardwareCredit.rejected, (state) => {
        state.isFetchingAvailableHardwareCredit = false;
      })
      .addCase(fetchAvailableHardwareCredit.fulfilled, (state, action) => {
        state.isFetchingAvailableHardwareCredit = false;
        state.availableHardwareCredit =
          (action.payload as number) || initialState.availableHardwareCredit;
      })

      // Account Settings
      .addCase(fetchAccountSettings.pending, (state) => {
        state.settings.fetching = true;
      })
      .addCase(fetchAccountSettings.rejected, (state) => {
        const settings = accountSettingsDefault as typeof accountSettings;
        state.settings.fetching = false;
        state.settings.response = settings;
        accountSettings = settings;
      })
      .addCase(
        fetchAccountSettings.fulfilled,
        (state, action: PayloadAction<any>) => {
          const settings = u(action.payload, accountSettingsDefault);
          state.settings.fetching = false;
          state.settings.response = settings;
          accountSettings = settings;
        }
      )

      // Billing Address
      .addCase(fetchBillingAddress.pending, (state) => {
        state.billingAddress.fetching = true;
      })
      .addCase(fetchBillingAddress.rejected, (state) => {
        state.billingAddress.fetching = false;
      })
      .addCase(
        fetchBillingAddress.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.billingAddress.fetching = false;
          state.billingAddress.response = action.payload;
        }
      )
      .addCase(uptdateBillingAddress.pending, (state) => {
        state.billingAddress.fetching = true;
      })
      .addCase(uptdateBillingAddress.rejected, (state) => {
        state.billingAddress.fetching = false;
      })
      .addCase(
        uptdateBillingAddress.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.billingAddress.fetching = false;
          state.billingAddress.response = action.payload;
        }
      )

      // Account Addresses
      .addCase(fetchAllAccountAddresses.pending, (state) => {
        state.accountAddresses.fetching = true;
      })
      .addCase(fetchAllAccountAddresses.rejected, (state) => {
        state.accountAddresses.fetching = false;
      })
      .addCase(
        fetchAllAccountAddresses.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.accountAddresses.fetching = false;
          state.accountAddresses.response = action.payload;
        }
      )

      // Contacts
      .addCase(fetchAllContacts.pending, (state) => {
        state.contacts.fetching = true;
      })
      .addCase(fetchAllContacts.rejected, (state) => {
        state.contacts.fetching = false;
      })
      .addCase(
        fetchAllContacts.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.contacts.fetching = false;
          state.contacts.response = action.payload;
          state.addContact.response = {};
        }
      )
      .addCase(addContact.pending, (state) => {
        state.addContact.fetching = true;
      })
      .addCase(addContact.rejected, (state) => {
        state.addContact.fetching = false;
      })
      .addCase(addContact.fulfilled, (state, action: PayloadAction<any>) => {
        state.addContact.fetching = false;
        state.addContact.response = action.payload;
        if (action.payload.data) {
          state.contacts.response = {
            data: {
              ...state?.contacts?.response?.data,
              contacts: [
                action?.payload?.data,
                ...state?.contacts?.response?.data?.contacts,
              ],
            },
          };
        }
      })
      .addCase(resetAddContact, (state) => {
        state.addContact.fetching = false;
        state.addContact.response = {};
      })
      .addCase(fetchContactTypes.pending, (state) => {
        state.contactTypes.fetching = true;
      })
      .addCase(fetchContactTypes.rejected, (state) => {
        state.contactTypes.fetching = false;
      })
      .addCase(
        fetchContactTypes.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.contactTypes.fetching = false;
          state.contactTypes.response = action.payload;
        }
      )

      // Credit Vet
      .addCase(processCreditVet.pending, (state) => {
        state.creditVetProcess.fetching = true;
      })
      .addCase(processCreditVet.rejected, (state) => {
        state.creditVetProcess.fetching = false;
      })
      .addCase(
        processCreditVet.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.creditVetProcess.fetching = false;
          state.creditVetProcess.response = action.payload;
        }
      )
      .addCase(fetchCreditVetStatus.pending, (state) => {
        state.creditVetStatus.fetching = true;
      })
      .addCase(fetchCreditVetStatus.rejected, (state) => {
        state.creditVetStatus.fetching = false;
      })
      .addCase(
        fetchCreditVetStatus.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.creditVetStatus.fetching = false;
          state.creditVetStatus.response = action.payload;
        }
      )

      //  Create Account
      .addCase(createAccount.pending, (state) => {
        state.createAccount.fetching = true;
      })
      .addCase(createAccount.rejected, (state) => {
        state.createAccount.fetching = false;
      })
      .addCase(createAccount.fulfilled, (state, action: PayloadAction<any>) => {
        state.createAccount.fetching = false;
        state.createAccount.response = action.payload;
        state.newAccountId = _.get(action.payload, "data.id");
      });
  },
});

export default account.reducer;
