import React from "react";

import ExtensionIcon from "@mui/icons-material/Extension";
import { useTheme } from "@mui/material/styles";

import { Chip } from "../../../../../shared/components/Chip/Chip";

interface BoltOnChipProps {
  title: string;
  price: number;
}

export const BoltOnChip = ({ title, price }: BoltOnChipProps) => {
  const theme = useTheme();

  return (
    <Chip
      Icon={ExtensionIcon}
      title={
        <span>
          {title}
          {!!price && <b> - £{price.toFixed(2)}</b>}
        </span>
      }
      color={theme.palette.secondary.main}
    />
  );
};
