import { useSelector } from "react-redux";

export const useBroadbandLocationByPin = (pin: string) => {
  const location = useSelector<any, any>((state) =>
    state.wlrBroadband.locations.find(
      (location: any) => location.cli.value === pin
    )
  );

  return location;
};
