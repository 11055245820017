import React from "react";
import { Box, Theme, Typography, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorOutline } from "@mui/icons-material";
import { AddressPicker } from "@akj-dev/shared-components";
import {
  WLR3Address,
  WLR3AddressSearch,
} from "../../../../../api/v1/addressSearch";

interface NoAddressErrorAmendProps {
  onSetAddress: (address: WLR3Address) => void;
}
export const NoAddressErrorAmend = ({
  onSetAddress,
}: NoAddressErrorAmendProps) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <Box className={classes.root}>
          <Box display="flex">
            <ErrorOutline fontSize="large" className={classes.icon} />
            <Box>
              <Typography variant="h4">Error</Typography>
              <Typography variant="h5" gutterBottom>
                This product instance doesn't have a full address. Please select
                an address to continue with the resign process.
              </Typography>
            </Box>
          </Box>
          <AddressPicker saveAddress={onSetAddress} api={WLR3AddressSearch} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: 2,
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    borderStyle: "solid",
    borderRadius: 8,
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: 14,
  },
}));
