import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { StatusChip } from "@akj-dev/shared-components";

import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { WlrChangePriceAdjustment } from "../../../../../components/wlrBroadband/WlrChangePriceAdjustment";

export const WlrChangePriceAdjustmentContainer = () => {
  const { targetConfigs } = useTargetConfigsContext();
  const careLevelChangeEnabled = useSelector<any, boolean>(
    (state: any) =>
      !!_.get(
        state.wlrBroadband.configurations[targetConfigs[0]],
        'broadbandProperties["wlr_change.care_level"]'
      )
  );

  const careLevelDiscountEnabled = useSelector<any, boolean>(
    (state: any) =>
      _.get(
        state.wlrBroadband.configurations[targetConfigs[0]],
        'broadbandProperties["wlr_change.care_level.recurring_discount_override"]'
      ) == 1 // eslint-disable-line eqeqeq
  );

  // TODO: routerDiscountFields price adjustment

  // Unlike other sections, Price Adjustment operates on properties outside of `dynamic_properties`
  // (under [line || broadband].activation_fee.*) Hence the special treatment.

  /**
     * In product data there is, for example....
     *
     * an overview:
     * line: {
         "overall_price": {
            "one_off_price_with_discounts": "78.00",
            "recurring_price_with_discounts": "23.50",
            "recurring_price_without_discounts": "23.50",
            "total_recurring_price_for_length_of_contract": "564.00",
            "one_off_price_without_discounts": "129.99"
         },
       }
     Then each part of the product where discounts are applied to:
         "component_price": {
            "recurring_pricing_rule": "Line - Single Line Recurring - Minimum Discounted Price   9.99",
            "recurring_discount_override": null,
            "one_off_discount_type": null,
            "product_description": null,
            "product_name": "WLR Single Line",
            "one_off_discount_override": null,
            "one_off_discount_value": null,
            "total_recurring_price_for_length_of_contract": "564.00",
            "recurring_price_without_discounts": "23.50",
            "one_off_price_with_discounts": "0.00",
            "recurring_discount_type": null,
            "one_off_price_without_discounts": "0.00",
            "product_id": "36401",
            "one_off_pricing_rule": null,
            "recurring_discount_value": null,
            "recurring_price_with_discounts": "23.50"
         }
     and "activation_fee" {} with the same collection of nodes as above:
             "recurring_discount_type": null,
             "one_off_price_with_discounts": "78.00",
             "one_off_price_without_discounts": "129.99",
             "recurring_discount_value": null,
             "product_id": "36406",
             "one_off_pricing_rule": "Line - 40% Install Fee Discount if 24-35 Month Contract and 0-45% monthly discount",
             "recurring_price_with_discounts": "0.00",
             "recurring_pricing_rule": null,
             "one_off_discount_type": "DiscountPercentage",
             "recurring_discount_override": null,
             "product_description": null,
             "one_off_discount_override": null,
             "product_name": "WLR Single Line Install Fee",
             "one_off_discount_value": "40.00",
             "total_recurring_price_for_length_of_contract": "0.00",
             "recurring_price_without_discounts": "0.00"
             },
     *
     * These values are changed via request params:
     * line-recurring_discount_override
     * line-recurring_discount_value
     * line-recurring_discount_type
     * line-activation_fee.one_off_discount_override
     * line-activation_fee.one_off_discount_value
     * line-activation_fee.one_off_discount_type
     */

  if (targetConfigs.length > 1)
    return (
      <StatusChip
        type="info"
        title="WLR Change Price Adjustments cannot be bulk edited."
        message="Please choose them per line."
      />
    );

  return (
    <WlrChangePriceAdjustment
      careLevelChangeEnabled={careLevelChangeEnabled}
      careLevelDiscountEnabled={careLevelDiscountEnabled}
    />
  );
};
