import { useAuth } from "@akj-dev/affinity-platform";

/**
 * Match notification types to feature toggles
 * TP46499
 */
export const useNotificationTypes = () => {
  const { accountSettings } = useAuth();
  let notificationTypes = [];
  if (accountSettings.as_mobile_order_enabled === "1")
    notificationTypes.push("Mobile");
  if (accountSettings.as_wlrbb_order_enabled === "1")
    notificationTypes.push("Broadband");
  return notificationTypes;
};
