import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { initBankHolidays } from "../../shared/utils/bankHolidays";
import { setActiveSubStepChoose } from "../../store/uiState/actions";
import { MonitoringServiceProducts } from "./MonitoringService";
import { UniversalProducts } from "./UniversalProducts";
import {
  saleStepLabelObject,
  saleSubStepChooseLabelObject,
} from "../../shared/constants";
import { ProductTypesEnum, SaleStepEnum } from "../../shared/enums";
import { TitleAndInfo } from "../../shared/containers/TitleAndInfo";
import { Footer } from "../../shared/components/Footer";
import { SubStepActionEnum } from "./enums";
import { ChooseProductTypes } from "./ChooseProductTypes";
import { ChooseMobile } from "./Mobile";
import { ChooseHardware } from "./Hardware";
import { ChooseBroadband } from "./Broadband";
import { ChooseEthernet } from "./Ethernet";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";
import { useDwsResellerEnabled } from "../../shared/hooks/useDwsResellerEnabled";

type ReduxChooseType = {
  productTypes: ProductTypesEnum[];
  activeStep: SaleStepEnum;
  activeSubStepChoose: ProductTypesEnum;
};

export const Choose = () => {
  const dispatch = useDispatch();
  const hostEnv = useSelector(getHostEnv);
  const isDwsResellerEnabled = useDwsResellerEnabled();

  const { productTypes, activeStep, activeSubStepChoose } = useSelector<
    any,
    ReduxChooseType
  >((state) => ({
    productTypes: state.uiState.productTypes ?? [],
    activeStep: state.uiState.activeStep,
    activeSubStepChoose:
      state.uiState.activeSubStepChoose ?? ProductTypesEnum.SELECT,
  }));

  const title =
    activeStep === SaleStepEnum.STEP_CHOOSE
      ? saleSubStepChooseLabelObject[activeSubStepChoose]
      : saleStepLabelObject[activeStep];

  const dwsTermsAccepted = useSelector<any, boolean>(
    (state) => state.mobile.dwsTermsAccepted
  );
  const continueButtonDisabled =
    productTypes.length === 0 ||
    (isDwsResellerEnabled &&
      productTypes.includes(ProductTypesEnum.MOBILE) &&
      !dwsTermsAccepted);

  const isFirstProductType = productTypes[0] === activeSubStepChoose;
  const isLastProductType =
    productTypes[productTypes.length - 1] === activeSubStepChoose;

  const handleContinue = () => {
    dispatch(setActiveSubStepChoose(SubStepActionEnum.CONTINUE));
  };

  const handleBack = () => {
    dispatch(setActiveSubStepChoose(SubStepActionEnum.BACK));
  };

  useEffect(() => {
    // TODO: Stop this re-fetching when we already have up-to-date data
    initBankHolidays();
  }, []);

  return (
    <Box>
      <TitleAndInfo title={title} />
      {activeSubStepChoose === ProductTypesEnum.SELECT && (
        <ChooseProductTypes />
      )}
      {activeSubStepChoose === ProductTypesEnum.MOBILE && <ChooseMobile />}
      {activeSubStepChoose === ProductTypesEnum.HARDWARE && <ChooseHardware />}
      {activeSubStepChoose === ProductTypesEnum.BROADBAND && (
        <ChooseBroadband />
      )}
      {activeSubStepChoose === ProductTypesEnum.ETHERNET && <ChooseEthernet />}
      {activeSubStepChoose === ProductTypesEnum.MONITORING && (
        <MonitoringServiceProducts />
      )}
      {activeSubStepChoose === ProductTypesEnum.UNIVERSAL && (
        <UniversalProducts />
      )}
      <Footer
        continueDisabled={continueButtonDisabled}
        backHidden={activeSubStepChoose === ProductTypesEnum.SELECT}
        shouldDisplayCancelSaleButton={hostEnv !== "dc"}
        onContinue={handleContinue}
        onBack={handleBack}
        LeftButtonText={
          isFirstProductType ? "Product Types" : "Previous Product"
        }
        RightButtonText={
          activeSubStepChoose === ProductTypesEnum.SELECT
            ? "Product Catalogue"
            : isLastProductType
            ? "Customise"
            : "Next Product"
        }
      />
    </Box>
  );
};
