import React, { ChangeEvent } from "react";

import { Box, InputAdornment, TextField } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

import { ResignsTableActions } from "./Actions";

interface ResignsTableSearchBarProps {
  filter: string;
  countOfProducts: number;
  countOfSelected: number;
  setFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  bulkSelectByCLI: (value: string) => void;
  doResignWithoutChanges: () => void;
  doResignNone: () => void;
}

export const ResignsTableSearchBar = ({
  filter,
  countOfProducts,
  countOfSelected,
  setFilter,
  bulkSelectByCLI,
  doResignNone,
  doResignWithoutChanges,
}: ResignsTableSearchBarProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.searchBox}>
        <TextField
          data-cy={"resignTableSearch"}
          className={classes.search}
          variant="outlined"
          name="filter"
          value={filter}
          onChange={setFilter}
          placeholder={`Search ${countOfProducts} Available Resigns`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <ResignsTableActions
        infoLabel={`${countOfSelected} Selected / ${countOfProducts} Available`}
        disabled={countOfSelected < 2}
        doResignWithoutChanges={doResignWithoutChanges}
        doResignNone={doResignNone}
        bulkSelectByCLI={bulkSelectByCLI}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    searchBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    search: {
      minWidth: "400px",
      marginBottom: theme.spacing(1),
    },
  })
);
