import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../../store/ethernetProducts/selectors";
import { Voice } from "../../../../components/Ethernet/Voice";

interface VoiceProps {
  purchaseArgs: { with_voice: string };
  index: number;
}

export const VoiceContainer = ({ purchaseArgs, index }: VoiceProps) => {
  const [expanded, setExpanded] = useState(false);

  const validationFields = ["with_voice", "voice_service"];

  const validationErrors = useSelector((state) =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter((field) => validationFields.includes(field));

  const showErrors = useSelector<any, boolean>((state) =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <Voice
      expanded={expanded}
      index={index}
      withVoice={purchaseArgs.with_voice === "1"}
      handleHeaderClick={handleHeaderClick}
    />
  );
};

VoiceContainer.propTypes = {
  purchaseArgs: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
