import React from "react";

import { StatusChip } from "@akj-dev/shared-components";
import { Button, Box } from "@mui/material";

import { Spinner } from "../../../shared/components/Spinner";

interface ProvisionOrderButtonProps {
  fetching: boolean;
  isError: boolean;
  errorMessage: string;
  onPlaceOrderClick: () => void;
}

export const ProvisionOrderButton = ({
  fetching,
  errorMessage,
  isError,
  onPlaceOrderClick,
}: ProvisionOrderButtonProps) => (
  <Box my={1}>
    <Button
      variant="contained"
      color="secondary"
      data-cy="placeOrderButton"
      onClick={onPlaceOrderClick}
      endIcon={fetching && <Spinner size={30} color="#aaa" />}
      disabled={fetching}
    >
      Place Order
    </Button>
    {isError && (
      <StatusChip
        type="error"
        title="Sorry, there has been a problem placing your order. Please contact support."
        message={errorMessage}
      />
    )}
  </Box>
);
