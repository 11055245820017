/**
 * This is used as:
 * - Part of the initial state for the reducer
 * - To instantiate new blank configs (passed to action maybe?)
 */

const defaultConfig = {
  // Collected in step 1
  product: false,
  site_b_type: false,
  site_b_bearer: false,
  speed: false,

  // Collected in step 2 via address lookup, to pass to /LeasedLineQuotes/PricingRequest
  site_a_full_address: {},
  site_b_full_address: {},

  site_b_cli: false,

  availableRouters: {
    fetching: false,
    response: false,
  },
  selectedRouterId: false,

  // Failover options (to send to pricing request)
  backup_line: false, // boolean
  backup_broadband: false, // boolean
  backup_broadband_technology: false, // [Fibre or ADSL]
  backup_broadband_provider: false, // [TTB or BTW]

  pricingRequestId: false,
  pricingRequestStatus: false,
  pricingResults: [],

  selectedQuoteId: false,
  purchaseIsRequesting: false,
  purchaseResponse: {
    order_id: false,
    error: false,
    missing: [],
  },
  purchaseArgs: {
    customer_required_date: null,
    site_b_site_contact_name: "",
    site_b_site_contact_phone: "",
    site_b_mounting_type: false,
    site_b_install_requirements: false,
    site_b_cli: false, // GEA only.
    with_voice: false,
    voice_service: false,
    voice_requirements: false,
    codec: false,
    traffic_prioritisation: false,
    concurrent_calls: false,
    bandwidth_allocation: false,
    // bandwidth_specifics: false,
    // technical_contact_name: false,
    // technical_contact_phone: false,
    // technical_contact_email: false,
    // secondary_technical_contact_name: false, // = Faults Contact. Wholesale only.
    // secondary_technical_contact_phone: false,
    // secondary_technical_contact_email: false,
    // reseller_contact_name: false, // Won't be needed hopefully. Wholesale only.
    // reseller_contact_phone: false,
    // reseller_contact_email: false,
    site_b_site_address_room: "",
    site_b_site_address_floor: "",
    site_b_site_address_company: "",
    site_b_site_address_building: false, // $address_from_dc->{thoroughfareNumber} || $address_from_dc->{premisesName};
    site_b_site_address_street: false, // $address_from_dc->{thoroughfareName};
    site_b_site_address_post_town: false, // $address_from_dc->{postTown};
    site_b_site_address_postcode: false, // $address_from_dc->{postcode};
    site_b_site_address_country: false, // $address_from_dc->{country};
    site_b_site_address_locale: false, // $address_from_dc->{locality};
    site_b_site_address_region: false, // $address_from_dc->{county};
    external_source_name: "Guided Sales",

    site_b_cabinet: false,
    site_b_shelf: false,

    // TODO: see if site A options need expanding in API
    // country & locale etc address fields etc + mounting type + install reqs
    // /trunk/lib/SelfService/ExternalServices/v1/LeasedLineQuotes.pm

    site_a_cabinet: false,
    site_a_shelf: false,
    site_a_mounting_type: "",

    //      # Options for P2P request
    //      product                      => false,
    site_a_site_address_building: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_company: "", // Auto populated from config or address organisationName
    site_a_site_address_country: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_locale: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_post_town: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_region: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_floor: "", // Location
    site_a_site_address_postcode: false, // Mapped from site_a_full_address in reducer
    site_a_site_address_room: "", // Location
    site_a_site_address_street: false, // Mapped from site_a_full_address in reducer

    site_a_site_contact_name: "", // SiteContact
    site_a_site_contact_phone: "", // SiteContact

    discount: 0.0,
  },
};

export default defaultConfig;
