import React from "react";
import { useSelector } from "react-redux";

import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import Grid from "@mui/material/Grid";

import { AccountSettings } from "@akj-dev/affinity-platform";

import { BulkDynamicField } from "../BulkDynamicField";
import { ArrayDynamicField } from "../ArrayDynamicField";
import { Section } from "../../../../../../shared/components/Section";
import { useCanAccessVfDirect } from "../../../../../../shared/hooks/useCanAccessVfDirect";
import { getAccountSettings } from "../../../../../../store/account/selectors";

export const AdvancedConfiguration = () => {
  const accountSettings = useSelector<any, AccountSettings>(getAccountSettings);
  const canAccessVfDirect = useCanAccessVfDirect();

  return (
    <Section
      title={`${canAccessVfDirect ? `Additional` : `Advanced`} Configuration`}
      avatar={<SettingsApplicationsIcon />}
    >
      {canAccessVfDirect ? (
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <BulkDynamicField
              propertyName="user_salutation"
              floatingLabelText="Title"
            />
          </Grid>
          <Grid item sm={4}>
            <ArrayDynamicField
              propertyName="user_first_name"
              floatingLabelText="First Name"
            />
          </Grid>
          <Grid item sm={4}>
            <ArrayDynamicField
              propertyName="user_last_name"
              floatingLabelText="Last Name"
            />
          </Grid>
        </Grid>
      ) : (
        <ArrayDynamicField
          propertyName="user_name"
          floatingLabelText="User Name"
        />
      )}
      {accountSettings.as_can_apply_wwcap === "1" && (
        <BulkDynamicField propertyName="wwcap_enabled" />
      )}
      {accountSettings.as_can_apply_bundle_notifications_opt_out === "1" && (
        <BulkDynamicField propertyName="bundle_notifications_opt_out" />
      )}
    </Section>
  );
};
