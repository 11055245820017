import { NoteAPIResponse } from "../../api/v1/notes";
import { DisplayError } from "../types";

export const SET_ERROR = "notes.SET_ERROR";
export const REQUEST_DATA = "notes.REQUEST_DATA";
export const INSERT_NOTES = "notes.SET_DATA";
export const INSERT_NOTE = "notes.INSERT_NOTE";
export const REQUEST_POST_NOTE = "notes.REQUEST_POST_NOTE";
export const SET_DRAFT_FORM_DATA = "notes.SET_DRAFT_FORM_DATA";
export const SET_SUBMIT_NOTE_ERROR = "notes.SET_DRAFT_SUBMIT_ERROR";

export type OrderNoteData = NoteAPIResponse;
export type NotesDisplayError = DisplayError & {
  [orderId: string]: string;
};
export type DraftNotesState = { [orderId: string]: string | undefined };

export interface NotesState {
  notes: { [orderId: string]: OrderNoteData[] | undefined };
  fetchingIds: string[];
  errors: NotesDisplayError[];
  draftNotes: DraftNotesState;
  submittingIds: string[];
}

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: { orderId: string; error: DisplayError };
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: string;
}

export interface SetDataAction {
  type: typeof INSERT_NOTES;
  payload: { orderId: string; notes: NoteAPIResponse[] };
}

export interface InsertNoteAction {
  type: typeof INSERT_NOTE;
  payload: { orderId: string; note: NoteAPIResponse };
}

export interface SetDraftSubmitErrorAction {
  type: typeof SET_SUBMIT_NOTE_ERROR;
  payload: { orderId: string; error: DisplayError };
}

export interface RequestPostNoteAction {
  type: typeof REQUEST_POST_NOTE;
  payload: { orderId: string };
}

export interface SetDraftNoteFormDataAction {
  type: typeof SET_DRAFT_FORM_DATA;
  payload: { orderId: string; content: string };
}

export type NotesActionTypes =
  | SetErrorAction
  | RequestDataAction
  | SetDataAction
  | InsertNoteAction
  | SetDraftSubmitErrorAction
  | RequestPostNoteAction
  | SetDraftNoteFormDataAction;
