import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ReduxAccountSettingsEnum,
  SaleStepEnum,
} from "../../../../../../shared/enums";
import { SupportCaseContainer } from "./SupportCase";
import { GenerateQuoteContainer } from "../Quote/GenerateQuote";
import { BulkOrderUpload } from "../../../../components/BulkOrderUpload/Dialog";
import { RenameDraft } from "../../../../components/Options/RenameDraft";
import { DeleteDraftModal } from "../../../../components/DeleteDraftModal";
import { OptionsMenu } from "../../../../components/Options";
import { clearState } from "../../../../../../store/_root/actions";
import { getAccountSettings } from "../../../../../../store/account/selectors";
import { resetOrderState } from "../../../../../../store/order/actions";
import {
  getAccountId,
  getBaseUrl,
  getLeadId,
  getOrderId,
  getOrderProductCallsFetching,
  getUploadedContractId,
} from "../../../../../../store/order/selectors";
import { getHostEnv } from "../../../../../../store/order/selectors/getHostEnv";
import { getPendingContractId } from "../../../../../../store/generateContract/selectors";
import { toggleOrderStatus } from "../../../../../../store/uiState/actions";
import { getSendQuoteButtonDisabled } from "../../../../../../store/uiState/selectors";
import {
  STEP_CHOOSE,
  STEP_SUMMARY,
} from "../../../../../../store/uiState/constants";

export interface OptionsProps {
  saveDraft: (newName?: string) => void;
}

export const Options = ({ saveDraft }: OptionsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [bulkOrderOpen, setBulkOrderOpen] = useState(false);
  const [deleteDraftOpen, setDeleteDraftOpen] = useState(false);
  const [renameDraftOpen, setRenameDraftOpen] = useState(false);
  const [generateQuoteOpen, setGenerateQuoteOpen] = useState(false);

  const activeStep = useSelector<any, SaleStepEnum>(
    (state) => state.uiState.activeStep
  );
  const accountSettings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);
  const orderProductCallsFetching = useSelector(getOrderProductCallsFetching);
  const orderStatusOpen = useSelector<any, boolean>(
    (state) => state.uiState.orderStatusOpen
  );
  const accountId = useSelector(getAccountId);
  const leadId = useSelector(getLeadId);
  const orderId = useSelector(getOrderId);
  const uploadedContractId = useSelector(getUploadedContractId);
  const pendingContractId = useSelector(getPendingContractId);
  const baseUrl = useSelector(getBaseUrl);
  const hostEnv = useSelector(getHostEnv);
  const sendQuoteDisabled = useSelector<any, boolean>(
    getSendQuoteButtonDisabled
  );

  const handleOpenBulkOrder = () => setBulkOrderOpen(true);

  const handleCloseBulkOrder = () => setBulkOrderOpen(false);

  const handleCloseRenameDraft = () => setRenameDraftOpen(false);

  const handleCloseGenerateQuote = () => setGenerateQuoteOpen(false);

  const handleOrderStatus = () => dispatch(toggleOrderStatus());

  // const handleRenameDraft = () => setRenameDraftOpen(true);

  const handleGenerateQuote = () => setGenerateQuoteOpen(true);

  const handleOpenDeleteDraft = () => setDeleteDraftOpen(true);

  const handleDeleteDraft = () => {
    if (hostEnv === "dc") {
      const url = `${baseUrl}CustomerService/AccountOverview/${accountId}`;
      window.location.href = url;
    } else {
      // clear root reducer
      dispatch(clearState());
      // clearing root reducer is not enough because config used by initial state will cache the order_id
      dispatch(resetOrderState());
      // Navigate up a level which will be where the CPQ launched from in all current scenarios
      history.push(".");
    }
  };

  const quoteDisabled =
    activeStep === STEP_CHOOSE ||
    activeStep === STEP_SUMMARY ||
    accountSettings.as_quote_generation_enabled === "0" ||
    orderProductCallsFetching ||
    sendQuoteDisabled ||
    (!leadId && !accountId);

  return (
    <>
      <OptionsMenu
        accountId={accountId}
        orderStatusOpen={orderStatusOpen}
        showDcOption={hostEnv === "dc"}
        showDeleteOption={!uploadedContractId && !pendingContractId}
        quoteDisabled={quoteDisabled}
        handleGenerateQuote={handleGenerateQuote}
        handleOpenBulkOrder={handleOpenBulkOrder}
        handleOpenDeleteDraft={handleOpenDeleteDraft}
        handleOrderStatus={handleOrderStatus}
        saveDraft={saveDraft}
      />

      <SupportCaseContainer />

      <BulkOrderUpload
        open={bulkOrderOpen}
        orderId={orderId}
        handleClose={handleCloseBulkOrder}
      />

      <RenameDraft
        open={renameDraftOpen}
        handleRename={saveDraft}
        handleClose={handleCloseRenameDraft}
      />

      <GenerateQuoteContainer
        open={generateQuoteOpen}
        handleClose={handleCloseGenerateQuote}
      />

      <DeleteDraftModal
        open={deleteDraftOpen}
        onClose={(reason) => {
          setDeleteDraftOpen(false);
          if (reason === "success") {
            handleDeleteDraft();
          }
        }}
        leadId={!accountId && leadId}
        accountId={accountId}
        orderId={orderId}
      />
    </>
  );
};
