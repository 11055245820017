import React from "react";

export function LogicMonitorIcon() {
  return (
    <svg width="1.5in" height="0.35in" viewBox="0 0 11042 2069">
      <path
        fill="#32394c"
        d="M8462 21l-191 23c-13,0 -23,10 -23,23l0 229c0,13 10,23 23,23l191 -23c13,0 23,-10 23,-23l0 -229c0,-13 -10,-23 -23,-23z"
      />
      <path
        fill="#32394c"
        d="M6602 427c-300,0 -472,160 -472,438l0 320c0,278 172,438 472,438 300,0 472,-160 472,-438l0 -320c0,-278 -172,-438 -472,-438zm236 765c0,148 -77,220 -236,220 -159,0 -236,-72 -236,-220l0 -334c0,-148 77,-220 236,-220 159,0 236,72 236,220l0 334z"
      />
      <path
        fill="#32394c"
        d="M7838 427c-131,0 -307,54 -428,108l-13 -67c-2,-11 -11,-18 -22,-18l-154 23c-13,0 -23,10 -23,23l0 1082c0,13 10,23 23,23l191 0c13,0 23,-10 23,-23l0 -821c111,-55 270,-102 347,-102 60,0 84,22 84,79l0 844c0,13 10,23 23,23l191 0c13,0 23,-10 23,-23l0 -846c0,-202 -89,-304 -263,-304z"
      />
      <path
        fill="#32394c"
        d="M11022 450c-128,6 -246,34 -360,85l-13 -67c-2,-11 -11,-18 -22,-18l-154 23c-13,0 -23,10 -23,23l0 1082c0,13 10,23 23,23l191 0c13,0 23,-10 23,-23l0 -821c102,-51 245,-81 336,-90 12,-1 23,-11 23,-24l0 -170c0,-13 -10,-23 -23,-22z"
      />
      <path
        fill="#32394c"
        d="M8462 427l-191 23c-13,0 -23,10 -23,23l0 1105c0,13 10,23 23,23l191 0c13,0 23,-10 23,-23l0 -1128c0,-13 -10,-23 -23,-23z"
      />
      <path
        fill="#32394c"
        d="M9298 1392c-5,-3 -12,-4 -18,-3 -72,19 -123,27 -171,27 -84,0 -91,-24 -91,-100l0 -663 275 0c12,0 21,-9 23,-20l16 -159c1,-6 -1,-13 -6,-17 -4,-5 -10,-7 -17,-7l-290 0 0 -277c0,-7 -3,-13 -8,-17 -5,-4 -12,-6 -18,-5l-191 27c-11,2 -20,11 -20,23l0 250 -172 0c-13,0 -23,10 -23,23l0 159c0,13 10,23 23,23l172 0 0 694c0,190 92,275 297,275 80,0 165,-15 239,-42 10,-4 16,-14 15,-25l-25 -147c-1,-6 -5,-12 -10,-15z"
      />
      <path
        fill="#32394c"
        d="M9846 427c-300,0 -472,160 -472,438l0 320c0,278 172,438 472,438 300,0 472,-160 472,-438l0 -320c0,-278 -172,-438 -472,-438zm236 765c0,148 -77,220 -236,220 -159,0 -236,-72 -236,-220l0 -334c0,-148 77,-220 236,-220 159,0 236,72 236,220l0 334z"
      />
      <path
        fill="#32394c"
        d="M5292 1809l265 -924c3,-12 9,-12 12,0l196 690c3,12 16,22 29,22l302 0c13,0 23,-10 23,-23l-45 -182c0,-13 -10,-23 -23,-23l-85 0c-13,0 -25,-10 -29,-22l-259 -912c-3,-12 -16,-22 -29,-22l-173 23c-13,0 -25,10 -29,22l-259 902c-3,12 -9,12 -12,0l-338 -1353c-3,-10 -12,-19 -23,-21l-11 0 -169 22c-13,0 -25,10 -28,22l-330 1317c-3,12 -23,22 -35,22l-70 0c-13,0 -23,10 -23,23l-45 182c0,13 10,23 23,23l300 0c13,0 25,-10 28,-22l264 -1053c3,-12 8,-12 11,0l332 1306c3,12 16,22 28,22l174 -21c13,0 25,-10 29,-22z"
      />
      <path
        fill="#4c76b9"
        d="M3195 21l-191 23c-13,0 -23,10 -23,23l0 229c0,13 10,23 23,23l191 -23c13,0 23,-10 23,-23l0 -229c0,-13 -10,-23 -23,-23z"
      />
      <path
        fill="#4c76b9"
        d="M218 43l-195 23c-13,0 -23,10 -23,23l0 1420c0,50 41,91 91,91l715 0c13,0 23,-10 23,-23l0 -175c0,-13 -10,-23 -23,-23l-520 0c-25,0 -45,-20 -45,-45l0 -1268c0,-13 -10,-23 -23,-23z"
      />
      <path
        fill="#4c76b9"
        d="M1341 1411c-159,0 -236,-72 -236,-220l0 -334c0,-148 77,-220 236,-220 159,0 236,72 236,220l0 334c0,148 -77,220 -236,220zm0 -985c-300,0 -472,160 -472,438l0 320c0,278 172,438 472,438 300,0 472,-160 472,-438l0 -320c0,-278 -172,-438 -472,-438z"
      />
      <path
        fill="#4c76b9"
        d="M2357 1076c-176,0 -229,-46 -229,-197l0 -48c0,-151 58,-202 229,-202 169,0 229,53 229,202l0 48c0,138 -69,197 -229,197zm0 -649c-305,0 -454,131 -454,402l0 50c0,143 48,251 139,316 -57,53 -89,122 -89,192 0,93 41,208 236,225 81,7 127,12 180,17 32,3 67,6 113,11 131,12 143,26 143,98 0,90 0,132 -286,132 -107,0 -247,-17 -365,-45 -2,0 -3,-1 -5,-1 -4,0 -9,1 -12,4 -5,3 -9,9 -10,15l-27 150c-2,12 5,23 16,26 126,34 304,53 408,53 393,0 506,-77 506,-343 0,-181 -89,-267 -297,-288 -71,-7 -121,-12 -169,-17 -45,-5 -88,-9 -145,-15 -48,-4 -52,-18 -52,-41 0,-34 30,-66 64,-94 32,4 68,6 107,6 294,0 456,-142 456,-399l0 -50c-2,-82 -18,-150 -47,-203l99 -19c11,-2 18,-11 18,-22l0 -111c0,-13 -10,-23 -23,-23l-314 0c-57,-17 -137,-23 -189,-23z"
      />
      <path
        fill="#4c76b9"
        d="M3195 427l-191 23c-13,0 -23,10 -23,23l0 1105c0,13 10,23 23,23l191 0c13,0 23,-10 23,-23l0 -1128c0,-13 -10,-23 -23,-23z"
      />
      <path
        fill="#4c76b9"
        d="M4040 1371c-72,0 -144,0 -218,0 -180,0 -232,-44 -232,-203l0 -323c0,-159 52,-205 232,-205 82,0 137,8 216,25 6,1 13,0 18,-4 5,-4 9,-10 9,-16l39 -159c1,-11 -5,-21 -16,-24 -64,-20 -178,-35 -271,-35 -325,0 -464,127 -464,425l0 348c0,221 53,400 460,400 57,0 122,0 169,-2 13,-1 25,-14 28,-26l46 -183c3,-12 -4,-18 -17,-18z"
      />
    </svg>
  );
}
