import React from "react";
import Chip from "@mui/material/Chip";
import { Trigger } from "../types";
import { Box } from "@mui/material";

type Props = {
  triggers: Array<Trigger>;
};

const Component: React.FunctionComponent<Props> = ({ triggers }) => {
  return (
    <Box sx={styles.root}>
      {triggers.map((trigger: Trigger) => (
        <Chip
          size="small"
          label={trigger.value}
          key={trigger.id}
          sx={styles.chip}
        />
      ))}
    </Box>
  );
};

const styles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: 0,
  },
  chip: {
    margin: 0.5,
  },
};

export default Component;
