import { getJSON } from "../helpers/core";

export const Result = (jobId: number) =>
  getJSON<APIJobResultResponse>(`APIJob/Result/${jobId}`, {}, false);

export type APIJobResultResponse = {
  status: string;
  request_data: any; // is it always an object? Who knows...
  received: string;
  id: number;
  response_data: unknown;
  elapsed: number;
};
