import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetUploadStatus,
  setDocumentsMeta,
  uploadDocuments,
} from "../../../../store/order/actions";
import { UploadUniversalContent } from "../../components/UploadUniversalDocuments/Content";

interface ContentProps {
  closeDialog: () => void;
}
export const UploadUniversalDocumentsContentContainer = ({
  closeDialog,
}: ContentProps) => {
  const dispatch = useDispatch();

  const uploadSending = useSelector(
    (state: any) => state.order.documentsUpload.sending
  );
  const uploadResponse = useSelector(
    (state: any) => state.order.documentsUpload.response
  );
  const files = useSelector((state: any) => state.order.documentsUpload.files);
  // const send_with_contract = useSelector(
  //   (state: any) => state.order.documentsUpload.send_with_contract
  // );

  return (
    <UploadUniversalContent
      files={files}
      uploadResponse={uploadResponse}
      uploadSending={uploadSending}
      closeDialog={closeDialog}
      onChangeMeta={(property, value) =>
        dispatch(setDocumentsMeta(property, value))
      }
      onResetUploadStatus={() => dispatch(resetUploadStatus())}
      onUploadDocuments={(send_with_contract: string) =>
        dispatch(uploadDocuments(send_with_contract))
      }
    />
  );
};
