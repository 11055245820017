import _ from "lodash";
import { isValidText, validatePostcode } from "./validation";

/**
 * Add space to postcode
 *
 * This is a validation requirement from DC.
 * It has been suggested by @davet this is to comply with CAS upload validation:
 * https://www.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
 *
 * @param postcode
 */
export const addSpaceToPostcode = (postcode) => {
  if (typeof postcode === "string") {
    return postcode.replace(/\s/g, "").replace(/^(.*)(.{3})$/, "$1 $2");
  }
  return false;
};

/**
 * Map the WLR3 address format that comes back from v1/AddressSearch/WLR3AddressSearch
 * to the internal DC order product format.
 * ...as in the one that exists on the order itself, but not necessarily the component.
 * See validation function below too.
 *
 * I'm presuming the endpoint follows the PAF format:
 * https://en.wikipedia.org/wiki/Postcode_Address_File
 * https://www.royalmail.com/sites/default/files/docs/pdf/programmers_guide_edition_7_v5.pdf
 * (the definitions above aren't quite the same though)
 *
 * Premises Elements
 *   Sub Building Name - subPremises
 *   Building Name - premisesName
 *   Building Number - thoroughfareNumber
 *   Organisation Name - organisationName
 *   PO Box - poBox
 * Thoroughfare Elements
 *   Dependent Thoroughfare Name - dependentThoroughfareName
 *   Dependent Thoroughfare Descriptor - ???
 *   Thoroughfare Name - thoroughfareName
 *   Thoroughfare Descriptor - ???
 * Locality Elements
 *   Double Dependent Locality - doubleDependentLocality
 *   Dependent Locality - locality
 *   Post town - postTown
 *   County - county
 * Postcode
 *   Postcode - postcode
 *
 * Then there's the extra fields that don't fit the above:
 *  - cssDatabaseCode
 *  - addressReference
 *  - exchangeGroupCode
 *  - addressCategory
 *
 *
 * @param wlr3Address
 * @param prefix - ethernet prefixes these fields.
 * @param suffix - ethernet can suffix these fields where previous company address history collected
 * @returns {{}}
 */
export function mapWLR3AddressToDCOrderProduct(
  wlr3Address,
  prefix = "",
  suffix = ""
) {
  return {
    // Required....
    [`${prefix}building${suffix}`]:
      wlr3Address.premisesName ||
      wlr3Address.thoroughfareNumber ||
      wlr3Address.subPremises ||
      wlr3Address.organisationName,
    [`${prefix}post_town${suffix}`]:
      wlr3Address.postTown || wlr3Address.locality,
    [`${prefix}postcode${suffix}`]: wlr3Address.postcode,
    [`${prefix}country${suffix}`]:
      wlr3Address.country ||
      (!_.isEmpty(wlr3Address) ? "United Kingdom" : undefined),

    // Required only for ethernet....
    [`${prefix}street${suffix}`]:
      wlr3Address.thoroughfareName || wlr3Address.dependentThoroughfareName,

    // Optional....
    [`${prefix}locale${suffix}`]: wlr3Address.locality,
    [`${prefix}region${suffix}`]: wlr3Address.county,
  };
}

/**
 * Validate DC formatted "order product configuration" address.
 * Matches rules applied in DC codebase at sub _address_configuration_fields
 *
 * site_address_building: 1,
 * site_address_street: 0,
 * site_address_locale: 0,
 * site_address_post_town: 1,
 * site_address_region: 0,
 * site_address_postcode: 1,
 * site_address_country: 1,

 * @param address
 * @returns boolean
 */
export function DCAddressIsValid(address) {
  return !!(
    address[`site_address_building`] &&
    address[`site_address_post_town`] &&
    address[`site_address_postcode`] &&
    address[`site_address_country`]
  );
}

/**
 * Used alongside the above function to check that the new address fields are valid
 * if/when adding a new address in the delivery section. Added as part of:
 * https://auroratarget.tpondemand.com/entity/11524-delivery-page-validation-checks
 *
 * @param name
 * @param value
 * @returns {object}
 */
export function getNewAddressFieldValidation(name, value) {
  switch (name) {
    case "building":
      return {
        required: true,
        message: "Must not be blank or contain special characters.",
        error: value && !isValidText(value),
      };
    case "post_town":
      return {
        required: true,
        message: "Must not be blank or contain special characters.",
        error: value && !isValidText(value),
      };
    case "postcode":
      return {
        required: true,
        message: "Must be a valid postcode.",
        error: value && !validatePostcode(value),
      };
    case "country":
      return {
        required: true,
        message: "Must not be blank or contain special characters.",
        error: value && !isValidText(value),
      };
    default:
      return {
        required: false,
        message: "Must not contain special characters.",
        error: value && !isValidText(value),
      };
  }
}

/**
 * Validate DC formatted ethernet address.
 * ... which, annoyingly, is different to the standard one,
 *
 * Example, low quality, address that doesn't validate:
 * Floor Gnd-Server Room, Unit 340, Bristol, BS161EJ
 *
 * @param address
 * @param prefix
 * @returns boolean
 */
export function getDCEthernetAddressValid(address, prefix) {
  return !!(
    address[`${prefix}site_address_building`] &&
    address[`${prefix}site_address_street`] &&
    address[`${prefix}site_address_post_town`] &&
    address[`${prefix}site_address_postcode`] &&
    address[`${prefix}site_address_country`]
  );
}

/**
 * Converts a WLR3 / SI formatted address into a single string.
 *
 * @param address
 * @returns {string}
 */
export function getAddressLine(address) {
  return _.compact([
    address["organisationName"],
    address["poBox"],
    address["subPremises"],
    address["premisesName"],
    _.compact([
      address["thoroughfareNumber"],
      address["thoroughfareName"],
    ]).join(" "),
    address["doubleDependentLocality"],
    address["locality"],
    address["postTown"],
    address["county"],
    address["postcode"],
  ]).join(", ");
}

/**
 * Converts DC order product format address fields to a string.
 * Used for ethernet address display on step 1
 * @param config
 * @param prefix
 * @returns {string}
 */
export function getDCAddressLine(config, prefix = "") {
  const el = (k) => config[`${prefix}site_address_${k}`];

  return _.compact([
    el("building"),
    el("street"),
    el("post_town"),
    el("locale"),
    el("region"),
    el("postcode"),
    el("country"),
  ]).join(", ");
}

/**
 * Used to map a product instance address to a location address.
 * Converting product instance and location addresses is a necessary part of the
 * resign process for lines and broadband. This is to ensure the correct address parameters are
 * being sent with the order.
 * @param address
 */
export function mapToLocationAddress(address) {
  return {
    addressReference: address.address_reference,
    cssDatabaseCode: address.css_database_code,
    postcode: address.post_code,
    postTown: address.post_town,
    thoroughfareName: address.thoroughfare,
    thoroughfareNumber: address.premises_number,
    premisesName: address.premises_name,
  };
}

/**
 * See above.
 * @param address
 */
export function mapToProductInstanceAddress(address) {
  return {
    address_reference: address.addressReference,
    css_database_code: address.cssDatabaseCode,
    post_code: address.postcode,
    post_town: address.postTown,
    thoroughfare: address.thoroughfareName,
    premises_number: address.thoroughfareNumber,
    premises_name: address.premisesName,
  };
}

/**
 * Determines whether we have all of the address fields we need to select a resign product and
 * proceed to the 'Customise' step.
 * @param productInstance
 * @param isPostcodeOnly
 */
export const hasProductInstanceAddress = (
  productInstance,
  isPostcodeOnly = false
) => {
  if (!productInstance || !productInstance.address) return false;
  const { address } = productInstance;
  if (isPostcodeOnly) return !!address.post_code;
  return (
    address.thoroughfare &&
    address.post_town &&
    address.post_code &&
    address.address_reference &&
    address.css_database_code
  );
};

/**
 * See above.
 * @param productInstance
 * @param isPostcodeOnly
 */
export const hasLocationAddress = ({ location, isPostcodeOnly = false }) => {
  if (!location || !location.address) {
    return false;
  }

  const { address } = location;

  if (isPostcodeOnly) {
    return !!address.postcode;
  }
  return (
    address.postTown &&
    address.postcode &&
    address.addressReference &&
    address.cssDatabaseCode
  );
};

/**
 * Maps and outputs certain fields from a given object to the correct format.
 * Used for the more information popover for wlr resigns step 1.
 * @param object
 * @returns {{description: string, price: *}}
 */
export function mapAndFormatCallBundleOrSelectServices(object) {
  let description =
    Object.values(object)
      .map((item) => item.service_description)
      .join(", ") || null;
  let price =
    Object.values(object)
      .map((item) => item.price)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) || null;
  return { description, price };
}
