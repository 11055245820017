import React from "react";
import { useSelector } from "react-redux";

import { ConfigTableContainer } from "./ConfigTable";
import { ConfigFormContainer } from "./ConfigForm";

export const UniversalProducts = () => {
  const selectedConfigIndex = useSelector<any, number | boolean>(
    (state) => state.universalProducts.selectedConfigIndex
  );

  return (
    <>
      <h2>Configure your product(s)</h2>
      {selectedConfigIndex === false ? (
        <ConfigTableContainer />
      ) : (
        <ConfigFormContainer />
      )}
    </>
  );
};
