import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import { ExpandingCard } from "@akj-dev/shared-components";
import { Grid } from "@mui/material";

import FormTextField from "../../containers/Ethernet/EthernetOrderForm/FormTextField";

interface SiteContactProps {
  expanded: boolean;
  index: number;
  prefix: string;
  handleHeaderClick: () => void;
}

export const SiteContact = ({
  expanded,
  prefix,
  index,
  handleHeaderClick,
}: SiteContactProps) => (
  <ExpandingCard
    title="Site Contact"
    avatar={<PersonIcon />}
    expanded={expanded}
    onHeaderClick={handleHeaderClick}
  >
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <FormTextField
          label="End User Company Name *"
          param={prefix + "_site_address_company"}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormTextField
          label="Contact Name *"
          param={prefix + "_site_contact_name"}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormTextField
          label="Contact Phone *"
          param={prefix + "_site_contact_phone"}
          index={index}
          errorText="A valid phone number is required"
        />
      </Grid>
    </Grid>
  </ExpandingCard>
);
