import React from "react";
import _ from "lodash";

import { StatusChip } from "@akj-dev/shared-components";

import { SparePairs } from "../../components/LineProducts/SparePairs";
import { WlrLocationProduct, WlrLocationProductConfig } from "../../types";

interface NewLineSectionProps {
  locationIndex: number;
  lineAvailability: Record<string, any>;
  lineSearch: Record<string, any>;
  mpfProducts: WlrLocationProduct[];
  singleLineProducts: WlrLocationProduct[];
  multiLineProducts: WlrLocationProduct[];
  configurations: WlrLocationProductConfig[];
  requiredSparePairs: number;
}

export const NewLineSection = ({
  locationIndex,
  lineAvailability,
  lineSearch,
  mpfProducts,
  singleLineProducts,
  multiLineProducts,
  configurations,
  requiredSparePairs,
}: NewLineSectionProps) => {
  const sparePairs = _.get(lineAvailability.response, "dp_spare_pairs");
  const products = _.get(lineSearch.response, "products");

  const lineProducts = [...(products ?? []), ...mpfProducts];
  const dcProducts = [
    singleLineProducts?.[0],
    multiLineProducts?.[0],
    ...mpfProducts,
  ].filter((p) => !!p);

  if (sparePairs && (lineProducts.length || dcProducts.length)) {
    return (
      <SparePairs
        remainingSparePairs={sparePairs - requiredSparePairs}
        dcProducts={dcProducts}
        lineProducts={lineProducts}
        locationIndex={locationIndex}
        configurations={configurations}
      />
    );
  } else {
    if (!sparePairs && !_.isEmpty(lineAvailability.response)) {
      return (
        <StatusChip
          type="error"
          title="No available lines found at this address."
          message="This order cannot be completed via Guided sales, please place the order manually. Note: Lead times maybe increased due to no infrastructure in the premises."
        />
      );
    }
  }

  return null;
};
