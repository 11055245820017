import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateEthernetCustomerData } from "../../../../../store/ethernetProducts/actions";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../store/ethernetProducts/selectors";
import { FormSelectField } from "../../../components/Ethernet/FormSelectField";

interface FormSelectFieldProps {
  index: number;
  helperText: string;
  param: string;
  options: Record<string, any>;
}

export const FormSelectFieldContainer = ({
  index,
  helperText,
  param,
  options,
}: FormSelectFieldProps) => {
  const dispatch = useDispatch();

  const configurations = useSelector(
    (state: any) => state.ethernetProducts.configurations
  );
  const errors = useSelector((state: any) =>
    ethernetPurchaseArgsValidationErrors(state, index)
  );
  const showError = useSelector<any, boolean>((state) =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const configuration = configurations[index];
  const hasError = errors.includes(param);

  return (
    <FormSelectField
      helperText={helperText}
      isError={showError && hasError}
      value={configuration.purchaseArgs[param] || "0"}
      disabled={configuration.purchaseIsRequesting}
      options={options}
      onChange={(event) =>
        dispatch(updateEthernetCustomerData(index, param, event.target.value))
      }
    />
  );
};
