import React from "react";
import { Button, Dialog, DialogTitle, Box } from "@mui/material";
import { ReactNode } from "react";

interface UploadSupportingDocumentsProps {
  openDialog: () => void;
  closeDialog: () => void;
  showDialog: boolean;
  isUploadButtonDisabled: boolean;
  contentChildren: ReactNode;
}

export const UploadSupportingDocuments = ({
  showDialog,
  isUploadButtonDisabled,
  contentChildren,
  openDialog,
  closeDialog,
}: UploadSupportingDocumentsProps) => (
  <>
    <Dialog open={showDialog} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Supporting Documents</DialogTitle>
      {contentChildren}
    </Dialog>
    <Box my={1}>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: 20 }}
        onClick={openDialog}
        disabled={isUploadButtonDisabled}
      >
        Upload Supporting Documents
      </Button>
    </Box>
  </>
);
