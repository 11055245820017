import React, { useMemo, useState } from "react";
import { pick, flattenDeep } from "lodash";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import { convertToLabelFromUnderscore } from "../../../../shared/utils/convertToLabelFromUnderscore";
import { formatCurrency } from "../../../../shared/utils/formatCurrency";
import { VFDirectBoltOn } from "api/v1/product";
import { useSelector } from "react-redux";
import { DeviceHub } from "@mui/icons-material";

interface CliBoltOnSlotSelectFieldProps {
  options: VFDirectBoltOn[];
  targetConfigs: number[];
  slotId: string;
  selectedOptionIds: (string | 0)[];
  onChooseCliBoltOn: (
    configId: number,
    boltOnType: string | undefined,
    index: string | number,
    slotId: string
  ) => void;
}

type SelectedBoltOn = {
  id: string;
  type: string;
};

export const CliBoltOnSlotSelectField = ({
  slotId,
  options,
  targetConfigs,
  selectedOptionIds,
  onChooseCliBoltOn,
}: CliBoltOnSlotSelectFieldProps) => {
  const classes = useStyles();
  const mobileConfigs = useSelector((state: any) => state.mobile.configs);
  const selectedMobileConfigs = Object.values(
    pick(mobileConfigs, ...targetConfigs)
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogSelectedOption, setDialogSelectedOption] =
    useState<VFDirectBoltOn | null>(null);

  const selectedOption = useMemo(
    () => options.find((option) => selectedOptionIds.includes(option.id)),
    [options, selectedOptionIds]
  );

  const allSameBoltOn = useMemo(
    () => selectedOptionIds.every((val, i, arr) => val === arr[0]),
    [selectedOptionIds]
  );

  const optionLabel = useMemo(() => {
    if (!allSameBoltOn) {
      return (
        <Chip
          label="Multiple"
          variant="filled"
          color="default"
          icon={<DeviceHub />}
        />
      );
    } else if (selectedOption) {
      return getOptionLabel(selectedOption);
    }
  }, [selectedOption, allSameBoltOn]);

  // TP60247.

  const selectedBoltOns: SelectedBoltOn[] = flattenDeep(
    selectedMobileConfigs.map((mobileConfig) =>
      Object.values(mobileConfig.selectedCliBoltOns || {})
    )
  );

  const hasSelectedSpendCap = selectedBoltOns.find(
    (selectedBoltOn) => selectedBoltOn.type === "spend_caps"
  );
  const hasSelectedDataCap = selectedBoltOns.find(
    (selectedBoltOn) => selectedBoltOn.type === "data_caps"
  );

  return (
    <>
      <Box display="flex" marginBottom={1} alignItems="center">
        <Box>VAS {slotId}</Box>
        <Box mx={2}>
          <Typography
            variant="body2"
            color={selectedOption ? "textPrimary" : "textSecondary"}
          >
            {selectedOption ? optionLabel : "None Selected"}
          </Typography>
        </Box>

        <Box marginLeft="auto">
          {selectedOption ? (
            <Box>
              <Button
                size="small"
                color="error"
                onClick={() => {
                  const boltOnType =
                    dialogSelectedOption?.first_mobile_bolt_on_component
                      ?.bolt_on_type;
                  // deselect the bolton by passing id for this slot "0"
                  // onChooseCliBoltOn(configId, boltOnType, 0, slotId);
                  targetConfigs.forEach((configId) =>
                    onChooseCliBoltOn(configId, boltOnType, 0, slotId)
                  );
                }}
              >
                {!allSameBoltOn ? "Remove All" : "Remove"}
              </Button>
            </Box>
          ) : (
            <Button
              data-cy="BoltOnSlotSelect"
              size="small"
              variant="outlined"
              onClick={() => setDialogOpen(true)}
            >
              Select
            </Button>
          )}
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby={`select vas ${slotId} dialog`}
        maxWidth="lg"
      >
        <DialogTitle id={`select vas ${slotId} dialog`}>
          Select VAS {slotId}
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Autocomplete
            classes={{
              listbox: classes.listbox,
              noOptions: classes.listbox,
              paper: classes.paper,
            }}
            forcePopupIcon={false}
            open={true}
            fullWidth
            PaperComponent={(params: any) => (
              <Paper {...params} variant="outlined" />
            )}
            PopperComponent={(params) => <div {...params} />}
            getOptionLabel={(option) => getOptionLabel(option)}
            renderOption={(liProps, option) => (
              <li {...liProps}>
                <Typography
                  variant="body2"
                  className={classes.renderOptionItem}
                >
                  {convertToLabelFromUnderscore(
                    option.first_mobile_bolt_on_component.bolt_on_type
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.renderOptionItem}
                >
                  {option.first_mobile_bolt_on_component.soc_code}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.renderOptionItem}
                >
                  {option.name}
                </Typography>
                <Typography
                  align="right"
                  variant="body2"
                  className={classes.renderOptionItem}
                >
                  {formatCurrency(
                    parseFloat(
                      option.price.first_bill_recurring_price_with_promotions
                    )
                  )}
                </Typography>
              </li>
            )}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                label="Search VAS"
              />
            )}
            onChange={(event, option) => {
              setDialogSelectedOption(option || null);
            }}
            // TP60247.
            getOptionDisabled={(option) =>
              (!!hasSelectedSpendCap &&
                option.first_mobile_bolt_on_component.bolt_on_type ===
                  "spend_caps") ||
              (!!hasSelectedDataCap &&
                option.first_mobile_bolt_on_component.bolt_on_type ===
                  "data_caps")
            }
          />
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialogActions,
          }}
        >
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button
            data-cy="BoltOnDialogSelect"
            variant="contained"
            color="primary"
            disabled={!dialogSelectedOption}
            onClick={() => {
              if (dialogSelectedOption) {
                const boltOnType =
                  dialogSelectedOption.first_mobile_bolt_on_component
                    ?.bolt_on_type;
                targetConfigs.forEach((configId) =>
                  onChooseCliBoltOn(
                    configId,
                    boltOnType,
                    dialogSelectedOption.id,
                    slotId
                  )
                );
              }
              setDialogOpen(false);
            }}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const getOptionLabel = (option: VFDirectBoltOn) =>
  `${convertToLabelFromUnderscore(
    option.first_mobile_bolt_on_component.bolt_on_type
  )} - ${option.first_mobile_bolt_on_component.soc_code} - ${
    option.name
  } - ${formatCurrency(
    parseFloat(option.price.first_bill_recurring_price_with_promotions)
  )}`;

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 800,
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  listbox: {
    height: "40vh",
  },
  renderOptionItem: {
    width: "25%",
  },
  dialogActions: {
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
}));
