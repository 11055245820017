import React, { ReactNode } from "react";
import { Collapse, TableCell, TableRow, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface CollapseRowProps {
  visible: boolean;
  children: ReactNode;
}

export const CollapseRow = ({ visible, children }: CollapseRowProps) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.collapseRow} colSpan={3}>
        <Collapse in={visible} unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  collapseRow: {
    padding: 0,
    border: "none",
  },
}));
