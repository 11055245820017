import reducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import saga from "./saga";
import { createMiddleware as createBeaconMiddleware } from "redux-beacon";
import GoogleAnalytics from "@redux-beacon/google-analytics";
import gaEventsMap from "./gaEventsMap";
import { configureStore } from "@reduxjs/toolkit";

const ga = GoogleAnalytics();
const beaconMiddleware = createBeaconMiddleware(gaEventsMap, ga);

export default function createStore(initialState: any) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, beaconMiddleware];

  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (defaultMiddleware) =>
      defaultMiddleware({ immutableCheck: false }).concat(middleware),
  });

  sagaMiddleware.run(saga);

  return store;
}
