import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatusChip } from "@akj-dev/shared-components";
import { RemoteValidationErrorsContainer } from "../../../../../StepCustomise/containers/wlrBroadband/ConfigurationForm/RemoteValidationErrors";
import { doRemoteValidation } from "../../../../../../store/wlrBroadband/actions/configurations";

export const QuoteWLRValidationErrorsContainer = () => {
  const dispatch = useDispatch();

  const configs = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  useEffect(() => {
    for (let i = 0; i < configs.length; i++) {
      dispatch(doRemoteValidation({ configurationIndex: i, quoteOnly: true }));
    }
  }, [dispatch, configs.length]);

  // Check if any validation requests are fetching:
  let fetching = false;

  configs.forEach((c: any) => {
    if (c.wlrValidation && c.wlrValidation.fetching) fetching = true;
    if (c.wlrValidationQuote && c.wlrValidationQuote.fetching) fetching = true;
    if (c.broadbandValidation && c.broadbandValidation.fetching)
      fetching = true;
  });

  return (
    <div>
      {fetching ? (
        <StatusChip
          type="loading"
          title="Validating WLR & Broadband configurations."
          message="Please wait..."
        />
      ) : (
        Array(configs.length)
          .fill(undefined)
          .map((e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <RemoteValidationErrorsContainer targetConfig={i} key={i} />
          ))
      )}
    </div>
  );
};
