import { kongAxios } from "../../../core/kongAxios";

/**
 * Address search by postcode
 *
 * Copied from packages/affinity-sales/src/api/v1/addressSearch.ts
 *
 * @param postcode
 * @param include_uprn - a more precise address identifier used by Virgin
 * @param account
 * @returns {Promise<*>}
 * @constructor
 */
export const wlr3AddressSearch = (
  postcode: string,
  include_uprn: boolean,
  account?: string
) =>
  kongAxios.get<WLR3AddressSearchResponse>(
    "/ExternalServices/v1/AddressSearch/WLR3AddressSearch",
    {
      params: { postcode, include_uprn: include_uprn ? "1" : "0", account },
    }
  );

export type WLR3Address = {
  organisationName: string | null;
  premisesName: string | null;
  subPremises: string | null;
  thoroughfareNumber: string | null;
  dependentThoroughfareName: string | null;
  thoroughfareName: string | null;
  doubleDependentLocality: string | null;
  locality: string | null;
  postTown: string | null;
  county: string | null;
  country: string;
  postcode: string;
  poBox: string | null;
  addressCategory: "GOLD" | "SILVER" | "BRONZE";
  exchangeGroupCode: string | null;
  addressReference: string;
  cssDatabaseCode: string; // enum?
  UPRNs?: UPRN[];
  listOfTechnologies: {
    AddressTechnology: string; // enum?
  };
};

export type UPRN = {
  address: string;
  uprn: string;
};

export type WLR3AddressSearchResponse = {
  message?: string;
  addressMatches?: WLR3Address[];
  addresses?: {
    Address: WLR3Address[]; // why there are two, I'm not sure...
  };
  status: string;
};

/**
 * Available broadband products at an address
 * Also see similar in packages/affinity-sales/src/api/v1/wlrBroadband.js
 *
 * TODO: Should we have the DC API fns as a separate package now Orders and Sales are sharing endpoints?
 */
export const broadbandSearch = (
  account: string, // necessary to check wlr_change_possible according to @mike
  address_reference: string,
  css_database_code: string,
  postcode: string,
  cli: string
) =>
  kongAxios.get<BroadbandSearchResponse>(
    `/ExternalServices/v1/Product/BroadbandSearch`,
    {
      params: {
        account,
        guided_sales: 1,
        standard_lead_times: 1,
        address_reference,
        css_database_code,
        postcode,
        cli,
        // All Affinity Orders customers will have 1 month contracts apparently.
        // see https://auroratarget.tpondemand.com/entity/36005-6-hide-contract-length-in-cpq
        contract_length_in_months: 1,
        rates_in_mbps: 1,
      },
    }
  );

export type BroadbandSearchResponse = {
  availability_check_id: number;
  availability_message?: string; // error message from SI
  code?: string; // error code
  message: string; // error message from DC
  broadband_rates: {
    Business: BroadbandRateCard;
    Residential: BroadbandRateCard;
    None: BroadbandRateCard;
  };
  exchange_details: {
    cease_date: string;
    cp_name: string;
    exchange_can_llu: string;
    exchange_code: string;
    exchange_name: string;
    exchange_state: string;
    market_code: string;
    reason_code: string;
  };
  mpf_response_details: {
    error_message: null | string; // TODO: No idea if this is right...
    mpf_available: 1 | 0;
  };
  ont_details: {
    mdu_build_complete: "0" | "1";
    mdu_status: string;
    ont_floor: string;
    /* Min lead time. eg. "eg. 9 working days" */
    ont_minimum_lead_time: string;
    ont_port_list: null | unknown; // TODO: What can this be?
    ont_position: string;
    ont_reference: string;
    ont_room: string;
    ont_spare_data_ports: null | string;
    ont_type: "NEW" | string; // Is this an enum?
    ont_working_data_ports: null | string; // what's this...
    tma_marker: string;
    tma_marker_present: string;
    tma_notice_or_permit: "Notice" | string; // is this an enum?
    tma_notice_required: "0" | "1";
    tma_permit_required: "0" | "1";
  };
  original_query_params: {
    account: string;
    address_reference: string;
    contract_length_in_months: string;
    css_database_code: string;
    guided_sales: "1" | "0";
    has_broadband: "1" | "0";
    postcode: string;
    standard_lead_times: "1" | "0";
  };
  products: DCProduct[];
  status: "success" | "error" | string;
  tags_response_details: {
    error_code: string;
    postcode: string;
    tags_values: {
      classic: {
        // TODO: do all these tags always get returned?
        fixed_rate: string;
        max_speed: string;
        rate_adaptive: string;
        reason_code: string;
      };
      fttc: BBTagsLong;
      fttc_gfast: BBTagsLong;
      max: BBTagsShort;
      plus: BBTagsShort & {
        annexm: {
          max_range: string;
          max_speed_down: string;
          max_speed_up: string;
          min_range: string;
          rate_adaptive: string;
        };
      };
      sogea: BBTagsLong;
      sogfast: BBTagsLong;
    };
  };
  total: number;
};

export type BroadbandRateCard = {
  /* supplier_product_ref */
  [key: string]: {
    /* supplier_product_subtype */
    [key: string]: BroadbandSpeedStats;
  };
};

// TODO: these won't always all be returned. Wait for OpenAPI though...
export type BroadbandSpeedStats = {
  down_speed_estimate: string;
  estimated_download_range: string;
  estimated_upload_range: string;
  max_downspeed: number;
  max_range: string;
  max_upspeed: string;
  min_range: string;
  min_threshold: string;
  minimum_guaranteed_speed: string;
  observed_speeds_available: number;
  throughput_percentage: string;
  up_speed_estimate: string;
  range_a_top_upstream: string;
  range_b_bottom_upstream: string;
  range_a_top_downstream: string;
  range_b_bottom_downstream: string;
};

export type DCProduct = {
  available_pricing_schemes: string[];
  code: string;
  components: DCProductComponent[];
  description: "TalkTalk MPF Premium";
  end_user_price_list: null | unknown;
  end_user_service_tariff_id: null | unknown;
  first_broadband_component: DCProductComponent;
  first_hardware_component: DCProductComponent | {};
  first_line_component: DCProductComponent | {};
  first_mobile_bolt_on_component: DCProductComponent | {};
  first_mobile_component: DCProductComponent | {};
  id: number;
  is_account_level: 0 | 1;
  is_lir: 0 | 1;
  minimum_contract_length_in_months: number;
  name: string;
  original_id: number;
  original_name: number;
  policy_type: null | unknown;
  price: {
    first_bill_recurring_price_with_promotions: string;
    one_off_price_with_promotions: string;
  };
  pricing_rule_id: number;
  pricing_rule_name: string;
  pricing_scheme: string;
  pricing_scheme_details: {
    [key: string]: DCPricingScheme;
  };
  product_category: {
    id: number;
    label: null | string;
    name: string;
  };
  recurring_price_without_promotions: string; // why is this on the root? who knows...
};

export type DCProductComponent = {
  acquisition_method: null | string;
  activation_code?: string;
  down_speed_estimate?: string;
  estimated_download_range?: string;
  estimated_lead_time?: {
    /* date YYYY-M-DD */
    appointment: string;
    lead_time: string;
  };
  estimated_upload_range?: string;
  id: number;
  is_resign: null | unknown;
  market_code?: string;
  max_download_speed_m: string;
  max_downspeed?: number;
  max_range?: string;
  max_upload_speed_m: string;
  max_upspeed?: number;
  min_range?: string;
  min_threshold?: string;
  minimum_guaranteed_speed?: string;
  module: string;
  product_id: number;
  service_speed?: string;
  site_type: string;
  supplier: string;
  supplier_product_ref: string;
  supplier_product_subtype: string;
  type: string;
  up_speed_estimate: string;
};

type DCPricingScheme = {
  allow_custom_discount: "0" | "1" | 0 | 1; // DC weirdness...
  first_bill_recurring_price_with_promotions: string;
  is_hardware_only: 0 | 1;
  is_leasing: 0 | 1;
  maximum_one_off_discount_percent: number;
  maximum_recurring_discount_percent: number;
  minimum_one_off_price: null | string;
  minimum_recurring_price: null | string;
  one_off_price_with_promotions: string;
  one_off_price_without_discounts: number;
  one_off_price_without_promotions: string;
  prices_per_month_over_length_of_contract: string[];
  pricing_scheme: string;
  pricing_scheme_name: string;
  pricing_scheme_priority: number;
  promotion_id: null | string;
  promotion_length_in_months: null | number;
  promotion_name: null | string;
  recurring_price_without_discounts: string;
  recurring_price_without_promotions: string;
  residual_value: 0 | 1;
  total_recurring_price_for_length_of_contract: string;
};

export type BBTagsShort = {
  // TODO: Is there a better name?
  max_range: string;
  max_speed: string;
  min_range: string;
  rate_adaptive: string;
};
export type BBTagsLong = {
  dlm_policy: string;
  downstream_dlm_profile_cap: string;
  downstream_product_cap: string;
  last_observed_date: string;
  lower_downstream_observed_speed: string;
  lower_upstream_observed_speed: string;
  range_a_base_upstream_bandwidth: string;
  range_a_bottom_downstream: string;
  range_a_bottom_upstream: string;
  range_a_downstream_handback_threshold: string;
  range_a_top_downstream: string;
  range_a_top_upstream: string;
  range_b_base_upstream_bandwidth: string;
  range_b_bottom_downstream: string;
  range_b_bottom_upstream: string;
  range_b_downstream_handback_threshold: string;
  range_b_top_downstream: string;
  range_b_top_upstream: string;
  rate_adaptive: string;
  upper_downstream_observed_speed: string;
  upper_upstream_observed_speed: string;
  upstream_dlm_profile_cap: string;
  upstream_product_cap: string;
};
