import { Close, Visibility } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material";
import React from "react";

const styles = {
  paper: { minWidth: "1000px" },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "grey.500",
  },
};

export const ViewMessage: React.FC<{ title: string }> = ({
  children,
  title,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  return children ? (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            sx={styles.closeButton}
            onClick={handleClose}
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Link
        component="button"
        variant="body2"
        onClick={() => setOpen(true)}
        underline="hover"
      >
        <Visibility
          // vertically centering svg in buttons makes me sad :-(
          style={{ marginBottom: "-5px" }}
          fontSize="small"
        />
        View
      </Link>
    </>
  ) : null;
};
