import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import DateIcon from "@mui/icons-material/DateRange";
import { StatusChip } from "@akj-dev/shared-components";

import {
  getRequiresBBAppointment,
  getRequiresWLRAppointment,
} from "../../../../../../../store/wlrBroadband/selectors";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { Section } from "../../../../../../../shared/components/Section";

import { ChooseBroadbandAppointmentContainer } from "./ChooseBroadbandAppointment";
import { ChooseWlrAppointmentContainer } from "./ChooseWlrAppointment";

export const AppointmentContainer = () => {
  const { targetConfigs } = useTargetConfigsContext();

  const locationIndex = useSelector<any, number>(
    (state) => state.wlrBroadband.configurations[targetConfigs[0]].locationIndex
  );

  const requiresWLRAppointment = useSelector((state) =>
    getRequiresWLRAppointment(state, { targetConfigs })
  );
  const requiresBBAppointment = useSelector((state) =>
    getRequiresBBAppointment(state, { targetConfigs })
  );
  const sparePairs = useSelector((state: any) =>
    _.get(
      state.wlrBroadband.locations[locationIndex].lineAvailability.response,
      "dp_spare_pairs"
    )
  );

  /**
   WLR:
    appointment_date
    appointment_expiry
    appointment_reference
    appointment_timeslot

    BB:
    bb.appointment_expiry
    bb.appointment.accessHazards
    bb.appointment.appointmentNotes
    bb.appointment.appointmentReference
    and maybe....
    bb.appointment.contact.contactEmail
    bb.appointment.contact.contactForename
    bb.appointment.contact.contactSurname
    bb.appointment.contact.contactTelephone
    bb.appointment.contactPassword

  */

  if (requiresWLRAppointment || requiresBBAppointment) {
    if (targetConfigs.length < 2) {
      return (
        <Section title="Appointment" avatar={<DateIcon />}>
          {requiresWLRAppointment && (
            <ChooseWlrAppointmentContainer targetConfig={targetConfigs[0]} />
          )}
          {requiresBBAppointment && (
            <ChooseBroadbandAppointmentContainer
              targetConfig={targetConfigs[0]}
            />
          )}
          {sparePairs == 0 && ( // eslint-disable-line eqeqeq
            <StatusChip
              type="info"
              title="No spare pairs available."
              message="Do not confirm appointment time with customer."
            />
          )}
        </Section>
      );
    }

    return (
      <StatusChip
        type="info"
        title="Appointments cannot be bulk edited."
        message="Please choose them per line."
      />
    );
  }

  return null;
};
