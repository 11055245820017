import React, { ReactNode } from "react";
import Checkbox from "@mui/material/Checkbox";
import { TableScrollWrap } from "../../../../shared/components/TableScrollWrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface ResignConfigTableOuterProps {
  checked: boolean;
  showCommissionType: boolean;
  toggleAll: () => void;
  children: ReactNode;
}

export const ResignConfigTableOuter = ({
  checked,
  showCommissionType,
  toggleAll,
  children,
}: ResignConfigTableOuterProps) => (
  <TableScrollWrap>
    <Table size="small" data-cy="wlrBroadbandStep2ResignsTable">
      <TableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              color="secondary"
              checked={checked}
              onChange={toggleAll}
              data-cy="wlrBroadbandResignToggleAll"
            />
          </TableCell>
          <TableCell>CLI</TableCell>
          <TableCell />
          <TableCell align="left">Product</TableCell>
          <TableCell align="center">Price</TableCell>
          <TableCell align="center">Resign Type</TableCell>
          {showCommissionType && (
            <TableCell align="center">
              <p>Commission</p>
              <p>Up Front &nbsp; Residual </p>
            </TableCell>
          )}
          <TableCell align="center">Configured?</TableCell>
          <TableCell align="right">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  </TableScrollWrap>
);
