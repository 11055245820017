import {
  DetailState,
  DetailActionTypes,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
  OrderDetail,
  OrderComponent,
} from "./types";
import { ViewAPIResponse } from "../../api/v1/view";
import { toDateString } from "../../helpers/dates";

const initialState: DetailState = {
  fetching: false,
  error: undefined,
  data: undefined,
};
export function detailReducer(
  state = initialState,
  action: DetailActionTypes
): DetailState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        fetching: true,
      };
    case SET_ERROR:
      return {
        error: action.payload,
        fetching: false,
      };
    case SET_DATA:
      return {
        error: undefined,
        fetching: false,
        data: mapViewAPIResponseToOrderDetail(action.payload),
      };

    default:
      return state;
  }
}

function mapViewAPIResponseToOrderDetail(
  apiResponse: ViewAPIResponse
): OrderDetail {
  // convert dates in the API response to DateStrings or clear the invalid strings.

  if (!apiResponse.components) {
    return { ...apiResponse, components: undefined };
  }

  let components: OrderComponent[] = apiResponse.components.map((component) => {
    try {
      return {
        ...component,
        appointment_date: component.appointment_date
          ? toDateString(component.appointment_date)
          : undefined,
        forecast_date: component.forecast_date
          ? toDateString(component.forecast_date)
          : undefined,
        submitted_date: component.submitted_date
          ? toDateString(component.submitted_date)
          : undefined,
        port_date: component.port_date
          ? toDateString(component.port_date)
          : undefined,
      };
    } catch (e) {
      console.warn(e);
      // The API has passed invalid dates so make the relevant fields undefined.
      return {
        ...component,
        appointment_date: undefined,
        forecast_date: undefined,
        submitted_date: undefined,
        port_date: undefined,
      };
    }
  });
  return { ...apiResponse, components };
}
