import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import { FileIcon } from "./FileIcon";

interface DocumentChipProps {
  filename: string;
  href: string;
}

export const DocumentChip = ({ filename, href }: DocumentChipProps) => (
  <Paper>
    <Container href={href} target="_blank">
      <IconOuter>
        <FileIcon filename={filename} />
      </IconOuter>
      {filename}
    </Container>
  </Paper>
);

DocumentChip.propTypes = {
  filename: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

const Container = styled.a`
  display: flex;
  align-items: center;
`;

const IconOuter = styled.div`
  width: 70px;
  padding: 10px;
`;
