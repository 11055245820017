import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useAuth } from "@akj-dev/affinity-platform";
import {
  ProductContainer,
  ViewContainer,
  ContentContainer,
  SwitchRoutes,
  StandaloneAppWrapper,
  BrandedDocumentTitle,
  SubNav,
} from "@akj-dev/shared-components";
import NewNotification from "./screens/Notifications/Create";
import { ReduxProvider } from "./store/ReduxProvider";
import { getRoutes, ROOT_PATH } from "./routes";

const queryClient = new QueryClient();

const AppStandaloneComponent = () => {
  const accountSettings = useAuth().accountSettings;
  const routes = getRoutes(accountSettings);
  const { pathname } = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider>
        <ProductContainer>
          <BrandedDocumentTitle title="Orders" />
          {pathname.includes(routes.rootPath) && (
            <SubNav routes={routes} rootTitle="Orders" />
          )}
          <ViewContainer>
            <ContentContainer>
              <Switch>
                <Route path={routes.rootPath + "/new-notification"}>
                  <NewNotification />
                </Route>
                <SwitchRoutes routes={routes} />
              </Switch>
            </ContentContainer>
          </ViewContainer>
        </ProductContainer>
      </ReduxProvider>
      {process.env.NODE_ENV !== "production" && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};
export const AppStandalone = () => (
  <StandaloneAppWrapper root={ROOT_PATH}>
    <AppStandaloneComponent />
  </StandaloneAppWrapper>
);
