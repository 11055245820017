import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField } from "@mui/material";

type Props = {
  label: string;
  loading?: boolean;
  multiple?: boolean;
  name?: string;
  onSearch?: (query: string) => void;
  onSelect: (selected: any) => void;
  options: Array<any>;
  placeholder?: string;
  required?: boolean;
  shrink?: boolean;
  textFrom?: string;
  value?: any;
  valueFrom?: string;
};

function Component({
  label,
  loading,
  multiple,
  name,
  onSearch,
  onSelect,
  options,
  placeholder,
  required,
  shrink,
  textFrom,
  value,
  valueFrom,
}: Props) {
  return (
    <Autocomplete
      autoComplete
      filterOptions={(options, state) => options}
      fullWidth
      getOptionLabel={(option) => {
        if (typeof option === "object") {
          return option[textFrom || "text"];
        }

        return option;
      }}
      isOptionEqualToValue={(selected, item) => {
        if (typeof selected === "object") {
          return selected[valueFrom || "value"] === item[valueFrom || "value"];
        }

        return selected === item;
      }}
      loading={loading}
      multiple={multiple}
      onChange={(event, selected) => onSelect(selected)}
      onInputChange={(event, value, reason) => {
        if (
          typeof onSearch === "function" &&
          (reason === "input" || reason === "clear")
        ) {
          if (value.length >= 3) {
            onSearch(value);
          }
        }
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          name={name}
          placeholder={placeholder}
          variant="outlined"
          InputLabelProps={{
            required,
            shrink,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading || false ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      size="small"
      value={value}
    />
  );
}

export default Component;
