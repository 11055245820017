import { PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";

import {
  validateAllConfigProperties,
  validateConfigProperty as validateConfigPropertyAction,
} from "../actions";
import {
  isValidEmail,
  isValidMobileNumber,
  isValidPACCode,
} from "../../../shared/utils/validation";
import { DC_DATE_FORMAT, isWorkingDay } from "../../../shared/utils/date";
import { getMinimumPortDate } from "../selectors/productConfig";
import { NEW_SIM, PRE_DISPATCHED_SIM, STAC } from "../constants";

// Client side validation of a specified config property.
// Note this is similar to VALIDATE_PRODUCT_PROPERTY in wlrBroadband
export const validationExtraReducers = (builder: any) => {
  builder
    // Validate a single config property across single or multiple (bulk selected) configs:
    .addCase(validateConfigPropertyAction, (state: any, action: any) => {
      action.payload.configIds.forEach((i: number) => {
        const config = state.configs[i];

        const [message, relatedMessage] = validateConfigProperty(
          action.payload.propertyName,
          config.properties,
          state.productData[config.productId],
          action.payload.isVfDirect,
          state.configs
        );

        state.configs[i].validation[action.payload.propertyName] = message;

        if (relatedMessage) {
          Object.entries(relatedMessage).forEach(([key, value]) => {
            state.configs[i].validation[key] = value;
          });
        }
      });
    })
    // Validate all properties on all mobile configs
    .addCase(
      validateAllConfigProperties,
      (state: any, action: PayloadAction<boolean>) => {
        const isVfDirect = action.payload;
        const validationUpdate = state.configs.map((config: any, i: number) => {
          if (config.properties) {
            const validation: { [key: string]: string } = {};

            Object.keys(config.properties).forEach((propertyName) => {
              const [message, relatedMessage] = validateConfigProperty(
                propertyName,
                config.properties,
                state.productData[config.productId],
                isVfDirect,
                state.configs
              );
              validation[propertyName] = message;
              Object.assign(validation, relatedMessage);
            });

            return { ...config, validation };
          }
          return config;
        });

        state.configs = validationUpdate;
      }
    );
};

function validateConfigProperty(
  propertyName: string,
  configProperties: any,
  productData: any,
  isVfDirect: boolean,
  mobileConfigs: any
) {
  let message = undefined,
    relatedMessage: any = {};

  const value = configProperties[propertyName];

  // Find the minimum port date for preferred migration date
  const minPortDate = (simIsBuffer: string, isSimRequired: string) => {
    // If all selected configs have validated pre-dispatched SIM serials,
    // we can offer faster port times (FB129427).
    // Subsequently FB154639:
    if (simIsBuffer === "1" || isSimRequired === "1") {
      return getMinimumPortDate(PRE_DISPATCHED_SIM);
    }
    return getMinimumPortDate(NEW_SIM);
  };

  switch (propertyName) {
    // Bill Limits -------------------------------------------------------
    case "bill_limit":
      relatedMessage.bill_limit =
        value === "" ? "Please enter a bill limit" : false;
      break;

    case "additional_notification":
      relatedMessage.notification_preference =
        value === 1 ? "Please specify a notification" : false;
      break;

    case "notification_preference":
      if (value === 1) {
        relatedMessage.notification_contact = "Please specify a contact";
      }
      break;

    case "notification_contact":
      switch (configProperties.notification_preference) {
        case "sms":
          if (!isValidMobileNumber(value)) {
            message = "Must be a valid UK mobile number";
          }
          break;
        case "email":
          if (!isValidEmail(value)) {
            message = "Must be a valid email address";
          }
          break;
        default:
      }
      break;

    // Unique Reference Number
    case "partner_reference_number":
      if (
        !productData?.response?.mobile.dynamic_properties
          .partner_reference_number
      ) {
        break;
      }
      if (!value) {
        message = "A unique reference number must be provided.";
        break;
      }
      if (value?.length > 10) {
        message = "Unique reference number must be no more than 10 characters.";
      }
      if (/^0*$/.test(value)) {
        message = "Unique reference number cannot be 0.";
      }
      if (/[^0-9]/g.test(value)) {
        message = "Unique reference number only accepts numerical values.";
      }
      break;

    // Airtime Credit ----------------------------------------------------
    case "airtime_credit_amount":
      if (value < 0) {
        message = "Must be a positive value";
      }

      // TP15881 - TODO: Is there a better way to do validation globally to avoid all these overlapping checks?
      if (Number(value) && !Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration =
          "Duration must be specified with monthly credit amount";

      if (Number(value) && Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration = false;

      if (!Number(value) && Number(configProperties.airtime_credit_duration))
        relatedMessage.airtime_credit_duration =
          "Duration cannot be specified when monthly credit amount is 0";
      break;

    case "airtime_credit_duration":
      if (Number(value) && !Number(configProperties.airtime_credit_amount)) {
        message =
          "Duration cannot be specified when monthly credit amount is 0";
      }
      if (!Number(value) && Number(configProperties.airtime_credit_amount))
        message = "Duration must be specified with monthly credit amount";
      break;

    case "airtime_credit_start_date":
      if (value && !/([12]\d{3}-(0[1-9]|1[0-2])-(01))/.test(value)) {
        message = "The start date must be the first day of the month";
      }
      break;

    // Connection --------------------------------------------------------
    case "stac":
      if (value && !/^\d{6}[A-Za-z]{3}$/.test(value)) {
        message = "Must be a valid STAC code: 6 digits then 3 letters";
      }
      if (value && !configProperties.stac_expiry_date) {
        relatedMessage.stac_not_verified = "Please verify a STAC code";
      } else {
        relatedMessage.stac_not_verified = false;
      }
      break;

    case "old_mobile_number":
      if (value && !isValidMobileNumber(value)) {
        message = "Must be a valid UK mobile number";
      }
      if (value && !configProperties.stac_expiry_date) {
        relatedMessage.stac_not_verified_old_mobile_number =
          "Please verify a STAC code";
      } else {
        relatedMessage.stac_not_verified_old_mobile_number = false;
      }
      break;

    case "mobile_number":
      if (value && !isValidMobileNumber(value))
        message = "Must be a valid UK mobile number";
      if (value && mobileConfigs.length > 1) {
        const mobileNumbers = mobileConfigs
          .map((config: any) => config.properties?.mobile_number)
          .filter((num: any) => num !== undefined);
        if (new Set(mobileNumbers).size !== mobileNumbers.length) {
          message =
            "Mobile numbers must be unique. Please check the other products in this order for duplicates";
        }
      }
      break;

    case "pac":
      if (value && !isValidPACCode(value)) {
        message = "Must be a valid PAC code: 3 uppercase letters then 6 digits";
      }
      break;

    case "acquisition_method":
      if (value === "port/mig") {
        if (!configProperties.port_date) {
          relatedMessage.port_date = "A port date must be chosen";
        }
        if (!configProperties.pac_expiry_date) {
          relatedMessage.connection_pac_expiry_date =
            "Please verify a PAC code";
        } else {
          relatedMessage.connection_pac_expiry_date = false;
        }
      } else {
        relatedMessage = {
          port_date: false,
          connection_pac_expiry_date: false,
        };
      }
      break;

    case "activation_date":
      // https://www.regextester.com/104039
      // Validator to match DC date format TP26504
      if (configProperties.acquisition_method === "port/mig") {
        break;
      }
      if (
        value &&
        !/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)
      ) {
        message = "Must be a valid activation date";
      }
      if (
        value &&
        new Date(value) <
          new Date(
            format(
              configProperties.stac_expiry_date
                ? getMinimumPortDate(STAC)
                : new Date(),
              DC_DATE_FORMAT
            )
          )
      ) {
        message = "Must be a valid activation date";
      }
      if (
        configProperties.stac_expiry_date &&
        new Date(value) > new Date(configProperties.stac_expiry_date)
      ) {
        message = "Activation date must be before STAC expiry date";
      }
      break;

    case "port_date":
      // port date N/A for resigns
      if (configProperties.acquisition_method === "resign") {
        break;
      }
      // port date N/A for new pre-dispatched sim orders or new e-sims.
      if (
        configProperties.acquisition_method === "new" &&
        (configProperties.sim_is_buffer === "1" ||
          configProperties.sim_type === "esim")
      ) {
        break;
      }
      if (!value) {
        message = "A port date must be chosen";
        break;
      }
      const port_date = new Date(value);
      if (!isWorkingDay(port_date)) {
        message = "Port date must be a working day";
      }
      if (!isVfDirect && port_date.getDay() === 5) {
        message = "Port date can't be a Friday";
      }
      if (
        configProperties.pac_expiry_date &&
        new Date(value) > new Date(configProperties.pac_expiry_date)
      ) {
        message = "Port date must be before PAC expiry date";
      }
      if (
        new Date(value) <
        new Date(
          format(
            minPortDate(
              configProperties.sim_is_buffer,
              configProperties.is_sim_required
            ),
            DC_DATE_FORMAT
          )
        )
      ) {
        message = "Port date must be a valid migration date";
      }
      break;

    case "user_name":
      if (value && value.length > 50)
        message = "User name must be no more than 50 characters.";
      break;

    case "user_first_name":
      if (value && value.length > 50)
        message = "First name must be no more than 50 characters.";
      break;

    case "user_last_name":
      if (value && value.length > 50)
        message = "Last name must be no more than 50 characters.";
      break;

    // case "sim_is_buffer": nope...
    //   console.log("cslled sim_is_buffer", configProperties);
    // add sim_is_buffer /  properties.sim_is_buffer == 1 && // eslint-disable-line eqeqeq
    //     !(_.get(config, "simValidCheck.response.is_valid_sim_number") == 1) // eslint-disable-line eqeqeq

    case "sim_buffer_serial":
      if (
        configProperties.sim_is_buffer === "1" &&
        configProperties.sim_type !== "esim" &&
        !value
      ) {
        message = "A SIM Serial must be entered";
      }
      if (
        configProperties.sim_is_buffer === "1" &&
        configProperties.sim_type !== "esim" &&
        value &&
        mobileConfigs.length > 1
      ) {
        const simBufferSerials = mobileConfigs
          .map((config: any) => config.properties?.sim_buffer_serial)
          .filter((serial: any) => serial !== null && serial.length > 0);
        if (new Set(simBufferSerials).size !== simBufferSerials.length) {
          message =
            "Sim Serials must be unique. Please check the other products in this order for duplicates";
        }
      }
      break;

    // Discounts --------------------------------------------------------
    case "shared_discount":
      if (
        Number(value) >
        Number(
          productData?.response?.overall_price
            ?.first_bill_recurring_price_with_promotions || 0
        )
      ) {
        message = "Cannot enter a discount which exceeds the line rental";
      }
      break;

    case "esim_delivery_email_address":
      if (configProperties.sim_type === "esim" && !isValidEmail(value)) {
        message = "Delivery email address must be entered.";
      }
      break;
    case "sim_type":
      if (configProperties.sim_type === "esim") {
        relatedMessage.sim_buffer_serial = false;
      }
      if (configProperties.sim_type === "triple") {
        relatedMessage.esim_delivery_email_address = false;
      }
      break;

    default:
  }

  return [message, relatedMessage];
}
