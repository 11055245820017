import React, { ReactNode } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Alert } from "@akj-dev/shared-components";

interface Props {
  children: ReactNode;
  menu?: ReactNode;
  title: string;
  loading: boolean;
  error: string;
  description?: string;
}

function Main({ children, menu, title, loading, error, description }: Props) {
  const getContent = () => {
    if (loading) {
      return <CircularProgress />;
    }

    return children;
  };

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
    <Box display="flex" flexDirection="column" paddingTop={1} paddingBottom={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginBottom={2}
      >
        <Box>
          <Typography variant="h2">{title}</Typography>
          {description && (
            <Typography variant="caption">{description}</Typography>
          )}
        </Box>
        {menu}
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {getContent()}
      </Box>
    </Box>
  );
}

export default Main;
