import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  requestReservedNumbersList,
  updateConfigProperty,
} from "../../../../../../../store/mobile/actions";
import { StatusChip } from "@akj-dev/shared-components";
import { getProductDataForConfig } from "../../../../../../../store/mobile/selectors/productConfig";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { ReservedNumbers } from "../../../../../components/Mobile/ReservedNumbers";

export const ReservedNumbersContainer = () => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();

  const firstConfig = useSelector(
    (state: any) => state.mobile.configs[targetConfigs[0]]
  );
  const productDataForConfig = useSelector((state: any) =>
    getProductDataForConfig(state, targetConfigs[0])
  );

  const newNetwork = useSelector((state: any) =>
    _.get(productDataForConfig, "mobile.product_component_data.supplier", false)
  );

  const {
    fetching: reservedNumbersSearchFetching,
    response: reservedNumbersSearchResponse,
  } = useSelector((state: any) =>
    _.get(state, "mobile.reservedNumbersSearch." + newNetwork, {})
  );

  const mobile_number = firstConfig.properties.mobile_number;

  useEffect(() => {
    if (
      !reservedNumbersSearchFetching &&
      reservedNumbersSearchResponse?.status !== "ok"
    ) {
      dispatch(requestReservedNumbersList(newNetwork));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (targetConfigs.length > 1) return null;

  if (reservedNumbersSearchFetching) {
    return (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Loading reserved numbers"
      />
    );
  }

  if (reservedNumbersSearchResponse?.status === "error") {
    return (
      <StatusChip
        type="error"
        title="Error"
        message="Something went wrong finding reserved numbers."
        retry={() => dispatch(requestReservedNumbersList(newNetwork))}
      />
    );
  }

  return (
    <ReservedNumbers
      mobileNumber={mobile_number}
      options={reservedNumbersSearchResponse?.result || []}
      onMobileNumberChange={(event) => {
        dispatch(
          updateConfigProperty({
            propertyName: "mobile_number",
            value:
              event.target.value === "0" ? "" : (event.target.value as string),
            configIds: targetConfigs,
          })
        );
      }}
    />
  );
};
