import React, { FC } from "react";
import { useSelector } from "react-redux";

import { WlrLocationProductConfig } from "../../../types";
import { WlrLocationProductCard } from "../../../components/WlrLocation/WlrLocationProductCard";

import { BroadbandSelectContainer } from "./BroadbandSelect";
import { WlrLocationTypeEnum } from "../../../enums";

export interface WlrLocationProductCardProps {
  locationIndex: number;
  productId: WlrLocationTypeEnum | string;
  title: string;
  subtitle?: string;
  handleClick: () => void;
}

export const WlrLocationProductCardContainer: FC<
  WlrLocationProductCardProps
> = ({ locationIndex, productId, title, handleClick }) => {
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    (state) => state.wlrBroadband.configurations ?? []
  );
  const productConfiguration = configurations.find(
    (c) => c.wlrProductId === productId && c.locationIndex === locationIndex
  );

  return (
    <WlrLocationProductCard
      title={title}
      productConfiguration={productConfiguration}
      broadbandSelect={
        <BroadbandSelectContainer
          locationIndex={locationIndex}
          productId={productId}
          broadbandProductId={productConfiguration?.broadbandProductId}
        />
      }
      handleClick={handleClick}
    />
  );
};
