import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableBody } from "@mui/material";
import {
  getProductInstances,
  isLinkedProductInstance,
} from "../../../../../../store/wlrBroadband/selectors";
import { MPFProduct } from "../../../section/MPFProduct";
import { StandardProduct } from "../../../section/StandardProduct";
import {
  removeResign,
  setResignType,
} from "../../../../../../store/wlrBroadband/actions/resigns";
import { SAME_PRODUCT } from "../../../../../../store/wlrBroadband/constants";
import {
  addResignLocationWithSearches,
  removeResignLocation,
} from "../../../../../../store/wlrBroadband/actions/locations";

interface ResignsTableBodyContainerProps {
  doFilter: (productInstance: Record<string, any>) => boolean;
  deselectAll: () => void;
  isChecked: (index: number) => boolean;
  toggleRow: (index: number) => void;
}

export const ResignsTableBodyContainer = ({
  doFilter,
  deselectAll,
  isChecked,
  toggleRow,
}: ResignsTableBodyContainerProps) => {
  const dispatch = useDispatch();

  const productInstances = useSelector(getProductInstances);
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  const setChanges = (
    productInstance: Record<string, any>,
    resignProductType: string
  ) => {
    dispatch(
      addResignLocationWithSearches({ productInstance, resignProductType })
    );
    dispatch(
      setResignType({ productInstances: [productInstance], resignProductType })
    );
    deselectAll();
  };

  const setNoChanges = (productInstance: Record<string, any>) => {
    dispatch(
      addResignLocationWithSearches({
        productInstance,
        resignProductType: SAME_PRODUCT,
      })
    );
    dispatch(
      setResignType({
        productInstances: [productInstance],
        resignProductType: SAME_PRODUCT,
      })
    );
    deselectAll();
  };

  return (
    <TableBody>
      {productInstances.map((productInstance: any, index: number) => {
        // Check if a configuration using this product instance
        // already exists.
        const configuration =
          configurations.find(
            (c: any) =>
              c[`${productInstance.type}ProductInstanceId`] ===
              productInstance.id
          ) || {};
        // Check if this product instance belongs to a linked
        // configuration (wlr and broadband).
        const linkedConfiguration = configurations.find(
          (c: any) =>
            c.wlrProductInstanceId &&
            c.broadbandProductInstanceId === productInstance.id
        );

        if (!doFilter(productInstance)) return null;

        return productInstance.type === "mpf" ? (
          <MPFProduct
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            configuration={configuration}
            isChecked={isChecked(index)}
            toggleRow={() => toggleRow(index)}
            productInstance={productInstance}
            setChanges={(resignProductType) =>
              setChanges(productInstance, resignProductType)
            }
            setNoChanges={() => setNoChanges(productInstance)}
            removeResign={() => {
              dispatch(removeResignLocation(configuration.locationIndex));
              dispatch(removeResign([productInstance.id]));
            }}
          />
        ) : (
          <StandardProduct
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            configuration={linkedConfiguration || configuration}
            isChecked={isChecked(index)}
            toggleRow={() => toggleRow(index)}
            productInstance={productInstance}
            setChanges={(resignProductType) =>
              setChanges(productInstance, resignProductType)
            }
            setNoChanges={() => setNoChanges(productInstance)}
            removeResign={() =>
              dispatch(removeResignLocation(configuration.locationIndex))
            }
            isLinkedProductInstance={isLinkedProductInstance(
              productInstances,
              productInstance,
              index
            )}
          />
        );
      })}
    </TableBody>
  );
};
