import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box } from "@mui/material";

export const ResignBoltOnsWarning = () => {
  return (
    <Box mb={1}>
      <Alert
        type="warning"
        message="Please be aware that all add-ons and bars will need to be configured again when resigning a customer. This includes Spend Caps."
      />
    </Box>
  );
};
