import { all } from "redux-saga/effects";
import * as mobile from "../mobile/sagas";

export default function* saga() {
  yield all([
    mobile.watchMobileSearch(),
    mobile.watchBoltOnSearch(),
    mobile.watchProductInstances(),
    mobile.watchCliBoltOnProducts(),
    mobile.watchCliTopUpBoltOnProducts(),
    mobile.watchResignProductSearch(),
    mobile.watchAccountBoltOns(),
    mobile.watchMobileProductData(),
    mobile.watchMobilePricingData(),
    mobile.watchResignPropertyValues(),
    mobile.watchDaisyFreshSearch(),
    mobile.watchHardwareCreditProductSearch(),
    mobile.watchCheckPacCode(),
    mobile.watchCheckStacCode(),
    mobile.watchSimVerification(),
    mobile.watchBarsCompatibility(),
    mobile.watchReservedNumbersList(),
  ]);
}
