import React, { useEffect, useState } from "react";
import { FinalStepWarning } from "../components/Messages/FinalStepWarning";
import { useDispatch, useSelector } from "react-redux";
import { saveDraftState } from "../../../store/drafts/actions";
import { GenerateQuoteContainer } from "./Header/Cart/Quote/GenerateQuote";

interface FinalStepContainerProps {
  handleConfirm: () => void;
  handleCancel: () => void;
  show: boolean;
}

export const FinalStepContainer = ({
  handleConfirm,
  handleCancel,
  show,
}: FinalStepContainerProps) => {
  const dispatch = useDispatch();
  const [generateQuoteOpen, setGenerateQuoteOpen] = useState(false);
  const [draftSaved, setDraftSaved] = useState(false);
  const draftIsSaving = useSelector((state: any) => state.drafts.isFetching);

  const handleGenerateQuote = () => setGenerateQuoteOpen(true);
  const handleCloseGenerateQuote = () => setGenerateQuoteOpen(false);

  const saveDraft = () => {
    dispatch(saveDraftState({}));
    setTimeout(() => setDraftSaved(true), 100);
  };

  useEffect(() => {
    if (draftSaved && !draftIsSaving) {
      handleCancel();
      setDraftSaved(false);
    }
  }, [draftSaved, draftIsSaving, handleCancel, setDraftSaved]);

  return (
    <>
      <FinalStepWarning
        show={!generateQuoteOpen && show}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        handleGenerateQuote={handleGenerateQuote}
        saveDraft={saveDraft}
        draftIsSaving={draftIsSaving}
      />
      <GenerateQuoteContainer
        open={generateQuoteOpen}
        handleClose={handleCloseGenerateQuote}
      />
    </>
  );
};
