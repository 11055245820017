export const SEARCH_ACCOUNTS = "orders.autocomplete.SEARCH_ACCOUNTS";
export const SEARCH_ACCOUNTS_OK = "orders.autocomplete.SEARCH_ACCOUNTS_OK";
export const SEARCH_ACCOUNTS_FAILED =
  "orders.autocomplete.SEARCH_ACCOUNTS_FAILED";

export const SEARCH_USERS = "orders.autocomplete.SEARCH_USERS";
export const SEARCH_USERS_OK = "orders.autocomplete.SEARCH_USERS_OK";
export const SEARCH_USERS_FAILED = "orders.autocomplete.SEARCH_USERS_FAILED";

export const REQUEST_ORDER_NUMBERS =
  "orders.autocomplete.REQUEST_ORDER_NUMBERS";
export const SET_ORDER_NUMBERS = "orders.autocomplete.SET_ORDER_NUMBERS";
export const REQUEST_ORDER_NUMBERS_FAILED =
  "orders.autocomplete.REQUEST_ORDER_NUMBERS_FAILED";
