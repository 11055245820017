export type MobileProductConfig = {
  productId: string;
  resignId?: string;
  resignType?: TypeOfConnection;
  selectedCliBoltOns: any;
  properties: Properties;
  delivery: Delivery;
  validation: object;
  orderProduct: object;
  pacCodeCheck: PacCodeCheck;
  stacCodeCheck: StacCodeCheck;
  productData: ProductData;
  additionalBundle: string;
};

export type Delivery = {
  addressType: string;
  addressId: string;
};

export type PacCodeCheck = {
  fetching: boolean;
  response: object;
  error: boolean;
};

export type Properties = {
  as_frontend_uuid: string;
  /* DC Dynamic properties */
  [key: string]: any;
};

export type StacCodeCheck = {
  fetching: boolean;
  response: object;
};

export type ProductData = {
  fetching: boolean;
  response: {
    product_name: string;
    mobile: { product_component_data: { supplier: string } };
  };
};

/**
 * Data to display in the Mobile Config table on the Customise step
 */
export type MobileConfigTableData = {
  [productId: string]: MobileConfigGroupDisplayData;
};

export type MobileConfigGroupDisplayData = {
  name: string;
  network: Network;
  contractLength: number;
  configs: {
    [configId: number]: MobileConfigRowDisplayData;
  };
};

export type MobileConfigRowDisplayData = {
  cli?: string;
  price: number;
  monthlyAirtimeCredit: string;
  monthlyAirtimeCreditDuration: number;
  oneOffAirtimeCredit: string;
  sharedDiscount: number;
  commissionSacrifice: number;
  connectionType: TypeOfConnection;
  commissionType: CommissionType;
  valid: boolean;
  status: string;
};

export type MultipleIndicators = {
  [Property in keyof MobileConfigRowDisplayData]?: boolean;
};

export enum TypeOfConnection {
  RESIGN_WITH_CHANGES = "RESIGN_WITH_CHANGES",
  RESIGN_WITHOUT_CHANGES = "RESIGN_WITHOUT_CHANGES",
  NEW = "new",
  PORT_MIG = "port/mig",
}

export enum Network {
  O2 = "O2",
  VODAFONE = "Vodafone",
  DISE = "Dise",
  VF_DIRECT = "VF Direct",
}

export enum CommissionType {
  UPFRONT = "Upfront",
  RESIDUAL = "Residual",
}
