import * as actions from "./actionTypes";
import { SearchAPIParams } from "../../api/v1/search";

export const searchAccounts = (query: string) => {
  return { type: actions.SEARCH_ACCOUNTS, payload: { query } };
};

export const searchUsers = (query?: string) => {
  return { type: actions.SEARCH_USERS, payload: { query } };
};

export const requestOrderNumbers = (params: SearchAPIParams) => {
  return {
    type: actions.REQUEST_ORDER_NUMBERS,
    payload: params,
  };
};

export const setOrderNumbers = (data: string[]) => {
  return {
    type: actions.SET_ORDER_NUMBERS,
    payload: data,
  };
};

export const requestOrderNumbersError = (error: string) => {
  return {
    type: actions.REQUEST_ORDER_NUMBERS_FAILED,
    payload: error,
  };
};
