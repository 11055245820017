import * as OrdersAPI from "../../api/v1/orders";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Select a contact for the quote.
 * Note this is copied from the old basket code...
 * @param id
 * @returns {{contact: *, type: string}}
 */
export const setQuoteContact = createAction<string>("quote/setQuoteContact");

/**
 * Make a request to generate a quote.
 * @returns {Function}
 */
export const generateQuote = createAsyncThunk(
  "quote/generateQuote",
  async (arg, { getState }: any) => {
    const {
      order,
      quote: { quoteContactId },
    } = getState();
    const response = await OrdersAPI.generateQuote(
      order.accountId,
      order.id,
      // We shouldn't send both lead and account ID as contact IDs are different
      // across leads and accounts according to @jon.taylor. See AllAccountContacts
      order.accountId ? null : order.leadId,
      quoteContactId
    );
    return response;
  }
);

export const setGenerateQuoteFetching = createAction(
  "quote/setGenerateQuoteFetching"
);

/**
 * Get rid of the quote ID that came back with any previous call.
 * ....hence clearing the UI so the user can generate another one.
 * @returns {object}
 */
export const resetQuote = createAction("quote/resetQuote");
