import React from "react";
import _ from "lodash";
import { StatusChip } from "@akj-dev/shared-components";
import { Box } from "@mui/material";

interface RemoteValidationErrorsProps {
  wlrValidation: any;
  wlrValidationQuote: any;
  broadbandValidation: any;
}

export const RemoteValidationErrors = ({
  wlrValidation,
  wlrValidationQuote,
  broadbandValidation,
}: RemoteValidationErrorsProps) => (
  <StatusChip
    type="error"
    title="This configuration has errors."
    message={
      <div style={{ display: "flex" }}>
        {!_.isEmpty(wlrValidation?.errors) && (
          <div style={{ marginRight: 10 }}>
            <h4>WLR:</h4>
            <ul>
              {Object.keys(wlrValidation.errors).map((k) => (
                <li key={k}>{wlrValidation.errors[k]}</li>
              ))}
            </ul>
          </div>
        )}
        {!_.isEmpty(wlrValidationQuote?.errors) && (
          <Box mr={1}>
            <h4>WLR:</h4>
            <ul>
              {Object.keys(wlrValidationQuote.errors).map((k) => (
                <li key={k}>{wlrValidationQuote.errors[k]}</li>
              ))}
            </ul>
          </Box>
        )}
        {!_.isEmpty(broadbandValidation?.errors) && (
          <div>
            <h4>Broadband:</h4>
            <ul>
              {Object.keys(broadbandValidation.errors).map((k) => (
                <li key={k}>{broadbandValidation.errors[k]}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    }
  />
);
