import React, { useState } from "react";
import styled from "styled-components";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";
import Slide, { SlideProps } from "@mui/material/Slide";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

// TODO: duplicite code in 5 files - unify
const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props,
  ref
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .icon {
    width: 40px;
    height: 24px;
    flex-shrink: 0;
  }
`;

interface ExistingSoGEAAlertDialogProps {
  onClose: () => void;
  open: boolean;
}

export const ExistingSoGEAAlertDialog = ({
  open,
  onClose,
}: ExistingSoGEAAlertDialogProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>Please read before progressing with the order.</DialogTitle>
      <DialogContent>
        <AlertItem>
          <div className="icon">
            <ErrorIcon color="error" />
          </div>
          <div>
            <div>
              Please ensure the below is read and understood in full before
              proceeding with this order. The SoGEA order will convert the
              existing services (line & any broadband service) with a single
              data only Broadband service whereby:
            </div>
            <br />
            <li>The WLR (line) or MPF voice service will CEASE</li>
            <li>The telephone number will be unretainable</li>
            <li>
              You will not be able to revert the service to a WLR/MPF (voice +
              broadband)
            </li>
            <br />
            <div>
              If the customer requires a voice service, please quote for a VoIP
              service separately along with a number import (if you wish to
              retain the number). Please confirm with the customer prior to
              placing any order if any of the following services are active, if
              so, please do not proceed with this order:
            </div>
            <br />
            <li>Customer who are vulnerable or using pendant service</li>
            <li>
              Security systems, payment devices, fax machines and lift services
            </li>
            <li>
              Red care services or any devices that customer is reliant on which
              uses the WLR service
            </li>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
              }
              label={
                <div style={{ fontSize: "14px" }}>
                  I confirm that I have read and understood the above along with
                  the impact this may have on the customer.
                </div>
              }
            />
          </div>
        </AlertItem>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          disabled={!checked}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
