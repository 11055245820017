import _ from "lodash";
import * as actionTypes from "../actionTypes";
import { RESIGN_WITH_CHANGES } from "../constants";
import {
  getAccountSettings,
  getCreditVetProcessAttempted,
} from "../../account/selectors";
import { fetchCreditVetStatus } from "../../account/actions";
import { getContractLength } from "../../mobile/selectors/order";

/**
 * Set mobile contract length in months for all mobile products
 * @param contractLength
 * @param date
 * @returns {{type: string, contractLength: *, date: *}}
 */
export const setContractLengthAllMobileProducts =
  (contractLength, date) => (dispatch, getState) => {
    const canSelectCommission =
      getAccountSettings(getState()).as_commission_type_selection_for_mobile ===
      "1";

    dispatch({
      type: actionTypes.SET_CONTRACT_LENGTH_IN_MONTHS_ALL_PRODUCTS,
      contractLength,
      date,
      canSelectCommission,
    });
  };

/**
 * Set the filterValue in months for the UI to use as a filter
 * @param contractLength
 * @returns {{type: string, contractLength: *}}
 */
export const setContractLengthUIFilter = (filterValue) => ({
  type: actionTypes.SET_CONTRACT_LENGTH_IN_MONTHS_UI_FILTER,
  filterValue,
});

/**
 * Toggle mobile co-terminus contract type
 * @returns {{type: string}}
 */
export const toggleCoTerminus = () => ({
  type: actionTypes.TOGGLE_CO_TERMINUS,
});

/**
 * Get available mobile products (saga)
 * @returns {{type: string}}
 */
export const requestMobileSearch = (isDynamic = true) => ({
  type: actionTypes.REQUEST_MOBILE_SEARCH,
  isDynamic,
});

/**
 * Get Resign-able product instances (saga)
 * @returns {Function}
 */
export const requestProductInstances = () => ({
  type: actionTypes.REQUEST_PRODUCT_INSTANCES,
});

/**
 * Get available account level bolt-ons (saga)
 * @returns {Function}
 */

export const requestBoltOnSearch = () => ({
  type: actionTypes.REQUEST_BOLT_ON_SEARCH,
});

/**
 * Find the product used to do resigns without change. (saga)
 * @returns {{type: string}}
 */
export const requestResignProduct = () => ({
  type: actionTypes.REQUEST_RESIGN_PRODUCT,
});

/**
 * Get existing account level bolt-ons on an account (saga)
 * @returns {Function}
 */
export const requestAccountBoltOns = () => ({
  type: actionTypes.REQUEST_ACCOUNT_BOLT_ONS,
});

/**
 * Set account bolt on
 * @param serviceProviderId
 * @param boltOnType
 * @param value
 * @returns {{type: string, serviceProviderId: *, boltOnType: *, value: *}}
 */
export const setAccountBoltOn = (serviceProviderId, boltOnType, value) => ({
  type: actionTypes.SET_ACCOUNT_BOLT_ON,
  serviceProviderId,
  boltOnType,
  value,
});

/**
 * Set optional start date from ALBs
 * @param date
 * @returns {{type: string, date: *}}
 */
export const setAccountBoltOnStartDate = (date) => ({
  type: actionTypes.SET_ACCOUNT_BOLT_ON_START_DATE,
  date,
});

/**
 * Set product quantity
 * @param qty
 * @param productId
 * @returns {{type: string, qty: *, productId: *, settings: *}}
 */
export const setProductQuantity = (qty, productId) => (dispatch, getState) => {
  const state = getState();
  const settings = getAccountSettings(state);
  const isOneMonthContract = getContractLength(state) === 1;

  dispatch({
    type: actionTypes.SET_PRODUCT_QUANTITY,
    qty,
    productId,
    settings,
    isOneMonthContract,
  });
  // Refresh Credit Vet if it has been performed previously (i.e. user has returned to step 1 to change things)
  // TP59410
  if (getCreditVetProcessAttempted(getState())) {
    dispatch(fetchCreditVetStatus());
  }
};

/**
 * Remove product configs.
 * @param configIndexes
 * @returns {{type: string, id: *}}
 */
export const removeConfig = (configIndexes) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.REMOVE_CONFIG,
    configIndexes,
  });
  if (getCreditVetProcessAttempted(getState())) {
    dispatch(fetchCreditVetStatus());
  }
};

/**
 * Clear all product configs.
 * @returns {{type: string, id: *}}
 */
export const clearConfig = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLEAR_CONFIG,
  });
};

/**
 * Set Resign Type
 * @param {array} resignIds - search param for configs to act upon
 * @param {string} resignType
 * @returns {{configIndices: *, resignType: *}}
 */
export const setResignType = (resignIds, resignType) => ({
  type: actionTypes.SET_RESIGN_TYPE,
  resignIds,
  resignType,
});

/**
 * Set Resign Product (for "resign with change" orders)
 * @param {array} resignIds - configs to act upon
 * @param {string | boolean} productId
 * @returns {{configIndices: *, productId: *}}
 */
export const setResignProduct = (resignIds, productId) => ({
  type: actionTypes.SET_RESIGN_PRODUCT,
  resignIds,
  productId,
});

/**
 * Remove resign configs by instance ID
 * @param {array} resignIds
 * @returns {{type: string, resignIds: *}}
 */
export const removeResign = (resignIds) => ({
  type: actionTypes.REMOVE_RESIGN,
  resignIds,
});

/**
 * Get all mobile product data required for configs (going into step 2) (saga)
 * @param {boolean} force
 * @returns {Function}
 */
export const requestAllMobileProductData = (force) => (dispatch, getState) => {
  const productIds = _.uniq(getState().mobile.configs.map((c) => c.productId));
  const productData = getState().mobile.productData;
  productIds.forEach((productId) => {
    if (
      (productId &&
        _.get(productData, `${productId}.response.status`) !== "success") ||
      force
    ) {
      dispatch({ type: actionTypes.REQUEST_MOBILE_PRODUCT_DATA, productId });
    }
  });
};

/**
 * Get pricing data for a single mobile config (saga)
 * (for when pricing has been altered in step 2)
 * @param configIds {Array}
 * @returns {Function}
 */
export const requestMobilePricingData = (configIds) => ({
  type: actionTypes.REQUEST_MOBILE_PRICING_DATA,
  configIds,
});

/**
 * Get existing dynamic property values for resigns.
 *
 * The "Resign with Changes" flow can receive pre-existing dynamic property values from product data (like WLR & Broadband)
 * when ProductData is called with the appropriate instance ID (that we get from the initial Product/Instance/Mobile call
 * These should be shown in the config form and used for the final OrderProduct/Create call.
 *
 * Apparently mobile orders can be ~50 products, so full product data responses can't be stored against them all without performance issues.
 * Hence we get just the resign ones here and store just the current_value attrs in each config's `properties` node.
 *
 * Possible values that get populated include (according to @ianc) :
 * mobile_number, connection_type, sim_type, parent_number, corporate_id, pbx_ddi_range_extn_number, is_sim_required,
 * user_name, wwcap_enabled & acquisition_method
 *
 * acquisition_method will be resign if the networks match, or port/mig otherwise
 *
 * @returns {Function}
 */
export const requestAllResignPropertyValues = () => (dispatch, getState) => {
  getState().mobile.configs.forEach((config, configId) => {
    if (config.resignType === RESIGN_WITH_CHANGES) {
      dispatch({
        type: actionTypes.REQUEST_RESIGN_PROPERTY_VALUES,
        configId,
        productId: config.productId,
        resignId: config.resignId,
      });
    }
  });
};

/**
 * Requests available CLI bolt on data for all products selected in order. (Saga)
 *
 * Note: Not sure this is the best way to do this...
 * Still using Redux-thunk to access state in the action creator
 * Feels strange to have both thunk & saga middleware....
 * At the same time, see Dan Abramov's answer here:
 * https://stackoverflow.com/questions/35667249/accessing-redux-state-in-an-action-creator
 *
 * Spawning the extra actions in the saga is possible but messy.
 * Perhaps separation of concerns between:
 * saga - async actions
 * +
 * action creators - spawning all those actions
 * is the cleanest way.
 * ...even if we do end up keeping thunk middleware.
 *
 * @returns {Function}
 */
export const requestAllCliBoltOnProducts = () => (dispatch, getState) => {
  // Get IDs of all products
  const productIds = _.uniq(getState().mobile.configs.map((c) => c.productId));
  productIds.forEach((productId) => {
    // Don't re-fetch.
    if (
      _.get(getState().mobile.cliBoltOnSearch[productId], "response.products")
    )
      return;

    dispatch({ type: actionTypes.REQUEST_CLI_BOLT_ON_PRODUCTS, productId });
  });
};

export function chooseCliBoltOn(configId, boltOnType, boltOnId, slotId) {
  return {
    type: actionTypes.CHOOSE_CLI_BOLT_ON,
    configId,
    boltOnType,
    boltOnId,
    slotId,
  };
}

/**
 * Find the Daisy Fresh product (saga)
 * @returns {Function}
 */
export const requestDaisyFreshSearch = () => ({
  type: actionTypes.REQUEST_DAISY_FRESH_SEARCH,
});

/**
 * Set Daisy Fresh values...
 * @param amount
 * @returns {{type: string, amount: *}}
 */
export const setDaisyFreshHardwareCredits = (amount) => ({
  type: actionTypes.SET_DAISY_FRESH_HARDWARE_CREDITS,
  amount,
});

export const setDaisyFreshTerminationFees = (amount) => ({
  type: actionTypes.SET_DAISY_FRESH_TERMINATION_FEES,
  amount,
});

export const setDaisyFreshEtf = (amount) => ({
  type: actionTypes.SET_DAISY_FRESH_ETF,
  amount,
});

export const setDaisyFreshLimitExceeded = () => ({
  type: actionTypes.SET_DAISY_FRESH_LIMIT_EXCEEDED,
});

/**
 * Get hardware credit product
 * @returns {Function}
 */
export const requestHardwareCreditProduct = () => ({
  type: actionTypes.REQUEST_HARDWARE_CREDIT_PRODUCT,
});

/**
 * Update dynamic property in mobile config(s)
 *
 * @param {String} propertyName
 * @param {String | null | undefined} value
 * @param {Array} configIds
 * @param {Boolean | null | undefined } isVfDirect
 * @returns {{type: string, propertyName: *, value: *, configIds: *}}
 */
export const updateConfigProperty = (
  propertyName,
  value,
  configIds,
  isVfDirect = undefined
) => ({
  type: actionTypes.UPDATE_CONFIG_PROPERTY,
  propertyName,
  value,
  configIds,
  isVfDirect,
});

/**
 * Apply an array of values to multiple mobile configurations
 * For the PAC & Mobile No. bulk entry requirements
 *
 * @param {String} propertyName
 * @param {String | Array} values
 * @param configIds
 * @returns {{type: string, propertyName: *, values: *, configIds: *}}
 */
export const arrayUpdateConfigProperty = (propertyName, values, configIds) => ({
  type: actionTypes.ARRAY_UPDATE_CONFIG_PROPERTY,
  propertyName,
  values,
  configIds,
});

export const validateConfigProperty = (
  propertyName,
  configIds,
  isVfDirect
) => ({
  type: actionTypes.VALIDATE_CONFIG_PROPERTY,
  propertyName,
  configIds,
  isVfDirect,
});

export const validateAllConfigProperties = (isVfDirect) => ({
  type: actionTypes.VALIDATE_ALL_CONFIG_PROPERTIES,
  isVfDirect,
});

/**
 * Set discount percentage
 * @param {string} discountType
 * @param value
 * @param configIds
 * @returns {{type, value: *, configurationIds: *}}
 */
export function setMobileProductDiscount(discountType, value, configIds) {
  return {
    type: actionTypes.SET_MOBILE_PRODUCT_DISCOUNT,
    discountType,
    value,
    configIds,
  };
}

/**
 * Verify the PAC codes for specified mobile configurations sequentially.
 * Checks if a port date has already been populated first, which would indicate successful check has already happened.
 * Shows alert message if PAC code or mobile number is missing, or if validation fails on either of these fields.
 *
 * @param {array} configIds
 * @returns {function(*, *)}
 */

export const verifyPacCodes = (configIds) => async (dispatch, getState) => {
  const configs = getState().mobile.configs;
  configIds.forEach((configId) => {
    if (!configs[configId].properties.pac_expiry_date) {
      dispatch({
        type: actionTypes.REQUEST_PAC_VERIFICATION,
        configId,
      });
    }
  });
};

/**
 * Resets the PAC state back to the default state, so that a new PAC code can be entered.
 *
 * @param {array} configIds
 * @return {function(*, *)}
 */
export const resetPacCodes = (configIds) => async (dispatch) => {
  configIds.forEach((configId) => {
    dispatch({
      type: actionTypes.RESET_PAC_STATE,
      configId,
    });
  });
};

/**
 * Verify a STAC code (new mobile connections). STAC is used to stop the billing from an old provider.
 * @param configIndex
 * @returns {{configId: *, type: string}}
 */
export const verifyStacCode = (configIndex) => ({
  type: actionTypes.REQUEST_STAC_VERIFICATION,
  configIndex,
});

/**
 * Resets the STAC state back to the default state, so that a new STAC code can be entered.
 *
 * @param {array} configIds
 * @return {function(*, *)}
 */
export const resetStacCodes = (configIds) => async (dispatch) => {
  configIds.forEach((configId) => {
    dispatch({
      type: actionTypes.RESET_STAC_STATE,
      configId,
    });
  });
};

/**
 * Verify SIM serials for specified mobile configurations.
 *
 * @param {array} configIds
 * @returns {function(*, *)}
 */

export const verifySimSerials = (configIds) => (dispatch, getState) => {
  configIds.forEach((configId) =>
    dispatch({ type: actionTypes.REQUEST_SIM_VERIFICATION, configId })
  );
};

/**
 * Get Mobile Bars Compatibility (saga)
 * Looks at current bars selection and sees what else can be selected according to the endppint.
 * @param configId
 * @returns {{configId: *, type: string}}
 */
export const fetchBarsCompatibility = (configId) => ({
  type: actionTypes.REQUEST_BARS_COMPATIBILITY,
  configId,
});

/**
 * Get reserved numbers
 *
 * @param network
 * @returns {Function}
 */
export const requestReservedNumbersList = (network) => ({
  type: actionTypes.REQUEST_RESERVED_NUMBERS_LIST,
  network,
});

/**
 * Toggle bill cap confirmation checkbox
 * @returns {{type: string}}
 */
export const toggleBillCapConfirmation = () => ({
  type: actionTypes.TOGGLE_BILL_CAP_CONFIRMATION,
});

/**
 * Toggle DWS Terms checkbox
 * @returns {{type: string}}
 */
export const toggleDwsTermsConfirmation = () => ({
  type: actionTypes.TOGGLE_DWS_TERMS_CONFIRMATION,
});

/**
 * Set global resign start date
 * @param _
 * @param date
 * @returns {{date: *, type: *}}
 */
export const setResignStartDate = (date) => ({
  type: actionTypes.SET_RESIGN_START_DATE,
  date,
});

export const receivePacVerification = (
  response,
  verifiedAcquisitionMethod,
  configId
) => ({
  type: actionTypes.RECEIVE_PAC_VERIFICATION,
  response,
  configId,
  verifiedAcquisitionMethod,
});

export const setPacVerificationError = (configId, e) => ({
  type: actionTypes.SET_PAC_VERIFICATION_ERROR,
  configId,
  ...e,
});

/**
 * Set delivery address type
 * @param addressType {addressTypes}
 * @param configIndexes {number|array}
 * @returns {{addressType: addressTypes, type: string}}
 */
export const setDeliveryAddressType = (addressType, configIndexes) => ({
  type: actionTypes.SET_DELIVERY_ADDRESS_TYPE,
  configIndexes,
  addressType,
});

/**
 * Set delivery address ID
 * @param addressId {string}
 * @param configIndexes {number|array}
 * @returns {{addressId: string, type: string}}
 */
export const setDeliveryAddressId = (addressId, configIndexes) => ({
  type: actionTypes.SET_DELIVERY_ADDRESS_ID,
  configIndexes,
  addressId,
});

/**
 * Set additional bundle
 * @param bundleId {string}
 * @param configId {number}
 */
export const setAdditionalBundle = (bundleId, configId) => ({
  type: actionTypes.SET_ADDITIONAL_BUNDLE,
  configId,
  bundleId,
});

export const requestMobileProductData = (productId) => (dispatch) => {
  dispatch({ type: actionTypes.REQUEST_MOBILE_PRODUCT_DATA, productId });
};
