import React from "react";
import { useSelector } from "react-redux";

import { getConfigDocuments } from "../../../../../store/universalProducts/selectors";
import { DownloadDocuments } from "../../../components/UniversalProducts/DownloadDocuments";

export const DownloadDocumentsContainer = () => {
  const documents = useSelector(getConfigDocuments);

  return (
    <DownloadDocuments
      isFetchingDocuments={documents?.fetching}
      documents={documents?.response.documents}
      productId={documents?.response.product_id}
    />
  );
};
