import React from "react";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { ConfigStatus } from "../../../../shared/components/ConfigStatus";

interface ConfigTableRowProps {
  isConfigValid: boolean;
  productName: string;
  oneOffPrice: string;
  recurringPrice: string;
  hideAction?: boolean;
  onSelectConfig: () => void;
  onRemoveConfig: () => void;
}

export const ConfigTableRow = ({
  isConfigValid,
  productName,
  hideAction,
  oneOffPrice,
  recurringPrice,
  onSelectConfig,
  onRemoveConfig,
}: ConfigTableRowProps) => (
  <TableRow>
    <TableCell>{productName}</TableCell>
    <TableCell>
      {oneOffPrice ? parseFloat(oneOffPrice).toFixed(2) : ""}
    </TableCell>
    <TableCell>
      {recurringPrice ? parseFloat(recurringPrice).toFixed(2) : ""}
    </TableCell>
    <TableCell>
      <ConfigStatus isValid={isConfigValid} onClick={onSelectConfig} />
    </TableCell>
    {!hideAction && (
      <TableCell>
        <IconButton
          onClick={onSelectConfig}
          data-cy="editUniversalProduct"
          size="large"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={onRemoveConfig}
          data-cy="removeUniversalProduct"
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    )}
  </TableRow>
);
