import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setContractLengthAllMobileProducts,
  setContractLengthUIFilter,
} from "../../store/mobile/actions";
import { toggleCoTerminus } from "../../store/mobile/actions";
import { getDaisyFreshAvailableForContractLength } from "../../store/mobile/selectors/daisyFresh";
import { useCanAccessVfDirect } from "../hooks/useCanAccessVfDirect";
import { ContractLengthSection } from "../components/ContractLength/Section";

export const ContractLength = () => {
  const dispatch = useDispatch();

  const canAccessVfDirect = useCanAccessVfDirect();

  const contractLengthInMonthsAllProducts = useSelector(
    (state: any) => state.mobile.contractLengthInMonthsAllProducts
  );
  const contractLengthInMonthsUIFilter = useSelector(
    (state: any) => state.mobile.contractLengthInMonthsUIFilter
  );
  const contractCoTerminus = useSelector(
    (state: any) => state.mobile.contractCoTerminus
  );
  const daisyFreshAvailableForContractLength = useSelector(
    getDaisyFreshAvailableForContractLength
  );

  const handleContractLengthInputChange = (
    value: number,
    date: string | undefined
  ) => {
    if (!canAccessVfDirect) {
      // the selected values from the contract length control sets global contract length for all products in redux
      dispatch(
        setContractLengthAllMobileProducts({ contractLength: value, date })
      );
    } else {
      // the selected value from the contract length control to be used as a filter
      dispatch(setContractLengthUIFilter(value));
    }
  };

  const handleNumberOfMonthsChange = (value: string | 0) => {
    dispatch(setContractLengthAllMobileProducts({ contractLength: value }));
  };

  return (
    <ContractLengthSection
      contractCoTerminus={contractCoTerminus}
      contractLength={contractLengthInMonthsAllProducts}
      contractFilterLength={contractLengthInMonthsUIFilter}
      daisyFreshAvailableForContractLength={
        daisyFreshAvailableForContractLength
      }
      toggleCoTerminus={() => dispatch(toggleCoTerminus())}
      handleContractLengthInputChange={handleContractLengthInputChange}
      handleNumberOfMonthsChange={handleNumberOfMonthsChange}
    />
  );
};
