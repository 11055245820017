import _ from "lodash";

import {
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_ACCOUNT,
  STEP_DELIVERY,
  STEP_SUMMARY,
} from "./constants";

/**
 * Get steps
 * @param accountRequired {boolean}
 * @returns {*}
 */
export const getSteps = (accountRequired: boolean) => {
  return accountRequired
    ? [STEP_CHOOSE, STEP_CUSTOMISE, STEP_ACCOUNT, STEP_DELIVERY, STEP_SUMMARY]
    : [STEP_CHOOSE, STEP_CUSTOMISE, STEP_DELIVERY, STEP_SUMMARY];
};

function getNextId(collection: any[]) {
  let keys = Object.keys(collection);
  keys = _.sortBy(keys, function (num: any) {
    return Math.ceil(num);
  });
  let lastId = keys[keys.length - 1];
  let nextId = lastId ? parseFloat(lastId) + 1 : 1;
  return nextId;
}

function convertArrayToObjectUsingParameterAsProperty(
  array: any[],
  property: string
) {
  let newObject: any = {};
  array.forEach(function (arrayItem) {
    let propertyKey = arrayItem[property];
    newObject[propertyKey] = arrayItem;
  });
  return newObject;
}

export function getNextIdFromArrayOfObjectsWithIdAsProperty(array: any[]) {
  const object = convertArrayToObjectUsingParameterAsProperty(array, "id");
  const nextId = getNextId(object);
  return nextId;
}
