import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedProductData } from "../../../store/wlrBroadband/actions/configurations";
import { getIsMPFLine } from "../../../store/wlrBroadband/selectors";
import { WlrBroadband } from "../components/WlrBroadband";
import { useCanAccessCommission } from "../../../shared/hooks/useAccountSettings";

interface WlrBroadbandContainerProps {
  cardIndex: string;
}

export const WlrBroadbandContainer = ({
  cardIndex,
}: WlrBroadbandContainerProps) => {
  const dispatch = useDispatch();

  const configs = useSelector<any, Record<string, any>[]>(
    (state: any) => state.wlrBroadband.configurations
  );
  const showCommissionType = useCanAccessCommission("wlr");

  return (
    <WlrBroadband
      cardIndex={cardIndex}
      configs={configs}
      getIsMPFLine={getIsMPFLine}
      onRetry={() => dispatch(getSelectedProductData())}
      showCommissionType={showCommissionType}
    />
  );
};
