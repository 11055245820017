import React, { ReactNode, ChangeEvent } from "react";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import { getTotalPrice } from "../../../../../../store/wlrBroadband/selectors";
import { useTheme } from "@mui/material/styles";
import { LinkIcon } from "../../../../../../shared/components/Icons/LinkIcon";
import { AddBroadbandContainer } from "../../../containers/Resigns/AddBroadband";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const CustomArrowForwardIcon = styled(ArrowForwardIcon)`
  color: ${(p) => p.theme.palette.text.disabled};
  margin-left: 10px;
  margin-right: 10px;
`;

interface ProductChangesRowProps {
  productInstance: Record<string, any>;
  configuration: Record<string, any>;
  resignProduct: Record<string, any>;
  isChecked: boolean;
  isLinkedProductInstance: boolean;
  canAddBroadband: boolean;
  actions: ReactNode;
  toggleRow: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const ProductChangesRow = ({
  configuration,
  isChecked,
  toggleRow,
  productInstance,
  resignProduct,
  isLinkedProductInstance,
  canAddBroadband,
  actions,
}: ProductChangesRowProps) => {
  const theme = useTheme();

  const isResignProduct = resignProduct.components[0].is_resign;
  const resignProductName = resignProduct.name_for_resign || resignProduct.name;
  const resignProductPrice =
    resignProduct.price.first_bill_recurring_price_with_promotions;

  return (
    <TableRow selected={isChecked}>
      <TableCell>
        <Checkbox color="secondary" onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <TableCell>{productInstance.pin}</TableCell>
      <TableCell>{isLinkedProductInstance && <LinkIcon />}</TableCell>
      <TableCell colSpan={2}>
        <CustomCell>
          {productInstance.broadband ? (
            <PublicIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          ) : (
            <PhoneIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          )}
          <div>{productInstance.name_for_resign || productInstance.name}</div>
          <CustomArrowForwardIcon />
          {productInstance.broadband ? (
            <PublicIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          ) : (
            <PhoneIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          )}
          <div>{resignProductName}</div>
        </CustomCell>
      </TableCell>
      <TableCell>
        {canAddBroadband && (
          <AddBroadbandContainer
            productInstance={productInstance}
            configuration={configuration}
            locationIndex={configuration.locationIndex}
          />
        )}
      </TableCell>
      <TableCell>
        &pound;
        {isResignProduct
          ? getTotalPrice(productInstance.transformedServices)
          : resignProductPrice}
      </TableCell>
      <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
      <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
        {actions}
      </TableCell>
    </TableRow>
  );
};
