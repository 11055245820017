import React from "react";

import Button from "@mui/material/Button";
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";

import { FileDrop } from "../../../../shared/components/File/Drop";
import { StatusLarge } from "../../../../shared/components/StatusLarge";
import { BrowserDateField } from "@akj-dev/shared-components";
import { ParsableDate } from "typings";

interface ContentProps {
  signedAt: ParsableDate;
  receivedAt: ParsableDate;
  file: File;
  uploadSending: boolean;
  uploadResponse: Record<string, any>;
  contractId: string;
  onChangeMeta: (propertyName: string, value: ParsableDate | File) => void;
  onUploadContract: () => void;
  onResetUploadStatus: () => void;
  closeDialog: () => void;
}

export const UploadSignedContractContent = ({
  closeDialog,
  onChangeMeta,
  onUploadContract,
  onResetUploadStatus,
  receivedAt,
  signedAt,
  file,
  uploadResponse,
  uploadSending,
  contractId,
}: ContentProps) => {
  return (
    <>
      <DialogContent>
        {uploadSending || uploadResponse ? (
          <div className="u-text-center">
            {uploadSending && (
              <Box
                m={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box mb={4}>
                  <CircularProgress size={80} />
                </Box>
                <Typography variant="h5">Uploading Contract</Typography>
              </Box>
            )}
            {uploadResponse && (
              <div>
                {contractId ? (
                  <StatusLarge
                    type="success"
                    title={`Success. Contract ID: ${contractId}`}
                  />
                ) : (
                  <StatusLarge
                    type="error"
                    title="Sorry, something went wrong."
                    message={uploadResponse.message}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                {file ? (
                  <StatusChip title="File selected" message={file.name} />
                ) : (
                  <FileDrop
                    onDrop={(files) => onChangeMeta("file", files[0])}
                    message="Drag a signed contract here"
                    maxSize={3e7}
                  />
                )}
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h4">
                  Please enter contract information:
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <BrowserDateField
                  name="receivedDate"
                  value={receivedAt as string}
                  label="Received Date"
                  fullWidth={true}
                  onChange={(date: string) => onChangeMeta("receivedAt", date)}
                />
              </Grid>
              <Grid item sm={6}>
                <BrowserDateField
                  name="signedDate"
                  value={signedAt as string}
                  label="Signed Date"
                  fullWidth={true}
                  onChange={(date: string) => onChangeMeta("signedAt", date)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {contractId ? (
          <Button variant="contained" onClick={closeDialog}>
            Close
          </Button>
        ) : (
          <Button
            variant="contained"
            key="cancel"
            onClick={closeDialog}
            disabled={uploadSending}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
        )}

        {uploadResponse ? (
          <Button
            variant="contained"
            color="primary"
            key="retry"
            onClick={onResetUploadStatus}
            disabled={uploadSending}
          >
            Retry
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            key="upload"
            onClick={onUploadContract}
            disabled={uploadSending || !file}
            data-cy="UploadContractButton"
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </>
  );
};
