import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSettings } from "../../../../../../store/account/selectors";
import { deselectEthernetQuote } from "../../../../../../store/ethernetProducts/actions";
import { getConfigRecurringPrice } from "../../../../../../store/ethernetProducts/selectors";
import { SelectedProduct } from "../../../../components/Ethernet/SelectedProduct";

interface SelectedProductContainerProps {
  index: number;
  configuration: Record<string, any>;
}

export const SelectedProductContainer = ({
  index,
  configuration,
}: SelectedProductContainerProps) => {
  const dispatch = useDispatch();

  const accountSettingRoundEthernetPrices = useSelector<any, boolean>(
    (state) =>
      getAccountSettings(state).round_ethernet_prices_to_next_whole_pound ===
      "1"
  );

  const handleSelectDifferentQuote = () => {
    dispatch(deselectEthernetQuote(index));
  };

  const selectedResult = configuration.pricingResults.filter(
    (r: any) => r.id === configuration.selectedQuoteId
  )[0];

  const discountPercentage = configuration.purchaseArgs.discount;
  const { amortised } = configuration.purchaseArgs;

  return (
    <SelectedProduct
      amortised={amortised}
      discountPercentage={discountPercentage}
      selectedResult={selectedResult}
      recurringPriceWithDiscounts={getConfigRecurringPrice(
        configuration,
        selectedResult,
        true,
        accountSettingRoundEthernetPrices
      )}
      recurringPriceWithoutDiscounts={getConfigRecurringPrice(
        configuration,
        selectedResult,
        false,
        accountSettingRoundEthernetPrices
      )}
      handleSelectDifferentQuote={handleSelectDifferentQuote}
    />
  );
};
