import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { doLineSearch } from "../../../store/wlrBroadband/actions/index";

import { ContractSelectSection } from "./section/ContractSelect";
import { LocationSelectSection } from "./section/LocationSelector";
import { ResignsProductsSection } from "./section/ResignsProducts";

export const ChooseBroadband = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const contractLength = useSelector<any, number>(
    (state) => state.wlrBroadband.contractLength
  );

  const showWlrResigns = useSelector<any, boolean>(
    (state) => state.uiState.showWlrResigns
  );

  const handleLineSearch = useCallback(() => {
    dispatch(doLineSearch());
  }, [dispatch]);

  useEffect(() => {
    handleLineSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let cardIndex = 0;

  return (
    <Box className={classes.root}>
      <ContractSelectSection cardIndex={++cardIndex} />

      {showWlrResigns && <ResignsProductsSection cardIndex={++cardIndex} />}

      {!!contractLength && <LocationSelectSection cardIndex={++cardIndex} />}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
});
