import { ShippingState } from "./types";

const initialState: ShippingState = {
  chosenProductId: "",
  products: [],
  isUpdatingShippingInApi: false,
  isFetchingShipping: false,
  response: {},
};

export default initialState;
