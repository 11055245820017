import React from "react";
import { Box, Typography } from "@mui/material";
import { getMuiThemePaletteColorByOrderType } from "../../../../helpers/getMuiThemePaletteColorByOrderType";
import { OrderType, ProductType } from "../../../../types";

interface Props {
  orderNumber: number;
  productCategory: ProductType;
  orderType: OrderType;
}

export const SubHeader: React.FunctionComponent<Props> = ({
  orderNumber,
  productCategory,
  orderType,
}) => {
  const muiThemePaletteColor = getMuiThemePaletteColorByOrderType(orderType);

  return (
    <Box sx={styles.root}>
      <div>
        <Typography variant="h6">#{orderNumber}</Typography>
      </div>
      <div>
        <Typography variant="h6">{productCategory}</Typography>
      </div>
      <div>
        <Typography variant="h6" color={`${muiThemePaletteColor}.main`}>
          {orderType}
        </Typography>
      </div>
    </Box>
  );
};

const styles = {
  root: {
    display: "flex",
    marginBottom: 1,
    textTransform: "capitalize",
    "& > div": {
      marginRight: 1,
    },
  },
};
