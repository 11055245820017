import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPricingRequest } from "../../../../store/order/actions";
import { CommercialPricingRequestSection } from "../../sections/CommercialPricingRequestSection";

export const CommercialPricingRequest = () => {
  const dispatch = useDispatch();

  const pricingRequest = useSelector(
    (state: any) => state.order.pricingRequest
  );

  useEffect(() => {
    if (!pricingRequest.fetching && !pricingRequest.response.status) {
      dispatch(getPricingRequest());
    }
  }, [pricingRequest, dispatch]);

  return (
    <CommercialPricingRequestSection
      pricingRequest={pricingRequest}
      loadPricingRequest={() => dispatch(getPricingRequest())}
    />
  );
};
