import React, { useState } from "react";
import { Button, Menu, MenuItem, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface RowActionsButtonProps {
  items: {
    label: string;
    dataCy: string;
    disabled: boolean;
    onClick: () => void;
  }[];
}

export const RowActionsButton = ({ items }: RowActionsButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-cy="ToolbarOptions"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={handleOpenMenu}
      >
        <div className={classes.text}>Options</div>
        <div className={classes.icon}>
          {!!anchorEl ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )}
        </div>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        {items.map(({ dataCy, label, disabled, onClick }) => (
          <MenuItem
            key={dataCy}
            data-cy={dataCy}
            disabled={disabled}
            className={classes.menuItem}
            onClick={onClick}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  text: {
    fontWeight: "bold",
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    borderRight: `1px solid ${theme.palette.primary.dark}`,
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.spacing(0.5)}`,
  },
  menuItem: {
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
