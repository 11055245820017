import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  getDynamicPropertyValue,
  getIsSOGEAOrFTTPUpgrade,
  makeGetDynamicPropertyInstances,
} from "../../../../../../../store/wlrBroadband/selectors";
import {
  doRemoteValidation,
  fetchProductData,
  setProductProperty,
  validateProductProperty,
  fetchBroadbandAppointments,
} from "../../../../../../../store/wlrBroadband/actions/configurations";
import {
  NEW_FTTP,
  NEW_SOGEA,
} from "../../../../../../../store/wlrBroadband/constants";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { SelectSiteVisitReason } from "../../../../../components/wlrBroadband/SelectSiteVisitReason";
import { SelectChangeEvent } from "@mui/material/Select";

interface SelectSiteVisitReasonContainerProps {
  productType: string;
  propertyName: string;
}

export const SelectSiteVisitReasonContainer = (
  props: SelectSiteVisitReasonContainerProps
) => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();
  const propertyInstances = useSelector((state: any) =>
    // @ts-ignore
    makeGetDynamicPropertyInstances()(state, { ...props, targetConfigs })
  );

  const ontType = useSelector((state) =>
    getDynamicPropertyValue(state, "broadband", targetConfigs[0], "bb.ONTType")
  );

  const configuration = useSelector(
    (state: any) => state.wlrBroadband.configurations[targetConfigs[0]]
  );

  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[configuration.locationIndex]
  );

  let property = propertyInstances[0];

  const propertyValidation = configuration.validation[props.propertyName];
  const propertyValue = useSelector((state: any) =>
    getDynamicPropertyValue(
      state,
      props.productType,
      targetConfigs[0],
      props.propertyName
    )
  );

  const [type, isUpgrade] = getIsSOGEAOrFTTPUpgrade(configuration);
  const isFTTP = location.type === NEW_FTTP;
  const isSOGEA = location.type === NEW_SOGEA || type === "SOGEA";

  // Don't display the select if property doesn't exist.
  // Also seems BE sometimes returns an empty property (Bug CM-4241)
  if (!property || !property.available_options) return null;

  // If property, add is_required
  property = {
    ...propertyInstances[0],
    is_required: ontType === "NEW" ? 1 : 0,
  };

  const availableOptions = property.available_options;

  // TP21765: Is there an existing ONT (Optical Network Termination). We use
  // this to determine what SVR (Site Visit Reasons) options to show for FTTP.
  const isExistingONT =
    _.get(location.broadbandSearch.response, "ont_details.ont_reference") &&
    _.get(
      location.broadbandSearch.response,
      "ont_details.ont_spare_data_ports",
      0
    ) > 0;

  const isMDUBuildComplete =
    _.get(
      location.broadbandSearch.response,
      "ont_details.mdu_build_complete",
      "0"
    ) === "1";

  const menuItems = availableOptions
    .filter((option: any) => {
      // TP21765: If this is not an existing ONT and mdu build is not
      // complete, then filter out the NO_SITE_VISIT option for FTTP.
      if (
        isFTTP &&
        !isExistingONT &&
        !isMDUBuildComplete &&
        option === "NO_SITE_VISIT"
      )
        return false;
      // TP12277: If a new connection for SOGEA, always filter out the
      // NO_SITE_VISIT option
      if (isSOGEA && option === "NO_SITE_VISIT") return false;
      // TP68504: If an FTTP upgrade, filter out NO_SITE_VISIT
      if (isUpgrade && type === "FTTP" && option === "NO_SITE_VISIT")
        return false;
      // Premium currently unavailable due to covid (as of 01/03/2021).
      if (option === "PREMIUM" && !isUpgrade) return false;
      return true;
    })
    .map((option: any) => ({
      value: option,
      label: property.available_option_map[option],
    }));

  const onSiteVisitReasonChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setProductProperty(
        targetConfigs,
        props.productType,
        props.propertyName,
        event.target.value
      )
    );
    targetConfigs.forEach((config) => {
      dispatch(
        fetchProductData({
          configurationIndex: config,
          productType: props.productType,
        })
      );
    });
    dispatch(
      validateProductProperty(
        targetConfigs,
        props.productType,
        props.propertyName,
        property
      )
    );
    targetConfigs.forEach((config) => {
      dispatch(doRemoteValidation({ configurationIndex: config }));
    });
    dispatch(fetchBroadbandAppointments(targetConfigs[0]));
  };

  return (
    <SelectSiteVisitReason
      property={property}
      propertyValidation={propertyValidation}
      propertyValue={propertyValue}
      menuItems={menuItems}
      onSiteVisitReasonChange={onSiteVisitReasonChange}
    />
  );
};
