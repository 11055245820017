import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StatusChip } from "@akj-dev/shared-components";

import { getNewOrder } from "../../../store/order/actions";

export const NewOrderContainer = () => {
  const dispatch = useDispatch();

  const orderStatus = useSelector((state: any) => state.order.orderStatus);

  useEffect(() => {
    dispatch(getNewOrder());
  }, [dispatch]);

  if (orderStatus.fetching)
    return (
      <StatusChip
        type="loading"
        title="Creating New Order"
        message="Please wait..."
      />
    );

  if (orderStatus.response.status === "error")
    return (
      <StatusChip
        type="error"
        title="Error creating order"
        message="Please contact support."
        retry={getNewOrder}
      />
    );

  return null;
};
