import { AcquisitionMethod, OrderStatus } from "../types";

export function getAcquisitionMethodDisplayLabel(
  acquisitionMethod: AcquisitionMethod
) {
  switch (acquisitionMethod) {
    case "new":
      return "New";
    case "portin":
      return "Port";
    case "Migration":
      return "Migration";
    case "full-term-renewal":
      return "Full Term Renewal";
    case "in-life-renewal":
      return "In Life Renewal";
    default:
      return acquisitionMethod;
  }
}

export function getOrderStatusDisplayLabel(status: OrderStatus) {
  switch (status) {
    case "in_progress":
      return "In Progress";
    case "committed":
      return "New";
    case "delayed":
      return "Delayed";
    case "attention":
      return "Attention";
    case "complete":
      return "Complete";
    case "cancelled":
      return "Cancelled";
    default:
      return "";
  }
}
