import React from "react";
import qs from "query-string";

import { Grid } from "@mui/material";

import { OrdersSummaryCard } from "./OrdersSummaryCard";
import { OrdersSummaryCardItem } from "./OrdersSummaryCardItem";

import { StatusBoardFilters } from "../../Status/Board/types";
import { DashboardFilters } from "../types";
import { OrderOverview } from "../../../store/overview/types";
import { OrderStatus, ProductType } from "../../../types";

type Props = {
  data: OrderOverview[];
  filters: DashboardFilters;
};
export function Overview({ data, filters }: Props) {
  const getTitle = (productType: ProductType) => {
    if (productType === "Fixed Line") {
      return "Fixed Lines & Calls";
    }
    return productType;
  };

  const getLocationDescriptorObject = (
    productType: ProductType,
    status?: OrderStatus[]
  ) => {
    const pathname = "/orders/status";

    // Generate the params that drive the filters to be pre applied on the Status view
    const params: Partial<StatusBoardFilters> = {
      product_type: productType,
      order_status: status,
      order_type: filters.order_type && [filters.order_type],
      created_after: filters.created_after,
    };

    return { pathname, search: qs.stringify(params) };
  };

  return (
    <Grid container spacing={2}>
      {data.sort(compareProductTypes).map((order) => {
        return (
          <Grid item lg={4} md={6} sm={12} xs={12} key={order.product_type}>
            <OrdersSummaryCard
              title={getTitle(order.product_type)}
              to={getLocationDescriptorObject(order.product_type)}
            >
              <OrdersSummaryCardItem
                value={order.new}
                title="New"
                to={getLocationDescriptorObject(order.product_type, [
                  "committed",
                ])}
              />
              <OrdersSummaryCardItem
                value={order.in_progress}
                title="In Progress"
                to={getLocationDescriptorObject(order.product_type, [
                  "in_progress",
                  "attention",
                  "delayed",
                ])}
              />
              <OrdersSummaryCardItem
                value={order.closed}
                title="Completed"
                to={getLocationDescriptorObject(order.product_type, [
                  "complete",
                ])}
              />
              <OrdersSummaryCardItem
                value={order.cancelled}
                title="Cancelled"
                to={getLocationDescriptorObject(order.product_type, [
                  "cancelled",
                ])}
              />
            </OrdersSummaryCard>
          </Grid>
        );
      })}
    </Grid>
  );
}

function compareProductTypes(a: OrderOverview, b: OrderOverview): number {
  const sortOrder: ProductType[] = [
    "Mobile",
    "Broadband",
    "Fixed Line",
    "Mixed",
  ];

  return (
    sortOrder.findIndex((status) => status === a.product_type) -
    sortOrder.findIndex((status) => status === b.product_type)
  );
}
