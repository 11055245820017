import { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { addMonths, format, getDate, startOfMonth } from "date-fns";

import {
  fetchProductInstances,
  fetchResignProductSearch,
  removeResign,
  requestResignPropertyValues,
  setResignProduct,
  setResignStartDate,
  setResignType,
} from "../actions";
import { transformMobileProductInstanceData } from "../transformers";
import { configDefaults } from "../defaults";
import { RESIGN_WITH_CHANGES } from "../constants";
import { addWeekdays, DC_DATE_FORMAT } from "../../../shared/utils/date";

export const resignsExtraReducers = (builder: any) => {
  builder
    .addCase(fetchProductInstances.pending, (state: any) => {
      state.productInstances.fetching = true;
    })
    .addCase(fetchProductInstances.fulfilled, (state: any, action: any) => {
      const response = transformMobileProductInstanceData(action.payload);
      if (
        state.productInstances?.response.results &&
        response.status === "success"
      ) {
        state.productInstances.fetching = false;
        // merge results
        state.productInstances.response.results = [
          ...state.productInstances.response.results,
          ...response.results,
        ];
      } else {
        state.productInstances.fetching = false;
        state.productInstances.response = response;
      }
    })
    .addCase(fetchResignProductSearch.pending, (state: any) => {
      state.resignProductSearch.fetching = true;
    })
    .addCase(
      fetchResignProductSearch.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.resignProductSearch.fetching = false;
        state.resignProductSearch.response = action.payload;
      }
    )
    // Create or update configs for resign products
    .addCase(
      setResignType,
      (
        state: any,
        action: PayloadAction<{ resignIds: string[]; resignType: string }>
      ) => {
        action.payload.resignIds.forEach((id) => {
          const index = state.configs.findIndex(
            (config: any) => config.resignId === id
          );
          if (index > -1) {
            state.configs[index].resignType = action.payload.resignType;
          } else {
            state.configs.push({
              resignId: id,
              resignType: action.payload.resignType,
            });
          }
        });
      }
    )
    // Set the new product on a resign config, or create if not already there.
    // TODO: There must be a simpler way to assign / create. Merge objects with comparison fn?
    .addCase(
      setResignProduct,
      (
        state: any,
        action: PayloadAction<{
          resignIds: string[];
          productId: string | false;
        }>
      ) => {
        const today = new Date();
        const defaultProperties = {
          ...configDefaults(state, action.payload.productId, {}, {}),
          acquisition_method: "resign",
          commission_type:
            state.contractLengthInMonthsAllProducts === 1
              ? "Residual"
              : "Upfront",
          port_date: addWeekdays(new Date(), 5, true),
          airtime_credit_start_date: format(
            getDate(today) === 1 ? today : addMonths(startOfMonth(today), 1),
            DC_DATE_FORMAT
          ),
        };

        action.payload.resignIds.forEach((id) => {
          const index = state.configs.findIndex(
            (config: any) => config.resignId === id
          );
          if (index > -1) {
            state.configs[index] = {
              ...state.configs[index],
              resignType: RESIGN_WITH_CHANGES,
              productId: action.payload.productId,
              properties: defaultProperties,
            };
          } else {
            state.configs.push({
              resignId: id,
              resignType: RESIGN_WITH_CHANGES,
              productId: action.payload.productId,
              properties: defaultProperties,
            });
          }
        });
      }
    )
    .addCase(
      removeResign,
      (state: any, action: PayloadAction<{ resignIds: string[] }>) => {
        state.configs = state.configs.filter(
          (config: any) => !action.payload.resignIds.includes(config.resignId)
        );
      }
    )
    .addCase(requestResignPropertyValues.pending, (state: any, action: any) => {
      const configId = action.meta.arg.configId;

      if (!state.configs[configId].resignPropertyValues) {
        state.configs[configId].resignPropertyValues = {};
      }

      state.configs[configId].resignPropertyValues.fetching = true;
    })
    .addCase(
      requestResignPropertyValues.fulfilled,
      (state: any, action: any) => {
        // Get any set current values to add to config properties
        // These will override any set by configDefaults() in SET_RESIGN_PRODUCT, which is what we want.
        let propertyUpdate: any = {};
        const configId = action.meta.arg.configId;
        const currentProperties = state.configs[configId].properties;
        const returnedProperties = _.get(
          action.payload,
          "mobile.dynamic_properties",
          {}
        );
        for (let k in returnedProperties) {
          const { current_value } = returnedProperties[k];
          if (
            current_value !== null &&
            k !== "port_date" // Port date is auto-filled by GS earlier.
          )
            propertyUpdate[k] = current_value;

          // Correct a DC error:
          // available_option_map for this field has string 1, but current_value can be number 1
          if (k === "is_sim_required" && current_value === 1)
            propertyUpdate[k] = "1";
          if (k === "is_sim_required" && current_value === 0)
            propertyUpdate[k] = "0";
          if (k === "airtime_credit_start_date")
            propertyUpdate[k] = currentProperties[k];
          // VF direct bars weren't setting for resigns: TP61936
          if (
            [
              "bar_69",
              "bar_70",
              "bar_71",
              "bar_72",
              "bar_73",
              "bar_74",
              "bar_75",
              "bar_76",
              "bar_77",
              "bar_78",
            ].includes(k)
          )
            propertyUpdate[k] = currentProperties[k];
        }

        state.configs[configId].resignPropertyValues = {
          fetching: false,
          status: action.payload.status,
          message: action.payload.message,
        };
        state.configs[configId].properties = {
          ...state.configs[configId].properties,
          ...propertyUpdate,
        };
      }
    )
    .addCase(setResignStartDate, (state: any, action: PayloadAction<any>) => {
      state.resignStartDate = action.payload;
    });
};
