import React, { useState, useEffect, SetStateAction, Dispatch } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateAccountFormValues } from "@akj-dev/shared-components";

import { fetchAllContacts } from "../../../store/account/actions";

import { AutoPopulateContact } from "../components/AutoPopulateContact";
import { getContacts } from "../../../store/account/selectors";

interface AutoPopulateContactProps {
  setDefaultValues: Dispatch<SetStateAction<CreateAccountFormValues>>;
}

/**
 * Auto-populate new account form.
 * Field names don't match up as usual though, hence the mapping here.
 * We went into this thinking they would :-(
 * https://gitlab.com/akj-dev/inbox/issues/526
 *
 * Note this is ported from old redux based form
 */
export const AutoPopulateContactContainer = ({
  setDefaultValues,
}: AutoPopulateContactProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const contacts = useSelector(getContacts);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllContacts(true));
  }, [dispatch]);

  const contactsList = contacts?.response?.data?.contacts || [];
  const hasContacts = contactsList.length > 0;

  const handleFillDetails = (i: number) => {
    setDefaultValues({
      company_postcode: contactsList[i].primary_address_postalcode,
      first_name: contactsList[i].first_name,
      last_name: contactsList[i].last_name,
      phone: contactsList[i].primary_telephone_number,
      mobile: contactsList[i].mobile_phone_number,
      email: contactsList[i].primary_email_address,
      ebilling_email: contactsList[i].primary_email_address,
    });
  };

  return (
    <AutoPopulateContact
      isContactError={contacts.response.status === "error"}
      hasContacts={hasContacts}
      onFillDetails={handleFillDetails}
      selectedIndex={selectedIndex}
      fetchingContact={contacts.fetching}
      contacts={contactsList}
      onContactSelect={(index) => setSelectedIndex(index)}
    />
  );
};
