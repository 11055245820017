import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Paper, { PaperProps } from "@mui/material/Paper";
import FileIcon from "@mui/icons-material/InsertDriveFile";

const StyledPaper = styled(Paper)<PaperProps>`
  margin: 20px;
  padding: 20px;
  vertical-align: baseline;
  svg {
    vertical-align: text-bottom;
  }
`;

interface FileDisplayProps {
  file: {
    name: string;
  };
}

export const FileDisplay = ({ file }: FileDisplayProps) => (
  <StyledPaper>
    <FileIcon /> {file.name}
  </StyledPaper>
);

FileDisplay.propTypes = {
  file: PropTypes.object.isRequired,
};
