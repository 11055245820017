import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import { ExpandingCard } from "@akj-dev/shared-components";
import EthernetIcon from "@mui/icons-material/SettingsEthernet";
import { EthernetTypeEnum } from "../../../store/ethernetProducts/constants";

interface EthernetCardProps {
  children: ReactNode;
  configuration: Record<string, any>;
}

export const EthernetCard = ({
  configuration,
  children,
}: EthernetCardProps) => {
  const { product, site_b_type, site_b_bearer, speed, type } = configuration;
  let subtitle;

  if (type === EthernetTypeEnum.ETHERNET_PRODUCT_GEA) {
    subtitle = `Bearer: ${site_b_bearer} Mbit/s`;
  } else {
    subtitle = `Bearer: ${site_b_bearer} Mbit/s - Speed: ${speed} Mbit/s`;
  }

  return (
    <ExpandingCard
      title={`${product} - ${site_b_type}`}
      subtitle={subtitle}
      avatar={<EthernetIcon />}
      defaultExpanded
    >
      {children}
    </ExpandingCard>
  );
};

EthernetCard.propTypes = {
  configuration: PropTypes.object.isRequired,
};
