import React from "react";
import { useSelector } from "react-redux";

import { ConfigTable } from "../../../components/UniversalProducts/ConfigTable";

export const ConfigTableContainer = () => {
  const configCount = useSelector<any, number>(
    (state) => state.universalProducts.configs.length
  );

  return <ConfigTable configCount={configCount} />;
};
