import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { StatusChip } from "@akj-dev/shared-components";
import { AccountSettings } from "@akj-dev/affinity-platform";

import { setOtherConfiguration } from "../../../../store/wlrBroadband/actions/configurations";
import {
  getIsWlrChangePossible,
  getIsWLTO,
  getMPFProducts,
  getMultiLineProducts,
  getSingleLineProducts,
  makeGetConfigsAtLocation,
  makeGetTransferProduct,
  getSOGEAProducts,
  makeGetRequiredSparePairs,
  isActivationCodeP as isActivationCodePSelector,
} from "../../../../store/wlrBroadband/selectors";
import {
  BROADBAND_ONLY,
  NEW_LINE,
  TRANSFER,
} from "../../../../store/wlrBroadband/constants";
import { getAccountSettings } from "../../../../store/account/selectors";
import { WorkingLineTakeOverSection } from "../section/ChooseLineProducts/WorkingLineTakeOver";
import { TransferProductSection } from "../section/ChooseLineProducts/TransferProduct";
import { BroadbandOnlySection } from "../section/ChooseLineProducts/BroadbandOnly";
import { NewLineSection } from "../section/ChooseLineProducts/NewLine";
import {
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "pages/StepChoose/Broadband/types";

interface ChooseLineProductsProps {
  locationIndex: number;
}

export const ChooseLineProductsContainer = ({
  locationIndex,
}: ChooseLineProductsProps) => {
  const dispatch = useDispatch();

  const requiredSparePairs = useSelector((state) =>
    //@ts-ignore
    makeGetRequiredSparePairs()(state, { locationIndex })
  );
  const isWlrChangePossible = useSelector((state) =>
    getIsWlrChangePossible(state, locationIndex)
  );
  const isWLTO = useSelector((state: any) =>
    getIsWLTO(state.wlrBroadband, locationIndex)
  );
  const sogeaProducts = useSelector((state: any) =>
    //@ts-ignore
    getSOGEAProducts(state, { locationIndex })
  );

  const locationType = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]?.type
  );
  const lineAvailability = useSelector(
    (state: any) =>
      state.wlrBroadband.locations[locationIndex]?.lineAvailability
  );
  const installationDetails = useSelector(
    (state: any) =>
      state.wlrBroadband.locations[locationIndex]?.installationDetails
  );
  const wltoDetails = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]?.wltoDetails
  );
  const broadbandSearch = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]?.broadbandSearch
  );
  const transferProduct = useSelector((state) =>
    //@ts-ignore
    makeGetTransferProduct()(state, { locationIndex })
  );
  const singleLineProducts = useSelector<any, WlrLocationProduct[]>(
    getSingleLineProducts
  );
  const multiLineProducts = useSelector<any, WlrLocationProduct[]>(
    getMultiLineProducts
  );
  const mpfProducts = useSelector<any, WlrLocationProduct[]>((state) =>
    getMPFProducts(state, { locationIndex })
  );
  const configurations = useSelector<any, WlrLocationProductConfig[]>((state) =>
    //@ts-ignore
    makeGetConfigsAtLocation()(state, { locationIndex })
  );
  const lineSearch = useSelector((state: any) => state.wlrBroadband.lineSearch);
  const accountSettings = useSelector<any, AccountSettings>(getAccountSettings);
  const isActivationCodeP = useSelector<any, boolean>((state) =>
    isActivationCodePSelector(state, { locationIndex })
  );

  const onSetOtherConfiguration = (
    locationIndex: number,
    product: Record<string, any>
  ) => dispatch(setOtherConfiguration({ locationIndex, product }));

  // Display loading conditions
  // Note: There are different calls depending on if it's a new or existing install

  if (lineAvailability.fetching || installationDetails.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Checking Line & Broadband Availability"
      />
    );

  if (broadbandSearch.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Searching for Broadband Products"
      />
    );

  // WLTO call happens after a failed installationDetails one, so show a separate loading message
  if (wltoDetails.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Validating Working Line Takeover"
      />
    );

  // Display error conditions.
  if (_.get(broadbandSearch.response, "status") === "error")
    return (
      <StatusChip
        type="error"
        title="Error"
        message={broadbandSearch.response.message}
      />
    );

  // New lines look for line availability at a property
  if (
    locationType === NEW_LINE &&
    _.get(lineAvailability.response, "status") === "error"
  ) {
    // FB126927 - we want to differentiate this error message from what comes back from DC. The advice for GS users is different.
    if (lineAvailability.response.message.includes("OR2450")) {
      return (
        <StatusChip
          type="error"
          title="Address Not Found"
          message="Openreach have deleted this address from CSS but not Siebel. This address is now unusable; if there is no suitable alternative address listed please pass this to the provisioning team to manually raise the order"
        />
      );
    }

    return (
      <StatusChip
        type="error"
        title="Error"
        message={lineAvailability.response.message}
      />
    );
  }

  // Transfer and BB only look for params of existing install.
  // If the line turn out to not be on BT, a WLTO call is attempted, leaving the failed installationDetails call in place
  // ...as we'll need the css_exchange_code from it to complete the order later...
  if (
    (locationType === TRANSFER || locationType === BROADBAND_ONLY) &&
    _.get(installationDetails.response, "status") === "error" &&
    _.get(wltoDetails.response, "status") !== "success"
  ) {
    return (
      <StatusChip
        type="error"
        title="Working Line Takeover Error"
        message={
          _.get(wltoDetails.response, "message") ||
          installationDetails.response.message
        }
      />
    );
  }

  // Display product options....
  // New lines: Single and multi-line, limited by lineAvailability (capacity) at address

  if (locationType === NEW_LINE) {
    return (
      <NewLineSection
        locationIndex={locationIndex}
        lineAvailability={lineAvailability}
        mpfProducts={mpfProducts}
        multiLineProducts={multiLineProducts}
        singleLineProducts={singleLineProducts}
        requiredSparePairs={requiredSparePairs}
        lineSearch={lineSearch}
        configurations={configurations}
      />
    );
  }

  // Transfers: Product matching service_type info retrieved by InstallationDetails call
  if (locationType === TRANSFER && transferProduct) {
    return (
      <TransferProductSection
        locationIndex={locationIndex}
        transferProduct={transferProduct}
        multiLineProducts={multiLineProducts}
        mpfProducts={mpfProducts}
        isActivationCodeP={isActivationCodeP}
        installationDetails={installationDetails}
        stopDaisyLineOrdersEnabled={
          accountSettings.as_stop_daisy_line_orders === "1"
        }
        onSetOtherConfiguration={onSetOtherConfiguration}
      />
    );
  }

  // Working Line Take over
  if (isWLTO) {
    return (
      <WorkingLineTakeOverSection
        locationIndex={locationIndex}
        wltoDetails={wltoDetails}
        singleLineProducts={singleLineProducts}
        onSetOtherConfiguration={onSetOtherConfiguration}
      />
    );
  }

  // We can't supply broadband to a non-BT line, and if that's the case, a WLTO check will have been carried out. Advise the user... FB126268
  if (
    locationType === BROADBAND_ONLY &&
    _.get(wltoDetails.response, "line_plant_type") === "WORKING_LINE"
  ) {
    return (
      <StatusChip
        type="info"
        title="This line is not on the BT network."
        message="Please choose Line Transfer to perform a Working Line Takeover if required."
      />
    );
  }

  if (
    locationType === TRANSFER &&
    installationDetails.response.service_type &&
    !transferProduct
  ) {
    return (
      <StatusChip
        type="error"
        title="No line transfer product available for this location."
        message={
          <span>
            Service type is reported as{" "}
            <b>{installationDetails.response.service_type}</b>. For further
            details please see the Provisioning teams
          </span>
        }
      />
    );
  }

  // Broadband only: A dummy product that will later have broadband added to it
  // ....it'll be on a line still, just not one of ours. Hence this data structure makes sense.

  // TODO: Seems sensible to use installationDetails here as additional check. New though, so confirm. ....check it's PSTN_EXISTING_LINE?
  // TODO: Show some of the extra info that comes back from that check... company name n that
  if (
    locationType === BROADBAND_ONLY &&
    _.get(installationDetails.response, "service_type")
  ) {
    return (
      <BroadbandOnlySection
        locationIndex={locationIndex}
        isWlrChangePossible={isWlrChangePossible}
        sogeaProducts={sogeaProducts}
        onSetOtherConfiguration={onSetOtherConfiguration}
      />
    );
  }

  return null;
};
