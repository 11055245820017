import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { updateETFMethod } from "../../../store/order/actions";
import { ETFMethod } from "../components/ETFMethod";

export const ETFMethodContainer = () => {
  const dispatch = useDispatch();

  const fetching = useSelector<any, boolean>(
    (state) => state.order.orderStatus.fetching
  );
  const orderStatus = useSelector<any, any>(
    (state) => state.order.orderStatus.response
  );

  return (
    <ETFMethod
      fetching={fetching}
      etfMethod={_.get(orderStatus, "data.etf_method", null)}
      validETFMethods={_.get(orderStatus, "data.valid_etf_methods", [])}
      updateETFMethod={(event) => dispatch(updateETFMethod(event))}
    />
  );
};
