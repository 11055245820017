import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box } from "@mui/material";

interface DeclinedCreditVetWarningProps {
  creditVetPassed: boolean;
}

export const DeclinedCreditVetWarning = ({
  creditVetPassed,
}: DeclinedCreditVetWarningProps) => (
  <>
    {!creditVetPassed && (
      <Box paddingBottom={1}>
        <Alert
          type="error"
          message={
            <>
              <b>Declined</b>
              <br />
              Order cannot be continued due to Declined Credit Check Result
            </>
          }
        />
      </Box>
    )}
  </>
);
