import React from "react";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useTheme } from "@mui/material";

import { getTotalPrice } from "../../../../../../store/wlrBroadband/selectors";
import { Chip } from "../../../../../../shared/components/Chip/Chip";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

interface MPFProductChangesSubRowProps {
  productInstance: Record<string, any>;
  resignProduct: Record<string, any>;
  removeResign: () => void;
  productInstanceType: string;
}

export const MPFProductChangesSubRow = ({
  productInstance,
  productInstanceType,
  resignProduct,
  removeResign,
}: MPFProductChangesSubRowProps) => {
  const theme = useTheme();

  const cli = productInstance.pin;
  const isResignProduct = resignProduct.components[0].is_resign;
  const name = resignProduct.name_for_resign || resignProduct.name;
  const price = resignProduct.price.first_bill_recurring_price_with_promotions;

  return (
    <TableRow>
      <TableCell style={{ backgroundColor: "#e0e0e0" }} />
      <TableCell colSpan={2}>{cli}</TableCell>
      <TableCell colSpan={2}>
        <CustomCell>
          {productInstanceType === "wlr" ? (
            <PhoneIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          ) : (
            <PublicIcon
              style={{ color: theme.palette.grey[500], marginRight: 10 }}
            />
          )}
          <div>{name}</div>
        </CustomCell>
      </TableCell>
      <TableCell />
      <TableCell>
        &pound;
        {isResignProduct ? getTotalPrice(productInstance.evo_services) : price}
      </TableCell>
      <TableCell />
      <TableCell style={{ paddingRight: 0 }}>
        <Chip
          title="Resign"
          color={theme.palette.primary.main}
          Icon={AutorenewIcon}
          onClick={removeResign}
        />
      </TableCell>
    </TableRow>
  );
};
