import React from "react";
import Icon from "@mui/icons-material/Book";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

export const MPFDirectoryEnquiry = () => (
  <Section
    title="Directory Enquiry (MPF)"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.businessName"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.businessDescription"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.businessSuffix"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.type"
    />

    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.thoroughfareNumber"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.thoroughfareName"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.premisesName"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.subPremises"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.postTown"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.businessDirectory.address.postcode"
    />
  </Section>
);
