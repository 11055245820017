import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doRemoteValidation,
  fetchProductData,
  removeProductDiscount,
  setProductDiscount,
} from "../../../../../../../store/wlrBroadband/actions/configurations";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { WlrChangePriceField } from "../../../../../components/wlrBroadband/WlrChangePriceField";

interface WlrChangePriceFieldProps {
  propertyNamePrefix: string;
  label: string;
}

export const WlrChangePriceFieldContainer = ({
  propertyNamePrefix,
  label,
}: WlrChangePriceFieldProps) => {
  const { targetConfigs } = useTargetConfigsContext();
  const dispatch = useDispatch();
  const propertyValue = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfigs[0]].broadbandProperties[
        `${propertyNamePrefix}_discount_value`
      ]
  );

  // Could be string or number 1.
  const discountEnabled = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfigs[0]].broadbandProperties[
        `${propertyNamePrefix}_discount_override`
      ] == 1 // eslint-disable-line eqeqeq
  );

  return (
    <WlrChangePriceField
      label={label}
      discountEnabled={discountEnabled}
      propertyValue={propertyValue}
      onProductDiscountChange={(e) => {
        // TODO: Call of setProductDiscount is not properly typed - need revalidation
        dispatch(
          // @ts-ignore

          setProductDiscount(
            targetConfigs,
            "broadband",
            // @ts-ignore
            propertyNamePrefix,
            e.target.value
          )
        );
      }}
      onProductDiscountBlur={() => {
        dispatch(
          fetchProductData({
            configurationIndex: targetConfigs[0],
            productType: "broadband",
          })
        );
        dispatch(doRemoteValidation({ configurationIndex: targetConfigs[0] }));
      }}
      onRemoveDiscount={() => {
        dispatch(
          removeProductDiscount(targetConfigs, "broadband", propertyNamePrefix)
        );
        dispatch(
          fetchProductData({
            configurationIndex: targetConfigs[0],
            productType: "broadband",
          })
        );
      }}
    />
  );
};
