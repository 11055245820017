import React, { ReactNode } from "react";
import _ from "lodash";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

interface GenerateQuoteProps {
  open: boolean;
  children: ReactNode;
  generateQuote: Record<string, any>;
  quoteContactId: string;
  quoteId: string;
  handleClose: () => void;
  closeAndReset: () => void;
  doGenerateQuote: () => void;
}

export const GenerateQuote = ({
  open,
  children,
  generateQuote,
  quoteContactId,
  quoteId,
  handleClose,
  closeAndReset,
  doGenerateQuote,
}: GenerateQuoteProps) => (
  <Dialog
    data-cy="GenerateQuote"
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>Generate Quote</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions sx={{ margin: (theme) => theme.spacing(-4, 1, 1, 1) }}>
      {quoteId || generateQuote.response.status === "error" ? (
        <Button variant="contained" onClick={closeAndReset}>
          OK
        </Button>
      ) : (
        <>
          <Button onClick={handleClose} disabled={generateQuote.fetching}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={doGenerateQuote}
            disabled={generateQuote.fetching || _.isEmpty(quoteContactId)}
          >
            Send
          </Button>
        </>
      )}
    </DialogActions>
  </Dialog>
);
