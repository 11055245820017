import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SaleStepEnum } from "../enums";
import { ChipInfo } from "../components/Chip/ChipInfo";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

type ReduxTitleAndInfoType = {
  steps: SaleStepEnum[];
  activeStep: SaleStepEnum;
  orderId: string;
  accountId: string;
  platformCustomerReference: string;
  opportunityNumber: string;
  opportunityName: string;
  opportunityId: string;
  leadNumber: string;
  baseUrl: string;
  leadName: string;
};

export const TitleAndInfo = ({ title }: { title: string }) => {
  const classes = useStyles();

  const {
    orderId,
    accountId,
    platformCustomerReference,
    opportunityName,
    opportunityNumber,
    opportunityId,
    leadNumber,
    baseUrl,
    leadName,
  } = useSelector<any, ReduxTitleAndInfoType>((state) => ({
    orderId: state.order.id,
    accountId: state.order.accountId,
    platformCustomerReference: state.order.platformCustomerReference,
    opportunityName: state.order.opportunityName,
    opportunityNumber: state.order.opportunityNumber,
    opportunityId: state.order.opportunityId,
    leadNumber: state.order.leadNumber,
    steps: state.uiState.steps,
    activeStep: state.uiState.activeStep,
    baseUrl: state.order.baseUrl,
    leadName: state.order.leadName,
  }));
  const hostEnv = useSelector(getHostEnv);
  const withHref = hostEnv === "dc";

  const chipLabels = useMemo(() => {
    const labels: { label: string; href?: string }[] = [];

    if (withHref) {
      [
        {
          condition: !!accountId,
          label: `Account: ${platformCustomerReference ?? accountId}`,
          href: `${baseUrl}CustomerService/AccountOverview/${accountId}`,
        },
        {
          condition: !!opportunityId,
          label: `Opportunity ID: ${opportunityNumber}`,
          href: `${baseUrl}CustomerService/Opportunities/view/${opportunityId}`,
        },
        {
          condition: !!orderId,
          label: `Order ID: ${orderId}`,
          href: `${baseUrl}Orders/edit/${orderId}`,
        },
      ].forEach(({ condition, label, href }) => {
        if (condition) {
          labels.push({ label, href });
        }
      });
    } else {
      [
        { condition: !!leadName, label: `Company Name: ${leadName}` },
        {
          condition: !!accountId,
          label: `Account: ${platformCustomerReference ?? accountId}`,
        },
        { condition: !!leadNumber, label: `Prospect: ${leadNumber}` },
        {
          condition: !!opportunityName,
          label: `Opportunity Name: ${opportunityName}`,
        },
        { condition: !!orderId, label: `Order ID: ${orderId}` },
      ].forEach(({ condition, label }) => {
        if (condition) {
          labels.push({ label });
        }
      });
    }

    return labels;
  }, [
    withHref,
    orderId,
    accountId,
    opportunityId,
    opportunityNumber,
    opportunityName,
    leadNumber,
    platformCustomerReference,
    baseUrl,
    leadName,
  ]);

  return (
    <Box
      className={classes.title}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h2">{title}</Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        {chipLabels.map(({ label, href }) => (
          <ChipInfo key={label} label={label} href={href} />
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(3.5),
  },
}));
