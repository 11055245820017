import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, SelectChangeEvent } from "@mui/material";
import { IndexedCardInfo, StatusChip } from "@akj-dev/shared-components";
import {
  doLineSearch,
  setContractLength,
} from "../../../../store/wlrBroadband/actions/index";
import { setLocationType } from "../../../../store/wlrBroadband/actions/locations";
import { getAccountSettings } from "../../../../store/account/selectors";
import { getAccountId } from "../../../../store/order/selectors";
import { ReduxAccountSettingsEnum } from "../../../../shared/enums";
import { ReduxWlrLocation } from "../types";
import { NEW_LINE } from "../../../../store/wlrBroadband/constants";
import { ContractLengthSelect } from "../../../../shared/components/ContractLength/Select";
import { ResignsToggleContainer } from "../containers/Resigns/ResignsToggle";
import { useDwsResellerEnabled } from "../../../../shared/hooks/useDwsResellerEnabled";
import * as ProductAPI from "../../../../api/v1/product";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";
import { useAccountSettings } from "../../../../shared/hooks/useAccountSettings";

interface BroadbandContractSectionProps {
  cardIndex: number;
}

export const ContractSelectSection = ({
  cardIndex,
}: BroadbandContractSectionProps) => {
  const dispatch = useDispatch();
  const contractLength = useSelector<any, number>(
    (state) => state.wlrBroadband.contractLength
  );
  const locations = useSelector<any, ReduxWlrLocation[]>(
    (state) => state.wlrBroadband.locations
  );
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >((state) => getAccountSettings(state));
  const accountSettings = useAccountSettings();

  const accountId = useSelector(getAccountId);
  const hostEnv = useSelector(getHostEnv);

  const userResignsPermission = useSelector(
    (state: any) => state.order.userResignsPermission
  );
  const isDwsResellerEnabled = useDwsResellerEnabled();

  const canRestrictContractTerms =
    accountSettings.can_restrict_contract_terms_by_product === "1";

  const handleLineSearch = useCallback(() => {
    dispatch(doLineSearch());
  }, [dispatch]);

  const handleContractLengthChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const length = parseInt(event.target.value, 10);
      dispatch(setContractLength(length));

      // Contract length of 3 months is only available for New Line.
      if (length === 3) {
        locations.forEach((_, index) =>
          dispatch(setLocationType(index, NEW_LINE))
        );
      }

      // Contract length can influence price
      handleLineSearch();
    },
    [dispatch, handleLineSearch, locations]
  );

  const [length, setLegnth] = useState<number[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    async function getContractLengths(type: string) {
      setLoading(true);
      const response = await ProductAPI.availableContractLengths(type);
      if (response.status === "success") {
        setLegnth(response.terms.map(Number));
        setLoading(false);
      } else {
        setError(response.message);
        setLoading(false);
      }
    }

    canRestrictContractTerms && hostEnv !== "dc"
      ? getContractLengths("Broadband").catch((e) => setError(e))
      : setLegnth([1, 3, 12, 24, 36, 48, 60, 72]);
  }, [canRestrictContractTerms, hostEnv]);

  const showSecondPart = !!contractLength;
  const showResignsToggle =
    showSecondPart &&
    !!accountId &&
    (settings.as_wlrbb_resign_enabled === "1" ||
      // eslint-disable-next-line eqeqeq
      userResignsPermission == "1");

  return (
    <IndexedCardInfo
      index={cardIndex}
      title="Please select the required contract length"
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item md={3}>
          {error && <StatusChip message={error} type="error" />}
          {loading && (
            <StatusChip
              type="loading"
              title="Please wait"
              message="Checking Contract Length Availability"
            />
          )}
          {!loading && length && (
            <ContractLengthSelect
              contractLength={contractLength}
              handleChange={handleContractLengthChange}
              possibleContractLengths={length}
              isDisabled={loading || isDwsResellerEnabled}
            />
          )}
        </Grid>
        <Grid item md={9}>
          {showResignsToggle && <ResignsToggleContainer />}
        </Grid>
      </Grid>
    </IndexedCardInfo>
  );
};
