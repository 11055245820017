import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { ThumbUp, ThumbDown } from "@mui/icons-material";

interface ApproveQuoteProps {
  approveOrRejectApproval: (approve: boolean) => void;
  isFetching: boolean;
  isError: boolean;
}

export const ApproveQuote = ({
  isFetching,
  isError,
  approveOrRejectApproval,
}: ApproveQuoteProps) => {
  if (isFetching)
    return (
      <Box ml={1}>
        <CircularProgress color="inherit" />
      </Box>
    );

  if (isError)
    return (
      <div>Sorry, there has been an error with your approval request.</div>
    );

  return (
    <Box>
      <Button
        color="inherit"
        variant="outlined"
        endIcon={<ThumbUp />}
        onClick={() => approveOrRejectApproval(false)}
        style={{ marginRight: 10 }}
      >
        Approve
      </Button>
      <Button
        color="inherit"
        variant="outlined"
        endIcon={<ThumbDown />}
        onClick={() => approveOrRejectApproval(true)}
      >
        Reject
      </Button>
    </Box>
  );
};
