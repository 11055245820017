import React from "react";

import { Box } from "@mui/material";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { SelectContact } from "../../../shared/containers/SelectContact";

interface ContactsProps {
  cardIndex: string;
  selectedId: string;
  onOrderContactChange: (id: string) => void;
}

export const Contacts = ({
  cardIndex,
  selectedId,
  onOrderContactChange,
}: ContactsProps) => (
  <IndexedCardInfo index={cardIndex} title="Contact">
    <Box display="flex" alignItems="flex-end">
      <SelectContact current={selectedId} handleUpdate={onOrderContactChange} />
    </Box>
  </IndexedCardInfo>
);
