import React from "react";
import styled from "styled-components";
import { CheckIcon } from "@akj-dev/shared-components";

// quote
// signed
// unsigned
// approved
// cancelled
// complete
// rejected
// approval
// Resign
// re_sign_signed
// re_sign_approved
// re_sign_completed
// re_sign_rejected
// re_sign_unsigned
// re_sign_approval

interface OrderStatusChipProps {
  status: string;
}

export const OrderStatusChip = ({ status }: OrderStatusChipProps) => {
  switch (status) {
    case "quote":
      return (
        <Chip>
          <CheckIcon />
          Quote
        </Chip>
      );
    default:
      return <Chip>{status}</Chip>;
  }
};

const Chip = styled.div`
  vertical-align: middle;
`;
