import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectEthernetQuote,
  updateEthernetCustomerData,
} from "../../../../store/ethernetProducts/actions";
import { getConfigRecurringPrice } from "../../../../store/ethernetProducts/selectors";
import { getAccountSettings } from "../../../../store/account/selectors";
import { EthernetConfiguration } from "../../components/Ethernet/EthernetConfiguration";
import { EthernetTypeEnum } from "../../../../store/ethernetProducts/constants";

interface EthernetConfigurationProps {
  configuration: any;
  configIndex: number;
}
export const EthernetConfigurationContainer = ({
  configIndex,
  configuration,
}: EthernetConfigurationProps) => {
  const dispatch = useDispatch();

  const accountSettingRoundEthernetPrices = useSelector<any, boolean>(
    (state) =>
      getAccountSettings(state).round_ethernet_prices_to_next_whole_pound ===
      "1"
  );
  const accountName = useSelector<any, string>(
    (state) => state.order.accountName
  );

  const { amortised } = configuration.purchaseArgs;
  const isEFM = configuration.type === EthernetTypeEnum.ETHERNET_PRODUCT_EFM;

  return (
    <EthernetConfiguration
      configIndex={configIndex}
      configuration={configuration}
      amortised={amortised}
      isEFM={isEFM}
      getRecurringPrice={(data) =>
        getConfigRecurringPrice(
          configuration,
          data,
          true,
          accountSettingRoundEthernetPrices
        )
      }
      onAmortizeChange={(_, checked) =>
        dispatch(updateEthernetCustomerData(configIndex, "amortised", checked))
      }
      onSelectEthernet={(data) =>
        dispatch(
          selectEthernetQuote(
            configIndex,
            data.id,
            data.technology,
            accountName
          )
        )
      }
    />
  );
};
