import { getProductSupplier } from "./selectors/resigns";
import { format } from "date-fns";
import { DC_DATE_FORMAT } from "../../shared/utils/date";
import { startOfMonth, getDate, addMonths } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { addressTypes } from "../hardwareDelivery/constants";

/**
 * Build default mobile config properties
 *
 * Some dynamic properties are not defaulted correctly in the product data returned from DC
 * While this should be sorted there, not here, it hasn't been. For ages. So we create overrides here.
 *
 * @param state
 * @param productId
 * @param settings
 * @returns Object
 */
export const configDefaults = (
  state,
  productId,
  settings,
  isOneMonthContract
) => {
  const supplier = getProductSupplier(state, productId);

  return {
    as_frontend_uuid: uuidv4(),
    wwcap_enabled: 1,
    internet: "yes",
    wap: "yes",
    service_wifi_calling: 1, // Wifi calling Enabled?
    service_mms: 1, // MMS Service enabled by default
    tethering: "yes", // Tethering
    ...createBars(supplier, settings.dws_reseller_enabled === "1"),
    // TP26482: Set these values if DWS Reseller.
    ...(settings.dws_reseller_enabled === "1" && {
      wwcap_enabled: 0,
      bundle_notifications_opt_out: 1,
    }),
    // Note account settings are asserted on here as part of Ella's TP8150 where resellers have to provide a SIM ID
    // See also https://akj-hq.slack.com/archives/GTFHJENFQ/p1611232050081200
    // Settings are passed blank when this is called from a resign (SET_RESIGN_PRODUCT) as apparently resellers will not
    // be resigning yet.
    sim_is_buffer: settings.as_pre_dispatched_sim_required === "1" ? "1" : "0", // Yes.... the API does return decimal 1 and string 0. and various other things. Oh god.
    sim_buffer_serial: "",
    sim_type: "triple", // Default SIM type to Triple.
    is_sim_required:
      settings.as_pre_dispatched_sim_required === "1" ? "0" : "1", // tp22146
    commission_type:
      settings.as_commission_type_selection_for_mobile === "1"
        ? isOneMonthContract
          ? "Residual"
          : "Upfront"
        : null, // if we show it, default it, if we don't show it, we don't want to send anything
    partner_reference_number: "",
    take_advance: settings.can_access_vf_direct === "1" ? "1" : "0",
  };
};

/**
 * Create new config object with default values
 * @param state
 * @param productId
 * @param settings
 * @returns object
 */
export const newConfig = (state, productId, settings, isOneMonthContract) => {
  const today = new Date();
  return {
    productId: productId,
    properties: {
      ...configDefaults(state, productId, settings, isOneMonthContract),
      acquisition_method: "new",
      activation_date: format(today, DC_DATE_FORMAT),
      airtime_credit_start_date: format(
        getDate(today) === 1 ? today : addMonths(startOfMonth(today), 1),
        DC_DATE_FORMAT
      ),
    },
    delivery: {
      // for SIMs - TP59307
      addressType: addressTypes.BILLING,
      addressId: "",
    },
    validation: {},
    orderProduct: {},
    pacCodeCheck: {
      fetching: false,
      response: {},
      error: false,
    },
    stacCodeCheck: {
      fetching: false,
      response: {},
    },
  };
};

function createBars(supplier, isReseller = false) {
  const SUPPLIER_VF_DIRECT_BARS = {
    bar_69: 0, // Admin/Lost SIM bar
    bar_70: 0, // GPRS Data Bar
    bar_71: 0, // MMS Bar
    bar_72: 0, // Receive MMS Bar
    bar_73: 0, // Send MMS Bar
    bar_74: 0, // Roaming Bar
    bar_75: 1, // Adult Content Bar... saucy
    bar_76: 1, // International and Premium Calls Bar
    bar_77: 1, // Premium Calls Bar
    bar_78: 0, // International Calls Bar
  };

  const SUPPLIER_O2_BARS = {
    bar_52: 1, // Premium
    bar_56: 1, // International
    bar_58: 1, // SMS Chat
    bar_59: 1, // SMS Adult
    bar_61: 1, // Roaming Standard
    bar_66: 1, // Adult Premium
    bar_67: 1, // MPAY
  };

  const SUPPLIER_OTHER_BARS = {
    bar_36: 1, // International and Premium Calls
    bar_41: 1, // Roaming
    bar_44: 1, // Premium SMS
    bar_45: 1, // GPRS (Roam)
    bar_67: 1, // MPAY
  };

  let supplierDefaults;

  if (supplier === "VF Direct") {
    supplierDefaults = SUPPLIER_VF_DIRECT_BARS;
  } else if (supplier === "02") {
    supplierDefaults = SUPPLIER_O2_BARS;
  } else {
    supplierDefaults = SUPPLIER_OTHER_BARS;
  }

  if (isReseller) {
    return {
      bar_31: 0, // Admin
      bar_32: 0, // GPRS
      bar_36: 0, // International and Premium Calls
      bar_37: 0, // Premium
      bar_40: 0, // Outgoing
      bar_41: 0, // Roaming
      bar_42: 0, // International
      bar_44: 0, // Premium SMS
      bar_45: 0, // GPRS (Roam)
      bar_67: 0, // MPAY
      bar_68: 0, // Combined SMS
      // TP26477: Default all bars to off if DWS Reseller.
      ...Object.keys(supplierDefaults).reduce(
        (acc, id) => ({
          ...acc,
          [id]: 0,
        }),
        {}
      ),
    };
  } else {
    return supplierDefaults;
  }
}
