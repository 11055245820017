import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box } from "@mui/material";

export const CliBoltOnFiltered = () => (
  <Box paddingBottom={1}>
    <Alert
      type="warning"
      message="Bolt on selection is restricted by shared compatibility when configuring more than one Product Family."
    />
  </Box>
);
