import React from "react";

import { Typography, Box, Grid } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";
import _ from "lodash";
import { WlrLocationProductCardContainer } from "../../containers/WlrLocation/ProductCard";

interface WorkingLineTakeOverProps {
  locationIndex: number;
  wltoDetails: Record<string, any>;
  singleLineProducts: Record<string, any>[];
  onSetOtherConfiguration: (
    locationIndex: number,
    product: Record<string, any>
  ) => void;
}

export const WorkingLineTakeOverSection = ({
  locationIndex,
  wltoDetails,
  singleLineProducts,
  onSetOtherConfiguration,
}: WorkingLineTakeOverProps) => (
  <div>
    <Typography variant="h4" gutterBottom>
      Choose WLTO transfer product
    </Typography>
    <Box mb={1}>
      <StatusChip
        type="info"
        title="This will be a working line takeover of an MPF line."
        message={`Access Line ID: ${_.get(
          wltoDetails.response,
          "existing_line_id",
          "Unknown"
        )}`}
      />
    </Box>
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <WlrLocationProductCardContainer
          locationIndex={locationIndex}
          productId={singleLineProducts[0].id}
          title={singleLineProducts[0].name}
          subtitle={`from £${singleLineProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
          handleClick={() =>
            onSetOtherConfiguration(locationIndex, singleLineProducts[0])
          }
        />
      </Grid>
    </Grid>
  </div>
);
