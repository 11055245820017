import React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

interface FilterProps {
  value: string;
  setFilter: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  clearFilter: () => void;
}

export const Filter = ({ value, setFilter, clearFilter }: FilterProps) => {
  return (
    <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
      <Grid item>
        {value ? (
          <IconButton onClick={clearFilter} size="large">
            <CancelIcon />
          </IconButton>
        ) : (
          <IconButton size="large">
            <SearchIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item>
        <TextField
          variant="standard"
          label="Search"
          name="filter"
          value={value}
          onChange={setFilter}
          fullWidth
          margin="normal"
        />
      </Grid>
    </Grid>
  );
};
