import { RequestDataAction, REQUEST_DATA } from "./types";
import { SearchAPIResponse, getSearch } from "../../api/v1/search";
import { call, put, takeLatest } from "redux-saga/effects";
import { setData, setError } from "./actions";
import { ErrorAPI } from "../../api/helpers";

function* fetchBoardData(action: RequestDataAction) {
  try {
    const apiData: SearchAPIResponse[] = yield call(getSearch, action.payload);
    yield put(setData(apiData));
  } catch (e) {
    const error = e as ErrorAPI;
    yield put(setError(error));
  }
}

export function* watchRequestBoard() {
  yield takeLatest(REQUEST_DATA, fetchBoardData);
}
