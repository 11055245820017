import React from "react";

import { FormControlLabel, Switch, MenuItem, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { StatusChip } from "@akj-dev/shared-components";

import { ProductSelect } from "../../../../shared/components/ProductSelect";
import { ReduxEthernetConfiguration } from "../types";

export interface EthernetConfigurationFailOverProps {
  configuration: ReduxEthernetConfiguration;
  onUpdateConfiguration: (
    propertyName: string,
    newValue: string | boolean
  ) => void;
}

export const EthernetConfigurationFailOver = ({
  configuration,
  onUpdateConfiguration,
}: EthernetConfigurationFailOverProps) => {
  const classes = useStyles();

  const { backup_line, backup_broadband, backup_broadband_technology } =
    configuration;

  return (
    <div>
      <div className={classes.toggleGroup}>
        <FormControlLabel
          control={
            <Switch
              data-cy="ethernet-backup-line"
              onChange={(event) =>
                onUpdateConfiguration("backup_line", event.target.checked)
              }
              checked={backup_line}
            />
          }
          label="Backup Line"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Switch
              data-cy="ethernet-backup-broadband"
              onChange={(event) =>
                onUpdateConfiguration("backup_broadband", event.target.checked)
              }
              checked={backup_broadband}
            />
          }
          label="Backup Broadband"
          labelPlacement="end"
        />
      </div>
      <ProductSelect
        label="Technology"
        dataCy="ethernet-fail-over"
        value={backup_broadband_technology}
        handleChange={(newTechnology) =>
          onUpdateConfiguration("backup_broadband_technology", newTechnology)
        }
      >
        <MenuItem value="Fibre">Fibre</MenuItem>
        <MenuItem value="ADSL">ADSL</MenuItem>
      </ProductSelect>
      {backup_broadband && (
        <StatusChip
          type="info"
          message={`Quote prices will include ${backup_broadband_technology} Failover`}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  toggleGroup: {
    paddingBottom: theme.spacing(1.5),
  },
}));
