import { useMemo } from "react";

import { StockLevelResponse } from "../types";
import { QuantityInfoType } from "../enum";
import { ReduxFetchType } from "../../../../store/_root/types";

interface QuantityInfoTypeHandlerProps {
  maxQuantity: number;
  hasReachedMaxConfigCount: boolean;
  quantity?: number;
  stockLevel?: ReduxFetchType<StockLevelResponse>;
  getStockLevel?: () => void;
}

/**
 * custom useMemo that returns type and message for a QuantityInfo component
 * based on the attributes
 */
export const useQuantityInfoTypeHandler = ({
  quantity,
  hasReachedMaxConfigCount,
  maxQuantity,
  stockLevel,
  getStockLevel,
}: QuantityInfoTypeHandlerProps) =>
  useMemo(() => {
    const { fetching, response } = stockLevel ?? {};
    const { stock = 0 } = response ?? {};
    const withStock = !!getStockLevel;

    let type = QuantityInfoType.INFO;
    let message = "Select Qty";

    if (quantity === undefined || quantity === 0 || fetching) {
      return { type, message };
    }

    if (hasReachedMaxConfigCount) {
      type = QuantityInfoType.QTY_ERROR;
      message = `Max ${maxQuantity}`;

      return { type, message };
    }

    if (withStock) {
      if (quantity > stock) {
        type = QuantityInfoType.STOCK_ERROR;
        message = `${stock} in Stock`;
      } else {
        type = QuantityInfoType.SUCCESS;
        message = `${stock} in Stock`;
      }
    }

    return { type, message };
  }, [
    quantity,
    hasReachedMaxConfigCount,
    maxQuantity,
    stockLevel,
    getStockLevel,
  ]);
