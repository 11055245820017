import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  BROADBAND_ONLY,
  NEW_SOGEA,
  TRANSFER,
} from "../../../../store/wlrBroadband/constants";
import {
  doBroadbandSearch,
  fetchInstallationDetails,
  fetchLineAvailability,
} from "../../../../store/wlrBroadband/actions/locations";
import { isValidPhoneNumber } from "../../../../shared/utils/validation";
import { FindProducts } from "../components/FindProducts";

interface FindProductsProps {
  locationIndex: number;
}

/**
 * Find broadband products and line availability / installation details for a
 * a location with a defined address.
 */
export const FindProductsContainer = ({ locationIndex }: FindProductsProps) => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );

  // Transfer and BB also need to know the CLI. ....because that has the existing services.
  const disabled =
    ((location.type === TRANSFER || location.type === BROADBAND_ONLY) &&
      !isValidPhoneNumber(location.cli.value)) ||
    !location.address.addressReference;

  const onFindProducts = () => {
    // Find line availability and BB products at the address
    dispatch(fetchLineAvailability(locationIndex));
    dispatch(doBroadbandSearch({ locationIndex }));
    // Transfers and broadband only flows need to find out about the existing services at the address.
    if (
      location.type === TRANSFER ||
      location.type === BROADBAND_ONLY ||
      location.type === NEW_SOGEA
    ) {
      dispatch(fetchInstallationDetails(locationIndex));
    }
  };

  return <FindProducts disabled={disabled} onClick={onFindProducts} />;
};
