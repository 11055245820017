import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface ReservedNumbersProps {
  mobileNumber: string;
  options: string[];
  onMobileNumberChange: (event: SelectChangeEvent<string>) => void;
}

export const ReservedNumbers = ({
  mobileNumber,
  options,
  onMobileNumberChange,
}: ReservedNumbersProps) => (
  <FormControl fullWidth margin="normal">
    <InputLabel variant="standard">Mobile Number</InputLabel>
    <Select
      variant="standard"
      name="mobile_number"
      value={mobileNumber || "0"}
      onChange={onMobileNumberChange}
    >
      <MenuItem value="0">Assign from available numbers</MenuItem>
      {options.map((number: string) => (
        <MenuItem value={number} key={number}>
          {number}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
