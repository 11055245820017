import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Spinner } from "../../../../shared/components/Spinner";

interface ConfigurationStatusProps {
  onClick?: () => void;
  configuration: Record<string, any>;
  isConfigValid: boolean;
}

export const ConfigurationStatus = ({
  configuration,
  isConfigValid,
  onClick,
}: ConfigurationStatusProps) => {
  // Local validation check...
  // let configured = true
  // for(let prop in configuration.validation) {
  //     if(configuration.validation[prop].length > 0) configured = false
  // }

  // DC validation check

  const classes = useStyles();
  const fetching =
    configuration.wlrValidation.fetching ||
    configuration.broadbandValidation.fetching;

  return (
    <div className="ConfigurationStatus">
      {fetching ? (
        <Spinner size={30} />
      ) : (
        <span>
          {isConfigValid ? (
            <Chip
              label="Configured"
              size="small"
              color="success"
              variant="filled"
              className={classes.configured}
              data-cy="wlrConfigStatusOK"
            />
          ) : (
            <Chip
              label="Not Configured"
              size="small"
              color="error"
              variant="filled"
              onClick={onClick}
              className={classes.notConfigured}
            />
          )}
        </span>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  configured: {
    backgroundColor: "#4caf50",
  },
  notConfigured: {
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#b63429",
    },
  },
}));

ConfigurationStatus.propTypes = {
  configuration: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
