import React from "react";

import { NoProductChangesRow } from "../../components/Resigns/StandardProductInstance/NoProductChangesRow";
import { ProductChangesRow } from "../../components/Resigns/StandardProductInstance/ProductChangesRow";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelectorContainer } from "../../containers/Resigns/BroadbandProductSelector";
import { RowActionsContainer } from "../../containers/Resigns/RowActions";

import { StandardProductBaseProps } from "./model";

export const BroadbandProductOverview = ({
  configuration,
  isChecked,
  productInstance,
  isLinkedProductInstance,
  products,
  toggleRow,
  setChanges,
  setNoChanges,
  removeResign,
}: StandardProductBaseProps) => {
  const resignProduct = products.find(
    (product) => product.id === configuration.broadbandProductId
  );

  return (
    <>
      {configuration.broadbandProductId && resignProduct ? (
        <ProductChangesRow
          configuration={configuration}
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          resignProduct={resignProduct}
          canAddBroadband={false}
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              removeResign={removeResign}
              hasProductId={configuration.broadbandProductId}
              resignProduct={resignProduct}
            />
          }
        />
      ) : (
        <NoProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              setChanges={setChanges}
              setNoChanges={setNoChanges}
              removeResign={removeResign}
              hasProductId={configuration.broadbandProductId}
              resignProduct={resignProduct}
            />
          }
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        !configuration.broadbandProductId && (
          <BroadbandProductSelectorContainer
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};
