import React from "react";
import _ from "lodash";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material";

import { SmallButton } from "../../../../../shared/components/SmallButton";
import { InlinePriceIndicator } from "../../../../../shared/components/Pricing/InlinePriceIndicator";

interface AddBroadbandProps {
  location: Record<string, any>;
  installationDetails: Record<string, any>;
  broadbandSearch: Record<string, any>[];
  fetching: boolean;
  isMultiLineProduct: boolean;
  isActivationCodeP: boolean;
  broadbandProductId: string;
  showSelect: boolean;
  handleShowSelect: () => void;
  onResignBroadbandChange: (event: SelectChangeEvent<string | boolean>) => void;
}

export const AddBroadband = ({
  location,
  fetching,
  isMultiLineProduct,
  isActivationCodeP,
  installationDetails,
  broadbandProductId,
  broadbandSearch,
  showSelect,
  handleShowSelect,
  onResignBroadbandChange,
}: AddBroadbandProps) => {
  const theme = useTheme();

  if (fetching) {
    return <SmallButton secondary label="Loading..." />;
  }

  if (location && _.isEmpty(location.address))
    return <SmallButton secondary label="No Broadband" disabled />;

  if (
    isMultiLineProduct ||
    _.get(installationDetails, "response.service_type") ===
      "PSTN_MULTI_LINE_AUX"
  )
    return <SmallButton secondary label="No Broadband" disabled />;

  if (isActivationCodeP)
    return <SmallButton secondary label="No Broadband" disabled />;

  return (
    <div>
      {showSelect || broadbandProductId ? (
        <Select
          value={broadbandProductId || false}
          onChange={onResignBroadbandChange}
          fullWidth={true}
          data-cy="AddResignBroadbandSelect"
        >
          <MenuItem value="">None</MenuItem>
          {broadbandSearch.map((product: any) => {
            if (product.first_broadband_component?.type === "MPF") return null;
            if (product.first_broadband_component?.type === "FTTP") return null;

            return (
              <MenuItem
                style={{ width: "100%" }}
                value={product.id}
                key={product.id}
              >
                {
                  <div>
                    <b>{product.name}</b>
                    {product.price
                      .first_bill_recurring_price_with_promotions !==
                      product.recurring_price_without_promotions && (
                      <InlinePriceIndicator />
                    )}
                    <div
                      style={{
                        fontSize: "0.9em",
                        color: theme.palette.text.disabled,
                      }}
                    >
                      {product.description}{" "}
                      <span
                        style={{
                          color: theme.palette.success.main,
                          fontSize: "0.9em",
                          paddingLeft: 8,
                        }}
                      >
                        £
                        {
                          product.price
                            .first_bill_recurring_price_with_promotions
                        }
                      </span>
                    </div>
                  </div>
                }
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <SmallButton
          secondary
          label="Add Broadband"
          onClick={handleShowSelect}
        />
      )}
    </div>
  );
};
