import React from "react";
import styled from "styled-components";
import { startOfMonth } from "date-fns";

import { TextField } from "@mui/material";
import DateIcon from "@mui/icons-material/DateRange";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { todaysDateString } from "../../../../shared/utils/date";

interface ResignStartDateProps {
  startDate: string;
  onDateChange: (date: Date | null) => void;
}

export const ResignStartDate = ({
  startDate,
  onDateChange,
}: ResignStartDateProps) => (
  <Outer>
    <Icon />
    <DatePicker
      value={startDate || todaysDateString}
      label="Resign Start Date"
      onChange={(date: Date | null) => {
        onDateChange(date);
      }}
      minDate={startOfMonth(new Date())}
      inputFormat="dd/MM/yyyy"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          size="small"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
    />
  </Outer>
);

const Outer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`;

const Icon = styled(DateIcon)`
  margin-right: 10px;
`;
