import { PayloadAction } from "@reduxjs/toolkit";

import {
  setContractLengthAllMobileProducts,
  setContractLengthUIFilter,
  toggleCoTerminus,
} from "../actions";
import { CommissionType } from "../../../shared/types/MobileProductConfig";
import { fetchAccountSettings } from "../../account/actions";

export const contractLengthExtraReducers = (builder: any) => {
  builder
    .addCase(fetchAccountSettings.fulfilled, (state: any, action: any) => {
      if (action.payload?.settings?.dws_reseller_enabled === "1") {
        state.contractLengthInMonthsAllProducts = 1;
      }
    })

    .addCase(
      setContractLengthAllMobileProducts.fulfilled,
      (
        state: any,
        action: PayloadAction<{
          contractLength: number;
          canSelectCommission: boolean;
          date?: string;
        }>
      ) => {
        const { contractLength, canSelectCommission, date } = action.payload;
        // Reset any airtime credit durations on existing configs.
        // They can only be 1 month or contract length, so become invalid.
        const resetConfigs = state.configs.map((config: any) => ({
          ...config,
          properties: {
            ...config.properties,
            airtime_credit_duration: null,
            commission_type: canSelectCommission
              ? contractLength === 1
                ? CommissionType.RESIDUAL
                : CommissionType.UPFRONT
              : null,
          },
        }));

        state.contractLengthInMonthsAllProducts = contractLength;
        state.configs = resetConfigs;
        if (date) {
          state.coTerminusContractEndDate = date;
        }
      }
    )
    .addCase(setContractLengthUIFilter, (state: any, action: any) => {
      state.contractLengthInMonthsUIFilter = action.payload;
    })
    .addCase(toggleCoTerminus, (state: any) => {
      state.contractCoTerminus = !state.contractCoTerminus;
      state.contractLengthInMonthsAllProducts = false;
    });
};
