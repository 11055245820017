import React from "react";
import { TextField, InputProps, TextFieldProps } from "@mui/material";

export interface BrowserDateFieldProps {
  name?: string;
  value: string;
  label: string;
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  error?: boolean;
  InputProps?: InputProps;
  onChange: (date: string) => void;
  onBlur?: (event: any) => void;
  hasMargin?: boolean;
  minDate?: string;
  maxDate?: string;
  size?: TextFieldProps["size"];
}

export const BrowserDateField = ({
  name,
  value,
  label,
  disabled = false,
  fullWidth = false,
  helperText,
  error = false,
  InputProps,
  onChange,
  onBlur,
  hasMargin = false,
  minDate,
  maxDate,
  size,
}: BrowserDateFieldProps) => {
  return (
    <TextField
      name={name}
      value={value}
      onChange={(event) => onChange(event.target.value || value)}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      helperText={helperText}
      error={error}
      InputProps={{
        ...InputProps,
        ...{
          inputProps: {
            ...(minDate && { min: minDate }),
            ...(maxDate && { max: maxDate }),
          },
        },
      }}
      type="date"
      onBlur={onBlur}
      margin={hasMargin ? "normal" : "none"}
      InputLabelProps={{ shrink: true }}
      size={size}
    />
  );
};
