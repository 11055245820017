import React from "react";
import { TabContext, TabList, TabPanel as MuiTabPanel } from "@mui/lab";
import { Tab, Divider } from "@mui/material";

interface Props {
  infoPanel: React.ReactNode;
  commentsPanel: React.ReactNode;
}

export const TabPanel: React.FunctionComponent<Props> = ({
  infoPanel,
  commentsPanel,
}) => {
  const [activeTab, setActiveTab] = React.useState("info");
  const handleTabChange = (event: any, selectedTab: string) => {
    setActiveTab(selectedTab);
  };

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={handleTabChange}
        aria-label="info or comment tabs"
        indicatorColor="primary"
      >
        <Tab label="Order Info" value="info" />
        <Tab label="Comments" value="comments" />
      </TabList>
      <Divider />
      <MuiTabPanel sx={styles.root} value="info">
        {infoPanel}
      </MuiTabPanel>
      <MuiTabPanel sx={styles.root} value="comments">
        {commentsPanel}
      </MuiTabPanel>
    </TabContext>
  );
};

const styles = {
  root: {
    paddingTop: 1,
    paddingLeft: 0,
    paddingBottom: 1,
    paddingRight: 0,
  },
};
