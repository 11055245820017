import React, { ReactNode } from "react";
import { IndexedCardInfo } from "@akj-dev/shared-components";
import { ContractLengthSelect } from "../../../../shared/components/ContractLength/Select";
import { SelectChangeEvent } from "@mui/material/Select";

interface UniversalProductsLayoutProps {
  children: ReactNode;
  contractLength: number;
  onContractLengthChange: (event: SelectChangeEvent<string>) => void;
}

export const UniversalProductsLayout = ({
  contractLength,
  children,
  onContractLengthChange,
}: UniversalProductsLayoutProps) => (
  <div>
    <IndexedCardInfo index="1" title="Please select contract length">
      <ContractLengthSelect
        contractLength={contractLength}
        possibleContractLengths={[12, 24, 36, 48, 60, 72]}
        handleChange={onContractLengthChange}
      />
    </IndexedCardInfo>
    {!!contractLength && (
      <IndexedCardInfo index="2" title="Please choose your product(s)">
        {children}
      </IndexedCardInfo>
    )}
  </div>
);
