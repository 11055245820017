import React, { useMemo } from "react";
import _ from "lodash";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DeviceHub } from "@mui/icons-material";

type CliBoltOnSelectFieldProps = {
  title: string;
  targetConfigs: number[];
  boltOnType: string;
  hasSelectedCliTopUpBoltOn: boolean;
  selectedCliBoltOn: string[];
  availableCliBoltOns: { id: number; name: string };
  onChange: (configId: number, boltOnType: string, value: number) => void;
};

export const CliBoltOnSelectField = ({
  title,
  targetConfigs,
  boltOnType,
  hasSelectedCliTopUpBoltOn,
  selectedCliBoltOn,
  availableCliBoltOns,
  onChange,
}: CliBoltOnSelectFieldProps) => {
  const classes = useStyles();
  const allSameBoltOn = useMemo(
    () => selectedCliBoltOn.every((val, i, arr) => val === arr[0]),
    [selectedCliBoltOn]
  );
  return (
    <div>
      {!allSameBoltOn && !hasSelectedCliTopUpBoltOn ? (
        <Box paddingTop={1}>
          <InputLabel shrink={true} className={classes.box}>
            {title}
          </InputLabel>
          <Box display="flex">
            <Box marginRight="auto">
              <Chip
                label="Multiple"
                variant="filled"
                color="default"
                icon={<DeviceHub />}
              />
            </Box>
            <Box marginLeft="auto">
              <Box>
                <Button
                  size="small"
                  color="error"
                  onClick={() => {
                    targetConfigs.forEach((targetConfig) =>
                      onChange(targetConfig, boltOnType, 0)
                    );
                  }}
                >
                  Remove All
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <FormControl fullWidth margin="normal">
          <InputLabel variant="standard">{title}</InputLabel>
          <Select
            variant="standard"
            onChange={(event) =>
              targetConfigs.forEach((targetConfig) =>
                onChange(targetConfig, boltOnType, event.target.value as number)
              )
            }
            value={hasSelectedCliTopUpBoltOn ? 0 : selectedCliBoltOn[0]}
            disabled={boltOnType === "data" && hasSelectedCliTopUpBoltOn}
          >
            {[{ id: 0, name: "Not required" }]
              .concat(availableCliBoltOns)
              .map((boltOn) => {
                const price = _.get(
                  boltOn,
                  ["price", "first_bill_recurring_price_with_promotions"],
                  false
                );
                return (
                  <MenuItem value={boltOn.id} key={boltOn.id}>
                    {boltOn.name + (price ? " - £" + price : "")}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    paddingBottom: 10,
  },
});
