import React, { useCallback, useRef, useState } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { VirtualTableProps } from "./types";
import { VirtualTableHeader } from "./VirtualTableHeader";
import { ScrollButton } from "./ScrollButton";
import { NoDataPlaceholder } from "./NoDataPlaceholder";
import { useTableHeight } from "./utils";

export const VirtualTable = <TData,>({
  headers,
  activeSort,
  handleSort,
  data,
  tableSize,
  itemSize,
  itemCount,
  RenderTableRow,
  withScrollButton = false,
  NoDataComponent = NoDataPlaceholder,
  NoDataIcon,
  noDataText,
}: VirtualTableProps<TData>) => {
  const classes = useStyles();
  const listRef = useRef();
  const [showScrollButton, setShowScrollButton] = useState(withScrollButton);
  const [offsetZero, setOffsetZero] = useState(true);

  const handleScroll = useCallback(
    ({ scrollOffset }: { scrollOffset: number }) => {
      if (scrollOffset !== 0) {
        setShowScrollButton(false);
        setOffsetZero(false);
      } else {
        setOffsetZero(true);
      }
    },
    [setShowScrollButton, setOffsetZero]
  );

  const scrollable = itemCount > tableSize / itemSize;
  const tableHeight = useTableHeight(itemCount, itemSize, tableSize);

  return (
    <Box className={classes.wrapper}>
      <Box
        style={{
          width: "fit-content",
          minWidth: "100%",
          height: tableHeight,
        }}
      >
        <Box
          boxShadow={offsetZero ? 0 : 3}
          style={{ paddingRight: scrollable ? 40 : 24 }}
          className={classes.header}
        >
          {headers.map((header) => (
            <VirtualTableHeader
              key={header.sortIndex}
              header={header}
              activeSort={activeSort}
              handleSort={handleSort}
            />
          ))}
        </Box>
        {itemCount ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                outerRef={listRef}
                itemData={data}
                itemSize={itemSize}
                itemCount={itemCount}
                height={height - 48}
                width={width}
                onScroll={handleScroll}
              >
                {RenderTableRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <NoDataComponent Icon={NoDataIcon} text={noDataText} />
        )}
      </Box>
      {scrollable && showScrollButton && (
        <ScrollButton
          listRef={listRef}
          setShowScrollButton={setShowScrollButton}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    overflow: "auto",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(0.25),
  },
  header: {
    width: "fit-content",
    minWidth: "100%",
    height: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
    transition: "box-shadow  0.5s",
  },
}));
