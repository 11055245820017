import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setShowMobileResigns } from "../../../../../store/uiState/actions";
import { ResignsToggle } from "../../components/Resigns/ResignsToggle";

export const ResignsToggleContainer = () => {
  const dispatch = useDispatch();
  const showMobileResigns = useSelector<any, boolean>(
    (state) => state.uiState.showMobileResigns
  );

  const handleShowResigns = () => {
    dispatch(setShowMobileResigns());
  };

  return (
    <ResignsToggle
      showMobileResigns={showMobileResigns}
      handleShowResigns={handleShowResigns}
    />
  );
};
