import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Box,
  Button,
  useTheme,
} from "@mui/material";

interface SelectedProductProps {
  discountPercentage: number;
  amortised: boolean;
  selectedResult: {
    supplier: string;
    technology: string;
    contract_length_in_months: string;
    one_off_price: string;
    router_name: string;
  };
  recurringPriceWithDiscounts: number;
  recurringPriceWithoutDiscounts: number;
  handleSelectDifferentQuote: () => void;
}

export const SelectedProduct = ({
  discountPercentage,
  amortised,
  selectedResult,
  recurringPriceWithDiscounts,
  recurringPriceWithoutDiscounts,
  handleSelectDifferentQuote,
}: SelectedProductProps) => {
  const theme = useTheme();

  return (
    <>
      <Box my={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Supplier</TableCell>
              <TableCell>Technology</TableCell>
              <TableCell>Term (Months)</TableCell>
              <TableCell>Router Name</TableCell>
              <TableCell>One Off</TableCell>
              <TableCell>Monthly</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{selectedResult.supplier}</TableCell>
              <TableCell>{selectedResult.technology}</TableCell>
              <TableCell>{selectedResult.contract_length_in_months}</TableCell>
              <TableCell>{selectedResult.router_name}</TableCell>
              <TableCell>
                &pound;{amortised ? "0.00" : selectedResult.one_off_price}
              </TableCell>
              {discountPercentage !== 0 ? (
                <TableCell>
                  &pound;{recurringPriceWithDiscounts.toFixed(2)}
                  <span style={{ color: theme.palette.text.disabled }}>
                    {" "}
                    (&pound;{recurringPriceWithoutDiscounts.toFixed(2)})
                  </span>
                </TableCell>
              ) : (
                <TableCell>
                  &pound;{recurringPriceWithDiscounts.toFixed(2)}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box my={1}>
        <Button variant="contained" onClick={handleSelectDifferentQuote}>
          Select Different Quote
        </Button>
      </Box>
    </>
  );
};
