import { getJSON, postJSON } from "../helpers/core";
import { Base64Encode } from "../../shared/utils/base64";

/**
 * Get product data
 * @param account
 * @param product_id
 * @param instanceId - Optional instance ID for getting predefined resign property values
 * @param {object} params - when we get product data with full OrderProduct params instead....
 * Params should be as close to that in the final OrderProduct/Crate call as possible.
 * Hence we sometimes use the same selector function to define them as OrderProduct/Create
 * @param order_id

 * @returns {Promise<*>}
 */
export const productData = (
  account: string,
  product_id: string,
  instanceId = false,
  params: any = {},
  order_id: string
) =>
  getJSON("GuidedSales/ProductData", {
    is_guided_sales_order: 1,
    account,
    product_id,
    order_id,
    // If this is a call to get info on an existing instance for a resign, these params are needed too:
    ...(instanceId && {
      "mobile-product_instance_id": instanceId,
      "mobile-product_instance_type": "resign",
    }),
    // ....note we don't just use the Create call param selector for this like with WLR
    // as the data is not 1:1 per config.
    ...params,
  });

/**
 * Save wizard state
 * @param order_id
 * @param state
 * @returns {Promise<*>}
 */
export const SaveWizardState = (order_id: string, state: any) =>
  postJSON("GuidedSales/SaveWizardState", {
    order_id,
    content: Base64Encode(state),
  });

/**
 * Load Saved Draft State
 * This is a Base64 string, not JSON like the rest of the API, hence can't use the base fetch method.
 * @param order_id
 * @returns {Promise<*>}
 */
export const LoadWizardState = (order_id: string) =>
  getJSON<LoadWizardStateResponse>("GuidedSales/LoadWizardState", { order_id });

export type LoadWizardStateResponse = {
  content?: string;
  opportunity_number?: string;
  wizard_id?: string;
  order_id?: string;
  opportunity_name?: string;
  status?: string;
  message?: string;
  date_entered?: string;
};

export const AddPricingRequestTaskGet = (account: string, order: string) =>
  getJSON<AddPricingRequestTaskGetResponse>(
    "GuidedSales/AddPricingRequestTask",
    {
      account,
      order,
    }
  );

/**
 * Slimmed down DC task object
 * (full one is ~2800 props so no good for these API responses)
 * See TP1187
 */
export type DCSlimPricingTask = {
  name: string;
  status: string;
  assigned_user_name: string;
  date_entered_local: string;
  date_modified_local: string;
  note_description: string | null;
};

export type AddPricingRequestTaskGetResponse = {
  status: string;
  message?: string;
  task?: DCSlimPricingTask;
};

/**
 * Add Pricing Request Task.
 * TP1187
 * @param account
 * @param order
 * @param task
 * @constructor
 */
export const AddPricingRequestTaskPost = (
  account: string,
  order: string,
  task: string
) =>
  postJSON<AddPricingRequestTaskPostResponse>(
    "GuidedSales/AddPricingRequestTask",
    {
      account,
      order,
      task,
    }
  );

export type AddPricingRequestTaskPostResponse = {
  status: string;
  message?: string;
  task?: DCSlimPricingTask;
};
