import React from "react";
import _ from "lodash";

import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { StatusChip } from "@akj-dev/shared-components";

import { Spinner } from "../../../../shared/components/Spinner";

interface ChooseBroadbandAppointmentProps {
  appointments: Record<string, any>;
  addBroadbandAppointment: Record<string, any>;
  isSalesPerson: boolean;
  productComponentType: string;
  siteVisitReason: string;
  onChooseBroadbandAppointment: (event: SelectChangeEvent<any>) => void;
  onRetry: () => void;
  onAddAppointment: () => void;
}

export const ChooseBroadbandAppointment = ({
  appointments,
  addBroadbandAppointment,
  siteVisitReason,
  productComponentType,
  isSalesPerson,
  onRetry,
  onAddAppointment,
  onChooseBroadbandAppointment,
}: ChooseBroadbandAppointmentProps) => {
  if (appointments.fetching)
    return (
      <StatusChip
        type="loading"
        title="Please Wait"
        message="Loading Broadband Appointments"
      />
    );

  if (productComponentType === "SOGEA" && siteVisitReason === null)
    return (
      <StatusChip
        type="info"
        message="You must provide a site visit reason to view available appointments."
      />
    );

  if (_.get(appointments.response, "status") === "error")
    return (
      <StatusChip
        type="error"
        title="Sorry"
        message="Something went wrong fetching broadband appointments."
        retry={onRetry}
      />
    );

  return (
    <div>
      <FormControl fullWidth margin="normal">
        <InputLabel variant="standard">
          Choose your Broadband Appointment time
        </InputLabel>
        <Select
          variant="standard"
          onChange={onChooseBroadbandAppointment}
          value={appointments.selectedIndex}
          style={{ width: "100%" }}
          disabled={
            addBroadbandAppointment.fetching ||
            addBroadbandAppointment?.response.status === "success"
          }
          data-cy="bbAppointmentSelect"
        >
          <MenuItem value="" />
          {appointments?.response.appointments.map((a: any, i: number) => (
            <MenuItem value={i} key={a.string}>
              {a.string}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {appointments.selectedIndex !== null && (
        <p>
          This appointment date is only reserved for 4 hours. If the contract is
          signed after 4 hour of receipt the next available appointment will be
          given.
        </p>
      )}
      {
        // TP32603: If the user is a sales person, then actual reserving of
        // the appointment will take place later, after account creation and
        // just before ordering of the products.
        !isSalesPerson && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={
              addBroadbandAppointment.fetching && (
                <Spinner size={30} color="#aaa" />
              )
            }
            onClick={onAddAppointment}
            disabled={
              addBroadbandAppointment.fetching ||
              addBroadbandAppointment?.response.status === "success" ||
              appointments.selectedIndex === null
            }
            data-cy="reserveBbAppointment"
          >
            Reserve Broadband Appointment
          </Button>
        )
      }
      {addBroadbandAppointment?.response.status === "success" && (
        <StatusChip
          type="success"
          title="Appointment Reserved"
          message={`Reference: ${addBroadbandAppointment?.response.appointment_reference}`}
          style={{ marginTop: 20 }}
        />
      )}
      {addBroadbandAppointment?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Sorry"
          message="Something went wrong reserving your WLR appointment."
        />
      )}
    </div>
  );
};
