import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NumberParam,
  StringParam,
  withDefault,
  useQueryParams,
} from "use-query-params";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";

import Main from "../../layout/Main";
import Table from "./components/Table";

import { RootState } from "../../store/rootReducer";
import { searchAccounts } from "../../store/autocomplete/actions";
import { fetch, remove, setStatus } from "../../store/notifications/actions";

import {
  NotificationTypeQueryParam,
  SortDirectionQueryParam,
  TriggerValueQueryParam,
} from "./types";

function Notifications() {
  const { count, data, error, loading } = useSelector(
    (state: RootState) => state.notifications
  );

  const { accounts } = useSelector((state: RootState) => state.autocomplete);

  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    account_id: StringParam,
    name: StringParam,
    email: StringParam,
    type: NotificationTypeQueryParam,
    values: TriggerValueQueryParam,
    only_active: NumberParam,
    limit: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, "type"),
    order: withDefault(SortDirectionQueryParam, "asc"),
  });

  const resetQuery = () => {
    let newQuery: { [key: string]: any } = {};

    for (let filter in query) {
      newQuery[filter] = undefined;
    }

    setQuery(newQuery);
  };

  useEffect(() => {
    dispatch(fetch(query));
  }, [query, dispatch]);

  return (
    <Main error={error} title="Notifications" loading={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row-reverse">
            <Button
              color="primary"
              component={Link}
              size="medium"
              startIcon={<AddIcon />}
              to="/orders/new-notification"
              variant="contained"
            >
              New Notification
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Table
            accounts={accounts}
            count={count}
            data={data}
            loading={loading}
            query={query}
            remove={(id: number) => dispatch(remove(id))}
            resetQuery={resetQuery}
            searchAccounts={(query: string) => dispatch(searchAccounts(query))}
            setQuery={setQuery}
            setStatus={(id: number, is_active: Boolean) =>
              dispatch(setStatus(id, is_active))
            }
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export default Notifications;
