import React from "react";
import _ from "lodash";

import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import { getNewAddressFieldValidation } from "../../../shared/utils/addresses";

interface AddressEntryFieldsProps {
  disabled: boolean;
  fields: string[];
  existingAddress: Record<string, any>;
  newAddress: Record<string, any>;
  updateField: (fieldName: string, value: string) => void;
}

interface FieldValidation {
  error: boolean;
  message: string;
  required: boolean;
}

export const AddressEntryFields = ({
  existingAddress,
  newAddress,
  fields,
  disabled,
  updateField,
}: AddressEntryFieldsProps) => {
  const fieldLabels = fields.map((f) =>
    _.capitalize(f)
      .replace(/([^a-zA-Z_])/g, " $1")
      .replace(/_/g, " ")
  );

  return (
    <Grid container spacing={1}>
      {fields.map((field, i) => {
        const value =
          (newAddress.hasOwnProperty(field)
            ? newAddress[field]
            : _.get(existingAddress, field)) || "";

        const fieldValidation = getNewAddressFieldValidation(
          field,
          value
        ) as FieldValidation;

        const fieldProps = {
          label: fieldLabels[i],
          value,
          id: field,
          onChange: updateField,
          disabled,
          required: fieldValidation.required,
        };

        if (field === "country")
          return (
            <Grid item md={3} key={fieldProps.id}>
              <FormControl fullWidth error={fieldValidation.error}>
                <InputLabel variant="standard">{`${fieldLabels[i]}${
                  fieldProps.required ? " *" : ""
                }`}</InputLabel>
                <Select
                  variant="standard"
                  {...fieldProps}
                  onChange={(event) =>
                    updateField("country", event.target.value as string)
                  }
                >
                  <MenuItem value="United Kingdom" key="uk">
                    <b>United Kingdom</b>
                  </MenuItem>
                  {_.get(existingAddress, "countries", []).map(
                    (country: string) => (
                      <MenuItem value={country} key={country}>
                        {country}
                      </MenuItem>
                    )
                  )}
                </Select>
                {fieldValidation.error && (
                  <FormHelperText error={!!fieldValidation.error}>
                    {fieldValidation.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          );

        return (
          <Grid item md={3} key={fieldProps.id}>
            <TextField
              variant="standard"
              {...fieldProps}
              fullWidth
              onChange={(event) =>
                updateField(event.target.id, event.target.value)
              }
              error={fieldValidation.error}
              helperText={fieldValidation.error && fieldValidation.message}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
