import React from "react";
import { Alert } from "@akj-dev/shared-components";

interface VodafoneSpendCapWarningProps {
  spendCapsNotSet: boolean;
}

export const VodafoneSpendCapWarning = ({
  spendCapsNotSet,
}: VodafoneSpendCapWarningProps) => (
  <>
    {!spendCapsNotSet && (
      <Alert
        type="warning"
        message={
          <ul>
            <li>
              The Spend Cap will not be active until the next billing period
              following the date of order completion
            </li>
          </ul>
        }
      />
    )}
  </>
);
