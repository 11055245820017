import _ from "lodash";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as GuidedSalesAPI from "../../../api/v1/guidedSales";
import { checkCommissionTag } from "../../account/selectors";
import newConfiguration from "../state/newConfiguration";
import newBroadbandProduct from "../state/newBroadbandProduct";
import * as WlrBroadbandAPI from "../../../api/v1/wlrBroadband";
import * as OrderProductAPI from "../../../api/v1/orderProduct";
import {
  EXISTING_LINE,
  NEW_FTTP,
  RESIGN,
  NEW_SOGEA,
  EXISTING_SOGEA,
  NUMBER_RES_NEXT,
  NEW_PRODUCT,
} from "../constants";
import {
  getConfigBBServiceType,
  getDCOrderParams,
  getDynamicPropertyValue,
  getIsMPFLine,
} from "../selectors";
import { addWeekdays } from "../../../shared/utils/date";
import { getAccountId } from "../../order/selectors";
import { removeSpecialRate } from "../actions/index";
import { setLocationAddress } from "../actions/locations";
import {
  mapToProductInstanceAddress,
  mapToLocationAddress,
} from "../../../shared/utils/addresses";
import {
  fetchLineAvailabilityForResign,
  doBroadbandSearchForResign,
} from "./resigns";

export const setBroadbandProduct = createAction(
  "wlrBroadband/setBroadbandProduct",
  (configurationIndex: number, productId: string) => ({
    payload: { configurationIndex, productId },
  })
);

export const setProductProperty = createAction(
  "wlrBroadband/setProductProperty",
  (
    targetConfigs: any,
    productType: string | null,
    propertyName: string,
    value: any
  ) => ({
    payload: {
      targetConfigs,
      productType,
      propertyName,
      value,
    },
  })
);

export const setPricingScheme = createAction(
  "wlrBroadband/setPricingScheme",
  (targetConfig: any, productType: string, schemeName: string) => ({
    payload: {
      targetConfig,
      productType,
      schemeName,
    },
  })
);

export const setProductDiscount = createAction(
  "wlrBroadband/setProductDiscount",
  (
    targetConfigs: any,
    productType: string,
    priceType: string,
    discountType: string,
    value: any
  ) => ({
    payload: {
      targetConfigs,
      productType,
      priceType,
      discountType,
      value,
    },
  })
);

export const removeProductDiscount = createAction(
  "wlrBroadband/removeProductDiscount",
  (targetConfigs: any, productType: string, propertyNamePrefix: string) => ({
    payload: {
      targetConfigs,
      productType,
      propertyNamePrefix,
    },
  })
);

export const validateProductProperty = createAction(
  "wlrBroadband/validateProductProperty",
  (
    targetConfigs: any,
    productType: string,
    propertyName: string,
    dynamicProperty: any
  ) => ({
    payload: {
      targetConfigs,
      productType,
      propertyName,
      dynamicProperty,
    },
  })
);

export const addWlrConfiguration = createAction(
  "wlrBroadband/addWlrConfiguration",
  (locationIndex: number, productId: string) => ({
    payload: { locationIndex, productId },
  })
);

export const removeWlrConfiguration = createAction<number>(
  "wlrBroadband/removeWlrConfiguration"
);

export const setConfiguration = createAction<any>(
  "wlrBroadband/setConfiguration"
);

export const setNewLineConfiguration = createAsyncThunk(
  "wlrBroadband/setNewLineConfiguration",
  async ({ locationIndex, product, qty }: any, { getState, dispatch }) => {
    const state: any = getState();
    const broadbandState: any = state.wlrBroadband;
    const productId = product.id;
    const isMpfProduct = product?.first_broadband_component?.type === "MPF";
    const { contractLength, configurations } = broadbandState;
    const canAccessCommission = checkCommissionTag(state, "wlr");
    const productConfigs = configurations.filter(
      (config: any) =>
        config.locationIndex === locationIndex &&
        config.wlrProductId === productId
    );
    const restConfigs = configurations.filter(
      (config: any) =>
        config.wlrProductId !== productId ||
        config.locationIndex !== locationIndex
    );

    const productConfigsCount = productConfigs.length;

    let newProductConfigs = _.cloneDeep(productConfigs);
    if (productConfigsCount > qty) {
      newProductConfigs = productConfigs.filter((_: any, i: number) => i < qty);
    }
    if (productConfigsCount < qty) {
      let newConfig: any = newConfiguration(
        { locationIndex, productId },
        broadbandState
      );
      newConfig.commission_type = canAccessCommission
        ? contractLength === 1
          ? "Residual"
          : "Upfront"
        : null;

      if (isMpfProduct) {
        newConfig = newBroadbandProduct(
          { configurationIndex: 0, productId, isMpfProduct: true },
          { ...broadbandState, configurations: [newConfig] }
        );
      }

      const newConfigsLength = qty - productConfigsCount;

      for (let i = 0; i < newConfigsLength; ++i) {
        newProductConfigs.push(_.cloneDeep(newConfig));
      }
    }
    const newConfigs = [...restConfigs, ...newProductConfigs];
    dispatch(setConfiguration(newConfigs));
  }
);

export const setOtherConfiguration = createAsyncThunk(
  "wlrBroadband/setOtherConfiguration",
  async (
    { locationIndex, product }: { locationIndex: number; product: any },
    { getState, dispatch }
  ) => {
    const state: any = getState();
    const broadbandState = state.wlrBroadband;
    const productId = product.id;
    const isMpfProduct = product?.first_broadband_component?.type === "MPF";
    const canAccessCommission = checkCommissionTag(state, "wlr");

    const { contractLength, configurations } = broadbandState;
    const restConfigs = configurations.filter(
      (config: any) =>
        config.wlrProductId !== productId ||
        config.locationIndex !== locationIndex
    );

    let newConfig: any = newConfiguration(
      { locationIndex, productId },
      broadbandState
    );

    newConfig.commission_type = canAccessCommission
      ? contractLength === 1
        ? "Residual"
        : "Upfront"
      : null;

    if (isMpfProduct) {
      newConfig = newBroadbandProduct(
        { configurationIndex: 0, productId, isMpfProduct: true },
        { ...broadbandState, configurations: [newConfig] }
      );
    }

    const newConfigs = [...restConfigs, newConfig];
    dispatch(setConfiguration(newConfigs));
  }
);

export const getSelectedProductData = createAsyncThunk(
  "wlrBroadband/getSelectedProductData",
  async (arg, { getState, dispatch }) => {
    const state: any = getState();
    state.wlrBroadband.configurations.forEach((c: any, i: number) => {
      if (
        c.wlrProductId &&
        c.wlrProductId !== EXISTING_LINE &&
        !getIsMPFLine(c) &&
        c.wlrProductId !== NEW_FTTP &&
        c.wlrProductId !== RESIGN &&
        c.wlrProductId !== NEW_SOGEA &&
        c.wlrProductId !== EXISTING_SOGEA
      ) {
        dispatch(
          fetchProductData({ configurationIndex: i, productType: "wlr" })
        );
      }
      if (c.broadbandProductId)
        dispatch(
          fetchProductData({ configurationIndex: i, productType: "broadband" })
        );
    });
  }
);

export const setPropertiesFromProductData = createAction(
  "wlrBroadband/setPropertiesFromProductData",
  (configurationIndex, productType, response) => ({
    payload: { configurationIndex, productType, response },
  })
);

export const fetchProductData = createAsyncThunk(
  "wlrBroadband/fetchProductData",
  async (
    { configurationIndex, productType }: any,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const productId = config[`${productType}ProductId`];
    if (!productId) rejectWithValue("No product id found");
    const dcOrderParams = getDCOrderParams(
      state,
      configurationIndex,
      productType
    );
    if (
      productType === "broadband" &&
      _.isEmpty(config.broadbandProductData?.response)
    ) {
      const initialResponse = await GuidedSalesAPI.productData(
        "",
        "",
        undefined,
        dcOrderParams,
        ""
      );
      dispatch(
        setPropertiesFromProductData(
          configurationIndex,
          productType,
          initialResponse
        )
      );
    }
    // @hack
    setTimeout(() => {
      dispatch(doRemoteValidation({ configurationIndex }));
    });
    const response = GuidedSalesAPI.productData(
      "",
      "",
      undefined,
      dcOrderParams,
      ""
    );
    return response;
  }
);

export const fetchBroadbandAppointments = createAsyncThunk(
  "wlrBroadband/fetchBroadbandAppointments",
  async (configurationIndex: number, { dispatch, getState }) => {
    const state: any = getState();
    const boradbandState = state.wlrBroadband;
    const config = boradbandState.configurations[configurationIndex];
    const location = boradbandState.locations[config.locationIndex];
    const productData = config.broadbandProductData?.response;
    const estimatedLeadTime = _.get(
      location.broadbandSearch.response.products.find(
        (p: any) => p.id === config.broadbandProductId
      ),
      "first_broadband_component.estimated_lead_time.appointment",
      addWeekdays(new Date(), 10, true)
    );
    const site_visit_reason = getDynamicPropertyValue(
      state,
      "broadband",
      configurationIndex,
      "bb.site_visit_reason"
    );
    const voice_service = getDynamicPropertyValue(
      state,
      "broadband",
      configurationIndex,
      "bb.voice_service"
    );
    const account = getAccountId(state);
    const response = await WlrBroadbandAPI.getBBAppointmentAvailability(
      productData.broadband.product_component_data.supplier,
      productData.broadband.product_component_data.supplier_product_ref,
      getConfigBBServiceType(state, configurationIndex),
      location.address.addressReference,
      location.address.cssDatabaseCode,
      voice_service === "1"
        ? addWeekdays(new Date(), 15, true)
        : estimatedLeadTime,
      site_visit_reason,
      account
    );
    return response;
  }
);

export const setBroadbandAppointment = createAsyncThunk(
  "wlrBroadband/setBroadbandAppointment",
  async (
    { configurationIndex, selectedIndex, isSalesPerson = false }: any,
    { dispatch, getState }
  ) => {
    // @hack
    setTimeout(() => {
      dispatch(doRemoteValidation({ configurationIndex }));
    });
    return { configurationIndex, selectedIndex, isSalesPerson };
  }
);

export const doRemoteValidation = createAsyncThunk(
  "wlrBroadband/doRemoteValidation",
  async (
    { configurationIndex, quoteOnly = false }: any,
    { getState, dispatch }
  ) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const validation = config.validation;
    let validated = true;
    for (let prop in validation) {
      if (validation[prop].length > 0) validated = false;
    }
    if (validated) {
      const { wlrProductId, broadbandProductId } = config;
      const promises = [];
      if (
        wlrProductId !== EXISTING_LINE &&
        !getIsMPFLine(config) &&
        wlrProductId !== NEW_FTTP &&
        wlrProductId !== RESIGN &&
        wlrProductId !== NEW_SOGEA &&
        wlrProductId !== EXISTING_SOGEA
      ) {
        if (quoteOnly)
          promises.push(dispatch(validateWlrQuoteOnly(configurationIndex)));
        else promises.push(dispatch(validateWlr(configurationIndex)));
      }
      if (broadbandProductId) {
        promises.push(
          dispatch(validateBroadband({ configurationIndex, quoteOnly }))
        );
      }
      return await Promise.all(promises);
    }
  }
);

export const validateWlr = createAsyncThunk(
  "wlrBroadband/validateWlr",
  async (configurationIndex: number, { getState }) => {
    const state: any = getState();
    const response = await WlrBroadbandAPI.wlrValidation(
      state,
      configurationIndex,
      false
    );
    return response;
  }
);

export const validateWlrQuoteOnly = createAsyncThunk(
  "wlrBroadband/validateWlrQuoteOnly",
  async (configurationIndex: number, { getState }) => {
    const state: any = getState();
    const response = await WlrBroadbandAPI.wlrValidation(
      state,
      configurationIndex,
      true
    );
    return response;
  }
);

export const validateBroadband = createAsyncThunk(
  "wlrBroadband/validateBroadband",
  async (
    {
      configurationIndex,
      quoteOnly = false,
    }: { configurationIndex: number; quoteOnly?: boolean },
    { getState }
  ) => {
    const state: any = getState();
    let requirements = ["broadband_provisioning"];
    if (
      getDynamicPropertyValue(
        state,
        "broadband",
        configurationIndex,
        "router_id"
      )
    ) {
      requirements.push("router_provisioning");
    }
    return await WlrBroadbandAPI.broadbandValidation(
      state,
      configurationIndex,
      requirements,
      quoteOnly
    );
  }
);

export const fetchWlrAppointments = createAsyncThunk(
  "wlrBroadband/fetchWlrAppointments",
  async (configurationIndex: number, { dispatch, getState }) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const location = state.wlrBroadband.locations[config.locationIndex];
    const account = getAccountId(state);
    return await WlrBroadbandAPI.wlr3GetAppointments(
      getDynamicPropertyValue(state, "wlr", configurationIndex, "service_type"),
      getDynamicPropertyValue(state, "wlr", configurationIndex, "product_type"), // Note DCT10 reports this as BASIC for both, which is in error. On live multi-line is PREMIUM
      getDynamicPropertyValue(
        state,
        "wlr",
        configurationIndex,
        "number_of_channels"
      ),
      location.address.addressReference,
      location.address.cssDatabaseCode,
      _.get(
        config.broadbandProductData.response,
        "broadband.product_component_data.supplier_product_ref"
      ),
      account
    );
  }
);

export const doNumberReservation = createAsyncThunk(
  "wlrBroadband/doNumberReservation",
  async (configurationIndex: number, { dispatch, getState }) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const location = state.wlrBroadband.locations[config.locationIndex];
    const account = getAccountId(state);

    let response;
    if (config.numberReservation.type === NUMBER_RES_NEXT) {
      response = await WlrBroadbandAPI.reserveNextNumber(
        location.lineAvailability?.response.css_exchange_code,
        config.wlrProductData?.response.line.product_component_data
          .service_type,
        location.address.cssDatabaseCode,
        account
      );
    } else {
      response = await WlrBroadbandAPI.reserveNumber(
        location.lineAvailability?.response.css_exchange_code,
        config.wlrProductData?.response.line.product_component_data
          .service_type,
        config.numberReservation.selectedNumber,
        account
      );
    }
    // @hack
    setTimeout(() => {
      dispatch(doRemoteValidation({ configurationIndex }));
    });
    return response;
  }
);

export const doAddWlrAppointment = createAsyncThunk(
  "wlrBroadband/doAddWlrAppointment",
  async (configurationIndex: number, { dispatch, getState }) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const location = state.wlrBroadband.locations[config.locationIndex];
    const appointment =
      config.wlrAppointments?.response.appointments[
        config.wlrAppointments.selectedIndex
      ];
    const account = getAccountId(state);
    const response = await WlrBroadbandAPI.wlr3AddAppointment({
      address_reference: location.address.addressReference,
      appointment_date: appointment.date,
      appointment_timeslot: appointment.timeslot,
      css_database_code: location.address.cssDatabaseCode,
      number_of_channels:
        getDynamicPropertyValue(
          state,
          "wlr",
          configurationIndex,
          "number_of_channels"
        ) || 1,
      product_type: getDynamicPropertyValue(
        state,
        "wlr",
        configurationIndex,
        "product_type"
      ),
      service_type: getDynamicPropertyValue(
        state,
        "wlr",
        configurationIndex,
        "service_type"
      ),
      account,
    });

    // @hack
    setTimeout(() => {
      dispatch(doRemoteValidation({ configurationIndex }));
    });
    return response;
  }
);

export const doAddBroadbandAppointment = createAsyncThunk(
  "wlrBroadband/doAddBroadbandAppointment",
  async (configurationIndex: number, { dispatch, getState }) => {
    const state: any = getState();
    const config = state.wlrBroadband.configurations[configurationIndex];
    const productData = config.broadbandProductData?.response;
    const location = state.wlrBroadband.locations[config.locationIndex];
    const appointment =
      config.broadbandAppointments?.response.appointments[
        config.broadbandAppointments.selectedIndex
      ];
    const supplier_product_ref = _.get(
      productData,
      "broadband.product_component_data.supplier_product_ref"
    );
    const account = getAccountId(state);
    const site_visit_reason = getDynamicPropertyValue(
      state,
      "broadband",
      configurationIndex,
      "bb.site_visit_reason"
    );
    const response = await WlrBroadbandAPI.broadbandAddAppointment({
      supplier: productData.broadband.product_component_data.supplier,
      ...(supplier_product_ref === "BT_21CN_FTTC_GFAST" && { gfast: 1 }),
      service_type: getConfigBBServiceType(state, configurationIndex),
      number_of_channels: config.wlrProperties.number_of_channels || 1,
      address_reference: location.address.addressReference,
      css_database_code: location.address.cssDatabaseCode,
      appointment_date: appointment.date,
      appointment_timeslot: appointment.timeslot,
      site_visit_reason,
      account,
    });
    // @hack
    setTimeout(() => {
      dispatch(doRemoteValidation({ configurationIndex }));
    });
    return { response, appointment };
  }
);

export const orderWlrBroadbandProducts = createAsyncThunk(
  "wlrBroadband/orderWlrBroadbandProducts",
  async (quoteOnly: boolean | undefined = false, { dispatch, getState }) => {
    const state: any = getState();
    const configs = state.wlrBroadband.configurations;
    const promises = configs.map(async (config: any, i: number) => {
      const { wlrProductId, broadbandProductId, orderProduct } = config;
      const wlrOrderProductId = _.get(orderProduct, "wlr.response.data.id");
      const broadbandOrderProductId = _.get(
        orderProduct,
        "broadband.response.data.id"
      );
      if (
        wlrProductId !== EXISTING_LINE &&
        !getIsMPFLine(config) &&
        wlrProductId !== NEW_FTTP &&
        wlrProductId !== RESIGN &&
        wlrProductId !== EXISTING_SOGEA &&
        wlrProductId !== NEW_SOGEA
      ) {
        const params = getDCOrderParams(getState(), i, "wlr", quoteOnly);
        const shouldUpdate = !!wlrOrderProductId;
        const { payload: wlrResponse } = await dispatch(
          executeOrderProduct({
            configurationIndex: i,
            productType: "wlr",
            isUpdate: shouldUpdate,
            id: shouldUpdate ? wlrOrderProductId : params.product_id,
            params,
          })
        );

        if (broadbandProductId) {
          const bbParams = {
            ...getDCOrderParams(getState(), i, "broadband", quoteOnly),
            "broadband-line_component_id": _.get(
              wlrResponse,
              "data.components[0].id"
            ),
          };
          const shouldUpdate = !!broadbandOrderProductId;
          await dispatch(
            executeOrderProduct({
              configurationIndex: i,
              productType: "broadband",
              isUpdate: shouldUpdate,
              id: shouldUpdate ? broadbandOrderProductId : bbParams.product_id,
              params: bbParams,
            })
          );
        }
      } else if (broadbandProductId) {
        const params = getDCOrderParams(state, i, "broadband", quoteOnly);
        const shouldUpdate = !!broadbandOrderProductId;
        await dispatch(
          executeOrderProduct({
            configurationIndex: i,
            productType: "broadband",
            isUpdate: shouldUpdate,
            id: shouldUpdate ? broadbandOrderProductId : params.product_id,
            params,
          })
        );
      }
      const specialRatesToRemove = state.wlrBroadband.specialRatesToRemove;
      specialRatesToRemove.forEach((specialRate: any) => {
        dispatch(removeSpecialRate(specialRate));
      });
    });
    return await Promise.all(promises);
  }
);

export const executeOrderProduct = createAsyncThunk(
  "wlrBroadband/executeOrderProduct",
  async (
    {
      configurationIndex,
      productType,
      isUpdate,
      id,
      params,
    }: {
      configurationIndex: number;
      productType: string;
      isUpdate: boolean;
      id: string;
      params: any;
    },
    { dispatch }
  ) => {
    const response = isUpdate
      ? await OrderProductAPI.update(id, params)
      : await OrderProductAPI.create(params.product_id, params);
    return response;
  }
);

export const setWlrAppointment = createAction(
  "wlrBroadband/setWlrAppointment",
  (configurationIndex, selectedIndex, isSalesPerson = false) => ({
    payload: {
      configurationIndex,
      selectedIndex,
      isSalesPerson,
    },
  })
);

export const setNumberReservationType = createAction(
  "wlrBroadband/setNumberReservationType",
  (configurationIndex, reservationType) => ({
    payload: {
      configurationIndex,
      reservationType,
    },
  })
);

export const setReservedNumber = createAction(
  "wlrBroadband/setReservedNumber",
  (configurationIndex, number) => ({
    payload: {
      configurationIndex,
      number,
    },
  })
);

export const addAppointments = createAsyncThunk(
  "wlrBroadband/addAppointments",
  async (arg, { dispatch, getState }) => {
    const state: any = getState();
    const promises: any = [];
    state.wlrBroadband.configurations.map(
      async (config: any, index: number) => {
        const bbAppointments = _.get(
          config,
          "broadbandAppointments.response.appointments"
        );
        const hasBBAppointment =
          _.get(config, "addBroadbandAppointment.response.status") ===
          "success";
        const wlrAppointments = _.get(
          config,
          "wlrAppointments.response.appointments"
        );
        const hasWlrAppointment =
          _.get(config, "addWlrAppointment.response.status") === "success";
        const hasSelectedBBAppointment =
          bbAppointments &&
          bbAppointments[config.broadbandAppointments.selectedIndex];
        const hasSelectedWlrAppointment =
          wlrAppointments &&
          wlrAppointments[config.wlrAppointments.selectedIndex];
        if (hasSelectedBBAppointment && !hasBBAppointment) {
          promises.push(dispatch(doAddBroadbandAppointment(index)));
        }
        if (hasSelectedWlrAppointment && !hasWlrAppointment) {
          promises.push(dispatch(doAddWlrAppointment(index)));
        }
      }
    );
    return await Promise.all(promises);
  }
);

export const doUpdateProductInstance = createAsyncThunk(
  "wlrBroadband/doUpdateProductInstance",
  async (
    {
      id,
      pin,
      configurationIndex,
      address,
      resignProductType,
      productInstance,
    }: any,
    { dispatch, getState }
  ) => {
    const state: any = getState();
    const locations = state.wlrBroadband.locations;
    const locationIndex = locations.findIndex((l: any) => l.cli.value === pin);
    const account = getAccountId(state);
    const response = await WlrBroadbandAPI.updateProductInstance(id, {
      ...mapToProductInstanceAddress(address),
      account,
    });
    // @hack
    setTimeout(() => {
      if (response.status === "success") {
        dispatch(
          setLocationAddress(
            locationIndex,
            mapToLocationAddress(response.result),
            true
          )
        );
        if (resignProductType === NEW_PRODUCT) {
          dispatch(fetchLineAvailabilityForResign(productInstance));
          dispatch(doBroadbandSearchForResign(productInstance));
        }
      }
    });
    return response;
  }
);
