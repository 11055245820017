import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateEthernetCustomerData } from "../../../../../../store/ethernetProducts/actions";
import { AdjustPrice } from "../../../../components/Ethernet/AdjustPrice";

interface AdjustPriceContainerProps {
  index: number;
}

export const AdjustPriceContainer = ({ index }: AdjustPriceContainerProps) => {
  const dispatch = useDispatch();

  const configurations = useSelector(
    (state: any) => state.ethernetProducts.configurations
  );

  const updatePrice = (_: Event, value: number | number[]) =>
    dispatch(updateEthernetCustomerData(index, "discount", value));

  return (
    <AdjustPrice
      discountPercentage={configurations[index].purchaseArgs.discount}
      updatePrice={updatePrice}
    />
  );
};
