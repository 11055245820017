import React from "react";
import Icon from "@mui/icons-material/Book";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

// These all have the category "Directory Info (Existing Line)" in product data.

export const DirectoryInfo = () => (
  <Section
    title="Directory Info (Existing Line)"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.directory_suffix"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.end_user_name_for_999"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.directory_description"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.company"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.directory_initials"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.entry_type"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.directory_title"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.location_option"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.directory_surname"
    />
  </Section>
);
