import React from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

import { format, parseISO } from "date-fns";
import { TableConstants } from "../../../Dashboard/enums";
import { OrderComponent } from "../../../../store/detail/types";
import { ViewMessage } from "./ViewMessage";

interface Props {
  components: OrderComponent[];
}

export const OrderComponentsTable = ({ components }: Props) => {
  const cleanEmpty = (val?: string): string => val || TableConstants.Empty;

  const formatDate = (val?: string): string | undefined => {
    if (!val) {
      return undefined;
    }
    try {
      return format(parseISO(val), "dd/MM/yyyy");
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  };

  const columns: {
    name: keyof OrderComponent;
    label: string;
    options?: {};
  }[] = [
    {
      name: "forecast_date",
      label: "Forecast Date",
      options: {
        customBodyRender: (val: OrderComponent["forecast_date"]) =>
          cleanEmpty(formatDate(val)),
      },
    },
    {
      name: "id",
      label: "ID",
      options: { customBodyRender: cleanEmpty },
    },
    {
      name: "cli",
      label: "PIN / CLI",
      options: { customBodyRender: cleanEmpty },
    },
    {
      name: "product",
      label: "Product",
      options: { customBodyRender: cleanEmpty },
    },
    {
      name: "port_date",
      label: "Port Date",
      options: {
        customBodyRender: (val: OrderComponent["port_date"]) =>
          cleanEmpty(formatDate(val)),
      },
    },
    {
      name: "request_stage",
      label: "Status",
      options: { customBodyRender: cleanEmpty, sortDirection: "asc" },
    },
    {
      name: "submitted_date",
      label: "Date Submitted",
      options: {
        customBodyRender: (val: OrderComponent["submitted_date"]) =>
          cleanEmpty(formatDate(val)),
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        customBodyRender: (message: OrderComponent["message"], meta: any) => {
          if (!message) {
            return cleanEmpty(message);
          }
          const id = components[meta.rowIndex]["id"];
          return (
            <ViewMessage title={`Message for ${id.toString()}`}>
              {message}
            </ViewMessage>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    elevation: 1,
    filter: false,
    print: false,
    responsive: "standard",
    rowHover: false,
    selectableRows: "none",
    search: false,
    setTableProps: () => ({
      size: "small",
    }),
    viewColumns: false,
  };

  return (
    <MUIDataTable
      title="Order Components"
      data={components}
      columns={columns}
      options={options}
    />
  );
};
