import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  resetStacCodes,
  verifyStacCode,
} from "../../../../../../../store/mobile/actions";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { StacCode } from "../../../../../components/Mobile/StacCode";
import { PacErrorsEnum } from "../../../../../utils/pacErrors";

export const StacCodeContainer = () => {
  const dispatch = useDispatch();

  const [showVerificationFeedback, setShowVerificationFeedback] =
    useState(false);

  const { targetConfigs } = useTargetConfigsContext();

  const config = useSelector(
    (state: any) => state.mobile.configs[targetConfigs[0]]
  );

  const oldMobileNumber = useSelector((state: any) =>
    _.get(
      state.mobile.configs[targetConfigs[0]],
      "properties.old_mobile_number"
    )
  );

  const stacCodeCheck = config.stacCodeCheck;
  const stac = _.get(config, "properties.stac");
  const stacValid = _.get(config.validation, "stac");
  const oldMobileNumberValid = _.get(config.validation, "old_mobile_number");
  // const expiryDate = _.get(stacCodeCheck.response, "result.pac_expiry_date", null); // Why pac?!
  const expiryDate = _.get(config, "properties.stac_expiry_date", null);

  const shouldEnableVerificationButton = () => {
    if (stacCodeCheck.fetching || !stac || !oldMobileNumber) {
      return false;
    }

    // passed client side validation?
    return !stacValid && !oldMobileNumberValid;
  };

  const handleConfigValueChange = () => {
    setShowVerificationFeedback(false);
  };

  const onClearClick = () => {
    dispatch(resetStacCodes([targetConfigs[0]]));
  };

  const onVerifyClick = () => {
    setShowVerificationFeedback(true);
    dispatch(verifyStacCode({ configIndex: targetConfigs[0] }));
  };

  const getErrorMessage = (): string => {
    const reason_code = _.get(config, "stacCodeCheck.response.reason_code");

    if (reason_code)
      return PacErrorsEnum[reason_code as keyof typeof PacErrorsEnum];
    else
      return _.get(
        config,
        "stacCodeCheck.response.message.result.errors[0].message"
      );
  };

  return (
    <StacCode
      stacCodeVerifyDisabled={!shouldEnableVerificationButton()}
      handleConfigValueChange={handleConfigValueChange}
      showVerificationFeedback={showVerificationFeedback}
      expiryDate={expiryDate}
      onClearClick={onClearClick}
      onVerifyClick={onVerifyClick}
      stacCodeCheckFetching={stacCodeCheck.fetching}
      stacCodeCheckError={stacCodeCheck.response?.status === "error"}
      stacCodeCheckErrorMessage={getErrorMessage()}
    />
  );
};
