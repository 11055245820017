import React from "react";

import { StatusChip } from "@akj-dev/shared-components";

interface ResignsTableStatusesProps {
  resignProductSearch: Record<string, any>;
  lineResignProductSearch: Record<string, any>;
  broadbandResignProductSearch: Record<string, any>;
  broadbandProductInstances: Record<string, any>;
  lineProductInstances: Record<string, any>;
  requestResignProduct: () => void;
  requestLineResignProduct: () => void;
  requestBroadbandResignProduct: () => void;
  requestBroadbandProductInstances: () => void;
  requestLineProductInstances: () => void;
}

export const ResignsTableStatuses = ({
  resignProductSearch,
  lineResignProductSearch,
  broadbandResignProductSearch,
  broadbandProductInstances,
  lineProductInstances,
  requestResignProduct,
  requestLineResignProduct,
  requestBroadbandResignProduct,
  requestBroadbandProductInstances,
  requestLineProductInstances,
}: ResignsTableStatusesProps) => (
  <>
    {!resignProductSearch.fetching &&
      resignProductSearch?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error finding resign product"
          message={resignProductSearch?.response.message}
          retry={requestResignProduct}
        />
      )}
    {!resignProductSearch.fetching &&
      resignProductSearch?.response.status === "success" &&
      resignProductSearch?.response.products.length < 1 && (
        <StatusChip
          type="error"
          title="Error finding resign product"
          message="No products returned from Daisy Central."
          retry={requestResignProduct}
        />
      )}
    {!lineResignProductSearch.fetching &&
      lineResignProductSearch?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error finding line resign product"
          message={lineResignProductSearch?.response.message}
          retry={requestLineResignProduct}
        />
      )}
    {!lineResignProductSearch.fetching &&
      lineResignProductSearch?.response.status === "success" &&
      lineResignProductSearch?.response.products.length < 1 && (
        <StatusChip
          type="error"
          title="Error finding line resign product"
          message="No line products returned from Daisy Central."
          retry={requestLineResignProduct}
        />
      )}
    {!broadbandResignProductSearch.fetching &&
      broadbandResignProductSearch?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error finding broadband resign product"
          message={broadbandResignProductSearch?.response.message}
          retry={requestBroadbandResignProduct}
        />
      )}
    {!broadbandResignProductSearch.fetching &&
      broadbandResignProductSearch?.response.status === "success" &&
      broadbandResignProductSearch?.response.products.length < 1 && (
        <StatusChip
          type="error"
          title="Error finding broadband resign product"
          message="No broadband products returned from Daisy Central."
          retry={requestBroadbandResignProduct}
        />
      )}
    {!lineProductInstances.fetching &&
      lineProductInstances?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error finding line product instances"
          message={lineProductInstances?.response.message}
          retry={requestLineProductInstances}
        />
      )}
    {!broadbandProductInstances.fetching &&
      broadbandProductInstances?.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error finding broadband product instances"
          message={broadbandProductInstances?.response.message}
          retry={requestBroadbandProductInstances}
        />
      )}
  </>
);
