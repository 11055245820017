import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

function union(a: any, b: any) {
  return [...a, ...not(b, a)];
}

export default function TransferList({ onSelect, selected, users }: any) {
  const [checked, setChecked] = useState<any>([]);
  const left = not(users.options, selected);
  const right = selected;

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: any) => intersection(checked, items).length;

  const handleToggleAll = (items: any) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleMoveRight = () => {
    onSelect(right.concat(leftChecked));
  };

  const handleMoveLeft = () => {
    onSelect(not(right, rightChecked));
  };

  const Items = ({ title, items }: any) => {
    return (
      <Card>
        <CardHeader
          sx={styles.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List sx={styles.list} dense>
          {items.map((item: any) => (
            <ListItem key={item.id} button onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.find((i: any) => i === item) ? true : false}
                />
              </ListItemIcon>
              <ListItemText primary={item.full_name} />
            </ListItem>
          ))}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Grid container spacing={1} alignItems="center" sx={styles.root}>
      <Grid item xs={5}>
        <Items title="Available Users" items={left} />
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            sx={styles.button}
            onClick={handleMoveRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <ChevronRightIcon />
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={styles.button}
            onClick={handleMoveLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <ChevronLeftIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Items title="Selected Users" items={right} />
      </Grid>
    </Grid>
  );
}

const styles = {
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: 1,
  },
  list: {
    height: 220,
    backgroundColor: "background.paper",
    overflow: "auto",
  },
  button: {
    margin: 0.5,
  },
};
