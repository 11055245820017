import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { setBroadbandProduct } from "../../../../../store/wlrBroadband/actions/configurations";
import {
  getIsMultiLineProduct,
  isActivationCodeP as isActivationCodePSelector,
} from "../../../../../store/wlrBroadband/selectors";
import { AddBroadband } from "../../components/Resigns/AddBroadband";

interface AddBroadbandContainerProps {
  locationIndex: number;
  configuration: Record<string, any>;
  productInstance: Record<string, any>;
}

export const AddBroadbandContainer = ({
  locationIndex,
  configuration,
  productInstance,
}: AddBroadbandContainerProps) => {
  const dispatch = useDispatch();

  const [showSelect, setShowSelect] = useState(false);

  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );
  const configurationIndex = useSelector<any, number>((state) =>
    state.wlrBroadband.configurations.indexOf(configuration)
  );

  const isActivationCodeP = useSelector<any, boolean>((state) =>
    isActivationCodePSelector(state, { locationIndex })
  );
  const isMultiLineProduct = useSelector<any, boolean>((state) =>
    getIsMultiLineProduct(state, configuration.wlrProductId)
  );

  const broadbandProductId = configuration.broadbandProductId;

  const handleShowSelect = () => {
    setShowSelect(true);
  };

  const fetchingBroadbandSearch = _.get(
    location,
    "broadbandSearch.fetching",
    false
  );
  const fetchingInstallationDetails = _.get(
    location,
    "installationDetails.fetching",
    false
  );
  const broadbandSearch = _.get(
    location,
    "broadbandSearch.response.products",
    []
  );

  const installationDetails = _.get(location, "installationDetails", {});

  return (
    <AddBroadband
      showSelect={showSelect}
      broadbandProductId={broadbandProductId}
      broadbandSearch={broadbandSearch}
      isMultiLineProduct={isMultiLineProduct}
      isActivationCodeP={isActivationCodeP}
      installationDetails={installationDetails}
      location={location}
      fetching={fetchingInstallationDetails || fetchingBroadbandSearch}
      handleShowSelect={handleShowSelect}
      onResignBroadbandChange={(event) => {
        // Adding a new broadband product so a broadband product instance id
        // doesn't need to be passed.
        dispatch(
          setBroadbandProduct(configurationIndex, event.target.value as string)
        );
      }}
    />
  );
};
