import React from "react";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Add } from "@mui/icons-material";

import { Alert } from "@akj-dev/shared-components";

import { ContactType, DCContact } from "../../../api/v1/account";
import { StatusLarge } from "../StatusLarge";

import { CreateContactForm } from "./CreateContactForm";

interface CreateContactProps {
  open: boolean;
  response: Record<string, any>;
  types: ContactType[];
  setOpen: (open: boolean) => void;
  handleSubmit: (values: Partial<DCContact>) => Promise<void>;
}

export function CreateContact({
  open,
  response,
  types,
  setOpen,
  handleSubmit,
}: CreateContactProps) {
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<Add />}
        variant="outlined"
      >
        Add new Contact
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        TransitionProps={{ unmountOnExit: true }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Add new Contact</DialogTitle>
        <DialogContent>
          {response.status === "success" ? (
            <StatusLarge type="success" title="Contact added" />
          ) : (
            <CreateContactForm types={types} onSubmit={handleSubmit} />
          )}
          {response.status === "error" && (
            <Alert type="error" message={response.message} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
