import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Paper, Table, TableBody, TableContainer } from "@mui/material";

import { SplitDeliveryMobileSIMList } from "./SplitDeliveryMobileSIMList";
import { SplitDeliveryHardwareList } from "./SplitDeliveryHardwareList";

export const SplitDeliveryGlobalList = () => {
  const [splitEditId, setSplitEditId] = useState("");
  const hardwareConfigs = useSelector(
    (state: any) => state.hardwareConfigurations
  );
  const mobileConfigs = useSelector((state: any) => state.mobile.configs);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <SplitDeliveryHardwareList
            splitEditId={splitEditId}
            setSplitEditId={setSplitEditId}
            hardwareConfigs={hardwareConfigs}
          />
          <SplitDeliveryMobileSIMList
            splitEditId={splitEditId}
            setSplitEditId={setSplitEditId}
            mobileConfigs={mobileConfigs}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};
