import React from "react";
import styled from "styled-components";

import { Button, Box } from "@mui/material";
import RouterIcon from "@mui/icons-material/Router";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";

import { Spinner } from "../../../../shared/components/Spinner";
import { Section } from "../../../../shared/components/Section";
import { SelectRouterContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/SiteVisitReason/SelectRouter";

import WlrBBDynamicField from "./WlrBBDynamicField";

const Delivery = styled.div`
  margin: 10px 0;
  opacity: 0.8;
  font-size: 0.9em;
  svg {
    vertical-align: middle;
    margin-right: 10px;
  }
`;

interface RouterProps {
  deliveryCharge: string;
  targetConfigs: number[];
  routerId: string | number;
  isSOGEA: boolean;
  isBTWFTTC: boolean;
  showAddress: boolean;
  fetching: boolean;
  toggleAddress: () => void;
}

export const Router = ({
  isBTWFTTC,
  isSOGEA,
  targetConfigs,
  routerId,
  deliveryCharge,
  showAddress,
  fetching,
  toggleAddress,
}: RouterProps) => (
  <Section title="Router" avatar={<RouterIcon />}>
    <p>Choose a router if one is required:</p>
    {isSOGEA || isBTWFTTC ? (
      <SelectRouterContainer productType="broadband" propertyName="router_id" />
    ) : (
      <WlrBBDynamicField productType="broadband" propertyName="router_id" />
    )}
    {targetConfigs.length === 1 && routerId && (
      <Delivery>
        {fetching ? (
          <Spinner />
        ) : (
          <span>
            {parseFloat(deliveryCharge) > 0 && (
              <span>
                <LocalShippingIcon />
                Delivery Charge: <b>£{deliveryCharge}</b>
              </span>
            )}
          </span>
        )}
      </Delivery>
    )}
    <Box mt={1}>
      <Button
        variant="contained"
        endIcon={<EditIcon />}
        onClick={toggleAddress}
      >
        Edit Address
      </Button>
    </Box>
    {showAddress && (
      <div>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.first_name"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.last_name"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.company"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.email"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.telephone"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.building_name"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.door_number"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.street"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.town"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.county"
          margin
        />
        <WlrBBDynamicField
          productType="broadband"
          propertyName="router.postcode"
          margin
        />
      </div>
    )}
  </Section>
);
