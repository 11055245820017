import React from "react";

import { Box } from "@mui/material";
import { IndexedCardInfo } from "@akj-dev/shared-components";

import { TitleAndInfo } from "../../shared/containers/TitleAndInfo";

import { StepSummary } from "./containers";

export const Summary = () => (
  <Box>
    <TitleAndInfo title="Summary" />
    <IndexedCardInfo index={1} title="Please check out the summary information">
      <StepSummary />
    </IndexedCardInfo>
  </Box>
);
