import { useSelector } from "react-redux";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { getAccountSettings } from "../../store/account/selectors";
import { get } from "lodash";

export const useAccountSettings = () => {
  const accountSettings = useSelector<any, AccountSettings>(getAccountSettings);

  return accountSettings;
};

export const useCanAccessCommission = (product: string) => {
  const accountSettings = useAccountSettings();
  let tag = "";
  switch (product) {
    case "mobile":
      tag = "as_commission_type_selection_for_mobile";
      break;
    case "wlr":
      tag = "as_commission_type_selection_for_wlr_bb";
      break;
    default:
      break;
  }

  return get(accountSettings, tag, {}) === "1";
};
