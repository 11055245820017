import _ from "lodash";
import { ProductInstanceResponse, ResultsEntity } from "../../api/v1/product";

/**
 * Pull pricing data from a product data response
 * We'll already have the rest of it stored in mobile.productData
 * Hence this keeps the size of the store down.
 * @param response
 * @returns {object}
 */
export function transformMobilePricingData(response: any) {
  return {
    status: response.status,
    message: response.message,
    mobile: {
      component_price: _.get(response, "mobile.component_price"),
    },
    // do we need this too? (CLI bolt-ons?)
    overall_price: response.overall_price,
  };
}

/**
 * Transform Mobile Resigns Data
 *
 * 1. Compute price totals from evo services for resigns and store with response.
 *    DC doesn't do this and we don't want to compute for every component render
 *    It's the sum of all the evo services.
 *    According to @davet there are no extra pricing rule shenanigans as DC is
 *    telling Evo the prices. Not vice versa.
 *    Still kinda worried about `TerminationFeeDiscountPercentage` though
 *
 * 2. Create a common `services` node that combines the bits we're interested in
 *    from third_party_ and evo_ responses. They're annoyingly different but
 *    fulfil the same purpose in the UI depending on if its VFDirect or not.
 *    Apparently this is because evo_services is copied from some external
 *    response DC gets while third_party_ is from its own db
 *
 * TODO: Do this for WLR too? We're not 100% sure on the response though.
 * @param response
 */
export function transformMobileProductInstanceData(
  response: ProductInstanceResponse
): TransformedResponse {
  return {
    ...response,
    results: (response.results || []).map((result) => {
      let services: TransformedService[] = [];
      if (result.evo_services) {
        services = result.evo_services.map((s) => ({
          rate: s.RateBeforeDiscount,
          active: s.Active,
          serviceType: s.ServiceType,
          description: s.Description,
          id: s.Id,
        }));
      }
      if (result.third_party_billing_services) {
        services = result.third_party_billing_services.map((s) => ({
          rate: parseFloat(s.third_party_billing_rate) || 0,
          active: s.is_live,
          serviceType: s.service_type,
          description: s.description,
          id: s.id,
        }));
      }
      const price = services.reduce((prev, current) => prev + current.rate, 0);
      return {
        ...result,
        computedPrice: price,
        transformedServices: services,
      };
    }),
  };
}

export type TransformedService = {
  rate: number;
  active: number;
  serviceType: string;
  description: string;
  id: string | number;
};

export type TransformedResult = ResultsEntity & {
  computedPrice: number;
  transformedServices: TransformedService[];
};

export type TransformedResponse = ProductInstanceResponse & {
  results: TransformedResult[];
};
