import React from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

interface DwsTermsProps {
  dwsTermsAccepted: boolean;
  handleChange: () => void;
}
export const DwsTerms = ({ dwsTermsAccepted, handleChange }: DwsTermsProps) => (
  <Box mb={2}>
    <Card>
      <CardHeader title="Mobile Order" />
      <CardContent>
        <Typography variant="h4" gutterBottom>
          All orders are accepted under your standard terms and conditions
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom>
          <li>
            Please Note: Pro-rata billing may apply which could affect
            allowances during the first billing month following a new connection
            or amendment. Please refer to your Mobile Specialist or the FAQ page
            of your pay plans for more information.
          </li>
          <li>
            New connections will be provisioned immediately, this is an
            automated process and cannot be reversed should you change your
            mind.
          </li>
        </Typography>
        <FormControlLabel
          data-cy="dwsTermsCheckbox"
          label="I accept the above exceptions to my standard terms and conditions with Giacom"
          control={
            <Checkbox
              color="secondary"
              onChange={handleChange}
              checked={dwsTermsAccepted}
              name="dwsTermsAccepted"
            />
          }
        />
      </CardContent>
    </Card>
  </Box>
);
