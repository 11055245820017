import React, { ReactNode } from "react";
import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DisplayPrefs } from "./index";

interface MobileConfigTableOuterProps {
  displayPrefs: DisplayPrefs;
  children: ReactNode;
}

export const MobileConfigTableOuter = ({
  displayPrefs,
  children,
}: MobileConfigTableOuterProps) => {
  const classes = useStyles();
  return (
    <div className={classes.inner}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{/*expander*/}</TableCell>
            <TableCell align="center">{/*count*/}</TableCell>
            <TableCell align="center">CLI</TableCell>
            <TableCell align="left">Product</TableCell>
            <TableCell align="center">Price</TableCell>
            {displayPrefs.showMonthlyAirtimeCredit && (
              <TableCell align="center">Monthly Credit</TableCell>
            )}
            {displayPrefs.showOneOffAirtimeCredit && (
              <TableCell align="center">One Off Credit</TableCell>
            )}
            {displayPrefs.showVFDiscounts && (
              <TableCell align="center">Shared Discount</TableCell>
            )}
            {displayPrefs.showVFDiscounts && (
              <TableCell align="center">Commission Sacrifice</TableCell>
            )}
            <TableCell align="center">Connection Type</TableCell>
            {displayPrefs.showCommissionTypeSelect && (
              <TableCell align="center">
                <p>Commission</p>
                <p>Up Front &nbsp; Residual </p>
              </TableCell>
            )}
            <TableCell align="center">Configured?</TableCell>
            <TableCell align="right">{/*actions*/}</TableCell>
          </TableRow>
        </TableHead>
        {children}
      </Table>
    </div>
  );
};

const useStyles = makeStyles({
  inner: {
    minWidth: 1030,
  },
});
