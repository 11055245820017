import {
  ViewAPIResponse,
  ViewAPIResponseOrderComponent,
  ViewAPIResponseOrderCLI,
} from "../../api/v1/view";
import { DisplayError } from "../types";
import { ChangeTypeOfKeys } from "../../helpers/utilityTypes";
import { DateString, Order } from "../../types";

export const SET_ERROR = "detail.SET_ERROR";
export const REQUEST_DATA = "detail.REQUEST_DATA";
export const SET_DATA = "detail.SET_DATA";

// STATE
export type OrderComponent = ChangeTypeOfKeys<
  ViewAPIResponseOrderComponent,
  "submitted_date" | "forecast_date" | "appointment_date" | "port_date",
  DateString
>;

export type OrderCLI = ViewAPIResponseOrderCLI;

export type OrderDetail = Order & {
  components?: OrderComponent[];
  clis?: OrderCLI[];
};

export interface DetailState {
  fetching: boolean;
  error?: DisplayError;
  data?: OrderDetail;
}

// ACTION CREATORS

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: DisplayError;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: string | number;
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: ViewAPIResponse;
}

export type DetailActionTypes =
  | SetErrorAction
  | RequestDataAction
  | SetDataAction;
