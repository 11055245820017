import React, { ReactNode } from "react";
import styled from "styled-components";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { Expanded } from "./Expanded";

interface GetTableRowsParams {
  totalQuantity: number;
  hasConnections: boolean;
  creditUsed: number;
  quantity: number;
  daisyFreshQuantity: number;
  config: any;
  product: {
    id: number;
    name: string;
    price: {
      oneOffWithConnection: number;
      oneOff: number;
      daisyFresh: string;
    };
  };
  totalOneOffPrice: string;
  totalDaisyFreshPrice: string;
  handleToggle: (productId: number) => void;
  setFields: (
    productId: number,
    fields: Record<string, any>,
    index?: number
  ) => void;
}

export const getTableRows = ({
  totalQuantity,
  hasConnections,
  product,
  creditUsed,
  quantity,
  daisyFreshQuantity,
  config,
  totalOneOffPrice,
  totalDaisyFreshPrice,
  handleToggle,
  setFields,
}: GetTableRowsParams) => {
  const rows: ReactNode[] = [];

  if (totalQuantity > 0) {
    rows.push(
      <TableRow key={product.id}>
        <TableCell>
          {!config.expanded && totalQuantity > 1 && (
            <IconButton onClick={() => handleToggle(product.id)} size="large">
              <CustomAddCircleIcon />
            </IconButton>
          )}
          {config.expanded && totalQuantity > 1 && (
            <IconButton onClick={() => handleToggle(product.id)} size="large">
              <CustomRemoveCircleIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <b>{product.name}</b>
        </TableCell>
        <TableCell>
          <b>£{totalOneOffPrice}</b>
        </TableCell>
        <TableCell>
          <b>{quantity}</b>
        </TableCell>
        <TableCell>
          <b>£{totalDaisyFreshPrice}</b>
        </TableCell>
        <TableCell>
          <b>{daisyFreshQuantity}</b>
        </TableCell>
        <TableCell>
          {totalQuantity <= 1 && daisyFreshQuantity > 0 ? (
            <Box fontStyle="italic"> Not Available</Box>
          ) : (
            <TextField
              variant="standard"
              data-cy="hardwareCreditInput"
              id={"credit_used_" + product.id}
              name={"credit_used_" + product.id}
              value={creditUsed}
              onChange={(e) =>
                setFields(
                  product.id,
                  { credit_used: e.target.value, expanded: totalQuantity > 1 },
                  undefined
                )
              }
              style={{ width: "100px" }}
              type="number"
              disabled={totalQuantity > 1}
            />
          )}
        </TableCell>
        <TableCell className="actions" />
      </TableRow>
    );

    if (config.expanded && totalQuantity > 1) {
      const commonProps = {
        setFields,
        config,
        product,
        hasConnections,
      };

      for (let i = 0; i < quantity; i++) {
        rows.push(
          <Expanded {...commonProps} key={product.id + "_i_" + i} index={i} />
        );
      }

      for (let i = 0; i < daisyFreshQuantity; i++) {
        rows.push(
          <Expanded
            {...commonProps}
            key={product.id + "_i_" + i + "_daisy_fresh"}
            index={i}
            isDaisyFreshRow={true}
          />
        );
      }
    }
  }

  return rows;
};

const CustomAddCircleIcon = styled(AddCircleIcon)`
  color: ${(p) => p.theme.palette.success.main};
`;

const CustomRemoveCircleIcon = styled(RemoveCircleIcon)`
  color: ${(p) => p.theme.palette.error.main};
`;
