export const generateTableHeaders = (
  isReseller: boolean,
  showProductFamily: boolean
) => [
  {
    label: "Carrier",
    sortIndex: "network",
    width: 200,
  },
  ...(showProductFamily
    ? [{ label: "Product Family", sortIndex: "family", width: 200 }]
    : []),
  {
    label: "Product",
    sortIndex: "name",
    minWidth: 400,
  },
  // tp40209
  ...(isReseller
    ? [
        {
          label: "Price Book",
          sortIndex: "priceBook",
          width: 300,
        },
      ]
    : []),
  {
    label: "Cost",
    sortIndex: "recurringPrice",
    width: 250,
  },
];
