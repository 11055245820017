import React, { useState, useEffect, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SAME_PRODUCT } from "../../../../../../store/wlrBroadband/constants";
import {
  fetchLineProductInstances,
  fetchBroadbandProductInstances,
  setResignType,
  removeResign,
  fetchResignProductSearch,
  fetchLineResignProductSearch,
  fetchBroadbandResignProductSearch,
} from "../../../../../../store/wlrBroadband/actions/resigns";
import { Table } from "@mui/material";
import { getProductInstances } from "../../../../../../store/wlrBroadband/selectors";
import { Loader } from "../../../../../../shared/components/Loader";
import { ResignsTableSearchBar } from "../../../components/Resigns/Table/SearchBar";
import { ResignsTableHeader } from "../../../components/Resigns/Table/Header";
import { ResignsTableStatusesContainer } from "./Statuses";
import { ResignsTableBodyContainer } from "./Body";
import { addResignLocationWithSearches } from "../../../../../../store/wlrBroadband/actions/locations";

export const ResignsTableContainer = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState<number[]>([]);
  const [filter, setFilter] = useState("");

  const lineResignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.lineResignProductSearch
  );
  const broadbandResignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.broadbandResignProductSearch
  );
  const resignProductSearch = useSelector(
    (state: any) => state.wlrBroadband.resignProductSearch
  );
  const lineProductInstances = useSelector(
    (state: any) => state.wlrBroadband.lineProductInstances
  );
  const broadbandProductInstances = useSelector(
    (state: any) => state.wlrBroadband.broadbandProductInstances
  );

  const productInstances = useSelector(getProductInstances);

  const fetching = useSelector<any, boolean>(
    (state) =>
      state.wlrBroadband.lineProductInstances.fetching ||
      state.wlrBroadband.broadbandProductInstances.fetching ||
      state.wlrBroadband.lineSearch.fetching ||
      state.wlrBroadband.lineResignProductSearch.fetching ||
      state.wlrBroadband.broadbandResignProductSearch.fetching ||
      state.wlrBroadband.resignProductSearch.fetching
  );

  useEffect(() => {
    if (!resignProductSearch?.response.products) {
      dispatch(fetchResignProductSearch());
    }
    if (!lineResignProductSearch?.response.products) {
      dispatch(fetchLineResignProductSearch());
    }
    if (!broadbandResignProductSearch?.response.products) {
      dispatch(fetchBroadbandResignProductSearch());
    }
    if (!lineProductInstances?.response.results) {
      dispatch(fetchLineProductInstances({}));
    }
    if (!broadbandProductInstances?.response.results) {
      dispatch(fetchBroadbandProductInstances({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = (id: number) => selected.includes(id);

  const allChecked = (productInstances: Record<string, any>[]) =>
    selected.length === productInstances.length;

  const toggleRow = (id: number) => {
    let newSelected = [];
    const i = selected.indexOf(id);

    if (i > -1) {
      newSelected = selected.splice(i, 1);
    } else {
      newSelected = [...selected, id];
    }

    setSelected(newSelected);
  };

  const deselectAll = () => setSelected([]);

  const toggleAllRows = (productInstances: Record<string, any>[]) => {
    if (allChecked(productInstances)) {
      deselectAll();
    } else {
      setSelected(productInstances.map((_, i) => i));
    }
  };

  const handleSetFilter = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter(e.target.value);

  const doFilter = (resign: Record<string, any>) => {
    if (!filter) {
      return true;
    }
    const match = (e: string) => e.toLowerCase().includes(filter.toLowerCase());

    return match(resign.pin) || match(resign.name);
  };

  const bulkSelectByCLI = (
    text: string,
    productInstances: Record<string, any>[]
  ) => {
    const array = text.split(/,|[, ]|\n/);
    let newSelected: number[] = [];

    productInstances.forEach((item, index) => {
      if (array.find((subtext) => subtext === item.pin)) {
        newSelected.push(index);
      }
    });

    setSelected(newSelected);
  };

  const selectedProductInstances = selected.map(
    (index) => productInstances[index]
  );

  if (fetching) {
    return <Loader />;
  }

  return (
    <>
      <ResignsTableSearchBar
        filter={filter}
        countOfProducts={productInstances.length}
        countOfSelected={selected.length}
        setFilter={handleSetFilter}
        doResignWithoutChanges={() => {
          selectedProductInstances.forEach((productInstance) => {
            addResignLocationWithSearches({
              productInstance,
              resignProductType: SAME_PRODUCT,
            });
            setResignType({
              productInstances: [productInstance],
              resignProductType: SAME_PRODUCT,
            });
          });
          setSelected([]);
        }}
        doResignNone={() => {
          removeResign(
            selectedProductInstances.map(
              (productInstance) => productInstance.id
            )
          );
          setSelected([]);
        }}
        bulkSelectByCLI={(text) => bulkSelectByCLI(text, productInstances)}
      />
      <Table size="small" data-cy="wlrBroadbandResignsTable">
        <ResignsTableHeader
          checked={allChecked(productInstances)}
          onCheckedChange={() => toggleAllRows(productInstances)}
        />
        <ResignsTableBodyContainer
          doFilter={doFilter}
          deselectAll={deselectAll}
          isChecked={isChecked}
          toggleRow={toggleRow}
        />
      </Table>
      <ResignsTableStatusesContainer />
    </>
  );
};
