import React, { useState } from "react";
import {
  DataTable,
  DataTableSortOrder,
  DataTableSortState,
  StatusChip,
} from "@akj-dev/shared-components";
import { CalcType } from "../AvailabilityCheck";
import { BroadbandSearchResponse } from "../api/availabilityCheckApi";
import { Box } from "@mui/material";
import { SpeedRange } from "./SpeedRange";
import { getSpeeds } from "./getSpeeds";

interface Props {
  calcType: CalcType;
  searchResults: BroadbandSearchResponse;
}

const columns = [
  { name: "name", label: "Name", sortable: true },
  { name: "supplier", label: "Supplier", sortable: true },
  { name: "type", label: "Type", sortable: true },
  {
    name: "dlSpeed",
    label: "Normally Available Download Speed",
    sortable: true,
  },
  { name: "ulSpeed", label: "Normally Available Upload Speed", sortable: true },
  {
    name: "minDlSpeed",
    label: "Minimum Guaranteed Download Speed",
    sortable: true,
  },
  { name: "leadTime", label: "Lead Time", sortable: true },
];

export const ResultsTable = ({ calcType, searchResults }: Props) => {
  const [sortState, setStortState] = useState<DataTableSortState>({
    columnIndex: 3,
    dir: DataTableSortOrder.Desc,
  });
  const handleChangeSort = (state: DataTableSortState) => setStortState(state);

  const data = searchResults.products.map((product) => {
    const speeds = getSpeeds(searchResults, product, calcType);

    return [
      { display: product.name, value: product.name },
      {
        display: product.first_broadband_component.supplier,
        value: product.first_broadband_component.supplier,
      },
      {
        display: product.first_broadband_component.supplier_product_ref,
        value: product.first_broadband_component.supplier_product_ref,
      },
      {
        display: (
          <SpeedRange best={speeds.download.max} worst={speeds.download.min} />
        ),
        value: parseFloat(speeds.download.max),
      },
      {
        display: (
          <SpeedRange best={speeds.upload.max} worst={speeds.upload.min} />
        ),
        value: parseFloat(speeds.upload.max),
      },
      {
        display: `${speeds.download.guaranteed} Mbps`,
        value: speeds.download.guaranteed,
      },
      {
        display:
          product.first_broadband_component.estimated_lead_time?.lead_time,
        value: parseFloat(
          (
            product.first_broadband_component.estimated_lead_time?.lead_time ||
            ""
          ).match(/\d+/)![0]
        ),
      },
    ];
  });

  const displayData = data
    .sort((a, b) => {
      const valueA = a[sortState.columnIndex].value;
      const valueB = b[sortState.columnIndex].value;
      if (valueA < valueB)
        return sortState.dir === DataTableSortOrder.Asc ? -1 : 1;
      if (valueA > valueB)
        return sortState.dir === DataTableSortOrder.Asc ? 1 : -1;
      return 0;
    })
    .map((row) => row.map((cell: any) => cell.display));

  return (
    <>
      {searchResults.availability_message && (
        <Box mb={1}>
          <StatusChip
            type="error"
            title="Availability check failed"
            message={searchResults.availability_message}
          />
        </Box>
      )}
      <DataTable
        title={`Postcode: ${searchResults.original_query_params.postcode}, Exchange name: ${searchResults.exchange_details.exchange_name}, Exchange code: ${searchResults.exchange_details.exchange_code}, CP Name: ${searchResults.exchange_details.cp_name}`}
        columns={columns}
        data={displayData}
        onChangeSort={handleChangeSort}
      />
    </>
  );
};
