import React from "react";

import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import Grid from "@mui/material/Grid";

import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

interface APNsProps {
  supplier: string;
}

export const APNs = ({ supplier }: APNsProps) => (
  <Section title="APNs" avatar={<TrackChangesIcon />} defaultExpanded={false}>
    <Grid container>
      {supplier === "O2" ? (
        <>
          <Grid item sm={6}>
            <BulkDynamicField propertyName="2041" />
          </Grid>
          <Grid item sm={6}>
            <BulkDynamicField propertyName="58" />
          </Grid>
        </>
      ) : (
        <>
          <Grid item sm={6}>
            <BulkDynamicField propertyName="wap" />
          </Grid>
          <Grid item sm={6}>
            <BulkDynamicField propertyName="tethering" />
          </Grid>
          <Grid item sm={12}>
            <BulkDynamicField propertyName="apn_4" />
          </Grid>
          <Grid item sm={12}>
            <BulkDynamicField propertyName="apn_5" />
          </Grid>
        </>
      )}
    </Grid>
  </Section>
);
