import { formatISO, startOfDay, subWeeks, subMonths, subYears } from "date-fns";
import { DateString } from "../types";

export function checkValidDateStr(str: string): str is DateString {
  return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null;
}

export function toDateString(date: Date | string): DateString {
  if (typeof date === "string") {
    if (checkValidDateStr(date)) {
      return date;
    } else {
      // attempt to trim the date eg. "2020-11-13T00:00:00" or "2020-11-13 AM"
      let trimmed = date.includes("T")
        ? date.split("T")[0]
        : date.split(" ")[0];
      if (checkValidDateStr(trimmed)) {
        return trimmed;
      }
      throw new Error(`Invalid date string: ${date}`);
    }
  } else {
    try {
      const dateString = formatISO(date, {
        representation: "date",
      });
      if (checkValidDateStr(dateString)) {
        return dateString;
      } else {
        throw new Error(`invalid toDateStr provided: ${date}`);
      }
    } catch (e) {
      if (typeof e === "string") {
        throw new Error(e);
      } else {
        throw e;
      }
    }
  }
}

export const dateStringSubWeek = toDateString(
  startOfDay(subWeeks(new Date(), 1))
);
export const dateStringSubMonth = toDateString(
  startOfDay(subMonths(new Date(), 1))
);
export const dateStringSubQuarterYear = toDateString(
  startOfDay(subMonths(new Date(), 3))
);
export const dateStringSubYear = toDateString(
  startOfDay(subYears(new Date(), 1))
);
