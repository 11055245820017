import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { addSpaceToPostcode } from "../../../shared/utils/addresses";
import { NEW_LINE, RESIGN, TRANSFER, NUMBER_RES_NEXT } from "../constants";

const newConfiguration = (params: any, state: any) => {
  const location = state.locations[params.locationIndex];
  const contractLength = state.contractLength;
  const installationDetails = location.installationDetails?.response;
  const wltoDetails = _.get(location, "wltoDetails.response", {});
  const productId = params.productId || params.resignProductId;

  const config = {
    commission_type: params.canAccessCommission
      ? contractLength === 1
        ? "Residual"
        : "Upfront"
      : null,
    locationIndex: params.locationIndex,
    wlrProductId: productId,
    broadbandProductId: undefined,

    wlrProperties: {
      as_frontend_uuid: uuidv4(),
      ...((location.type === NEW_LINE || location.type === RESIGN) && {
        acquisition_method: "install",
        termination_type: "LINE_BOX",
        installation_type: "STANDARD",
        force_new_provide: 1,
        caller_display: 1,
        got_installation_details: location.type === RESIGN ? 1 : 0,
      }),

      ...(location.type === TRANSFER && {
        acquisition_method:
          wltoDetails.line_plant_type === "WORKING_LINE"
            ? "install"
            : "transfer",
        installation_type:
          wltoDetails.line_plant_type === "WORKING_LINE"
            ? "STANDARD"
            : installationDetails.installation_type,
        product_type: installationDetails.line_type || "BASIC",
        termination_type: (() => {
          const type = location.installationDetails?.response.termination_type;
          if (!type) return "LINE_BOX";
          return type === "NTE" ? "LINE_BOX" : type;
        })(),
        care_level: installationDetails.care_level,
        number_of_channels:
          installationDetails.number_of_channels ||
          installationDetails.line_quantity_including_main_line ||
          1,
        like_for_like: wltoDetails.line_plant_type === "WORKING_LINE" ? 0 : 1,
        got_installation_details: 1,
        cli: location.cli.value,
      }),

      autoprovision: 1,
      line_plant_type: "SPARE_PAIR",
      address_category: location.address.addressCategory,
      address_reference: location.address.addressReference,
      county: location.address.county,
      css_database_code: location.address.cssDatabaseCode,
      dependentthoroughfarename: location.address.dependentThoroughfareName,
      dependentlocality: location.address.doubleDependentLocality,
      locality: location.address.locality,
      company: location.address.organisationName,
      pobox: location.address.poBox,
      postcode: addSpaceToPostcode(location.address.postcode),
      posttown: location.address.postTown,
      premisesname: location.address.premisesName,
      subpremises: location.address.subPremises,
      thoroughfarename: location.address.thoroughfareName,
      thoroughfarenumber: location.address.thoroughfareNumber,
    },
    broadbandProperties: {},

    validation: {},

    wlrProductData: {
      fetching: false,
      response: {},
    },

    broadbandProductData: {
      fetching: false,
      response: {},
      initialWlrChangeValues: {},
    },

    wlrAppointments: {
      fetching: false,
      response: {
        appointments: [],
      },
      selectedIndex: null,
    },

    addWlrAppointment: {
      fetching: false,
      response: {},
    },

    broadbandAppointments: {
      fetching: false,
      response: {
        appointments: [],
      },
      selectedIndex: null,
    },

    addBroadbandAppointment: {
      fetching: false,
      response: {},
    },

    numberReservation: {
      fetching: false,
      response: {},
      type: NUMBER_RES_NEXT,
      selectedNumber: "",
    },

    wlrValidation: {
      fetching: false,
      response: {},
    },

    wlrValidationQuote: {
      fetching: false,
      response: {},
    },

    broadbandValidation: {
      fetching: false,
      response: {},
    },

    orderProduct: {
      wlr: {
        fetching: false,
        response: {},
      },
      broadband: {
        fetching: false,
        response: {},
      },
    },
  };
  return config;
};

export default newConfiguration;
