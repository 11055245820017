import { MenuItem, TextField, Button, Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React, { useState } from "react";
import { LostReason } from "./enum";
import { CancelOrderReason } from "./types";

interface Props {
  onSubmit: (reason: CancelOrderReason) => void;
  onCancel: () => void;
}

export const DeleteDraftForm = ({ onSubmit, onCancel }: Props) => {
  const classes = useStyles();
  const [reason, setReason] = useState<"" | LostReason>("");
  const [otherReason, setOtherReason] = useState<string>("");

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 500) {
      setOtherReason(event.target.value);
    }
  };

  const handleCancel = () => {
    onCancel();
    setReason("");
    setOtherReason("");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!reason) {
      return;
    }
    if (reason === LostReason.OTHER && otherReason) {
      onSubmit({ reason, otherReason });
    } else {
      onSubmit({ reason });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        className={classes.textField}
        style={{ marginBottom: 16 }}
        label="Select the reasons"
        fullWidth
        variant="outlined"
        select
        value={reason}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setReason(event.target.value as LostReason);
        }}
      >
        <MenuItem value={LostReason.COMMERCIAL}>
          {LostReason.COMMERCIAL}
        </MenuItem>
        <MenuItem value={LostReason.COVERAGE}>{LostReason.COVERAGE}</MenuItem>
        <MenuItem value={LostReason.DECISION}>{LostReason.DECISION}</MenuItem>
        <MenuItem value={LostReason.SERVICE}>{LostReason.SERVICE}</MenuItem>
        <MenuItem value={LostReason.STAYED}>{LostReason.STAYED}</MenuItem>
        <MenuItem value={LostReason.OTHER}>{LostReason.OTHER}</MenuItem>
      </TextField>
      {reason === LostReason.OTHER && (
        <TextField
          helperText="Max 500 characters"
          className={classes.textField}
          variant="outlined"
          fullWidth
          multiline
          value={otherReason}
          rows={4}
          onChange={handleOtherChange}
          label="Please explain further"
        />
      )}
      <Box className={classes.actions}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
        <Button color="primary" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </form>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(1),
    },
    actions: {
      marginBottom: theme.spacing(1),
      "& > button": {
        marginRight: theme.spacing(1),
      },
    },
  })
);
