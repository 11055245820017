import React from "react";
import VoiceOverOffIcon from "@mui/icons-material/VoiceOverOff";
import { Grid } from "@mui/material";

import { Spinner } from "../../../../shared/components/Spinner";
import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

interface BarsProps {
  isFetchingBarsCompatibility: boolean;
  productData: any;
  barDisabled: (name: string) => boolean;
  fetchBarsCompatibility: () => void;
}

export const Bars = ({
  isFetchingBarsCompatibility,
  productData,
  barDisabled,
  fetchBarsCompatibility,
}: BarsProps) => (
  <Section
    title="Bars"
    avatar={isFetchingBarsCompatibility ? <Spinner /> : <VoiceOverOffIcon />}
    defaultExpanded={false}
  >
    <Grid container spacing={2}>
      {Object.keys(productData.mobile.dynamic_properties)
        .filter((name) => name.includes("bar_"))
        .sort((a, b) => (a < b ? 1 : a > b ? -1 : 0))
        .map((name) => (
          <Grid item sm={6} key={`barGridItem_${name}`}>
            <BulkDynamicField
              propertyName={name}
              afterUpdate={fetchBarsCompatibility}
              disabled={barDisabled(name)}
            />
          </Grid>
        ))}
    </Grid>
  </Section>
);
