import { authApiFetch } from "@akj-dev/affinity-platform";
import { getJSON } from "./";

export function accounts({
  query,
  limit = 10,
}: {
  query: string;
  limit: number;
}) {
  return getJSON("Account/AutoComplete", {
    name: query,
    limit,
  });
}

export function users({ query }: { query: string }) {
  return authApiFetch("organisation/users", {
    method: "GET",
    query,
  });
}
