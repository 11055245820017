import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";
import { Pricing } from "../../../../../../shared/containers/Pricing";
import {
  requestMobilePricingData,
  setMobileProductDiscount,
  updateConfigProperty,
} from "../../../../../../store/mobile/actions";
import { makeGetMergedProductData } from "../../../../../../store/mobile/selectors/productConfig";
import { getHasMatchingPricingRules } from "../../../../../../store/mobile/selectors/pricing";
import { useTargetConfigsContext } from "../../../../context/TargetConfigsContext";

export const MobilePricingWrapper = () => {
  const dispatch = useDispatch();

  const { targetConfigs } = useTargetConfigsContext();

  const hasMatchingPricingRules = useSelector((state) =>
    getHasMatchingPricingRules(state, targetConfigs)
  );

  const properties = useSelector<any, any>(
    (state) => state.mobile.configs[targetConfigs[0]].properties
  );

  const productData = useSelector((state) =>
    //@ts-ignore
    makeGetMergedProductData()(state, { targetConfigs })
  );

  if (targetConfigs.length > 1 && !hasMatchingPricingRules) {
    return (
      <Box mb={1}>
        <StatusChip
          type="info"
          title="Sorry, pricing is not bulk editable"
          message="You must select products with the same pricing rule."
        />
      </Box>
    );
  }

  const setPricingScheme = (scheme: string | null | undefined) => {
    dispatch(
      updateConfigProperty({
        propertyName: "pricing_scheme",
        value: scheme,
        configIds: targetConfigs,
      })
    );
    // ....and fetch config specific product (pricing) data TODO: Limit to one config.
    dispatch(requestMobilePricingData({ configIds: targetConfigs }));
  };

  const setDiscount = (
    _: string,
    discountType: string,
    discountValue: number | null
  ) => {
    dispatch(
      setMobileProductDiscount(discountType, discountValue, targetConfigs)
    );
    dispatch(requestMobilePricingData({ configIds: targetConfigs }));
  };
  const setServiceTariff = (tariff: string | null | undefined) => {
    dispatch(
      updateConfigProperty({
        propertyName: "service_tariff_id",
        value: tariff,
        configIds: targetConfigs,
      })
    );
    dispatch(requestMobilePricingData({ configIds: targetConfigs }));
  };

  return (
    <Pricing
      title="Pricing"
      productData={productData}
      configProperties={properties}
      setPricingScheme={setPricingScheme}
      setDiscount={setDiscount}
      setServiceTariff={setServiceTariff}
    />
  );
};
