import React, { CSSProperties, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { setNewLineConfiguration } from "../../../../../../../store/wlrBroadband/actions/configurations";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../types";
import { WlrLocationNewLineBroadbandCellModalContainer } from "../BroadbandCell/Modal";
import { getIsMultiLineProduct } from "../../../../../../../store/wlrBroadband/selectors";
import { MAX_QUANTITY_BROADBAND } from "../../../../components/WlrLocation/NewLine/Table";
import { WlrLocationNewLineTableRow } from "../../../../components/WlrLocation/NewLine/Table/Row";

export interface WlrLocationNewLineTableRenderRowProps {
  index: number;
  style: CSSProperties;
  data: {
    locationIndex: number;
    products: WlrLocationProduct[];
  };
}

export const WlrLocationNewLineTableRenderRow = ({
  index,
  style,
  data,
}: WlrLocationNewLineTableRenderRowProps) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { locationIndex, products } = data;
  const product = products[index];

  // Location
  const location = useSelector<any, ReduxWlrLocation>(
    (state) => state.wlrBroadband.locations[locationIndex]
  );

  const isMultiLineProduct =
    useSelector((state) => getIsMultiLineProduct(state, product.id)) ||
    location?.installationDetails?.response?.service_type ===
      "PSTN_MULTI_LINE_AUX";

  // Location configurations (quantities of products)
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    (state) => state.wlrBroadband.configurations ?? []
  );

  // Broadband Products
  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const productConfigurations = configurations.filter(
    (c) => c.wlrProductId === product.id && c.locationIndex === locationIndex
  );

  // For the max qty (across all the locations)
  const configCount = configurations.length;

  // Qty for this location and this product
  const productConfigCount = productConfigurations.length;

  // One off price of a product
  const oneOffPrice = parseFloat(
    product.price.first_bill_recurring_price_with_promotions
  );

  // Total price of all broadbands (in this location and this product)
  const prices = productConfigurations
    .filter((c) => !!c.broadbandProductId)
    .map(
      (c) =>
        broadbandProducts.find((b) => b.id === c.broadbandProductId)?.price
          .first_bill_recurring_price_with_promotions ?? "0.00"
    );
  const totalPrice = prices
    .reduce((total, p) => total + parseFloat(p), 0)
    .toFixed(2);

  const memoizedProduct = useMemo(
    () => ({
      id: product.id,
      name: product.name,
    }),
    [product.id, product.name]
  );

  const handleQuantity = useCallback(
    (qty: number = 0) => {
      dispatch(setNewLineConfiguration({ locationIndex, product, qty }));
    },
    [dispatch, locationIndex, product]
  );

  const toggleModal = useCallback(() => setOpen(true), [setOpen]);

  return (
    <Box data-cy={product.name} className={classes.root} style={style}>
      <WlrLocationNewLineTableRow
        locationIndex={locationIndex}
        product={memoizedProduct}
        hasReachedMaxConfigCount={MAX_QUANTITY_BROADBAND < configCount}
        productConfigCount={productConfigCount}
        oneOffPrice={oneOffPrice}
        totalPrice={totalPrice}
        isMultiLineProduct={isMultiLineProduct}
        isAddBroadbandDisabled={productConfigCount === 0 || isMultiLineProduct}
        handleQuantity={handleQuantity}
        toggleModal={toggleModal}
      />
      {open && (
        <WlrLocationNewLineBroadbandCellModalContainer
          open={open}
          locationIndex={locationIndex}
          location={location}
          product={product}
          handleClose={() => setOpen(false)}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));
