import { combineReducers } from "redux";

import { overviewReducer } from "./overview/reducers";
import { detailReducer } from "./detail/reducers";
import { notesReducer } from "./notes/reducers";
import { BoardReducer } from "./board/reducers";

import autocomplete from "./autocomplete/reducer";
import newnotification from "./newnotification/reducer";
import notifications from "./notifications/reducer";

export const rootReducer = combineReducers({
  overview: overviewReducer,
  detail: detailReducer,
  notes: notesReducer,
  board: BoardReducer,

  autocomplete,
  newnotification,
  notifications,
});

export type RootState = ReturnType<typeof rootReducer>;
