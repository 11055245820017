import React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { LocationDescriptorObject } from "history";
import { Theme } from "@mui/material";

type Props = {
  title: string;
  value: number;
  to: LocationDescriptorObject | string;
};

const styles = {
  root: {
    cursor: "pointer",
    paddingTop: 1.5,
    paddingRight: 0.25,
    paddingBottom: 1.5,
    paddingLeft: 0.25,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transition: (theme: Theme) =>
      `background-color ${theme.transitions.easing.easeInOut} 0.${theme.transitions.duration.standard}s`,
    "&:hover": {
      backgroundColor: "grey.100",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: 600,
      fontSize: 24,
      color: "text.primary",
      textAlign: "center",
    },
  },
};

export const OrdersSummaryCardItem = ({ title, value, to }: Props) => {
  return (
    <Box sx={styles.root}>
      <Link to={to}>
        <Box>
          <Box>{value}</Box>
          <Box fontSize={12}>{title}</Box>
        </Box>
      </Link>
    </Box>
  );
};
