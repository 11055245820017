import React from "react";

import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Typography, useTheme } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

import {
  ColumnModel,
  SortableTable,
} from "../../../../shared/components/SortableTable";
import { TableActionsCell } from "../../../../shared/components/TableActionsCell";
import { Loader } from "../../../../shared/components/Loader";

const HEADER_DEFINITION = [
  {
    header: "Product",
    accessor: "name",
    align: "left",
  },
  {
    header: "Quantity",
    accessor: "",
    align: "right",
  },
  {
    header: "",
    accessor: "",
    align: "left",
  },
] as ColumnModel[];

interface ProductTableProps {
  selectedCount: number;
  products: any[];
  productCount: number;
  isProductError: boolean;
  showLoader: boolean;
  onRetryClick: () => void;
  onAddProduct: (itemId: string) => void;
  onRemoveProduct: (itemId: string) => void;
  getProductConfigCount: (itemId: string) => number;
}

export const ProductTable = ({
  selectedCount,
  productCount,
  isProductError,
  products,
  showLoader,
  onRetryClick,
  onAddProduct,
  onRemoveProduct,
  getProductConfigCount,
}: ProductTableProps) => {
  const theme = useTheme();

  return (
    <div data-cy="UniversalProductTable">
      <Typography>
        {`${selectedCount} Selected / ${productCount} Available`}
      </Typography>
      {showLoader && (
        <Loader text="Searching for Universal Products. This may take up to a minute." />
      )}
      {isProductError ? (
        <StatusChip
          type="error"
          title="Error"
          message="Something went wrong finding hardware products."
          retry={onRetryClick}
        />
      ) : (
        productCount && (
          <SortableTable
            columns={HEADER_DEFINITION}
            data={products}
            renderRow={(product) => {
              const productConfigCount = getProductConfigCount(product.id);

              return (
                <TableRow key={product.id} selected={!!productConfigCount}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell align="right">{productConfigCount}</TableCell>
                  <TableActionsCell>
                    <IconButton
                      onClick={() => onAddProduct(product.id)}
                      data-cy="incrementUniversalProduct"
                      style={{ color: theme.palette.success.main }}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => onRemoveProduct(product.id)}
                      style={{ color: theme.palette.error.main }}
                      size="large"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableActionsCell>
                </TableRow>
              );
            }}
          />
        )
      )}
    </div>
  );
};
