import React, { ReactNode } from "react";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter } from "@mui/material";
import styled from "styled-components";

import { Popover } from "../../../../shared/components/Popover";
import { Section } from "../../../../shared/components/Section";
import { ContractLength } from "../../../../shared/containers/ContractLength";
import { StatusChip } from "@akj-dev/shared-components";

interface HardwareTableProps {
  contractLengthInMonthsAllProducts: number;
  hardwareCount: number;
  hasDaisyFresh: boolean;
  isFetchingAvailableHardwareCredit: boolean;
  rows: ReactNode[];
  totalOneOffPrice: string;
  remainingHWCredit: number;
  totalQuantity: number;
  totalQuantityDaisyFresh: number;
  totalDaisyFreshPrice: number;
  totalConfigHardwareCredit: number;
}

export const HardwareTable = ({
  contractLengthInMonthsAllProducts,
  hardwareCount,
  hasDaisyFresh,
  isFetchingAvailableHardwareCredit,
  rows,
  totalOneOffPrice,
  remainingHWCredit,
  totalQuantity,
  totalQuantityDaisyFresh,
  totalDaisyFreshPrice,
  totalConfigHardwareCredit,
}: HardwareTableProps) => (
  <>
    {contractLengthInMonthsAllProducts !== 24 &&
      hardwareCount > 0 &&
      hasDaisyFresh && <ContractLength />}
    <Section title="Customise Pricing" avatar={<MonetizationOnIcon />}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Product</TableCell>
            <TableCell>Pay Up Front</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Daisy Fresh</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>
              Hardware Credit
              <Popover position={"left"}>
                The hardware fund can be used to purchase hardware against your
                account. See below remaining value.
              </Popover>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell>Total</TableCell>
            <TableCell>{`£${totalOneOffPrice}`}</TableCell>
            <TableCell>{totalQuantity}</TableCell>
            <TableCell>{`£${totalDaisyFreshPrice}`}</TableCell>
            <TableCell>{totalQuantityDaisyFresh}</TableCell>
            <TableCell colSpan={2}>
              <TotalUsed>{`£${totalConfigHardwareCredit.toFixed(
                2
              )}`}</TotalUsed>
              <TotalRemaining>
                (£{remainingHWCredit.toFixed(2)} remaining)
              </TotalRemaining>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Section>

    {isFetchingAvailableHardwareCredit && (
      <StatusChip
        type="loading"
        title="Please wait"
        message="Loading available hardware credit"
      />
    )}
  </>
);

const TotalUsed = styled.span`
  margin-right: 10px;
`;

const TotalRemaining = styled.span`
  color: ${(p) => p.theme.palette.info.main};
`;
