import React from "react";

import { Grid, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ProductCard } from "../../../../shared/components/ProductCard";
import { EthernetTypeEnum } from "../../../../store/ethernetProducts/constants";

const ethernetTypeOptions = [
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED,
    title: "Ethernet",
    subtitle: "Managed Internet Access",
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_EFM,
    title: "EFM",
    subtitle: "Managed Internet Access",
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_GEA,
    title: "GEA",
    subtitle: "Managed Internet Access",
  },
  {
    type: EthernetTypeEnum.ETHERNET_PRODUCT_PTP,
    title: "Ethernet",
    subtitle: "Point to Point",
  },
];

export interface EthernetConfigurationTypeProps {
  selectedType: EthernetTypeEnum;
  setConfigType: (type: EthernetTypeEnum) => void;
}

export const EthernetConfigurationType = ({
  selectedType,
  setConfigType,
}: EthernetConfigurationTypeProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      {ethernetTypeOptions.map(({ title, subtitle, type }) => (
        <Grid key={type} item md={3}>
          <ProductCard
            dataCy={`card-${type}`}
            checked={selectedType === type}
            handleClick={() => setConfigType(type)}
          >
            <div className={classes.body}>
              <Typography>{title}</Typography>
              <Typography className={classes.subtitle}>{subtitle}</Typography>
            </div>
          </ProductCard>
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles({
  body: {
    height: 80,
  },
  subtitle: {
    fontSize: "0.75rem",
  },
});
