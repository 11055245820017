import React from "react";
import { useQuery } from "./hooks/useQuery";
import { Cpq } from "@akj-dev/cpq";
import { kongAxios } from "../../core/kongAxios";

export const CpqWrapper = () => {
  const query = useQuery();

  // TODO: Do we ever set all these params for orders? Copied from sales
  return (
    <Cpq
      hostEnv="platform"
      fetchFn={kongAxios}
      accountId={query.get("accountId")}
      savedOrderId={query.get("order_id")}
      orderId={query.get("orderId")}
      leadId={query.get("leadId")}
      leadNumber={query.get("leadNumber")}
      opportunityId={query.get("opportunityId")}
      opportunityNumber={query.get("opportunityNumber")}
      opportunityName={query.get("opportunityName")}
      customerName={query.get("customerName")}
    />
  );
};
