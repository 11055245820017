import React from "react";
import {
  StatusChip,
  CheckIcon,
  LoaderButton,
} from "@akj-dev/shared-components";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";

import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";
import { SIM_VERIFIED, SIM_ERROR } from "../../utils/simStatuses";

interface SimSerialVerificationProps {
  isFetchingSIM: boolean;
  isMulti: boolean;
  simStatus: string;
  onVerifyClick: () => void;
  isValidationFailed: boolean;
  validationErrorMessage: string;
  verifyButtonDisabled: boolean;
  labelSuffix: string;
}

export const SimSerialVerification = ({
  isFetchingSIM,
  simStatus,
  onVerifyClick,
  isValidationFailed,
  validationErrorMessage,
  verifyButtonDisabled,
  isMulti,
  labelSuffix,
}: SimSerialVerificationProps) => {
  return (
    <Box>
      <ArrayDynamicField
        propertyName="sim_buffer_serial"
        floatingLabelText="SIM Serial"
        suffixLabelText={labelSuffix}
        unique
      />
      <Box display="flex">
        <LoaderButton
          loading={isFetchingSIM}
          onClick={onVerifyClick}
          data-cy="SimSerialVerify"
          color="primary"
          disabled={verifyButtonDisabled}
        >
          Verify
        </LoaderButton>
        <Box ml={1}>
          {simStatus === SIM_VERIFIED && (
            <CheckIcon data-cy="SimVerified" fontSize="large" />
          )}
          {simStatus === SIM_ERROR && (
            <ErrorIcon fontSize="large" color="error" />
          )}
        </Box>
      </Box>
      {isValidationFailed && (
        <Box mt={1}>
          <StatusChip
            type="error"
            title="Sim Validation Error"
            message={validationErrorMessage}
          />
        </Box>
      )}
    </Box>
  );
};
