import React from "react";

import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material/styles";

import { TierBase } from "./TierBase";

interface SelectTierProps {
  name: string;
  description: string;
  index: number;
  quantity: number;
  price: string;
  removeProduct: () => void;
  addProduct: () => void;
}

export const SelectTier = ({
  name,
  price,
  description,
  quantity,
  index,
  addProduct,
  removeProduct,
}: SelectTierProps) => {
  const theme = useTheme();

  return (
    <TierBase>
      <div className="header">
        <div>{name}</div>
        <div className="price">&pound;{price}</div>
      </div>
      <div className="description">{description}</div>
      <div className="action">
        <IconButton
          data-cy={"removeTier" + index}
          onClick={removeProduct}
          size="large"
        >
          <RemoveCircleOutlineIcon htmlColor={theme.palette.success.main} />
        </IconButton>
        <div className="quantity">{quantity}</div>
        <IconButton
          data-cy={"addTier" + index}
          onClick={addProduct}
          size="large"
        >
          <AddCircleOutlineIcon htmlColor={theme.palette.success.main} />
        </IconButton>
      </div>
    </TierBase>
  );
};
