import { trackEvent } from "@redux-beacon/google-analytics";
import * as actionTypes from "./actionTypes";
import { trackSimpleEvent } from "../../shared/utils/reduxBeacon";

const gaEventsMap = {
  ...trackSimpleEvent(actionTypes.SET_CONTRACT_LENGTH_IN_MONTHS_ALL_PRODUCTS),
  ...trackSimpleEvent(actionTypes.TOGGLE_CO_TERMINUS),
  ...trackSimpleEvent(actionTypes.SET_ACCOUNT_BOLT_ON),
  ...trackSimpleEvent(actionTypes.SET_ACCOUNT_BOLT_ON_START_DATE),
  ...trackSimpleEvent(actionTypes.SET_RESIGN_START_DATE),
  [actionTypes.SET_PRODUCT_QUANTITY]: trackEvent(
    (action) => ({
      category: "mobile",
      action: "SET_PRODUCT_QUANTITY",
      value: action.qty,
    }),
    "gtm1"
  ),
  ...trackSimpleEvent(actionTypes.SET_PRODUCT_QUANTITY),
  ...trackSimpleEvent(actionTypes.REMOVE_CONFIG),
  ...trackSimpleEvent(actionTypes.SET_RESIGN_TYPE),
  ...trackSimpleEvent(actionTypes.REMOVE_RESIGN),
  ...trackSimpleEvent(actionTypes.CHOOSE_CLI_BOLT_ON),
  ...trackSimpleEvent(actionTypes.TOGGLE_BILL_CAP_CONFIRMATION),
};

export default gaEventsMap;
