import React from "react";
import { useDispatch } from "react-redux";

import { ReduxEthernetConfiguration } from "../types";
import { EthernetConfigurationAddress } from "../components/EthernetConfigurationAddress";
import {
  setEthernetConfigAddress,
  updateEthernetConfiguration,
  updateEthernetCustomerData,
} from "../../../../store/ethernetProducts/actions";

export interface EthernetConfigurationAddressProps {
  prefix: "site_a_" | "site_b_";
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
  withCli?: boolean;
  cli?: string;
  setCli?: (cli: string) => void;
}

export const EthernetConfigurationAddressContainer = ({
  prefix,
  configIndex,
  configuration,
  withCli,
  cli,
  setCli,
}: EthernetConfigurationAddressProps) => {
  const dispatch = useDispatch();

  return (
    <EthernetConfigurationAddress
      withCli={withCli}
      cli={cli}
      prefix={prefix}
      configuration={configuration}
      setCli={setCli}
      onUpdateAddress={(address) =>
        dispatch(updateEthernetCustomerData(configIndex, address, undefined))
      }
      onRemoveAddress={() =>
        dispatch(setEthernetConfigAddress(configIndex, {}, prefix))
      }
      onSetConfigAddress={(wlr3Address, prefix) =>
        dispatch(setEthernetConfigAddress(configIndex, wlr3Address, prefix))
      }
      updateEthernetConfiguration={(value, prefixUprn) => {
        dispatch(updateEthernetConfiguration(configIndex, prefixUprn, value));
      }}
    />
  );
};
