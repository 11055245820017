import React from "react";
import { useSelector } from "react-redux";
import { getSpendCapsNotSet } from "../../../../store/mobile/selectors/productConfig";
import { VodafoneSpendCapWarning } from "../../components/Mobile/VodafoneSpendCapWarning";

export const VodafoneSpendCapWarningContainer = () => {
  const spendCapsNotSet = useSelector<any, boolean>((state) =>
    getSpendCapsNotSet(state)
  );

  return <VodafoneSpendCapWarning spendCapsNotSet={spendCapsNotSet} />;
};
