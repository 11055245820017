import { GeneateContractState } from "./types";

const initialState: GeneateContractState = {
  contactId: "",
  generatingContract: false,
  error: null,
  openRedirectDialog: false,
  contract: false,
};

export default initialState;
