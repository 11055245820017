import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { TextField } from "@mui/material";

type Props = {
  onDebouncedChange: (value?: string) => void;
  value: string;
  wait: number;
  id: string;
  label: string;
  [x: string]: any;
};

export const FreeSearch = ({
  value,
  onDebouncedChange,
  wait,
  id,
  label,
  ...rest
}: Props) => {
  const [val, setVal] = useState("");
  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleDebouncedChange = useMemo(
    () =>
      debounce((input: string) => {
        onDebouncedChange(input || undefined);
      }, wait),
    [onDebouncedChange, wait]
  );

  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      id={id}
      label={label}
      type="search"
      value={val}
      onChange={(event) => {
        setVal(event.target.value);
        handleDebouncedChange(event.target.value);
      }}
      {...rest}
    />
  );
};
