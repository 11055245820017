import React from "react";
import _ from "lodash";
import styled from "styled-components";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {
  FormHelperText,
  Select as SelectField,
  SelectChangeEvent,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import FieldContainer from "./WlrBBDynamicField/FieldContainer";

const Price = styled.div`
  display: inline-block;
  padding-left: 10px;
  font-size: 0.9em;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
`;

const Description = styled.div`
  font-size: 0.9em;
  line-height: 1.6em;
  color: #999;
  max-width: 400px;
  white-space: normal;
`;

interface SelectRouterProps {
  propertyValidation: string;
  dynamicProperty: Record<string, any>;
  propertyValue: string;
  availableOptionDetails: Record<string, any>[];
  onRouterChange: (event: SelectChangeEvent<string>) => void;
}

export const SelectRouter = ({
  propertyValidation,
  dynamicProperty,
  propertyValue,
  availableOptionDetails,
  onRouterChange,
}: SelectRouterProps) => (
  <FieldContainer>
    <FormControl fullWidth error={Boolean(propertyValidation)} margin="normal">
      <InputLabel variant="standard">{`${dynamicProperty.label}${
        dynamicProperty.is_required ? " *" : ""
      }`}</InputLabel>
      <SelectField
        variant="standard"
        value={propertyValue || ""}
        onChange={onRouterChange}
      >
        <MenuItem />
        {availableOptionDetails.map((option) => {
          let recurringPrice = _.get(
            option,
            "first_bill_recurring_price_with_promotions",
            0
          );
          let oneOffPrice = _.get(option, "one_off_price_with_promotions", 0);
          if (parseFloat(recurringPrice) === 0) recurringPrice = false;
          if (parseFloat(oneOffPrice) === 0) oneOffPrice = false;
          const description = _.get(option, "product_description", false);
          return (
            <MenuItem key={option.product_id} value={option.product_id}>
              <div>
                {option.product_name}
                {oneOffPrice && <Price>£{oneOffPrice} (one off)</Price>}
                {recurringPrice && <Price>£{recurringPrice} / month</Price>}
                {description && <Description>{description}</Description>}
              </div>
            </MenuItem>
          );
        })}
      </SelectField>
      {propertyValidation && (
        <FormHelperText>{propertyValidation}</FormHelperText>
      )}
    </FormControl>
  </FieldContainer>
);
