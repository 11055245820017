import React from "react";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export interface WlrLocationNewLineBroadbandCellProps {
  locationIndex: number;
  disabled: boolean;
  handleClick: (index: number) => void;
  message: string | boolean;
}

export const WlrLocationNewLineBroadbandCell = ({
  locationIndex,
  disabled,
  handleClick,
  message,
}: WlrLocationNewLineBroadbandCellProps) => {
  return (
    <Tooltip
      title={message}
      disableFocusListener={!message}
      disableHoverListener={!message}
      disableTouchListener={!message}
    >
      <span>
        <Button
          data-cy="broadband-modal-button"
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={() => handleClick(locationIndex)}
        >
          Add Broadband
        </Button>
      </span>
    </Tooltip>
  );
};
