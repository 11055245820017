import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setOrderContact } from "../../../store/order/actions";

import { Contacts } from "../components/Contacts";

interface ContactsContainerProps {
  cardIndex: string;
}

export const ContactsContainer = ({ cardIndex }: ContactsContainerProps) => {
  const dispatch = useDispatch();

  const selectedId = useSelector<any, string>(
    (state) => state.order.orderContactId
  );

  const onOrderContactChange = (id: string) => dispatch(setOrderContact(id));

  return (
    <Contacts
      cardIndex={cardIndex}
      selectedId={selectedId}
      onOrderContactChange={onOrderContactChange}
    />
  );
};
