import React from "react";
import { Box, Card, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";

import { getCreditVetProcessAttempted } from "../../../../store/account/selectors";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";

import { CreditVetStatusContainer } from "./CreditVetStatus";
import { Basket } from "./Cart";
import { StepperContainer } from "./Stepper";

export function Header() {
  const classes = useStyles();
  const canAccessVfDirect = useCanAccessVfDirect();

  const creditVetProcessAttempted = useSelector(getCreditVetProcessAttempted);

  return (
    <Box className={classes.wrapper}>
      <Card className={classes.root}>
        <StepperContainer />
        <Basket />
      </Card>
      {canAccessVfDirect && creditVetProcessAttempted && (
        <CreditVetStatusContainer />
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "sticky",
    zIndex: 10,
    top: 0,

    // custom width 100 % because of LayoutContainers.tsx
    width: "calc(100% + 80px)",
    marginLeft: "-32px",
  },
  root: {
    display: "flex",
    transform: "translateZ(0px)", // this creates a separate layer for <Card/> so the box shadow is not cut off by <CreditVet/>
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2.5),
    borderRadius: 0,
  },
}));
