import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLineAvailabilityForLocation,
  getProductInstances,
  getResignProductForProductInstanceServiceType,
  getTagsCheckForLocation,
} from "../../../../../store/wlrBroadband/selectors";
import {
  fetchLineAvailabilityForResign,
  doBroadbandSearchForResign,
  setWlrResignProduct,
} from "../../../../../store/wlrBroadband/actions/resigns";
import { doUpdateProductInstance } from "../../../../../store/wlrBroadband/actions/configurations";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { LineProductSelector } from "../../components/Resigns/LineProductSelector";

interface LineProductSelectorContainerProps {
  configuration: any;
  productInstance: any;
}

export const LineProductSelectorContainer = ({
  configuration,
  productInstance,
}: LineProductSelectorContainerProps) => {
  const dispatch = useDispatch();

  // setup variables for selectors
  const locationIndex = configuration.locationIndex;
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const configurationIndex = configurations.indexOf(configuration);

  // selectors
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );
  const lineAvailability = useSelector((state: any) =>
    getLineAvailabilityForLocation(state, { configuration })
  );
  const products = useSelector(
    (state: any) => state.wlrBroadband.lineSearch.response.products
  );
  const resignProduct = useSelector((state: any) =>
    getResignProductForProductInstanceServiceType(state, productInstance)
  );
  const tagsCheck = useSelector((state: any) =>
    getTagsCheckForLocation(state, { configuration })
  );
  const productInstances = useSelector(getProductInstances);

  const setResignProduct = (
    productInstance: any,
    resignProduct: any,
    resignProductType: any
  ) => {
    dispatch(
      setWlrResignProduct({
        productInstance,
        resignProductId: resignProduct.id,
        resignProductType,
      })
    );
  };

  return (
    <LineProductSelector
      tagsCheck={tagsCheck}
      products={products}
      resignProduct={resignProduct}
      lineAvailability={lineAvailability}
      location={location}
      productInstance={productInstance}
      configuration={configuration}
      updateProductInstance={configuration.updateProductInstance}
      setResignProduct={setResignProduct}
      onSetAddress={(address) => {
        dispatch(
          doUpdateProductInstance({
            id: productInstance.id,
            pin: productInstance.pin,
            configurationIndex,
            address,
            resignProductType: configuration.resignProductType,
            productInstance: productInstance,
          })
        );
        if (configuration.resignProductType === NEW_PRODUCT) {
          dispatch(fetchLineAvailabilityForResign(productInstance));
          dispatch(doBroadbandSearchForResign(productInstance));
        }
        if (
          configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
          productInstance.type === "wlr" &&
          productInstances.filter(
            (item: any) => item.pin === productInstance.pin
          ).length === 1
        ) {
          dispatch(doBroadbandSearchForResign(productInstance));
        }
      }}
    />
  );
};
