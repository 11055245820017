import React from "react";

import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";

import FieldContainer from "./WlrBBDynamicField/FieldContainer";
import ChangeIcon from "./WlrBBDynamicField/ChangeIcon";
import { ChangeEvent } from "react";

interface WlrChangePriceFieldProps {
  label: string;
  propertyValue: string;
  discountEnabled: boolean;
  onProductDiscountChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onProductDiscountBlur: () => void;
  onRemoveDiscount: () => void;
}

export const WlrChangePriceField = ({
  label,
  propertyValue,
  discountEnabled,
  onProductDiscountChange,
  onProductDiscountBlur,
  onRemoveDiscount,
}: WlrChangePriceFieldProps) => (
  <FieldContainer>
    <TextField
      variant="standard"
      label={label}
      onChange={onProductDiscountChange}
      onBlur={onProductDiscountBlur}
      type="number"
      value={propertyValue || ""}
    />
    {discountEnabled && <ChangeIcon />}
    {discountEnabled && (
      <Tooltip title="Remove Discount">
        <IconButton onClick={onRemoveDiscount} size="large">
          <CloseIcon />
        </IconButton>
      </Tooltip>
    )}
  </FieldContainer>
);
