import React, { ChangeEvent } from "react";

import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import InputAdornment from "@mui/material/InputAdornment";
import { StatusChip } from "@akj-dev/shared-components";

import { ResellerBillingNotificationContainer } from "../../containers/Mobile/ConfigurationForm/sections/BillLimits/ResellerBillingNotification";
import { StandardBillingNotification } from "../../containers/Mobile/ConfigurationForm/sections/BillLimits/StandardBillingNotification";
import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { useDwsResellerEnabled } from "../../../../shared/hooks/useDwsResellerEnabled";

interface BillLimitsProps {
  billLimitRequired: boolean;
  targetConfigs: number[];
  billLimit: number | string;
  onBillLimitRequiredChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const BillLimits = ({
  billLimitRequired,
  targetConfigs,
  billLimit,
  onBillLimitRequiredChange,
}: BillLimitsProps) => {
  const isDwsResellerEnabled = useDwsResellerEnabled();

  return (
    <Section title="Bill Limits" avatar={<MonetizationOnIcon />}>
      <Grid container alignItems="center">
        <Grid item sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={billLimitRequired}
                onChange={onBillLimitRequiredChange}
                name="billLimitRequired"
              />
            }
            label="Bill limit required"
          />
        </Grid>
        <Grid item sm={6}>
          <BulkDynamicField
            propertyName="bill_limit"
            disabled={!billLimitRequired}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        {billLimitRequired && (
          <>
            <Grid item sm={12}>
              <StatusChip
                type="info"
                message={
                  <span>
                    {isDwsResellerEnabled ? (
                      <div>
                        This is your bill limit with your supplier, manage your
                        limits appropriately
                      </div>
                    ) : (
                      <div>
                        Notifications for this Bill Limit will be sent to the
                        end user by SMS.
                      </div>
                    )}
                    {billLimit !== "" && (
                      <Box mt={1}>
                        {billLimit > 0 ? (
                          <b>
                            Note: You are adding a £{billLimit} bill limit. This
                            means the customer will be able to spend £
                            {billLimit} beyond their included allowance.
                          </b>
                        ) : (
                          <b>
                            Note: You are adding a zero bill limit. This means
                            the customer won't be able to spend beyond their
                            included allowance.
                          </b>
                        )}
                      </Box>
                    )}
                  </span>
                }
              />
            </Grid>
            <Grid item sm={12}>
              {isDwsResellerEnabled ? (
                <ResellerBillingNotificationContainer />
              ) : (
                <StandardBillingNotification targetConfigs={targetConfigs} />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  );
};
