import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { ConfigurationStatus } from "./ConfigurationStatus";
import { Spinner } from "../../../../shared/components/Spinner";
import Checkbox from "@mui/material/Checkbox";
import {
  EXISTING_LINE,
  NEW_SOGEA,
  EXISTING_SOGEA,
  NEW_FTTP,
} from "../../../../store/wlrBroadband/constants";
import { StatusChip } from "@akj-dev/shared-components";
import { InlinePriceMeta } from "../../../../shared/components/Pricing/InlinePriceMeta";
import { Edit, Delete } from "@mui/icons-material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableActionsCell } from "../../../../shared/components/TableActionsCell";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { setProductProperty } from "../../../../store/wlrBroadband/actions/configurations";
import { useDispatch } from "react-redux";
import { getIsConfigValid } from "../../../../store/wlrBroadband/selectors";
import { formatCurrency } from "../../../../shared/utils/formatCurrency";
import { CommissionRadio } from "../../../../shared/components/CommissionRadio";

interface ConfigTableRowProps {
  c: Record<string, any>;
  configIndex: number;
  isMPFLine: boolean;
  checked?: boolean;
  toggleAction?: () => void;
  editAction?: () => void;
  deleteAction?: () => void;
  retryAction?: () => void;
  showCommissionTypeSelection?: boolean;
  isOneMonthContract?: boolean;
}

export const ConfigTableRow = ({
  c,
  configIndex,
  checked,
  isMPFLine,
  toggleAction,
  editAction,
  deleteAction,
  retryAction,
  showCommissionTypeSelection,
  isOneMonthContract,
}: ConfigTableRowProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // See loading statuses inline...
  // Also this to disable edit when anything's fetching:
  const fetching =
    c.wlrProductData.fetching ||
    c.broadbandProductData.fetching ||
    c.broadbandValidation.fetching;

  const onCommissionSelect = () =>
    dispatch(
      setProductProperty(
        [configIndex],
        null,
        "commission_type",
        c.commission_type === "Residual" ? "Upfront" : "Residual"
      )
    );

  // Show product data error states...

  if (
    !fetching &&
    (c.wlrProductData?.response.status === "error" ||
      c.broadbandProductData?.response.status === "error")
  )
    return (
      <TableRow key={configIndex}>
        <TableCell colSpan={7}>
          <StatusChip
            type="error"
            title="Error fetching product data."
            retry={retryAction}
          />
        </TableCell>
      </TableRow>
    );

  // If everything's ok, show the config row
  return (
    <TableRow key={configIndex}>
      {toggleAction && (
        <TableCell>
          <Checkbox
            color="secondary"
            checked={checked}
            onChange={toggleAction}
          />
        </TableCell>
      )}
      <TableCell align="left">
        {c.wlrProductData.fetching ? (
          <Spinner size={30} />
        ) : (
          c.wlrProductData?.response.product_name ||
          (c.wlrProductId === EXISTING_LINE && EXISTING_LINE) ||
          (isMPFLine && "MPF Line") ||
          (c.wlrProductId === NEW_FTTP && NEW_FTTP) ||
          (c.wlrProductId === NEW_SOGEA && NEW_SOGEA) ||
          (c.wlrProductId === EXISTING_SOGEA && EXISTING_SOGEA)
        )}
      </TableCell>
      <TableCell align="center">
        {formatCurrency(
          parseFloat(
            _.get(
              c.wlrProductData.response,
              "overall_price.one_off_price_with_promotions",
              0
            )
          ) +
            parseFloat(
              _.get(
                c.broadbandProductData.response,
                "overall_price.one_off_price_with_promotions",
                0
              )
            )
        )}
        <InlinePriceMeta
          productData={c.wlrProductData?.response}
          priceType="ONE_OFF"
        />
        <InlinePriceMeta
          productData={c.broadbandProductData?.response}
          priceType="ONE_OFF"
        />
      </TableCell>
      <TableCell align="center">
        {formatCurrency(
          parseFloat(
            _.get(
              c.wlrProductData.response,
              "overall_price.first_bill_recurring_price_with_promotions",
              0
            )
          ) +
            parseFloat(
              _.get(
                c.broadbandProductData.response,
                "overall_price.first_bill_recurring_price_with_promotions",
                0
              )
            )
        )}
        <InlinePriceMeta
          productData={c.wlrProductData?.response}
          priceType="RECURRING"
        />
        <InlinePriceMeta
          productData={c.broadbandProductData?.response}
          priceType="RECURRING"
        />
      </TableCell>
      <TableCell align="center">
        {c.broadbandProductData.fetching ? (
          <Spinner size={30} />
        ) : (
          _.get(c.broadbandProductData.response, "product_name", "None")
        )}
      </TableCell>

      {showCommissionTypeSelection && (
        <TableCell align="center" className={classes.commissionCell}>
          <CommissionRadio
            disabled={isOneMonthContract}
            commissionType={c.commission_type}
            onCommissionSelect={onCommissionSelect}
          />
        </TableCell>
      )}
      <TableCell align="center">
        {c.broadbandValidation.fetching ? (
          <Spinner size={30} />
        ) : (
          <ConfigurationStatus
            configuration={c}
            onClick={fetching ? () => false : editAction}
            isConfigValid={getIsConfigValid(c)}
          />
        )}
      </TableCell>
      {editAction && (
        <TableActionsCell align="right">
          <Button
            onClick={editAction}
            disabled={fetching}
            data-cy="wlrBroadbandEditConfig"
            variant="contained"
            startIcon={<Edit />}
          >
            Edit
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={deleteAction}
            disabled={fetching}
            variant="contained"
            color="primary"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </TableActionsCell>
      )}
    </TableRow>
  );
};

const useStyles = makeStyles({
  deleteButton: {
    marginLeft: 5,
    background: "#cc3324",
    "&:hover": {
      backgroundColor: "#bb2e1e",
    },
  },
  commissionCell: { padding: 0, maxWidth: "100%" },
});

ConfigTableRow.propTypes = {
  c: PropTypes.object.isRequired,
  configIndex: PropTypes.number.isRequired,
  toggleAction: PropTypes.func,
  checked: PropTypes.bool,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
  retryAction: PropTypes.func,
};
