import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchBarsCompatibility } from "../../../../../../store/mobile/actions";
import { Bars } from "../../../../components/Mobile/Bars";
import { useEffect } from "react";

interface BarsProps {
  targetConfigs: number[];
}

export const BarsContainer = ({ targetConfigs }: BarsProps) => {
  const dispatch = useDispatch();
  const config = useSelector<any, Record<string, any>>(
    (state) => state.mobile.configs[targetConfigs[0]]
  );
  const productData = useSelector<any, any>(
    (state) => state.mobile.productData[config.productId]?.response
  );

  const targetConfig = targetConfigs[0];

  useEffect(() => {
    dispatch(fetchBarsCompatibility(targetConfig));
  }, [dispatch, targetConfig]);

  const barDisabled = (name: string) =>
    !_.get(config.barsCompatibility, "response.valid_ids", []).includes(name) &&
    // @ts-ignore
    !config.properties[name] == 1; // eslint-disable-line eqeqeq

  return (
    <Bars
      productData={productData}
      isFetchingBarsCompatibility={config.barsCompatibility?.fetching}
      barDisabled={barDisabled}
      fetchBarsCompatibility={() =>
        dispatch(fetchBarsCompatibility(targetConfigs[0]))
      }
    />
  );
};
