import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";

import { setFields } from "../../../../store/hardware/configurations/actions";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import { getIsConfigSplit } from "../../../../store/hardware/selectors";
import { CollapseRow } from "../../components/Hardware/CollapseRow";
import { HardwareDeliveryRow } from "../../components/Hardware/DeliveryRow";

import { SplitDeliveryHardwareSplitList } from "./SplitDeliveryHardwareSplitList";

interface SplitDeliveryHardwareListProps {
  splitEditId: string;
  setSplitEditId: Dispatch<SetStateAction<string>>;
  hardwareConfigs: any;
}

export const SplitDeliveryHardwareList = ({
  splitEditId,
  setSplitEditId,
  hardwareConfigs,
}: SplitDeliveryHardwareListProps) => {
  const dispatch = useDispatch();

  const handleChangeAddressType =
    (productId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setFields(productId, {
          addressType: event.target.value,
          addressId: "",
        })
      );
      setSplitEditId("");
    };

  const handleClickExistingAddressSelect = (productId: string) => () => {
    dispatch(
      setFields(productId, {
        addressType: addressTypes.EXISTING,
      })
    );
  };

  const handleSelectAccountAddress =
    (productId: string) => (event: any, child: ReactNode) => {
      dispatch(
        setFields(productId, {
          addressId: (child as ReactElement).props.value,
        })
      );
    };

  return (
    <>
      {Object.keys(hardwareConfigs).map((productId) => {
        const config = hardwareConfigs[productId];

        if (config.quantity > 0) {
          const split = getIsConfigSplit(config);
          return [
            <HardwareDeliveryRow
              key={productId}
              productId={productId}
              quantity={config.quantity}
              split={split}
              splitEditId={splitEditId}
              setSplitEditId={setSplitEditId}
              productName={config.product.name}
              addressType={config.rows[0].addressType}
              addressId={config.rows[0].addressId}
              handleChangeAddressType={handleChangeAddressType(productId)}
              handleSelectAccountAddress={handleSelectAccountAddress(productId)}
              handleClickExistingAddressSelect={handleClickExistingAddressSelect(
                productId
              )}
            />,
            <CollapseRow
              visible={productId === splitEditId}
              key={`${productId}-split`}
            >
              <SplitDeliveryHardwareSplitList
                productId={productId}
                productName={config.product.name}
              />
            </CollapseRow>,
          ];
        } else {
          return null;
        }
      })}
    </>
  );
};
