import React, { useMemo, CSSProperties } from "react";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../../../types";
import { BroadbandModalTableRow } from "./Row";

interface BroadbandModalTableRenderRowProps {
  index: number;
  style: CSSProperties;
  data: {
    contractLength: number;
    location: ReduxWlrLocation;
    locationProduct: WlrLocationProduct;
    locationIndex: number;
    isMpfProduct: boolean;
    products: WlrLocationProductConfig[];
    broadbandProducts: WlrLocationBroadbandProduct[];
  };
}

export const BroadbandModalTableRenderRow = ({
  index,
  style,
  data: {
    contractLength,
    location,
    locationProduct: product,
    isMpfProduct,
    products: configurations,
    broadbandProducts,
    locationIndex,
  },
}: BroadbandModalTableRenderRowProps) => {
  const classes = useStyles();

  const configuration = configurations[index];

  const broadbandProductId = configuration.broadbandProductId ?? "";
  const broadbandProduct = broadbandProducts.find(
    (bp) => bp.id === broadbandProductId
  );

  const broadbandDetails = broadbandProduct?.first_broadband_component;

  const memoizedBroadbandDetails = useMemo(
    () => ({
      estimatedDownloadRange: broadbandDetails?.estimated_download_range,
      estimatedUploadRange: broadbandDetails?.estimated_upload_range,
      minimumGuaranteedSpeed: broadbandDetails?.minimum_guaranteed_speed,
      leadTime: broadbandDetails?.estimated_lead_time?.lead_time,
    }),
    [
      broadbandDetails?.estimated_download_range,
      broadbandDetails?.estimated_lead_time?.lead_time,
      broadbandDetails?.estimated_upload_range,
      broadbandDetails?.minimum_guaranteed_speed,
    ]
  );

  return (
    <Box className={classes.root} style={style}>
      <BroadbandModalTableRow
        contractLength={contractLength}
        locationType={location.type}
        productName={product.name}
        isMpfProduct={isMpfProduct}
        locationIndex={locationIndex}
        configId={configuration.configId!}
        price={
          broadbandProduct?.price.first_bill_recurring_price_with_promotions
        }
        broadbandProductId={broadbandProductId}
        broadbandDetails={memoizedBroadbandDetails}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}`,
  },
}));
