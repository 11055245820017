import React from "react";
import { Grid } from "@mui/material";
import VoiceIcon from "@mui/icons-material/KeyboardVoice";
import { ExpandingCard } from "@akj-dev/shared-components";

import { FormSelectFieldContainer } from "../../containers/Ethernet/EthernetOrderForm/FormSelectField";

interface VoiceProps {
  expanded: boolean;
  index: number;
  withVoice: boolean;
  handleHeaderClick: () => void;
}

export const Voice = ({
  expanded,
  index,
  withVoice,
  handleHeaderClick,
}: VoiceProps) => (
  <ExpandingCard
    expanded={expanded}
    title="Voice"
    avatar={<VoiceIcon />}
    onHeaderClick={handleHeaderClick}
  >
    <Grid container spacing={2}>
      <Grid item sm={6}>
        <FormSelectFieldContainer
          index={index}
          helperText="Is this circuit being sold with Voice? *"
          param="with_voice"
          options={{
            1: "Yes",
            0: "No",
          }}
        />
      </Grid>
      {withVoice && (
        <Grid item sm={6}>
          <FormSelectFieldContainer
            index={index}
            helperText="Which voice service is this being sold with? *"
            param="voice_service"
            options={{
              HVS: "HVS",
              "Daisy SIP": "Daisy SIP",
              "Gamma SIP": "Gamma SIP",
              Other: "Other",
            }}
          />
        </Grid>
      )}
    </Grid>
  </ExpandingCard>
);
