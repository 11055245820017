import React, { ChangeEvent } from "react";

import { LoaderButton } from "@akj-dev/shared-components";
import { TextField, Grid, Button } from "@mui/material";

interface AddPricingRequestProps {
  handleClose: () => void;
  onSubmit: () => void;
  onDescriptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isFetching: boolean;
  description: string;
}

export const AddPricingRequest = ({
  handleClose,
  onSubmit,
  onDescriptionChange,
  isFetching,
  description,
}: AddPricingRequestProps) => (
  <Grid container spacing={1} justifyContent="flex-end">
    <Grid item sm={12}>
      <TextField
        variant="standard"
        id="pricing-request-content"
        label="Add Pricing Request Description"
        multiline
        maxRows={4}
        value={description}
        onChange={onDescriptionChange}
        fullWidth
        autoFocus
        disabled={isFetching}
      />
    </Grid>
    <Grid item>
      <LoaderButton loading={isFetching} onClick={onSubmit} color="primary">
        Submit Request
      </LoaderButton>
    </Grid>
    <Grid item>
      <Button onClick={handleClose}>Close</Button>
    </Grid>
  </Grid>
);
