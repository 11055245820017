import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import {
  setConfigProperty,
  setProductDiscount,
  setPricingScheme,
} from "../../../../../store/universalProducts/actions";
import {
  getSelectedConfig,
  getRecurringDiscountedPrice,
  getOneOffDiscountedPrice,
} from "../../../../../store/universalProducts/selectors";
import { Pricing } from "../../../components/UniversalProducts/Pricing";

import { Section } from "../../../../../shared/components/Section";
import { PriceMeta } from "../../../../../shared/components/Pricing/PriceMeta";
import { DiscountFields } from "../../../../../shared/components/Pricing/DiscountFields";
import { PricingScheme } from "../../../../../shared/components/Pricing/PricingScheme";

export const PricingContainer = () => {
  const dispatch = useDispatch();

  const config = useSelector(getSelectedConfig);

  const productData = useSelector(
    (state: any) => state.universalProducts.productSearch.response.products
  );

  const selectedConfigIndex = useSelector(
    (state: any) => state.universalProducts.selectedConfigIndex
  );

  const configProduct = productData.find(
    (product: any) => product.id === config.productId
  );

  const setScheme = (scheme: string) => {
    dispatch(setPricingScheme(selectedConfigIndex, scheme));
  };

  const setDiscount = (
    priceType: string,
    discountType: string | null,
    discountValue: string | number | null,
    fullPrice: any
  ) => {
    dispatch(
      setProductDiscount(
        [selectedConfigIndex],
        "universalProduct",
        priceType,
        discountType,
        discountValue,
        fullPrice
      )
    );
  };

  const {
    // Pricing scheme
    available_pricing_schemes,
    pricing_rule_id,
    pricing_scheme_details,
  } = configProduct;

  const pricing_scheme_name =
    config.pricingScheme ||
    (available_pricing_schemes && available_pricing_schemes[0]);

  const pricing_scheme =
    pricing_scheme_name && pricing_scheme_details[pricing_scheme_name];

  if (pricing_scheme && pricing_rule_id) {
    const recurring_component = getRecurringDiscountedPrice(
      config.universalProductProperties,
      pricing_scheme,
      true
    );
    const {
      // Recurring discounts
      minimum_recurring_price,
      maximum_recurring_discount_percent,
      recurring_price_without_discounts,
      //
    } = recurring_component;

    const one_off_component = getOneOffDiscountedPrice(
      config.universalProductProperties,
      pricing_scheme,
      true
    );

    const {
      minimum_one_off_price,
      maximum_one_off_discount_percent,
      one_off_price_without_discounts,
    } = one_off_component;

    // Some products can have discounts, others can't.
    // This applies to both one off and recurring ones
    const discountAllowed = recurring_component.allow_custom_discount == 1; // eslint-disable-line eqeqeq
    const oneOffDiscountAllowed = one_off_component.allow_custom_discount == 1; // eslint-disable-line eqeqeq

    // See if we have recurring or one off prices, so we know which discount UIs to display
    const hasRecurringPrice = parseFloat(recurring_price_without_discounts) > 0; // This is the base price
    const hasOneOffPrice =
      parseFloat(_.get(one_off_component, "one_off_price_without_discounts")) >
      0;

    return (
      <Section title={"Pricing"} avatar={<MonetizationOnIcon />}>
        <PricingScheme
          availablePricingSchemes={available_pricing_schemes}
          pricingSchemeDetails={pricing_scheme_details}
          pricingScheme={config.pricingScheme || available_pricing_schemes[0]}
          setPricingScheme={setScheme}
        />
        {hasRecurringPrice && discountAllowed && (
          <DiscountFields
            discountType={
              config.universalProductProperties.recurring_discount_type
            }
            priceType="recurring"
            discountValue={
              config.universalProductProperties.recurring_discount_value
            }
            minPrice={minimum_recurring_price}
            fullPrice={recurring_price_without_discounts}
            maxPercent={maximum_recurring_discount_percent}
            setDiscount={setDiscount}
            scheme={pricing_scheme_name}
          />
        )}
        {hasRecurringPrice && (
          <PriceMeta
            priceType="RECURRING"
            componentPrice={recurring_component}
            fetching={false}
          />
        )}
        {hasOneOffPrice && oneOffDiscountAllowed && (
          <DiscountFields
            discountType={
              config.universalProductProperties["one_off_discount_type"]
            }
            priceType="one_off"
            discountValue={
              config.universalProductProperties["one_off_discount_value"]
            }
            minPrice={minimum_one_off_price}
            fullPrice={one_off_price_without_discounts}
            maxPercent={maximum_one_off_discount_percent}
            setDiscount={setDiscount}
            scheme={pricing_scheme_name}
          />
        )}

        {hasOneOffPrice && (
          <PriceMeta
            priceType="ONE_OFF"
            componentPrice={one_off_component}
            fetching={false}
          />
        )}
      </Section>
    );
  } else
    return (
      <Pricing
        oneOffPrice={config.oneOffPrice}
        recurringPrice={config.recurringPrice}
        hasValidPrices={config.hasValidPrices}
        onChangePrice={(propertyName, value) =>
          dispatch(setConfigProperty(propertyName, value))
        }
      />
    );
};
