import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./state";
import { orderProduct } from "./actions";

const configurations = createSlice({
  name: "hardwareOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderProduct.pending, (state, action) => {
        const { identifier, shouldUpdate } = action.meta.arg;
        state[identifier] = {
          fetching: true,
          isUpdate: shouldUpdate,
          response: {},
        };
      })
      .addCase(orderProduct.fulfilled, (state, action: PayloadAction<any>) => {
        const { identifier, response } = action.payload;
        state[identifier] = {
          fetching: false,
          isUpdate: state[identifier].isUpdate,
          response,
        };
      });
  },
});

export default configurations.reducer;
