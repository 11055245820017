import React from "react";
import { Grid, TextField } from "@mui/material";

import { AddressPicker } from "@akj-dev/shared-components";

import { AddressChip } from "../../Ethernet/components/AddressChip";
import { isValidPhoneNumber } from "../../../../shared/utils/validation";
import { ReduxEthernetConfiguration } from "../types";
import {
  WLR3Address,
  WLR3AddressSearch,
} from "../../../../api/v1/addressSearch";
import {
  getDCAddressLine,
  getDCEthernetAddressValid,
} from "../../../../shared/utils/addresses";
import { AddressCorrect } from "./AddressCorrect";

export interface EthernetConfigurationAddressProps {
  prefix: string;
  configuration: ReduxEthernetConfiguration;
  withCli?: boolean;
  cli?: string;
  setCli?: (cli: string) => void;
  onUpdateAddress: (address: any) => void;
  onRemoveAddress: () => void;
  updateEthernetConfiguration: (
    value: string | false,
    prefixUprn: string
  ) => void;
  onSetConfigAddress: (wlr3Address: WLR3Address, prefix: string) => void;
}

export const EthernetConfigurationAddress = ({
  prefix,
  cli,
  configuration,
  withCli,
  setCli,
  onUpdateAddress,
  onRemoveAddress,
  updateEthernetConfiguration,
  onSetConfigAddress,
}: EthernetConfigurationAddressProps) => {
  const prefixUprn = `${prefix}uprn` as keyof ReduxEthernetConfiguration;
  const uprn = configuration[prefixUprn];

  const addressLine = getDCAddressLine(configuration.purchaseArgs, prefix);
  const addressValid = getDCEthernetAddressValid(
    configuration.purchaseArgs,
    prefix
  );

  return (
    <Grid container spacing={2}>
      {addressLine && (
        <Grid item md={12}>
          <AddressChip address={addressLine} remove={onRemoveAddress} />
        </Grid>
      )}
      {uprn && (
        <Grid item md={12}>
          <AddressChip
            address={
              <span>
                UPRN: <b>{uprn}</b>
              </span>
            }
            remove={() => updateEthernetConfiguration(false, prefixUprn)}
          />
        </Grid>
      )}
      <Grid item md={withCli ? 6 : 12}>
        <AddressPicker
          saveAddress={(wlr3Address) => onSetConfigAddress(wlr3Address, prefix)}
          saveUPRN={(uprn) => updateEthernetConfiguration(uprn, prefixUprn)}
          includeUPRN
          api={WLR3AddressSearch}
        />
      </Grid>
      {withCli && (
        <Grid item md={6}>
          <TextField
            label={`Enter CLI (optional)`}
            variant={"outlined"}
            data-cy={"ethernet-CLI"}
            value={cli}
            helperText={
              cli && !isValidPhoneNumber(cli) && "Must be a valid CLI"
            }
            error={!!cli && !isValidPhoneNumber(cli)}
            onChange={(e) => setCli?.(e.target.value)}
          />
        </Grid>
      )}
      <Grid item md={12}>
        <AddressCorrect
          address={configuration.purchaseArgs}
          prefix={prefix}
          updateAddress={onUpdateAddress}
          open={!!(addressLine && !addressValid)}
        />
      </Grid>
    </Grid>
  );
};
