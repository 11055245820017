import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  CircularProgress,
  Box,
} from "@mui/material";
import { ReactNode } from "react";

interface UploadSignedContractProps {
  openDialog: () => void;
  closeDialog: () => void;
  showDialog: boolean;
  isUploadButtonDisabled: boolean;
  isOrderStatusFetching: boolean;
  contentChildren: ReactNode;
}

export const UploadSignedContract = ({
  showDialog,
  isOrderStatusFetching,
  isUploadButtonDisabled,
  contentChildren,
  openDialog,
  closeDialog,
}: UploadSignedContractProps) => (
  <>
    <Dialog open={showDialog} onClose={closeDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Signed Contract</DialogTitle>
      {contentChildren}
    </Dialog>
    <Box mb={1}>
      <Button
        variant="contained"
        color="secondary"
        onClick={openDialog}
        endIcon={
          isOrderStatusFetching && (
            <CircularProgress size={20} color="inherit" />
          )
        }
        disabled={isUploadButtonDisabled}
        data-cy={!isOrderStatusFetching && "UploadSignedContractAvailable"}
      >
        Upload Signed Contract
      </Button>
    </Box>
  </>
);
