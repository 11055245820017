import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CachedIcon from "@mui/icons-material/Cached";
import { useTheme } from "@mui/material/styles";

import { Chip } from "../../../../../shared/components/Chip/Chip";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  SAME_PRODUCT,
} from "../../../../../store/wlrBroadband/constants";
import { RowActionsButton } from "../../../../../shared/components/RowActionsButton";
import { getHostEnv } from "../../../../../store/order/selectors/getHostEnv";
import { useCanAccessVfDirect } from "../../../../../shared/hooks/useCanAccessVfDirect";

interface RowActionsProps {
  configuration: Record<string, any>;
  productInstance: Record<string, any>;
  resignProduct?: Record<string, any>;
  hasProductId?: boolean;
  setChanges?: (resignProductType: string) => void;
  setNoChanges?: () => void;
  removeResign: () => void;
}

// TODO: Why do we have two separate RowActions components? (one for wlr & one for mobile)
//  Is there definitely enough difference to justify that?
export const RowActionsContainer = ({
  configuration,
  hasProductId,
  productInstance,
  resignProduct,
  setNoChanges = () => {},
  setChanges = () => {},
  removeResign,
}: RowActionsProps) => {
  const theme = useTheme();
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();

  const linkedConfiguration = useSelector((state: any) =>
    state.wlrBroadband.configurations.find(
      (config: any) =>
        config.pin === productInstance.pin &&
        (config.type === "wlr" || config.type === "mpf")
    )
  );

  const fetching = useSelector<any, boolean>(
    (state) =>
      state.wlrBroadband.lineProductInstances.fetching ||
      state.wlrBroadband.broadbandProductInstances.fetching ||
      state.wlrBroadband.lineSearch.fetching ||
      state.wlrBroadband.lineResignProductSearch.fetching ||
      state.wlrBroadband.broadbandResignProductSearch.fetching ||
      state.wlrBroadband.resignProductSearch.fetching
  );

  const isResignProduct = resignProduct?.components[0]?.is_resign;
  const hasLinkedSameProductConfig =
    !!linkedConfiguration &&
    linkedConfiguration.resignProductType === SAME_PRODUCT;
  if (
    configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
    resignProduct &&
    !isResignProduct
  )
    return (
      <Chip
        title="Resign"
        color={theme.palette.success.main}
        Icon={AutorenewIcon}
        onClick={removeResign}
        outline={!hasProductId}
      />
    );
  switch (configuration.resignProductType) {
    case NEW_PRODUCT:
      return (
        <Chip
          title="Resign"
          color={theme.palette.primary.main}
          Icon={AutorenewIcon}
          onClick={removeResign}
          outline={!hasProductId}
        />
      );
    case SAME_PRODUCT_WITH_CHANGES:
      return (
        <Chip
          title={
            hostEnv === "platform" && !canAccessVfDirect
              ? "Resign"
              : "Same Product With Changes"
          }
          color={theme.palette.primary.main}
          Icon={AutorenewIcon}
          onClick={removeResign}
          outline={!hasProductId}
        />
      );
    case SAME_PRODUCT:
      return (
        <Chip
          title="Same Product"
          color={theme.palette.primary.main}
          Icon={SettingsBackupRestoreIcon}
          onClick={removeResign}
        />
      );
    default:
      return (
        <div data-cy="resignOptionsBtn">
          {hostEnv === "platform" && !canAccessVfDirect && (
            <>
              {productInstance && productInstance.type !== "wlr" ? (
                <Button
                  data-cy="wlrBroadbandResignNPWChangeBtn"
                  variant="outlined"
                  color="primary"
                  onClick={() => setChanges(NEW_PRODUCT)}
                  disabled={!!fetching || !!hasLinkedSameProductConfig}
                >
                  Resign
                </Button>
              ) : (
                <Button
                  data-cy="wlrBroadbandResignSPWChangeBtn"
                  variant="outlined"
                  color="primary"
                  onClick={() => setChanges(SAME_PRODUCT_WITH_CHANGES)}
                  endIcon={<CachedIcon />}
                  disabled={!!fetching || !!hasLinkedSameProductConfig}
                >
                  Resign
                </Button>
              )}
            </>
          )}
          {(hostEnv !== "platform" || canAccessVfDirect) && (
            <RowActionsButton
              items={[
                ...([
                      {
                        label: "Same product",
                        dataCy: "wlrBroadbandResignSPWOChangeBtn",
                        disabled: !!fetching,
                        onClick: setNoChanges,
                      },
                    ]),
                ...([
                      {
                        label: "Same product with changes",
                        dataCy: "wlrBroadbandResignSPWChangeBtn",
                        disabled: !!fetching || !!hasLinkedSameProductConfig,
                        onClick: () => setChanges(SAME_PRODUCT_WITH_CHANGES),
                      },
                    ]),
                ...(productInstance && productInstance.type !== "wlr"
                  ? [
                      {
                        label: "Resign",
                        dataCy: "wlrBroadbandResignNPWChangeBtn",
                        disabled: !!fetching || !!hasLinkedSameProductConfig,
                        onClick: () => setChanges(NEW_PRODUCT),
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </div>
      );
  }
};
