import React from "react";
import { Card, Theme } from "@mui/material";

interface Props {
  owner?: string;
  address?: string;
  reference?: string;
}

export function OrderInfo({ owner, address, reference }: Props) {
  return (
    <Card raised sx={styles.root}>
      <table>
        <tbody>
          {owner && (
            <tr>
              <th>Created by</th>
              <td>{owner}</td>
            </tr>
          )}
          {address && (
            <tr>
              <th>Address</th>
              <td>{address}</td>
            </tr>
          )}
          {reference && (
            <tr>
              <th>Reference</th>
              <td>{reference}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Card>
  );
}

const styles = {
  root: {
    marginBottom: 1.5,
    padding: 1,
    "& table": {
      borderCollapse: "separate",
      borderSpacing: (theme: Theme) => `0 ${theme.spacing(0.5)}`,
      fontSize: (theme: Theme) => theme.typography.body2.fontSize,
      "& th": {
        textAlign: "left",
        width: "150px",
      },
    },
  },
};
