import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import {
  setResignStartDate,
  setAccountBoltOnStartDate,
} from "../../../../store/mobile/actions";
import { getSelectedBoltOnCount } from "../../../../store/mobile/selectors/boltOns";
import { ResignStartDate } from "../../components/Mobile/ResignStartDate";
import { DC_DATE_FORMAT } from "../../../../shared/utils/date";

export const ResignStartDateContainer = () => {
  const dispatch = useDispatch();

  const startDate = useSelector<any, string>(
    (state) => state.mobile.resignStartDate
  );
  const boltOnCount = useSelector<any, number>(
    (state) =>
      getSelectedBoltOnCount(state, 1) + getSelectedBoltOnCount(state, 2)
  );

  const onDateChange = (date: Date | null) => {
    if (date) dispatch(setResignStartDate(format(date, DC_DATE_FORMAT)));

    if (boltOnCount > 0) {
      dispatch(setAccountBoltOnStartDate({ date }));
    }
  };

  return <ResignStartDate onDateChange={onDateChange} startDate={startDate} />;
};
