import React from "react";
import { useDispatch } from "react-redux";
import { updateEthernetConfiguration } from "../../../../store/ethernetProducts/actions";
import { ReduxEthernetConfiguration } from "../types";
import { EthernetConfigurationFailOver } from "../components/EthernetConfigurationFailOver";

export interface EthernetConfigurationFailOverProps {
  configIndex: number;
  configuration: ReduxEthernetConfiguration;
}

export const EthernetConfigurationFailOverContainer = ({
  configIndex,
  configuration,
}: EthernetConfigurationFailOverProps) => {
  const dispatch = useDispatch();

  return (
    <EthernetConfigurationFailOver
      configuration={configuration}
      onUpdateConfiguration={(propertyName, value) =>
        dispatch(updateEthernetConfiguration(configIndex, propertyName, value))
      }
    />
  );
};
