import React from "react";
import { Grid, Typography } from "@mui/material";

import { SelectedProductContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/SelectedProduct";
import { AdjustPriceContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/AdjustPrice";
import { SiteInstallationAddress } from "./SiteInstallationAddress";
import { CustomerRequiredDateContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/CustomerRequiredDate";
import { SiteContactContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/SiteContact";
import { LocationContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/Location";
import { VoiceContainer } from "../../containers/Ethernet/EthernetOrderForm/sections/Voice";
import { EthernetTypeEnum } from "../../../../store/ethernetProducts/constants";

interface EthernetOrderFormProps {
  index: number;
  configuration: Record<string, any>;
}

export const EthernetOrderForm = ({
  configuration,
  index,
}: EthernetOrderFormProps) => (
  <Grid container spacing={2}>
    <Grid item sm={12}>
      <SelectedProductContainer index={index} configuration={configuration} />
    </Grid>
    <Grid item sm={12}>
      <AdjustPriceContainer index={index} />
    </Grid>
    {configuration.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP && (
      <>
        <Grid item sm={12}>
          <Typography variant="h3">Site A</Typography>
        </Grid>
        <Grid item sm={6}>
          <SiteInstallationAddress
            purchaseArgs={configuration.purchaseArgs}
            prefix="site_a"
          />
        </Grid>
        <Grid item sm={12}>
          <SiteContactContainer prefix="site_a" index={index} />
        </Grid>
        <Grid item sm={12}>
          <LocationContainer prefix="site_a" index={index} />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h3">Site B</Typography>
        </Grid>
      </>
    )}

    <Grid item sm={6}>
      <SiteInstallationAddress
        purchaseArgs={configuration.purchaseArgs}
        prefix="site_b"
      />
    </Grid>
    <Grid item sm={6}>
      <CustomerRequiredDateContainer index={index} />
    </Grid>
    <Grid item sm={12}>
      <SiteContactContainer prefix="site_b" index={index} />
      <LocationContainer prefix="site_b" index={index} />
      {configuration.type === EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED && (
        <VoiceContainer
          purchaseArgs={configuration.purchaseArgs}
          index={index}
        />
      )}
    </Grid>
  </Grid>
);
