import React from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CreditCheckStatus } from "../../../../api/v1/account";
import { CreditVetIndicator } from "./CreditVetIndicator";
import { StatusHelperIcon } from "./StatusHelperIcon";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    background: theme.palette.grey[50],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(2.5), // this extra 1 spacing value is to offset something that has been set on the main page wrapper. TODO figure out what that is!
  },
}));

interface CreditVetStatusProps {
  hasError: boolean;
  fetching: boolean;
  status: CreditCheckStatus | undefined;
  handleRefreshClick: () => void;
}

export const CreditVetStatus = ({
  hasError,
  fetching,
  status,
  handleRefreshClick,
}: CreditVetStatusProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root} data-cy="CreditVetStatus">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding={0.5}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2">
            Credit check result:{" "}
            {fetching ? (
              <CircularProgress size={12} />
            ) : hasError ? (
              "Error"
            ) : (
              <CreditVetIndicator status={status} />
            )}
          </Typography>
          <StatusHelperIcon hasError={hasError} creditCheckStatus={status} />
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            aria-label="refresh"
            color="primary"
            onClick={handleRefreshClick}
          >
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};
