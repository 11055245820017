import React from "react";

import { Typography, Box } from "@mui/material";

type Props = {
  filterMenu: React.ReactNode;
  children: React.ReactNode;
};
export const Board = ({ filterMenu, children }: Props) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginY={1}
      >
        <Typography variant="h2">Orders Status</Typography>
      </Box>
      <Box marginTop={2} marginBottom={1}>
        {filterMenu}
      </Box>
      {children}
    </>
  );
};
