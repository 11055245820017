import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { ProductType } from "../../../../../types";
import { AccountSettings, useAuth } from "@akj-dev/affinity-platform";
import { productTypes as allProductTypes } from "../../../../../types";

type Props = {
  onSelect: (selected: ProductType) => void;
  id: string;
  value?: ProductType | null;
  [x: string]: any;
};

export const ProductTypeSelect = ({ onSelect, id, value, ...rest }: Props) => {
  const { accountSettings } = useAuth();
  const productTypes =
    accountSettings.can_access_vf_direct === "1"
      ? productTypesWithConditions(accountSettings)
      : allProductTypes;
  return (
    <TextField
      label="Product Type"
      select
      fullWidth
      variant="outlined"
      size="small"
      id={id}
      disabled={productTypes.length <= 1}
      value={productTypes.length === 1 ? productTypes[0] : value || ""}
      onChange={(event) => {
        const value = event.target.value as ProductType;
        onSelect(value);
      }}
      {...rest}
    >
      {productTypes.slice().map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </TextField>
  );
};

// All product types to be filtered based on account tag settings
// https://auroratarget.tpondemand.com/entity/46529-align-product-type-filter-when-searching
export const productTypesWithConditions = (
  accountSettings: AccountSettings
) => {
  const productTypes = [
    { name: "Broadband", show: accountSettings?.as_wlrbb_order_enabled },
    { name: "Fixed Line", show: accountSettings?.as_ethernet_order_enabled },
    { name: "Mobile", show: accountSettings?.as_mobile_order_enabled },
  ]
    .filter((type) => type.show === "1")
    .map((type) => type.name);

  if (productTypes.length > 1) {
    productTypes.push("Mixed");
  }

  return productTypes.sort();
};
