import React from "react";
import { StatusChip } from "@akj-dev/shared-components";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { Section } from "../../../../shared/components/Section";
import { Spinner } from "../../../../shared/components/Spinner";

import { DocumentChip } from "./DocumentChip";

interface DownloadDocumentsProps {
  documents: { id: string; filename: string }[];
  isFetchingDocuments: boolean;
  productId: string | number;
}

export const DownloadDocuments = ({
  documents,
  isFetchingDocuments,
  productId,
}: DownloadDocumentsProps) => (
  <Section
    title="Download Documents"
    avatar={isFetchingDocuments ? <Spinner /> : <InsertDriveFileIcon />}
  >
    {documents && (
      <>
        {documents.length ? (
          documents.map((doc) => {
            if (doc)
              return (
                <DocumentChip
                  key={doc.id}
                  filename={doc.filename}
                  href={`/cgi-bin/index.cgi/ExternalServices/v1/Product/DownloadDocument?product_id=${productId}&document_id=${doc.id}`}
                />
              );
            return false;
          })
        ) : (
          <StatusChip
            type="info"
            message="No documents available for this product."
          />
        )}
      </>
    )}
  </Section>
);
