import React from "react";
import { get } from "lodash";
import { ConfigurationStatus } from "../../../components/wlrBroadband/ConfigurationStatus";
import { Spinner } from "../../../../../shared/components/Spinner";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import { StatusChip } from "@akj-dev/shared-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableActionsCell } from "../../../../../shared/components/TableActionsCell";
import { ResignTypeChip } from "../../../components/wlrBroadband/ResignTypeChip";
import { Edit, Delete } from "@mui/icons-material";
import { SAME_PRODUCT } from "../../../../../store/wlrBroadband/constants";
import {
  getIsConfigValid,
  getOverallBroadbandPriceForResign,
  getProductInstanceForConfiguration,
  getTotalPrice,
} from "../../../../../store/wlrBroadband/selectors";
import { useDispatch, useSelector } from "react-redux";
import { ConfigStatusExpanded } from "../../../../../shared/components/ConfigStatusExpanded";
import { formatCurrency } from "../../../../../shared/utils/formatCurrency";
import { CommissionRadio } from "../../../../../shared/components/CommissionRadio";
import { setProductProperty } from "../../../../../store/wlrBroadband/actions/configurations";

interface ResignConfigTableRowProps {
  index: number;
  configuration: any;
  checked?: boolean;
  showCommissionType: boolean;
  toggleAction?: () => void;
  editAction?: () => void;
  deleteAction?: () => void;
  retryAction?: () => void;
}

export const ResignConfigTableRow = ({
  index,
  configuration,
  checked,
  showCommissionType,
  toggleAction,
  editAction,
  deleteAction,
  retryAction,
}: ResignConfigTableRowProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productInstance = useSelector((state) =>
    getProductInstanceForConfiguration(state, index)
  );
  const isOneMonthContract =
    useSelector((state: any) => state.wlrBroadband.contractLength) === 1;

  const resignProductType = configuration.resignProductType;

  const overallBroadbandPrice = getOverallBroadbandPriceForResign(
    configuration,
    // @ts-ignore
    true
  );

  const onCommissionSelect = () =>
    dispatch(
      setProductProperty(
        [index],
        null,
        "commission_type",
        configuration.commission_type === "Residual" ? "Upfront" : "Residual"
      )
    );

  if (resignProductType === SAME_PRODUCT)
    return (
      <TableRow key={index}>
        {toggleAction && <TableCell />}
        <TableCell>{configuration.pin}</TableCell>
        <TableCell />
        <TableCell align="left">
          {productInstance.name_for_resign || productInstance.name}
        </TableCell>
        <TableCell align="center">
          &pound;{getTotalPrice(productInstance.evo_services)}
        </TableCell>
        <TableCell align="center">
          <ResignTypeChip resignProductType={configuration.resignProductType} />
        </TableCell>
        {showCommissionType && (
          <TableCell align="center">
            <CommissionRadio
              disabled={isOneMonthContract}
              commissionType={configuration.commission_type}
              onCommissionSelect={onCommissionSelect}
            />
          </TableCell>
        )}

        <TableCell align="center">
          <ConfigStatusExpanded isValid={true} />
        </TableCell>
        <TableActionsCell align="right">
          <Button
            className={classes.deleteButton}
            onClick={deleteAction}
            variant="contained"
            color="primary"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </TableActionsCell>
      </TableRow>
    );

  if (!configuration.wlrProductData && !configuration.broadbandProductData)
    return null;

  const fetching =
    get(configuration.broadbandProductData, "fetching") ||
    get(configuration.broadbandValidation, "fetching") ||
    get(configuration.wlrProductData, "fetching");

  if (
    !fetching &&
    (get(configuration.wlrProductData, "response.status") === "error" ||
      get(configuration.broadbandProductData, "response.status") === "error")
  )
    return (
      <TableRow key={index}>
        <TableCell colSpan={8}>
          <StatusChip
            type="error"
            title="Error fetching product data."
            retry={retryAction}
          />
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow key={index}>
      {toggleAction && (
        <TableCell>
          <Checkbox
            color="secondary"
            checked={checked}
            onChange={toggleAction}
          />
        </TableCell>
      )}
      <TableCell>{configuration.pin}</TableCell>
      <TableCell />
      <TableCell align="left">
        {fetching ? (
          <Spinner size={30} />
        ) : (
          <>
            <div>
              {get(configuration.wlrProductData, "response") &&
                get(configuration.wlrProductData, "response.product_name")}
            </div>
            <div>
              {get(configuration.broadbandProductData, "response") &&
                get(
                  configuration.broadbandProductData,
                  "response.product_name"
                )}
            </div>
          </>
        )}
      </TableCell>
      <TableCell align="center">
        {formatCurrency(
          parseFloat(
            get(
              configuration.wlrProductData,
              "response.overall_price.first_bill_recurring_price_with_promotions",
              0
            )
          ) + overallBroadbandPrice
        )}
      </TableCell>
      <TableCell align="center">
        <ResignTypeChip resignProductType={configuration.resignProductType} />
      </TableCell>
      {showCommissionType && (
        <TableCell align="center">
          <CommissionRadio
            disabled={isOneMonthContract}
            commissionType={configuration.commission_type}
            onCommissionSelect={onCommissionSelect}
          />
        </TableCell>
      )}
      <TableCell align="center">
        {get(configuration.broadbandValidation, "fetching") ? (
          <Spinner size={30} />
        ) : (
          <ConfigurationStatus
            configuration={configuration}
            onClick={fetching ? () => false : editAction}
            isConfigValid={getIsConfigValid(configuration)}
          />
        )}
      </TableCell>
      {editAction && (
        <TableActionsCell align="right">
          <Button
            onClick={editAction}
            disabled={fetching}
            data-cy="wlrBroadbandEditResignWChangeConfig"
            variant="contained"
            startIcon={<Edit />}
          >
            Edit
          </Button>
          <Button
            className={classes.deleteButton}
            onClick={deleteAction}
            disabled={fetching}
            variant="contained"
            color="primary"
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </TableActionsCell>
      )}
    </TableRow>
  );
};

const useStyles = makeStyles({
  deleteButton: {
    marginLeft: 5,
    background: "#cc3324",
    "&:hover": {
      backgroundColor: "#bb2e1e",
    },
  },
});
