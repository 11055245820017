/**
 * Fire an optional callback when the order completes, if it's declared on the `window` object.
 * eg.
 * window.orderCompleteCallback = () => console.log('do things');
 *
 * This is used by Affinity Classic currently.
 */
export function doOrderCompleteCallback() {
  if (typeof window.orderCompleteCallback === "function") {
    window.orderCompleteCallback();
  }
}
