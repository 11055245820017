import React, { ChangeEvent } from "react";

import Checkbox from "@mui/material/Checkbox";
import { StatusChip } from "@akj-dev/shared-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { Popover } from "../../../../shared/components/Popover";
import { EthernetCard } from "../../../../shared/components/Ethernet/Card";
import { PricingRequestStatus } from "../../components/PricingRequestStatus";
import { EthernetOrderFormContainer } from "../../containers/Ethernet/EthernetOrderForm";
import { CompletedOrderInfo } from "../../../../shared/components/CompletedOrderInfo";
import { SortableTable } from "../../../../shared/components/SortableTable";

interface EthernetConfigurationProps {
  configuration: any;
  configIndex: number;
  amortised: boolean;
  isEFM: boolean;
  getRecurringPrice: (data: any) => number;
  onAmortizeChange: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onSelectEthernet: (data: any) => void;
}

export const EthernetConfiguration = ({
  configIndex,
  configuration,
  amortised,
  isEFM,
  getRecurringPrice,
  onAmortizeChange,
  onSelectEthernet,
}: EthernetConfigurationProps) => (
  <EthernetCard configuration={configuration}>
    {configuration.purchaseResponse.order_id ? (
      <CompletedOrderInfo orderId={configuration.purchaseResponse.order_id} />
    ) : (
      <span>
        <div style={{ textAlign: "right" }}>
          <FormControlLabel
            label="Amortise installation costs?"
            labelPlacement="start"
            control={
              <Checkbox
                color="secondary"
                onChange={onAmortizeChange}
                value={amortised}
              />
            }
          />
        </div>
        {configuration.selectedQuoteId ? (
          <EthernetOrderFormContainer
            configuration={configuration}
            index={configIndex}
          />
        ) : (
          <span>
            {configuration.pricingResults.length > 0 && (
              <SortableTable
                columns={[
                  {
                    header: "",
                    accessor: "",
                  },
                  {
                    header: "Supplier",
                    accessor: "supplier",
                  },
                  {
                    header: "Technology",
                    accessor: "technology",
                  },
                  ...(isEFM
                    ? [
                        {
                          header: "Min. Speed",
                          accessor: "minimum_speed",
                        },
                        {
                          header: "Max. Speed",
                          accessor: "maximum_speed",
                        },
                      ]
                    : []),
                  {
                    header: "Term (Months)",
                    accessor: "contract_length_in_months",
                  },
                  {
                    header: "Router Name",
                    accessor: "router_name",
                  },
                  {
                    header: "One Off",
                    accessor: "one_off_price",
                  },
                  {
                    header: "Monthly",
                    accessor: "recurring_price",
                  },
                ]}
                data={configuration.pricingResults}
                defaultSortColumn="supplier"
                renderRow={(data, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Checkbox
                        color="secondary"
                        onChange={() => onSelectEthernet(data)}
                        data-cy="SelectEthernetConfig"
                      />
                    </TableCell>
                    <TableCell>
                      {data.supplier}
                      {data.provider_messages.length > 0 && (
                        <Popover title="Additional Information">
                          {data.provider_messages.map((m: string) => (
                            <p key={m}>{m}</p>
                          ))}
                        </Popover>
                      )}
                    </TableCell>
                    <TableCell>{data.technology}</TableCell>
                    {isEFM && (
                      <>
                        <TableCell>{data.minimum_speed}</TableCell>
                        <TableCell>{data.maximum_speed}</TableCell>
                      </>
                    )}
                    <TableCell>{data.contract_length_in_months}</TableCell>
                    <TableCell>{data.router_name}</TableCell>
                    <TableCell>
                      &pound; {amortised ? "0.00" : data.one_off_price}
                    </TableCell>
                    <TableCell>
                      &pound;{getRecurringPrice(data).toFixed(2)}
                    </TableCell>
                  </TableRow>
                )}
              />
            )}
            <PricingRequestStatus configuration={configuration} />
            {!(configuration.site_a_uprn || configuration.site_b_uprn) && (
              <StatusChip
                type="error"
                title="Warning"
                message="A UPRN reference is required for accurate Virgin pricing."
              />
            )}
          </span>
        )}
      </span>
    )}
  </EthernetCard>
);
