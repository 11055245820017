import React from "react";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface PricingSchemeProps {
  availablePricingSchemes: string[];
  pricingSchemeDetails: Record<string, any>;
  pricingScheme: string;
  setPricingScheme: (scheme: string) => void;
}

export const PricingScheme = ({
  availablePricingSchemes,
  pricingScheme,
  pricingSchemeDetails,
  setPricingScheme,
}: PricingSchemeProps) => (
  <div>
    {availablePricingSchemes && (
      <FormControl fullWidth margin="normal">
        <InputLabel variant="standard">Pricing Scheme</InputLabel>
        <Select
          variant="standard"
          value={pricingScheme}
          onChange={(event) => setPricingScheme(event.target.value as string)}
        >
          {/* <MenuItem value={""} /> */}
          {/* <MenuItem value={""}>Select a price scheme</MenuItem> */}
          {availablePricingSchemes.map((scheme) => (
            <MenuItem value={scheme} key={scheme}>
              {pricingSchemeDetails[scheme].pricing_scheme_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </div>
);
