import React, { memo } from "react";

import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { formatCurrency } from "../../../../../../../shared/utils/formatCurrency";
import { QuantityField } from "../../../../../../../shared/components/QuantityField";
import { WlrLocationProduct } from "../../../../types";
import { WlrLocationNewLineBroadbandCell } from "../BroadbandCell";
import { MAX_QUANTITY_BROADBAND } from ".";

export interface WlrLocationNewLineTableRowProps {
  locationIndex: number;
  product: Pick<WlrLocationProduct, "id" | "name">;
  productConfigCount: number;
  oneOffPrice: number;
  hasReachedMaxConfigCount: boolean;
  isAddBroadbandDisabled: boolean;
  isMultiLineProduct: boolean;
  totalPrice: string;
  handleQuantity: () => void;
  toggleModal: () => void;
}

export const WlrLocationNewLineTableRow = memo(
  ({
    locationIndex,
    product,
    productConfigCount,
    oneOffPrice,
    hasReachedMaxConfigCount,
    isAddBroadbandDisabled,
    isMultiLineProduct,
    totalPrice,
    handleQuantity,
    toggleModal,
  }: WlrLocationNewLineTableRowProps) => {
    const classes = useStyles();

    return (
      <>
        <Box className={classes.product}>{product.name}</Box>
        <Box className={classes.rest}>{formatCurrency(oneOffPrice)}</Box>
        <Box className={classes.rest}>
          <QuantityField
            quantity={productConfigCount}
            hasReachedMaxConfigCount={hasReachedMaxConfigCount}
            maxQuantity={MAX_QUANTITY_BROADBAND}
            quantityThreshold={10}
            setQuantity={handleQuantity}
            dataCy="quantityField"
          />
        </Box>
        <Box className={classes.rest}>
          <WlrLocationNewLineBroadbandCell
            disabled={isAddBroadbandDisabled}
            locationIndex={locationIndex}
            handleClick={toggleModal}
            message={
              isMultiLineProduct
                ? "Broadband products can not be added to a multi-line."
                : false
            }
          />
        </Box>
        <Box className={classes.rest}>{`£${totalPrice}`}</Box>
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  product: {
    flexGrow: 1,
    minWidth: 200,
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
  },
  rest: {
    width: 250,
    minWidth: 250,
  },
}));
