import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSingleLineProducts } from "../../../../../store/wlrBroadband/selectors";
import {
  removeLocation,
  removeProductsForLocation,
  setLocationAddress,
  setLocationCli,
  setLocationType,
} from "../../../../../store/wlrBroadband/actions/locations";
import { ReduxWlrLocation } from "../../types";

import { WlrLocation } from "../../components/WlrLocation";
import { ChooseProductTypeContainer } from "./ChooseProductType";
import { WlrLocationTypeEnum } from "../../enums";

export interface WlrLocationProps {
  location: ReduxWlrLocation;
  locationIndex: number;
  open: boolean;
  handleLocationOpened: (i: number) => void;
}
/**
 * WLR & Broadband location
 * Chooses tye of install and sets address.
 * Wraps different product selection components depending on selection and run mode
 *
 * Product info:
 * https://miro.com/app/board/o9J_lRZqr3k=/
 *
 */
export const WlrLocationContainer = ({
  location,
  locationIndex,
  open,
  handleLocationOpened,
}: WlrLocationProps) => {
  // Redux State
  const contractLength: number = useSelector(
    (state: any) => state.wlrBroadband.contractLength
  );
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const lineSearchFetching = useSelector(
    (state: any) => state.wlrBroadband.lineSearch.fetching
  );
  const singleLineProducts = useSelector(getSingleLineProducts);

  const [openResign, setOpenResign] = useState(false);
  const shouldOpen =
    location.type === WlrLocationTypeEnum.WLR_RESIGN ? openResign : open;

  const handleResignOpened = useCallback(() => {
    if (location.type === WlrLocationTypeEnum.WLR_RESIGN)
      setOpenResign(!openResign);
  }, [setOpenResign, openResign, location]);

  // Redux Actions
  const dispatch = useDispatch();
  const handleLocationType = (locationType: string) =>
    dispatch(setLocationType(locationIndex, locationType));
  const handleLocationCli = (index: number, newCli: string) =>
    dispatch(setLocationCli(index, newCli));
  const saveAddress = (address: any) =>
    dispatch(setLocationAddress(locationIndex, address));
  const removeAllProducts = () =>
    dispatch(removeProductsForLocation(locationIndex));
  const onRemoveLocation = (index: number) => dispatch(removeLocation(index));

  return (
    <WlrLocation
      open={shouldOpen}
      withSingleLineProducts={singleLineProducts.length > 0}
      configurations={configurations}
      contractLength={contractLength}
      locationIndex={locationIndex}
      location={location}
      lineSearchFetching={lineSearchFetching}
      onRemoveLocation={onRemoveLocation}
      onRemoveAllProducts={removeAllProducts}
      handleLocationOpened={handleLocationOpened}
      handleResignOpened={handleResignOpened}
      handleLocationType={handleLocationType}
      handleLocationCli={handleLocationCli}
      saveAddress={saveAddress}
      productContainer={
        <ChooseProductTypeContainer
          locationIndex={locationIndex}
          locationType={location.type}
        />
      }
    />
  );
};
