import React from "react";
import styled from "styled-components";
import DateIcon from "@mui/icons-material/DateRange";
import { DC_DATE_FORMAT, isWorkingDay } from "../../../../shared/utils/date";
import { startOfMonth, addMonths, format } from "date-fns";
import { BrowserDateField } from "@akj-dev/shared-components";

const Outer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 20px;
`;

const CustomDateIcon = styled(DateIcon)`
  margin-right: 10px;
`;

interface ResignStartDateProps {
  resignStartDate: string;
  onDateChange: (date?: string | null) => void;
}

export const ResignStartDate = ({
  resignStartDate,
  onDateChange,
}: ResignStartDateProps) => {
  const startOfNextMonth = startOfMonth(addMonths(new Date(), 1));

  return (
    <Outer>
      <CustomDateIcon />
      <BrowserDateField
        value={resignStartDate || format(startOfNextMonth, DC_DATE_FORMAT)}
        label="Resign Start Date"
        disabled={!isWorkingDay(new Date())}
        fullWidth={true}
        onChange={(date: string) => onDateChange(date)}
        minDate={format(startOfNextMonth, DC_DATE_FORMAT)}
      />
    </Outer>
  );
};
