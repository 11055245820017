import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";

import { addWeekdays } from "../../../../../../shared/utils/date";
import { updateEthernetCustomerData } from "../../../../../../store/ethernetProducts/actions";
import { ethernetPurchaseArgsValidationErrors } from "../../../../../../store/ethernetProducts/selectors";
import { CustomerRequiredDate } from "../../../../components/Ethernet/CustomerRequiredDate";

import { DC_DATE_FORMAT } from "../../../../../../shared/utils/date";

interface CustomerRequiredDateContainerProps {
  index: number;
}

export const CustomerRequiredDateContainer = ({
  index,
}: CustomerRequiredDateContainerProps) => {
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();

  const config = useSelector(
    (state: any) => state.ethernetProducts.configurations[index]
  );
  const errors = useSelector((state) =>
    ethernetPurchaseArgsValidationErrors(state, index)
  );
  const showErrors = useSelector<any, boolean>((state) =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const handleChangeDate = (date?: string | null) => {
    dispatch(
      updateEthernetCustomerData(
        index,
        "customer_required_date",
        format(new Date(date as string), DC_DATE_FORMAT)
      )
    );
  };

  const hasError = errors.includes("customer_required_date");
  const forceExpanded = showErrors && hasError;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  const minDate = addWeekdays(new Date(), 45, false); // Note: This default value is populated in the reducer too.

  return (
    <CustomerRequiredDate
      isExpanded={forceExpanded || expanded}
      isError={showErrors && hasError}
      customerRequiredDate={config.purchaseArgs.customer_required_date}
      minDate={minDate}
      handleHeaderClick={handleHeaderClick}
      handleChangeDate={handleChangeDate}
    />
  );
};
