import React from "react";
import { Section } from "../../../../shared/components/Section";
import BuildIcon from "@mui/icons-material/Build";
import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";

interface UserDefinedFieldsProps {
  dynamicProperties?: Record<string, { name: string; label: string }>;
}

export const UserDefinedFields = ({
  dynamicProperties = {},
}: UserDefinedFieldsProps) => (
  <Section
    title="User-Defined Fields"
    avatar={<BuildIcon />}
    defaultExpanded={false}
  >
    {Object.values(dynamicProperties)
      .filter((dp) => dp.name.includes("user_defined_field_"))
      .map((dp) => (
        <ArrayDynamicField
          key={dp.name}
          propertyName={dp.name}
          floatingLabelText={dp.label}
          isRootProperty={true}
        />
      ))}
  </Section>
);
