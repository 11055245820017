import { useAccountSettings } from "./useAccountSettings";
import { useSelector } from "react-redux";
import {
  getConfigsWithMonthlyAirtimeCredit,
  getConfigsWithOneOffAirtimeCredit,
} from "../../store/mobile/selectors/productConfig";
import { useMemo } from "react";
import { DisplayPrefs } from "../../pages/StepCustomise/components/Mobile/MobileConfigTable";

export const useMobileConfigTableDisplayPrefs = (
  showActions: boolean
): DisplayPrefs => {
  const accountSettings = useAccountSettings();
  /**
   * Table display preferences - feature toggles etc
   */
  const showMonthlyAirtimeCredit = useSelector(
    (state: any) => getConfigsWithMonthlyAirtimeCredit(state).length > 0
  );
  const showOneOffAirtimeCredit = useSelector(
    (state: any) => getConfigsWithOneOffAirtimeCredit(state).length > 0
  );
  const showVFDiscounts = accountSettings.can_access_vf_direct === "1";
  const showCommissionTypeSelect =
    accountSettings.as_commission_type_selection_for_mobile === "1";
  return useMemo(
    () => ({
      showActions,
      showMonthlyAirtimeCredit,
      showOneOffAirtimeCredit,
      showVFDiscounts,
      showCommissionTypeSelect,
    }),
    [
      showActions,
      showMonthlyAirtimeCredit,
      showOneOffAirtimeCredit,
      showVFDiscounts,
      showCommissionTypeSelect,
    ]
  );
};
