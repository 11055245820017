import React from "react";
import { useForm, FormProvider, UseFormSetValue } from "react-hook-form";

import { Grid, MenuItem } from "@mui/material";

import {
  RHFTextField,
  RHFSelectField,
  RHFPostcodeLookup,
  Alert,
  LoaderButton,
} from "@akj-dev/shared-components";

import { ContactType, DCContact } from "../../../api/v1/account";
import { WLR3Address, WLR3AddressSearch } from "../../../api/v1/addressSearch";

import { convertWLR3toDCContactAddress } from "./helpers";
import { RHFCountryField } from "./RHFCountryField";

interface CreateContactProps {
  onSubmit: (values: typeof defaultValues) => Promise<any>;
  types: ContactType[];
}

const defaultValues: Partial<DCContact> = {
  authority: "",
  title: "",
  first_name: "",
  last_name: "",
  primary_email_address: "",
  mobile_phone_number: "",

  primary_address_building_number: "",
  primary_address_building: "",
  primary_address_street: "",
  primary_address_city: "",
  primary_address_state: "",
  primary_address_postalcode: "",
  primary_address_country: "United Kingdom",
};

export const CreateContactForm = ({ types, onSubmit }: CreateContactProps) => {
  const methods = useForm({
    defaultValues,
  });

  const authority = methods.watch("authority");
  const addressRequired = authority === "Delivery Contact";

  const onSelectPostcode = (
    wlr3Address: WLR3Address,
    setValue: UseFormSetValue<any>
  ) => {
    const dcAddress = convertWLR3toDCContactAddress(wlr3Address);
    Object.entries(dcAddress).forEach(([key, value]) => {
      setValue(key, value || "");
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} id="CreateContactForm">
        <Grid container spacing={2}>
          <Grid item sm={3}>
            <RHFSelectField name="title" label="Title">
              <MenuItem value="Dr.">Dr.</MenuItem>
              <MenuItem value="Miss">Miss</MenuItem>
              <MenuItem value="Mr.">Mr.</MenuItem>
              <MenuItem value="Mrs.">Mrs.</MenuItem>
              <MenuItem value="Ms.">Ms.</MenuItem>
              <MenuItem value="Prof.">Prof.</MenuItem>
            </RHFSelectField>
          </Grid>
          <Grid item sm={4}>
            <RHFTextField
              name="first_name"
              label="First Name"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item sm={5}>
            <RHFTextField
              name="last_name"
              label="Last Name"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item sm={6}>
            <RHFTextField
              name="primary_email_address"
              label="Email"
              type="email"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item sm={6}>
            <RHFTextField name="mobile_phone_number" label="Mobile" />
          </Grid>
          <Grid item sm={12}>
            <RHFSelectField
              name="authority"
              label="Contact Type"
              rules={{ required: true }}
            >
              {types.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </RHFSelectField>
          </Grid>
          <Grid item lg={4}>
            <h4 style={{ margin: 0 }}>Primary address</h4>
          </Grid>
          {/*
          Note: These address fields are very similar to CreateAccountForm/sections/AddressFields.tsx but annoyingly not
          the same. One has locale+region, one has city+state hence we can't re-use.
          We should really have a common address standard
          */}
          <Grid item lg={8}>
            <Grid container spacing={1}>
              <Grid item sm={12}>
                <RHFPostcodeLookup
                  name="primary_address_postalcode"
                  api={WLR3AddressSearch}
                  onSelect={onSelectPostcode}
                  required={addressRequired}
                />
              </Grid>
              <Grid item sm={12}>
                <RHFTextField
                  name="primary_address_building_number"
                  label="Building Number"
                  rules={
                    addressRequired
                      ? {
                          validate: (val: string) => {
                            if (
                              !val &&
                              !methods.getValues("primary_address_building")
                            ) {
                              return "Street number or building name required";
                            }
                            if (val && isNaN(Number(val))) {
                              return "Street number must be numeric";
                            }
                            return true;
                          },
                          deps: ["primary_address_building"],
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item sm={12}>
                <RHFTextField
                  name="primary_address_building"
                  label="Building"
                  rules={
                    addressRequired
                      ? {
                          validate: (val: string) =>
                            !val &&
                            !methods.getValues(
                              "primary_address_building_number"
                            )
                              ? "Street number or building name required"
                              : true,
                          deps: ["primary_address_building_number"],
                        }
                      : {}
                  }
                />
              </Grid>
              <Grid item sm={12}>
                <RHFTextField
                  name="primary_address_street"
                  label="Street"
                  rules={{ required: addressRequired }}
                />
              </Grid>
              <Grid item sm={12}>
                <RHFTextField
                  name="primary_address_city"
                  label="City"
                  rules={{ required: addressRequired }}
                />
              </Grid>
              <Grid item sm={12}>
                <RHFTextField
                  name="primary_address_state"
                  label="County"
                  rules={{ required: addressRequired }}
                />
              </Grid>
              <Grid item sm={12}>
                <RHFCountryField
                  name="primary_address_country"
                  rules={{ required: addressRequired }}
                  // Country field should be locked to the UK
                  // https://auroratarget.tpondemand.com/entity/64781-when-adding-new-contact-only-uk
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
          {Object.keys(methods.formState.errors).length > 0 && (
            <Grid item sm={12}>
              <Alert
                type="error"
                message="Please correct the errors above to continue"
              />
            </Grid>
          )}
          <Grid item sm={12}>
            <LoaderButton
              type="submit"
              variant="contained"
              color="primary"
              loading={methods.formState.isSubmitting}
            >
              Create Contact
            </LoaderButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
