import React from "react";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { getDynamicPropertyValue } from "../../../../store/wlrBroadband/selectors";
import { useSelector } from "react-redux";

export const ONTContainer = ({ targetConfigs }: any) => {
  const ontType = useSelector((state) =>
    getDynamicPropertyValue(state, "broadband", targetConfigs[0], "bb.ONTType")
  );

  return (
    <Section
      title="Optical Network Terminal"
      avatar={<ElectricalServicesIcon />}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.ONTType"
        margin
      />
      {ontType === "EXISTING" && (
        <>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.ontReference"
            margin
            disabled
          />
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.ontPortNumber"
            margin
            required
          />
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.ontPortStatus"
            margin
            required
          />
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.ontPortType"
            margin
            required
          />
        </>
      )}
    </Section>
  );
};
