import React, { ChangeEvent } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";

export interface AddressOptionsProps {
  addressType: addressTypes | false;
  handleChangeAddressType: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AddressOptions = ({
  addressType,
  handleChangeAddressType,
}: AddressOptionsProps) => {
  return (
    <>
      <FormControl variant="standard">
        <RadioGroup
          aria-label="address preference"
          name="address_preference"
          value={addressType}
          onChange={handleChangeAddressType}
        >
          <Box display="flex" textAlign="left">
            <FormControlLabel
              control={<Radio />}
              value={addressTypes.BILLING}
              label="Use billing address"
            />
            <FormControlLabel
              control={<Radio />}
              value={addressTypes.EXISTING}
              label="Choose from contacts"
            />
          </Box>
        </RadioGroup>
      </FormControl>
    </>
  );
};
