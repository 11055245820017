import React, { useRef, Dispatch, SetStateAction } from "react";

import { ArrowForward } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { AccountSettings } from "@akj-dev/affinity-platform";
import {
  CreateAccountFormValues,
  IndexedTitle,
  CreateAccountForm,
} from "@akj-dev/shared-components";

import { TitleAndInfo } from "../../../shared/containers/TitleAndInfo";
import { WLR3AddressSearch } from "../../../api/v1/addressSearch";
import { RequestStateContainer } from "../containers/RequestState";
import { AutoPopulateContactContainer } from "../containers/AutoPopulateContact";

interface AccountSectionProps {
  accountSettings: AccountSettings;
  isFetching: boolean;
  defaultValues: CreateAccountFormValues;
  setDefaultValues: Dispatch<SetStateAction<CreateAccountFormValues>>;
  handleSubmit: (params: any) => void;
}

export const AccountSection = ({
  accountSettings,
  isFetching,
  defaultValues,
  setDefaultValues,
  handleSubmit,
}: AccountSectionProps) => {
  const classes = useStyles();
  const formRef = useRef<any>(null);

  return (
    <Box>
      <TitleAndInfo title="Create Account" />
      <Box my={1}>
        <IndexedTitle title="Please enter new account details" index="1" />
      </Box>
      <AutoPopulateContactContainer setDefaultValues={setDefaultValues} />
      <CreateAccountForm
        onSubmit={handleSubmit}
        accountSettings={accountSettings as AccountSettings}
        api={WLR3AddressSearch}
        defaultValues={defaultValues}
        ref={formRef}
      />
      <Box mb={1}>
        <RequestStateContainer />
      </Box>
      <div className={classes.submitBtnContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          endIcon={<ArrowForward />}
          onClick={() => {
            formRef.current.dispatchEvent(
              new Event("submit", { bubbles: true, cancelable: true })
            );
          }}
          disabled={isFetching}
          data-cy="StepNavFooterNext"
        >
          Create Account
        </Button>
      </div>
    </Box>
  );
};

const useStyles = makeStyles({
  submitBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: -66,
  },
});
