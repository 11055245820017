import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InputIcon from "@mui/icons-material/Input";
import { useTheme } from "@mui/material/styles";
import { TypeOfConnection } from "../../types/MobileProductConfig";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

interface ConnectionTypeProps {
  type: TypeOfConnection;
}

export const ConnectionType = ({ type }: ConnectionTypeProps) => {
  const theme = useTheme();
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();

  switch (type) {
    case TypeOfConnection.RESIGN_WITH_CHANGES:
      return (
        <Outer>
          <SettingsBackupRestoreIcon htmlColor={theme.palette.success.main} />
          {hostEnv === "platform" && !canAccessVfDirect
            ? "Resign"
            : "Resign with changes"}
        </Outer>
      );
    case TypeOfConnection.RESIGN_WITHOUT_CHANGES:
      return (
        <Outer>
          <AutorenewIcon htmlColor={theme.palette.info.main} />
          Resign without changes
        </Outer>
      );
    case TypeOfConnection.NEW: // Dynamic property value
      return (
        <Outer>
          <AddCircleOutlineIcon htmlColor={theme.palette.primary.main} />
          New
        </Outer>
      );
    case TypeOfConnection.PORT_MIG: // Dynamic property value
      return (
        <Outer>
          <InputIcon htmlColor={theme.palette.primary.main} />
          Port / migration
        </Outer>
      );
    default:
      return (
        <Outer>
          Unknown acquisition method: <b>{type}</b>
        </Outer>
      );
  }
};

ConnectionType.propTypes = {
  type: PropTypes.string.isRequired,
};
