import { Section } from "../../../../shared/components/Section";
import React from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Alert } from "@akj-dev/shared-components";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { InputAdornment } from "@mui/material";

interface DiscountsProps {
  showSharedDiscount: boolean;
}
export const Discounts = ({ showSharedDiscount }: DiscountsProps) => {
  const sharedMsg =
    "Shared Discount - This will reduce the Line Rental cost for the customer and will reduce the Partner Advance paid.";
  const commissionMsg =
    "Commission Sacrifice - This will reduce the Line Rental cost for the customer and will reduce the Partner Commission paid.";
  return (
    <Section title="Discounts" avatar={<MonetizationOnIcon />}>
      <Alert
        type="info"
        message={
          showSharedDiscount ? (
            <ul>
              <li>{sharedMsg}</li>
              <li>{commissionMsg}</li>
            </ul>
          ) : (
            commissionMsg
          )
        }
      />
      {showSharedDiscount && (
        <BulkDynamicField
          propertyName="shared_discount"
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
        />
      )}
      <BulkDynamicField
        propertyName="commission_sacrifice"
        customSelectSortComparator={(a: string, b: string) =>
          parseInt(a) - parseInt(b)
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
    </Section>
  );
};
