import { getJSON, postJSON } from "../helpers/core";

/**
 * Get available mobile products
 */
export const MobileSearch = (
  account: string,
  is_dynamic = 1,
  lead_id?: string
) =>
  postJSON("Product/MobileSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    limit: 1000,
    // mobile_is_data_only_voice: 0,
    ...(lead_id && { lead_id: lead_id }),
  });

/**
 * Get available account level bolt-ons
 */
export const AccountBoltOnSearch = (account: string, is_dynamic = 1) =>
  postJSON("Product/AccountBoltOnSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    only_mobile_bolt_ons: 1,
  });

// its not practical to type the entire bolton search API response, but this partial type is useful for some consumers
export type VFDirectBoltOn = {
  id: string;
  name: string;
  price: {
    first_bill_recurring_price_with_promotions: string;
  };
  first_mobile_bolt_on_component: {
    bolt_on_type: string;
    soc_code: string;
  };
};

/**
 * Get available bolt on products
 * Used in step 2 for CLI level ones per product
 */
export const MobileBoltOnSearch = (
  mobile_bolt_on_level: string,
  mobile_voice_product_id: string,
  account: string,
  isTopUpBoltOn: boolean,
  is_dynamic = 1,
  category_id: string,
  minimum_contract_length_in_months: number | undefined
) =>
  postJSON<MobileBoltonResponse>("Product/MobileBoltOnSearch", {
    account,
    guided_sales: 1,
    is_dynamic,
    only_mobile_bolt_ons: 1,
    mobile_bolt_on_level,
    mobile_voice_product_id,
    ...(isTopUpBoltOn && { mobile_bolt_on_bolt_on_subtype: "top_up" }),
    category_id,
    minimum_contract_length_in_months,
  });

export type MobileBoltonResponse = {
  original_query_params: any;
  products: any[];
  status: string;
  total: string;
};
/**
 * Get existing product instances on account for resigns.
 * TODO: Figure out exactly which params are necessary
 *
 * @param productType - `Mobile`, `Line` or `Broadband`
 * @param account - Daisy account number
 * @param {number} page - pagination
 * @param params - Custom params
 * @returns {Promise<*>}
 * @constructor
 */

export const ProductInstance = (
  productType: string,
  account: string,
  page: any,
  params: any
) =>
  getJSON<ProductInstanceResponse>(`Product/Instance/${productType}`, {
    account,
    direction: "asc",
    limit: 20,
    offset: 0,
    page,
    hide_terminated: 1,
    single_account_only: true,
    ...params,
  });

export type ProductInstanceResponse = {
  success: number;
  results: ResultsEntity[];
  pagination: Pagination;
  status: string;
};

export interface ResultsEntity {
  id: string;
  // TODO: Are all these string | null declarations correct or could some props not exist entirely?
  order_id: string | null;

  // TODO: Why is there also a separate address object?
  css_database_code: string | null;
  premises_number: string | null;
  premises_name: string | null;
  post_town: string | null;
  post_code: string | null;
  country: string | null;
  thoroughfare: string | null;
  address_reference: string | null;
  installation_site_name: string | null;
  address_full: string | null;

  address: Address;
  end_date_ymd: string;
  start_date_ymd: string;
  family: string;
  cost_centre_1: string | null;
  cost_centre_2: string | null;
  cost_centre_3: string | null;
  sim_serial: string;
  service_end_date_ymd: string;
  contract_length_in_months: string;
  status: string;
  resign_status: string;
  account_id: string;
  description: string;
  pin: string;
  name_for_resign: string;
  billing_system_alternate_id: string;
  name: string;
  contact_name: string | null;
  user_name: string;
  cust_reference: string | null;
  network: Network;
  third_party_billing_services?: ThirdPartyBillingServicesEntity[];
  evo_services?: EvoServicesEntity[];
  // TODO: Are there more possible props for different types of product instance?
}
export interface Address {
  // TODO: This address format must be reused in a lot of endpoints. Can we identify all of them and share type?
  installation_site_name: string | null;
  premises_number: string | null;
  premises_name: string | null;
  css_database_code: string | null;
  thoroughfare: string | null;
  address_reference: string | null;
  post_town: string | null;
  post_code: string | null;
}
export interface Network {
  name: string;
  id: string;
}
export interface ThirdPartyBillingServicesEntity {
  third_party_billing_rate: string;
  category: string;
  service_id: string | null;
  id: string;
  enddate: string;
  startdate: string;
  service_cost_id: string | null;
  third_party_billing_reference: string;
  soc_code: string;
  status_display_order: number;
  is_deleted: string;
  has_ended: number;
  nominal_code: string | null;
  last_update: string;
  description: string;
  is_live: number;
  product_instance_id: string;
  next_month_price_ignoring_end_dates: string;
  supplier: string;
  is_termination_fee: string | null;
  third_party_billing_supplier_id: string;
  reference: string;
  modified_by: string;
  service_class: string;
  service_type: string;
}

export interface EvoServicesEntity {
  AdditionalInformation: string | null;
  Description: string;
  IsData: number;
  BundleInformation?: BundleInformation | null;
  Category: string;
  ServiceId: number;
  primary_key: string;
  SPID: string;
  UnitsUsedLastMonth: number;
  IsTerminationFee: string | null;
  pk: number;
  StartDate: string;
  Id: number;
  PaymentPeriod: string;
  ServiceTariffDescription?: string | null;
  ServiceTariffId?: number | null;
  ServiceCategoryId: number;
  CreatedExternalSystemReferenceId: string | null;
  IsWorldWideCap: number;
  EndDatedExternalSystemReferenceId: string | null;
  UnitsUsedThisMonth: number;
  IsVoice: number;
  BoltOnType: string;
  BoltOnFullType: string;
  Quantity: number;
  BoltOnSubType: string;
  BundleApplicationLevel: string | null;
  ServiceCategoryDescription: string | null;
  IncludedChildrenCount: string | null;
  Active: number;
  IsFutureDated: number;
  OriginalEndDate: string | null;
  TerminationFeeDiscountPercentage: string | null;
  NetworkCode?: string | null;
  SuppressBundles: string | null;
  ServiceClassDescription: string | null;
  DoesNotProRateCost: string | null;
  ServiceGroupDescription: string | null;
  IsBoltOn: number;
  DoesNotProRateBundles: string | null;
  ServiceGroupId: number;
  Rate: number | string;
  RateSource: string;
  IsActive: number;
  ServiceClass: number;
  EndDate: string | null;
  ServiceType: string;
  RateBeforeDiscount: number;
  DisconnectionNoticeGivenDate: string | null;
  Network?: string | null;
  SecondaryOverrideRate: string | null;
  OneOff?: number | null;
}
export interface BundleInformation {
  ValidForAccountAssignment: string | null;
  Bundles?: BundlesEntity[] | null;
  HasMobileBundles: string | null;
  HasFixedLineBundles: string | null;
}
export interface BundlesEntity {
  SharingPolicy: number;
  Id: number;
  AppliesToday: string | null;
  InternalName: string;
  StartDate?: string | null;
  EndDate?: string | null;
}
export interface Pagination {
  page: string;
  sort: string;
  pages: number;
  direction: string;
  count: string;
  prev_page: number;
  next_page: number;
}

export interface AvailableContractLengths {
  status: string;
  terms: string[];
  message: string;
  code: string;
}

/**
 * Remove a special rate when resigning a product instance.
 *
 * @param params
 */

export const RemoveSpecialRate = (params: any) =>
  postJSON("Product/Instance/EndDateSpecial", params);

/**
 * Product Search
 *
 * Generic endpoint used to find:
 *  - The product used to do resigns without change.
 *  - Universal Products list
 *
 * @param params
 * @returns {Promise<*>}
 * @constructor
 */
export const search = (params: any) => getJSON("Product/Search", params);

/**
 * Universal Product Search
 *
 *  Find Universal Products list
 *
 * @param params
 * @returns {Promise<*>}
 * @constructor
 */
export const universalSearch = (params: any) =>
  getJSON("Product/UniversalSearch", params);

/**
 * Find the Daisy Fresh product
 * @param account
 * @param mobile_bolt_on_service_provider_id
 * @returns {Promise<*>}
 */
export const daisyFreshSearch = (
  account: string,
  mobile_bolt_on_service_provider_id: string
) =>
  getJSON("Product/DaisyFreshSearch", {
    account,
    mobile_bolt_on_service_provider_id,
  });

/**
 * Get the Hardware Credit product
 * (used for adding hardware credit to an account when Daisy Fresh is selected)
 * @param account
 * @returns {Promise<void>}
 */
export const hardwareCreditSearch = (account: string) =>
  getJSON("Product/HardwareCreditSearch", { account });

/**
 * Search for hardware products available to an account
 * @param account
 * @param pricing_scheme - a key from available_pricing_schemes array. ...currently all will be shown without this.
 * @param asBlankSimEnabled - TP15998
 * @returns {Promise<*>}
 */
export const hardwareSearch = (
  account: string,
  pricing_scheme = false,
  asBlankSimEnabled = "0",
  is_dynamic = 1
) =>
  getJSON("Product/HardwareSearch", {
    guided_sales: 1,
    is_dynamic,
    // 'hardware-is_leasing': 1,
    ...(asBlankSimEnabled === "0" && { hardware_is_sim: 0 }),
    basic: 1,
    limit: 1000, // TODO: Pagination
    account,
    ...(pricing_scheme && { pricing_scheme }),
  });

/**
 * Get documents attached to a product
 * PDFs, Excel and such. See FB155292
 * @param product_id
 * @returns {Promise<*>}
 */
export const listDocuments = (product_id: string) =>
  getJSON("Product/ListDocuments", { product_id });

/**
 * Get shipping methods available for stated account
 * @param {string} account
 */
export const shippingSearch = (account: string) =>
  getJSON(`Product/ShippingSearch`, { account });

/**
 * Get available contract lengths available by product type
 * @param {string} type
 * @returns
 */
export const availableContractLengths = (type: string) =>
  getJSON<AvailableContractLengths>(`Product/AvailableContractLengths/${type}`);
