import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";
import { Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { StatusChip } from "@akj-dev/shared-components";

import { Section } from "../../../../../../../shared/components/Section";
import { PacVerificationContainer } from "../PacVerification";
import { BulkDynamicField } from "../../BulkDynamicField";
import { ProductTitle } from "../../../../../../../shared/components/MobileProduct/ProductTitle";
import { ReservedNumbersContainer } from "./ReservedNumbers";
import { StacCodeContainer } from "./StacCode";

import {
  DC_DATE_FORMAT,
  isNotWorkingDay,
} from "../../../../../../../shared/utils/date";
import {
  getNonGenericInstanceName,
  getResignProductInstanceByConfig,
} from "../../../../../../../store/mobile/selectors/resigns";
import {
  getMinimumPortDate,
  getProductDataForConfig,
} from "../../../../../../../store/mobile/selectors/productConfig";
import { getLeadId } from "../../../../../../../store/order/selectors";
import { getAccountSettings } from "../../../../../../../store/account/selectors";

import {
  NEW_SIM,
  PRE_DISPATCHED_SIM,
  STAC,
} from "../../../../../../../store/mobile/constants";
import { useCanAccessVfDirect } from "../../../../../../../shared/hooks/useCanAccessVfDirect";
import { validateAllConfigProperties } from "../../../../../../../store/mobile/actions";

interface Props {
  targetConfigs: number[];
}

export const Connection = ({ targetConfigs }: Props) => {
  const canAccessVFDirect = useCanAccessVfDirect();
  const dispatch = useDispatch();

  const firstConfigProps = useSelector((state: any) => state.mobile.configs)[
    targetConfigs[0]
  ].properties;
  const {
    acquisition_method,
    pac_expiry_date,
    stac_expiry_date,
    sim_is_buffer,
    is_sim_required,
  } = firstConfigProps;

  const resignProductInstance = useSelector((state: any) =>
    getResignProductInstanceByConfig(state, targetConfigs[0])
  );
  const productDataForConfig = useSelector((state: any) =>
    getProductDataForConfig(state, targetConfigs[0])
  );
  const newNetwork =
    productDataForConfig?.mobile?.product_component_data?.supplier;
  const existingNetwork = resignProductInstance?.network?.name;
  const productName = productDataForConfig?.product_name;

  const leadId = useSelector(getLeadId);
  const settings = useSelector(getAccountSettings);
  const { as_can_set_friday_ports } = settings;

  const minPortDate = useMemo(() => {
    // If all selected configs have validated pre-dispatched SIM serials,
    // we can offer faster port times (FB129427).
    // Subsequently FB154639:
    if (sim_is_buffer === "1" || is_sim_required === "0") {
      return getMinimumPortDate(PRE_DISPATCHED_SIM, !canAccessVFDirect);
    }
    return getMinimumPortDate(NEW_SIM, !canAccessVFDirect);
  }, [sim_is_buffer, is_sim_required, canAccessVFDirect]);

  const resignProduct = (
    <div className="u-flex-center" style={{ marginBottom: 20 }}>
      <ProductTitle network={existingNetwork}>
        {getNonGenericInstanceName(resignProductInstance)}
      </ProductTitle>
      <ArrowForwardIcon />
      <ProductTitle network={newNetwork}>{productName}</ProductTitle>
    </div>
  );

  useEffect(() => {
    dispatch(validateAllConfigProperties(canAccessVFDirect));
  }, [acquisition_method, dispatch, canAccessVFDirect]);

  const acquisitionMethod = (
    <BulkDynamicField
      propertyName="acquisition_method"
      availableOptionsMap={{
        // Product data has extra options we don't want for some reason, so override here.
        "port/mig": "Port/Migration",
        new: "New",
      }}
    />
  );

  const shouldDisableDate = (dateString: string) => {
    const date = new Date(dateString);
    return (
      isNotWorkingDay(dateString) ||
      (as_can_set_friday_ports !== "1" && date.getDay() === 5)
    );
  };

  return (
    <Section title="Connection" avatar={<PhonelinkRingIcon />}>
      {(() => {
        switch (acquisition_method) {
          case "new":
            return (
              <>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    {acquisitionMethod}
                  </Grid>
                  <Grid item sm={6}>
                    <BulkDynamicField
                      propertyName="activation_date"
                      minDate={format(
                        stac_expiry_date
                          ? (getMinimumPortDate(
                              STAC,
                              !canAccessVFDirect
                            ) as Date)
                          : new Date(),
                        DC_DATE_FORMAT
                      )}
                      maxDate={stac_expiry_date as string}
                    />
                  </Grid>
                </Grid>
                <StacCodeContainer />
                {!leadId && !canAccessVFDirect && <ReservedNumbersContainer />}
              </>
            );
          case "port/mig":
            // TODO: maxDate triggers warnings as it's a string, but still works. Fix warning...
            return (
              <div>
                {acquisitionMethod}
                <PacVerificationContainer />
                <BulkDynamicField
                  propertyName="port_date"
                  minDate={format(minPortDate as Date, DC_DATE_FORMAT)}
                  maxDate={pac_expiry_date as string}
                  shouldDisableDate={shouldDisableDate}
                />
              </div>
            );
          case "resign":
            return (
              <div>
                {resignProduct}
                {
                  // We need a PAC if changing from or to Dise, but not any other network.
                  // Advised by @ianc
                  // TODO: Would this check be relevant though? Wouldn't any resign with network change come as internal_port_* ?
                  (existingNetwork === "Dise" || newNetwork === "Dise") &&
                    !(existingNetwork === "Dise" && newNetwork === "Dise") && (
                      <PacVerificationContainer />
                    )
                }
              </div>
            );
          case "internal_port_dise_to_o2":
          case "internal_port_dise_to_voda":
          case "internal_port_o2_to_dise":
          case "internal_port_o2_to_voda":
          case "internal_port_voda_to_dise":
          case "internal_port_voda_to_o2":
          case "internal_port":
            return (
              <div>
                {resignProduct}
                <StatusChip
                  type="info"
                  title="Internal Port"
                  message={
                    existingNetwork !== "Dise"
                      ? "A new SIM will be sent with this order."
                      : ""
                  }
                />
                {
                  // We need a PAC if changing from or to Dise, but not any other network.
                  // Advised by @ianc
                  // TODO: Does this actually need to be duplicated above? Is this situation always an internal port?
                  (existingNetwork === "Dise" || newNetwork === "Dise") &&
                    !(existingNetwork === "Dise" && newNetwork === "Dise") && (
                      <PacVerificationContainer />
                    )
                }
                <BulkDynamicField
                  propertyName="port_date"
                  minDate={format(minPortDate as Date, DC_DATE_FORMAT)}
                  maxDate={pac_expiry_date as string}
                  shouldDisableDate={isNotWorkingDay}
                />
              </div>
            );
          default:
            return (
              <StatusChip
                type="error"
                message={`Unknown acquisition method "${acquisition_method}". Please contact support.`}
              />
            );
        }
      })()}
    </Section>
  );
};
