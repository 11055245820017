import React, { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { TableBody } from "@mui/material";
import {
  MobileConfigGroupDisplayData,
  MobileConfigRowDisplayData,
  MultipleIndicators,
} from "../../../../../shared/types/MobileProductConfig";
import { MobileConfigTableRow } from "./MobileConfigTableRow";
import { DisplayPrefs } from "./index";
import { requestMobileProductData } from "../../../../../store/mobile/actions";

interface RowExpanderProps {
  displayPrefs: DisplayPrefs;
  productId: string;
  groupData: MobileConfigGroupDisplayData;
  onEditConfigs: (configIndexes: string[]) => void;
  onDeleteConfigs: (configIndexes: string[]) => void;
  selectedConfigs: string[];
  onSelectConfig: (configIndex: string) => void;
  onCommissionSelect: (configIndexes: string[]) => void;
}

export const RowExpander = ({
  displayPrefs,
  productId,
  groupData,
  onEditConfigs,
  onDeleteConfigs,
  selectedConfigs,
  onSelectConfig,
  onCommissionSelect,
}: RowExpanderProps) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const configs = Object.entries(groupData.configs);
  const isOneMonthContract = groupData.contractLength === 1;
  const multi = configs.length > 1;

  let multiples: MultipleIndicators = {};

  Object.keys(configs[0][1]).forEach((key) => {
    const values = configs.map(
      (c) => c[1][key as keyof MobileConfigRowDisplayData]
    );
    multiples[key as keyof MobileConfigRowDisplayData] = !values.every(
      (v) => v === values[0]
    );
  });

  const handleOnEdit = (target: string[]) => (e: MouseEvent) => {
    e.stopPropagation();
    onEditConfigs(target);
  };

  const handleOnDelete = (target: string[]) => (e: MouseEvent) => {
    e.stopPropagation();
    onDeleteConfigs(target);
  };

  const handleCommissionSelect = (target: string[]) => (e: MouseEvent) => {
    e.stopPropagation();
    onCommissionSelect(target);
  };

  const handleSelectConfig = (target: string) => (e: MouseEvent) => {
    e.stopPropagation();
    onSelectConfig(target);
  };

  const all = configs.map((c) => c[0]);

  const onRetry = () => dispatch(requestMobileProductData(productId));

  return (
    <>
      <MobileConfigTableRow
        displayPrefs={displayPrefs}
        root={true}
        data={configs[0][1]}
        multiples={multiples}
        productName={groupData.name}
        network={groupData.network}
        count={configs.length}
        expanded={expanded}
        onExpand={multi ? () => setExpanded(!expanded) : undefined}
        onEdit={handleOnEdit(all)}
        onDelete={handleOnDelete(all)}
        onCommissionSelect={handleCommissionSelect(all)}
        selected={selectedConfigs.includes(configs[0][0])}
        onSelect={handleSelectConfig(configs[0][0])}
        isCheckBoxHidden={false}
        isOneMonthContract={isOneMonthContract}
        onRetry={onRetry}
      />
      <TableBody style={!expanded || !multi ? { display: "none" } : undefined}>
        {configs.map((config, i) => {
          const [configIndex, data] = config;
          return (
            <MobileConfigTableRow
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              displayPrefs={displayPrefs}
              root={false}
              data={data}
              onEdit={handleOnEdit([configIndex])}
              onDelete={handleOnDelete([configIndex])}
              onCommissionSelect={handleCommissionSelect([configIndex])}
              selected={selectedConfigs.includes(configIndex)}
              onSelect={handleSelectConfig(configIndex)}
              isCheckBoxHidden={false}
              isOneMonthContract={isOneMonthContract}
              onRetry={onRetry}
            />
          );
        })}
      </TableBody>
    </>
  );
};
