import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { SalesStatusDataTable } from "./SalesStatus/SalesStatusDataTable";
import { LoaderButton, StatusChip } from "@akj-dev/shared-components";
import { AccountSelect } from "./AccountSelect";
import { AccountAutocompleteResult } from "./api/accountAutocompleteApi";
import { NewOrderForAccountOrLead } from "../../api/v1/guidedSales";
import { orderToCpqParams } from "./utils/orderToCpqParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getErrorMessage } from "@akj-dev/affinity-platform";

export const Orders = () => {
  const history = useHistory();
  let { url } = useRouteMatch();

  const [opportunityName, setOpportunityName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [account, setAccount] = useState<AccountAutocompleteResult | null>(
    null
  );
  const [error, setError] = useState("");

  const getFormError = () => {
    if (!opportunityName) {
      return "Please enter an opportunity name";
    }

    if (!/^[A-Za-z0-9\-'., ]+$/.test(opportunityName)) {
      return "Must not contain special characters";
    }
    return "";
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Box mt={2}>
          <Typography variant="h2">New Order</Typography>
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Card>
          <CardContent>
            <form
              // TODO: Refactor to use Formik
              onSubmit={async (event) => {
                event.preventDefault();
                setSubmitted(true);
                if (!getFormError()) {
                  setFetching(true);
                  try {
                    const response = await NewOrderForAccountOrLead({
                      name: opportunityName,
                      order_source: "Affinity Orders",
                      account_id: account?.id,
                    });

                    console.log("res ", response);
                    if (response.data.status === "success") {
                      const params = orderToCpqParams(response.data);
                      console.log("push", params);
                      history.push(`${url}/cpq?${params}`);
                    } else {
                      setError(response.data.message || "Error creating order");
                    }
                  } catch (e) {
                    setError(getErrorMessage(e));
                  }
                  setFetching(false);
                }
              }}
            >
              <Grid container spacing={1}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Order Name"
                    value={opportunityName}
                    onChange={(event) => setOpportunityName(event.target.value)}
                    error={submitted && Boolean(getFormError())}
                    helperText={submitted && getFormError()}
                  />
                </Grid>
                <Grid item sm={6}>
                  <AccountSelect account={account} setAccount={setAccount} />
                </Grid>
                <Grid item sm={12}>
                  <LoaderButton
                    type="submit"
                    size="small"
                    color="primary"
                    loading={fetching}
                    disabled={!account}
                  >
                    Place Order
                  </LoaderButton>
                </Grid>
                {error && (
                  <Grid item sm={12}>
                    <StatusChip type="error" message={error} />
                  </Grid>
                )}
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12}>
        <SalesStatusDataTable
          status="draft"
          title="Draft Orders"
          orderSource="Affinity Orders"
        />
      </Grid>
    </Grid>
  );
};
