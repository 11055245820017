import _ from "lodash";
import { getHardwareOrderTotalBeforeCredit } from "../../order/selectors";
import { addressTypes } from "../../hardwareDelivery/constants";

/**
 * Get the total one off price for a configuration
 *
 * @param configuration {Object}
 * @param products {Array}
 * @param hasConnections {Boolean}
 * @param ignoreCredit {Boolean}
 *
 * @returns {float}
 */
export function configurationTotalOneOffPrice(
  configuration: any,
  products: any,
  hasConnections: boolean,
  ignoreCredit = false
) {
  const product = configuration.product;

  let priceInPence =
    configuration["quantity"] *
    (hasConnections
      ? product.price.oneOffWithConnection * 100
      : product.price.oneOff * 100);

  if (configuration["rows"]) {
    configuration["rows"].forEach((row: any) => {
      if (row["credit_used"] && !ignoreCredit) {
        const creditUsedCalculation = row["credit_used"] * 100;
        priceInPence = priceInPence - creditUsedCalculation;
      }
    });
  }
  const priceInPenceCalculation = priceInPence / 100;
  return priceInPenceCalculation.toFixed(2);
}

/**
 * Get the total daisy fresh price for a configuration
 *
 * @param configuration {Object}
 *
 * @returns {float}
 */
export function configurationTotalDaisyFreshPrice(configuration: any) {
  const product = configuration.product;

  let price =
    (configuration["daisyFreshQuantity"] || 0) *
    (parseFloat(product.price.daisyFresh) || 0);

  return price.toFixed(2);
}

/**
 * Get the total one off price for all products
 *
 * @param configurations {Object}
 * @param products {Array}
 * @param {boolean} hasConnections
 *
 * @returns {float}
 */
export function totalOneOffPrice(
  configurations: any,
  products: any,
  hasConnections: boolean
) {
  let total = 0;

  _.forEach(configurations, (configuration) => {
    total =
      total +
      parseFloat(
        configurationTotalOneOffPrice(configuration, products, hasConnections)
      );
  });

  return total.toFixed(2);
}

/**
 * Get the total daisy fresh price for all products
 *
 * @param configurations {Object}
 *
 * @returns {float}
 */
export function totalDaisyFreshPrice(configurations: any) {
  let total = 0;

  _.forEach(configurations, (configuration) => {
    total =
      total + parseFloat(configurationTotalDaisyFreshPrice(configuration));
  });

  return total.toFixed(2);
}

/**
 * Get the total quantity
 *
 * @param configurations {Object}
 * @param products {Array}
 * @param isDaisyFresh {boolean}
 *
 * @returns {float}
 */
export function totalQuantity(configurations: any, isDaisyFresh = false) {
  return _.sumBy(
    _.keys(configurations),
    (index) =>
      configurations[index][isDaisyFresh ? "daisyFreshQuantity" : "quantity"]
  );
}

/**
 * Get total hardware credit for a single product config (across all it's rows)
 *
 * @param config
 * @returns {number}
 */
export function totalConfigHardwareCredit(config: any) {
  return _.sumBy(
    config.rows,
    (row: any) => row && parseFloat(row.credit_used || 0)
  );
}

/**
 * Get the total hardware credit for all products on the order
 *
 * @param configurations {Object}
 * @returns {number}
 */
export function getTotalOrderHardwareCredit(configurations: any) {
  return _.sumBy(_.keys(configurations), (index) =>
    totalConfigHardwareCredit(configurations[index])
  );
}

/**
 * Get any error message involving HW credit
 * @param state
 * @returns {string|boolean}
 */
export function getHardwareCreditError(state: any) {
  const totalOrderHardwareCredit = getTotalOrderHardwareCredit(
    state.hardwareConfigurations
  );
  const hardwareOrderTotal = parseFloat(
    getHardwareOrderTotalBeforeCredit(state)
  );
  const availableHardwareCredit = parseFloat(
    state.account.availableHardwareCredit
  );

  if (totalOrderHardwareCredit > availableHardwareCredit) {
    return `You are trying to use £${totalOrderHardwareCredit.toFixed(
      2
    )} credit. Only £${state.account.availableHardwareCredit} is available.`;
  }

  if (totalOrderHardwareCredit > hardwareOrderTotal) {
    return "Hardware credit must not exceed hardware order value.";
  }

  return false;
}

/**
 * Check if delivery options have been properly populated
 * @param state
 * @returns {boolean}
 */
export function allHardwareDeliveryPopulated(state: any) {
  const isOk = (sel: any) => {
    if (sel.addressType === addressTypes.BILLING) return true;
    if (sel.addressType === addressTypes.EXISTING) {
      return sel.addressId !== "";
    }
    if (!sel.addressType) return false;
  };

  const globalDelivery = state.hardwareDelivery;
  if (globalDelivery.splitDelivery === false) {
    return isOk(globalDelivery);
  }

  const hwConfigs = state.hardwareConfigurations;
  for (const productId in hwConfigs) {
    for (const row of hwConfigs[productId].rows) {
      if (!isOk(row)) return false;
    }
  }
  const mobileConfigs = state.mobile.configs;
  for (const config of mobileConfigs) {
    if (!isOk(config.delivery)) return false;
  }

  return true;
}
