import React, { useCallback, useState } from "react";
import { Box, CircularProgress, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { QuantityInfoType } from "./enum";
import { useQuantityInfoTypeHandler, useStockLevelHandler } from "./hooks";
import { QuantityFieldSelect } from "./components/Select";
import { QuantityFieldInput } from "./components/Input";
import { QuantityFieldQuantityInfo } from "./components/QuantityInfo";
import { QuantityFieldSyncButton } from "./components/SyncButton";
import { QuantityFieldPriceInfo } from "./components/PriceInfo";
import { ColorType, StockLevelResponse } from "./types";
import { ReduxFetchType } from "../../../store/_root/types";

export interface QuantityFieldProps {
  color?: ColorType;
  label?: string;
  price?: string;
  maxQuantity: number;
  quantityThreshold: number;
  setQuantity: (v: number | undefined) => void;
  disabled?: boolean;
  quantity?: number;
  stockLevel?: ReduxFetchType<StockLevelResponse>;
  getStockLevel?: () => void;
  allowInput?: boolean;
  hasReachedMaxConfigCount: boolean;
  dataCy: string;
}

export const QuantityField = (props: QuantityFieldProps) => {
  const {
    color,
    disabled = false,
    price,
    label,
    quantity,
    quantityThreshold,
    stockLevel,
    maxQuantity,
    setQuantity,
    getStockLevel,
    allowInput = true,
    hasReachedMaxConfigCount = false,
    dataCy,
  } = props;
  const classes = useStyles();
  const { fetching } = stockLevel ?? {};

  const [inputValue, setInputValue] = useState(quantity);
  const [showInput, setShowInput] = useState(false);

  const handleQuantitySync = useCallback(() => {
    setQuantity(inputValue);
    setShowInput(false);
    if (!stockLevel) {
      getStockLevel?.();
    }
  }, [inputValue, stockLevel, setQuantity, setShowInput, getStockLevel]);

  useStockLevelHandler({ stockLevel, quantity, getStockLevel });
  const { type, message } = useQuantityInfoTypeHandler({
    quantity,
    hasReachedMaxConfigCount,
    maxQuantity,
    stockLevel,
    getStockLevel,
  });

  const showPrice = !!color || !!price || !!label;
  const showInputField =
    allowInput && (showInput || (quantity ?? 0) >= quantityThreshold);
  const showLoader = !!fetching;
  const showInfo = !showLoader && !showInput;
  const showError =
    type === QuantityInfoType.QTY_ERROR ||
    type === QuantityInfoType.STOCK_ERROR;

  return (
    <Box className={classes.wrapper} data-cy={dataCy}>
      {showPrice && (
        <QuantityFieldPriceInfo color={color} price={price} label={label} />
      )}
      <Box>
        {showInputField ? (
          <QuantityFieldInput
            disabled={disabled}
            showError={showError}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleBlur={handleQuantitySync}
            showInput={showInput}
            setShowInput={setShowInput}
          />
        ) : (
          <QuantityFieldSelect
            disabled={disabled}
            showError={showError}
            quantity={quantity}
            quantityThreshold={quantityThreshold}
            maxQuantity={maxQuantity}
            setQuantity={setQuantity}
            setShowInput={setShowInput}
            allowInput={allowInput}
          />
        )}
      </Box>
      <Box className={classes.infoBox}>
        {showLoader && <CircularProgress />}
        {showInput && (
          <QuantityFieldSyncButton handleSync={handleQuantitySync} />
        )}
        {showInfo && (
          <QuantityFieldQuantityInfo type={type}>
            {message}
          </QuantityFieldQuantityInfo>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
  },
  infoBox: {
    width: 100,
    padding: theme.spacing(0.5),
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));
