import React from "react";

import { MPFNoProductChangesSubRow } from "../../components/Resigns/MPFProductInstance/MPFNoProductChangesSubRow";
import { MPFProductChangesSubRow } from "../../components/Resigns/MPFProductInstance/MPFProductChangesSubRow";
import { LineProductSelectorContainer } from "../../containers/Resigns/LineProductSelector";
import { NEW_PRODUCT } from "../../../../../store/wlrBroadband/constants";

import { MPFProductBaseProps } from "./model";

interface MPFLineProductOverviewProps
  extends Pick<
    MPFProductBaseProps,
    "configuration" | "removeResign" | "productInstance"
  > {
  allProducts: Record<string, any>[];
  setChanges: () => void;
}

export const MPFLineProductOverview = ({
  configuration,
  productInstance,
  allProducts,
  removeResign,
  setChanges,
}: MPFLineProductOverviewProps) => {
  const resignProduct = allProducts.find(
    (product) => product.id === configuration.wlrProductId
  );

  return (
    <>
      {configuration.wlrProductId && resignProduct ? (
        <MPFProductChangesSubRow
          productInstance={productInstance}
          removeResign={removeResign}
          resignProduct={resignProduct}
          productInstanceType="wlr"
        />
      ) : (
        <MPFNoProductChangesSubRow
          productInstance={productInstance}
          configuration={configuration}
          onClick={setChanges}
          removeResign={removeResign}
          productInstanceId={configuration.wlrProductId}
          productInstanceType="wlr"
        />
      )}
      {configuration.resignProductType === NEW_PRODUCT &&
        !configuration.wlrProductId && (
          <LineProductSelectorContainer
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};
