import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getBroadbandSearchForLocation,
  getLineAvailabilityForLocation,
  getResignProductForProductInstanceServiceType,
  getTagsCheckForLocation,
} from "../../../../../store/wlrBroadband/selectors";
import {
  fetchLineAvailabilityForResign,
  doBroadbandSearchForResign,
  setWlrResignProduct,
  setBroadbandResignProduct,
  removeResign,
} from "../../../../../store/wlrBroadband/actions/resigns";
import { doUpdateProductInstance } from "../../../../../store/wlrBroadband/actions/configurations";
import {
  NEW_PRODUCT,
  SAME_PRODUCT,
  RESIGN,
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelector } from "../../components/Resigns/BroadbandProductSelector";

interface BroadbandProductSelectorContainerProps {
  configuration: any;
  productInstance: any;
}

export const BroadbandProductSelectorContainer = ({
  configuration,
  productInstance,
}: BroadbandProductSelectorContainerProps) => {
  const dispatch = useDispatch();

  // setup variables for selectors
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const linkedConfiguration = configurations.find(
    (config: any) =>
      config.pin === productInstance.pin &&
      config.wlrProductId &&
      config.wlrProductId !== RESIGN
  );
  const locationIndex = linkedConfiguration
    ? linkedConfiguration.locationIndex
    : configuration.locationIndex;
  const configurationIndex = configurations.indexOf(configuration);

  // selectors
  const location = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]
  );
  const lineAvailability = useSelector((state: any) =>
    getLineAvailabilityForLocation(state, { configuration })
  );
  const broadbandSearch = useSelector((state: any) =>
    getBroadbandSearchForLocation(state, { configuration })
  );

  const resignProduct = useSelector((state: any) =>
    getResignProductForProductInstanceServiceType(state, productInstance)
  );
  const tagsCheck = useSelector((state: any) =>
    getTagsCheckForLocation(state, { configuration })
  );

  const setResignProduct = (
    productInstance: any,
    resignProduct: any,
    resignProductType: any
  ) => {
    if (
      !!linkedConfiguration &&
      linkedConfiguration.resignProductType !== SAME_PRODUCT
    ) {
      // The below doesn't apply to MPF, as MPF comes through as one product
      // instance.
      if (linkedConfiguration.type !== "mpf") {
        // When initially selecting 'Same Product With Changes' or 'New Product'
        // for both a line and broadband product instance a new config is added
        // for each. If they are linked, the second config is never needed, as
        // the subsequent broadband details will be added under the first.
        dispatch(removeResign([productInstance.id]));
      }
      dispatch(
        setBroadbandResignProduct({
          productInstance,
          resignProductId: resignProduct.id,
          type: linkedConfiguration.type,
        })
      );
    } else {
      dispatch(
        setWlrResignProduct({
          productInstance,
          resignProductId: "Resign",
          resignProductType,
        })
      );
      dispatch(
        setBroadbandResignProduct({
          productInstance,
          resignProductId: resignProduct.id,
          type: configuration.type,
        })
      );
    }
  };

  return (
    <BroadbandProductSelector
      tagsCheck={tagsCheck}
      broadbandSearch={broadbandSearch}
      lineAvailability={lineAvailability}
      location={location}
      productInstance={productInstance}
      updateProductInstance={
        linkedConfiguration
          ? linkedConfiguration.updateProductInstance
          : configuration.updateProductInstance
      }
      configuration={configuration}
      resignProduct={resignProduct}
      setResignProduct={setResignProduct}
      onSetAddress={(address) => {
        dispatch(
          doUpdateProductInstance({
            id: productInstance.id,
            pin: productInstance.pin,
            configurationIndex,
            address,
            resignProductType: configuration.resignProductType,
            productInstance: productInstance,
          })
        );
        if (configuration.resignProductType === NEW_PRODUCT) {
          dispatch(fetchLineAvailabilityForResign(productInstance));
          dispatch(doBroadbandSearchForResign(productInstance));
        }
      }}
    />
  );
};
