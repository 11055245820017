import { flow, join, upperFirst, words } from "lodash";

/**
 * takes a string with underscores and converts underscores to spaces
 * and capitilises first letter
 * @param string
 * @returns {*}
 */
export const convertToLabelFromUnderscore = (label: string) => {
  return flow([words, (s) => join(s, " "), upperFirst])(label);
};
