import React from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";

type OptionValue = string | null;

type Props<T extends OptionValue> = {
  id: string;
  label: string;
  value?: (T | null)[];
  onSelect: (selected: T[]) => void;
  options: T[];
  getOptionLabel?: (option: string | null) => string;
  disabled?: boolean;
};

export function MultiSelect<T extends OptionValue>({
  value,
  onSelect,
  options,
  id,
  label,
  disabled,
  getOptionLabel,
}: Props<T>) {
  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      filterSelectedOptions
      id={id}
      size="small"
      multiple
      getOptionLabel={getOptionLabel}
      value={value}
      options={options || []}
      onChange={(e, selected) => {
        const value = selected.filter((x) => typeof x === "string") as T[];
        onSelect(value);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
}
