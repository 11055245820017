import React from "react";
import Icon from "@mui/icons-material/SettingsPhone";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

interface CallTariffProps {
  isWlrChangePossible: boolean;
}

export const CallTariff = ({ isWlrChangePossible }: CallTariffProps) => (
  <Section title="Call Tariff" avatar={<Icon />}>
    {isWlrChangePossible && (
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.call_tariff_id"
      />
    )}
    <WlrBBDynamicField
      productType="wlr"
      propertyName="call_tariff_id"
      isRootProperty={true}
    />
  </Section>
);
