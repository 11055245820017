import React from "react";

import { ExpandingCard } from "@akj-dev/shared-components";
import HomeIcon from "@mui/material/SvgIcon/SvgIcon";

import { ResignConfigTableRow } from "../../containers/wlrBroadband/ResignsConfigurationTable/ResignConfigTableRow";
import { ResignConfigTableOuter } from "./ResignConfigTableOuter";
import { ResignStartDateContainer } from "../../containers/wlrBroadband/ResignStartDate";

interface ResignConfigTableRowProps {
  configurations: any[];
  checked: boolean;
  configCount: number;
  selectedConfigs: number[];
  hasResigns: boolean;
  showCommissionType: boolean;
  toggleAction: (index: number) => void;
  editAction: (index: number) => void;
  deleteAction: (index: number) => void;
  retryAction: () => void;
  toggleAll: () => void;
}

export const ResignsConfigurationTable = ({
  checked = false,
  configCount,
  selectedConfigs,
  configurations,
  hasResigns,
  showCommissionType,
  toggleAction,
  editAction,
  deleteAction,
  retryAction,
  toggleAll,
}: ResignConfigTableRowProps) => (
  <ExpandingCard
    title="Resign Products"
    subtitle={`${selectedConfigs.length} Selected / ${configCount} Available`}
    avatar={<HomeIcon />}
    defaultExpanded
  >
    <ResignConfigTableOuter
      checked={checked}
      showCommissionType={showCommissionType}
      toggleAll={toggleAll}
    >
      {configurations.map((configuration: any, index: number) => {
        if (!configuration.productInstanceId) return false;
        return (
          <ResignConfigTableRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            configuration={configuration}
            index={index}
            checked={selectedConfigs.indexOf(index) !== -1}
            showCommissionType={showCommissionType}
            toggleAction={() => toggleAction(index)}
            editAction={() => editAction(index)}
            deleteAction={() => deleteAction(configuration.locationIndex)}
            retryAction={retryAction}
          />
        );
      })}
    </ResignConfigTableOuter>
    {hasResigns && <ResignStartDateContainer />}
  </ExpandingCard>
);
