import React from "react";
import PropTypes from "prop-types";

interface SiteAddressProps {
  prefix: "site_a" | "site_b";
  purchaseArgs: Record<string, string>;
}

export const SiteAddress = ({ purchaseArgs, prefix }: SiteAddressProps) => {
  return (
    <div>
      <div>{purchaseArgs[prefix + "_site_address_company"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_building"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_street"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_post_town"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_postcode"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_country"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_locale"]}</div>
      <div>{purchaseArgs[prefix + "_site_address_region"]}</div>
    </div>
  );
};

SiteAddress.propTypes = {
  purchaseArgs: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired, // `site_a` or `site_b`
};
