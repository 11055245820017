import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Alert, LoaderButton } from "@akj-dev/shared-components";

interface FinalStepWarningProps {
  handleConfirm: () => void;
  handleCancel: () => void;
  handleGenerateQuote: () => void;
  saveDraft: () => void;
  draftIsSaving: boolean;
  show: boolean;
}

export const FinalStepWarning = ({
  handleConfirm,
  handleCancel,
  handleGenerateQuote,
  saveDraft,
  draftIsSaving,
  show,
}: FinalStepWarningProps) => (
  <Dialog open={show}>
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <Alert
        type="warning"
        message={
          <div>
            If you continue past this point, you will not be able to make any
            further amendments to this sale.{" "}
            <b>Are you sure you wish to continue?</b>
          </div>
        }
      />
    </DialogContent>
    <DialogActions>
      <LoaderButton
        type="submit"
        variant="contained"
        color="primary"
        loading={draftIsSaving}
        onClick={saveDraft}
      >
        Save Draft
      </LoaderButton>
      <Button color="primary" variant="contained" onClick={handleGenerateQuote}>
        Send Quote
      </Button>
      <div style={{ flex: "1 0 0" }} />
      <Button onClick={handleCancel}>Cancel</Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        data-cy="FinalStepConfirmButton"
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);
