import { createSlice } from "@reduxjs/toolkit";

import mobileState from "./state";

import { boltOnsExtraReducers } from "./extraReducers/boltOns";
import { contractLengthExtraReducers } from "./extraReducers/contractLength";
import { daisyFreshExtraReducers } from "./extraReducers/daisyFresh";
import { newConfigsExtraReducers } from "./extraReducers/newConfigs";
import { orderExtraReducers } from "./extraReducers/order";
import { productConfigExtraReducers } from "./extraReducers/productConfig";
import { reservedNumbersExtraReducers } from "./extraReducers/reservedNumbers";
import { resignsExtraReducers } from "./extraReducers/resigns";
import { validationExtraReducers } from "./extraReducers/validation";

const mobileSlice = createSlice({
  name: "mobile",
  initialState: {
    ...mobileState,
  },
  reducers: {},
  extraReducers: (builder) => {
    boltOnsExtraReducers(builder);
    contractLengthExtraReducers(builder);
    daisyFreshExtraReducers(builder);
    newConfigsExtraReducers(builder);
    orderExtraReducers(builder);
    productConfigExtraReducers(builder);
    reservedNumbersExtraReducers(builder);
    resignsExtraReducers(builder);
    validationExtraReducers(builder);
  },
});

export const { actions: mobileActions, reducer: mobileReducer } = mobileSlice;
export default mobileReducer;
