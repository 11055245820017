import React from "react";
import { Alert } from "@akj-dev/shared-components";
import SimCardIcon from "@mui/icons-material/SimCard";

import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";
import { SimSerialVerificationContainer } from "../../containers/Mobile/ConfigurationForm/sections/SimSerialVerification";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { useDwsResellerEnabled } from "../../../../shared/hooks/useDwsResellerEnabled";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";

interface SimCardProps {
  existingNetwork: string;
  userSelectedAcquisitionMethod: string;
  simIsBuffer: "1" | "0";
  isMigration: boolean;
  canKeepSim: boolean;
  isESIM: boolean;
  settings: AccountSettings;
  supplier: string;
}

export const SimCard = ({
  existingNetwork,
  userSelectedAcquisitionMethod,
  simIsBuffer,
  isMigration,
  canKeepSim,
  settings,
  isESIM,
  supplier,
}: SimCardProps) => {
  const isDwsResellerEnabled = useDwsResellerEnabled();
  const canAccessVfDirect = useCanAccessVfDirect();

  /* TP140847 - Ideally this logic would be in ConfigurationForm.tsx but we already have our flags in here to test. */
  if (canAccessVfDirect && userSelectedAcquisitionMethod === "resign")
    return null;
  return (
    <Section title="SIM Card" avatar={<SimCardIcon />}>
      {!canAccessVfDirect && (
        <BulkDynamicField
          propertyName="is_sim_required"
          disabled={
            !(
              (userSelectedAcquisitionMethod === "port/mig" &&
                isMigration &&
                canKeepSim) ||
              (userSelectedAcquisitionMethod === "internal_port" &&
                existingNetwork === "Dise")
            )
          }
        />
      )}

      {settings.as_display_sim_type === "1" && (
        <BulkDynamicField propertyName="sim_type" />
      )}
      {!isDwsResellerEnabled && !canAccessVfDirect && (
        <BulkDynamicField
          propertyName="sim_is_buffer"
          disabled={settings.as_pre_dispatched_sim_required === "1"}
        />
      )}
      {!isESIM && simIsBuffer === "1" && (
        <SimSerialVerificationContainer supplier={supplier} />
      )}
      {isESIM &&
        (supplier === "Vodafone" ||
          (supplier === "VF Direct" &&
            settings.as_display_sim_type === "1")) && (
          <>
            <ArrayDynamicField
              propertyName="esim_delivery_email_address"
              floatingLabelText="Delivery Email Address"
            />
            <Alert
              type="warning"
              message={`Please note when placing this order, the E-SIM can only be updated during working hours.\n
                Not all handsets are E-SIM compatible. Please confirm the customers handset is compatible before processing.  E-SIM provided by ${
                  !canAccessVfDirect ? "Daisy" : "Vodafone"
                } is not compatible with wearables such a smart watches.`}
            />
          </>
        )}
    </Section>
  );
};
