import u from "updeep";

import * as actions from "./actionTypes";

const initialState = {
  error: "",
  loading: false,

  fields: {
    account_id: "",
    contacts: [],
    email: "",
    name: "",
    statuses: [],
    type: "",
  },
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actions.CREATE:
      return u(
        {
          error: "",
          loading: true,
        },
        state
      );
    case actions.CREATE_OK:
      return u(
        {
          error: "",
          loading: false,
          fields: initialState.fields,
        },
        state
      );
    case actions.CREATE_FAILED:
      return u(
        {
          ...action.payload,
          loading: false,
        },
        state
      );
    case actions.SET_FIELDS:
      return u(
        {
          fields: {
            ...action.fields,
          },
        },
        state
      );
    default:
      return state;
  }
};

export default reducer;
