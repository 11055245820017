import React from "react";
import { ExpandingCard } from "@akj-dev/shared-components";
import HomeIcon from "@mui/icons-material/Home";
import { EthernetCard } from "../../../../shared/components/Ethernet/Card";
import { SiteAddress } from "../../../../shared/components/Ethernet/SiteAddress";
import { CompletedOrderInfo } from "../../../../shared/components/CompletedOrderInfo";
import { EthernetTypeEnum } from "../../../../store/ethernetProducts/constants";

interface EthernetOrderConfirmationProps {
  configuration: Record<string, any>;
}

export const EthernetOrderConfirmation = ({
  configuration,
}: EthernetOrderConfirmationProps) => (
  <EthernetCard configuration={configuration}>
    <ExpandingCard
      defaultExpanded
      title="Router delivery address"
      avatar={<HomeIcon />}
    >
      {configuration.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP ? (
        <h2>N/A</h2>
      ) : (
        <SiteAddress
          purchaseArgs={configuration.purchaseArgs}
          prefix="site_b"
        />
      )}
    </ExpandingCard>
    {configuration.purchaseResponse.order_id && (
      <CompletedOrderInfo orderId={configuration.purchaseResponse.order_id} />
    )}
  </EthernetCard>
);
