import React from "react";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/material";

interface ExpandedProps {
  index: number;
  product: {
    id: number;
    name: string;
    price: {
      oneOffWithConnection: number;
      oneOff: number;
      daisyFresh: string;
    };
  };
  config: any;
  isDaisyFreshRow?: boolean;
  hasConnections: boolean;
  setFields: (
    productId: number,
    fields: Record<string, any>,
    index?: number
  ) => void;
}

export const Expanded = ({
  index,
  product,
  config,
  setFields,
  hasConnections,
  isDaisyFreshRow = false,
}: ExpandedProps) => {
  const creditUsed = _.get(config.rows, [index, "credit_used"]) || 0;
  const oneOffPrice = hasConnections
    ? product.price.oneOffWithConnection
    : product.price.oneOff;
  const daisyFreshPrice = product.price.daisyFresh;

  return (
    <TableRow>
      <TableCell />
      <TableCell>{product.name}</TableCell>
      <TableCell>
        {isDaisyFreshRow ? "" : "£" + (oneOffPrice - creditUsed).toFixed(2)}
      </TableCell>
      <TableCell>{isDaisyFreshRow ? "" : 1}</TableCell>
      <TableCell>
        {isDaisyFreshRow ? "£" + parseFloat(daisyFreshPrice).toFixed(2) : ""}
      </TableCell>
      <TableCell>{isDaisyFreshRow ? 1 : ""}</TableCell>
      <TableCell>
        {isDaisyFreshRow ? (
          <Box fontStyle="italic"> Not Available</Box>
        ) : (
          <TextField
            variant="standard"
            id={"credit_used_" + product.id + "_i_" + index}
            name={"credit_used_" + product.id + "_i_" + index}
            value={creditUsed}
            onChange={(e) =>
              setFields(product.id, { credit_used: e.target.value }, index)
            }
            style={{ width: "100px" }}
            type="number"
          />
        )}
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
