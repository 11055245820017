import React from "react";
import {
  Dialog,
  Button,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { SelectContact } from "../../../shared/containers/SelectContact";
import { CircularProgress } from "@mui/material";

interface GenerateContractProps {
  contactId: string;
  fetching: boolean;
  isDialogOpen: boolean;
  toggleDialog: () => void;
  generateAndApprove: () => void;
  onSelectContactForContract: (id: string) => void;
}

export const GenerateContract = ({
  contactId,
  fetching,
  isDialogOpen,
  generateAndApprove,
  onSelectContactForContract,
  toggleDialog,
}: GenerateContractProps) => {
  return (
    <Box mt={1}>
      <Button
        variant="contained"
        color="secondary"
        data-cy="generateContractButton"
        onClick={toggleDialog}
        disabled={fetching}
        endIcon={fetching && <CircularProgress size={20} color="inherit" />}
      >
        Send E-Sign Contract
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Send E-Sign Contract</DialogTitle>
        <DialogContent>
          <p>
            Select or add a new contact to generate an digital contract and send
            via email. The recipient will receive a digital contract they can
            electronically sign.
          </p>
          <SelectContact
            current={contactId}
            handleUpdate={onSelectContactForContract}
          />
        </DialogContent>
        <DialogActions sx={{ margin: (theme) => theme.spacing(-4, 1, 1, 1) }}>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={contactId === ""}
            onClick={generateAndApprove}
          >
            Send digital contract for signing
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
