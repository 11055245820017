import React from "react";

import { Box } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

import { MobileProduct } from "../../../../../shared/types/general";
import { ProductTable } from "../../components/Tariff/ProductTable";
import { Loader } from "../../../../../shared/components/Loader";
import { MobileProductConfig } from "../../../../../shared/types/MobileProductConfig";

interface TariffTableContainerProps {
  showLoader: boolean;
  mobileError?: string | false;
  canSeeProductFamily: boolean;
  withO2: boolean;
  configsCount: number;
  products: MobileProduct[];
  configs: MobileProductConfig[];
  retryRequestMobileSearch: () => void;
}

export const TariffTable = ({
  showLoader,
  mobileError,
  canSeeProductFamily,
  withO2,
  configsCount,
  products,
  configs,
  retryRequestMobileSearch,
}: TariffTableContainerProps) => (
  <Box width="100%" display="flex" flexDirection="column">
    {showLoader && (
      <Loader text="Searching for the latest Products. This may take up to a minute." />
    )}
    {!showLoader && !mobileError && (
      <ProductTable
        configsCount={configsCount}
        products={products}
        configs={configs}
        isReseller={false}
        withCarrierFilter={withO2}
        showProductFamily={canSeeProductFamily}
      />
    )}
    {mobileError && (
      <Box mb={1}>
        <StatusChip
          type="error"
          title="Error finding mobile products"
          message={mobileError}
          retry={retryRequestMobileSearch}
        />
      </Box>
    )}
  </Box>
);
