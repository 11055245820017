import React from "react";
import _ from "lodash";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import { Section } from "../components/Section";
import { PriceMeta } from "../components/Pricing/PriceMeta";
import { DiscountFields } from "../components/Pricing/DiscountFields";
import { PricingScheme } from "../components/Pricing/PricingScheme";
import { ServiceTariff } from "../components/Pricing/ServiceTariff";
import { getOptimisticComponentPrice } from "../../store/mobile/selectors/pricing";

interface PricingProps {
  title: string;
  // Returned product data from DC...available pricing schemes etc.
  productData: Record<string, any>;
  // Config properties ready for OrderProduct calls and to show in this UI
  // So for mobile: mobile.configs[i].properties
  // And WLR / BB: wlrBroadband.configurations[i].wlrProperties or broadbandProperties
  configProperties: Record<string, any>;
  // Functions to set all this stuff..
  setPricingScheme: (scheme: string) => void;
  setDiscount: (priceType: any, discountType: any, discountValue: any) => void;
  // Note this is just a standard dynamic_property,so SET_PRODUCT_PROPERTY:
  setServiceTariff: (tariff: string) => void;
}
export const Pricing = ({
  title,
  productData,
  configProperties,
  setPricingScheme,
  setDiscount,
  setServiceTariff,
}: PricingProps) => {
  const fetching = productData.fetching;

  // Most products (all, I mistakenly thought originally!) have one component
  // which holds pricing data. Get that component....

  // Exception to the rule... WLR change components will have a broadband AND line component.
  // Line for the WLR change bit presumably...
  // We just want the broadband one in that case, as this is the one with pricing data.
  const component =
    productData.response.broadband ||
    productData.response.line ||
    productData.response.mobile;

  // Don't render at all if product component data hasn't loaded.
  if (!component) return null;

  const component_price = getOptimisticComponentPrice(
    configProperties,
    component.component_price,
    fetching
  );

  const {
    // Pricing scheme
    available_pricing_schemes,
    pricing_scheme_details,
    // Recurring discounts
    minimum_recurring_price,
    maximum_recurring_discount_percent,
    recurring_price_without_discounts,
  } = component_price;

  // Service tariff. WLR, Broadband and Mobile all have this.
  // It's a root property like contract length.
  const { service_tariff_id } = productData.response.dynamic_properties;

  // Check if a resign product (service tariff is not needed if so).
  const isResign = !!component.product_component_data.is_resign;

  // Some products can have discounts, others can't.
  // This applies to both one off and recurring ones
  const discountAllowed = component_price.allow_custom_discount == 1; // eslint-disable-line eqeqeq
  const oneOffDiscountAllowed =
    component.activation_fee?.allow_custom_discount == 1; // eslint-disable-line eqeqeq

  // See if we have recurring or one off prices, so we know which discount UIs to display
  const hasRecurringPrice =
    // parseFloat(component_price.total_recurring_price_for_length_of_contract) > 0 ||
    // parseFloat(component_price.recurring_price_without_promotions) > 0 || // This includes discounts
    parseFloat(recurring_price_without_discounts) > 0; // This is the base price

  // Annoyingly, all one off fees for wlr and broadband are under the `activation_fee` sub component.
  // Mobile never has one off fees. .....so I'm told.
  // While there's a one_off_price_without_discounts in the root <component>.component_price, it's not used currently.
  // Guess if other one off fees come along we'll have to revisit this.
  // Set with line-activation_fee.one_off_discount_value in the OrderProduct / Product data call...
  const hasOneOffPrice =
    parseFloat(
      _.get(component, "activation_fee.one_off_price_without_discounts")
    ) > 0;

  // One off Discount limitations are also stored on this sub-product.
  const {
    minimum_one_off_price = false,
    maximum_one_off_discount_percent = false,
  } = component.activation_fee || {};

  return (
    <Section title={title} avatar={<MonetizationOnIcon />}>
      <PricingScheme
        availablePricingSchemes={available_pricing_schemes}
        pricingSchemeDetails={pricing_scheme_details}
        pricingScheme={configProperties.pricing_scheme}
        setPricingScheme={setPricingScheme}
      />
      {/*Note: Service tariff EXISTS in mobile product data but we don't want UI for it according to @lindsey */}
      {!productData.response.mobile && !isResign && (
        <ServiceTariff
          serviceTariffId={service_tariff_id}
          configServiceTariffId={configProperties.service_tariff_id}
          setServiceTariff={setServiceTariff}
        />
      )}
      {hasRecurringPrice && discountAllowed && (
        <DiscountFields
          discountType={configProperties.recurring_discount_type}
          priceType="recurring"
          discountValue={configProperties.recurring_discount_value}
          minPrice={minimum_recurring_price}
          fullPrice={recurring_price_without_discounts}
          maxPercent={maximum_recurring_discount_percent}
          setDiscount={setDiscount}
        />
      )}
      {hasRecurringPrice && (
        <PriceMeta
          priceType="RECURRING"
          componentPrice={component_price}
          fetching={fetching}
        />
      )}
      {hasOneOffPrice && oneOffDiscountAllowed && (
        <DiscountFields
          discountType={
            configProperties["activation_fee.one_off_discount_type"]
          }
          priceType="activation_fee.one_off"
          discountValue={
            configProperties["activation_fee.one_off_discount_value"]
          }
          minPrice={minimum_one_off_price}
          fullPrice={component.activation_fee.one_off_price_without_discounts}
          maxPercent={maximum_one_off_discount_percent}
          setDiscount={setDiscount}
        />
      )}
      {hasOneOffPrice && (
        <PriceMeta
          priceType="ONE_OFF"
          componentPrice={component.activation_fee}
          fetching={fetching}
        />
      )}
    </Section>
  );
};
