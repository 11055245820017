import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Orders } from "./Orders";
import { CpqWrapper } from "./CpqWrapper";

export const NewOrder = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Orders />
      </Route>
      <Route path={`${path}/cpq`}>
        <CpqWrapper />
      </Route>
    </Switch>
  );
};
