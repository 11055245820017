import { OrderType } from "../types";

export function getMuiThemePaletteColorByOrderType(orderType: OrderType) {
  let muiThemePaletteColor: "success" | "warning" | "error" | "primary" =
    "primary";

  if (orderType === "Provide") {
    muiThemePaletteColor = "success";
  }

  return muiThemePaletteColor;
}
