import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "./components/Form";
import Main from "../../../layout/Main";

import { RootState } from "../../../store/rootReducer";

import {
  searchAccounts,
  searchUsers,
} from "../../../store/autocomplete/actions";
import { create, setFields } from "../../../store/newnotification/actions";

function NewNotification() {
  const { fields, error, loading } = useSelector(
    (state: RootState) => state.newnotification
  );

  const { accounts, users } = useSelector(
    (state: RootState) => state.autocomplete
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(searchUsers());
  }, [dispatch]);

  return (
    <Main error={error} title="New Notification" loading={loading}>
      <Form
        fields={fields}
        setFields={(fields: any) => dispatch(setFields(fields))}
        onSubmit={(fields: any) => dispatch(create(fields))}
        accounts={accounts}
        searchAccounts={(query: string) => dispatch(searchAccounts(query))}
        users={users}
      />
    </Main>
  );
}

export default NewNotification;
