import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import {
  addSpecialRateToRemove,
  deleteSpecialRateToRemove,
  doLineSearch,
  removeSpecialRate,
  setContractLength,
} from "./actions/index";
import {
  addLocation,
  addResignLocation,
  doBroadbandSearch,
  fetchAvailableNumbers,
  fetchInstallationDetails,
  fetchLineAvailability,
  fetchTagsCheck,
  removeLocation,
  removeProductsForLocation,
  removeResignLocation,
  setLocationAddress,
  setLocationCli,
  setLocationType,
  toggleSogeaTermsConfirmation,
  validateWorkingLineTakeOver,
} from "./actions/locations";
import {
  addWlrConfiguration,
  doAddBroadbandAppointment,
  doAddWlrAppointment,
  doNumberReservation,
  doRemoteValidation,
  doUpdateProductInstance,
  executeOrderProduct,
  fetchBroadbandAppointments,
  fetchProductData,
  fetchWlrAppointments,
  removeProductDiscount,
  removeWlrConfiguration,
  setBroadbandAppointment,
  setBroadbandProduct,
  setConfiguration,
  setNumberReservationType,
  setPricingScheme,
  setProductDiscount,
  setProductProperty,
  setPropertiesFromProductData,
  setReservedNumber,
  setWlrAppointment,
  validateBroadband,
  validateProductProperty,
  validateWlr,
  validateWlrQuoteOnly,
} from "./actions/configurations";
import {
  doBroadbandSearchForResign,
  fetchBroadbandProductInstances,
  fetchBroadbandResignProductSearch,
  fetchLineAvailabilityForResign,
  fetchLineProductInstances,
  fetchLineResignProductSearch,
  fetchResignProductSearch,
  removeResign,
  setBroadbandResignProduct,
  setResignStartDate,
  setResignType,
  setWlrResignProduct,
} from "./actions/resigns";
import { newLocation } from "./state/newLocation";
import { mapToLocationAddress } from "../../shared/utils/addresses";
import {
  AWAITING,
  broadbandToWlrFieldMappings,
  existingONTTypeProps,
  NEW_PRODUCT,
  RESIGN,
  SAME_PRODUCT_WITH_CHANGES,
} from "./constants";
import newConfiguration from "./state/newConfiguration";
import newBroadbandProduct from "./state/newBroadbandProduct";
import u from "updeep";
import { format } from "date-fns";
import { DC_DATE_FORMAT, addWeekdays } from "../../shared/utils/date";
import _ from "lodash";
import { removeConfigForLocationIndex } from "./utils";

const wlrBroadband = createSlice({
  name: "wlrBroadband",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Root

      .addCase(setContractLength, (state, action) => {
        state.contractLength = action.payload;
      })

      .addCase(doLineSearch.pending, (state) => {
        state.lineSearch.fetching = true;
      })

      .addCase(doLineSearch.fulfilled, (state, action) => {
        state.lineSearch.fetching = false;
        state.lineSearch.response = action.payload;
      })

      .addCase(removeSpecialRate.pending, (state, action: any) => {
        const { id } = action.meta.arg as any;
        const index = state.specialRatesToRemove.findIndex(
          (specialRate: any) => specialRate.id === id
        );
        state.specialRatesToRemove[index].removeSpecialDate = {
          fetching: true,
        };
      })

      .addCase(removeSpecialRate.fulfilled, (state, action: any) => {
        const { id } = action.meta.arg as any;
        const index = state.specialRatesToRemove.findIndex(
          (specialRate: any) => specialRate.id === id
        );
        state.specialRatesToRemove[index].removeSpecialDate.fetching = false;
        state.specialRatesToRemove[index].removeSpecialDate.response =
          action.payload;
      })

      .addCase(addSpecialRateToRemove, (state, action: any) => {
        const { id, specialRateType, date } = action.payload;
        state.specialRatesToRemove.push({
          id: id,
          type: specialRateType,
          date: date,
          removeSpecialRate: {
            fetching: false,
            response: {},
          },
        });
      })

      .addCase(deleteSpecialRateToRemove, (state, action: any) => {
        const id = action.payload;
        state.specialRatesToRemove = state.specialRatesToRemove.filter(
          (s: any) => id !== s.id
        );
      })

      // Locations

      .addCase(addLocation, (state) => {
        state.locations.push(newLocation);
      })

      .addCase(removeLocation, (state, action) => {
        const index = action.payload;
        state.locations = [
          ...state.locations.slice(0, index),
          ...state.locations.slice(index + 1),
        ];
        const filteredConfigs: any[] = removeConfigForLocationIndex(
          state.configurations,
          index
        );
        state.configurations = filteredConfigs.map(
          ({ locationIndex, ...rest }) => ({
            locationIndex:
              locationIndex > index ? --locationIndex : locationIndex,
            ...rest,
          })
        );
      })

      .addCase(removeProductsForLocation, (state, action) => {
        const index = action.payload;
        state.configurations = removeConfigForLocationIndex(
          state.configurations,
          index
        );
      })

      .addCase(setLocationType, (state, action) => {
        const { index, type } = action.payload;
        state.locations[index].type = type;
        state.configurations = removeConfigForLocationIndex(
          state.configurations,
          index
        );
      })

      .addCase(setLocationAddress, (state, action) => {
        const { index, address, isResign } = action.payload;
        state.locations[index] = {
          ...state.locations[index],
          address: address,
          lineAvailability: {
            ...state.locations[index].lineAvailability,
            response: {},
          },
          installationDetails: {
            ...state.locations[index].installationDetails,
            response: {},
          },
          broadbandSearch: {
            ...state.locations[index].broadbandSearch,
            response: {},
          },
        };
        if (!isResign) {
          state.configurations = removeConfigForLocationIndex(
            state.configurations,
            index
          );
        }
      })

      .addCase(setLocationCli, (state, action) => {
        const { index, cli } = action.payload;
        state.locations[index].cli.value = cli.replace(/\s+/g, "");
        state.configurations = removeConfigForLocationIndex(
          state.configurations,
          index
        );
      })

      .addCase(addResignLocation, (state, action) => {
        const { address, pin } = action.payload;
        state.locations.push({
          ...newLocation,
          type: RESIGN,
          address: mapToLocationAddress(address || {}),
          cli: { ...newLocation.cli, value: pin },
        });
      })

      .addCase(removeResignLocation, (state, action) => {
        const index = action.payload;
        state.locations = [
          ...state.locations.slice(0, index),
          ...state.locations.slice(index + 1),
        ];
        state.configurations = removeConfigForLocationIndex(
          state.configurations,
          index
        );
      })

      .addCase(toggleSogeaTermsConfirmation, (state, action) => {
        const locationIndex = action.payload;
        const location = state.locations[locationIndex];
        location.sogeaTermsAccepted = !location.sogeaTermsAccepted;
      })

      .addCase(fetchLineAvailability.pending, (state, action) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].lineAvailability.fetching = true;
      })

      .addCase(fetchLineAvailability.rejected, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].lineAvailability.fetching = false;
        state.locations[locationIndex].lineAvailability.response =
          action.payload;
      })

      .addCase(fetchLineAvailability.fulfilled, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].lineAvailability.fetching = false;
        state.locations[locationIndex].lineAvailability.response =
          action.payload;
      })

      .addCase(fetchInstallationDetails.pending, (state, action) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].installationDetails.fetching = true;
      })

      .addCase(fetchInstallationDetails.rejected, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].installationDetails.fetching = false;
        state.locations[locationIndex].installationDetails.response =
          action.payload;
      })

      .addCase(fetchInstallationDetails.fulfilled, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].installationDetails.fetching = false;
        state.locations[locationIndex].installationDetails.response =
          action.payload;
      })

      .addCase(validateWorkingLineTakeOver.pending, (state, action) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].wltoDetails.fetching = true;
      })

      .addCase(validateWorkingLineTakeOver.rejected, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].wltoDetails.fetching = false;
      })

      .addCase(validateWorkingLineTakeOver.fulfilled, (state, action: any) => {
        const locationIndex = action.meta.arg as unknown as number;
        state.locations[locationIndex].wltoDetails.fetching = false;
        state.locations[locationIndex].wltoDetails.response = action.payload;
      })

      .addCase(doBroadbandSearch.pending, (state, action) => {
        const { locationIndex } = action.meta.arg as { locationIndex: number };
        state.locations[locationIndex].broadbandSearch = { fetching: true };
      })

      .addCase(doBroadbandSearch.rejected, (state, action) => {
        const { locationIndex } = action.meta.arg as { locationIndex: number };
        state.locations[locationIndex].broadbandSearch = { fetching: false };
      })

      .addCase(doBroadbandSearch.fulfilled, (state, action: any) => {
        const { locationIndex } = action.meta.arg as { locationIndex: number };
        state.locations[locationIndex].broadbandSearch.fetching = false;
        state.locations[locationIndex].broadbandSearch.response =
          action.payload;
      })

      .addCase(fetchAvailableNumbers.pending, (state, action) => {
        const locationIndex = action.meta.arg as number;
        state.locations[locationIndex].numbers.fetching = true;
      })

      .addCase(fetchAvailableNumbers.fulfilled, (state, action: any) => {
        const locationIndex = action.meta.arg as number;
        state.locations[locationIndex].numbers.fetching = false;
        state.locations[locationIndex].numbers.response = action.payload;
      })

      .addCase(fetchTagsCheck.pending, (state, action) => {
        const pin = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === pin
        );
        state.locations[locationIndex].tagsCheck.fetching = true;
      })

      .addCase(fetchTagsCheck.rejected, (state, action: any) => {
        const pin = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === pin
        );
        state.locations[locationIndex].tagsCheck.fetching = false;
        state.locations[locationIndex].tagsCheck.response = action.payload;
      })

      .addCase(fetchTagsCheck.fulfilled, (state, action: any) => {
        const pin = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === pin
        );
        state.locations[locationIndex].tagsCheck.fetching = false;
        state.locations[locationIndex].tagsCheck.response = action.payload;
      })

      // Resigns

      .addCase(setResignType.fulfilled, (state, action) => {
        const { productInstances, resignProductType, canAccessCommission } =
          action.payload;
        const locations = state.locations;
        productInstances.forEach((productInstance: any) => {
          const locationIndex = locations.findIndex(
            (location) => location.cli.value === productInstance.pin
          );
          state.configurations.push({
            productInstanceId: productInstance.id,
            [`${productInstance.type}ProductInstanceId`]: productInstance.id,
            resignProductType: resignProductType,
            pin: productInstance.pin,
            type: productInstance.type,
            locationIndex,
            ...(canAccessCommission && {
              commission_type:
                state.contractLength === 1 ? "Residual" : "Upfront",
            }),
            ...((resignProductType === SAME_PRODUCT_WITH_CHANGES ||
              resignProductType === NEW_PRODUCT) && {
              updateProductInstance: {
                fetching: false,
                response: {},
              },
            }),
          });
        });
      })

      .addCase(removeResign, (state, action) => {
        const { productInstanceIds } = action.payload;
        state.configurations = state.configurations.filter(
          (c) => !productInstanceIds.includes(c.productInstanceId)
        );
      })

      .addCase(setWlrResignProduct.fulfilled, (state, action) => {
        const configs = state.configurations;
        const {
          productInstance,
          resignProductId,
          resignProductType,
          canAccessCommission,
        } = action.payload;
        const productInstanceId = productInstance.id;
        const locationIndex = state.locations.findIndex(
          (location) => location.cli.value === productInstance.pin
        );
        const index = configs.findIndex(
          (c) => c.productInstanceId === productInstanceId
        );
        if (index > -1) {
          configs[index] = {
            ...configs[index],
            resignProductType,
            ...newConfiguration(
              {
                productInstance,
                resignProductId,
                resignProductType,
                locationIndex,
                productInstanceId,
                canAccessCommission,
              },
              state
            ),
          };
        } else {
          configs.push({
            productInstanceId,
            resignProductType,
            ...newConfiguration(
              {
                productInstance,
                resignProductId,
                resignProductType,
                locationIndex,
                productInstanceId,
                canAccessCommission,
              },
              state
            ),
          });
        }
      })

      .addCase(setBroadbandResignProduct.fulfilled, (state, action) => {
        const configs = state.configurations;
        const { productInstance, resignProductId, type } = action.payload;
        const productInstanceId = productInstance.id;
        const locationIndex = state.locations.findIndex(
          (location) => location.cli.value === productInstance.pin
        );
        const configurationIndex = configs.findIndex(
          (c) => c.pin === productInstance.pin && c.type === type
        );
        state.configurations[configurationIndex] = newBroadbandProduct(
          {
            locationIndex,
            configurationIndex,
            productInstance,
            productInstanceId,
            resignProductId,
            type,
          },
          state
        );
      })

      .addCase(fetchLineAvailabilityForResign.pending, (state, action) => {
        const productInstance = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === productInstance.pin
        );
        state.locations[locationIndex].lineAvailability.fetching = true;
      })

      .addCase(
        fetchLineAvailabilityForResign.rejected,
        (state, action: any) => {
          const productInstance = action.meta.arg as any;
          const locationIndex = state.locations.findIndex(
            (l: any) => l.cli.value === productInstance.pin
          );
          state.locations[locationIndex].lineAvailability.fetching = false;
          state.locations[locationIndex].lineAvailability.response =
            action.payload;
        }
      )

      .addCase(
        fetchLineAvailabilityForResign.fulfilled,
        (state, action: any) => {
          const productInstance = action.meta.arg as any;
          const locationIndex = state.locations.findIndex(
            (l: any) => l.cli.value === productInstance.pin
          );
          state.locations[locationIndex].lineAvailability.fetching = false;
          state.locations[locationIndex].lineAvailability.response =
            action.payload;
        }
      )

      .addCase(doBroadbandSearchForResign.pending, (state, action) => {
        const productInstance = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === productInstance.pin
        );
        state.locations[locationIndex].broadbandSearch.fetching = true;
      })

      .addCase(doBroadbandSearchForResign.rejected, (state, action: any) => {
        const productInstance = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === productInstance.pin
        );
        state.locations[locationIndex].broadbandSearch.fetching = false;
      })

      .addCase(doBroadbandSearchForResign.fulfilled, (state, action: any) => {
        const productInstance = action.meta.arg as any;
        const locationIndex = state.locations.findIndex(
          (l: any) => l.cli.value === productInstance.pin
        );
        state.locations[locationIndex].broadbandSearch.fetching = false;
        state.locations[locationIndex].broadbandSearch.response =
          action.payload;
      })

      .addCase(setResignStartDate, (state, action: any) => {
        state.resignStartDate = action.payload;
      })

      .addCase(fetchLineProductInstances.pending, (state) => {
        state.lineProductInstances.fetching = true;
      })

      .addCase(fetchLineProductInstances.rejected, (state) => {
        state.lineProductInstances.fetching = false;
      })

      .addCase(fetchLineProductInstances.fulfilled, (state, action: any) => {
        const response = action.payload;
        const results = state.lineProductInstances.response.results;
        state.lineProductInstances.fetching = false;
        if (results && response.status === "success") {
          results.push(...response.results);
        } else {
          state.lineProductInstances.response = response;
        }
      })

      .addCase(fetchBroadbandProductInstances.pending, (state) => {
        state.broadbandProductInstances.fetching = true;
      })

      .addCase(fetchBroadbandProductInstances.rejected, (state) => {
        state.broadbandProductInstances.fetching = false;
      })

      .addCase(
        fetchBroadbandProductInstances.fulfilled,
        (state, action: any) => {
          const response = action.payload;
          const results = state.broadbandProductInstances.response.results;
          state.broadbandProductInstances.fetching = false;
          if (results && response.status === "success") {
            results.push(...response.results);
          } else {
            state.broadbandProductInstances.response = response;
          }
        }
      )

      .addCase(fetchResignProductSearch.pending, (state) => {
        state.resignProductSearch.fetching = true;
      })

      .addCase(fetchResignProductSearch.rejected, (state) => {
        state.resignProductSearch.fetching = false;
      })

      .addCase(fetchResignProductSearch.fulfilled, (state, action: any) => {
        state.resignProductSearch.fetching = false;
        state.resignProductSearch.response = action.payload;
      })

      .addCase(fetchLineResignProductSearch.pending, (state) => {
        state.lineResignProductSearch.fetching = true;
      })

      .addCase(fetchLineResignProductSearch.rejected, (state) => {
        state.lineResignProductSearch.fetching = true;
      })

      .addCase(fetchLineResignProductSearch.fulfilled, (state, action: any) => {
        state.lineResignProductSearch.fetching = false;
        state.lineResignProductSearch.response = action.payload;
      })

      .addCase(fetchBroadbandResignProductSearch.pending, (state) => {
        state.broadbandResignProductSearch.fetching = true;
      })

      .addCase(fetchBroadbandResignProductSearch.rejected, (state) => {
        state.broadbandResignProductSearch.fetching = true;
      })

      .addCase(
        fetchBroadbandResignProductSearch.fulfilled,
        (state, action: any) => {
          state.broadbandResignProductSearch.fetching = false;
          state.broadbandResignProductSearch.response = action.payload;
        }
      )

      // Configurations

      .addCase(setBroadbandProduct, (state, action) => {
        const configIndex = action.payload.configurationIndex;
        state.configurations[configIndex] = newBroadbandProduct(
          action.payload,
          state
        );
      })

      .addCase(setProductProperty, (state, action) => {
        const { targetConfigs, productType, propertyName, value } =
          action.payload;
        targetConfigs.forEach((i: any) => {
          let config = state.configurations[i];
          switch (propertyName) {
            case "commission_type":
              config.commission_type = value;
              break;
            case "bb.contact.contactForename":
              config.broadbandProperties = {
                ...config.broadbandProperties,
                "bb.contact.contactForename": value,
                "bb.appointment.contact.contactForename": value,
                "router.first_name": value,
              };
              config.wlrProperties = {
                ...config.wlrProperties,
                contact_name: `${value} ${config.broadbandProperties["bb.contact.contactSurname"]}`,
                end_user_name_for_999: `${value} ${config.broadbandProperties["bb.contact.contactSurname"]}`,
              };
              break;
            case "bb.contact.contactSurname":
              config.broadbandProperties = {
                ...config.broadbandProperties,
                "bb.contact.contactSurname": value,
                "bb.appointment.contact.contactSurname": value,
                "router.last_name": value,
              };
              config.wlrProperties = {
                ...config.wlrProperties,
                contact_name: `${config.broadbandProperties["bb.contact.contactForename"]} ${value}`,
                end_user_name_for_999: `${config.broadbandProperties["bb.contact.contactForename"]} ${value}`,
              };
              break;
            case "contact_name":
              config.wlrProperties = {
                ...config.wlrProperties,
                contact_name: value,
                end_user_name_for_999: value,
              };
              break;
            case "bb.contact.contactTelephone":
              config.broadbandProperties = {
                ...config.broadbandProperties,
                "bb.contact.contactTelephone": value,
                "bb.appointment.contact.contactTelephone": value,
                "router.telephone": value,
              };
              config.wlrProperties = {
                ...config.wlrProperties,
                contact_telephone: value,
              };
              break;
            case "bb.contact.businessName":
              config.broadbandProperties = {
                ...config.broadbandProperties,
                "bb.contact.businessName": value,
                "bb.businessDirectory.businessName": value,
                "router.company": value,
              };
              config.wlrProperties = {
                ...config.wlrProperties,
                company: value,
              };
              break;
            case "redcare_installation":
              config.wlrProperties.redcare_installation = value;
              if (value === 1) {
                config.broadbandProductId = false;
                config.broadbandProductData.response = {};
              }
              break;
            case "partner_reference_number":
              const hasLine = config.wlrProductId !== undefined;
              const hasBroadband = config.broadbandProductId !== undefined;
              if (hasLine && hasBroadband) {
                config.wlrProperties[propertyName] = value;
                config.broadbandProperties[propertyName] = value;
                break;
              }
              if (hasLine) config.wlrProperties[propertyName] = value;
              else config.broadbandProperties[propertyName] = value;
              break;
            case "bb.ONTType":
              let props: any = {};
              if (value === "EXISTING") {
                existingONTTypeProps.forEach((propertyName) => {
                  const value = _.get(
                    config.broadbandProductData.response,
                    "broadband.dynamic_properties"
                  )[propertyName].current_value;
                  // Update order type if needed
                  if (propertyName === "bb.ontPortStatus") {
                    props["bb.order_type"] = `FTTP ${
                      value === "Working" ? "Migrate" : "Provide"
                    }`;
                  }
                  props[propertyName] = value;
                });
                // Clear SVR when Existing is selected.
                props["bb.site_visit_reason"] = "NO_SITE_VISIT";
              }

              if (value === "NEW") {
                props["bb.ontPortNumber"] = undefined;
                props["bb.ontPortStatus"] = undefined;
                props["bb.ontReference"] = undefined;
                props["bb.ontPortType"] = undefined;
                props["bb.order_type"] = "FTTP Provide";
                props["bb.site_visit_reason"] = "PREMIUM";
              }
              props["bb.ONTType"] = value;
              config.broadbandProperties = props;
              break;
            // For new FTTP upgrades we need to check the ONT type and port status to
            // determine the order type, this will potentially override the initial order type
            // which gets set based on the activation code (always 'Z' - FTTP Provide)
            case "bb.ontPortStatus":
              config.broadbandProperties = {
                ...config.broadbandProperties,
                [propertyName]: value,
                ...(config.broadbandProperties["bb.ONTType"] === "EXISTING" && {
                  "bb.order_type": `FTTP ${
                    value === "Working" ? "Migrate" : "Provide"
                  }`,
                }),
              };
              break;
            case "bb.voice_service":
              if (value === "1") {
                config.broadbandProperties[propertyName] = value;
                config.broadbandProperties["bb.required_by_date"] = addWeekdays(
                  new Date(),
                  15,
                  true
                );
              } else {
                config.broadbandProperties[propertyName] = value;
                config.broadbandProperties["bb.required_by_date"] = _.get(
                  config.broadbandProductData.response,
                  "broadband.dynamic_properties.required_by_date",
                  addWeekdays(new Date(), 9, true)
                );
              }
              // Invalidate list of appointments if voice service changes
              config.broadbandAppointments.response = { appointments: [] };
              config.addBroadbandAppointment.response = {};
              break;
            default:
              if (broadbandToWlrFieldMappings.hasOwnProperty(propertyName)) {
                config.broadbandProperties[propertyName] = value;
                config.wlrProperties[
                  broadbandToWlrFieldMappings[propertyName]
                ] = value;
                break;
              }
              if (
                productType === "broadband" &&
                propertyName.includes("wlr_change") &&
                config.broadbandProductData.initialWlrChangeValues[
                  propertyName
                ] === value
              ) {
                config.broadbandProperties = u.omit(propertyName);
                break;
              }

              config[`${productType}Properties`][propertyName] = value;
              break;
          }
          doRemoteValidation(i);
        });
      })

      .addCase(setPricingScheme, (state, action) => {
        const { targetConfig, productType, schemeName } = action.payload;
        state.configurations[targetConfig][
          `${productType}Properties`
        ].pricing_scheme = schemeName;
      })

      .addCase(setProductDiscount, (state, action) => {
        const { targetConfigs, productType, discountType, priceType, value } =
          action.payload;
        targetConfigs.forEach((i: number) => {
          state.configurations[i][`${productType}Properties`] = {
            ...state.configurations[i][`${productType}Properties`],
            ...(discountType !== null && {
              [`${priceType}_discount_type`]: discountType,
            }),
            [`${priceType}_discount_override`]: value === false ? 0 : 1,
            [`${priceType}_discount_value`]: value,
          };
        });
      })

      .addCase(removeProductDiscount, (state, action) => {
        const { targetConfigs, productType, propertyNamePrefix } =
          action.payload;
        targetConfigs.forEach((i: number) => {
          state.configurations[i][`${productType}Properties`] = u.omit([
            `${propertyNamePrefix}_discount_override`,
            `${propertyNamePrefix}_discount_type`,
            `${propertyNamePrefix}_discount_value`,
          ]);
        });
      })

      .addCase(validateProductProperty, (state, action) => {
        const { targetConfigs, productType, propertyName, dynamicProperty } =
          action.payload;
        targetConfigs.forEach((i: number) => {
          let message: string | boolean = false;
          const value =
            state.configurations[i][`${productType}Properties`][propertyName];

          if (dynamicProperty.is_required && !value) message = "Required";
          if (dynamicProperty.propertyName === "bb.voice_service" && !value) {
            message = "Required";
          }
          if (
            dynamicProperty.name.toLowerCase().indexOf("email") > -1 &&
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) // eslint-disable-line no-useless-escape
          ) {
            message = "Must be valid email address";
          }
          if (
            dynamicProperty.name.toLowerCase().indexOf("phone") > -1 &&
            // eslint-disable-next-line no-useless-escape
            !/^\(?(?:(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?\(?(?:0\)?[\s-]?\(?)?|0)(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}|\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4}|\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3})|\d{5}\)?[\s-]?\d{4,5}|8(?:00[\s-]?11[\s-]?11|45[\s-]?46[\s-]?4\d))(?:(?:[\s-]?(?:x|ext\.?\s?|\#)\d+)?)$/.test(
              value
            )
          ) {
            message = "Must be a valid UK phone number";
          }
          if (
            dynamicProperty.name
              .toLowerCase()
              .indexOf("partner_reference_number") > -1 &&
            typeof value === "string"
          ) {
            if (value?.length > 10) {
              message =
                "Unique reference number must be no more than 10 characters.";
            }
            if (/^0*$/.test(value)) {
              message = "Unique reference number cannot be 0.";
            }
            if (/[^0-9]/g.test(value)) {
              message =
                "Unique reference number only accepts numerical values.";
            }
          }
          state.configurations[i].validation[dynamicProperty.name] = message;
        });
      })

      .addCase(addWlrConfiguration, (state, action) => {
        state.configurations.push(newConfiguration(action.payload, state));
      })

      .addCase(removeWlrConfiguration, (state, action) => {
        const index = action.payload;
        state.configurations = [
          ...state.configurations.slice(0, index),
          ...state.configurations.slice(index + 1),
        ];
      })

      .addCase(setConfiguration, (state, action) => {
        state.configurations = action.payload;
      })

      .addCase(fetchProductData.pending, (state, action) => {
        const { configurationIndex, productType } = action.meta.arg as any;
        const config = state.configurations[configurationIndex];
        const productData = config[productType + "ProductData"];
        productData.fetching = true;
      })

      .addCase(fetchProductData.rejected, (state, action) => {
        const { configurationIndex, productType } = action.meta.arg as any;
        const response: any = action.payload;
        const config = state.configurations[configurationIndex];
        const productData = config[productType + "ProductData"];
        productData.fetching = false;
        if (response?.status === "error") {
          productData.response = {
            status: response.status,
            message: response.message,
            code: response.code,
          };
        }
      })

      .addCase(fetchProductData.fulfilled, (state, action) => {
        const { configurationIndex, productType } = action.meta.arg as any;
        const response: any = action.payload;
        const config = state.configurations[configurationIndex];
        const productData = config[productType + "ProductData"];
        productData.fetching = false;
        if (response?.status === "error") {
          productData.response = {
            status: response.status,
            message: response.message,
            code: response.code,
          };
        } else {
          productData.response = response;
          if (
            productType === "broadband" &&
            _.isEmpty(config.broadbandProductData.initialWlrChangeValues)
          ) {
            const properties = _.get(
              response,
              "broadband.dynamic_properties",
              {}
            );
            const res: any = {};
            for (let k in properties) {
              if (k.includes("wlr_change"))
                res[k] = properties[k].current_value;
            }
            productData.initialWlrChangeValues = res;
          }
        }
      })

      .addCase(setPropertiesFromProductData, (state, action) => {
        const { configurationIndex, productType, response } = action.payload;
        if (productType !== "broadband") return;
        const config = state.configurations[configurationIndex];
        const dynamic_properties = _.get(
          response,
          "broadband.dynamic_properties"
        );
        if (dynamic_properties) {
          config.broadbandProductData.response = response;
          config.broadbandProperties = {
            ...config.broadbandProperties,
            "bb.contact.title":
              response.broadband.dynamic_properties["bb.contact.title"]
                .default_value,
            "radius.radius_realm_id": (() => {
              const realms =
                response.broadband.dynamic_properties["radius.radius_realm_id"]
                  .available_option_map;
              if (realms) {
                return (
                  Object.keys(realms).find((realmId) =>
                    realms[realmId].includes("@daisybb.co.uk")
                  ) || Object.keys(realms)[0]
                );
              } else {
                return undefined;
              }
            })(),
          };
        } else {
          config.broadbandProductData.fetching = false;
          config.broadbandProductData.fetching = response;
        }
      })

      .addCase(fetchBroadbandAppointments.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[
          configurationIndex
        ].broadbandAppointments.fetching = true;
      })

      .addCase(fetchBroadbandAppointments.rejected, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[
          configurationIndex
        ].broadbandAppointments.fetching = false;
      })

      .addCase(fetchBroadbandAppointments.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[
          configurationIndex
        ].broadbandAppointments.fetching = false;
        state.configurations[
          configurationIndex
        ].broadbandAppointments.response = action.payload;
      })

      .addCase(setBroadbandAppointment.fulfilled, (state, action) => {
        const { configurationIndex, selectedIndex, isSalesPerson } =
          action.payload;
        if (selectedIndex === null) return;

        const config = state.configurations[configurationIndex];
        config.broadbandAppointments.selectedIndex = selectedIndex;

        if (isSalesPerson) {
          const bbExpiryDate = new Date().setDate(new Date().getDate() + 1);
          const bbAppointment =
            config?.broadbandAppointments?.response?.appointments?.[
              selectedIndex
            ];
          config.broadbandProperties = {
            ...config.broadbandProperties,
            "bb.appointment.appointmentReference": AWAITING,
            "bb.appointment_date": bbAppointment.date,
            "bb.appointment_datetime": bbAppointment.date + " 00:00:00",
            "bb.appointment_timeslot": bbAppointment.timeslot,
            "bb.appointment_expiry": format(bbExpiryDate, DC_DATE_FORMAT),
          };
        }
      })

      .addCase(fetchWlrAppointments.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrAppointments.fetching =
          true;
      })

      .addCase(fetchWlrAppointments.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrAppointments.fetching =
          false;
        state.configurations[configurationIndex].wlrAppointments.response =
          action.payload;
      })

      .addCase(setWlrAppointment, (state, action) => {
        const { configurationIndex, selectedIndex, isSalesPerson } =
          action.payload;
        if (selectedIndex === null) return;

        const config = state.configurations[configurationIndex];
        config.wlrAppointments.selectedIndex = selectedIndex;
        config.broadbandProperties["bb.required_by_date"] =
          config.wlrAppointments?.response?.appointments?.[selectedIndex]?.date;

        if (isSalesPerson) {
          const wlrExpiryDate = new Date().setDate(new Date().getDate() + 1);
          const wlrAppointment =
            config.wlrAppointments?.response?.appointments?.[selectedIndex];
          config.wlrProperties = {
            ...config.wlrProperties,
            appointment_reference: AWAITING,
            appointment_date: wlrAppointment.date,
            appointment_timeslot: wlrAppointment.timeslot,
            appointment_expiry: format(wlrExpiryDate, DC_DATE_FORMAT),
          };
        }
      })

      .addCase(setNumberReservationType, (state, action) => {
        const { configurationIndex, reservationType } = action.payload;
        state.configurations[configurationIndex].numberReservation.type =
          reservationType;
      })

      .addCase(setReservedNumber, (state, action) => {
        const { configurationIndex, number } = action.payload;
        state.configurations[
          configurationIndex
        ].numberReservation.selectedNumber = number;
      })

      .addCase(doNumberReservation.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].numberReservation.fetching =
          true;
      })

      .addCase(doNumberReservation.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        const response = action.payload;
        const config = state.configurations[configurationIndex];
        config.numberReservation.fetching = false;
        config.numberReservation.response = response;

        if (response.status === "success") {
          config.wlrProperties = {
            ...config.wlrProperties,
            reserved_number: response.reserved_number,
            reserved_number_expiry: response.reservation_expiry,
            reserved_number_reference: response.reservation_reference,
          };
        }
      })

      .addCase(doAddWlrAppointment.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].addWlrAppointment.fetching =
          true;
      })

      .addCase(doAddWlrAppointment.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        const response = action.payload;
        const config = state.configurations[configurationIndex];
        config.addWlrAppointment.fetching = false;
        config.addWlrAppointment.response = response;

        if (response.status === "success") {
          const appointment =
            config.wlrAppointments.response.appointments[
              config.wlrAppointments.selectedIndex
            ];
          config.wlrProperties = {
            ...config.wlrProperties,
            appointment_date: appointment.date,
            appointment_expiry: response.appointment_expiry,
            appointment_reference: response.appointment_reference,
            appointment_timeslot: appointment.timeslot,
          };
        }
      })

      .addCase(doAddBroadbandAppointment.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[
          configurationIndex
        ].addBroadbandAppointment.fetching = true;
      })

      .addCase(doAddBroadbandAppointment.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        const { response, appointment } = action.payload;
        const config = state.configurations[configurationIndex];
        config.addBroadbandAppointment.fetching = false;
        config.addBroadbandAppointment.response = response;

        if (response.status === "success") {
          config.broadbandProperties = {
            ...config.broadbandProperties,
            "bb.appointment_expiry": response.appointment_expiry,
            "bb.appointment.appointmentReference":
              response.appointment_reference,
            "bb.appointment_date": appointment.date,
            "bb.appointment_datetime": appointment.date + " 00:00:00",
            "bb.appointment_timeslot": appointment.timeslot,
          };
        }
      })

      .addCase(validateWlr.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrValidation.fetching = true;
      })

      .addCase(validateWlr.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrValidation.fetching = false;
        state.configurations[configurationIndex].wlrValidation.response =
          action.payload;
      })

      .addCase(validateWlrQuoteOnly.pending, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrValidationQuote.fetching =
          true;
      })

      .addCase(validateWlrQuoteOnly.fulfilled, (state, action) => {
        const configurationIndex = action.meta.arg as number;
        state.configurations[configurationIndex].wlrValidationQuote.fetching =
          false;
        state.configurations[configurationIndex].wlrValidationQuote.response =
          action.payload;
      })

      .addCase(validateBroadband.pending, (state, action) => {
        const { configurationIndex } = action.meta.arg as {
          configurationIndex: number;
        };
        state.configurations[configurationIndex].broadbandValidation.fetching =
          true;
      })

      .addCase(validateBroadband.fulfilled, (state, action) => {
        const { configurationIndex } = action.meta.arg as {
          configurationIndex: number;
        };
        state.configurations[configurationIndex].broadbandValidation.fetching =
          false;
        state.configurations[configurationIndex].broadbandValidation.response =
          action.payload;
      })

      .addCase(executeOrderProduct.pending, (state, action) => {
        const { configurationIndex, isUpdate, productType } = action.meta
          .arg as {
          configurationIndex: number;
          isUpdate: boolean;
          productType: string;
        };
        const config = state.configurations[configurationIndex];
        if (!productType) return;
        config.orderProduct = {
          ...config.orderProduct,
          [productType]: {
            fetching: true,
            isUpdate: isUpdate,
            response: {},
          },
        };
      })

      .addCase(executeOrderProduct.fulfilled, (state, action) => {
        const { configurationIndex, productType } = action.meta.arg as {
          configurationIndex: number;
          productType: string;
        };
        const config = state.configurations[configurationIndex];
        const response = action.payload;
        if (!productType) return;
        if (response.status !== "success") {
          const orderProductId = _.get(response, "message.order_product_id");
          if (orderProductId) response.data = { id: orderProductId };
          if (typeof response.message !== "string") {
            response.message = _.get(response.message, "message[0].message");
          }
        }
        config.orderProduct[productType].fetching = false;
        config.orderProduct[productType].response = response;
      })

      .addCase(doUpdateProductInstance.pending, (state, action) => {
        const { configurationIndex } = action.meta.arg as any;
        const config = state.configurations[configurationIndex];
        config.updateProductInstance.fetching = true;
      })

      .addCase(doUpdateProductInstance.rejected, (state, action) => {
        const { configurationIndex } = action.meta.arg as any;
        const config = state.configurations[configurationIndex];
        config.updateProductInstance.fetching = false;
        config.updateProductInstance.response = action.payload;
      })

      .addCase(doUpdateProductInstance.fulfilled, (state, action) => {
        const { configurationIndex } = action.meta.arg as any;
        const config = state.configurations[configurationIndex];
        config.updateProductInstance.fetching = false;
        config.updateProductInstance.response = action.payload;
      });
  },
});

export default wlrBroadband.reducer;
