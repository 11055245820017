import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  getDynamicPropertyValue,
  makeGetDynamicPropertyInstances,
  makeGetDynamicPropertyValues,
} from "../../../../../../../store/wlrBroadband/selectors";
import {
  doRemoteValidation,
  fetchProductData,
  setProductProperty,
  validateProductProperty,
} from "../../../../../../../store/wlrBroadband/actions/configurations";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { SelectRouter } from "../../../../../components/wlrBroadband/SelectRouter";
import { SelectChangeEvent } from "@mui/material/Select";

interface SelectRouterContainerProps {
  propertyName: string;
  productType: string;
}

export const SelectRouterContainer = (props: SelectRouterContainerProps) => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();
  const dynamicPropertyInstances = useSelector((state) =>
    // @ts-ignore
    makeGetDynamicPropertyInstances()(state, {
      ...props,
      targetConfigs,
    })
  );

  const propertyValues = useSelector((state) =>
    // @ts-ignore
    makeGetDynamicPropertyValues()(state, { ...props, targetConfigs })
  );

  const propertyValidation = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfigs[0]].validation[
        props.propertyName
      ]
  );
  const siteVisitReason = useSelector((state) =>
    getDynamicPropertyValue(
      state,
      "broadband",
      targetConfigs[0],
      "bb.site_visit_reason"
    )
  );

  const dynamicProperty = dynamicPropertyInstances[0];
  const propertyValue = propertyValues[0];

  // Available router options are dependant on the site visit reason.
  const availableOptionDetails = _.filter(
    dynamicProperty.available_option_details,
    (option) => {
      if (
        siteVisitReason === "NO_SITE_VISIT" &&
        option.install_type === "Managed"
      )
        return false;
      if (
        (siteVisitReason === "STANDARD" || siteVisitReason === "PREMIUM") &&
        option.install_type === "Self"
      )
        return false;
      return true;
    }
  );

  const onRouterChange = (event: SelectChangeEvent<string>) => {
    dispatch(
      setProductProperty(
        targetConfigs,
        "broadband",
        "router_id",
        event.target.value
      )
    );
    targetConfigs.forEach((config) => {
      dispatch(
        fetchProductData({
          configurationIndex: config,
          productType: "broadband",
        })
      );
    });
    dispatch(
      validateProductProperty(
        targetConfigs,
        "broadband",
        "router_id",
        dynamicProperty
      )
    );
    targetConfigs.forEach((config) => {
      dispatch(doRemoteValidation({ configurationIndex: config }));
    });
  };

  return (
    <SelectRouter
      availableOptionDetails={availableOptionDetails}
      dynamicProperty={dynamicProperty}
      propertyValidation={propertyValidation}
      propertyValue={propertyValue}
      onRouterChange={onRouterChange}
    />
  );
};
