import React from "react";

import {
  // useDispatch,
  useSelector,
} from "react-redux";

import { PricingRequestTaskView } from "../../components/CommercialPricingRequest/PricingRequestTaskView";
// import { getPricingRequest } from "../../../store/order/actions";

export const PricingRequestTaskViewContainer = () => {
  // const dispatch = useDispatch();
  const task = useSelector(
    (state: any) => state.order.pricingRequest.response.task
  );

  /**
   * Note: refresh button disabled for now following discussions with Toby Young and Dave Thomas.
   *
   * Current DC API cannot provide a view of completed pricing requests, only new & in progress, so there's no way for
   * the UI here to show them to the user. Hence we're relying on the email DC sends for that, and subsequent refresh of
   * the order summary prices.
   *
   * I suggested adopting a standard CRUD pattern for pricing requests, but apparently there's no time to do the DC
   * code for that.
   *
   * So for now, a completed pricing request just means the user sees "Add Pricing Request for Commercial" again.
   * Not great.
   *
   * TP1187
   */

  // const fetching = useSelector(
  //   (state: any) => state.order.pricingRequest.fetching
  // );

  return (
    <PricingRequestTaskView
      task={task}
      // loadPricingRequest={() => dispatch(getPricingRequest())}
    />
  );
};
