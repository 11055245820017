import { SupportCaseState } from "./types";

const initialState: SupportCaseState = {
  open: false,
  loading: false,
  screenshot: "",
  invalid: false,
  category: "Enquiry",
  system: "Affinity Sales",
  short_description: "",
  description: "",
  type_id: 1,
  caseDetails: {},
};

export default initialState;
