import { DisplayError } from "../types";
import { DetailActionTypes, SET_ERROR, REQUEST_DATA, SET_DATA } from "./types";
import { ViewAPIResponse } from "../../api/v1/view";

export function setError(errorForDisplay: DisplayError): DetailActionTypes {
  return {
    type: SET_ERROR,
    payload: errorForDisplay,
  };
}

export function requestViewDataById(id: string | number): DetailActionTypes {
  return {
    type: REQUEST_DATA,
    payload: id,
  };
}

export function setData(data: ViewAPIResponse): DetailActionTypes {
  return {
    type: SET_DATA,
    payload: data,
  };
}
