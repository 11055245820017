import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as WlrBroadbandAPI from "../../../api/v1/wlrBroadband";
import * as ProductAPI from "../../../api/v1/product";
import { getAccountId } from "../../order/selectors";

export const setContractLength = createAction<number>(
  "wlrBroadband/setContractLength"
);

export const doLineSearch = createAsyncThunk(
  "wlrBroadband/doLineSearch",
  async (arg, { getState }) => {
    const state: any = getState();
    return await WlrBroadbandAPI.lineSearch(state.wlrBroadband.contractLength);
  }
);

export const removeSpecialRate = createAsyncThunk(
  "wlrBroadband/removeSpecialRate",
  async (specialRate: any, { getState }) => {
    const state: any = getState();
    const { id, type, date } = specialRate;
    const accountId = getAccountId(state);
    return await ProductAPI.RemoveSpecialRate({
      accountId,
      id,
      type,
      date,
    });
  }
);

export const addSpecialRateToRemove = createAction(
  "wlrBroadband/addSpecialRateToRemove",
  (id, specialRateType, date) => ({
    payload: { id, specialRateType, date },
  })
);

export const deleteSpecialRateToRemove = createAction<number | string>(
  "wlrBroadband/deleteSpecialRateToRemove"
);
