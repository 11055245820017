import React from "react";
import { Provider } from "react-redux";
import createStore from "./store";

function StoreProvider({ children }) {
  const store = createStore({});
  return <Provider store={store}>{children}</Provider>;
}

export default StoreProvider;
