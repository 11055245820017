import React from "react";
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { NoteConstants } from "../enums";

interface Props {
  content: string;
  onChange: (content: string) => void;
  onSubmit: () => void;
  submitting: boolean;
}

export const FormAddComment = ({
  content,
  onSubmit,
  onChange,
  submitting,
}: Props) => {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const error = content.length > NoteConstants.newNoteLimit;
  const disabled = content.length < 1 || error || submitting;

  return (
    <Box mb={2} sx={styles.root}>
      <form onSubmit={handleSubmit}>
        <Card raised sx={styles.card}>
          <CardContent>
            <TextField
              variant="outlined"
              id="add-comment-to-order"
              label="Your comment (max 3000 characters)"
              multiline
              rows={4}
              value={content}
              onChange={handleChange}
              error={error}
              helperText={
                content.length > NoteConstants.newNoteLimit - 600 &&
                `${content.length} / ${NoteConstants.newNoteLimit}`
              }
            />
            <CardActions sx={styles.cardActions}>
              <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                type="submit"
              >
                {submitting ? "Saving..." : "Save Comment"}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};

const styles = {
  root: {
    "& form": {
      "& .MuiTextField-root": {
        width: "100%",
      },
      "& .MuiFormHelperText-root": {
        textAlign: "end",
      },
    },
  },
  card: {
    padding: 0.5,
  },
  cardActions: {
    padding: 0,
    paddingTop: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
};
