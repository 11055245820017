import React from "react";
import Icon from "@mui/icons-material/Security";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

export const RadiusConfiguration = () => (
  <Section
    title="Radius Configuration"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.radius_username"
      margin
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.radius_password"
      margin
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.radius_realm_id"
      margin
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.routed_ip_quantity"
      margin
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.wan_ip_type"
      margin
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="radius.wan_ipv6_type"
      margin
    />
  </Section>
);
