import React, { Component, useContext } from "react";

type TargetConfigsContextValue = {
  targetConfigs: number[];
  setTargetConfigs: (targetConfigs: number[]) => void;
};

export const TargetConfigsContext =
  React.createContext<TargetConfigsContextValue>({
    targetConfigs: [],
    setTargetConfigs: () => {},
  });

export class TargetConfigsProvider extends Component {
  state = {
    targetConfigs: [],
  };

  setTargetConfigs = (targetConfigs: number[]) =>
    this.setState((prevState) => ({ ...prevState, targetConfigs }));

  render() {
    const { children } = this.props;
    const { targetConfigs } = this.state;
    const { setTargetConfigs } = this;

    return (
      <TargetConfigsContext.Provider
        value={{
          targetConfigs,
          setTargetConfigs,
        }}
      >
        {children}
      </TargetConfigsContext.Provider>
    );
  }
}

export const TargetConfigsConsumer = TargetConfigsContext.Consumer;

export const useTargetConfigsContext = (): TargetConfigsContextValue =>
  useContext(TargetConfigsContext);
