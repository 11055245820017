import React, { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { useDispatch } from "react-redux";
import { Products } from "./Products";
import { fetchAvailableHardwareCredit } from "../../../../store/account/actions";
import { CreditWarning } from "./CreditWarning";

export const Hardware = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 2 Hardware mounted`,
      category: "action",
    });

    dispatch(fetchAvailableHardwareCredit());
  }, [dispatch]);

  return (
    <>
      <Products />
      <CreditWarning />
    </>
  );
};
