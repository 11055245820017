import React from "react";

import { WlrLocationTypeEnum } from "../../enums";
import { ChooseLineProductsContainer } from "../ChooseLineProducts";
import { ChooseSingleProductContainer } from "../ChooseSingleProductContainer";
import { SogeaTermsContainer } from "../SogeaTerms";
import { useDwsResellerEnabled } from "../../../../../shared/hooks/useDwsResellerEnabled";

interface ChooseProductTypeContainerProps {
  locationIndex: number;
  locationType: string;
}

export const ChooseProductTypeContainer = ({
  locationIndex,
  locationType,
}: ChooseProductTypeContainerProps) => {
  const isDwsResellerEnabled = useDwsResellerEnabled();

  const showSogeaTerm =
    isDwsResellerEnabled &&
    (locationType === WlrLocationTypeEnum.NEW_SOGEA ||
      locationType === WlrLocationTypeEnum.EXISTING_SOGEA);

  return (
    <>
      {locationType === WlrLocationTypeEnum.NEW_FTTP ? (
        <ChooseSingleProductContainer
          locationIndex={locationIndex}
          productType={WlrLocationTypeEnum.NEW_FTTP}
        />
      ) : locationType === WlrLocationTypeEnum.NEW_SOGEA ? (
        <ChooseSingleProductContainer
          locationIndex={locationIndex}
          productType={WlrLocationTypeEnum.NEW_SOGEA}
        />
      ) : (
        <ChooseLineProductsContainer locationIndex={locationIndex} />
      )}
      {showSogeaTerm && <SogeaTermsContainer locationIndex={locationIndex} />}
    </>
  );
};
