import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  getProductSupplier,
  getResignProductInstanceByConfig,
} from "../../../../../../store/mobile/selectors/resigns";
import { canKeepSim } from "../../../../../../store/mobile/selectors/productConfig";
import { getAccountSettings } from "../../../../../../store/account/selectors";
import { SimCard } from "../../../../components/Mobile/SimCard";
import { AccountSettings } from "@akj-dev/affinity-platform";

interface SimCardProps {
  targetConfigs: number[];
}

export const SimCardContainer = ({ targetConfigs }: SimCardProps) => {
  const resignProductInstance = useSelector((state) =>
    getResignProductInstanceByConfig(state, targetConfigs[0])
  );

  const firstConfig = useSelector<any, Record<string, any>>(
    (state) => state.mobile.configs[targetConfigs[0]]
  );

  const settings = useSelector<any, AccountSettings>((state) =>
    getAccountSettings(state)
  );
  const supplier = useSelector<any, string>((state) =>
    getProductSupplier(state.mobile, firstConfig.productId)
  );

  return (
    <SimCard
      isESIM={firstConfig.properties.sim_type === "esim"}
      canKeepSim={canKeepSim(firstConfig)}
      isMigration={
        firstConfig.pacCodeCheck?.verifiedAcquisitionMethod === "migration"
      }
      simIsBuffer={firstConfig.properties.sim_is_buffer}
      userSelectedAcquisitionMethod={firstConfig.properties.acquisition_method}
      existingNetwork={_.get(resignProductInstance, "network.name", false)}
      supplier={supplier}
      settings={settings}
    />
  );
};
