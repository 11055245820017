import React from "react";
import Box from "@mui/material/Box";
import ContactMailIcon from "@mui/icons-material/ContactMail";

import { SelectContact } from "../../../../shared/containers/SelectContact";

interface LetterNotificationProps {
  notificationContact: string;
  setAccountContact: (id: string) => void;
  error?: string;
}

export const LetterNotification = ({
  notificationContact,
  setAccountContact,
  error,
}: LetterNotificationProps) => (
  <Box display="flex" alignItems="center" mt={1}>
    <Box mr={1}>
      <ContactMailIcon />
    </Box>
    <Box flexGrow={1}>
      <SelectContact
        current={notificationContact}
        handleUpdate={setAccountContact}
        errorText={error}
      />
    </Box>
  </Box>
);
