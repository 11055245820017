import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSpecialRateToRemove,
  deleteSpecialRateToRemove,
} from "../../../../../../store/wlrBroadband/actions/index";
import { DC_DATE_FORMAT } from "../../../../../../shared/utils/date";
import { format, startOfMonth, addMonths } from "date-fns";
import { SpecialRates } from "../../../../components/wlrBroadband/SpecialRates";

interface SpecialRatesContainerProps {
  productInstance: any;
}

export const SpecialRatesContainer = ({
  productInstance,
}: SpecialRatesContainerProps) => {
  const dispatch = useDispatch();

  const resignStartDate = useSelector(
    (state: any) => state.wlrBroadband.resignStartDate
  );
  const specialRatesToRemove = useSelector(
    (state: any) => state.wlrBroadband.specialRatesToRemove
  );

  const hasSpecialRateToRemove = (id: string | number) =>
    specialRatesToRemove.find((specialRate: any) => specialRate.id === id);

  const setSpecialRateToRemove = (id: string | number, type: string) => {
    const startOfNextMonth = format(
      startOfMonth(addMonths(new Date(), 1)),
      DC_DATE_FORMAT
    );
    if (hasSpecialRateToRemove(id)) {
      dispatch(deleteSpecialRateToRemove(id));
    } else {
      dispatch(
        addSpecialRateToRemove(id, type, resignStartDate || startOfNextMonth)
      );
    }
  };

  const tariffs = productInstance?.specials?.tariffs;
  const numbers = productInstance?.specials?.numbers;

  if (!tariffs && !numbers) return null;

  return (
    <SpecialRates
      tariffs={tariffs}
      numbers={numbers}
      getIsDisabled={(itemId) => hasSpecialRateToRemove(itemId)}
      onSpecialRateToRemove={(itemId, type) =>
        setSpecialRateToRemove(itemId, type)
      }
    />
  );
};
