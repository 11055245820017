import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getProductDataForConfig } from "../../../../../../store/mobile/selectors/productConfig";
import { APNs } from "../../../../components/Mobile/APNs";

interface APNsProps {
  targetConfigs: number[];
}

export const APNsContainer = ({ targetConfigs }: APNsProps) => {
  const supplier = useSelector((state) =>
    _.get(
      getProductDataForConfig(state, targetConfigs[0]),
      "mobile.product_component_data.supplier"
    )
  );

  return <APNs supplier={supplier} />;
};
