import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  getOneOffOrderTotal,
  getRecurringOrderTotal,
} from "../../../../../store/order/selectors";
import { saveDraftState } from "../../../../../store/drafts/actions";
import { Prices } from "../../../components/Prices";
import { Options } from "./Options";
import { BasketButton } from "../../../components/BasketButton";

export const Basket = () => {
  const classes = useStyles();

  const { totalOneOffPrice, totalRecurringPrice } = useSelector(
    (state: any) => ({
      totalOneOffPrice:
        state.order.totalOneOffPrice ?? getOneOffOrderTotal(state),
      totalRecurringPrice:
        state.order.totalRecurringPrice ?? getRecurringOrderTotal(state),
    })
  );
  const dispatch = useDispatch();

  const saveDraft = () => {
    dispatch(saveDraftState({}));
  };

  return (
    <Box className={classes.basket}>
      <BasketButton
        data={[]}
        totalCount={0}
        totalRecurringPrice={totalRecurringPrice}
        totalOneOffPrice={totalOneOffPrice}
      />
      <Prices
        monthlyPrice={totalRecurringPrice}
        upFrontPrice={totalOneOffPrice}
      />
      <Options saveDraft={saveDraft} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  basket: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[100],
  },
}));
