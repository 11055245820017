export enum CarrierFilterEnum {
  ALL = "ALL",
  O2 = "O2",
  VODAFONE = "Vodafone",
}

export enum DataFilterEnum {
  ALL = "DATA_ALL",
  VOICE = "VOICE",
  DATA = "DATA",
}
