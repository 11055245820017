import React, { Component, ChangeEvent } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { Filter } from "./Filter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

export interface ColumnModel {
  header: string;
  accessor: string;
  align?: "right" | "left";
}

interface SortableTableProps {
  defaultSortColumn?: string;
  limitHeight?: boolean;
  renderRow: (data: any, index: number) => void;
  data: any[];
  columns: ColumnModel[];
}

interface SortableTableState {
  filter: string;
  sortColumn: string;
  sortDirection: string;
}

export class SortableTable extends Component<
  SortableTableProps,
  SortableTableState
> {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    renderRow: PropTypes.func.isRequired,
    defaultSortColumn: PropTypes.string,
    limitHeight: PropTypes.bool,
  };

  state = {
    filter: "",
    sortColumn: "",
    sortDirection: "",
  };

  componentDidMount() {
    if (this.props.defaultSortColumn)
      this.setState({ sortColumn: this.props.defaultSortColumn });
  }

  setFilter = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ filter: e.target.value });
  };

  doFilter = (row: any) => {
    const term = this.state.filter;
    if (!term) return true;
    for (let col of this.props.columns) {
      const subject = _.get(row, col.accessor);
      if (subject && `${subject}`.toLowerCase().includes(term.toLowerCase()))
        return true;
    }
  };

  clearFilter = () => {
    this.setState({ filter: "" });
  };

  /**
   * Set sort column and direction
   * Cycle through asc/desc/off if it's the same column
   * @param accessor
   */
  setSort = (accessor: string) => {
    this.setState((s) => {
      if (s.sortColumn === accessor) {
        switch (s.sortDirection) {
          case "":
            return { sortDirection: "desc", sortColumn: accessor };
          case "desc":
            return { sortDirection: "asc", sortColumn: accessor };
          case "asc":
            return { sortDirection: "", sortColumn: "" };
        }
      }

      return {
        sortDirection: "desc",
        sortColumn: accessor,
      };
    });
  };

  render() {
    const { columns, data, renderRow, limitHeight } = this.props;
    const { filter, sortColumn, sortDirection } = this.state;

    let displayData;
    if (!sortColumn) {
      displayData = data;
    } else {
      const isNum = sortColumn.includes("price");
      displayData = data.slice().sort((a, b) => {
        if (!sortColumn) return 0;
        let va = _.get(a, sortColumn),
          vb = _.get(b, sortColumn);

        if (isNum) {
          va = parseFloat(va);
          vb = parseFloat(vb);
        }

        if (va < vb) return sortDirection === "asc" ? -1 : 1;
        if (va > vb) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    return (
      <Grid container>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <Filter
            value={filter}
            setFilter={this.setFilter}
            clearFilter={this.clearFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={limitHeight ? { maxHeight: 300, overflowY: "auto" } : {}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((col, i) =>
                    col.header ? (
                      <TableCell key={col.header}>
                        <SortHeading
                          onClick={() => this.setSort(col.accessor)}
                          align={col.align}
                        >
                          {col.header}
                          {col.accessor && (
                            <>
                              {this.state.sortColumn === col.accessor ? (
                                this.state.sortDirection === "desc" ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )
                              ) : (
                                <UnfoldMoreIcon />
                              )}
                            </>
                          )}
                        </SortHeading>
                      </TableCell>
                    ) : (
                      // No stable identity
                      // eslint-disable-next-line react/no-array-index-key
                      <TableCell key={i} />
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayData.map(
                  (d, i: number) => this.doFilter(d) && renderRow(d, i)
                )}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const SortHeading = styled.button<{ align?: "right" | "left" }>`
  background: none;
  border: none;
  color: #9e9e9e;
  font-size: 12px;
  padding: 0;
  outline: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(props) =>
    props.align === "right" ? "flex-end" : "flex-start"};
`;
