import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { useSaveDraftAndStartNewOrder } from "../../store/_root/hooks";
import { useSelector } from "react-redux";

export const CancelSaleButton: React.FC = () => {
  const startNewOrder = useSaveDraftAndStartNewOrder();
  const isFetching = useSelector((state: any) => state.drafts.isFetching);

  return (
    <Button
      color="primary"
      variant="text"
      size="large"
      onClick={startNewOrder}
      disabled={isFetching}
      endIcon={isFetching && <CircularProgress color="inherit" size="1rem" />}
    >
      Save and Exit
    </Button>
  );
};
