import { REQUEST_DATA, RequestDataAction } from "./types";
import { put, takeEvery, call } from "redux-saga/effects";
import { setData, setError } from "./actions";
import { getDetailById, ViewAPIResponse } from "../../api/v1/view";
import { ErrorAPI } from "../../api/helpers";

function* fetchDetailDataById(action: RequestDataAction) {
  try {
    const apiData: ViewAPIResponse = yield call(getDetailById, action.payload);
    if (apiData.warning === undefined) yield put(setData(apiData));
    else {
      const error = {
        message: apiData.message?.message || "Not Found",
      } as ErrorAPI;
      yield put(setError(error));
    }
  } catch (e) {
    const error = e as ErrorAPI;
    yield put(setError(error));
  }
}

export function* watchRequestDetailById() {
  yield takeEvery(REQUEST_DATA, fetchDetailDataById);
}
