import React from "react";

import { MPFNoProductChangesSubRow } from "../../components/Resigns/MPFProductInstance/MPFNoProductChangesSubRow";
import { MPFProductChangesSubRow } from "../../components/Resigns/MPFProductInstance/MPFProductChangesSubRow";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { BroadbandProductSelectorContainer } from "../../containers/Resigns/BroadbandProductSelector";

import { MPFProductBaseProps } from "./model";

interface MPFBroadbandProductOverviewProps
  extends Pick<
    MPFProductBaseProps,
    "configuration" | "removeResign" | "productInstance"
  > {
  isMultiLineProduct: boolean;
  allProducts: Record<string, any>[];
  setChanges: () => void;
}

export const MPFBroadbandProductOverview = ({
  configuration,
  productInstance,
  isMultiLineProduct,
  allProducts,
  removeResign,
  setChanges,
}: MPFBroadbandProductOverviewProps) => {
  const resignProduct = allProducts.find(
    (product) => product.id === configuration.broadbandProductId
  );

  return (
    <>
      {configuration.broadbandProductId && resignProduct ? (
        <MPFProductChangesSubRow
          productInstance={productInstance}
          resignProduct={resignProduct}
          removeResign={removeResign}
          productInstanceType="broadband"
        />
      ) : (
        <MPFNoProductChangesSubRow
          productInstance={productInstance}
          configuration={configuration}
          onClick={setChanges}
          removeResign={removeResign}
          productInstanceId={configuration.broadbandProductId}
          productInstanceType="broadband"
          disabled={!configuration.wlrProductId || isMultiLineProduct}
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        configuration.wlrProductId &&
        !configuration.broadbandProductId && (
          <BroadbandProductSelectorContainer
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};
