import * as BankHolidaysAPI from "../../api/v1/bankHolidays";

const LOCAL_STORAGE_BANK_HOLIDAYS_KEY = "bankHolidays";

/**
 * Init or refresh bank holidays data from API into Local Storage
 * Not ideal as this falls outside of redux state and fails without telling the user,
 * but at least it can't blow up the whole app on failure like the old one.
 * @returns {Promise<void>}
 */
export const initBankHolidays = async () => {
  const response = await BankHolidaysAPI.search();
  if (response.status === "success") {
    localStorage.setItem(
      LOCAL_STORAGE_BANK_HOLIDAYS_KEY,
      JSON.stringify(response.results)
    );
  }
};

/**
 * Gets current bank holidays
 * @returns {Array}
 */
export const getBankHolidays = () => {
  const bankHolidays = localStorage.getItem(LOCAL_STORAGE_BANK_HOLIDAYS_KEY);
  return bankHolidays ? JSON.parse(bankHolidays) : [];
};
