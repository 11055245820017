import { AnyAction, PayloadAction } from "@reduxjs/toolkit";

import {
  fetchDaisyFreshSearch,
  setDaisyFreshHardwareCredits,
  setDaisyFreshTerminationFees,
  setDaisyFreshEtf,
  setDaisyFreshLimitExceeded,
  fetchHardwareCreditProductSearch,
} from "../actions";
import { getDaisyFreshAllowedAmount } from "../selectors/daisyFresh";
import { setDaisyFreshHardwareTotal } from "../../hardware/configurations/actions";

export const daisyFreshExtraReducers = (builder: any) => {
  builder
    .addCase(fetchDaisyFreshSearch.pending, (state: any) => {
      state.daisyFreshSearch.fetching = true;
    })
    .addCase(
      fetchDaisyFreshSearch.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.daisyFreshSearch.fetching = false;
        state.daisyFreshSearch.response = action.payload;
      }
    )
    .addCase(setDaisyFreshHardwareCredits, (state: any, action: any) => {
      state.daisyFreshAmounts = setDaisyFreshAmount(
        "hardwareCredits",
        state,
        action
      );
    })
    .addCase(setDaisyFreshTerminationFees, (state: any, action: any) => {
      state.daisyFreshAmounts = setDaisyFreshAmount(
        "terminationFees",
        state,
        action
      );
    })
    .addCase(setDaisyFreshEtf, (state: any, action: any) => {
      state.daisyFreshAmounts = setDaisyFreshAmount("etf", state, action);
    })
    .addCase(setDaisyFreshHardwareTotal, (state: any, action: any) => {
      state.daisyFreshAmounts = setDaisyFreshAmount(
        "hardwareTotal",
        state,
        action
      );
    })
    .addCase(setDaisyFreshLimitExceeded, (state: any, action: any) => {
      const total =
        state.daisyFreshAmounts.hardwareCredits +
        state.daisyFreshAmounts.terminationFees +
        state.daisyFreshAmounts.hardwareTotal;

      state.daisyFreshAmounts.limitExceeded =
        total > getDaisyFreshAllowedAmount(state);
    })
    .addCase(
      fetchHardwareCreditProductSearch.pending,
      (state: any, action: any) => {
        state.hardwareCreditProductSearch.fetching = true;
      }
    )
    .addCase(
      fetchHardwareCreditProductSearch.fulfilled,
      (state: any, action: any) => {
        state.hardwareCreditProductSearch.fetching = false;
        state.hardwareCreditProductSearch.response = action.payload;
      }
    );
};

const setDaisyFreshAmount = (
  type: string,
  state: any,
  action: AnyAction
): any => {
  const daisyFreshAmounts = state.daisyFreshAmounts;

  // Update the actual amount
  daisyFreshAmounts[type] = parseFloat(action.payload) || 0;

  // // Check the new values don't exceed the limits and set flag accordingly.
  const total =
    daisyFreshAmounts.hardwareCredits +
    daisyFreshAmounts.terminationFees +
    daisyFreshAmounts.hardwareTotal;

  // // Note: state.mobile.daisyFreshAmounts.etf (Remaining Contractual Charges) isn't included in the limiting total.
  daisyFreshAmounts.limitExceeded = total > getDaisyFreshAllowedAmount(state);

  return daisyFreshAmounts;
};
