import React from "react";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface ChooseAvailableProps {
  numberReservation: Record<string, any>;
  availableNumbers: string[];
  onChooseNumberChange: (value: string) => void;
}
export const ChooseAvailable = ({
  numberReservation,
  availableNumbers,
  onChooseNumberChange,
}: ChooseAvailableProps) => (
  <FormControl fullWidth>
    <InputLabel variant="standard">Choose a number</InputLabel>
    <Select
      variant="standard"
      onChange={(event) => onChooseNumberChange(event.target.value as string)}
      value={numberReservation.selectedNumber}
      disabled={numberReservation?.response.status === "success"}
    >
      <MenuItem value="" />
      {availableNumbers.map((number) => (
        <MenuItem key={number} value={number}>
          {number}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
