import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderType } from "../../pages/StepChoose/Ethernet/types";
import {
  mapWLR3AddressToDCOrderProduct,
  getDCEthernetAddressValid,
} from "../../shared/utils/addresses";
import { addWeekdays } from "../../shared/utils/date";
import {
  addEthernetConfiguration,
  deselectEthernetQuote,
  fetchAvailableRouters,
  fetchEthernetPricingRequest,
  fetchEthernetPricingResults,
  fetchEthernetPurchase,
  removeEthernetConfiguration,
  selectEthernetQuote,
  setEthernetConfigAddress,
  setEthernetConfigPurchaseArgsSubmitted,
  setEthernetConfigType,
  updateEthernetConfiguration,
  updateEthernetCustomerData,
  updateEthernetPricingRequestStatus,
  clearEthernetConfiguration,
} from "./actions";
import blankEthernetConfig from "./blankEthernetConfig";
import { EthernetTypeEnum, ethernetProductSpec } from "./constants";
import { getBackupCapableRouterID } from "./selectors";
import initialState from "./state";

const ethernetProducts = createSlice({
  name: "ethernetProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Routers
      .addCase(fetchAvailableRouters.pending, (state, action) => {
        const configIndex = action.meta.arg;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex].availableRouters.fetching = true;
      })
      .addCase(
        fetchAvailableRouters.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { configIndex, response } = action.payload;
          if (typeof configIndex !== "number") return;
          state.configurations[configIndex].availableRouters.fetching = false;
          state.configurations[configIndex].availableRouters.response =
            response;
        }
      )

      // Config
      .addCase(addEthernetConfiguration, (state) => {
        state.configurations.push(blankEthernetConfig);
      })
      .addCase(removeEthernetConfiguration, (state, action) => {
        const configIndex = action.payload;
        if (typeof configIndex !== "number") return;
        state.configurations = [
          ...state.configurations.slice(0, configIndex),
          ...state.configurations.slice(configIndex + 1),
        ];
      })
      .addCase(updateEthernetConfiguration, (state, action) => {
        const { configIndex, field, value } = action.payload;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex] = {
          ...state.configurations[configIndex],
          [field]: value,
          ...(() => {
            if (field === "site_b_bearer") return { speed: false };
            if (field === "backup_line" && value === true)
              return {
                backup_broadband: true,
                selectedRouterId: getBackupCapableRouterID(state, configIndex),
              };
            if (field === "backup_broadband" && value === true)
              return {
                selectedRouterId: getBackupCapableRouterID(state, configIndex),
              };
            if (field === "backup_broadband" && value === false)
              return {
                backup_broadband: false,
                backup_line: false,
              };
            if (field === "orderType" && value === OrderType.RENEWAL)
              return {
                type: false,
                ...blankEthernetConfig,
              };
          })(),
          pricingResults: [],
          pricingRequestStatus: false,
          selectedQuoteId: false,
        };
      })
      .addCase(setEthernetConfigAddress, (state, action) => {
        const { configIndex, wlr3Address, addressPrefix } = action.payload;
        if (typeof configIndex !== "number") return;
        const dcAddress = mapWLR3AddressToDCOrderProduct(
          wlr3Address,
          `${addressPrefix}site_address_`
        );
        const addressValid = getDCEthernetAddressValid(
          dcAddress,
          addressPrefix
        );
        state.configurations[configIndex].purchaseArgs = {
          ...state.configurations[configIndex].purchaseArgs,
          ...dcAddress,
        };
        state.configurations[configIndex][`${addressPrefix}addressValid`] =
          addressValid;
      })
      .addCase(setEthernetConfigType, (state, action) => {
        const { configIndex, productType } = action.payload;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex] = {
          ...state.configurations[configIndex],
          type: productType,
          ...blankEthernetConfig,
          ...ethernetProductSpec[productType],
        };
      })

      // Pricing Requests
      .addCase(fetchEthernetPricingRequest.pending, (state, action) => {
        const {
          configIndex,
          status = "requesting",
          providersStatus,
        } = action.meta.arg;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex].pricingRequestStatus = status;
        if (providersStatus)
          state.configurations[configIndex].pricingRequestProvidersStatus =
            providersStatus;
      })
      .addCase(
        fetchEthernetPricingRequest.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { configIndex, pricingRequestId } = action.payload;
          if (typeof configIndex !== "number") return;
          state.configurations[configIndex].pricingRequestId = pricingRequestId;
        }
      )
      .addCase(updateEthernetPricingRequestStatus, (state, action) => {
        const { configIndex, status, providersStatus } = action.payload;
        if (typeof configIndex !== "number") return;
        if (status)
          state.configurations[configIndex].pricingRequestStatus = status;
        if (providersStatus)
          state.configurations[configIndex].pricingRequestProvidersStatus =
            providersStatus;
      })

      // Pricing Results
      .addCase(
        fetchEthernetPricingResults.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { configIndex, results } = action.payload;
          if (typeof configIndex !== "number") return;
          state.configurations[configIndex].pricingResults = results;
        }
      )

      // Purchases
      .addCase(fetchEthernetPurchase.pending, (state, action) => {
        const { configIndex } = action.meta.arg;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex].purchaseIsRequesting = true;
        state.configurations[configIndex].purchaseResponse = {};
      })
      .addCase(
        fetchEthernetPurchase.fulfilled,
        (state, action: PayloadAction<any>) => {
          const { configIndex, response } = action.payload;
          if (typeof configIndex !== "number") return;
          state.configurations[configIndex].purchaseIsRequesting = false;
          state.configurations[configIndex].purchaseResponse = response;
        }
      )

      // Quotes
      .addCase(selectEthernetQuote, (state, action: PayloadAction<any>) => {
        const { configIndex, quoteId, technology, accountName } =
          action.payload;
        if (typeof configIndex !== "number") return;
        const config = state.configurations[configIndex];
        const company =
          accountName || config.site_b_full_address.organisationName || "";

        state.configurations[configIndex].selectedQuoteId = quoteId;
        state.configurations[configIndex].technology = technology;
        state.configurations[configIndex].purchaseArgs = {
          ...state.configurations[configIndex].purchaseArgs,
          customer_required_date: addWeekdays(new Date(Date.now()), 45, true),
          site_b_site_address_company: company,
          site_b_cli: config.site_b_cli,
          ...(config.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP && {
            site_a_site_address_company: company,
          }),
        };
      })
      .addCase(deselectEthernetQuote, (state, action) => {
        const configIndex = action.payload;
        if (typeof configIndex !== "number") return;
        state.configurations[configIndex].selectedQuoteId = false;
      })

      // Customer Data
      .addCase(
        updateEthernetCustomerData,
        (state, action: PayloadAction<any>) => {
          const { configIndex, key, value } = action.payload;
          if (typeof configIndex !== "number") return;

          let args: any;
          if (typeof key === "object") {
            args = key;
          } else {
            args = { [key]: value };
            if (key === "concurrent_calls") {
              args["bandwidth_allocation"] = value * 100;
            }
          }
          state.configurations[configIndex].purchaseArgs = {
            ...state.configurations[configIndex].purchaseArgs,
            ...args,
          };
        }
      )
      .addCase(setEthernetConfigPurchaseArgsSubmitted, (state, action) => {
        const configIndex = action.payload;
        if (typeof configIndex !== "number") return;
        if (!state.configurationIndexesSubmitted.includes(configIndex)) {
          state.configurationIndexesSubmitted.push(configIndex);
        }
      })
      .addCase(clearEthernetConfiguration, (state) => {
        state.configurations = [];
      });
  },
});

export default ethernetProducts.reducer;
