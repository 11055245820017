import _ from "lodash";
import { createSelector } from "reselect";
import { RESIGN_WITHOUT_CHANGES, RESIGN_WITH_CHANGES } from "../constants";
import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";

/**
 * Get Resign Product Instance for a given config
 * @param state
 * @param configId
 * @returns {*}
 */
export const getResignProductInstanceByConfig = (state, configId) => {
  const { resignId } = state.mobile.configs[configId];
  if (!resignId) return false;
  return _.get(state.mobile.productInstances.response, "results", []).find(
    (r) => r.id === resignId
  );
};

/**
 * Get Resign Product Instance for target configs
 *
 * @param state
 * @param targetConfigs
 * @param {*}
 */
export const getResignProductInstanceByTargetConfigs = (
  state,
  targetConfigs
) => {
  return targetConfigs.map((targetConfig) => {
    const { resignId } = state.mobile.configs[targetConfig];
    if (!resignId) return null;
    return _.get(state.mobile.productInstances.response, "results", []).find(
      (r) => r.id === resignId
    );
  });
};

export const getResignProductInstanceById = (mobileState, id) =>
  _.get(mobileState.productInstances.response, "results", []).find(
    (r) => r.id === id
  );

/**
 * Get the supplier for a given mobile product ID
 *
 * @param state
 * @param productId
 * @returns {string}
 */
export const getProductSupplier = (state, productId) => {
  const product = state.mobileSearch.response.products.find(
    (p) => p.id === productId
  );
  return _.get(product, "first_mobile_component.supplier");
};

/**
 * Some tariff descriptions are misleading as they refer to generic voice products apparently.
 * As specified by @lisa & @joannes hey're overridden by the name of the next evo service
 * ...which will hopefully be the one users recognise
 *
 * @param instance
 * @returns {*}
 */
export const getNonGenericInstanceName = (instance) => {
  // const excludeNames = [
  //     'VODAFONE DATA',
  //     'VDC - VODAFONE DATA',
  //     'O2DC - O2 Data'
  //     ]
  // if(excludeNames.includes(instance.name)) {
  //     const otherService = instance.evo_services.find(s => !excludeNames.includes(s.Description))
  //     if(otherService) return otherService.Description
  // }
  // return instance.name

  // ...this has now been sorted DC side as it was needed for display on the contracts, so:

  return instance.name_for_resign || instance.name;
};

/**
 * Returns if the order contains resigns without change
 * @param state
 * @returns {boolean}
 */
export const hasResignsWithoutChange = (state) => {
  let result = false;
  state.mobile.configs.forEach((c) => {
    if (c.resignType === RESIGN_WITHOUT_CHANGES) result = true;
  });
  return result;
};

/**
 * Check if any mobile configs are resigns
 * @param configs - state.mobile.configs[]
 * @returns {boolean}
 */
export const getHasResigns = (configs) =>
  configs.findIndex((c) => c.resignType) > -1;

/**
 * Returns if the order contains resigns without a resign product id.
 * @param state
 * @returns {boolean}
 */
export const hasResignsWithoutProductId = (state) => {
  let result = false;
  state.mobile.configs.forEach((c) => {
    if (c.resignType === RESIGN_WITH_CHANGES && !c.productId) result = true;
  });
  return result;
};

/**
 * Get existing bolt-ons on a resign product instance
 * @param instance
 */
export const getProductInstanceBoltOns = (instance) =>
  instance?.transformedServices?.filter((s) => s.serviceType === "bolt_on");

/**
 * Get existing bolt-ons on multiple product instances
 * @param instances
 */
export const getAllProductInstanceBoltOns = (instances) => {
  const filteredInstances = instances.filter((i) => i !== null);
  const boltOns = filteredInstances
    .map((instance) => {
      const boltOns = instance?.transformedServices?.filter(
        (s) => s.serviceType === "bolt_on"
      );
      if (boltOns.length === 0) return [];
      return boltOns;
    })
    .filter((instance) => instance.length !== 0);
  return _.flattenDeep(boltOns);
};

/**
 * Get the reign without change product ID
 * ...a generic product returned by DC from v1/Product/Search
 * @param state
 * @returns {any}
 */
export const getResignWithoutChangeProduct = (state) =>
  _.get(state.mobile.resignProductSearch, "response.products[0].id");

/**
 * Compute the Early Termination Fee applicable to selected resigns.
 * Months left on the contract * price
 *
 * @type {Reselect.Selector<any, any>}
 */
export const getETFPrice = createSelector(
  [
    (state) => state.mobile.configs,
    (state) => state.mobile.productInstances.response.results,
  ],
  (configs, productInstances) => {
    if (!productInstances) return;
    const price = configs.reduce((total, config) => {
      if (!config.resignId) return total;
      const resignInstance = productInstances.find(
        (i) => i.id === config.resignId
      );
      const endDate = resignInstance.end_date_ymd;
      const remainingDays = differenceInDays(parseISO(endDate), new Date());
      if (remainingDays > 0) {
        return (
          total + Math.ceil(remainingDays / 28) * resignInstance.computedPrice
        );
      } else {
        return total;
      }
    }, 0);
    return price;
  }
);
