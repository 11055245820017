import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";

import { AccountSettings } from "@akj-dev/affinity-platform";

import {
  fetchDaisyFreshSearch,
  fetchHardwareCreditProductSearch,
  requestAllCliBoltOnProducts,
  requestAllMobileProductData,
  requestAllResignPropertyValues,
} from "../../../../store/mobile/actions";
import { getStandardConfigCount } from "../../../../store/mobile/selectors/daisyFresh";
import { getAccountSettings } from "../../../../store/account/selectors";
import {
  TargetConfigsProvider,
  TargetConfigsConsumer,
} from "../../context/TargetConfigsContext";

import { ConfigurationFormContainer } from "./ConfigurationForm";
import { BoltOnsContainer } from "./BoltOns";
import { BillCapConfirmationContainer } from "./BillCapConfirmation";
import { DaisyFreshContainer } from "./DaisyFresh";
import { VodafoneSpendCapWarningContainer } from "./VodafoneSpendCapWarningContainer";
import { useDwsResellerEnabled } from "../../../../shared/hooks/useDwsResellerEnabled";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";
import { getHasResigns } from "../../../../store/mobile/selectors/resigns";
import { ResignBoltOnsWarning } from "../../components/Mobile/ResignBoltOnsWarning";
import { MobileConfigTableContainer } from "./MobileConfigTable/MobileConfigTableContainer";
import { ResignStartDateContainer } from "./ResignStartDate";

export const Mobile = () => {
  const dispatch = useDispatch();
  const isDwsResellerEnabled = useDwsResellerEnabled();
  const canAccessVfDirect = useCanAccessVfDirect();
  const settings = useSelector<any, AccountSettings>((state) =>
    getAccountSettings(state)
  );
  const hasResigns = useSelector((state: any) =>
    getHasResigns(state.mobile.configs)
  );

  const standardConfigCount = useSelector((state) =>
    getStandardConfigCount(state)
  );

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 2 Mobile mounted`,
      category: "action",
    });
    dispatch(requestAllMobileProductData(true));
    if (!isDwsResellerEnabled) {
      dispatch(requestAllCliBoltOnProducts());
    }

    dispatch(requestAllResignPropertyValues());
    dispatch(fetchDaisyFreshSearch()); // TODO: This could be fired in step 1 to speed things up.
    dispatch(fetchHardwareCreditProductSearch());
  }, [dispatch, isDwsResellerEnabled]);

  return (
    <TargetConfigsProvider>
      <div className="MobileConfigurations">
        <TargetConfigsConsumer>
          {({ targetConfigs }) => (
            <>
              {targetConfigs.length > 0 && <ConfigurationFormContainer />}
              <div
                style={
                  targetConfigs.length > 0 ? { display: "none" } : undefined
                }
              >
                {hasResigns && canAccessVfDirect && <ResignBoltOnsWarning />}
                <MobileConfigTableContainer />
                {!canAccessVfDirect && hasResigns && (
                  <ResignStartDateContainer />
                )}
                <BoltOnsContainer />
                {settings.as_daisy_fresh_enabled === "1" &&
                  standardConfigCount > 0 && <DaisyFreshContainer />}
              </div>
            </>
          )}
        </TargetConfigsConsumer>

        {canAccessVfDirect && <VodafoneSpendCapWarningContainer />}
        {!isDwsResellerEnabled && <BillCapConfirmationContainer />}
      </div>
    </TargetConfigsProvider>
  );
};
