import React from "react";
import { StatusChip } from "@akj-dev/shared-components";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

interface StacCodeProps {
  handleConfigValueChange: () => void;
  onClearClick: () => void;
  onVerifyClick: () => void;
  showVerificationFeedback: boolean;
  expiryDate: Date | null;
  stacCodeVerifyDisabled: boolean;
  stacCodeCheckFetching: boolean;
  stacCodeCheckError: boolean;
  stacCodeCheckErrorMessage: string;
}

export const StacCode = ({
  handleConfigValueChange,
  onClearClick,
  onVerifyClick,
  showVerificationFeedback,
  expiryDate,
  stacCodeVerifyDisabled,
  stacCodeCheckFetching,
  stacCodeCheckError,
  stacCodeCheckErrorMessage,
}: StacCodeProps) => (
  <Grid container spacing={2}>
    <Grid item sm={6}>
      <BulkDynamicField
        propertyName="stac"
        afterUpdate={handleConfigValueChange}
        disabled={!!expiryDate}
      />
    </Grid>
    <Grid item sm={6}>
      <BulkDynamicField
        propertyName="old_mobile_number"
        helperText="Accepts domestic (07) and E164 (+447) formats, but other common formats not accepted (e.g. 00447, 447)"
        afterUpdate={handleConfigValueChange}
        disabled={!!expiryDate}
      />
    </Grid>
    <Grid item sm={4}>
      {expiryDate ? (
        <Button variant="contained" onClick={onClearClick} fullWidth>
          Clear
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onVerifyClick}
          disabled={stacCodeVerifyDisabled}
          data-cy="StacCodeVerify"
          fullWidth
        >
          Verify
        </Button>
      )}
    </Grid>
    <Grid item sm={8}>
      {showVerificationFeedback &&
        (stacCodeCheckFetching ? (
          <StatusChip
            type="loading"
            title="Verifying STAC code"
            message="Please wait..."
          />
        ) : expiryDate ? (
          <StatusChip
            type="success"
            title="STAC expiry date"
            message={expiryDate}
          />
        ) : stacCodeCheckError ? (
          <StatusChip
            type="error"
            title="Problem checking STAC code"
            message={stacCodeCheckErrorMessage}
          />
        ) : (
          false
        ))}
    </Grid>
  </Grid>
);
