import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { Box } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

import { setQuoteContact } from "../../../../../../store/quote/actions";
import { SelectContact } from "../../../../../../shared/containers/SelectContact";
import { OrderProductStatus } from "../../../../../StepSummary/containers/OrderProductStatus";
import { StatusLarge } from "../../../../../../shared/components/StatusLarge";

export const QuoteContactStatusContainer = () => {
  const generateQuote = useSelector((state: any) => state.quote.generateQuote);
  const quoteContactId = useSelector(
    (state: any) => state.quote.quoteContactId
  );
  const orderId = useSelector((state: any) => state.order.id);

  const dispatch = useDispatch();

  const quoteId = _.get(generateQuote.response, "data.quote_id");

  if (generateQuote.fetching)
    return (
      <>
        <Box mb={1}>
          <StatusChip
            type="loading"
            title="Generating Quote"
            message="Please wait..."
          />
        </Box>
        <OrderProductStatus />
      </>
    );

  if (quoteId)
    return (
      <StatusLarge
        type="success"
        message={`Quote generated${orderId ? " for Sales ID: " + orderId : ""}`}
      />
    );

  if (generateQuote.response.status === "error")
    return (
      <StatusLarge
        type="error"
        title="Quote generation failed."
        message={generateQuote.response.message}
      />
    );

  return (
    <Box display="flex" alignItems="flex-end">
      <SelectContact
        current={quoteContactId}
        handleUpdate={(id) => dispatch(setQuoteContact(id))}
      />
    </Box>
  );
};
