import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getIsDynamicProduct } from "../../../../../store/mobile/selectors/pricing";
import { getAccountSettings } from "../../../../../store/account/selectors";
import { getValidationErrors } from "../../../../../store/mobile/selectors/productConfig";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { ConfigurationForm } from "../../../components/Mobile/ConfigurationForm";
import { transformMobileConfig } from "../MobileConfigTable/helpers";
import { TypeOfConnection } from "../../../../../shared/types/MobileProductConfig";

export const ConfigurationFormContainer = () => {
  const { setTargetConfigs, targetConfigs } = useTargetConfigsContext();

  const firstConfigProductId = useSelector(
    (state: any) => state.mobile.configs[targetConfigs[0]].productId
  );

  const getMobileConfigs = useSelector((state: any) => state.mobile.configs);
  const isDynamicProduct = useSelector((state) =>
    getIsDynamicProduct(state, firstConfigProductId)
  );
  const cliBoltOnSearch = useSelector(
    (state: any) => state.mobile.cliBoltOnSearch
  );
  const hasCliProducts = targetConfigs.every((targetConfig) => {
    const productId = getMobileConfigs[targetConfig].productId;
    return _.get(cliBoltOnSearch, [productId, "response", "total"], 0) > 0;
  });
  const settings = useSelector<any, AccountSettings>((state) =>
    getAccountSettings(state)
  );
  const productData = useSelector((state: any) => state.mobile.productData);
  const validationErrors = useSelector((state: any) =>
    getValidationErrors(targetConfigs[0], state)
  );
  const configs = useSelector((state: any) => state.mobile.configs);

  const productInstances = useSelector(
    (state: any) => state.mobile.productInstances
  );

  const isOneMonthContract =
    useSelector(
      (state: any) => state.mobile.contractLengthInMonthsAllProducts
    ) === 1;

  // TP60148 -> TP179111. Retain ability to filter shared discount by adding a condition here.
  const showSharedDiscount = true;

  const closeEditConfig = () => {
    setTargetConfigs([]);
  };

  const targetConfigsData = useMemo(() => {
    return targetConfigs.map((configIndex) => {
      const config = configs[configIndex];
      const configProductData = config.productData?.response || {};
      const resign = config.resignId
        ? productInstances?.response.results?.find(
            (r: { id: string }) => r.id === config.resignId
          )
        : {};
      const isResignWithoutChanges =
        config.resignType === TypeOfConnection.RESIGN_WITHOUT_CHANGES;

      return {
        name: configProductData.product_name || resign.name_for_resign,
        network: isResignWithoutChanges
          ? resign.network.name
          : configProductData.mobile?.product_component_data.supplier,
        config: transformMobileConfig(
          config,
          productInstances,
          cliBoltOnSearch
        ),
      };
    });
  }, [targetConfigs, configs, productInstances, cliBoltOnSearch]);

  return (
    <ConfigurationForm
      targetConfigs={targetConfigs}
      targetConfigsData={targetConfigsData}
      settings={settings}
      isDynamicProduct={isDynamicProduct}
      hasCliProducts={hasCliProducts}
      dynamicProperties={
        productData[firstConfigProductId]?.response?.dynamic_properties
      }
      mobileDynamicProperties={
        productData[firstConfigProductId]?.response?.mobile?.dynamic_properties
      }
      showSharedDiscount={showSharedDiscount}
      validationErrors={validationErrors}
      closeEditConfig={closeEditConfig}
      isOneMonthContract={isOneMonthContract}
    />
  );
};
