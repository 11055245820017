import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { StatusChip } from "@akj-dev/shared-components";

import { useCanAccessVfDirect } from "../../../../../shared/hooks/useCanAccessVfDirect";
import SearchIcon from "@mui/icons-material/Search";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState,
} from "../../../../../shared/components/VirtualTable/utils";
import { generateTableHeaders } from "../Tariff/ProductTable/utils/generateTableHeaders";
import { VirtualTable } from "../../../../../shared/components/VirtualTable";
import { useAccountSettings } from "../../../../../shared/hooks/useAccountSettings";
import { ActiveSort } from "../../../../../shared/components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../../shared/components/VirtualTable/enums";
import { transformMobileProductForDisplay } from "../../utils";
import { ResignProductSelectTableRow } from "./ResignProductSelectTableRow";

interface ResignProductSelectorContainerProps {
  fetching: boolean;
  products: any[];
  minPrice?: number;
  setResignProduct: (id: string) => void;
}

export const ResignProductSelector = ({
  fetching,
  minPrice = 0,
  products = [],
  setResignProduct,
}: ResignProductSelectorContainerProps) => {
  const classes = useStyles();
  const canAccessVfDirect = useCanAccessVfDirect();
  const showProductFamily =
    useAccountSettings().as_can_see_product_family === "1";

  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter,
  } = useDebouncedState();

  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: showProductFamily ? "family" : "name",
    order: VirtualTableSortOrder.ASC,
  });

  const availableProducts = useMemo(
    () =>
      products
        .filter((product) => {
          const price = parseFloat(
            product.price.first_bill_recurring_price_with_promotions
          );
          return !(canAccessVfDirect && price < minPrice);
        })
        .map(transformMobileProductForDisplay),
    [products, canAccessVfDirect, minPrice]
  );

  const filteredProducts = useMemo(
    () =>
      availableProducts
        .filter(
          (product) =>
            `${product.name} £${product.recurringPrice}`
              .toLowerCase()
              .indexOf(debouncedProductFilter.toLowerCase()) !== -1
        )
        .sort(sortVirtualTableData(activeSort)),
    [availableProducts, debouncedProductFilter, activeSort]
  );

  const handleSort = useMemo(
    () => handleActiveSort(setActiveSort),
    [setActiveSort]
  );

  if (fetching) {
    return <CircularProgress />;
  }

  if (availableProducts.length < 1) {
    return (
      <StatusChip type="error" title="No products available for this resign" />
    );
  }

  return (
    <Card className={classes.root} data-cy="ResignProductSelector">
      <Box className={classes.searchBox}>
        <TextField
          data-cy={"tableSearch"}
          className={classes.search}
          variant="outlined"
          name="filter"
          value={productFilter}
          onChange={handleProductFilter}
          placeholder={`Search by code, description or cost`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <VirtualTable
        withScrollButton={false}
        headers={generateTableHeaders(false, showProductFamily)}
        activeSort={activeSort}
        handleSort={handleSort}
        data={{
          products: filteredProducts,
          allConfigsCount: 0,
          showProductFamily,
          setResignProduct,
        }}
        tableSize={500}
        itemSize={60}
        itemCount={filteredProducts.length}
        RenderTableRow={ResignProductSelectTableRow}
      />
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderLeft: `${theme.spacing(0.25)} solid ${theme.palette.primary.main}`,
  },
  statusTag: {
    background: theme.palette.success.main,
    color: "#fff",
    width: "140px",
    height: "50px",
    padding: "12px 0",
    borderRadius: "5px 0 0 5px",
    textAlign: "center",
  },
  table: {
    background: theme.palette.grey[50],
  },
  // TODO: dry?
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1),
  },
}));
