import { trim, trimEnd } from "lodash";

export const isValidPhoneNumber = (value: string) => {
  const mobileNumber = trimEnd(trim(value));
  const regexp = /^(?:0|\+44)(7)([0-9 ]{9,13})$/gi;
  // Some phone numbers apparently have ten digits:
  // https://auroratarget.tpondemand.com/entity/9541-10-digit-phone-numbers-have-always
  // 22/12/20: We are now allowing 0800 numbers, they can have 10, 9 or 7 digits.
  // https://auroratarget.tpondemand.com/entity/20017-contact-cannot-use-0845
  // Allow phone numbers with UK area code (+44) to pass validation
  // https://auroratarget.tpondemand.com/entity/9534-as-fe-l-all-phone-number
  const tenDigitRegexp = /^(?:0|\+44)(1|2|8)([0-9 ]{8,13})$/gi;
  const nineDigitRegexp = /^(?:0|\+44)(1|8)([0-9 ]{7,12})$/gi;
  const sevenDigitRegexp = /^(?:0|\+44)(8)([0-9 ]{5,10})$/gi;

  return (
    regexp.test(mobileNumber) ||
    tenDigitRegexp.test(mobileNumber) ||
    nineDigitRegexp.test(mobileNumber) ||
    sevenDigitRegexp.test(mobileNumber)
  );
};

/**
 * Test if a value is a valid UK mobile number
 * ...allowing for international codes. See http://regexlib.com/REDetails.aspx?regexp_id=592
 * @param value
 * @returns {boolean}
 */
export const isValidMobileNumber = (value: string) => {
  // Must be an 11 digit number starting 07
  // if (!/07[0-9]{9}/.test(value)) message = "Must be a valid UK mobile number"

  return /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/.test(value);
};

/**
 * Test if a value is a valid email address
 * @param email
 * @returns {boolean}
 */
export function isValidEmail(email: string) {
  var re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Test if a postcode is valid.
 * Note this regex is taken from the official UK government spec here:
 * https://www.gov.uk/government/publications/bulk-data-transfer-for-sponsors-validation-rules-for-cas-upload
 * https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
 *
 * 3. UK Postcode Regular Expression
 * ^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$
 * 3.2 Logic
 "GIR 0AA"
 OR
 One letter followed by either one or two numbers
 OR
 One letter followed by a second letter that must be one of ABCDEFGHJ
 KLMNOPQRSTUVWXY (i.e..not I) and then followed by either one or two
 numbers
 OR
 One letter followed by one number and then another letter
 OR
 A two part post code
 where the first part must be
 One letter followed by a second letter that must be one of ABCDEFGH
 JKLMNOPQRSTUVWXY (i.e..not I) and then followed by one number and
 optionally a further letter after that
 AND
 The second part (separated by a space from the first part) must be One
 number followed by two letters.
 A combination of upper and lower case characters is allowed.
 Note: the length is determined by the regular expression and is between 2 and 8
 characters.

 * @param postcode
 * @returns {boolean}
 */
export function validatePostcode(postcode: string) {
  // const postcodeRegEx = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/;
  // Or...y'know, just get any old one because data doesn't match:
  const postcodeRegEx = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/i;
  const result = postcodeRegEx.test(postcode);
  return result;
}

/**
 * Valid Pac Code (3 Letters & 6 Digits)
 * http://regexlib.com/REDetails.aspx?regexp_id=3825
 *
 * @param value
 * @returns {boolean}
 */
export const isValidPACCode = (value: string) =>
  /^[A-Z]{3}[0-9]{6}$/.test(value);

/**
 * Checks that a value is a monetary amount.
 * https://regexlib.com/REDetails.aspx?regexp_id=3939
 *
 * @param value
 * @returns {boolean}
 */
export const isValidMonetaryAmount = (value: string) =>
  /^(?!0,?\d)(?:\d{1,3}(?:([, .])\d{2})?(?:\1\d{2})*|(?:\d+))((?!\1)[,.]\d{1,2})?$/u.test(
    value
  );

/**
 * Valid general text (only certain special characters allowed).
 * Restriction of special characters wanted as part of a couple of tickets, including:
 * https://auroratarget.tpondemand.com/entity/11524-delivery-page-validation-checks,
 * https://auroratarget.tpondemand.com/entity/8950-validation-issues and
 * https://auroratarget.tpondemand.com/entity/11525-opportunity-name-validation-checks
 * Agreed with Toby to restrict accented characters 26/08/2020.
 *
 * @param value
 * @returns {boolean}
 */
export const isValidText = (value: string) =>
  /^[A-Za-z0-9\-'., ]+$/.test(value);
