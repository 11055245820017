import React from "react";
import styled, { keyframes } from "styled-components";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";

import { IndexedCardInfo } from "@akj-dev/shared-components";

interface TrackingDetailsProps {
  cardIndex: string;
  validEmail: boolean;
  validMobile: boolean;
  error: boolean;
  trackingDetails: Record<string, any>;
  onTrackingDetailsChange: (value: unknown) => void;
}

export const TrackingDetail = ({
  cardIndex,
  validEmail,
  validMobile,
  error,
  trackingDetails,
  onTrackingDetailsChange,
}: TrackingDetailsProps) => (
  <IndexedCardInfo index={cardIndex} title="Tracking Details">
    <FieldWrapper data-cy="TrackingDetailsEmail">
      <TextField
        variant="standard"
        fullWidth
        label="Email or Mobile Number"
        onChange={(event) => onTrackingDetailsChange(event.target.value)}
        value={trackingDetails}
        error={!!error}
        helperText={error && "Must be a valid email address or mobile number."}
      />
      <Indicator>
        <ShowHideAnimation $in={validEmail || validMobile}>
          {validEmail ? <EmailIcon /> : <PhoneIcon />}
          <CheckCircleIcon />
        </ShowHideAnimation>
      </Indicator>
    </FieldWrapper>
  </IndexedCardInfo>
);

const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Indicator = styled.div`
  margin-left: 15px;
  width: 70px;
  height: 100%;
  overflow: hidden;
`;

const fadeIn = keyframes`
  from {
    transform: translateX(-48px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const ShowHideAnimation = styled.div<{ $in: boolean }>`
  display: ${(props) => (props.$in ? "block" : "none")};
  animation: ${(props) => (props.$in ? fadeIn : "")} 0.5s linear;
`;
