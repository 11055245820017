import React from "react";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";

import { DownloadDocumentsContainer } from "../../containers/UniversalProducts/ConfigForm/DownloadDocuments";
import { UploadDocumentsContainer } from "../../containers/UniversalProducts/ConfigForm/UploadDocuments";
import { PricingContainer } from "../../containers/UniversalProducts/ConfigForm/Pricing";
import { ConfigTableRowContainer } from "../../containers/UniversalProducts/ConfigTable/ConfigTableRow";
import { TableOuter } from "./TableOuter";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";

interface UniversalProductsConfigFormProps {
  goBack: () => void;
  configIndex: number;
}

export const UniversalProductsConfigForm = ({
  goBack,
  configIndex,
}: UniversalProductsConfigFormProps) => {
  const hostEnv = useSelector(getHostEnv);

  return (
    <div>
      <Paper style={{ marginBottom: 20 }}>
        <Grid
          item
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "1rem",
          }}
        >
          <h3>Editing Product Configuration</h3>
          <Button variant="contained" color="primary" onClick={goBack}>
            Return to Product List
          </Button>
        </Grid>
        <TableOuter hideAction>
          <ConfigTableRowContainer configIndex={configIndex} hideAction />
        </TableOuter>
      </Paper>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <DownloadDocumentsContainer />
        </Grid>
        <Grid item sm={6}>
          {hostEnv !== "dc" && <UploadDocumentsContainer />}
          <PricingContainer />
        </Grid>
      </Grid>
    </div>
  );
};
