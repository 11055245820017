import React, { useState } from "react";

import { RequestParams } from "./RequestParams";
import { RequestStatus } from "./RequestStatus";
import { TemplateUploadDownload } from "./TemplateUploadDownload";
import { BulkOrderStageEnum } from "./enum";

interface DialogContentProps {
  orderId: string;
}
export const DialogContent = ({ orderId }: DialogContentProps) => {
  const [dialogState, setDialogState] = useState<{
    stage: BulkOrderStageEnum;
    file?: any;
    jobId?: string;
  }>({ stage: BulkOrderStageEnum.TEMPLATE });

  const goBack = () => {
    setDialogState({ stage: BulkOrderStageEnum.TEMPLATE });
  };

  const chooseFile = (files: any) => {
    if (files.length)
      setDialogState({
        file: files[0],
        stage: BulkOrderStageEnum.PARAMS,
      });
  };

  const setJobId = (jobId: string) => {
    setDialogState({
      jobId,
      stage: BulkOrderStageEnum.STATUS,
    });
  };

  const { file, jobId, stage } = dialogState;

  switch (stage) {
    case BulkOrderStageEnum.PARAMS:
      return (
        <RequestParams
          file={file}
          orderId={orderId}
          goBack={goBack}
          setJobId={setJobId}
        />
      );
    case BulkOrderStageEnum.STATUS:
      return <RequestStatus jobId={jobId} />;
    case BulkOrderStageEnum.TEMPLATE:
      return <TemplateUploadDownload chooseFile={chooseFile} />;
    default:
      return <></>;
  }
};
