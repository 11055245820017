import React from "react";
import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { NoDataPlaceholderProps } from "./types";

export const NoDataPlaceholder = ({
  Icon = MenuBookIcon,
  text = "No data found",
}: NoDataPlaceholderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Icon color="primary" className={classes.icon} />
      <Typography>{text}</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: `${theme.spacing(2)} 0`,
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));
