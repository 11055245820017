import React from "react";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { TableConstants } from "../../../Dashboard/enums";
import { OrderCLI } from "../../../../store/detail/types";

interface Props {
  clis: OrderCLI[];
}

export function OrderCLIsTable({ clis }: Props) {
  const columns: {
    name: keyof OrderCLI;
    label: string;
    options?: {};
  }[] = [
    {
      name: "cli",
      label: "CLI",
      options: {
        customBodyRender: (val: OrderCLI["cli"]) => val || TableConstants.Empty,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (val: OrderCLI["name"]) =>
          val || TableConstants.Empty,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    download: false,
    elevation: 1,
    filter: false,
    print: false,
    responsive: "standard",
    rowHover: false,
    selectableRows: "none",
    search: false,
    sort: false,
    setTableProps: () => ({
      size: "small",
    }),
    viewColumns: false,
  };

  return (
    <MUIDataTable
      title="Order CLIs"
      data={clis}
      columns={columns}
      options={options}
    />
  );
}
