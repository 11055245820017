import { UniversalProductsState } from "./types";

const initialState: UniversalProductsState = {
  productSearch: {
    fetching: false,
    response: {},
  },
  configs: [],
  productDocuments: {},
  selectedConfigIndex: false,
};

export const UPBlankConfig = {
  productId: false,
  oneOffPrice: "",
  recurringPrice: "",
  userDocuments: [],
  userDocumentsSendPref: [],
  userDocumentsUpload: [],
  orderProduct: {
    fetching: false,
    response: {},
  },
  universalProductProperties: {},
  pricingScheme: "",
  hasValidPrices: true,
};

export default initialState;
