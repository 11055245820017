import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  SaleSummaryRowType,
  SaleSummaryType,
} from "../../containers/Header/Cart/types";

export interface SaleSummaryProps extends Omit<SaleSummaryType, "totalCount"> {}

export const SaleSummary = ({
  data,
  totalRecurringPrice,
  totalOneOffPrice,
}: SaleSummaryProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell} colSpan={2}>
              Sale Summary
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              Monthly
            </TableCell>
            <TableCell className={classes.headerCell} align="right">
              One-Off
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.body}>
          <TableRow className={classes.divider} />
          {data.map(
            ({
              count,
              label,
              recurringPrice,
              oneOffPrice,
            }: SaleSummaryRowType) => (
              <TableRow key={`${label}-${count}`}>
                <TableCell className={classes.bodyCell} align="right">
                  {count}
                </TableCell>
                <TableCell className={classes.bodyCell}>{label}</TableCell>
                <TableCell className={classes.bodyCell} align="right">
                  {showPrice(recurringPrice)}
                </TableCell>
                <TableCell className={classes.bodyCell} align="right">
                  {showPrice(oneOffPrice)}
                </TableCell>
              </TableRow>
            )
          )}
          <TableRow className={classes.divider} />
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell className={classes.footerCell} colSpan={2}>
              Totals
            </TableCell>
            <TableCell className={classes.footerCell} align="right">
              {showPrice(totalRecurringPrice)}
            </TableCell>
            <TableCell className={classes.footerCell} align="right">
              {showPrice(totalOneOffPrice)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const showPrice = (price?: string) => (price ? `£${price}` : "..");

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  divider: {
    height: theme.spacing(1),
  },
  headerCell: {
    fontSize: 14,
    fontWeight: "normal",
    color: theme.palette.common.black,
    borderBottom: "none",
  },
  bodyCell: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    borderBottom: 0,
  },
  footerCell: {
    fontWeight: "bold",
    borderBottom: "none",
  },
}));
