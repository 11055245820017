import React from "react";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { ResignsTableContainer } from "../containers/Resigns/Table";

interface ResignsProductsSectionProps {
  cardIndex: number;
}

export const ResignsProductsSection = ({
  cardIndex,
}: ResignsProductsSectionProps) => (
  <IndexedCardInfo
    index={cardIndex}
    title="Please choose your resigns products"
  >
    <ResignsTableContainer />
  </IndexedCardInfo>
);
