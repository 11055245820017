import React from "react";
import MultiLineIcon from "@mui/icons-material/SettingsPhone";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

// TODO: Product data reports this as a text field, but gives a number for the value. Get corrected in DC.

export const MultiLineOptions = () => (
  <Section title="Multi-Line Options" avatar={<MultiLineIcon />}>
    <WlrBBDynamicField productType="wlr" propertyName="number_of_channels" />
  </Section>
);
