import React from "react";
import { StatusChip, Alert } from "@akj-dev/shared-components";
import { Grid, Button, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { CheckIcon } from "@akj-dev/shared-components";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";
import { PAC_ERROR, PAC_VERIFIED } from "../../utils/pacStatuses";

interface PacVerificationProps {
  isValidated: boolean;
  pacStatus: string;
  showVCNPACNotAllowedMessage: boolean;
  acquisitionMethod: string;
  pacErrorMessage: React.ReactNode;
  onVerifyClick: () => void;
  onClearClick: () => void;
}

export const PacVerification = ({
  isValidated,
  pacStatus,
  showVCNPACNotAllowedMessage,
  acquisitionMethod,
  pacErrorMessage,
  onVerifyClick,
  onClearClick,
}: PacVerificationProps) => {
  return (
    <div>
      <ArrayDynamicField
        propertyName="mobile_number"
        floatingLabelText="Mobile Number"
        helperText="Accepts domestic (07) and E164 (+447) formats, but other common formats not accepted (e.g. 00447, 447)"
        disabled={pacStatus === PAC_VERIFIED}
        unique
      />
      <ArrayDynamicField
        propertyName="pac"
        floatingLabelText="PAC Code"
        disabled={pacStatus === PAC_VERIFIED}
      />
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item sm={6}>
          <Box display="flex" alignItems="center">
            {pacStatus !== PAC_VERIFIED ? (
              <Button
                variant="contained"
                onClick={onVerifyClick}
                disabled={!isValidated}
              >
                Verify
              </Button>
            ) : (
              <Button variant="contained" onClick={onClearClick}>
                Clear
              </Button>
            )}
            <Box ml={2}>
              {pacStatus === PAC_VERIFIED && <CheckIcon />}
              {pacStatus === PAC_ERROR && <ErrorIcon color="error" />}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <BulkDynamicField propertyName="pac_expiry_date" disabled={true} />
        </Grid>
      </Grid>
      {showVCNPACNotAllowedMessage && (
        <StatusChip
          type="error"
          title="VCN PAC Codes"
          message={
            <span>
              VCN PAC codes come from the Gemini Billing Platform so you are
              unable to process a migration through Affinity Sales. These
              migration requests are classed as Transfer Of Ownerships (TOO) so
              please{" "}
              <a
                href={getPathForCrossAppRedirect(
                  AppSubdomains.SUPPORT,
                  "/support/new-ticket"
                )}
              >
                raise a Ticket to the Giacom Service Delivery team,
              </a>{" "}
              who will manually process your request.
            </span>
          }
        />
      )}

      {pacStatus === PAC_ERROR && (
        <Box pt={1} pb={1}>
          <StatusChip
            type="error"
            title="Error verifying PACs"
            message={pacErrorMessage}
          />
        </Box>
      )}
      {acquisitionMethod && (
        <Alert type="info" message={`This is a ${acquisitionMethod}`} />
      )}
    </div>
  );
};
