import React, { useState } from "react";
import {
  MobileConfigTableData,
  TypeOfConnection,
} from "../../../../../shared/types/MobileProductConfig";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Clear, Edit, SignalCellular3Bar } from "@mui/icons-material";
import { RowExpander } from "./RowExpander";
import { MobileConfigTableOuter } from "./MobileConfigTableOuter";

export interface MobileConfigTableProps {
  displayPrefs: DisplayPrefs;
  data: MobileConfigTableData;
  onEditConfigs: (configIndexes: string[]) => void;
  onDeleteConfigs: (configIndexes: string[]) => void;
  /* Update config property is just for commission type select. Bad UI decision to have it inline IMO */
  onCommissionSelect: (configIndexes: string[]) => void;
}

export type DisplayPrefs = {
  showActions?: boolean; // Whether it's main table or embedded in the edit form
  showMonthlyAirtimeCredit?: boolean;
  showOneOffAirtimeCredit?: boolean;
  showVFDiscounts?: boolean; // Shared + commission sacrifice
  showCommissionTypeSelect?: boolean;
};

export const MobileConfigTable = ({
  displayPrefs,
  data,
  onEditConfigs,
  onDeleteConfigs,
  onCommissionSelect,
}: MobileConfigTableProps) => {
  const classes = useStyles();
  const [selectedConfigs, setSelectedConfigs] = useState<string[]>([]);

  const handleSelectConfig = (configIndex: string) => {
    if (selectedConfigs.includes(configIndex)) {
      setSelectedConfigs(selectedConfigs.filter((c) => c !== configIndex));
    } else {
      setSelectedConfigs([...selectedConfigs, configIndex]);
    }
  };

  const handleConfigureMultiple = () => {
    // All editable config indices. Wow that's ugly!
    const all = Object.entries(data).reduce(
      (p, c) => [
        ...p,
        ...Object.entries(c[1].configs).reduce(
          (p, c) => [
            ...p,
            ...(c[1].connectionType !== TypeOfConnection.RESIGN_WITHOUT_CHANGES
              ? [c[0]]
              : []),
          ],
          [] as any
        ),
      ],
      [] as any
    );
    onEditConfigs(selectedConfigs.length ? selectedConfigs : all);
    if (selectedConfigs.length === 0) setSelectedConfigs(all);
  };

  const clearSelected = () => {
    setSelectedConfigs([]);
  };

  return (
    <Box mb={1}>
      <Card elevation={1} data-cy="step2MobileConfigTable">
        <CardHeader
          avatar={<SignalCellular3Bar />}
          action={
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  disabled={selectedConfigs.length === 0}
                  variant="outlined"
                  endIcon={<Clear />}
                  onClick={clearSelected}
                >
                  Clear Selected
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  endIcon={<Edit />}
                  onClick={handleConfigureMultiple}
                >
                  Configure {selectedConfigs.length || "all"} product
                  {selectedConfigs.length !== 1 && "s"}
                </Button>
              </Grid>
            </Grid>
          }
          title="Configure Your Products"
        />
        <CardContent className={classes.inner}>
          <MobileConfigTableOuter displayPrefs={displayPrefs}>
            {Object.entries(data).map((entry) => {
              const [productId, groupData] = entry;
              return (
                <RowExpander
                  key={productId}
                  displayPrefs={displayPrefs}
                  productId={productId}
                  groupData={groupData}
                  onEditConfigs={onEditConfigs}
                  onDeleteConfigs={onDeleteConfigs}
                  onCommissionSelect={onCommissionSelect}
                  selectedConfigs={selectedConfigs}
                  onSelectConfig={handleSelectConfig}
                />
              );
            })}
          </MobileConfigTableOuter>
        </CardContent>
      </Card>
    </Box>
  );
};

const useStyles = makeStyles({
  inner: {
    overflowX: "auto",
  },
});
