import { CancelOrderReason } from "../../pages/Wizard/components/DeleteDraftModal/types";
import { postJSON, getJSON, multiPartPostJSON } from "../helpers/core";
import {
  multiPartPostJSONBackgroundIdempotent,
  postJSONBackgroundIdempotent,
  getJSONBackgroundIdempotent,
} from "../helpers/idempotency";

/**
 * Generate an eSign contract and send it to the customer
 *
 * @param account
 * @param order
 * @param send_to_contact_id
 * @returns {Promise<*>}
 */
export const createAndSendEsignContract = (
  account: string,
  order: string,
  send_to_contact_id: string
) =>
  postJSON(`Orders/CreateAndSendEsignContract`, {
    account,
    order,
    send_to_contact_id,
  });

/**
 * Generate a quote
 * @param account
 * @param order
 * @param lead
 * @param send_to_contact_id
 * @returns {Promise<*>}
 */
export const generateQuote = (
  account: string,
  order: string,
  lead: string,
  send_to_contact_id: string
) =>
  postJSON(`Orders/GenerateQuote`, {
    account,
    order,
    lead,
    send_to_contact_id,
  });

/**
 * Send for approval on an order
 * @param order
 * @param account
 * @returns {Promise<*>}
 */
export const sendForApproval = (order: string, account: string) =>
  postJSONBackgroundIdempotent(`Orders/SendForApproval`, {
    order,
    account,
  });

/**
 * Requests order provisioning
 * This is for hardware only orders where we don't generate a contract
 * and hardware only orders that don't require approval (the approval process also triggers provisioning)
 *
 * @param order
 * @param account
 * @returns {Promise<*|void>}
 */
export const provision = (order: string, account: string) =>
  postJSONBackgroundIdempotent(`Orders/Provision`, {
    order,
    account,
  });

/**
 * Find out if a contract can be uploaded or not
 * @param account
 * @param order
 * @returns {Promise.<*>}
 */

/**
 * Upload the contract
 *
 * // @param sent_at - not included? Why was this missed out?
 *
 * @param account* (account_id)
 * @param order* (order_id)
 * @param received_at* (YYYY-MM-DD date - user input. Must be later than signed_at)
 * @param signed_at* (YYYY-MM-DD date - user input)
 * @param contact_id (can be obtained using uploadSignedContract GET request)
 * @param signed_contract (the contract file)
 * @returns {Promise.<*>}
 */
export const uploadSignedContractPost = (
  account: string,
  order: string,
  received_at: string,
  signed_at: string,
  contact_id: string,
  signed_contract: string
) =>
  multiPartPostJSONBackgroundIdempotent(`Orders/UploadSignedContract`, {
    account,
    order,
    received_at,
    signed_at,
    contact_id,
    signed_contract,
    do_not_provision: "0", // I'm presuming this endpoint deals with provisioning too - there's been a separate endpoint in the past...
  });

/**
 * Recalculate order price (run after products have been added)
 *
 * @param order
 * @param account
 * @param lead
 * @returns {Promise<*>}
 */
export const recalculatePrices = (
  order: string,
  account: string,
  lead: string
) =>
  getJSONBackgroundIdempotent("Orders/RecalculatePrices", {
    order,
    account,
    lead,
    t: Date.now(),
  });

/**
 * Update order details.
 * Possible params:
 * account, tracking_email, tracking_mobile, additional_contract_notes, order_contact_id
 * (taken from Nicola's old getUpdateTrackingAndTerminationBody body fn)
 *
 * @param orderId
 * @param params
 * @returns {Promise<void>}
 */
export const update = (orderId: string, params: any) =>
  postJSONBackgroundIdempotent(`Orders/Update/${orderId}`, params);

/**
 * Bulk order upload
 *
 * @param order_reference
 * @param products_per_order
 * @param use_existing_order_id
 * @param csv_file
 * @returns {Promise<*>}
 */
export const bulkOrderUpload = (
  order_reference: string,
  products_per_order: string,
  use_existing_order_id: string,
  csv_file: any
) =>
  multiPartPostJSON(`Orders/BulkOrderUpload`, {
    order_reference,
    products_per_order,
    use_existing_order_id,
    csv_file,
  });

/**
 * Create a new blank order in DC for us to add products to.
 * (also creates a new opportunity for said order to belong to)
 * @param account
 * @param name
 * @returns {Promise<*|void>}
 */
export const create = (account: string, name: string = "Guided Sales Order") =>
  postJSON(`Orders/Create`, {
    account,
    name,
    external_source_name: "Guided Sales",
  });

/**
 * Get status of bulk ordering job
 * @param jobId
 * @returns {Promise<*>}
 */
export const bulkOrderStatus = (jobId: any) =>
  getJSON(`Orders/BulkOrderStatus/${jobId}`);

/**
 * View an order
 *
 * @param order_id
 * @param account_id
 * @returns {Promise<*>}
 */
export const view = (order_id: string, account_id: string, lead_id: string) =>
  getJSONBackgroundIdempotent(
    `Orders/View/${order_id}?account=${account_id}&lead_id=${lead_id}&include_pricing=1&background=1`,
    { t: Date.now() }
  );

/**
 * Get existing orders on an account
 *
 * @param account_id
 * @param offset
 * @param limit
 * @returns {Promise<*>}
 * @constructor
 */
export const OrdersForAccount = (
  account_id: string,
  offset?: string,
  limit: number = 20
) =>
  getJSON(`GuidedSales/OrdersForAccount`, {
    account_id,
    offset,
    limit,
  });

/**
 * Get paginated orders on account.
 * TODO: Move this to a generic API method?
 *
 * @param nextPageUrl
 * @returns {Promise<*>}
 * @constructor
 */
export const MoreOrdersForAccount = (nextPageUrl: string) =>
  getJSON(nextPageUrl);

/**
 * Create an account from a lead.
 * See also Account/Create 😭
 * @param params
 * @returns {Promise<*>}
 * @constructor
 */
export const CreateAccount = (params: any) =>
  postJSONBackgroundIdempotent(`Orders/CreateAccount`, {
    ...params,
  });

type CancelOrderOptsWithAccount = CancelOrderReason & {
  orderId: string;
  accountId?: string;
  leadId?: never;
};

type CancelOrderOptsWithLead = CancelOrderReason & {
  orderId: string;
  accountId?: never;
  leadId?: string;
};

/**
 * Close the opportunity (ie. an order in draft) and provide the reason for deleting.
 * Must pass leadId or accountId during the CPQ process, optional from Sales Status.
 */
export const Cancel = (
  options: CancelOrderOptsWithAccount | CancelOrderOptsWithLead
) =>
  postJSON<{ status: "success"; data: {} }>("Orders/Cancel", {
    order: options.orderId,
    lead: options.leadId,
    account: options.accountId,
    reason: options.reason,
    opp_closed_lost_reason: options.otherReason,
    also_mark_opp_as_closed_lost: "1",
  });

/**
 * Re-books any appointments which have expired after four hours.
 *
 * @param orderId
 * @returns {Promise<void>}
 */
export const rebookExpiredReservations = (orderId: string) =>
  postJSON(`Orders/RebookExpiredReservations/${orderId}`);

export const uploadDocumentsPost = (
  orderId: string,
  documentFiles: any,
  send_with_contract: string
) =>
  multiPartPostJSON(`Orders/UploadDocuments/${orderId}`, {
    "document_files[]": documentFiles,
    send_with_contract: send_with_contract,
  });

export const listDocuments = (orderId: string) =>
  getJSON(`Orders/ListDocuments/${orderId}`);
