import { DisplayError } from "../types";
import { Order } from "../../types";
import { SearchAPIParams, SearchAPIResponse } from "../../api/v1/search";

export const SET_ERROR = "board.SET_ERROR";
export const REQUEST_DATA = "board.REQUEST_DATA";
export const SET_DATA = "board.SET_DATA";

// STATE
export interface BoardState {
  data: Order[];
  fetching: boolean;
  error?: DisplayError;
}

// ACTION CREATORS

export interface SetErrorAction {
  type: typeof SET_ERROR;
  payload: DisplayError;
}

export interface RequestDataAction {
  type: typeof REQUEST_DATA;
  payload: SearchAPIParams;
}

export interface SetDataAction {
  type: typeof SET_DATA;
  payload: SearchAPIResponse[];
}

export type BoardActionTypes =
  | SetErrorAction
  | RequestDataAction
  | SetDataAction;
