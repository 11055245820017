import React from "react";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { ConfigTableRow } from "../../StepCustomise/components/wlrBroadband/ConfigTableRow";
import { ResignConfigTableRow } from "../../StepCustomise/containers/wlrBroadband/ResignsConfigurationTable/ResignConfigTableRow";

interface WlrBroadbandProps {
  cardIndex: string;
  configs: Record<string, any>[];
  showCommissionType: boolean;
  getIsMPFLine: (config: Record<string, any>) => boolean;
  onRetry: () => void;
}

export const WlrBroadband = ({
  cardIndex,
  configs,
  showCommissionType,
  getIsMPFLine,
  onRetry,
}: WlrBroadbandProps) => (
  <IndexedCardInfo index={cardIndex} title="Lines, Calls & Broadband">
    {configs.filter((config) => !config.resignProductType).length > 0 && (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Total One Off Price</TableCell>
            <TableCell>Total Recurring Price</TableCell>
            <TableCell>Broadband</TableCell>
            <TableCell>Configured?</TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {configs.map((c, i) =>
            !c.resignProductType ? (
              <ConfigTableRow
                c={c}
                configIndex={i}
                // eslint-disable-next-line react/no-array-index-key
                key={`row-${i}`}
                retryAction={onRetry}
                isMPFLine={getIsMPFLine(c)}
              />
            ) : null
          )}
        </tbody>
      </Table>
    )}
    {configs.filter((config) => config.resignProductType).length > 0 && (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CLI</TableCell>
            <TableCell />
            <TableCell>Product</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Resign Type</TableCell>
            {showCommissionType && (
              <TableCell align="center">
                <p>Commission</p>
                <p>Up Front &nbsp; Residual </p>
              </TableCell>
            )}
            <TableCell>Configured?</TableCell>
          </TableRow>
        </TableHead>
        <tbody>
          {configs.map((c, i) =>
            c.resignProductType ? (
              <ResignConfigTableRow
                configuration={c}
                index={i}
                // eslint-disable-next-line react/no-array-index-key
                key={`resignRow-${i}`}
                retryAction={onRetry}
                showCommissionType={showCommissionType}
              />
            ) : null
          )}
        </tbody>
      </Table>
    )}
  </IndexedCardInfo>
);
