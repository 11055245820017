import React from "react";
import { useSelector } from "react-redux";

import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../../types";
import { WlrLocationNewLineBroadbandCellModal } from "../../../../../components/WlrLocation/NewLine/BroadbandCell/Modal";

export interface WlrLocationNewLineBroadbandCellModalProps {
  open: boolean;
  locationIndex: number;
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  handleClose: () => void;
}

export const WlrLocationNewLineBroadbandCellModalContainer = ({
  open,
  locationIndex,
  location,
  product,
  handleClose,
}: WlrLocationNewLineBroadbandCellModalProps) => {
  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    (state) => state.wlrBroadband.configurations ?? []
  ).map((config, configId) => ({ ...config, configId }));

  const contractLength = useSelector<any, number>(
    (state) => state.wlrBroadband.contractLength
  );

  const productConfigurations = configurations.filter(
    (c) => c.wlrProductId === product.id && c.locationIndex === locationIndex
  );

  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const prices = productConfigurations
    .filter((c) => !!c.broadbandProductId)
    .map(
      (c) =>
        broadbandProducts.find((b) => b.id === c.broadbandProductId)?.price
          .first_bill_recurring_price_with_promotions ?? "0.00"
    );

  const totalPrice = prices
    .reduce((total, p) => total + parseFloat(p), 0)
    .toFixed(2);

  return (
    <WlrLocationNewLineBroadbandCellModal
      open={open}
      locationIndex={locationIndex}
      totalPrice={totalPrice}
      contractLength={contractLength}
      location={location}
      product={product}
      productConfigurations={productConfigurations}
      handleClose={handleClose}
    />
  );
};
