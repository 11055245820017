export const notificationTypes = ["Broadband", "Mobile"] as const;

export const sortDirections = ["asc", "desc", "none"] as const;

export const triggers = [
  { value: "Submitted", types: ["Broadband"] },
  { value: "Committed", types: ["Broadband"] },
  { value: "Delayed", types: ["Broadband"] },
  { value: "Rejected", types: ["Broadband"] },
  { value: "Cancelled", types: ["Broadband", "Mobile"] },
  { value: "Attention", types: ["Broadband"] },
  { value: "Completed", types: ["Broadband"] },
  { value: "Complete", types: ["Mobile"] },
] as const;

export const wait = 500;
