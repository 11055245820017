import React, { useState } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";

interface BaseTextFieldLowUpdateProps {
  validate: (value: string) => string;
  value: string | number;
}

type TextFieldLowUpdateProps = TextFieldProps & BaseTextFieldLowUpdateProps;

/**
 * This lets us update redux state only when the TextField is blurred, by storing
 * intermediate values in local component state.
 * Standard behaviour is onChange, but the store is so large this is becomes laggy.
 * onChange also creates problems when async calls are fired. Stale calls returning
 * before current ones create state errors.
 * (the latter would probably be solved with Redux Saga, but that's a big job)
 *
 * ...also with validation... which makes it a bit messy...
 */
export const TextFieldLowUpdate = ({
  value: initialValue,
  validate,
  type,
  ...rest
}: TextFieldLowUpdateProps) => {
  const [value, setValue] = useState(initialValue);
  const [errorText, setErrorText] = useState(
    typeof validate === "function" ? validate(initialValue as string) : false
  );

  return (
    <TextField
      variant="standard"
      {...rest}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        setErrorText(validate ? validate(event.target.value) : false);
      }}
      helperText={errorText}
      error={Boolean(errorText)}
      type={type}
      onKeyDown={(event) => {
        if (type === "number" && ["e", "E", "+", "-"].includes(event.key))
          event.preventDefault();
      }}
    />
  );
};
