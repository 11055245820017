import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { useTargetConfigsContext } from "../../../../context/TargetConfigsContext";
import { Router } from "../../../../components/wlrBroadband/Router";

interface RouterProps {
  isSOGEA: boolean;
  isBTWFTTC: boolean;
}

export const RouterContainer = ({ isBTWFTTC, isSOGEA }: RouterProps) => {
  const { targetConfigs } = useTargetConfigsContext();
  const [showAddress, setShowAddress] = useState(false);

  const config = useSelector(
    (state: any) => state.wlrBroadband.configurations[targetConfigs[0]]
  );

  const routerId = config.broadbandProperties.router_id;
  const fetching = config.broadbandProductData.fetching;
  const deliveryCharge = _.get(
    config.broadbandProductData.response,
    "broadband.router_delivery.one_off_price_with_promotions"
  );

  const toggleAddress = () => setShowAddress((prev) => !prev);

  return (
    <Router
      isSOGEA={isSOGEA}
      isBTWFTTC={isBTWFTTC}
      routerId={routerId}
      fetching={fetching}
      deliveryCharge={deliveryCharge}
      targetConfigs={targetConfigs}
      showAddress={showAddress}
      toggleAddress={toggleAddress}
    />
  );
};
