import React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { FileDrop } from "../../../../shared/components/File/Drop";
import { Section } from "../../../../shared/components/Section";

import { UserDocumentChip } from "./UserDocumentChip";

interface UploadDocumentsProps {
  onFileDrop: (files: any[]) => void;
  onSendPref: (pref: boolean, index: number) => void;
  onRemove: (index: number) => void;
  userDocuments: any[];
  userDocumentsSendPref: boolean[];
}

export const UploadDocuments = ({
  onFileDrop,
  onRemove,
  onSendPref,
  userDocuments,
  userDocumentsSendPref,
}: UploadDocumentsProps) => (
  <Section title="Upload Documents" avatar={<InsertDriveFileIcon />}>
    <FileDrop onDrop={onFileDrop} message="Drag completed documents here" />
    {userDocuments.map((file: any, i: number) => (
      <UserDocumentChip
        file={file}
        sendPref={userDocumentsSendPref[i]}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        removeAction={() => onRemove(i)}
        sendPrefAction={(pref: boolean) => onSendPref(pref, i)}
      />
    ))}
  </Section>
);
