import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resetUploadStatus } from "../../../../store/order/actions";
import { UploadSignedContract } from "../../components/UploadSignedContract";

import { UploadSignedContractContentContainer } from "./Content";

export const UploadSignedContractContainer = () => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);

  const orderStatus = useSelector((state: any) => state.order.orderStatus);

  const openDialog = () => {
    dispatch(resetUploadStatus());
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <UploadSignedContract
      showDialog={showDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
      isUploadButtonDisabled={
        !orderStatus.response.data?.actions?.upload_signed_contract?.ok
      }
      isOrderStatusFetching={orderStatus.fetching}
      contentChildren={
        <UploadSignedContractContentContainer closeDialog={closeDialog} />
      }
    />
  );
};
