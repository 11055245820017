import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

interface FormSelectFieldProps {
  helperText: string;
  options: Record<string, any>;
  isError: boolean;
  value: string;
  disabled: boolean;
  onChange: (event: SelectChangeEvent<string>) => void;
}
export const FormSelectField = ({
  helperText,
  options,
  isError,
  value,
  disabled,
  onChange,
}: FormSelectFieldProps) => (
  <FormControl fullWidth error={isError}>
    <InputLabel variant="standard">{helperText}</InputLabel>
    <Select
      variant="standard"
      value={value}
      disabled={disabled}
      onChange={onChange}
    >
      {Object.keys(options).map((key) => (
        <MenuItem key={key} value={key}>
          {options[key]}
        </MenuItem>
      ))}
    </Select>
    {isError && <FormHelperText>This is a required field</FormHelperText>}
  </FormControl>
);
