import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const load8 = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const DEFAULT_SPINNER_SIZE = 40;

export const Spinner = styled.div.attrs({ "data-cy": "Spinner" })<{
  size?: number;
  color?: string;
}>`
  display: inline-block;
  width: ${({ size = DEFAULT_SPINNER_SIZE }) => size}px;
  height: ${({ size = DEFAULT_SPINNER_SIZE }) => size}px;
  border: ${({ size = DEFAULT_SPINNER_SIZE }) => Math.max(4, size / 10)}px solid
    ${(p) => p.color || p.theme.palette.secondary.main};
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  animation: ${load8} 1s infinite linear;
`;

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
