import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountId } from "../../order/selectors";
import * as WlrBroadbandAPI from "../../../api/v1/wlrBroadband";
import * as ProductAPI from "../../../api/v1/product";
import { checkCommissionTag } from "../../account/selectors";
import _ from "lodash";

export const removeResign = createAction(
  "wlrBroadband/removeResign",
  (productInstanceIds) => ({
    payload: { productInstanceIds },
  })
);

export const setResignStartDate = createAction<any>(
  "wlrBroadband/setResignStartDate"
);

export const setResignType = createAsyncThunk(
  "wlrBroadband/setResignType",
  async ({ productInstances, resignProductType }: any, { getState }) => {
    const state: any = getState();
    const canAccessCommission = checkCommissionTag(state, "wlr");
    return { productInstances, resignProductType, canAccessCommission };
  }
);

export const setWlrResignProduct = createAsyncThunk(
  "wlrBroadband/setWlrResignProduct",
  async (
    { productInstance, resignProductId, resignProductType }: any,
    { getState }
  ) => {
    const state: any = getState();
    const canAccessCommission = checkCommissionTag(state, "wlr");
    return {
      productInstance,
      resignProductId,
      resignProductType,
      canAccessCommission,
    };
  }
);

export const setBroadbandResignProduct = createAsyncThunk(
  "wlrBroadband/setBroadbandResignProduct",
  async ({ productInstance, resignProductId, type }: any) => {
    return { productInstance, resignProductId, type };
  }
);

export const fetchLineAvailabilityForResign = createAsyncThunk(
  "wlrBroadband/fetchLineAvailabilityForResign",
  async (productInstance: any, { getState }) => {
    const state: any = getState();
    const locations = state.wlrBroadband.locations;
    const locationIndex = locations.findIndex(
      (l: any) => l.cli.value === productInstance.pin
    );
    const address = locations[locationIndex].address;
    const accountId = getAccountId(state);
    return await WlrBroadbandAPI.lineAvailability(
      address.cssDatabaseCode,
      address.addressReference,
      accountId
    );
  }
);

export const doBroadbandSearchForResign = createAsyncThunk(
  "wlrBroadband/doBroadbandSearchForResign",
  async (productInstance: any, { getState }) => {
    const state: any = getState();
    const locations = state.wlrBroadband.locations;
    const locationIndex = locations.findIndex(
      (l: any) => l.cli.value === productInstance.pin
    );
    const address = locations[locationIndex].address;
    const accountId = getAccountId(state);
    return await WlrBroadbandAPI.broadbandSearch(
      accountId,
      address.addressReference,
      address.cssDatabaseCode,
      address.postcode,
      null,
      state.wlrBroadband.contractLength
    );
  }
);

export const fetchLineProductInstances = createAsyncThunk(
  "wlrBroadband/fetchLineProductInstances",
  async ({ page = 1 }: any, { getState, dispatch }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    const params = {
      is_resignable: 1,
      sort: "pin",
      with: "line_details,special_numbers,evo_services",
      filter_evo_services: 1,
    };
    const response = await ProductAPI.ProductInstance(
      "Line",
      accountId,
      page,
      params
    );

    const nextPage = _.get(response, "pagination.next_page");
    if (nextPage && nextPage > page) {
      dispatch(fetchLineProductInstances({ page: nextPage }));
    }

    return response;
  }
);

export const fetchBroadbandProductInstances = createAsyncThunk(
  "wlrBroadband/fetchBroadbandProductInstances",
  async ({ page = 1 }: any, { getState, dispatch }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    const params = {
      is_resignable: 1,
      sort: "pin",
      with: "line_details,special_numbers,evo_services",
      filter_evo_services: 1,
    };
    const response = await ProductAPI.ProductInstance(
      "Broadband",
      accountId,
      page,
      params
    );

    const nextPage = _.get(response, "pagination.next_page");
    if (nextPage && nextPage > page) {
      dispatch(fetchBroadbandProductInstances({ page: nextPage }));
    }

    return response;
  }
);

export const fetchResignProductSearch = createAsyncThunk(
  "wlrBroadband/fetchResignProductSearch",
  async (arg, { getState }) => {
    return await ProductAPI.search({
      has_extra_services: 1,
      extra_services_type: "resign",
    });
  }
);

export const fetchLineResignProductSearch = createAsyncThunk(
  "wlrBroadband/fetchLineResignProductSearch",
  async (arg, { getState }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    return await ProductAPI.search({
      account: accountId,
      has_line: 1,
      line_is_resign: 1,
    });
  }
);

export const fetchBroadbandResignProductSearch = createAsyncThunk(
  "wlrBroadband/fetchBroadbandResignProductSearch",
  async (arg, { getState }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    return await ProductAPI.search({
      account: accountId,
      has_broadband: 1,
      broadband_is_resign: 1,
    });
  }
);
