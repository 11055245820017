import React from "react";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface AdditionalBundleProps {
  propertyName: string;
  selectedBundleId: string;
  bundles: any;
  onChangeBundle: (event: SelectChangeEvent<string>) => void;
}

export const AdditionalBundles = ({
  bundles,
  selectedBundleId,
  onChangeBundle,
}: AdditionalBundleProps) => {
  const options = bundles.available_options;

  const menuItems = options.map((option: any) => ({
    value: option,
    label: bundles.available_option_map[option],
  }));

  return (
    <Select
      onChange={onChangeBundle}
      value={!selectedBundleId ? "0" : selectedBundleId}
      label="Additional Bundle"
      fullWidth={true}
      variant="standard"
    >
      <MenuItem value={"0"}>Not Required</MenuItem>
      {menuItems.map((menuItem: any) => (
        <MenuItem key={menuItem.value} value={menuItem.value}>
          {menuItem.label +
            " - £" +
            bundles.available_option_details[menuItem.value]
              .first_bill_recurring_price_with_promotions}
        </MenuItem>
      ))}
    </Select>
  );
};
