import React from "react";
import { parseISO } from "date-fns";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateString } from "../../../../../types";
import { toDateString } from "../../../../../helpers/dates";

type Props = {
  dateFromSelected: DateString;
  dateToSelected: DateString;
  onDateFromSelect: (date: DateString) => void;
  onDateToSelect: (date: DateString) => void;
  disabled?: boolean;
};

export const DateRangeSelect = ({
  dateFromSelected,
  dateToSelected,
  onDateFromSelect,
  onDateToSelect,
  disabled,
}: Props) => {
  const dateFrom = parseISO(dateFromSelected);
  const dateTo = parseISO(dateToSelected);
  return (
    <Box display="flex" justifyContent="space-between">
      <Box marginRight={0.5}>
        <DatePicker
          disabled={disabled}
          maxDate={dateTo}
          inputFormat="dd/MM/yyy"
          label="From"
          value={dateFrom}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              id="date-from"
            />
          )}
          onChange={(date) => {
            const dateString = toDateString(date || "");
            onDateFromSelect(dateString);
          }}
        />
      </Box>
      <Box marginLeft={0.5}>
        <DatePicker
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              id="date-to"
            />
          )}
          minDate={dateFrom}
          inputFormat="dd/MM/yyy"
          label="To"
          value={dateTo}
          onChange={(date) => {
            const dateString = toDateString(date || "");
            onDateToSelect(dateString);
          }}
        />
      </Box>
    </Box>
  );
};
