import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import {
  getSelectedProductData,
  removeWlrConfiguration,
} from "../../../../store/wlrBroadband/actions/configurations";
import { removeResignLocation } from "../../../../store/wlrBroadband/actions/locations";
import {
  TargetConfigsProvider,
  useTargetConfigsContext,
} from "../../context/TargetConfigsContext";
import { WlrBroadband as WlrBroadbandComponent } from "../../components/wlrBroadband/WlrBroadband";

import { ConfigurationFormContainer } from "./ConfigurationForm";

import { getAccountSettings } from "../../../../store/account/selectors";

export const WlrBroadbandContainer = () => {
  const dispatch = useDispatch();
  const showCommissionTypeSelection =
    useSelector((state: any) => getAccountSettings(state))
      .as_commission_type_selection_for_wlr_bb === "1";
  const { setTargetConfigs, targetConfigs } = useTargetConfigsContext();

  const locations = useSelector((state: any) => state.wlrBroadband.locations);
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 2 Lines, Calls & Broadband mounted`,
      category: "action",
    });

    dispatch(getSelectedProductData());
  }, [dispatch]);

  const toggleSingle = (configIndex: number) => {
    const i = targetConfigs.indexOf(configIndex);
    if (i !== -1) {
      setTargetConfigs([
        ...targetConfigs.slice(0, i),
        ...targetConfigs.slice(i + 1),
      ]);
    } else {
      setTargetConfigs([...targetConfigs, configIndex]);
    }
  };

  /**
   * Only configs with product IDs can be edited (i.e. not "Same product" ones) so....
   */
  const allCheckable = () => {
    const checkable: number[] = [];
    configurations.forEach((c: any, i: number) => {
      if (c.wlrProductId || c.broadbandProductId) checkable.push(i);
    });

    return checkable;
  };

  const toggleAll = () => {
    if (configurations.length > targetConfigs.length) {
      setTargetConfigs(allCheckable());
    } else {
      setTargetConfigs([]);
    }
  };

  const selectSingle = (configIndex: number) => setTargetConfigs([configIndex]);

  const configCount = () =>
    configurations.filter((c: any) => !!c.resignProductType).length;

  const openForm = () => setFormOpen(true);

  const closeForm = () => setFormOpen(false);

  if (formOpen) {
    return <ConfigurationFormContainer closeAction={closeForm} />;
  }

  return (
    <WlrBroadbandComponent
      targetConfigs={targetConfigs}
      locations={locations}
      configCount={configCount()}
      configurations={configurations}
      openForm={openForm}
      toggleAll={toggleAll}
      toggleSingle={toggleSingle}
      retryAction={() => dispatch(getSelectedProductData())}
      deleteAction={(index) => dispatch(removeResignLocation(index))}
      deleteWlrAction={(index) => dispatch(removeWlrConfiguration(index))}
      editAction={(index) => {
        selectSingle(index);
        openForm();
      }}
      showCommissionTypeSelection={showCommissionTypeSelection}
    />
  );
};

export const WlrBroadband = () => (
  <TargetConfigsProvider>
    <WlrBroadbandContainer />
  </TargetConfigsProvider>
);
