import React from "react";

import { Box, InputAdornment, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

import { StatusChip } from "@akj-dev/shared-components";

import { VirtualTable } from "../../../../shared/components/VirtualTable";
import { ActiveSort } from "../../../../shared/components/VirtualTable/types";
import { Loader } from "../../../../shared/components/Loader";
import { ItemsSelected } from "../../../../shared/components/ItemsSelected";
import { useTableHeaders } from "../hooks";
import { MAX_QTY } from "../constants";
import { ChooseHardwareTableRenderRow } from "../containers/RenderRow";
import { HardwareTableData } from "../types";

interface ChooseHardwareTableProps {
  showLoader: boolean;
  error?: string | boolean;
  productFilter: string;
  productsCount: number;
  filteredProductsCount: number;
  configsCount: number;
  hasConnections: boolean;
  tableData: HardwareTableData;
  activeSort: ActiveSort;
  handleProductFilter: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleSort: (newSortIndex: string) => void;
  onRetry: () => void;
}

export const ChooseHardwareTable = ({
  showLoader,
  error,
  productFilter,
  productsCount,
  configsCount,
  hasConnections,
  tableData,
  filteredProductsCount,
  activeSort,
  handleProductFilter,
  handleSort,
  onRetry,
}: ChooseHardwareTableProps) => {
  const classes = useStyles();
  const tableHeaders = useTableHeaders(hasConnections);

  return (
    <Box className={classes.root}>
      {showLoader && <Loader />}
      {!showLoader && !error && (
        <>
          <Box className={classes.searchBox}>
            <TextField
              data-cy="tableSearch"
              className={classes.search}
              variant="outlined"
              name="filter"
              value={productFilter}
              onChange={handleProductFilter}
              placeholder={`Search ${productsCount} Available Products`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            {!!configsCount && (
              <ItemsSelected
                maxQuantity={MAX_QTY}
                configsCount={configsCount}
              />
            )}
          </Box>
          <VirtualTable<HardwareTableData>
            withScrollButton={false}
            headers={tableHeaders}
            activeSort={activeSort}
            handleSort={handleSort}
            data={tableData}
            tableSize={500}
            itemSize={80}
            itemCount={filteredProductsCount}
            RenderTableRow={ChooseHardwareTableRenderRow}
          />
        </>
      )}
      {error && (
        <Box mb={1}>
          <StatusChip
            type="error"
            title="Error finding price book mobile products"
            message={error}
            retry={onRetry}
          />
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `calc(100% - ${theme.spacing(3)}) !important`,
    display: "flex",
    flexDirection: "column",
    margin: `0 ${theme.spacing(1.5)}`,
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1),
  },
}));
