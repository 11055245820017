// Resign types
export const RESIGN_WITHOUT_CHANGES = "RESIGN_WITHOUT_CHANGES";
export const RESIGN_WITH_CHANGES = "RESIGN_WITH_CHANGES";

// Bolt-on flag
export const KEEP_CURRENT_ALB = "KEEP_CURRENT_ALB";
export const ALB_NOT_REQUIRED = "ALB_NOT_REQUIRED";
export const REMOVE_EXISTING_ALB = "REMOVE_EXISTING_ALB";

// Port date types
export const STAC = "STAC";
export const PRE_DISPATCHED_SIM = "PRE_DISPATCHED_SIM";
export const NEW_SIM = "NEW_SIM";
