import { useQuery } from "react-query";
import {
  accountAutoComplete,
  AutoCompleteResponse,
} from "../api/accountAutocompleteApi";

export function useAccountAutoComplete(name: string) {
  return useQuery<AutoCompleteResponse, Error>(
    ["accountAutoComplete", name],
    async () => {
      const response = await accountAutoComplete(name);
      if (response.data.status === "error") throw Error(response.data.message);
      return response.data;
    }
  );
}
