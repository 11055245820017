import React from "react";
import MonetizationOnIcon from "@mui/icons-material/SettingsApplications";
import { Section } from "../../../../../../shared/components/Section";
import { BulkDynamicField } from "../BulkDynamicField";
import { InputAdornment } from "@mui/material";

export const Finance = () => {
  return (
    <Section title="Finance" avatar={<MonetizationOnIcon />}>
      <BulkDynamicField
        propertyName="requested_funding_advance"
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
      <BulkDynamicField
        propertyName="requested_funding_commission"
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
      <BulkDynamicField propertyName="take_advance" />
    </Section>
  );
};
