import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

interface ConfigStatusExpandedProps {
  isValid: boolean;
  onClick?: () => void;
}

export const ConfigStatusExpanded = ({
  isValid,
  onClick,
}: ConfigStatusExpandedProps) => {
  const classes = useStyles();
  return (
    <div className="ConfigurationStatus">
      {isValid ? (
        <Chip
          label="Configured"
          size="small"
          color="success"
          variant="filled"
          className={classes.configured}
        />
      ) : (
        <Chip
          label="Not Configured"
          size="small"
          color="error"
          variant="filled"
          onClick={onClick}
          className={classes.notConfigured}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  configured: {
    backgroundColor: "#4caf50",
  },
  notConfigured: {
    backgroundColor: "#f44336",
  },
}));

ConfigStatusExpanded.propTypes = {
  isValid: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};
