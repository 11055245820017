import React from "react";

import { pick } from "lodash";
import { CliBoltOns } from "../../../../../components/Mobile/CliBoltOns";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import {
  getAllProductInstanceBoltOns,
  getResignProductInstanceByTargetConfigs,
} from "../../../../../../../store/mobile/selectors/resigns";
import { useSelector } from "react-redux";

interface CliBoltOnsProps {
  mode?: "slot" | "type";
}

export const CliBoltOnsContainer = ({ mode = "type" }: CliBoltOnsProps) => {
  const { targetConfigs } = useTargetConfigsContext();

  const mobileConfigs = useSelector((state: any) => state.mobile.configs);
  const selectedMobileConfigs = Object.values(
    pick(mobileConfigs, ...targetConfigs)
  );
  const selectedMobileConfigIds = selectedMobileConfigs.map(
    (selectedMobileConfig) => selectedMobileConfig.productId
  );
  const allSameProducts = selectedMobileConfigIds.every(
    (val, i, arr) => val === arr[0]
  );
  const productInstance = useSelector((state) =>
    getResignProductInstanceByTargetConfigs(state, targetConfigs)
  );

  const existingResignBoltOns = getAllProductInstanceBoltOns(productInstance);

  return (
    <CliBoltOns
      mode={mode}
      targetConfigs={targetConfigs}
      existingResignBoltOns={existingResignBoltOns}
      allSameProducts={allSameProducts}
    />
  );
};
