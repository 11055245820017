import { createSelector } from "reselect";
import {
  getCreditVetFetching,
  getIsVfDirectCreditVetPassed,
} from "../account/selectors";
import { validateWlrBroadbandConfigs } from "../wlrBroadband/selectors";
import { validateEthernetConfigurations } from "../ethernetProducts/selectors";
import { STEP_DELIVERY } from "./constants";

/**
 * Decide if the stepper nav back button is disabled
 */
export const getBackButtonDisabled = createSelector(
  [(state: any) => state.account.createAccount],
  (createAccount) => {
    return createAccount.fetching;
  }
);

/**
 * Decide if the stepper nav next button is disabled
 */
export const getNextButtonDisabled = createSelector(
  [
    (state: any) => state.uiState.activeStep,
    (state: any) => state.account?.billingAddress?.response?.status,
    (state: any) => state.account?.settings?.response?.settings,
    (state: any) => getIsVfDirectCreditVetPassed(state),
    (state: any) => getCreditVetFetching(state),
    // TODO: Product data call failures etc. state.mobile.productData.xxxxx.response.status = "error"
  ],
  (
    activeStep,
    billingAddressStatus,
    accountSettings,
    vfDirectCreditVetPassed,
    getCreditVetFetching
  ) => {
    if (billingAddressStatus !== "success" && activeStep === STEP_DELIVERY)
      return true;

    const canAccessVfDirect = accountSettings?.can_access_vf_direct === "1";
    if (canAccessVfDirect && (getCreditVetFetching || !vfDirectCreditVetPassed))
      return true;

    return false;
  }
);

/**
 * Decide if the save and exit buttons should be disabled
 * Hide it when we are creating the account or the order/orderproducts
 * Probably going to have more conditions added to this over time
 */
export const getSaveAndExitButtonDisabled = createSelector(
  [
    (state: any) => state.account.createAccount,
    (state: any) => state.order.recalculatePrices,
    (state: any) => state.order.orderStatus,
    (state: any) => state.mobile.configs,
    (state: any) => state.wlrBroadband.configurations,
    (state: any) => state.hardwareProducts.configurations,
    (state: any) => state.universalProducts.configurations,
    (state: any) => state.monitoringService.configurations,
    (state: any) => state.ethernetProducts.configurations,
  ],
  (
    createAccount,
    recalculatePrices,
    orderStatus,
    mobileConfigs,
    wlrBBConfigs,
    hardwareConfigs,
    universalConfigs,
    monitoringServiceConfigs,
    ethernetConfigs
  ) => {
    if (
      createAccount.fetching ||
      recalculatePrices.fetching ||
      orderStatus.fetching
    ) {
      return true;
    }

    let productsAreBeingConfigured = false;

    if (Array.isArray(mobileConfigs)) {
      mobileConfigs.forEach((config) => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(wlrBBConfigs)) {
      wlrBBConfigs.forEach((config) => {
        if (
          config.addWlrAppointment?.fetching ||
          config.addBroadbandAppointment?.fetching ||
          config.orderProduct?.wlr?.fetching ||
          config.orderProduct?.broadband?.fetching
        ) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(hardwareConfigs)) {
      hardwareConfigs.forEach((config) => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(universalConfigs)) {
      universalConfigs.forEach((config) => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(monitoringServiceConfigs)) {
      monitoringServiceConfigs.forEach((config) => {
        if (config.orderProduct?.fetching) {
          productsAreBeingConfigured = true;
        }
      });
    }

    if (Array.isArray(ethernetConfigs)) {
      ethernetConfigs.forEach((config) => {
        if (config.purchaseIsRequesting) {
          productsAreBeingConfigured = true;
        }
      });
    }

    return productsAreBeingConfigured;
  }
);

export const getSendQuoteButtonDisabled = createSelector(
  [
    (state: any) => state,
    (state: any) => state.wlrBroadband.configurations,
    (state: any) => state.ethernetProducts.configurations,
  ],
  (state, wlrBroadbandConfigs, ethernetConfigs) => {
    const areWlrBroadbandConfigsValid = validateWlrBroadbandConfigs(state);
    const areEthernetConfigsValid = validateEthernetConfigurations(state);

    if (
      (wlrBroadbandConfigs?.length && !areWlrBroadbandConfigsValid) ||
      (ethernetConfigs?.length && !areEthernetConfigsValid)
    )
      return true;

    return false;
  }
);
