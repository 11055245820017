import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { postPricingRequest } from "../../../../store/order/actions";
import { AddPricingRequest } from "../../components/CommercialPricingRequest/AddPricingRequest";

interface AddPricingRequestProps {
  handleClose: () => void;
}

export const AddPricingRequestContainer = ({
  handleClose,
}: AddPricingRequestProps) => {
  const dispatch = useDispatch();
  const pricingRequest = useSelector(
    (state: any) => state.order.pricingRequest
  );
  const [message, setMessage] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };
  const handleSubmit = async () => {
    dispatch(postPricingRequest(message));
  };

  return (
    <AddPricingRequest
      description={message}
      isFetching={pricingRequest.fetching}
      handleClose={handleClose}
      onSubmit={handleSubmit}
      onDescriptionChange={handleChange}
    />
  );
};
