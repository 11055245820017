import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";
import { updateEthernetConfiguration } from "../../../../store/ethernetProducts/actions";
import { OrderType } from "../types";

interface EthernetOrderTypeSelectProps {
  configIndex: number;
}

/**
 * Select ethernet order type.
 * This is only for the purposes of displaying info to the user.
 * It doesn't have any bearing on the order itself as only NEW provides are possible
 * TP56370
 */
export const EthernetOrderTypeSelect = ({
  configIndex,
}: EthernetOrderTypeSelectProps) => {
  const dispatch = useDispatch();
  const orderType =
    useSelector(
      (state: any) =>
        state.ethernetProducts.configurations[configIndex].orderType
    ) || "";

  const disabled = !!configIndex;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateEthernetConfiguration(
        configIndex,
        "orderType",
        (event.target as HTMLInputElement).value
      )
    );
  };

  return (
    <div>
      <FormControl variant="standard" component="fieldset">
        <RadioGroup
          aria-label="order-type"
          name="order-type"
          value={orderType}
          onChange={handleChange}
          style={{ flexDirection: "row" }}
        >
          <FormControlLabel
            value={OrderType.NEW}
            control={<Radio />}
            label="New Connection"
            disabled={disabled}
          />
          <FormControlLabel
            value={OrderType.RENEWAL}
            control={<Radio />}
            label="Renewal / Upgrade"
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
      {orderType === OrderType.RENEWAL && (
        <Box mt={1}>
          <StatusChip
            type="info"
            title="Sorry"
            message="This order should be processed manually in Daisy Central."
          />
        </Box>
      )}
    </div>
  );
};
