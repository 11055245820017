import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountSettings } from "../account/selectors";
import { getAccountId } from "../order/selectors";
import * as ProductAPI from "../../api/v1/product";
import * as OrderProductAPI from "../../api/v1/orderProduct";
import _ from "lodash";

export const setAlertPreferences = createAction(
  "monitoringService/setAlertPreferences"
);
export const addProduct = createAction<any>("monitoringService/addProduct");
export const removeProduct = createAction<number>(
  "monitoringService/removeProduct"
);

export const clearConfig = createAction("monitoringService/clearConfig");

export const fetchProductSearch = createAsyncThunk(
  "monitoringService/fetchProductSearch",
  async (arg, { getState }) => {
    const state: any = getState();
    const response = await ProductAPI.search({
      account_id: getAccountId(state),
      category_id: getAccountSettings(state).category_id_logic_monitor,
    });
    return response;
  }
);

export const orderLogicMonitorProducts = createAsyncThunk(
  "monitoringService/orderLogicMonitorProducts",
  async (arg, { getState, dispatch }) => {
    const state: any = getState();
    const promises: any[] = [];
    state.monitoringService.configs.map((config: any, configIndex: number) => {
      const configProductId = config.productId;
      const orderProductId = _.get(config, "orderProduct.response.data.id");
      const orderParams = {
        contract_length_in_months: state.order.contractLength,
        account_id: state.order.accountId,
        order_id: state.order.id,
      };
      const isUpdate = !!orderProductId;
      return promises.push(
        dispatch(
          orderProduct({
            configIndex,
            configProductId,
            orderParams,
            orderProductId,
            isUpdate,
          })
        )
      );
    });
    return await Promise.all(promises);
  }
);

export const orderProduct = createAsyncThunk(
  "monitoringService/orderProduct",
  async (arg: {
    configIndex: number;
    configProductId: string;
    orderParams: any;
    orderProductId: string;
    isUpdate: boolean;
  }) => {
    const { configProductId, orderParams, orderProductId, isUpdate }: any = arg;
    return isUpdate
      ? await OrderProductAPI.update(orderProductId, orderParams)
      : await OrderProductAPI.create(configProductId, orderParams);
  }
);
