import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";
import {
  MobileConfigRowDisplayData,
  Network,
} from "../../../../shared/types/MobileProductConfig";

interface Props {
  configsData: {
    name: string;
    network: Network;
    config: MobileConfigRowDisplayData;
  }[];
}

export const Services = ({ configsData }: Props) => {
  const canAccessVfDirect = useCanAccessVfDirect();
  const hasO2Products = !!configsData.find((c: any) => c.network === "O2");

  return (
    <Section title="Services" avatar={<LanguageIcon />} defaultExpanded={false}>
      <div className="flex-row">
        {!canAccessVfDirect ? (
          <>
            {hasO2Products ? (
              <div className="col">
                <BulkDynamicField propertyName="service_visual_voicemail" />
              </div>
            ) : null}
            <div className="col">
              <BulkDynamicField propertyName="service_conference_calling" />
            </div>
            <div className="col">
              <BulkDynamicField propertyName="service_wifi_calling" />
            </div>
          </>
        ) : null}
        <div className="col">
          <BulkDynamicField propertyName="service_5g" />
        </div>
        <div className="col">
          <BulkDynamicField propertyName="service_mms" />
        </div>
      </div>
    </Section>
  );
};
