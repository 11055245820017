import React from "react";
import { useSelector } from "react-redux";

import { hasResigns } from "../../../../../store/wlrBroadband/selectors";
import { ResignsConfigurationTable } from "../../../components/wlrBroadband/ResignsConfigurationTable";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import { useCanAccessCommission } from "../../../../../shared/hooks/useAccountSettings";

interface ResignConfigTableRowProps {
  checked: boolean;
  configCount: number;
  toggleAction: (index: number) => void;
  editAction: (index: number) => void;
  deleteAction: (index: number) => void;
  retryAction: () => void;
  toggleAll: () => void;
}

export const ResignsConfigurationTableContainer = (
  props: ResignConfigTableRowProps
) => {
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  const showCommissionType = useCanAccessCommission("wlr");

  const { targetConfigs } = useTargetConfigsContext();

  return (
    <ResignsConfigurationTable
      {...props}
      hasResigns={hasResigns(configurations)}
      selectedConfigs={targetConfigs}
      configurations={configurations}
      showCommissionType={showCommissionType}
    />
  );
};
