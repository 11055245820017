import React from "react";
import Box from "@mui/material/Box";
import TextsmsIcon from "@mui/icons-material/Textsms";

import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

export const SmsNotification = () => (
  <Box display="flex" alignItems="center">
    <Box mr={1}>
      <TextsmsIcon />
    </Box>
    <BulkDynamicField propertyName="notification_contact" />
  </Box>
);
