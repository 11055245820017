import React from "react";

import { FormControlLabel, Switch } from "@mui/material";

interface ResignsToggleProps {
  handleShowResigns: () => void;
  showMobileResigns: boolean;
}
export const ResignsToggle = ({
  showMobileResigns,
  handleShowResigns,
}: ResignsToggleProps) => (
  <FormControlLabel
    data-cy={"mobileResignsToggle"}
    control={
      <Switch onChange={handleShowResigns} checked={showMobileResigns} />
    }
    label="Show contracts available for resign"
  />
);
