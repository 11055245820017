import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResignStartDate } from "../../../../store/wlrBroadband/actions/resigns";
import { ResignStartDate } from "../../components/wlrBroadband/ResignStartDate";

export const ResignStartDateContainer = () => {
  const dispatch = useDispatch();
  const resignStartDate = useSelector(
    (state: any) => state.wlrBroadband.resignStartDate
  );

  return (
    <ResignStartDate
      resignStartDate={resignStartDate}
      onDateChange={(date) => dispatch(setResignStartDate(date))}
    />
  );
};
