import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { IndexedTitle } from "@akj-dev/shared-components";

import { WlrLocationProductCardContainer } from "../../containers/WlrLocation/ProductCard";
import { EXISTING_LINE } from "../../../../../store/wlrBroadband/constants";
import { ExistingSoGEAAlertDialog } from "../../components/ExistingSoGEAAlertDialog";
import { WlrLocationTypeEnum } from "../../enums";

interface BroadbandOnlyProps {
  isWlrChangePossible: boolean;
  locationIndex: number;
  sogeaProducts: Record<string, any>[];
  onSetOtherConfiguration: (
    locationIndex: number,
    product: Record<string, any>
  ) => void;
}

export const BroadbandOnlySection = ({
  isWlrChangePossible,
  locationIndex,
  sogeaProducts,
  onSetOtherConfiguration,
}: BroadbandOnlyProps) => {
  const [existingSoGEAAlertOpen, setExistingSoGEAAlertOpen] = useState(false);

  const existingLineTitle = isWlrChangePossible
    ? "Existing Daisy Line"
    : "Existing Line (non-Daisy)";
  const existingLineSubtitle = isWlrChangePossible
    ? "Change requests can be made on the next step"
    : "£0.00";

  return (
    <>
      <div>
        <Typography variant="h4" gutterBottom>
          <IndexedTitle
            index="C"
            title="Choose existing line"
            withMargin={true}
            negative={true}
          />
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <WlrLocationProductCardContainer
              locationIndex={locationIndex}
              productId={EXISTING_LINE}
              title={existingLineTitle}
              subtitle={existingLineSubtitle}
              handleClick={() =>
                onSetOtherConfiguration(locationIndex, { id: EXISTING_LINE })
              }
            />
          </Grid>
          {sogeaProducts?.length > 0 && (
            <Grid item sm={6}>
              <WlrLocationProductCardContainer
                locationIndex={locationIndex}
                productId={WlrLocationTypeEnum.EXISTING_SOGEA}
                title={WlrLocationTypeEnum.EXISTING_SOGEA}
                subtitle={"£0.00"}
                handleClick={() => {
                  setExistingSoGEAAlertOpen(true);
                  onSetOtherConfiguration(locationIndex, {
                    id: WlrLocationTypeEnum.EXISTING_SOGEA,
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <ExistingSoGEAAlertDialog
        open={existingSoGEAAlertOpen}
        onClose={() => setExistingSoGEAAlertOpen(false)}
      />
    </>
  );
};
