import React from "react";
import { useSelector } from "react-redux";

import {
  getProductInstances,
  getProducts,
} from "../../../../../store/wlrBroadband/selectors";

import { BroadbandProductOverview } from "./BroadbandProductOverview";
import { LineProductOverview } from "./LineProductOverview";
import { StandardProductBaseProps } from "./model";

export const StandardProduct = ({
  configuration,
  isChecked,
  isLinkedProductInstance,
  productInstance,
  toggleRow,
  setChanges,
  setNoChanges,
  removeResign,
}: Omit<StandardProductBaseProps, "products">) => {
  const locationIndex = configuration.locationIndex;

  const products = useSelector<any, any[]>((state) =>
    getProducts(state, locationIndex)
  );

  const productInstances = useSelector(getProductInstances);

  return productInstance.type === "wlr" ? (
    <LineProductOverview
      configuration={configuration}
      isChecked={isChecked}
      toggleRow={toggleRow}
      productInstance={productInstance}
      setChanges={setChanges}
      setNoChanges={setNoChanges}
      removeResign={removeResign}
      isLinkedProductInstance={isLinkedProductInstance}
      products={products}
      productInstances={productInstances}
    />
  ) : (
    <BroadbandProductOverview
      configuration={configuration}
      isChecked={isChecked}
      toggleRow={toggleRow}
      productInstance={productInstance}
      setChanges={setChanges}
      setNoChanges={setNoChanges}
      removeResign={removeResign}
      isLinkedProductInstance={isLinkedProductInstance}
      products={products}
    />
  );
};
