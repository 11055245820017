import React from "react";

import { NoProductChangesRow } from "../../components/Resigns/StandardProductInstance/NoProductChangesRow";
import { ProductChangesRow } from "../../components/Resigns/StandardProductInstance/ProductChangesRow";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { LineProductSelectorContainer } from "../../containers/Resigns/LineProductSelector";

import { StandardProductBaseProps } from "./model";
import { RowActionsContainer } from "../../containers/Resigns/RowActions";

interface LineProductOverviewProps extends StandardProductBaseProps {
  productInstances: Record<string, any>[];
}

export const LineProductOverview = ({
  configuration,
  isChecked,
  productInstance,
  isLinkedProductInstance,
  products,
  productInstances,
  setChanges,
  setNoChanges,
  removeResign,
  toggleRow,
}: LineProductOverviewProps) => {
  const resignProduct = products.find(
    (product) => product.id === configuration.wlrProductId
  );

  const canAddBroadband =
    productInstances.filter((item) => item.pin === productInstance.pin)
      .length === 1;

  return (
    <>
      {configuration.wlrProductId &&
      configuration.wlrProductId !== "Resign" &&
      resignProduct ? (
        <ProductChangesRow
          configuration={configuration}
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          resignProduct={resignProduct}
          canAddBroadband={canAddBroadband} // Need account with product instance to test.
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              removeResign={removeResign}
              hasProductId={configuration.wlrProductId}
            />
          }
        />
      ) : (
        <NoProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          isLinkedProductInstance={isLinkedProductInstance}
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              setChanges={setChanges}
              setNoChanges={setNoChanges}
              removeResign={removeResign}
              hasProductId={configuration.wlrProductId}
            />
          }
        />
      )}
      {(configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
        configuration.resignProductType === NEW_PRODUCT) &&
        !configuration.wlrProductId && (
          <LineProductSelectorContainer
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};
