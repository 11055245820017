import React from "react";

import { Button } from "@mui/material";

interface FindProductsProps {
  disabled: boolean;
  onClick: () => void;
}

export const FindProducts = ({ disabled, onClick }: FindProductsProps) => (
  <Button
    variant={disabled ? "outlined" : "contained"}
    color="primary"
    onClick={onClick}
    disabled={disabled}
    sx={{ marginTop: "2px" }}
  >
    Find Products
  </Button>
);
