import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { fetchShippingMethods, updateShippingMethodInState } from "./actions";

const shipping = createSlice({
  name: "shipping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateShippingMethodInState, (state, action) => {
        state.chosenProductId = action.payload;
      })
      .addCase(fetchShippingMethods.pending, (state, action) => {
        state.isFetchingShipping = true;
      })
      .addCase(fetchShippingMethods.rejected, (state, action) => {
        state.isFetchingShipping = false;
      })
      .addCase(fetchShippingMethods.fulfilled, (state, action) => {
        state.isFetchingShipping = false;
        state.products = action.payload;
        state.chosenProductId = 0;
      });
  },
});

export default shipping.reducer;
