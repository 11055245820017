import * as ProductAPI from "../../../api/v1/product";
import { getAccountSettings } from "../../account/selectors";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const search = createAsyncThunk(
  "hardwareProducts/search",
  async (arg, { getState }) => {
    // TP15998
    const state: any = getState();
    const settings = getAccountSettings(state);
    const asBlankSimEnabled = settings.as_blank_sim_enabled;
    const { contractLengthInMonthsAllProducts } = state.mobile;
    const response = await ProductAPI.hardwareSearch(
      state.order.accountId,
      false,
      asBlankSimEnabled
    );
    return { response, contractLengthInMonthsAllProducts };
  }
);
