import React from "react";
import DateIcon from "@mui/icons-material/DateRange";
import { DC_DATE_FORMAT } from "../../../../shared/utils/date";
import { ExpandingCard } from "@akj-dev/shared-components";
import { StatusChip } from "@akj-dev/shared-components";
import { format } from "date-fns";
import { ParsableDate } from "typings";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

interface CustomerRequiredDateProps {
  handleHeaderClick: () => void;
  handleChangeDate: (date?: string | null) => void;
  isExpanded: boolean;
  isError: boolean;
  minDate: ParsableDate;
  customerRequiredDate: ParsableDate;
}

export const CustomerRequiredDate = ({
  isExpanded,
  isError,
  minDate,
  customerRequiredDate,
  handleHeaderClick,
  handleChangeDate,
}: CustomerRequiredDateProps) => (
  <ExpandingCard
    title="Customer Required Date"
    avatar={<DateIcon />}
    expanded={isExpanded}
    onHeaderClick={handleHeaderClick}
  >
    <DatePicker
      value={customerRequiredDate as string}
      label={
        isError
          ? "This is a required field"
          : "Minimum lead time is 45 working days"
      }
      disablePast
      onChange={(value: any) =>
        handleChangeDate(format(new Date(value as string), DC_DATE_FORMAT))
      }
      minDate={format(minDate as Date, DC_DATE_FORMAT)}
      inputFormat={"dd/MM/yyyy"}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          size="small"
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
    />
    <StatusChip
      type="info"
      message="This is not a guaranteed install date, Daisy will aim to meet this requested date but only once a site survey has been completed will a date be confirmed."
    />
  </ExpandingCard>
);
