import React from "react";
import { useSelector } from "react-redux";

import { RequestState } from "../components/RequestState";

export const RequestStateContainer = () => {
  const accountFetching = useSelector(
    (state: any) => state.account.createAccount.fetching
  );
  const accountResponse = useSelector(
    (state: any) => state.account.createAccount.response
  );
  const contactFetching = useSelector(
    (state: any) => state.account.addContact.fetching
  );
  const contactResponse = useSelector(
    (state: any) => state.account.addContact.response
  );

  let errorList = accountResponse.message?._errors; //DC responses still a little bit of a mess, '_errors' gets VF direct errors that 'errors' doesn't, but doesn't work with most DC validation atm
  if (
    !!accountResponse.message?.errors &&
    Object.values(accountResponse.message.errors).length
  ) {
    errorList = [];
    Object.values(accountResponse.message.errors).forEach(
      (error_array: any) => {
        errorList = errorList.concat(error_array);
      }
    ); // 'errors' is a map of arrays of maps, flattening to make behave like '_errors' which is array of maps
  }

  return (
    <RequestState
      accountResponse={accountResponse}
      isAccountError={accountResponse.status === "error"}
      isContactError={contactResponse.status === "error"}
      contactErrorMessage={contactResponse.message}
      contactFetching={contactFetching}
      accountFetching={accountFetching}
      accountErrors={errorList}
    />
  );
};
