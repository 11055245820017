import React from "react";
import { ExpandingCard } from "@akj-dev/shared-components";
import FolderIcon from "@mui/icons-material/Folder";

import { TableOuter } from "./TableOuter";

import { ConfigTableRowContainer } from "../../containers/UniversalProducts/ConfigTable/ConfigTableRow";

interface UniversalProductsConfigTableProps {
  configCount: number;
}

export const ConfigTable = ({
  configCount,
}: UniversalProductsConfigTableProps) => (
  <ExpandingCard
    title="Other Products"
    subtitle={`${configCount} Selected`}
    avatar={<FolderIcon />}
    defaultExpanded
  >
    <TableOuter>
      {Array(configCount)
        .fill("")
        .map((_, i) => (
          // No stable identity
          // eslint-disable-next-line react/no-array-index-key
          <ConfigTableRowContainer key={i} configIndex={i} />
        ))}
    </TableOuter>
  </ExpandingCard>
);
