import React, { ReactNode } from "react";
import styled from "styled-components";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import PhoneIcon from "@mui/icons-material/Phone";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import ExtensionIcon from "@mui/icons-material/Extension";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { SimCard as SimIcon } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import { Chip, SvgIconTypeMap } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Typography from "@mui/material/Typography";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import vodaIcon from "../../../images/voda@2x.png";
import o2Icon from "../../../images/o2@2x.png";
import diseIcon from "../../../images/dise@2x.png";
import { formatCurrency } from "../../../shared/utils/formatCurrency";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";
import { useAccountSettings } from "../../../shared/hooks/useAccountSettings";
import { OrderViewProductRow } from "./OrderViewProductRow";

const useStyles = makeStyles({
  oldPrice: {
    textDecoration: "line-through",
  },
});

const SupplierImg = styled.img`
  vertical-align: middle;
  margin: 0 10px 0 0;
`;

const ProductIcon = ({
  Icon,
  indent = false,
}: {
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  indent?: boolean;
}) => (
  <Icon
    color="disabled"
    style={{
      verticalAlign: "middle",
      marginLeft: indent ? 40 : 0,
      marginRight: 10,
    }}
  />
);

const Quantity = styled(Chip)`
  margin-right: 10px;
  background: ${(p) => p.theme.palette.info.main};
  color: white;
  font-weight: bold;
`;

const MobileNumber = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

const SimNumber = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

const Username = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
`;

const AirtimeCredit = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  color: ${(p) => p.theme.palette.text.disabled};
  span {
    font-weight: bold;
  }
`;

const AddCircle = styled(AddCircleIcon)`
  vertical-align: middle;
  margin-right: 10px;
  color: ${(p) => p.theme.palette.success.main};
`;

const RemoveCircle = styled(RemoveCircleIcon)`
  vertical-align: middle;
  margin-right: 10px;
  color: ${(p) => p.theme.palette.error.main};
`;

const Finance = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`;

const iconMap: Record<
  string,
  OverridableComponent<SvgIconTypeMap<{}, "svg">>
> = {
  broadband: SettingsPhoneIcon,
  broadband_extras: WorkOutlineIcon,
  calls: PhoneIcon,
  credit: MonetizationOnIcon,
  discount: MonetizationOnIcon,
  extras: WorkOutlineIcon,
  extra_services: AutorenewIcon,
  hardware: PhonelinkIcon,
  leased_line: PhonelinkIcon,
  line: PhoneIcon,
  line_extras: WorkOutlineIcon,
  linked_to_line: PhoneIcon,
  linked_to_mobile: PhoneAndroidIcon,
  linked_to_product_instance: WorkOutlineIcon,
  maintenance: AutorenewIcon,
  mobile: PhoneAndroidIcon,
  mobile_bolt_on: ExtensionIcon,
  system: ExtensionIcon,
  universal: ExtensionIcon,
  voip: PhonelinkIcon,
};

interface OrderViewTableProps {
  sortedProducts: any[];
}

export const OrderViewItemRows = ({ sortedProducts }: OrderViewTableProps) => {
  const classes = useStyles();
  const canAccessVfDirect = useCanAccessVfDirect();
  const accountSettings = useAccountSettings();

  const recurringPriceWithPromotion = (product: any) => {
    const standardPrice =
      product.price.first_bill_recurring_price_with_promotions;

    if (product.pricing === undefined) {
      return formatCurrency(standardPrice);
    }

    const priceWithPromotions =
      product.pricing.product.first_bill_recurring_price_with_promotions ||
      standardPrice;
    const priceWithoutPromotions =
      product.pricing.product.recurring_price_without_promotions ||
      standardPrice;
    const promotionLength = product.pricing.product.promotion_length_in_months;

    if (priceWithPromotions === priceWithoutPromotions) {
      return formatCurrency(priceWithPromotions);
    }

    return promotionLength === null ? (
      <span>
        <span className={classes.oldPrice}>
          {formatCurrency(priceWithoutPromotions)}
        </span>{" "}
        {formatCurrency(priceWithPromotions)} with promotion
      </span>
    ) : (
      <span>
        <span className={classes.oldPrice}>
          {formatCurrency(priceWithoutPromotions)}
        </span>{" "}
        {formatCurrency(priceWithPromotions)} for {promotionLength}{" "}
        {promotionLength === 1 ? "Month" : "Months"}
      </span>
    );
  };

  return (
    <>
      {sortedProducts.map((product) => {
        // Products can come back with no components. Might be a DC bug?
        // Deal with it anyway.....
        const firstComponent = product.components[0] || {};

        // If it's a CLI bolt-on, don't show it in the top level. We'll show it under it's parent line
        // Note this check could also be done with `firstComponent.module === 'mobile_bolt_on'`
        // but it seems `mobile_component_id` might cover other eventualities too.
        if (firstComponent.mobile_component_id) return null;

        // If it's a data only component with a linked parent voice component, don't show it on the top level either
        // Like bolt-ons, we'll show it under the parent.
        if (
          firstComponent.is_data_only &&
          sortedProducts.find(
            (p) => p.components[0].gprs_component_id === firstComponent.id
          )
        )
          return null;

        const row: (product: any) => ReactNode = (product) => {
          if (!product) return null;
          const firstComponent = product.components[0] || {};
          const isSubComponent = !!(
            firstComponent.mobile_component_id ||
            (firstComponent.is_data_only &&
              sortedProducts.find(
                (p) => p.components[0].gprs_component_id === firstComponent.id
              ))
          );
          // If there has been credit used against a product (hardware), deduct this off the one off product price
          // as per: https://auroratarget.tpondemand.com/entity/6938-review-api-response-for-hw-credit.
          const oneOffPrice = firstComponent.credit_used
            ? formatCurrency(
                parseFloat(product.price.one_off_price_with_promotions) -
                  parseFloat(firstComponent.credit_used)
              )
            : formatCurrency(product.price.one_off_price_with_promotions);

          const recurringPrice = canAccessVfDirect
            ? formatCurrency(
                firstComponent.original_recurring_price ||
                  firstComponent.current_recurring_price
              )
            : recurringPriceWithPromotion(product);

          const productDescription = (
            <>
              <ProductIcon
                Icon={iconMap[firstComponent.module as string] || AddCircleIcon}
              />
              {firstComponent.quantity > 1 && (
                <Quantity size="small" label={firstComponent.quantity}>
                  {firstComponent.quantity}
                </Quantity>
              )}
              {firstComponent.module === "mobile_bolt_on" ? (
                firstComponent.is_removal ? (
                  <RemoveCircle />
                ) : (
                  <AddCircle />
                )
              ) : (
                false
              )}
              {(() => {
                switch (firstComponent.supplier) {
                  case "O2":
                    return <SupplierImg src={o2Icon} width={32} title="O2" />;
                  case "Vodafone":
                  case "VF Direct":
                    return (
                      <SupplierImg src={vodaIcon} width={32} title="Vodafone" />
                    );
                  case "Dise":
                    return (
                      <SupplierImg src={diseIcon} width={32} title="Dise" />
                    );
                  default:
                    return null;
                }
              })()}
              <span
                style={
                  firstComponent.gprs_component_id ? { color: "#999" } : {}
                }
              >
                {product.name}
              </span>
              {firstComponent.mobile_number && (
                <MobileNumber>
                  <Chip
                    icon={
                      firstComponent.acquisition_method === "resign" ? (
                        <SettingsBackupRestoreIcon />
                      ) : (
                        <PhoneIcon />
                      )
                    }
                    label={firstComponent.mobile_number}
                    variant="filled"
                    color="default"
                  />
                </MobileNumber>
              )}
              {accountSettings.show_sim_in_summary &&
                firstComponent.sim_buffer_serial &&
                firstComponent.sim_type !== "esim" && (
                  <SimNumber>
                    <Chip
                      icon={<SimIcon />}
                      label={firstComponent.sim_buffer_serial}
                      variant="filled"
                      color="default"
                    />
                  </SimNumber>
                )}
              {accountSettings.show_sim_in_summary &&
                firstComponent.sim_type === "esim" && (
                  <SimNumber>
                    <Chip
                      icon={<SimIcon />}
                      label={"E-SIM"}
                      variant="filled"
                      color="default"
                    />
                  </SimNumber>
                )}
              {firstComponent.airtime_credit_amount && (
                <AirtimeCredit>
                  Airtime Credit:{" "}
                  <span>£{firstComponent.airtime_credit_amount}</span> /{" "}
                  {firstComponent.airtime_credit_duration} month
                  {firstComponent.airtime_credit_duration > 1 && "s"}
                </AirtimeCredit>
              )}
              {firstComponent.airtime_credit_amount_oneoff && (
                <AirtimeCredit>
                  Airtime One Off Credit: £
                  {firstComponent.airtime_credit_amount_oneoff}
                </AirtimeCredit>
              )}
              {firstComponent.user_name && (
                <Username>
                  <Typography variant="body2" color="textSecondary">
                    {firstComponent.user_name.toUpperCase()}
                  </Typography>
                </Username>
              )}
            </>
          );

          return (
            <OrderViewProductRow
              productDescription={productDescription}
              indent={isSubComponent}
              oneOffPrice={oneOffPrice}
              recurringPrice={recurringPrice}
              isConfigured={product.is_configured}
              key={product.id}
            />
          );
        };
        const cliBoltOns = sortedProducts.filter(
          (p) => p.components[0].mobile_component_id === firstComponent.id
        );

        const linkedGprsProducts = sortedProducts.filter(
          (p) => p.components[0].id === firstComponent.gprs_component_id
        );

        const productRows = [
          row(product),
          ...cliBoltOns.map(row),
          ...linkedGprsProducts.map(row),
        ];

        if (canAccessVfDirect) {
          // for VFDirect if a "shared_discount" is included in the product component, we represent this as a negative recurring price with an additional row
          // the server side reported price total will account for this reduction from the total recurring price.
          if (parseFloat(firstComponent.shared_discount) > 0) {
            productRows.push(
              <OrderViewProductRow
                productDescription={
                  <>
                    <ProductIcon Icon={ExtensionIcon} />
                    <AddCircle />
                    Shared Discount
                  </>
                }
                oneOffPrice="£0.00"
                recurringPrice={`-${formatCurrency(
                  firstComponent.shared_discount
                )}`}
                indent
                isConfigured
                key={`${product.id}-shared_discount`}
              />
            );
          }
          // TP65069.
          if (parseFloat(firstComponent.requested_funding_advance) > 0) {
            productRows.push(
              <OrderViewProductRow
                productDescription={
                  <>
                    <ProductIcon Icon={ExtensionIcon} />
                    <AddCircle />
                    Requested Approved Bonus
                    <Finance>
                      <Chip
                        icon={<MonetizationOnIcon />}
                        label={firstComponent.requested_funding_advance}
                        variant="filled"
                        color="default"
                      />
                    </Finance>
                  </>
                }
                oneOffPrice="£0.00"
                recurringPrice="£0.00"
                indent
                isConfigured
                key={`${product.id}-requested_funding_advance`}
              />
            );
          }
          // TP65069.
          if (parseFloat(firstComponent.requested_funding_commission) > 0) {
            productRows.push(
              <OrderViewProductRow
                productDescription={
                  <>
                    <ProductIcon Icon={ExtensionIcon} />
                    <AddCircle />
                    Requested Additional Funding
                    <Finance>
                      <Chip
                        icon={<MonetizationOnIcon />}
                        label={firstComponent.requested_funding_commission}
                        variant="filled"
                        color="default"
                      />
                    </Finance>
                  </>
                }
                oneOffPrice="£0.00"
                recurringPrice="£0.00"
                indent
                isConfigured
                key={`${product.id}-requested_funding_commission`}
              />
            );
          }
        }

        return productRows;
      })}
    </>
  );
};
