import React from "react";

import { Grid } from "@mui/material";

import { ContractLength } from "../../../../shared/containers/ContractLength";
import { ResignsToggleContainer } from "../containers/Resigns";
import { IndexedCardInfo } from "@akj-dev/shared-components";

interface ContractSectionProps {
  showResignsToggle: boolean;
  cardIndex: number;
}

export const ContractSection = ({
  showResignsToggle,
  cardIndex,
}: ContractSectionProps) => (
  <IndexedCardInfo
    index={cardIndex}
    title="Please select the required contract length"
  >
    <Grid container spacing={2} alignItems="center">
      <ContractLength />
      {showResignsToggle && (
        <Grid item md={12}>
          <ResignsToggleContainer />
        </Grid>
      )}
    </Grid>
  </IndexedCardInfo>
);
