import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { StatusChip } from "@akj-dev/shared-components";

import { Loader } from "../../../../../shared/components/Loader";
import { getNetworkIcon } from "../../utils";
import { boltOnFields } from "./constants";
import { BoltOnSelectFieldContainer } from "../../containers/BoltOnTable/BoltOnSelectField";

interface BoltOnTableProps {
  showLoader: boolean;
  errorMessage?: string | false;
  providers: { id: string; label: string }[];
  onRetry: () => void;
}

export const BoltOnTable = ({
  showLoader,
  errorMessage,
  providers,
  onRetry,
}: BoltOnTableProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.table}>
      {showLoader && <Loader />}
      {!showLoader && !errorMessage && (
        <Table data-cy="BoltOnsTable">
          <TableHead>
            <TableRow>
              <TableCell>Carrier</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Voice</TableCell>
              <TableCell>International</TableCell>
              <TableCell>Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providers.map((provider) => (
              <TableRow
                data-cy={`boltOn-provider-${provider.label.toLowerCase()}`}
                key={provider.id}
              >
                <TableCell>
                  <img src={getNetworkIcon(provider.label)} width="40" alt="" />
                </TableCell>
                {boltOnFields.map((field) => (
                  <TableCell
                    data-cy={`boltOn-field-${field.label.toLowerCase()}`}
                    key={field.id}
                  >
                    <BoltOnSelectFieldContainer
                      boltOnType={field.id}
                      serviceProviderId={provider.id}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!!errorMessage && (
        <StatusChip
          type="error"
          title="Error"
          message={errorMessage}
          retry={onRetry}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  table: {
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
});
