import React from "react";
import styled from "styled-components";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

interface AddressChipProps {
  address: ReactNode;
  remove: () => void;
}

export const AddressChip = ({ address, remove }: AddressChipProps) => (
  <Address>
    {address}
    <Action>
      <IconButton onClick={remove} size="large">
        <CloseIcon htmlColor="white" />
      </IconButton>
    </Action>
  </Address>
);

const Address = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  background: ${(p) => p.theme.palette.primary.main};
  border-radius: 5px;
  color: white;
`;

const Action = styled.div`
  flex: 1;
  text-align: right;
`;
