import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as AffinitySupportAPI from "../../api/v1/affinitySupport";
import { addFlashMessage, addAlertMessage } from "../uiState/actions";

export const close = createAction("supportCase/close");
export const screenshot = createAction<any>("supportCase/screenshot");
export const update = createAction<any>("supportCase/update");
export const invalid = createAction("supportCase/invalid");

export const open = createAsyncThunk(
  "supportCase/open",
  async (arg, { dispatch }) => {
    const html2canvas = require("html2canvas");
    const canvas = await html2canvas(document.body);
    dispatch(screenshot(canvas.toDataURL()));
    return;
  }
);

export const create = createAsyncThunk(
  "supportCase/create",
  async (arg, { dispatch, getState, rejectWithValue }) => {
    const state: any = getState();
    const caseDetails = state.supportCase;
    const order = state.order;

    if (!caseDetails.short_description || !caseDetails.description) {
      return dispatch(invalid());
    }

    try {
      const supportCase = await AffinitySupportAPI.Create({
        account: order.accountId,
        order: order.id,
        ...caseDetails,
      });

      if (supportCase.status === "error") throw new Error(supportCase.message);
      console.info(supportCase);

      dispatch(
        addFlashMessage("Support case #" + supportCase.ticket + " was created.")
      );
      return supportCase;
    } catch (error) {
      dispatch(addAlertMessage(error));
      rejectWithValue(error);
    }
  }
);
