import React, { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setFields,
  toggleExpand,
} from "../../../../store/hardware/configurations/actions";
import {
  configurationTotalDaisyFreshPrice,
  configurationTotalOneOffPrice,
  getTotalOrderHardwareCredit,
  totalDaisyFreshPrice,
  totalOneOffPrice,
  totalQuantity,
} from "../../../../store/hardware/configurations/selectors";
import {
  getHardwareCount,
  getHasDaisyFreshHardware,
} from "../../../../store/hardware/selectors";
import { HardwareTable } from "../../components/Hardware/Table";
import { getTableRows } from "../../components/Hardware/TableRows";

export const Products = () => {
  const dispatch = useDispatch();

  const products = useSelector(
    (state: any) => state.hardwareProducts.response.products
  );
  const configurations = useSelector(
    (state: any) => state.hardwareConfigurations
  );
  const availableHardwareCredit = useSelector<any, number>(
    (state) => state.account.availableHardwareCredit
  );
  const isFetchingAvailableHardwareCredit = useSelector<any, boolean>(
    (state) => state.account.isFetchingAvailableHardwareCredit
  );
  const hasConnections = useSelector<any, boolean>(
    (state) => state.mobile.configs.length > 0
  );
  const contractLengthInMonthsAllProducts = useSelector<any, number>(
    (state) => state.mobile.contractLengthInMonthsAllProducts
  );
  const hardwareCount = useSelector<any, number>((state) =>
    getHardwareCount(state)
  );
  const hasDaisyFresh = useSelector<any, boolean>((state) =>
    getHasDaisyFreshHardware(state)
  );

  const configurationsWithQuantity = _.filter(
    configurations,
    (config) => !!config.quantity || !!config.daisyFreshQuantity
  );

  const totalHWCredit = getTotalOrderHardwareCredit(configurationsWithQuantity);
  const remainingHWCredit = availableHardwareCredit - totalHWCredit;

  let rows: ReactNode[] = [];

  _.forEach(configurationsWithQuantity, (config) => {
    const product = products.find(
      (p: { id: number | string }) => p.id == config.product.id // eslint-disable-line eqeqeq
    );
    const quantity = config.quantity;
    const daisyFreshQuantity = config.daisyFreshQuantity || 0;
    const totalQuantity = quantity + daisyFreshQuantity;

    rows = [
      ...rows,
      ...getTableRows({
        product,
        hasConnections,
        config,
        creditUsed: config.rows[0].credit_used || "",
        quantity,
        daisyFreshQuantity,
        totalOneOffPrice: configurationTotalOneOffPrice(
          config,
          products,
          hasConnections
        ),
        totalDaisyFreshPrice: configurationTotalDaisyFreshPrice(config),
        totalQuantity,
        handleToggle: () => dispatch(toggleExpand(product.id)),
        setFields: (productId, fields, index) =>
          dispatch(setFields(productId, fields, index)),
      }),
    ];
  });

  return (
    <HardwareTable
      remainingHWCredit={remainingHWCredit}
      hardwareCount={hardwareCount}
      hasDaisyFresh={hasDaisyFresh}
      contractLengthInMonthsAllProducts={contractLengthInMonthsAllProducts}
      rows={rows}
      totalOneOffPrice={totalOneOffPrice(
        configurationsWithQuantity,
        products,
        hasConnections
      )}
      totalDaisyFreshPrice={parseFloat(
        totalDaisyFreshPrice(configurationsWithQuantity)
      )}
      totalQuantity={totalQuantity(configurationsWithQuantity)}
      totalQuantityDaisyFresh={totalQuantity(configurationsWithQuantity, true)}
      isFetchingAvailableHardwareCredit={isFetchingAvailableHardwareCredit}
      totalConfigHardwareCredit={getTotalOrderHardwareCredit(
        configurationsWithQuantity
      )}
    />
  );
};
