import { getJSON } from "../helpers";

interface ExternalUsersAutocompleteAPIResponse {
  name: string;
  id: string;
}

export function getExternalUsersAutocomplete(query: string) {
  return getJSON<ExternalUsersAutocompleteAPIResponse[]>(
    "/ExternalUsers/AutoComplete",
    {
      name: query,
    }
  );
}
