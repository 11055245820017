import React, { ReactElement, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccountAddress,
  setAddressType,
  toggleSplitDelivery,
} from "../../../../store/hardwareDelivery/actionCreators";
import { Box, Button } from "@mui/material";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import { IndexedCardInfo } from "@akj-dev/shared-components";
import { BillingAddressContainer } from "../BillingAddress";
import { SplitDeliveryGlobalList } from "./SplitDeliveryGlobalList";
import { CreateContactContainer } from "../../../../shared/containers/CreateContact";
import { AddressOptions } from "../../components/Hardware/AddressOptions";
import { ExistingAddressSelectContainer } from "./ExistingAddressSelect";
import { getAccountSettings } from "../../../../store/account/selectors";

interface HardwareDeliveryProps {
  cardIndex: string;
}

export function HardwareDelivery({ cardIndex }: HardwareDeliveryProps) {
  const dispatch = useDispatch();

  const accountSettings = useSelector(getAccountSettings);

  const addressType = useSelector(
    (state: any) => state.hardwareDelivery.addressType
  );
  const addressId = useSelector(
    (state: any) => state.hardwareDelivery.addressId
  );

  const splitDelivery: boolean = useSelector(
    (state: any) => state.hardwareDelivery.splitDelivery
  );

  const handleChangeAddressType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setAddressType(event.target.value));
  };

  const handleSelectAccountAddress = (event: any, child: ReactNode) => {
    dispatch(selectAccountAddress((child as ReactElement).props.value));
  };

  const handleSplitDelivery = () => {
    dispatch(toggleSplitDelivery());
  };

  const handleAddContactSuccess = (contactId: string) => {
    dispatch(setAddressType(addressTypes.EXISTING));
    dispatch(selectAccountAddress(contactId));
  };

  return (
    <IndexedCardInfo index={cardIndex} title="Hardware Delivery">
      {splitDelivery ? (
        <SplitDeliveryGlobalList />
      ) : (
        <>
          <AddressOptions
            addressType={addressType}
            handleChangeAddressType={handleChangeAddressType}
          />
          {addressType === addressTypes.BILLING && <BillingAddressContainer />}
          {addressType === addressTypes.EXISTING && (
            <ExistingAddressSelectContainer
              handleSelectAccountAddress={handleSelectAccountAddress}
              addressId={addressId}
            />
          )}
        </>
      )}
      <Box mt={1} display="flex">
        {accountSettings.as_multiple_delivery_addresses === "1" && (
          <Box mr={1}>
            <Button variant="outlined" onClick={handleSplitDelivery}>
              {splitDelivery ? "Un-split" : "Split"} Delivery
            </Button>
          </Box>
        )}
        <CreateContactContainer onSuccess={handleAddContactSuccess} />
      </Box>
    </IndexedCardInfo>
  );
}
