import { NewOrderForAccountOrLeadResponse } from "../../../api/v1/guidedSales";

/**
 * Convert new order params returned from DC into params for CPQ launch
 * Also strips falsey values so we don't get things like leadId: "undefined"
 */
export function orderToCpqParams(order: NewOrderForAccountOrLeadResponse) {
  const paramMap = {
    accountId: "account_id",
    orderId: "order_id",
    leadId: "lead_id",
    leadNumber: "lead_number",
    opportunityId: "opportunity_id",
    opportunityNumber: "opportunity_number",
    opportunityName: "opportunity_name",
    customerName: "customer_name",
  };

  let params = {};
  for (let key in paramMap) {
    // @ts-ignore
    const param = order[paramMap[key]];
    // @ts-ignore
    if (param) params[key] = param;
  }
  return new URLSearchParams(params).toString();
}
