import React from "react";
import { Button } from "@mui/material";

interface QuantitySyncButtonProps {
  handleSync: () => void;
}

export const QuantityFieldSyncButton = ({
  handleSync,
}: QuantitySyncButtonProps) => {
  return (
    <Button color="primary" variant="outlined" onClick={handleSync}>
      Update
    </Button>
  );
};
