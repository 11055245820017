import React from "react";
import Icon from "@mui/icons-material/Star";
import { Section } from "../../../../shared/components/Section";
import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";

export const UniqueReferenceNumberField = () => {
  return (
    <Section
      title="Unique Reference Number"
      avatar={<Icon />}
      defaultExpanded={true}
    >
      <ArrayDynamicField
        propertyName="partner_reference_number"
        floatingLabelText="Unique Reference Number"
        unique
      />
    </Section>
  );
};
