import React, { useState } from "react";

import { FormControlLabel, Switch } from "@mui/material";

type Props = {
  active: string | number;
  id: number;
  setStatus: (id: number, is_active: boolean) => void;
};

const Component: React.FunctionComponent<Props> = ({
  active,
  id,
  setStatus,
}) => {
  // eslint-disable-next-line eqeqeq
  const [isActive, setIsActive] = useState(active == 1 ? true : false);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isActive}
          onChange={(event) => {
            setIsActive(event.target.checked);
            setStatus(id, event.target.checked);
          }}
          name="is_active"
          color="primary"
        />
      }
      label={isActive ? "Active" : "Inactive"}
    />
  );
};

export default Component;
