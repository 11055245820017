import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./state";
import { search } from "./actions";
import { transformHardwareSearch } from "../transformers";

const hardwareProducts = createSlice({
  name: "hardwareProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(search.pending, (state) => {
        state.fetching = true;
      })
      .addCase(search.fulfilled, (state, action: PayloadAction<any>) => {
        const { response, contractLengthInMonthsAllProducts } = action.payload;
        state.fetching = false;
        state.response = transformHardwareSearch(
          response,
          contractLengthInMonthsAllProducts
        );
      });
  },
});

export default hardwareProducts.reducer;
