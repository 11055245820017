import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { verifySimSerials } from "../../../../../../store/mobile/actions";
import { useTargetConfigsContext } from "../../../../context/TargetConfigsContext";
import { SimSerialVerification } from "../../../../components/Mobile/SimSerialVerification";
import {
  SIM_VERIFIED,
  SIM_ERROR,
  SIM_UNVERIFIED,
} from "../../../../utils/simStatuses";

interface SimVerificationProps {
  supplier: string;
}
type SupplierCounts = Record<string, number>;

export const SimSerialVerificationContainer = ({
  supplier,
}: SimVerificationProps) => {
  const dispatch = useDispatch();
  const configs = useSelector<any, any[]>((state) => state.mobile.configs);
  const { targetConfigs } = useTargetConfigsContext();

  const isFetchingSIM = _.find(
    configs,
    (c) => _.get(c, "simValidCheck.fetching") === true
  );

  // check if multiple configs for multiple suppliers
  const isMixedMulti = () => {
    const supplierCounts: SupplierCounts = {};

    for (const id of targetConfigs) {
      const supplier = _.get(
        configs[id],
        "productData.response.mobile.product_component_data.supplier"
      );

      if (supplier !== undefined) {
        supplierCounts[supplier] = (supplierCounts[supplier] || 0) + 1;
      }
    }

    return (
      Object.keys(supplierCounts).length > 1 &&
      Object.values(supplierCounts).every((count) => count > 0)
    );
  };

  const isMulti = targetConfigs.length > 1;
  let labelSuffix = "";
  if (isMixedMulti()) {
    labelSuffix = " - e.g. 89441100304000000000, 89441000304000000000";
  } else if (supplier === "O2") {
    labelSuffix =
      " - e.g. 89441100304000000000" + (isMulti ? ",89441100304000000001" : "");
  } else if (supplier === "Vodafone" || supplier === "VF Direct") {
    labelSuffix =
      " - e.g. 89441000304000000000" + (isMulti ? ",89441000304000000001" : "");
  }

  let simStatus = SIM_UNVERIFIED;
  for (const id of targetConfigs) {
    if (_.get(configs[id], "simValidCheck.response.is_valid_sim_number"))
      simStatus = SIM_VERIFIED;

    if (_.get(configs[id], "simValidCheck.response.is_valid_sim_number") === 0)
      simStatus = SIM_ERROR;
  }

  return (
    <SimSerialVerification
      isFetchingSIM={isFetchingSIM}
      isMulti={targetConfigs.length > 1}
      labelSuffix={labelSuffix}
      simStatus={simStatus}
      onVerifyClick={() => dispatch(verifySimSerials(targetConfigs))}
      isValidationFailed={
        _.get(configs[0], "simValidCheck.response.status") === "error"
      }
      validationErrorMessage={configs[0].simValidCheck?.response?.message}
      verifyButtonDisabled={
        !configs[targetConfigs[0]].properties.sim_buffer_serial
      }
    />
  );
};
