import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stepper } from "../../components/Stepper";
import { setActiveStep } from "../../../../store/uiState/actions";
import { ProductTypesEnum, SaleStepEnum } from "../../../../shared/enums";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";
import {
  getCreditVetFetching,
  getIsVfDirectCreditVetPassed,
} from "../../../../store/account/selectors";

export interface ReduxStepperProps {
  activeStep?: SaleStepEnum;
  visited?: boolean[];
  steps?: SaleStepEnum[];
  activeSubStepChoose?: ProductTypesEnum;
}

export const StepperContainer = () => {
  const { activeStep, steps, visited, activeSubStepChoose } = useSelector<
    { uiState: ReduxStepperProps },
    ReduxStepperProps
  >((state) => ({
    activeStep: state.uiState.activeStep,
    visited: state.uiState.visited,
    steps: state.uiState.steps,
    activeSubStepChoose: state.uiState.activeSubStepChoose,
  }));
  const dispatch = useDispatch();
  const canAccessVfDirect = useCanAccessVfDirect();

  const creditVetPassed = useSelector<any, boolean>((state) =>
    getIsVfDirectCreditVetPassed(state)
  );
  const creditVetFetching = useSelector<any, boolean>((state) =>
    getCreditVetFetching(state)
  );
  const handleActiveSteps = (stepId: SaleStepEnum) => {
    dispatch(setActiveStep(stepId));
  };
  const disableStepButton =
    canAccessVfDirect && (creditVetFetching || !creditVetPassed);

  return (
    <Stepper
      activeStep={activeStep}
      steps={steps}
      visited={visited}
      activeSubStepChoose={activeSubStepChoose}
      handleActiveSteps={handleActiveSteps}
      disableStepButton={disableStepButton}
    />
  );
};
