import React from "react";
import { StatusChip } from "@akj-dev/shared-components";

interface EthernetMessageProps {
  orderId: string;
}

export const EthernetMessage = ({ orderId }: EthernetMessageProps) => (
  <StatusChip
    type="info"
    title="If your order requires a manual pricing change or it includes a replacement circuit, please do not ‘Generate Contract’"
    message={
      <span>
        Please email the order number {orderId} to{" "}
        <a href="mailto:TelcoCommercial@daisygroup.com">
          TelcoCommercial@daisygroup.com
        </a>
      </span>
    }
  />
);
