import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CreateContact } from "../components/CreateContact";
import { ContactType, DCContact } from "../../api/v1/account";
import {
  addContact,
  fetchContactTypes,
  resetAddContact,
} from "../../store/account/actions";
import { isForAnAccount } from "../../store/order/selectors";

interface CreateContactProps {
  /* Callback after contact created */
  onSuccess: (newContactId: string) => void;
}

/**
 * Creates a contact either on an account, or a lead if no account exists.
 */
export const CreateContactContainer = ({ onSuccess }: CreateContactProps) => {
  const dispatch = useDispatch();
  const response = useSelector(
    (state: any) => state.account.addContact.response
  );

  const isForAccount = useSelector(isForAnAccount);

  const contactTypes = useSelector((state: any) => state.account.contactTypes);

  const [open, setOpen] = useState(false);

  const types: ContactType[] = contactTypes.response.data?.types || [];
  useEffect(() => {
    if (response.status === "success") {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
      setTimeout(() => {
        dispatch(resetAddContact());
      }, 3000);
    }
  }, [response, dispatch]);

  useEffect(() => {
    if (types.length < 1) {
      dispatch(fetchContactTypes());
    }
  }, [dispatch, types.length, response.status]);

  const handleSubmit = async (values: Partial<DCContact>) => {
    const resp: any = await dispatch(
      addContact({ values, lead: !isForAccount })
    );
    // TODO: This is so messy... but how else? API comms might be better local to the dialog, but we still haven't agreed what we're doing about redux
    const newContactId = resp?.payload?.data?.id;
    if (newContactId) {
      onSuccess(newContactId);
    }
  };

  return (
    <CreateContact
      types={types}
      open={open}
      response={response}
      handleSubmit={handleSubmit}
      setOpen={setOpen}
    />
  );
};
