import React from "react";

import { Button, ButtonGroup } from "@mui/material";
import {
  dateStringSubMonth,
  dateStringSubQuarterYear,
  dateStringSubWeek,
  dateStringSubYear,
} from "../../../helpers/dates";
import { DateString } from "../../../types";

export const options = [
  {
    id: "one-week",
    label: "1 Week",
    getDateStr: dateStringSubWeek,
  },
  {
    id: "one-month",
    label: "1 Month",
    getDateStr: dateStringSubMonth,
  },
  {
    id: "three-month",
    label: "3 Months",
    getDateStr: dateStringSubQuarterYear,
  },
  {
    id: "one-year",
    label: "1 Year",
    getDateStr: dateStringSubYear,
  },
];

type Props = {
  selected: DateString;
  onSelect: (selectedDate: DateString) => void;
};

// @TODO - we're duplicating sales status date selector here
export function SelectDate({ selected, onSelect }: Props) {
  const styles = {
    group: {
      backgroundColor: "common.white",
    },
    button: {
      textTransform: "capitalize",
      color: "common.white",
      backgroundColor: "primary.main",
      "&:hover": {
        backgroundColor: "primary.main",
      },
      "&:active": {
        boxShadow: "none",
      },
    },
  };

  return (
    <ButtonGroup sx={styles.group} size="large">
      {options.map((option) => {
        const optionDate = option.getDateStr;
        const optionSelected = selected === optionDate;
        return (
          <Button
            sx={optionSelected ? styles.button : {}}
            key={option.id}
            onClick={() => onSelect(optionDate)}
            variant={optionSelected ? "contained" : "outlined"}
          >
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
