import React from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CachedIcon from "@mui/icons-material/Cached";
import { useTheme } from "@mui/material/styles";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES,
} from "../../../../../store/mobile/constants";
import { Chip } from "../../../../../shared/components/Chip/Chip";
import { getHostEnv } from "../../../../../store/order/selectors/getHostEnv";
import { RowActionsButton } from "../../../../../shared/components/RowActionsButton";
import { useCanAccessVfDirect } from "../../../../../shared/hooks/useCanAccessVfDirect";

interface RowActionsProps {
  config: Record<string, any>;
  disabled?: boolean;
  resetType?: () => void;
  resetProduct?: () => void;
  setNoChanges?: () => void;
  setChanges?: () => void;
}
export const RowActions = ({
  config,
  resetType,
  resetProduct,
  disabled = false,
  setNoChanges = () => {},
  setChanges = () => {},
}: RowActionsProps) => {
  const theme = useTheme();
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();

  switch (config.resignType) {
    case RESIGN_WITHOUT_CHANGES:
      return (
        <Chip
          title="Resign without changes"
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          onClick={resetType}
        />
      );
    case RESIGN_WITH_CHANGES:
      return (
        <Chip
          title={hostEnv === "platform" ? "Resign" : "Resign with changes"}
          color={
            canAccessVfDirect || hostEnv === "dc"
              ? theme.palette.success.main
              : theme.palette.primary.main
          }
          Icon={
            canAccessVfDirect || hostEnv === "dc"
              ? SettingsBackupRestoreIcon
              : CachedIcon
          }
          onClick={config.productId ? resetProduct : resetType}
          outline={!config.productId}
        />
      );
    default:
      return (
        <div data-cy="resignOptionsBtn">
          {hostEnv === "platform" ? (
            // VF Direct can only resign with changes. TP46680 // same for Daisy Comms/Platform TP79914
            <Button
              variant="outlined"
              color="primary"
              onClick={setChanges}
              endIcon={<CachedIcon />}
            >
              Resign
            </Button>
          ) : (
            <RowActionsButton
              items={[
                {
                  label: "Resign without changes",
                  dataCy: "resignWithoutChangeBtn",
                  disabled,
                  onClick: setNoChanges,
                },
                {
                  label: "Resign with changes",
                  dataCy: "resignWithChangeBtn",
                  disabled,
                  onClick: setChanges,
                },
              ]}
            />
          )}
        </div>
      );
  }
};
