import { getJSON, postJSON } from "../helpers/core";
import { DCContact } from "./account";

/**
 * Fetch all contacts on a given lead
 * @param leadId
 * @returns {Promise<*>}
 * @constructor
 */
export const AllLeadContacts = (leadId: string) =>
  getJSON(`Leads/AllLeadContacts/${leadId}`);

/**
 * Get lead contact types
 * ....do we need this?
 * @returns {Promise<*>}
 * @constructor
 */
export const ContactTypes = () => getJSON(`Leads/ContactTypes`);

/**
 * Edit a contact on a lead
 * @param leadId
 * @param contact
 * @returns {Promise<*>}
 * @constructor
 */
export const EditContact = (leadId: string, contact: DCContact) =>
  postJSON(`Leads/EditContact/${leadId}`, contact);

/**
 * Add a contact on a lead
 * @param leadId
 * @param contact
 * @returns {Promise<*>}
 * @constructor
 */
export const AddContact = (leadId: string, contact: DCContact) =>
  postJSON(`Leads/AddContact/${leadId}`, contact);
