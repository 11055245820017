import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  doAddBroadbandAppointment,
  fetchBroadbandAppointments,
  setBroadbandAppointment,
} from "../../../../../../../store/wlrBroadband/actions/configurations";

import { getIsSalesPerson } from "../../../../../../../store/account/selectors";
import { getDynamicPropertyValue } from "../../../../../../../store/wlrBroadband/selectors";
import { ChooseBroadbandAppointment } from "../../../../../components/wlrBroadband/ChooseBroadbandAppointment";

interface ChooseBroadbandAppointmentContainerProps {
  targetConfig: number;
}

export const ChooseBroadbandAppointmentContainer = ({
  targetConfig,
}: ChooseBroadbandAppointmentContainerProps) => {
  const dispatch = useDispatch();

  const appointments = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig].broadbandAppointments
  );

  const addBroadbandAppointment = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig].addBroadbandAppointment
  );

  const isSalesPerson = useSelector<any, boolean>(getIsSalesPerson);

  const siteVisitReason = useSelector((state) =>
    getDynamicPropertyValue(
      state,
      "broadband",
      targetConfig,
      "bb.site_visit_reason"
    )
  );

  const productComponentType = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig].broadbandProductData
        ?.response.broadband.product_component_data.type
  );

  useEffect(() => {
    if (
      !_.get(appointments.response, "appointments", []).length &&
      !appointments.fetching &&
      appointments.response.status !== "error"
    ) {
      dispatch(fetchBroadbandAppointments(targetConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);
  return (
    <ChooseBroadbandAppointment
      appointments={appointments}
      addBroadbandAppointment={addBroadbandAppointment}
      isSalesPerson={isSalesPerson}
      productComponentType={productComponentType}
      siteVisitReason={siteVisitReason}
      onAddAppointment={() => dispatch(doAddBroadbandAppointment(targetConfig))}
      onRetry={() => dispatch(fetchBroadbandAppointments(targetConfig))}
      onChooseBroadbandAppointment={(event) =>
        dispatch(
          setBroadbandAppointment({
            configurationIndex: targetConfig,
            selectedIndex: event.target.value,
            isSalesPerson,
          })
        )
      }
    />
  );
};
