import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box, Typography } from "@mui/material";

export const ResignBoltOnsBulkConfig = () => {
  return (
    <>
      <Typography variant="h4">Previous bolt-ons</Typography>
      <Box paddingTop={1}>
        <Alert
          type="info"
          message="To view previous bolt-ons, please configure a single connection."
        />
      </Box>
    </>
  );
};
