import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { addSpaceToPostcode } from "../../../shared/utils/addresses";
import { addWeekdays } from "../../../shared/utils/date";
import {
  NEW_LINE,
  TRANSFER,
  RESIGN,
  BROADBAND_ONLY,
  NEW_FTTP,
  NEW_SOGEA,
} from "../constants";
import { getIsWLTO, getIsTransfer, getIsMPFLine } from "../selectors";
import { getRandomString } from "../utils";

export default function newBroadbandProduct(params: any, state: any) {
  const config = state.configurations[params.configurationIndex];
  const location = state.locations[config.locationIndex];
  const productId = params.productId || params.resignProductId;
  const broadbandProductInstanceId = params.productInstanceId;
  const product =
    _.get(location, "broadbandSearch.response.products", []).find(
      (product: any) => product.id === productId
    ) ||
    _.get(state, "broadbandResignProductSearch.response.products", []).find(
      (product: any) => product.id === productId
    );
  const { ont_details } = location.lineAvailability?.response;
  const isWLTO = getIsWLTO(state, config.locationIndex);
  const isTransfer = getIsTransfer(state, config.locationIndex);
  const isMpfProduct = params.isMpfProduct;
  const simProvideRef =
    (location.type === NEW_LINE && !isMpfProduct) || isWLTO || isTransfer
      ? `SIM2${Math.floor(Math.random() * 900000 + 100000)}`
      : false;

  return {
    ...config,
    broadbandProductId: productId,
    ...(config.type !== "mpf" && { broadbandProductInstanceId }),
    broadbandProperties: productId
      ? {
          as_frontend_uuid: uuidv4(),
          contract_length_in_months: state.contractLength,
          product_id: productId,
          "bb.estimated_download_range":
            product.first_broadband_component.estimated_download_range,
          "bb.estimated_upload_range":
            product.first_broadband_component.estimated_upload_range,
          "bb.minimum_guaranteed_speed":
            product.first_broadband_component.minimum_guaranteed_speed,
          "bb.order_type": `${product.first_broadband_component.type} ${(() => {
            if (location.type === NEW_LINE) return "Provide";
            if (product.first_broadband_component.type === "SOGEA") {
              return ["J", "H"].includes(
                product.first_broadband_component.activation_code
              )
                ? "Migrate"
                : "Provide";
            }

            switch (product.first_broadband_component.activation_code) {
              case "A":
              case "L":
              case "G":
                return "Migrate";
              case "Z":
              case "E":
                return "Provide";
              default:
                return "Migrate";
            }
          })()}`,
          "bb.activation_code": `${(() => {
            if (
              product.first_broadband_component.type === "SOGEA" &&
              !["J", "H"].includes(
                product.first_broadband_component.activation_code
              )
            ) {
              return "Z";
            }

            return product.first_broadband_component.activation_code;
          })()}`,
          "bb.market_code": product.first_broadband_component.market_code,
          ...((location.type === TRANSFER ||
            location.type === RESIGN ||
            location.type === BROADBAND_ONLY) &&
            !isWLTO && {
              "bb.cli": location.cli.value,
            }),

          "bb.address.addressReference": location.address.addressReference,
          "bb.address.cssDatabaseCode": location.address.cssDatabaseCode,
          "bb.address.postcode": addSpaceToPostcode(location.address.postcode),
          "bb.address.postTown": location.address.postTown,
          "bb.address.county": location.address.county,
          "bb.address.premisesName": location.address.premisesName,
          "bb.address.thoroughfareName": location.address.thoroughfareName,
          "bb.address.thoroughfareNumber":
            location.address.thoroughfareNumber || location.address.subPremises,
          ...(getIsMPFLine(config) && {
            "bb.businessDirectory.address.addressReference":
              location.address.addressReference,
            "bb.businessDirectory.address.postcode": addSpaceToPostcode(
              location.address.postcode
            ),
            "bb.businessDirectory.address.postTown": location.address.postTown,
            "bb.businessDirectory.address.premisesName":
              location.address.premisesName,
            "bb.businessDirectory.address.thoroughfareName":
              location.address.thoroughfareName,
            "bb.businessDirectory.address.thoroughfareNumber":
              location.address.thoroughfareNumber,
            "bb.businessDirectory.type": "ORDINARY",
          }),
          "router.building_name": location.address.premisesName,
          "router.door_number":
            location.address.thoroughfareNumber || location.address.subPremises,
          "router.postcode": addSpaceToPostcode(location.address.postcode),
          "router.street": location.address.thoroughfareName,
          "router.town": location.address.postTown,
          "router.county": location.address.county,
          "bb.postcode": addSpaceToPostcode(location.address.postcode),

          ...(simProvideRef && { "bb.sim_provide_ref": simProvideRef }),
          "radius.radius_username": `${
            location.cli.value || location.address.postcode
          }${getRandomString(3)}`,
          "radius.radius_password": getRandomString(),
          "bb.required_by_date": _.get(
            product,
            "first_broadband_component.estimated_lead_time.appointment",
            addWeekdays(new Date(), 10, true)
          ),
          order_provisioning: 1,
          ...(location.type === NEW_FTTP && {
            "bb.ONTType":
              ont_details.ont_spare_data_ports > 0
                ? ont_details.ont_type
                : "NEW",
            "bb.ontReference": ont_details.ont_reference,
            "bb.mdu_build_complete": ont_details.mdu_build_complete,
            "bb.tma_marker_present": ont_details.tma_marker_present,
            "bb.tma_notice_required": ont_details.tma_notice_required,
            "bb.tma_permit_required": ont_details.tma_permit_required,
            "bb.site_visit_reason":
              ont_details.ont_spare_data_ports > 0 ||
              ont_details.mdu_build_complete === "1"
                ? "NO_SITE_VISIT"
                : "STANDARD",
          }),
          // To SOGEA
          ...(product.first_broadband_component.type === "SOGEA" &&
            config.resignProductType === "NEW_PRODUCT" && {
              "bb.site_visit_reason": "STANDARD",
            }),
          // To FTTP
          ...(product.first_broadband_component.type === "FTTP" &&
            config.resignProductType === "NEW_PRODUCT" && {
              "bb.site_visit_reason": "PREMIUM",
            }),

          ...(location.type === NEW_SOGEA && {
            "bb.site_visit_reason": "STANDARD",
          }),
          ...(location.type === RESIGN &&
            config.resignProductType !== "NEW_PRODUCT" && {
              "bb.site_visit_reason": "NO_SITE_VISIT",
            }),
        }
      : () => ({}),

    ...(simProvideRef && {
      wlrProperties: {
        ...config.wlrProperties,
        adsl_reference: simProvideRef,
        like_for_like: 0,
      },
    }),
  };
}
