import React, { ChangeEvent } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

interface SupportCaseProps {
  open: boolean;
  loading: boolean;
  actions: {
    close: () => void;
    create: () => void;
    update: (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
  };
  supportCase: Record<string, any>;
}

export const SupportCase = ({
  open,
  actions,
  loading,
  supportCase,
}: SupportCaseProps) => (
  <Dialog open={open} onClose={actions.close} fullWidth maxWidth="sm">
    <DialogTitle>Create New Support Case</DialogTitle>
    <DialogContent>
      <TextField
        variant="standard"
        name="short_description"
        autoFocus={true}
        fullWidth={true}
        onChange={(event) => actions.update(event)}
        label="Issue Title *"
        value={supportCase.short_description}
        error={supportCase.invalid && !supportCase.short_description}
        helperText={
          supportCase.invalid &&
          !supportCase.short_description &&
          "This field is required."
        }
        disabled={loading}
      />

      <TextField
        variant="standard"
        name="description"
        fullWidth={true}
        onChange={(event) => actions.update(event)}
        required={true}
        label="Issue Description *"
        value={supportCase.description}
        multiline={true}
        rows={4}
        error={supportCase.invalid && !supportCase.description}
        helperText={
          supportCase.invalid && !supportCase.description
            ? "This field is required."
            : "Full description, including steps to reproduce issue"
        }
        disabled={loading}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={actions.close} disabled={loading}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={actions.create}
        disabled={loading}
        endIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Submit Case
      </Button>
    </DialogActions>
  </Dialog>
);
