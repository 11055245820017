import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CliBoltOnsContainer } from "../../containers/Mobile/ConfigurationForm/sections/CliBoltOns";
import { AirtimeCreditContainer } from "../../containers/Mobile/ConfigurationForm/sections/AirtimeCredit";
import { SimCardContainer } from "../../containers/Mobile/ConfigurationForm/sections/SimCard";
import { Connection } from "../../containers/Mobile/ConfigurationForm/sections/Connection";
import { AdvancedConfiguration } from "../../containers/Mobile/ConfigurationForm/sections/AdvancedConfiguration";
import { BarsContainer } from "../../containers/Mobile/ConfigurationForm/sections/Bars";
import { APNsContainer } from "../../containers/Mobile/ConfigurationForm/sections/APNs";
import { CostCentres } from "./CostCentres";
import { MobilePricingWrapper } from "../../containers/Mobile/ConfigurationForm/sections/MobilePricingWrapper";
import { Discounts } from "./Discounts";
import Paper from "@mui/material/Paper";
import { BillLimitsContainer } from "../../containers/Mobile/ConfigurationForm/sections/BillLimits";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Services } from "./Services";
import { UserDefinedFields } from "./UserDefinedFields";
import { StatusChip } from "@akj-dev/shared-components";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";
import { MobileConfigTableOuter } from "./MobileConfigTable/MobileConfigTableOuter";
import { MobileConfigTableRow } from "./MobileConfigTable/MobileConfigTableRow";
import {
  MobileConfigRowDisplayData,
  Network,
} from "../../../../shared/types/MobileProductConfig";
import { useMobileConfigTableDisplayPrefs } from "../../../../shared/hooks/useMobileConfigTableDisplayPrefs";
import { UniqueReferenceNumberField } from "./UniqueReferenceNumberField";
import { Finance } from "../../containers/Mobile/ConfigurationForm/sections/Finance";
import { AdditionalBundle } from "../../containers/Mobile/ConfigurationForm/sections/AdditionalBundle";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";

interface ConfigurationFormProps {
  settings: AccountSettings;
  targetConfigs: number[];
  targetConfigsData: {
    name: string;
    network: Network;
    config: MobileConfigRowDisplayData;
  }[];
  validationErrors: any[];
  closeEditConfig: () => void;
  isDynamicProduct: boolean;
  hasCliProducts: boolean;
  dynamicProperties: any;
  mobileDynamicProperties: any;
  showSharedDiscount: boolean;
  isOneMonthContract: boolean;
}

const DISPLAY_LENGTH = 5;
export const ConfigurationForm = ({
  settings,
  targetConfigs,
  targetConfigsData,
  validationErrors,
  isDynamicProduct,
  hasCliProducts,
  dynamicProperties,
  mobileDynamicProperties,
  closeEditConfig,
  showSharedDiscount,
  isOneMonthContract,
}: ConfigurationFormProps) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const canAccessVFDirect = useCanAccessVfDirect();
  const hostEnv = useSelector(getHostEnv);

  const tableDisplayPrefs = useMobileConfigTableDisplayPrefs(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <div className="ConfigurationForm">
      <Paper className={classes.paper}>
        <h3>
          <IconButton
            data-cy="configBackButton"
            onClick={closeEditConfig}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <span>
            {`Editing ${targetConfigs.length} Product Configuration${
              targetConfigs.length > 1 ? "s" : ""
            }`}
          </span>
        </h3>
        <div className={classes.configs}>
          <MobileConfigTableOuter displayPrefs={tableDisplayPrefs}>
            {targetConfigsData
              .slice(0, showAll ? targetConfigsData.length : DISPLAY_LENGTH)
              .map((config) => (
                <MobileConfigTableRow
                  displayPrefs={tableDisplayPrefs}
                  root={true}
                  data={config.config}
                  productName={config.name}
                  network={config.network}
                  isCheckBoxHidden={true}
                  key={config.name}
                  isOneMonthContract={isOneMonthContract}
                />
              ))}
          </MobileConfigTableOuter>
        </div>
        {targetConfigsData.length > DISPLAY_LENGTH && (
          <Box p={1} display="flex" justifyContent="flex-end">
            <Button variant="outlined" onClick={handleShowAll}>
              {showAll ? "Show less" : "Show all"}
            </Button>
          </Box>
        )}
      </Paper>
      {/*<ProductNames configs={configs} targetConfigs={targetConfigs} />*/}
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Connection targetConfigs={targetConfigs} />
          <SimCardContainer targetConfigs={targetConfigs} />
          {
            // Show CLI bolt-ons and were not bulk editing.
            hasCliProducts && (
              <CliBoltOnsContainer mode={canAccessVFDirect ? "slot" : "type"} />
            )
          }
          {settings.as_can_add_bill_options === "1" && <BillLimitsContainer />}
        </Grid>

        <Grid item md={6}>
          {settings.as_airtime_credits_enabled === "1" && (
            <AirtimeCreditContainer />
          )}
          {settings.can_use_vfdirect_discount === "1" ? (
            <Discounts showSharedDiscount={showSharedDiscount} />
          ) : (
            isDynamicProduct && <MobilePricingWrapper />
          )}
          {canAccessVFDirect && <Finance />}
          {mobileDynamicProperties &&
            mobileDynamicProperties["partner_reference_number"] !==
              undefined && <UniqueReferenceNumberField />}
          <BarsContainer targetConfigs={targetConfigs} />
          {settings.as_services === "1" && (
            <Services configsData={targetConfigsData} />
          )}
          {settings.as_APN === "1" && (
            <APNsContainer targetConfigs={targetConfigs} />
          )}
          {
            settings.as_cost_centre_enabled === "1" && <CostCentres /> // TP22408
          }
          {settings.as_user_definable_fields_enabled === "1" && (
            <UserDefinedFields dynamicProperties={dynamicProperties} />
          )}
          <AdvancedConfiguration />
          {hostEnv === "dc" && (
            <AdditionalBundle targetConfigs={targetConfigs} />
          )}
        </Grid>
      </Grid>
      {validationErrors.length > 0 && (
        <StatusChip
          type="error"
          title="This configuration has errors."
          message={
            <div>
              <ul>
                {validationErrors.map((error) => {
                  const [name, message] = [error[0], error[1]];
                  if (message)
                    return <li key={name}>{`${name}: ${message}`}</li>;
                  return false;
                })}
              </ul>
            </div>
          }
        />
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={closeEditConfig}
      >
        Return to Customise
      </Button>
    </div>
  );
};

const useStyles = makeStyles({
  paper: {
    marginBottom: 20,
  },
  button: {
    marginTop: "16px",
  },
  configs: {
    overflowX: "auto",
  },
});
