import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";
import { FileDrop } from "../../../../shared/components/File/Drop";
import { StatusLarge } from "../../../../shared/components/StatusLarge";
import { listDocuments } from "../../../../store/order/actions";
import { useDispatch, useSelector } from "react-redux";
import { ParsableDate } from "typings";
import DocumentsList from "./DocumentsList";

interface ContentProps {
  files: any[];
  uploadSending: boolean;
  uploadResponse: Record<string, any>;
  onChangeMeta: (
    propertyName: string,
    value: ParsableDate[] | File[] | string
  ) => void;
  onUploadDocuments: (send_with_contract: string) => void;
  onResetUploadStatus: () => void;
  closeDialog: () => void;
}

export const UploadUniversalContent = ({
  closeDialog,
  onChangeMeta,
  onUploadDocuments,
  onResetUploadStatus,
  files,
  uploadResponse,
  uploadSending,
}: ContentProps) => {
  const dispatch = useDispatch();
  const getListDocuments = useSelector(
    (state: any) => state.order.listDocuments.response
  );
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);

  useEffect(() => {
    dispatch(listDocuments());
  }, [dispatch, uploadResponse]);

  const handleToggleDocument = (name: string) => {
    if (selectedDocuments.includes(name)) {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== name));
    } else {
      setSelectedDocuments([...selectedDocuments, name]);
    }
  };

  const handleUpload = () => {
    const selectedIndexes = files.map((file) =>
      selectedDocuments.includes(file.name) ? 1 : 0
    );

    onUploadDocuments(selectedIndexes.join(","));
  };

  return (
    <>
      <DialogContent>
        <Box>
          {getListDocuments.data &&
            getListDocuments.data.documents.map((document: any) => (
              <Box mb={1} key={document.id}>
                <StatusChip
                  type="success"
                  title="File uploaded"
                  message={document.filename}
                />
              </Box>
            ))}
        </Box>
        {uploadSending || uploadResponse ? (
          <div className="u-text-center">
            {uploadSending && (
              <Box
                m={4}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box mb={4}>
                  <CircularProgress size={80} />
                </Box>
                <Typography variant="h5">Uploading Documents</Typography>
              </Box>
            )}
            {uploadResponse && (
              <div>
                {uploadResponse.status === "success" ? (
                  <StatusLarge type="success" title="Success" />
                ) : (
                  <StatusLarge
                    type="error"
                    title="Sorry, something went wrong."
                    message={uploadResponse.message}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                {files ? (
                  <DocumentsList
                    selectedDocuments={selectedDocuments}
                    documents={files}
                    handleSelect={handleToggleDocument}
                  />
                ) : (
                  <FileDrop
                    onDrop={(files) => onChangeMeta("files", files)}
                    message="Drag supporting documents here"
                    maxSize={3e7}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        {!uploadResponse || uploadResponse.status === "success" ? (
          <Button variant="contained" onClick={closeDialog}>
            Close
          </Button>
        ) : (
          <Button
            variant="contained"
            key="cancel"
            onClick={closeDialog}
            disabled={uploadSending}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
        )}

        {uploadResponse ? (
          <Button
            variant="contained"
            color="primary"
            key="retry"
            onClick={onResetUploadStatus}
            disabled={uploadSending}
          >
            Retry
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            key="upload"
            onClick={handleUpload}
            disabled={uploadSending || !files}
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </>
  );
};
