import React, { memo } from "react";

import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WlrLocationTypeEnum } from "../../../../../../enums";
import { BroadbandProductSelectContainer } from "../../../../../../containers/WlrLocation/BroadbandProductSelect";

interface BroadbandModalTableRowProps {
  contractLength: number;
  locationType: WlrLocationTypeEnum;
  productName: string;
  isMpfProduct: boolean;
  locationIndex: number;
  price?: string;
  broadbandProductId: string;
  broadbandDetails: {
    estimatedDownloadRange: string | undefined;
    estimatedUploadRange: string | undefined;
    minimumGuaranteedSpeed: string | undefined;
    leadTime: string | undefined;
  };
  configId: number;
}

export const BroadbandModalTableRow = memo(
  ({
    contractLength,
    locationType,
    productName,
    isMpfProduct,
    price,
    locationIndex,
    broadbandProductId,
    broadbandDetails,
    configId,
  }: BroadbandModalTableRowProps) => {
    const classes = useStyles();

    return (
      <>
        <Box className={classes.name}>{productName || "N/A"}</Box>
        <Box className={classes.name}>
          {broadbandDetails.estimatedDownloadRange || "N/A"}
        </Box>
        <Box className={classes.name}>
          {broadbandDetails.estimatedUploadRange || "N/A"}
        </Box>
        <Box className={classes.name}>
          {broadbandDetails.minimumGuaranteedSpeed || "N/A"}
        </Box>
        <Box className={classes.name}>{broadbandDetails.leadTime || "N/A"}</Box>
        {!isMpfProduct && (
          <Box className={classes.broadband}>
            <BroadbandProductSelectContainer
              dense={true}
              broadbandProductId={broadbandProductId}
              locationIndex={locationIndex}
              wlrProductId={locationType}
              configId={configId}
              contractLength={contractLength}
            />
          </Box>
        )}
        <Box className={classes.price}>{price ? `£${price}` : "£0.00"}</Box>
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    width: 250,
  },
  broadband: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
  },
  price: {
    width: 150,
  },
}));
