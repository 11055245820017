import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  arrayUpdateConfigProperty,
  validateConfigProperty,
} from "../../../../../store/mobile/actions";
import TextField from "@mui/material/TextField";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";

interface ArrayDynamicFieldProps {
  floatingLabelText: string;
  suffixLabelText?: string;
  propertyName: string;
  isRootProperty?: boolean;
  helperText?: string;
  disabled?: boolean;
  unique?: boolean;
}

// TODO: This currently doesn't show non-uniform value warnings like the standard bulk dynamic field.
// Either make it do so or conditionally switch between the two... or something.
// Make this a controlled input somehow. Just array split / join to get data in and out makes new line unworkable.
// Perhaps the whole UI for this needs rethinking.
export const ArrayDynamicField = ({
  floatingLabelText,
  suffixLabelText,
  propertyName,
  isRootProperty,
  helperText,
  disabled,
  unique,
}: ArrayDynamicFieldProps) => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();
  const configs = useSelector((state: any) => state.mobile.configs);
  const productData = useSelector((state: any) => state.mobile.productData);

  const allValues = targetConfigs.map(
    (c) => configs[c]?.properties[propertyName]
  );

  const [values, setValues] = useState<any[]>(
    allValues.every((item) => !item) ? [] : allValues
  );
  const numberOfValues = targetConfigs.length;

  useEffect(() => {
    if (numberOfValues > 0) {
      const newValues = new Array(numberOfValues).fill(null);
      newValues.splice(0, values.length, ...values);
      dispatch(
        arrayUpdateConfigProperty({
          propertyName,
          values: newValues,
          configIds: targetConfigs,
        })
      );
    }
  }, [values, dispatch, numberOfValues, propertyName, targetConfigs]);

  const dynamicProperty = _.get(
    productData[configs[targetConfigs[0]].productId].response,
    [...(!isRootProperty ? ["mobile"] : []), "dynamic_properties", propertyName]
  );
  if (!dynamicProperty) return null;

  const isMultiple = targetConfigs.length > 1;
  const isRequired = dynamicProperty.is_required === 1;
  const currentValue = configs[targetConfigs[0]].properties[propertyName];
  const value = !isMultiple && currentValue ? currentValue : values;

  let validation = "";
  targetConfigs.forEach((id) => {
    if (!validation) validation = _.get(configs[id].validation, propertyName);
  });

  let hasDuplicates = false;
  if (unique && isMultiple) {
    hasDuplicates = !!values.filter(
      (item, index) => values.indexOf(item) !== index
    ).length;
  }
  if (unique && hasDuplicates) validation = "Values must be unique.";
  if (values.length > 1 && values.filter((i) => i).length < numberOfValues)
    validation = `Fill in ${numberOfValues} ${unique ? "unique" : ""} values.`;
  if (values.length > numberOfValues) validation = "Too many values filled in.";
  if (
    !values.length &&
    validation &&
    propertyName !== "partner_reference_number"
  )
    validation = "";

  return (
    <TextField
      variant="standard"
      data-cy="arrayDynamicTextField"
      label={
        floatingLabelText +
        (isMultiple ? "(s)" : "") +
        (suffixLabelText || "") +
        (isRequired ? "*" : "")
      }
      multiline={isMultiple}
      fullWidth
      onChange={(e) => {
        const splitValues = e.target.value
          .split(/,|\n/)
          .map((item) => item.trim());
        setValues(splitValues.slice(0, numberOfValues));

        window.setTimeout(
          () =>
            dispatch(
              validateConfigProperty({ propertyName, configIds: targetConfigs })
            ),
          1000
        );
      }}
      onBlur={() => {
        dispatch(
          validateConfigProperty({ propertyName, configIds: targetConfigs })
        );
      }}
      value={value}
      helperText={
        validation ||
        helperText ||
        (isMultiple
          ? "Enter multiple items separated by commas or on new lines."
          : "")
      }
      error={!!validation}
      margin="normal"
      name={dynamicProperty.name}
      disabled={disabled}
      maxRows={numberOfValues}
    />
  );
};
