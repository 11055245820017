import qs from "query-string";
import { platformApiFetch } from "@akj-dev/affinity-platform";

type APIErrorTypes = "network" | "DC";
export interface ErrorAPI {
  type: APIErrorTypes;
  message: string;
  url: string;
}

export interface DCOrdersError {
  status: "error";
  code: string;
  message: string;
}

interface DCSuccessBase<T> {
  status: "success" | "warning";
  result: T;
  data: { result: T } | { results: T };
}

interface DCOrdersSuccess<T> extends DCSuccessBase<T> {
  data: never;
}

interface DCAutocomplete<T> extends DCSuccessBase<T> {
  result: never;
  data: { results: T };
}

/**
 * Other DC API response
 * TODO: Is it really practical to type things here? The DC API doesn't follow a consistent pattern.
 * We could say
 * interface DCAddressSearchSuccess<T> extends DCSuccessBase<T> {
 *   data: never;
 *   result: never;
 *   addressMatches: T;
 * }
 * ...but then what about BroadbandSearch that has like 9 keys on the root of the response?
 */

type DCOrdersResponse<T> =
  | DCOrdersError
  | DCOrdersSuccess<T>
  | DCAutocomplete<T>
  | any; // TODO: How do I type this now?

//@TODO - params type needs narrowing
export function getJSON<T>(resource: string, params?: {}): Promise<T> {
  const options = {
    headers: {
      Accept: "application/json",
      pragma: "no-cache",
      "cache-control": "no-cache",
    },
  };

  let url = `ExternalServices/v1${resource}`;

  if (params) {
    const query = qs.stringify(params, { arrayFormat: "bracket" });
    url = url + "?" + query;
  }

  return platformApiFetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw createAPIError("network", url, response.statusText);
      }
      return response.json() as Promise<DCOrdersResponse<T>>;
    })
    .then((response) => {
      if (response.status === "error") {
        const errorResponse = response as DCOrdersError;
        throw createAPIError("DC", url, errorResponse.message);
      }

      if (response.data) {
        const successResponse = response as DCAutocomplete<T>;
        return successResponse.data.results;
      } else if (response.result) {
        const successResponse = response as DCOrdersSuccess<T>;
        return successResponse.result;
      } else {
        return response;
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

//@TODO - params type needs narrowing
export function postJSON<T>(resource: string, bodyParams: {}): Promise<T> {
  let url = `ExternalServices/v1${resource}`;

  const options = {
    method: "POST",
    headers: {
      "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: qs.stringify(bodyParams),
  };

  return platformApiFetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw createAPIError("network", url, response.statusText);
      }
      return response.json() as Promise<DCOrdersResponse<T>>;
    })
    .then((response) => {
      if (response.status === "error") {
        const errorResponse = response as DCOrdersError;
        throw createAPIError("DC", url, errorResponse.message);
      }
      const successResponse = response as DCOrdersSuccess<T>;
      return successResponse.result;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

function createAPIError(
  type: APIErrorTypes,
  requestedUrl: string,
  message: string
): ErrorAPI {
  let url = requestedUrl.split("?")[0];
  return {
    type,
    url,
    message,
  };
}
