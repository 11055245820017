import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { StatusChip } from "@akj-dev/shared-components";
import {
  doALBRemovals,
  getOrderStatus,
  recalculatePrices,
  updateOrderTracking,
} from "../../../store/order/actions";
import { orderHardwareCredits } from "../../../store/mobile/selectors/order";
import { SaleProductStatusChip } from "../components/SaleProductStatusChip";
import {
  orderMobileTariffs,
  orderBoltOns,
} from "../../../store/mobile/selectors/order";
import { orderWlrBroadbandProducts } from "../../../store/wlrBroadband/actions/configurations";
import { orderHardware } from "../../../store/hardware/hardwareOrders/actions";
import { orderUniversalProducts } from "../../../store/universalProducts/actions";
import { orderLogicMonitorProducts } from "../../../store/monitoringService/actions";
import { requestAllEthernetPurchases } from "../../../store/ethernetProducts/actions";

interface OrderProductStatusProps {
  hideSuccess?: boolean;
}
/**
 * Show status of order product calls
 *
 * Optionally just the errors & fetching states, which we'd show for retry.
 * @param hideSuccess
 * @returns {*}
 * @constructor
 */
export const OrderProductStatus = ({
  hideSuccess = false,
}: OrderProductStatusProps) => {
  const dispatch = useDispatch();

  const mobileConfigs = useSelector((state: any) => state.mobile.configs);
  const orderBoltOn = useSelector((state: any) => state.mobile.orderBoltOn);
  const hardwareCredit = useSelector(
    (state: any) => state.mobile.hardwareCredit
  );
  const wlrBBConfigs = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const ethernetConfigs = useSelector(
    (state: any) => state.ethernetProducts.configurations
  );
  const hardwareOrders = useSelector((state: any) => state.hardwareOrders);
  const universalProductConfigs = useSelector(
    (state: any) => state.universalProducts.configs
  );
  const logicMonitorProductConfigs = useSelector(
    (state: any) => state.monitoringService.configs
  );
  const order = useSelector((state: any) => state.order);
  const specialRatesToRemove = useSelector(
    (state: any) => state.wlrBroadband.specialRatesToRemove
  );

  /**
   * When a user retries, order status needs to be updated so product lines and totals are visible
   */
  const retry = (action: Function) => {
    dispatch(action);
    dispatch(recalculatePrices());
    dispatch(getOrderStatus());
  };

  return (
    <div>
      {order.orderStatus.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error retrieving order status"
          message={order.orderStatus.response.message}
          retry={() => retry(updateOrderTracking())}
          hasMarginBottom
        />
      )}
      {
        // Mobile Status
        mobileConfigs.map((c: any, i: number) => (
          <SaleProductStatusChip
            request={c.orderProduct}
            title="mobile product"
            hideSuccess={hideSuccess}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            retry={() => retry(orderMobileTariffs(true))}
          />
        ))
      }
      {
        // Mobile Bolt-on Status
        Object.keys(orderBoltOn).map((id, i) => (
          <SaleProductStatusChip
            request={orderBoltOn[id]}
            title="mobile bolt-on"
            hideSuccess={hideSuccess}
            retry={() => retry(orderBoltOns())}
            key={id}
          />
        ))
      }
      {
        // Mobile bolt-on removal status
        Object.keys(order.boltOnRemovals).map((id) => (
          <SaleProductStatusChip
            request={order.boltOnRemovals[id]}
            title="account bolt-on removal request"
            retry={() => retry(doALBRemovals())}
            hideSuccess={hideSuccess}
            key={id}
          />
        ))
      }
      {/* Hardware credit (from Daisy Fresh) */}
      <SaleProductStatusChip
        request={hardwareCredit}
        title="hardware credit"
        retry={() => retry(orderHardwareCredits())}
        hideSuccess={hideSuccess}
      />
      {
        // WLR+Broadband Status
        wlrBBConfigs.map(
          (config: any) =>
            config.orderProduct &&
            Object.keys(config.orderProduct).map((productType) => {
              // TODO: Retry
              return (
                <SaleProductStatusChip
                  request={config.orderProduct[productType]}
                  title="lines, Calls and Broadband product"
                  hideSuccess={hideSuccess}
                  retry={() => retry(orderWlrBroadbandProducts())}
                  key={productType}
                />
              );
            })
        )
      }
      {
        // Hardware ordering status
        Object.keys(hardwareOrders).map((identifier) => (
          <SaleProductStatusChip
            request={hardwareOrders[identifier]}
            title="hardware product"
            hideSuccess={hideSuccess}
            retry={() => retry(orderHardware(true))}
            key={identifier}
          />
        ))
      }
      {
        // Ethernet ordering status
        // Note the API response is a very different shape so SaleProductStatusChip can't be used.
        // It doesn't even have "status" in teh response
        ethernetConfigs.map((c: any, i: number) => {
          if (c.purchaseIsRequesting)
            return (
              <StatusChip
                type="loading"
                title="Adding ethernet product to order."
                message="Please wait..."
                // eslint-disable-next-line react/no-array-index-key
                key={"eth" + i}
                hasMarginBottom
              />
            );

          if (c.purchaseResponse.error || c.purchaseResponse.status === "error")
            return (
              <StatusChip
                type="error"
                title="Error adding ethernet product to order."
                message={c.purchaseResponse.error}
                // eslint-disable-next-line react/no-array-index-key
                key={"eth" + i}
                retry={() => retry(requestAllEthernetPurchases())}
                hasMarginBottom
              />
            );

          return null;
        })
      }
      {
        // Universal Product ordering status
        universalProductConfigs.map((c: any, i: number) => (
          <SaleProductStatusChip
            request={c.orderProduct}
            title="universal product"
            retry={() => retry(orderUniversalProducts())}
            hideSuccess={hideSuccess}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))
      }
      {
        // Logic Monitor Products ordering status.
        logicMonitorProductConfigs.map((item: any, i: number) => (
          <SaleProductStatusChip
            request={item.orderProduct}
            title="logic monitor product"
            retry={() => retry(orderLogicMonitorProducts())}
            hideSuccess={hideSuccess}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))
      }
      {universalProductConfigs.map((config: any) =>
        config.userDocumentsUpload.map((doc: any, i: number) => (
          <SaleProductStatusChip
            title="product document"
            request={doc}
            hideSuccess={hideSuccess}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))
      )}
      {order.recalculatePrices.fetching && (
        <StatusChip
          type="loading"
          title="Calculating Prices"
          message="Please wait..."
          hasMarginBottom
        />
      )}
      {order.recalculatePrices.response.status === "error" && (
        <StatusChip
          type="error"
          title="Error Calculating Prices"
          message={order.recalculatePrices.response.message}
          retry={() => retry(recalculatePrices())}
          hasMarginBottom
        />
      )}
      {order.orderStatus.fetching && (
        <StatusChip
          type="loading"
          title="Updating order attributes."
          message="Please wait..."
          hasMarginBottom
        />
      )}
      {order.sendForApproval.fetching && (
        <StatusChip
          type="loading"
          title="Sending for Approval."
          message="Please wait..."
          hasMarginBottom
        />
      )}
      {
        // Special rates removal status
        specialRatesToRemove.map((specialRate: any) => {
          if (specialRate.removeSpecialRate.fetching)
            return (
              <StatusChip
                type="loading"
                title="Removing special rate"
                message="Please wait..."
                key={`sr-${specialRate.id}`}
                hasMarginBottom
              />
            );

          if (
            specialRate.removeSpecialRate.response.status === "error" ||
            specialRate.removeSpecialRate.response.status === "warning"
          )
            return (
              <StatusChip
                type="error"
                title="Error removing special rate."
                message={specialRate.removeSpecialRate.response.message}
                key={`sr-${specialRate.id}`}
                hasMarginBottom
              />
            );

          if (specialRate.removeSpecialRate.response.status === "success")
            return (
              <StatusChip
                type="success"
                title="Special rate successfully ended."
                message={specialRate.removeSpecialRate.response.msg}
                key={`sr-${specialRate.id}`}
                hasMarginBottom
              />
            );

          return null;
        })
      }
    </div>
  );
};
