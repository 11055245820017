import _ from "lodash";
import { getCliBoltOnByType, getProductDataForConfig } from "./productConfig";
import { getResignProductInstanceByConfig } from "./resigns";
import {
  ALB_NOT_REQUIRED,
  KEEP_CURRENT_ALB,
  REMOVE_EXISTING_ALB,
  RESIGN_WITHOUT_CHANGES,
} from "../constants";
import { createSelector } from "reselect";

/**
 * Is Daisy Fresh available?
 *
 * Note all users can setContractLengthAllMobileProducts except VF Direct, who
 * don't use Daisy Fresh anyway
 *
 * TP56988
 */
export const getDaisyFreshAvailableForContractLength = (state) => {
  const contractLength = state.mobile.contractLengthInMonthsAllProducts;
  return contractLength >= 24 && contractLength <= 36;
};

/**
 * Compute the amount a customer should be charged per line for Daisy Fresh
 * @type {Reselect.Selector<any, any>}
 */

export const getDaisyFreshPerLineAmount = createSelector(
  [
    (state) => state.mobile.daisyFreshAmounts,
    (state) => state.mobile.contractLengthInMonthsAllProducts,
    (state) => getStandardConfigCount(state),
  ],
  (daisyFreshAmounts, contractLengthInMonthsAllProducts, configCount) => {
    const { hardwareCredits, terminationFees, etf, hardwareTotal } =
      daisyFreshAmounts;
    return (
      Math.round(
        ((hardwareCredits + terminationFees + etf + hardwareTotal) /
          contractLengthInMonthsAllProducts /
          configCount) *
          100
      ) / 100
    );
  }
);

export const getDaisyFreshTotal = createSelector(
  [
    (state) => state.mobile.daisyFreshAmounts,
    (state) => state.mobile.contractLengthInMonthsAllProducts,
    (state) => getStandardConfigCount(state),
  ],
  (daisyFreshAmounts, contractLengthInMonthsAllProducts, configCount) => {
    // Get the total amount without the hardware total, as this is added in a separate selector when calculating
    // the basket recurring total.
    const { hardwareCredits, terminationFees, etf } = daisyFreshAmounts;
    const perLineAmount =
      Math.round(
        ((hardwareCredits + terminationFees + etf) /
          contractLengthInMonthsAllProducts /
          configCount) *
          100
      ) / 100;
    return perLineAmount * configCount || 0;
  }
);

/**
 * Get how many configs there are that are not "resign without change"
 * These will be the ones applicable for Daisy Fresh and further config.
 * @param configs
 */
export const getStandardConfigCount = createSelector(
  [(state) => state.mobile.configs],
  (configs) => {
    let count = 0;
    configs.forEach((c) => {
      if (c.resignType !== RESIGN_WITHOUT_CHANGES) count++;
    });
    return count;
  }
);

/**
 * Get maximum allowed Daisy Fresh amount.
 * Total product charges + bolt-ons * contract length * 1.8
 *
 * Should this be memoized with Reselect? No!
 * Timed and for 20+ products, it takes 0.2ms to execute on my Mac.
 *
 * @param state - the mobile part of redux state
 * @returns {number}
 */
export const getDaisyFreshAllowedAmount = (state) => {
  // const t0 = performance.now();

  let total = 0;

  // Compute total CLI charges:
  state.configs.forEach((config, i) => {
    if (config.productId) {
      const product = state.mobileSearch.response.products.find(
        (p) => p.id === config.productId
      );
      const productData = getProductDataForConfig({ mobile: state }, i);
      // Standard or "resign with change" config
      if (!_.isEmpty(productData)) {
        total += parseFloat(
          // Product data might not have loaded yet, qwhen this is first called going into step 2, so _.get with fallback.
          _.get(
            productData,
            "overall_price.first_bill_recurring_price_with_promotions",
            0
          )
        );
      } else {
        total += parseFloat(
          _.get(product, "price.first_bill_recurring_price_with_promotions", 0)
        );
      }
      // Any CLI bolt-ons the config may have
      for (let type in config.selectedCliBoltOns) {
        total += parseFloat(
          _.get(
            getCliBoltOnByType(i, type, state),
            "price.first_bill_recurring_price_with_promotions",
            0
          )
        );
      }
    } else {
      // Resign without change, with price from the product instance's evo services
      total += getResignProductInstanceByConfig(
        { mobile: state },
        i
      ).computedPrice;
    }
  });

  // Compute bolt on charges.
  // Iterate data structure, look up bolt-on price + add it to the total
  // TODO: Use `flat` npm package?
  for (let provider in state.selectedBoltOns) {
    for (let type in state.selectedBoltOns[provider]) {
      const p = state.selectedBoltOns[provider][type];
      if (
        p !== ALB_NOT_REQUIRED &&
        p !== KEEP_CURRENT_ALB &&
        p !== REMOVE_EXISTING_ALB
      ) {
        total += _.get(
          _.get(state.boltOnSearch.response, "products", []).find(
            (p) => p.id === p
          ),
          "price.first_bill_recurring_price_with_promotions",
          0
        );
      }
    }
  }

  total = total * state.contractLengthInMonthsAllProducts * 1.8;

  // const t1 = performance.now();
  // console.log("Call to getDaisyFreshAllowedAmount took " + (t1 - t0) + " milliseconds.");

  return total;
};

export const productDataIsFetching = (state) => {
  const { productData } = state.mobile;
  let fetching = false;

  Object.keys(productData).forEach((k) => {
    if (productData[k].fetching) fetching = true;
  });

  return fetching;
};

export const getDaisyFreshHardwareTotal = createSelector(
  [(state) => state.hardwareConfigurations],
  (hardwareConfigurations) => {
    let total = 0;
    for (const id in hardwareConfigurations) {
      const configuration = hardwareConfigurations[id];
      const product = configuration.product;
      const daisyFreshQuantity = configuration.daisyFreshQuantity || 0;
      if (product)
        total +=
          daisyFreshQuantity * parseFloat(product.price.oneOffWithConnection);
    }
    return total;
  }
);
