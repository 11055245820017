import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContacts,
  getHasContacts,
} from "../../../../../../../store/account/selectors";
import {
  updateConfigProperty,
  validateConfigProperty,
} from "../../../../../../../store/mobile/actions";
import { BulkDynamicField } from "../../BulkDynamicField";
import { EmailNotification } from "../../../../../components/Mobile/EmailNotification";
import { SmsNotification } from "../../../../../components/Mobile/SmsNotification";
import { LetterNotification } from "../../../../../components/Mobile/LetterNotification";

type Props = {
  targetConfigs: number[];
};

export const StandardBillingNotification = ({ targetConfigs }: Props) => {
  const dispatch = useDispatch();

  // store values
  const {
    additional_notification,
    notification_preference,
    notification_contact,
  } = useSelector((state: any) => state.mobile.configs)[targetConfigs[0]]
    .properties;

  const validationError = useSelector((state: any) => state.mobile.configs)[
    targetConfigs[0]
  ].validation?.notification_contact;

  const hasContacts = useSelector(getHasContacts);
  const contacts = useSelector(getContacts);

  // actions
  const resetContact = () => {
    dispatch(
      updateConfigProperty({
        propertyName: "notification_contact",
        value: "",
        configIds: targetConfigs,
      })
    );
  };

  const validateContact = () => {
    dispatch(
      validateConfigProperty({
        propertyName: "notification_contact",
        configIds: targetConfigs,
      })
    );
  };

  const updateContact = (value: string) => {
    dispatch(
      updateConfigProperty({
        propertyName: "notification_contact",
        value,
        configIds: targetConfigs,
      })
    );
  };

  const useEBillingAddress = () => {
    const contact = contacts.response?.data?.contacts?.find(
      (c: any) => c.cyclone_contact == 1 // eslint-disable-line eqeqeq
    )?.email1;
    updateContact(contact);
    validateContact();
  };

  const setAccountContact = (id: string) => {
    updateContact(id);
    validateContact();
  };

  return (
    <>
      <BulkDynamicField propertyName="additional_notification" />
      {additional_notification == 1 && ( // eslint-disable-line eqeqeq
        <div>
          <BulkDynamicField
            propertyName="notification_preference"
            afterUpdate={resetContact}
          />
          {(() => {
            switch (notification_preference) {
              case "email":
                return (
                  <EmailNotification
                    hasContacts={hasContacts}
                    useEBillingAddress={useEBillingAddress}
                  />
                );
              case "sms":
                return <SmsNotification />;
              case "letter":
                return (
                  <LetterNotification
                    notificationContact={notification_contact}
                    error={validationError}
                    setAccountContact={setAccountContact}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      )}
    </>
  );
};
