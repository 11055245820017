import { WLR3Address } from "../../../api/v1/addressSearch";
import { DCContactAddress } from "../../../api/v1/account";

/**
 * Convert address format from PAF / WLR3 Lookup response to DC Contact
 * @param wlr3
 */

export function convertWLR3toDCContactAddress(
  wlr3: WLR3Address
): DCContactAddress {
  return {
    primary_address_building_number: [
      wlr3.poBox && `PO Box ${wlr3.poBox}`,
      wlr3.thoroughfareNumber,
    ]
      .filter(Boolean)
      .join(" "),
    primary_address_building: [
      wlr3.organisationName,
      wlr3.premisesName,
      wlr3.subPremises,
    ]
      .filter(Boolean)
      .join(" "),
    primary_address_street: wlr3.thoroughfareName || "",
    primary_address_city: [
      wlr3.doubleDependentLocality,
      wlr3.locality,
      wlr3.postTown,
    ]
      .filter(Boolean)
      .join(", "),
    primary_address_state: wlr3.county || "",
    primary_address_postalcode: wlr3.postcode,
    primary_address_country: wlr3.country,
  };
}
