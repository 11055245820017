import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import {
  getAllAvailableCliBoltOnsBySlot,
  getAllSelectedCliBoltOnIds,
} from "../../../../../../../store/mobile/selectors/productConfig";
import { chooseCliBoltOn } from "../../../../../../../store/mobile/actions";
import { VFDirectBoltOn } from "api/v1/product";
import { CliBoltOnSlotSelectField } from "../../../../../components/Mobile/CliBoltOnSlotSelectField";

type Props = {
  slotId: string;
};

export const CliBoltOnSlotSelectFieldContainer = ({ slotId }: Props) => {
  const dispatch = useDispatch();

  const { targetConfigs } = useContext(TargetConfigsContext);

  const options: VFDirectBoltOn[] = useSelector((state) =>
    getAllAvailableCliBoltOnsBySlot(state, {
      boltOnType: slotId,
      targetConfigs,
    })
  ) as any[];

  // 0 is used for "none is selected"
  const selectedOptionIds: (string | 0)[] = useSelector((state) =>
    getAllSelectedCliBoltOnIds(state, { boltOnType: slotId, targetConfigs })
  );

  return (
    <CliBoltOnSlotSelectField
      selectedOptionIds={selectedOptionIds}
      targetConfigs={targetConfigs}
      options={options}
      slotId={slotId}
      onChooseCliBoltOn={(configId, boltOnType, index, slotId) =>
        dispatch(chooseCliBoltOn(configId, boltOnType, index, slotId))
      }
    />
  );
};
