import React from "react";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    whiteSpace: "nowrap",
  },
});

/**
 * Cell for end of table rows.
 * Should contain <IconButton>s
 * @param props
 * @returns {*}
 * @constructor
 */
export function TableActionsCell(props: TableCellProps) {
  const classes = useStyles();

  return (
    <TableCell
      align="right"
      padding="none"
      className={classes.root}
      {...props}
    />
  );
}
