import React from "react";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";

interface CompletedOrderInfoProps {
  orderId: string;
}
export const CompletedOrderInfo = ({ orderId }: CompletedOrderInfoProps) => {
  const green = "#5cb85c";

  return (
    <div className="CompletedOrderInfo">
      <h2 style={{ color: green }}>
        <DoneIcon style={{ color: green }} /> Complete.
      </h2>
      Order ID: {orderId}
    </div>
  );
};

CompletedOrderInfo.propTypes = {
  orderId: PropTypes.string.isRequired,
};
