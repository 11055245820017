import React from "react";
import styled from "styled-components";

interface SmallButtonProps {
  secondary: boolean;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
}

type BtnProps = Pick<SmallButtonProps, "disabled" | "secondary">;

const Btn = styled.a<BtnProps>`
  display: inline-block;
  background: ${(props) =>
    props.secondary
      ? props.theme.palette.secondary.main
      : props.theme.palette.primary.main};
  color: white;
  padding: 6px 10px;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.2s;
  ${(props) =>
    props.disabled &&
    `
      background: #e5e5e5; color: graytext; cursor: not-allowed;
  `}

  &:hover {
    opacity: 0.8;
  }
`;

export const SmallButton = ({
  secondary,
  onClick,
  disabled,
  label,
}: SmallButtonProps) => {
  return (
    <Btn
      secondary={secondary}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
    >
      {label}
    </Btn>
  );
};
