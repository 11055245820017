import React from "react";

import { Box } from "@mui/material";

import { TitleAndInfo } from "../../shared/containers/TitleAndInfo";

import { StepDeliveryContainer } from "./containers";

export const Delivery = () => (
  <Box>
    <TitleAndInfo title="Delivery" />
    <StepDeliveryContainer />
  </Box>
);
