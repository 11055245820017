import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, // Box
  Theme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

// import { LoaderButton } from "@akj-dev/shared-components";
// import { getPricingRequest } from "../../../store/order/actions";
// import { Refresh } from "@mui/icons-material";

interface PricingRequestTaskViewProps {
  task: Record<string, any>;
  // loadPricingRequest: () => void;
}

export const PricingRequestTaskView = ({
  task,
}: PricingRequestTaskViewProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name of the task</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assigned to</TableCell>
              <TableCell>Date created</TableCell>
              <TableCell>Last modified</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{task.name}</TableCell>
              <TableCell>{task.status}</TableCell>
              <TableCell>{task.assigned_user_name}</TableCell>
              <TableCell>{task.date_entered_local}</TableCell>
              <TableCell>{task.date_modified_local}</TableCell>
              <TableCell>{task.note_description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      {/*<Box mt={1}>*/}
      {/*  <LoaderButton*/}
      {/*    loading={fetching}*/}
      {/*    onClick={loadPricingRequest}*/}
      {/*    variant="outlined"*/}
      {/*    startIcon={<Refresh />}*/}
      {/*  >*/}
      {/*    Refresh*/}
      {/*  </LoaderButton>*/}
      {/*</Box>*/}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  // TODO: This is copied from packages/affinity-sales/src/components/VirtualTable/index.tsx ...shared component?
  wrapper: {
    position: "relative",
    width: "100%",
    overflow: "auto",
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(0.25),
  },
}));
