import React from "react";
import { Box } from "@mui/material";
import { StepCustomise } from "./containers";
import { TitleAndInfo } from "../../shared/containers/TitleAndInfo";

export const Customise = () => {
  return (
    <Box>
      <TitleAndInfo title={"Customise Products"} />
      <StepCustomise />
    </Box>
  );
};
