import { OrderState } from "./types";
import { todaysDateString } from "../../shared/utils/date";

const initialState: OrderState = {
  // Wizard data is set on the window by DC and should always be present.

  // GS normally starts with an account ID to place an order on
  // This can be the end user account ID on the platform which is different from the head account ID in the account
  // reducer.
  accountId: "",
  accountName: "",
  platformCustomerReference: "",

  // Lead ID is set when this is a new account creation pathway
  // i.e. GS has been launched from a lead view in DC
  leadId: "",
  // Like the lead ID but shorter and human readable AFAIK...
  leadNumber: "",

  // GS can either be passed an order & opportunity at init (by DC)
  // or make one itself going into step 2 (which happens with MyAccount)
  // Hopefully we'll always do the latter eventually, but the existing opportunity flow in DC stop this currently.
  id: "",

  // We show a link to the opportunity (if there is one) in the toolbar
  // We also set things directly on the opportunity (not the order). Like the MSA flag.
  opportunityId: "",
  opportunityNumber: "",
  opportunityName: "",

  customerName: "",

  hostEnv: "",
  userResignsPermission: 0,

  baseUrl: "",
  leadName: "",

  quoteOnly: false,

  unrestrict: "",

  // contractLength: 24,

  contractUpload: {
    sending: false,
    response: false,
    receivedAt: todaysDateString,
    signedAt: todaysDateString,
    file: false,
  },
  trackingDetails: "",

  orderContactId: "",

  totalOneOffPrice: null,
  totalRecurringPrice: null,

  suppress_welcome_email: false,

  contractLength: "",

  // v1/Orders/View + Update
  orderStatus: {
    fetching: false,
    response: {},
  },

  // v1/Opportunity/Update
  opportunityStatus: {
    fetching: false,
    response: {},
  },

  /**
   * Responses from ALB removal requests.
   * Like:
   * {
   *    [bundleId]: {
   *      fetching: false,
   *      response: { ... }
   *    },
   *    [bundleId]: {
   *      ...
   * }
   */
  boltOnRemovals: {},

  orderProductIDs: {},

  recalculatePrices: {
    fetching: false,
    response: {},
  },

  ordersForAccount: {
    fetching: false,
    response: {},
  },

  provision: {
    fetching: false,
    response: {},
  },

  sendForApproval: {
    fetching: false,
    response: {},
  },

  approveApproval: {
    fetching: false, // Fetch needs to be reflected in approval UI
    // response: {} // but the response is orderStatus. Hence this mirrors the former
  },

  pricingRequest: {
    fetching: false,
    response: {},
  },

  rebookExpiredReservations: {
    fetching: false,
    response: {},
  },

  documentsUpload: {
    sending: false,
    response: false,
    files: false,
  },

  listDocuments: {
    fetching: false,
    response: {},
  },
};

export default initialState;
