import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

// import { confirm } from "../../../api/platform";
type DeselectDialogProps = {
  showWarning: boolean;
  handleOK: () => void;
  handleClose: () => void;
};

export function DeselectDialog({
  showWarning,
  handleOK,
  handleClose,
}: DeselectDialogProps) {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showWarning}
      >
        <DialogTitle>Remove Product Confirmation</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Deselecting this product type will remove any previously configured
            products. Do you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK} color="primary">
            OK
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
