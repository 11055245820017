import React, { useState, FocusEvent } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PublishIcon from "@mui/icons-material/Publish";
import makeStyles from "@mui/styles/makeStyles";

interface BulkSelectPopoverProps {
  bulkSelectByCLI: (event: any) => void;
}

export const BulkSelectPopover = ({
  bulkSelectByCLI,
}: BulkSelectPopoverProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleClick = (event: any) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  const bulkSelectByCLIHandler = (event: FocusEvent<HTMLInputElement>) => {
    bulkSelectByCLI(event.target.value);
    handleRequestClose();
  };

  const id = isOpen ? "bulkSelectPopover" : undefined;

  return (
    <>
      <Button
        data-cy="bulkSelectButton"
        color="primary"
        variant="text"
        size="large"
        onClick={handleClick}
        startIcon={<PublishIcon />}
      >
        Bulk select
      </Button>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleRequestClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box m={1}>
          <Typography variant="h3">Bulk Select</Typography>
          <Typography color="textSecondary" paragraph id="bulkSelectLabel">
            Enter a list of CLIs, separated by commas:
          </Typography>
          <TextField
            variant="standard"
            fullWidth
            data-cy="bulkSelectTextField"
            multiline
            name="clis"
            onBlur={bulkSelectByCLIHandler}
            aria-labelledby="bulkSelectLabel"
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleRequestClose}
          >
            Select
          </Button>
        </Box>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(() => ({
  popover: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
  },
}));
