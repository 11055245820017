import React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Icon from "@mui/icons-material/SwapVert";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

import { Section } from "../../../../shared/components/Section";
import { ChooseAvailableContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/NumberReservation/ChooseAvailable";
import {
  NUMBER_RES_CHOOSE,
  NUMBER_RES_CUSTOM,
  NUMBER_RES_NEXT,
} from "../../../../store/wlrBroadband/constants";

interface NumberReservationProps {
  numberReservation: any;
  onReserveNowClick: () => void;
  onSetReservedNumber: (value: string) => void;
  onSetNumberReservationType: (value: string) => void;
}

export const NumberReservation = ({
  numberReservation,
  onReserveNowClick,
  onSetReservedNumber,
  onSetNumberReservationType,
}: NumberReservationProps) => {
  const disable = numberReservation?.response.status === "success";

  return (
    <Section title="Number Reservation" avatar={<Icon />}>
      <FormControl variant="standard" fullWidth>
        <RadioGroup
          aria-label="number reservation"
          name="numberReservation"
          value={numberReservation.type}
          onChange={(_, v) => onSetNumberReservationType(v)}
        >
          <FormControlLabel
            control={<Radio />}
            value={NUMBER_RES_NEXT}
            label="Reserve next available number"
            disabled={disable}
          />
          <FormControlLabel
            control={<Radio />}
            value={NUMBER_RES_CUSTOM}
            label="Reserve custom number"
            disabled={disable}
          />
          <FormControlLabel
            control={<Radio />}
            value={NUMBER_RES_CHOOSE}
            label="Choose from available numbers"
            disabled={disable}
          />
        </RadioGroup>
      </FormControl>

      {numberReservation.type === NUMBER_RES_CUSTOM && (
        <TextField
          variant="standard"
          label="Enter your number"
          onChange={(event) => onSetReservedNumber(event.target.value)}
          value={numberReservation.selectedNumber}
          fullWidth
        />
      )}
      {numberReservation.type === NUMBER_RES_CHOOSE && (
        <ChooseAvailableContainer />
      )}
      <Button
        variant="contained"
        color="secondary"
        data-cy="NumberReservationButton"
        disabled={disable}
        onClick={onReserveNowClick}
        style={{ marginTop: 20 }}
      >
        Reserve Now
      </Button>

      <div style={{ height: 20 }} />

      {numberReservation.fetching ? (
        <StatusChip
          type="loading"
          title="Reserving number"
          message="Please wait"
        />
      ) : (
        <div>
          {numberReservation?.response.reservation_reference && (
            <StatusChip
              type="success"
              title="Number Reserved"
              message={
                <span>
                  CLI: <b>{numberReservation?.response.reserved_number}</b>
                  <br />
                  This number is only reserved for 4 hours.
                  <br />
                  If the contract is signed after 4 hours of receipt a new
                  number will be given.
                </span>
              }
            />
          )}

          {numberReservation?.response.status === "error" && (
            <StatusChip
              type="error"
              title="Sorry, something went wrong."
              message={numberReservation?.response.message}
            />
          )}
        </div>
      )}
    </Section>
  );
};
