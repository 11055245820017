import React, { ReactNode } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface TableOuterProps {
  hideAction?: boolean;
  children: ReactNode;
}

export const TableOuter = ({ hideAction, children }: TableOuterProps) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Product</TableCell>
        <TableCell>One Off Price</TableCell>
        <TableCell>Recurring Price</TableCell>
        <TableCell>Configured?</TableCell>
        {!hideAction && <TableCell>Actions</TableCell>}
      </TableRow>
    </TableHead>
    <TableBody>{children}</TableBody>
  </Table>
);

TableOuter.propTypes = {
  hideAction: PropTypes.bool,
};
