import React from "react";
import _ from "lodash";
import { LowPriorityInfoChip } from "../../../../shared/components/Chip/LowPriorityInfoChip";
import { Box, Chip } from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";

interface InstallationDetailsProps {
  stopDaisyLineOrdersEnabled: boolean;
  installationDetails: Record<string, any>;
}

export const InstallationDetails = ({
  installationDetails,
  stopDaisyLineOrdersEnabled,
}: InstallationDetailsProps) => {
  const selectServices = _.get(
    installationDetails,
    "calling_and_network_features.calling_and_network_feature",
    []
  );

  return (
    <LowPriorityInfoChip>
      {installationDetails.ownership_confirmation === "true" && (
        <Box mb={1}>
          {stopDaisyLineOrdersEnabled ? (
            <StatusChip
              type="error"
              title="Existing Daisy Line"
              message="Sorry, you may not proceed with this order."
              style={{ marginTop: 0 }}
            />
          ) : (
            <Chip
              label="Existing Daisy Line"
              color="primary"
              variant="filled"
            />
          )}
        </Box>
      )}
      <Box mb={1}>
        <b>Line Type: </b>
        {installationDetails.line_type}
      </Box>
      <Box mb={1}>
        <b>Service Type: </b>
        {installationDetails.service_type}
      </Box>
      <div>
        <b>Select Services: </b>
        {selectServices.length > 0 ? (
          <ul>
            {selectServices.map((service: any) => (
              <li key={`${service.cnf_name}-${service.aux_line_number}`}>
                {service.cnf_name}
              </li>
            ))}
          </ul>
        ) : (
          "None"
        )}
      </div>
    </LowPriorityInfoChip>
  );
};
