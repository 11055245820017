import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setProductTypes } from "../../../store/uiState/actions";
import {
  clearConfig as clearMobileConfig,
  toggleDwsTermsConfirmation,
} from "../../../store/mobile/actions";
import { clearConfig as clearHardwareConfig } from "../../../store/hardware/configurations/actions";
import { setConfiguration } from "../../../store/wlrBroadband/actions/configurations";
import { clearEthernetConfiguration } from "../../../store/ethernetProducts/actions";
import { clearConfig as clearMonitoringConfig } from "../../../store/monitoringService/actions";
import { clearConfig as clearUniversalConfig } from "../../../store/universalProducts/actions";
import { useGetProductTypeCards } from "../hooks";
import { ReduxAccountSettingsEnum } from "../../../shared/enums";

import { ProductTypesSelector } from "./components/ProductTypesSelector";
import { useDwsResellerEnabled } from "../../../shared/hooks/useDwsResellerEnabled";

export type ReduxChooseProductTypesType = {
  productTypes: ReduxAccountSettingsEnum[];
};

export const ChooseProductTypes = () => {
  const [open, setOpen] = React.useState(false);
  const [activeTileId, setActiveTileId] =
    React.useState<ReduxAccountSettingsEnum>();

  const productTypeCards = useGetProductTypeCards();
  const dispatch = useDispatch();

  const isDwsResellerEnabled = useDwsResellerEnabled();
  const { productTypes } = useSelector<any, ReduxChooseProductTypesType>(
    (state) => ({
      productTypes: state.uiState.productTypes ?? [],
    })
  );

  const dwsTermsAccepted = useSelector<any, boolean>(
    (state) => state.mobile.dwsTermsAccepted
  );

  const productTypesObject: { [k in ReduxAccountSettingsEnum]?: boolean } = {};
  productTypes.forEach((pt) => (productTypesObject[pt] = true));

  const showDwsTerms =
    isDwsResellerEnabled &&
    productTypes.includes(ReduxAccountSettingsEnum.MOBILE);

  const handleDwsTermsChange = () => dispatch(toggleDwsTermsConfirmation());

  const handleOK = () => {
    switch (activeTileId) {
      case "as_mobile_order_enabled":
        dispatch(clearMobileConfig());
        break;
      case "as_hardware_order_enabled":
        dispatch(clearHardwareConfig());
        break;
      case "as_wlrbb_order_enabled":
        dispatch(setConfiguration([]));
        break;
      case "as_ethernet_order_enabled":
        dispatch(clearEthernetConfiguration());
        break;
      case "as_monitoring_order_enabled":
        dispatch(clearMonitoringConfig());
        break;
      case "as_universal_enabled":
        dispatch(clearUniversalConfig());
        break;
      default:
        break;
    }

    activeTileId && toggleProductType(activeTileId);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckProductType = (id: ReduxAccountSettingsEnum) => () => {
    const newProductTypeObject = { ...productTypesObject };
    newProductTypeObject[id] = !newProductTypeObject[id];

    setActiveTileId(id);
    // toggled off the card
    if (newProductTypeObject[id] === false) {
      setOpen(true);
    } else {
      // toggled on the card
      toggleProductType(id);
    }
  };

  const toggleProductType = (id: ReduxAccountSettingsEnum) => {
    const newProductTypeObject = { ...productTypesObject };
    newProductTypeObject[id] = !newProductTypeObject[id];

    dispatch(
      setProductTypes(
        productTypeCards
          .map((card) => card.id)
          .filter((id) => !!newProductTypeObject[id])
      )
    );
  };

  return (
    <ProductTypesSelector
      productTypeCards={productTypeCards}
      dwsTermsAccepted={dwsTermsAccepted}
      productTypesObject={productTypesObject}
      showDwsTerms={showDwsTerms}
      handleCheckProductType={handleCheckProductType}
      handleDwsTermsChange={handleDwsTermsChange}
      showWarning={open}
      handleClose={handleClose}
      handleOK={handleOK}
    />
  );
};
