import { isValidPhoneNumber } from "../../shared/utils/validation";

export function isEthernetPurchaseArgValid(key: string, value: any) {
  if (key.indexOf("contact_phone") >= 0) {
    return isValidPhoneNumber(value);
  }

  if (key.indexOf("mounting_type") >= 0) {
    return !!value;
  }

  if (key.indexOf("customer_required_date") >= 0) {
    return !!value;
  }

  if (value === "") {
    // this case covers user configurable text fields on the ethernet order form
    return false;
  }

  return true;
}
