import React from "react";
import { useSelector } from "react-redux";

import { AirtimeCredit } from "../../../../components/Mobile/AirtimeCredit";

export const AirtimeCreditContainer = () => {
  const contractLengthInMonthsAllProducts = useSelector<any, number>(
    (state) => state.mobile.contractLengthInMonthsAllProducts
  );
  const coTerminusContractEndDate = useSelector<any, string>(
    (state) => state.mobile.coTerminusContractEndDate
  );
  const canAccessNewCoterminus = useSelector<any, string>(
    (state) =>
      state.account.settings.response.settings.as_can_access_new_coterminus
  );

  return (
    <AirtimeCredit
      contractLengthInMonthsAllProducts={contractLengthInMonthsAllProducts}
      coTerminusContractEndDate={coTerminusContractEndDate}
      canAccessNewCoterminus={canAccessNewCoterminus === "1"}
    />
  );
};
