import { getHardwareCount } from "../hardware/selectors";
import _ from "lodash";
import { createSelector } from "reselect";
import { getHostEnv } from "../order/selectors/getHostEnv";

export const isMobileOnly = (state) =>
  state.mobile.configs.length > 0 &&
  getHardwareCount(state) < 1 &&
  state.wlrBroadband.configurations.length < 1 &&
  state.ethernetProducts.configurations.length < 1;

export const getContacts = (state) => state.account.contacts;

/**
 * Get the "Valid for Resign" flag from the billing address response.
 * This indicates low quality addresses in general.
 *
 * @param state
 * @returns {*}
 */
export const getValidForResign = (state) =>
  _.get(state.account.billingAddress.response, "data.valid_for_resign");

/**
 * True if contacts have been fetched / exist on the current account
 * @param state
 * @returns {boolean}
 */
export const getHasContacts = (state) =>
  _.get(state.account.contacts?.response, "data.contacts", []).length > 0;

/**
 * Get account settings (data from DC or defaulted from config/accountSettings.default.js
 * @param state
 * @returns {*}
 */
export const getAccountSettings = (state) =>
  _.get(state.account, "settings.response.settings", {});

export const checkCommissionTag = (state, product) => {
  let tag = "";
  // To be added to as commission expands
  switch (product) {
    case "mobile":
      tag = "as_commission_type_selection_for_mobile";
      break;
    case "wlr":
      tag = "as_commission_type_selection_for_wlr_bb";
      break;
    default:
      break;
  }
  return _.get(state.account, `settings.response.settings.${tag}`, {}) === "1";
};

/**
 * Get selected contact for the order
 * @type {OutputSelector<unknown, *, (res1: *, res2: *) => *>}
 */
export const getOrderContact = createSelector(
  [
    (state) => state.account.contacts,
    (state) => state.generateContract.contactId,
  ],
  (contacts, contactId) => {
    return contacts.response.data?.contacts.find(
      (contact) => contact.id === contactId
    );
  }
);

/**
 * Get account meta
 * @param state
 * @returns {*}
 */
export const getAccountMeta = (state) =>
  _.get(state.account, "settings.response.meta", {});

/**
 * Check if the user is a sales person
 */
export const getIsSalesPerson = createSelector([(state) => state], (state) => {
  const meta = getAccountMeta(state);
  const hostEnv = getHostEnv(state);
  return hostEnv === "platform" && meta?.type === "sales_person";
});

/**
 * Determine whether we need to capture residential status from the user to create an account
 * @param state
 * @returns {boolean}
 */
export const getIsVfDirectConsumerAccount = createSelector(
  [
    (state) => state,
    (state) => state.account.newAccountFields.business_entity_type,
  ],
  (state, businessEntityType) => {
    const { can_access_vf_direct } = getAccountSettings(state);
    return (
      can_access_vf_direct === "1" &&
      businessEntityType === "Sole Trader / Owner Manager"
    );
  }
);

export const getCreditVetProcessAttempted = (state) =>
  state.account.creditVetProcess?.response?.status ||
  state.account.creditVetProcess?.fetching;

export const shouldProcessCreditVet = (state) => {
  const nonResignConfigs = state.mobile.configs.filter(
    (config) => !config.hasOwnProperty("resignId")
  );
  return nonResignConfigs.length > 0;
};

export const getIsVfDirectCreditVetPassed = (state) => {
  const creditVet = state.account.creditVetStatus;
  if (!creditVet) return true;
  const credtiVetCheck = creditVet?.response?.data?.vf_credit_check_status;
  return (credtiVetCheck === "Declined" ? false : true) ?? true;
};

export const getCreditVetFetching = (state) => {
  const creditVetProcess = state.account?.creditVetProcess;
  const creditVetStatus = state.account?.creditVetStatus;
  return (creditVetProcess?.fetching || creditVetStatus?.fetching) ?? false;
};
