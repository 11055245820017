const formatter = Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

/**
 * Format currency to GBP
 * @param subject
 */
export function formatCurrency(subject: number) {
  try {
    return formatter.format(subject);
  } catch (e) {
    console.error(e);
    return "N/A";
  }
}
