import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import { Box } from "@mui/material";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";

type ResellerBillingNotificationProps = {
  additionalNotification: any;
};

export const ResellerBillingNotification = ({
  additionalNotification,
}: ResellerBillingNotificationProps) => {
  // eslint-disable-next-line eqeqeq
  if (additionalNotification == 1) {
    return (
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <EmailIcon />
        </Box>
        <BulkDynamicField propertyName="notification_contact" />
      </Box>
    );
  }

  return null;
};
