import React from "react";

import { StatusChip } from "@akj-dev/shared-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { TableScrollWrap } from "../../../../shared/components/TableScrollWrap";

interface ONTInfoProps {
  ontDetails: Record<string, any>;
}
export const ONTInfo = ({ ontDetails }: ONTInfoProps) => {
  if (!ontDetails.ont_reference)
    return (
      <StatusChip
        type="info"
        message="No existing ONT found. NEW will be selected for this order"
      />
    );

  return (
    <div>
      {ontDetails.ont_spare_data_ports > 0 ? (
        <StatusChip
          type="success"
          message="There is an Existing ONT. It will be used for this order."
        />
      ) : (
        <StatusChip
          type="info"
          message="No available ports on the Existing ONT. NEW will be selected for this order and an appointment will be required."
        />
      )}
      <TableScrollWrap style={{ marginBottom: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ONT reference number</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Floor</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Minimum Lead Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{ontDetails.ont_reference}</TableCell>
              <TableCell>{ontDetails.ont_room}</TableCell>
              <TableCell>{ontDetails.ont_floor}</TableCell>
              <TableCell>{ontDetails.ont_position}</TableCell>
              <TableCell>
                {
                  // TODO: Get this logic applied inside DC. The stated lead time for existing ONTs is wrong frm BroadbandSearch currently.
                  ontDetails.ont_type === "EXISTING"
                    ? "1 Working Day"
                    : ontDetails.ont_minimum_lead_time
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableScrollWrap>
    </div>
  );
};
