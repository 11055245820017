import React, { PropsWithChildren } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ExpandingCard } from "@akj-dev/shared-components";

interface SectionProps {
  title?: string;
  defaultExpanded?: boolean;
  avatar: JSX.Element;
  "data-cy"?: string | null;
}

export const Section = ({
  title,
  avatar,
  defaultExpanded = true,
  children,
  "data-cy": dataCy = null,
}: PropsWithChildren<SectionProps>) => {
  return (
    <SectionOuter data-cy={dataCy}>
      <ExpandingCard {...{ title, avatar, defaultExpanded }}>
        {children}
      </ExpandingCard>
    </SectionOuter>
  );
};

const SectionOuter = styled.div`
  break-inside: avoid;
`;

Section.propTypes = {
  title: PropTypes.string,
  avatar: PropTypes.object,
  defaultExpanded: PropTypes.bool,
};
