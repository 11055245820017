import React from "react";

import { startOfMonth, addMonths, format } from "date-fns";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { StatusChip } from "@akj-dev/shared-components";

import { Section } from "../../../../shared/components/Section";
import { BulkDynamicField } from "../../containers/Mobile/ConfigurationForm/BulkDynamicField";
import { InputAdornment } from "@mui/material";
import {
  DC_DATE_FORMAT,
  isNotFirstOfMonth,
} from "../../../../shared/utils/date";

interface AirtimeCreditProps {
  contractLengthInMonthsAllProducts: number;
  coTerminusContractEndDate: string;
  canAccessNewCoterminus: boolean;
}

export const AirtimeCredit = ({
  contractLengthInMonthsAllProducts,
  coTerminusContractEndDate,
  canAccessNewCoterminus,
}: AirtimeCreditProps) => (
  <Section title="Airtime Credit" avatar={<AccountBalanceIcon />}>
    {canAccessNewCoterminus &&
    !coTerminusContractEndDate &&
    !contractLengthInMonthsAllProducts ? (
      <StatusChip
        type="error"
        title="Cannot offer Airtime Credit without a contract length"
      />
    ) : (
      <>
        <BulkDynamicField
          propertyName="airtime_credit_amount_oneoff"
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
        />
        <BulkDynamicField
          propertyName="airtime_credit_amount"
          InputProps={{
            startAdornment: <InputAdornment position="start">£</InputAdornment>,
          }}
        />
        <BulkDynamicField
          propertyName="airtime_credit_start_date"
          minDate={format(
            startOfMonth(addMonths(new Date(), 1)),
            DC_DATE_FORMAT
          )}
          shouldDisableDate={isNotFirstOfMonth}
        />
        <BulkDynamicField
          propertyName="airtime_credit_duration"
          availableOptionsMap={{
            // We don't want all the options DC gives us so:
            0: "None",
            1: "1 Month",
            [contractLengthInMonthsAllProducts]: `${contractLengthInMonthsAllProducts} Months`,
          }}
        />
      </>
    )}
  </Section>
);
