import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toggleStepOneUniversalProducts } from "../../../store/uiState/actions";
import { setContractLength } from "../../../store/order/actions";

import { ProductTableContainer } from "./containers/ProductTable";
import { UniversalProductsLayout } from "./components/Layout";
import { SelectChangeEvent } from "@mui/material/Select";

export const UniversalProducts = () => {
  const dispatch = useDispatch();

  const contractLength = useSelector<any, number>(
    (state) => state.order.contractLength
  );

  useEffect(() => {
    dispatch(toggleStepOneUniversalProducts());
  }, [dispatch]);

  const onContractLengthChange = (event: SelectChangeEvent<string>) => {
    dispatch(setContractLength(event.target.value));
  };

  return (
    <UniversalProductsLayout
      contractLength={contractLength}
      onContractLengthChange={onContractLengthChange}
    >
      <ProductTableContainer />
    </UniversalProductsLayout>
  );
};
