import React, { Dispatch, SetStateAction } from "react";
import { Chip, TableCell, TableRow, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import {
  ExistingAddressSelectContainer,
  ExistingAddressSelectProps,
} from "../../containers/Hardware/ExistingAddressSelect";

import { AddressOptions, AddressOptionsProps } from "./AddressOptions";
import { ExpanderIcon } from "../../../../shared/components/ExpanderIcon";

interface HardwareDeliveryRowProps {
  productId: string;
  quantity: number;
  split: boolean;
  splitEditId: string;
  setSplitEditId: Dispatch<SetStateAction<string>>;
  productName: string;
  addressType: addressTypes;
  addressId: string;
  handleChangeAddressType: AddressOptionsProps["handleChangeAddressType"];
  handleClickExistingAddressSelect: () => void;
  handleSelectAccountAddress: ExistingAddressSelectProps["handleSelectAccountAddress"];
}

export const HardwareDeliveryRow = ({
  productId,
  quantity,
  split,
  splitEditId,
  setSplitEditId,
  productName,
  addressType,
  addressId,
  handleChangeAddressType,
  handleClickExistingAddressSelect,
  handleSelectAccountAddress,
}: HardwareDeliveryRowProps) => {
  const classes = useStyles();

  const expanded = splitEditId === productId;
  return (
    <TableRow key={productId} data-cy="HardwareListRow">
      <TableCell
        onClick={() =>
          quantity > 1 &&
          setSplitEditId(productId === splitEditId ? "" : productId)
        }
        colSpan={expanded || split ? 3 : 1}
      >
        {quantity > 1 ? (
          <div className={classes.expander}>
            <ExpanderIcon expanded={expanded} data-cy="HardwareListRowExpand" />
            {productName}
            <Chip
              size="small"
              color="secondary"
              variant="filled"
              label={quantity}
              className={classes.quantity}
            />
            {split && (
              <Chip
                className={classes.splitChip}
                variant="outlined"
                size="small"
                label="Split delivery"
                color="secondary"
              />
            )}
          </div>
        ) : (
          productName
        )}
      </TableCell>
      {!expanded && !split && (
        <>
          <TableCell align="right">
            <AddressOptions
              addressType={!split && addressType}
              handleChangeAddressType={handleChangeAddressType}
            />
          </TableCell>
          <TableCell className={classes.addressSelectCell}>
            <div
              className={classes.addressSelect}
              onClick={handleClickExistingAddressSelect}
            >
              <ExistingAddressSelectContainer
                disabled={addressType !== addressTypes.EXISTING || split}
                handleSelectAccountAddress={handleSelectAccountAddress}
                addressId={split ? "" : addressId}
              />
            </div>
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export const useStyles = makeStyles((theme: Theme) => ({
  addressSelect: {
    width: 400,
    marginLeft: "auto",
  },
  addressSelectCell: {
    width: 400,
  },
  expander: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },

  quantity: {
    marginLeft: theme.spacing(1),
  },
  splitChip: {
    marginLeft: theme.spacing(1),
  },
}));
