import React, { useState, useEffect, useMemo } from "react";
import { debounce } from "lodash";
import { TextField } from "@mui/material";

type Props = {
  onDebouncedChange: (value: string) => void;
  value: string;
  wait: number;
  [x: string]: any;
};

const Component = ({ value, onDebouncedChange, wait, ...props }: Props) => {
  const [val, setVal] = useState("");

  useEffect(() => {
    setVal(value);
  }, [value]);

  const handleDebouncedChange = useMemo(
    () =>
      debounce((value: string) => {
        onDebouncedChange(value);
      }, wait),
    [onDebouncedChange, wait]
  );

  return (
    <TextField
      variant="standard"
      {...props}
      onChange={(event) => {
        setVal(event.target.value);
        handleDebouncedChange(event.target.value);
      }}
      value={val}
    />
  );
};

export default Component;
