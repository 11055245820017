import React from "react";
import { useSelector } from "react-redux";
import { StatusChip } from "@akj-dev/shared-components";
import { getTotalOrderHardwareCredit } from "../../../../store/hardware/configurations/selectors";
import { getHardwareOrderTotalBeforeCredit } from "../../../../store/order/selectors";

export const CreditWarning = () => {
  const totalOrderHardwareCredit = useSelector((state: any) =>
    getTotalOrderHardwareCredit(state.hardwareConfigurations)
  );

  const hardwareOrderTotal = useSelector((state: any) =>
    getHardwareOrderTotalBeforeCredit(state)
  );

  return totalOrderHardwareCredit > hardwareOrderTotal ? (
    <StatusChip
      type="error"
      title="Hardware credit must not exceed hardware order value"
      message="Please reduce the amount before continuing."
    />
  ) : null;
};
