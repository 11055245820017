import { trackSimpleEvent } from "../../shared/utils/reduxBeacon";
import { trackEvent } from "@redux-beacon/google-analytics";
import {
  setStep,
  toggleStepOneEthernet,
  toggleStepOneHardware,
  toggleStepOneMobile,
  toggleStepOneUniversalProducts,
  toggleStepOneWlrBroadband,
} from "./actions";

const gaEventsMap = {
  ...trackSimpleEvent(toggleStepOneMobile.type),
  ...trackSimpleEvent(toggleStepOneHardware.type),
  ...trackSimpleEvent(toggleStepOneWlrBroadband.type),
  ...trackSimpleEvent(toggleStepOneUniversalProducts.type),
  ...trackSimpleEvent(toggleStepOneUniversalProducts.type),
  ...trackSimpleEvent(toggleStepOneEthernet.type),
  [setStep.type]: trackEvent(
    (action) => ({
      category: "uiState",
      action: setStep.type,
      value: action.payload,
    }),
    "gtm1"
  ),
};

export default gaEventsMap;
