import React, { ReactNode } from "react";

import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ProductCard } from "../../../../../shared/components/ProductCard";
import { WlrLocationProductConfig } from "../../types";

export interface WlrLocationProductCardProps {
  productConfiguration?: WlrLocationProductConfig;
  title: string;
  subtitle?: string;
  broadbandSelect: ReactNode;
  handleClick: () => void;
}

export const WlrLocationProductCard = ({
  title,
  subtitle,
  productConfiguration,
  broadbandSelect,
  handleClick,
}: WlrLocationProductCardProps) => {
  const classes = useStyles();

  const selected = !!productConfiguration;

  return (
    <ProductCard
      dataCy={`card-${title}`}
      checked={selected}
      handleClick={selected ? undefined : handleClick}
    >
      <div className={classes.body}>
        <Typography>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
        {selected && broadbandSelect}
      </div>
    </ProductCard>
  );
};

const useStyles = makeStyles({
  body: {
    height: 100,
  },
  subtitle: {
    fontSize: "0.75rem",
  },
});
