import React from "react";

import { orderTypes, OrderType } from "../../../types";

import { MenuItem, TextField } from "@mui/material";

interface Props {
  selected?: OrderType;
  onSelect: (val?: OrderType) => void;
}

export function SelectOrderType({ selected, onSelect }: Props) {
  const ALL = "all";

  return (
    <TextField
      value={selected || ALL}
      style={{
        width: 400,
        textTransform: "capitalize",
      }}
      id="end-customer-select"
      label="Order Type"
      select
      onChange={(event) => {
        let val = event.target.value;
        if (val === ALL) {
          onSelect();
        } else {
          onSelect(val as OrderType);
        }
      }}
      variant="outlined"
    >
      <MenuItem value={ALL}>All Orders</MenuItem>
      {orderTypes.map((option) => (
        <MenuItem
          key={option}
          value={option}
          style={{ textTransform: "capitalize" }}
        >
          {`${option} Orders`}
        </MenuItem>
      ))}
    </TextField>
  );
}
