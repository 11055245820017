import React, { useState } from "react";
import { AccountOption, StatusBoardFilters } from "../types";
import Box from "@mui/material/Box";
import { Popover, Button } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FilterMenu } from "./FilterMenu";
import { FilterChip } from "./filterControls/FilterChip";
import { format, parseISO } from "date-fns";
import {
  getAcquisitionMethodDisplayLabel,
  getOrderStatusDisplayLabel,
} from "../../../../helpers/getOrderStatusDisplayLabel";

type Props = {
  filters: StatusBoardFilters;
  updateFilters: (filtersUpdate: Partial<StatusBoardFilters>) => void;
  onReset: () => void;
};

export const Filters = ({ filters, updateFilters, onReset }: Props) => {
  // hold the selected account id / name pairs here as we only want the id in the queryParams
  const [cachedOrderOwnerAccounts, setCachedOrderOwnerAccounts] = useState<
    AccountOption[]
  >([]);
  const [cachedEndUserAccounts, setCachedEndUserAccounts] = useState<
    AccountOption[]
  >([]);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(null);
  const handleFilterButtonClick = (event: React.MouseEvent) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };
  const isPopoverOpen = Boolean(popoverAnchorEl);
  const popoverId = isPopoverOpen ? "simple-popover" : undefined;

  const handleApply = (filters: Partial<StatusBoardFilters>) => {
    updateFilters(filters);
    handlePopoverClose();
  };

  const getChips = () => {
    const chips: React.ReactNode[] = [];

    const {
      created_after,
      created_before,
      order_status,
      acquisition_methods,
      ...restFilters
    } = filters;

    // date range - note these cannot be unset so the onDelete prop is NOT passed
    chips.push(
      <FilterChip
        key="date-range"
        value={`${format(parseISO(created_after), "dd/MM/yyyy")} - ${format(
          parseISO(created_before),
          "dd/MM/yyyy"
        )}`}
      />
    );

    if (Array.isArray(order_status)) {
      chips.push(
        order_status.map((status) => (
          <FilterChip
            key={`order_status-${status}`}
            onDelete={() => {
              updateFilters({
                order_status: order_status.filter((x) => x !== status),
              });
            }}
            value={"Status - " + getOrderStatusDisplayLabel(status)}
          />
        ))
      );
    }

    if (Array.isArray(acquisition_methods)) {
      chips.push(
        acquisition_methods.map((acquisition) => (
          <FilterChip
            key={`acquisition-method-${acquisition}`}
            onDelete={() => {
              updateFilters({
                acquisition_methods: acquisition_methods.filter(
                  (x) => x !== acquisition
                ),
              });
            }}
            value={
              "Acquisition method - " +
              getAcquisitionMethodDisplayLabel(acquisition)
            }
          />
        ))
      );
    }

    for (let filterKey in restFilters) {
      const filterValue = restFilters[filterKey as keyof typeof restFilters];

      if (typeof filterValue === "string") {
        chips.push(
          <FilterChip
            key={filterKey}
            onDelete={() => updateFilters({ [filterKey]: undefined })}
            value={filterValue}
          />
        );
      }

      if (Array.isArray(filterValue)) {
        chips.push(
          filterValue
            .filter((x) => typeof x === "string")
            .map(
              (value) =>
                value && (
                  <FilterChip
                    key={filterKey}
                    onDelete={() => {
                      updateFilters({
                        [filterKey]: filterValue.filter((x) => x !== value),
                      });
                    }}
                    value={value}
                  />
                )
            )
        );
      }
    }
    return chips;
  };

  const chips = getChips();

  return (
    <Box display="flex" data-cy="status-filter-chips">
      <Box display="flex" flexWrap="wrap" marginTop={chips.length > 0 ? -1 : 0}>
        {chips}
      </Box>
      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
      >
        <FilterMenu
          updateOrderOwnerAccountsCache={(accountOption) => {
            setCachedOrderOwnerAccounts([
              ...cachedOrderOwnerAccounts,
              accountOption,
            ]);
          }}
          updateEndUserAccountsCache={(accountOption) => {
            setCachedEndUserAccounts([...cachedEndUserAccounts, accountOption]);
          }}
          ownerAccountsCache={cachedOrderOwnerAccounts}
          endUserAccountsCache={cachedEndUserAccounts}
          filters={filters}
          onApplyFilters={(filters) => handleApply(filters)}
          onReset={onReset}
        />
      </Popover>
      <Box flex="0 1 auto" marginLeft="auto">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleFilterButtonClick}
          startIcon={<FilterListIcon />}
        >
          Filter Orders
        </Button>
      </Box>
    </Box>
  );
};
