import {
  AcquisitionMethod,
  OrderStatus,
  OrderType,
  ProductType,
} from "../../types";
import { getJSON } from "../helpers";

export interface ViewAPIResponseOrderComponent {
  circuit_reference?: string;
  cust_reference?: string;
  appointment_date?: string;
  forecast_date?: string;
  id: number;
  cli?: string;
  product?: string;
  request_stage: string;
  request_sub_stage?: string;
  submitted_date?: string;
  message: string;
  port_date?: string;
}

export interface ViewAPIResponseOrderCLI {
  cli: string;
  name: string;
}

export interface ViewAPIResponse {
  clis?: ViewAPIResponseOrderCLI[];
  components?: ViewAPIResponseOrderComponent[];
  customer_company_name: string;
  platform_customer_reference: string | null;
  address: string;
  billing_account: string;
  created_by_for_display: {
    name: string;
  };
  creation_date_timestamp: number;
  cust_reference: string;
  order_type: OrderType;
  order_number: number;
  order_product_category: ProductType;
  id: string | number;
  status_category: OrderStatus;
  warning?: number;
  message?: {
    message: string;
  };
  components_acquisition_method: AcquisitionMethod;
}

export function getDetailById(id: string | number) {
  return getJSON<ViewAPIResponse>("/Affinity/Orders/View/" + id);
}
