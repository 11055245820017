import React from "react";
import clsx from "clsx";
import { Box, Card, Theme, Typography, Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";
import shadowImage from "../../../../images/shadow.png";

export interface DataCardCheckboxProps {
  title: string;
  subtitle?: string;
  checked: boolean;
  handleClick: () => void;
  dataCy?: string;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  disabled?: boolean;
}

export const DataCardCheckbox = ({
  title,
  subtitle,
  checked,
  handleClick,
  dataCy,
  Icon,
  disabled = false,
}: DataCardCheckboxProps) => {
  const classes = useStyles({ checked });

  return (
    <Card
      raised
      className={clsx(classes.root, { [classes.rootDisabled]: disabled })}
      onClick={!disabled ? handleClick : undefined}
      data-cy={dataCy}
    >
      {disabled && <Box className={classes.overlay} />}
      <Box className={classes.box}>
        {Icon ? (
          <>
            <Icon className={classes.icon} />
            <img className={classes.shadow} src={shadowImage} alt="shadow" />
          </>
        ) : (
          <Box className={classes.noIcon} />
        )}
        <Typography className={classes.text}>{title}</Typography>
        {subtitle && (
          <Typography className={classes.text}>{subtitle}</Typography>
        )}
        <Box className={classes.bottom}>
          <Checkbox
            key={`${checked}`} // For some reason it wasn't re-rendering on checked change
            color="primary"
            checked={checked}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { checked: boolean }>((theme) => ({
  root: {
    position: "relative",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
      "& > div > svg": {
        transition: "margin 0.5s",
        margin: `${theme.spacing(3.5)} 0 ${theme.spacing(0.5)} 0`,
      },
    },
    "& > div > svg": {
      transition: "margin 0.5s",
      margin: `${theme.spacing(4)} 0 0 0`,
    },
  },
  rootDisabled: {
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `${theme.palette.grey[100]}88`,
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: ({ checked }) => ({
    fontSize: "5rem",
    color: checked ? theme.palette.primary.main : theme.palette.grey[300],
  }),
  shadow: {
    width: "5rem",
    margin: `${theme.spacing(1)} 0`,
  },
  noIcon: {
    width: "100%",
    height: theme.spacing(3),
  },
  text: {
    height: "1rem",
  },
  bottom: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));
