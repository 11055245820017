import { multiPartPostJSON } from "../helpers/core";
import { postJSONBackgroundIdempotent } from "../helpers/idempotency";

export const create = (productId, params, identifier = null) =>
  postJSONBackgroundIdempotent(`OrderProduct/Create/${productId}`, {
    ...params,
    skip_price_recalculations: true,
    identifier,
  });

export const update = (orderProductID, params, identifier = null) =>
  postJSONBackgroundIdempotent(`OrderProduct/Update/${orderProductID}`, {
    ...params,
    skip_price_recalculations: true,
    identifier,
  });

export const uploadDocument = (
  productInstanceId,
  document_file,
  send_with_contract
) =>
  multiPartPostJSON(`OrderProduct/UploadDocument/${productInstanceId}`, {
    document_file,
    send_with_contract: send_with_contract ? 1 : 0,
  });
