import React from "react";
import { Grid } from "@mui/material";

import { ProductTypeCardType } from "../../types";
import { ReduxAccountSettingsEnum } from "../../../../shared/enums";

import { DataCardCheckbox } from "./DataCardCheckbox";
import { Info } from "./Info";
import { DwsTerms } from "./DwsTerms";
import { DeselectDialog } from "./DeselectDialog";

export type ProductTypesSelectorProps = {
  productTypeCards: ProductTypeCardType[];
  showDwsTerms: boolean;
  dwsTermsAccepted: boolean;
  productTypesObject: Record<string, boolean>;
  handleDwsTermsChange: () => void;
  handleCheckProductType: (id: ReduxAccountSettingsEnum) => () => void;
  showWarning: boolean;
  handleOK: () => void;
  handleClose: () => void;
};

export const ProductTypesSelector = ({
  productTypeCards,
  showDwsTerms,
  dwsTermsAccepted,
  productTypesObject,
  handleCheckProductType,
  handleDwsTermsChange,
  showWarning,
  handleClose,
  handleOK,
}: ProductTypesSelectorProps) => {
  return (
    <>
      <Grid container spacing={2}>
        {productTypeCards.map(({ id, label, dataCy, Icon }) => (
          <Grid key={label} item xs={12} sm={6} md={4} lg={3}>
            <DataCardCheckbox
              title={label}
              checked={!!productTypesObject[id]}
              handleClick={handleCheckProductType(id)}
              dataCy={dataCy}
              Icon={Icon}
            />
          </Grid>
        ))}
      </Grid>

      <Info />

      {showDwsTerms && (
        <DwsTerms
          handleChange={handleDwsTermsChange}
          dwsTermsAccepted={dwsTermsAccepted}
        />
      )}
      <DeselectDialog
        showWarning={showWarning}
        handleOK={handleOK}
        handleClose={handleClose}
      />
    </>
  );
};
