import React from "react";
import { Section } from "../../../../shared/components/Section";
import HomeIcon from "@mui/icons-material/Home";
import { ArrayDynamicField } from "../../containers/Mobile/ConfigurationForm/ArrayDynamicField";

export const CostCentres = () => (
  <Section title="Cost Centres" avatar={<HomeIcon />} defaultExpanded={false}>
    <ArrayDynamicField
      propertyName="cost_centre_1"
      floatingLabelText="Cost Centre 1"
    />
    <ArrayDynamicField
      propertyName="cost_centre_2"
      floatingLabelText="Cost Centre 2"
    />
    <ArrayDynamicField
      propertyName="cost_centre_3"
      floatingLabelText="Cost Centre 3"
    />
  </Section>
);
