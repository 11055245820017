import React, { ReactNode, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { fetchAllAccountAddresses } from "../../../../store/account/actions";
import { DCContact } from "../../../../api/v1/account";
import { isValidDeliveryContactAddress } from "../../utils/helpers";
import { ExistingAddressSelect } from "../../components/Hardware/ExistingAddressSelect";

export interface ExistingAddressSelectProps {
  handleSelectAccountAddress: (event: any, child: ReactNode) => void;
  /**
   * This is either a contact UUID or an array index when it's an accountAddresses, that has no unique identifier.
   * Yes, this is very messy.
   */
  addressId: number | string;
  disabled?: boolean;
}

export const ExistingAddressSelectContainer = (
  props: ExistingAddressSelectProps
) => {
  const dispatch = useDispatch();

  /**
   * DC has possible delivery addresses stored in both "Account Addresses" (a non-editable list of previous entries)
   * and contacts, which are editable and necessary for multi-delivery address work (TP41267). The former legacy data
   * is still required though, by the looks of it, hence we need to display both.
   */
  const accountAddresses =
    useSelector(
      (state: any) => state.account.accountAddresses.response.data?.addresses
    ) || [];
  const accountAddressesStatus = useSelector<any, string>(
    (state) => state.account.accountAddresses?.response.status
  );

  const accountContacts = useSelector<any, DCContact[]>(
    (state: any) => state.account.contacts?.response?.data?.contacts
  );
  const filteredAccountContacts =
    accountContacts?.filter?.(
      (contact) =>
        // Some contacts won't be of a type we can use for addresses. The ID would mean a different thing:
        contact.virtual_contact_source !== "account" &&
        // Many contacts won't have an address:
        isValidDeliveryContactAddress(contact)
    ) || [];

  useEffect(() => {
    if (accountAddressesStatus !== "success") {
      dispatch(fetchAllAccountAddresses());
    }
  }, [accountAddressesStatus, dispatch]);

  const hasAddresses = Boolean(
    accountAddresses?.length || accountContacts?.length
  );

  return (
    <ExistingAddressSelect
      accountAddresses={accountAddresses}
      filteredAccountContacts={filteredAccountContacts}
      hasAddresses={hasAddresses}
      {...props}
    />
  );
};
