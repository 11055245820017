import React, { useState } from "react";

import { VirtualTable } from "../../../../../../../../../shared/components/VirtualTable";
import { ActiveSort } from "../../../../../../../../../shared/components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../../../../../../shared/components/VirtualTable/enums";
import {
  ReduxWlrLocation,
  WlrLocationBroadbandProduct,
  WlrLocationProduct,
  WlrLocationProductConfig,
} from "../../../../../../types";

import { BroadbandModalTableRenderRow } from "./RenderRow";

interface WlrLocationNewLineBroadbandCellModalProductTableProps {
  location: ReduxWlrLocation;
  product: WlrLocationProduct;
  configurations: WlrLocationProductConfig[];
  locationIndex: number;
  contractLength: number;
}

export const WlrLocationNewLineBroadbandCellModalProductTable = ({
  location,
  product,
  configurations,
  locationIndex,
  contractLength,
}: WlrLocationNewLineBroadbandCellModalProductTableProps) => {
  const [activeSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.ASC,
  });

  const isMpfProduct = product.first_broadband_component?.type === "MPF";
  const headers = tableHeaders(isMpfProduct);
  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  return (
    <VirtualTable
      headers={headers}
      activeSort={activeSort}
      handleSort={() => {}}
      data={{
        contractLength,
        location: location,
        products: configurations,
        locationProduct: product,
        isMpfProduct,
        broadbandProducts,
        locationIndex,
      }}
      tableSize={400}
      itemSize={50}
      itemCount={configurations.length}
      RenderTableRow={BroadbandModalTableRenderRow}
    />
  );
};

const tableHeaders = (isMpfProduct: boolean) => [
  { label: "Product", sortIndex: "name", disabled: true, width: 250 },
  {
    label: "Normally Available Download Speeds",
    sortIndex: "name",
    disabled: true,
    width: 250,
  },
  {
    label: "Normally Available Upload Speeds",
    sortIndex: "name",
    disabled: true,
    width: 250,
  },
  {
    label: "Minimum Guaranteed Download Speed",
    sortIndex: "name",
    disabled: true,
    width: 250,
  },
  { label: "Lead Time", sortIndex: "name", disabled: true, width: 250 },
  ...(isMpfProduct
    ? []
    : [
        {
          label: "Select Broadband",
          sortIndex: "broadband",
          disabled: true,
          width: 250,
        },
      ]),
  { label: "Price From", sortIndex: "price", disabled: true, width: 150 },
];
