import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddCircle from "@mui/icons-material/AddCircle";
import { addLocation } from "../../../../store/wlrBroadband/actions/locations";
import { IndexedCardInfo } from "@akj-dev/shared-components";
import { ReduxWlrLocation } from "../types";
import { WlrLocationContainer } from "../containers/WlrLocation";

interface LocationSelectSectionProps {
  cardIndex: number;
}

export const LocationSelectSection = ({
  cardIndex,
}: LocationSelectSectionProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const locations = useSelector<any, ReduxWlrLocation[]>(
    (state) => state.wlrBroadband.locations
  );

  const [locationOpened, setLocationOpened] = useState(0);
  const locationsLength = locations.length;

  const handleLocationOpened = useCallback(
    (index) =>
      setLocationOpened((oldIndex) => (oldIndex === index ? -1 : index)),
    [setLocationOpened]
  );

  const handleAddLocation = useCallback(() => {
    setLocationOpened(locationsLength);
    dispatch(addLocation());
  }, [dispatch, setLocationOpened, locationsLength]);

  return (
    <IndexedCardInfo index={cardIndex} title="Please select the Locations">
      <Box className={classes.locations}>
        {locations.map((location, index) => (
          <WlrLocationContainer
            // No stable identity
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            open={index === locationOpened}
            locationIndex={index}
            location={location}
            handleLocationOpened={handleLocationOpened}
          />
        ))}
        <Button
          variant="outlined"
          color="primary"
          fullWidth={true}
          startIcon={<AddCircle />}
          onClick={handleAddLocation}
        >
          Add Another Location
        </Button>
      </Box>
    </IndexedCardInfo>
  );
};

const useStyles = makeStyles({
  locations: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});
