import React, { useEffect } from "react";

import Slider, { SliderProps } from "@mui/material/Slider";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

interface SliderLowUpdateProps extends SliderProps {
  onDragStop: (value: number) => void;
  value: number;
}

export const SliderLowUpdate = ({
  value,
  onDragStop,
  max,
  ...rest
}: SliderLowUpdateProps) => {
  const classes = useStyles();

  const [intValue, setIntValue] = React.useState(0);

  useEffect(() => setIntValue(value), [value]);

  return (
    <Box my={2}>
      <Slider
        {...rest}
        max={max}
        value={intValue}
        getAriaValueText={(value) => `${value}%`}
        valueLabelDisplay="on"
        onChange={(_, value) => setIntValue(value as number)}
        onChangeCommitted={(_, value) => onDragStop(value as number)}
        className={
          intValue == max // eslint-disable-line eqeqeq
            ? classes.max
            : ""
        }
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  max: {
    color: theme.palette.success.main,
  },
}));
