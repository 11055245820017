import React from "react";
import { useSelector } from "react-redux";

import { getAccountSettings } from "../../../store/account/selectors";
import { getAccountId } from "../../../store/order/selectors";
import { ReduxAccountSettingsEnum } from "../../../shared/enums";

import { TariffTableContainer } from "./containers/Tariff/Table";
import { ResignsTableContainer } from "./containers/Resigns";
import { BoltOnTableContainer } from "./containers/BoltOnTable";
import { ContractSection } from "./sections/ContractSection";
import { TableSection } from "./sections/TableSection";
import { MobileProductConfig } from "../../../shared/types/MobileProductConfig";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";

export const ChooseMobile = () => {
  const accountId = useSelector(getAccountId);
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);
  const contractLength = useSelector<any, number | undefined>(
    (state) =>
      state.mobile.contractLengthInMonthsUIFilter ||
      state.mobile.contractLengthInMonthsAllProducts ||
      state.mobile.coTerminusContractEndDate
  );
  const showMobileResigns = useSelector<any, boolean>(
    (state) => state.uiState.showMobileResigns
  );
  const configs = useSelector<any, MobileProductConfig[]>(
    (state) => state.mobile.configs ?? []
  );
  const hostEnv = useSelector(getHostEnv);

  const configsCount = configs.length;

  const showTable = !!contractLength;
  const showResignsToggle =
    showTable && !!accountId && settings.as_mobile_resign_enabled === "1";
  const showBoltOn =
    configsCount > 0 && settings.as_account_level_bolt_ons_enabled === "1";

  let cardIndex = 0;

  const TABLES = [
    {
      title: "Please choose your mobile resigns",
      tableComponent: <ResignsTableContainer />,
      show: showMobileResigns,
    },
    {
      title:
        showMobileResigns && hostEnv === "platform"
          ? "Please select the required tariffs for any additional connections"
          : "Please select the required tariffs",
      tableComponent: <TariffTableContainer />,
      show: showTable,
    },
    {
      title: "Please select any required bolt ons",
      tableComponent: <BoltOnTableContainer />,
      show: showBoltOn,
    },
  ];

  return (
    <>
      <ContractSection
        cardIndex={++cardIndex}
        showResignsToggle={showResignsToggle}
      />

      {TABLES.map((table) =>
        table.show ? (
          <TableSection key={table.title} {...table} cardIndex={++cardIndex} />
        ) : null
      )}
    </>
  );
};
