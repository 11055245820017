import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailableNonTopUpCliBoltOns,
  getAvailableCliTopUpBoltOns,
  getAllSelectedCliBoltOnIds,
} from "../../../../../../../store/mobile/selectors/productConfig";
import { chooseCliBoltOn } from "../../../../../../../store/mobile/actions";
import { CliBoltOnSelectField } from "../../../../../components/Mobile/CliBoltOnSelectField";

type Props = {
  title: string;
  targetConfigs: number[];
  boltOnType: string;
};

export const CliBoltOnSelectFieldContainer = ({
  title,
  targetConfigs,
  boltOnType,
}: Props) => {
  const dispatch = useDispatch();
  const availableCliBoltOns = useSelector((state) =>
    getAvailableNonTopUpCliBoltOns(state, { boltOnType, targetConfigs })
  ) as any;
  const availableCliTopUpBoltOns = useSelector((state) =>
    getAvailableCliTopUpBoltOns(state, { targetConfigs })
  );
  const selectedCliBoltOn = useSelector((state) =>
    getAllSelectedCliBoltOnIds(state, { targetConfigs, boltOnType })
  );
  const hasSelectedCliTopUpBoltOn = !!availableCliTopUpBoltOns.find(
    (boltOn: any) => selectedCliBoltOn.includes(boltOn.id)
  );

  return (
    <CliBoltOnSelectField
      title={title}
      targetConfigs={targetConfigs}
      hasSelectedCliTopUpBoltOn={hasSelectedCliTopUpBoltOn}
      availableCliBoltOns={availableCliBoltOns}
      boltOnType={boltOnType}
      selectedCliBoltOn={selectedCliBoltOn}
      onChange={(configId, boltOnType, value) =>
        dispatch(chooseCliBoltOn(configId, boltOnType, value))
      }
    />
  );
};
