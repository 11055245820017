import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addUserDocument,
  removeUserDocument,
  setUserDocumentSendPref,
} from "../../../../../store/universalProducts/actions";
import { UploadDocuments } from "../../../components/UniversalProducts/UploadDocuments";

export const UploadDocumentsContainer = () => {
  const dispatch = useDispatch();

  const selectedConfigIndex = useSelector<any, number>(
    (state) => state.universalProducts.selectedConfigIndex
  );

  const userDocuments = useSelector(
    (state: any) =>
      state.universalProducts.configs[selectedConfigIndex].userDocuments
  );
  const userDocumentsSendPref = useSelector(
    (state: any) =>
      state.universalProducts.configs[selectedConfigIndex].userDocumentsSendPref
  );

  return (
    <UploadDocuments
      userDocuments={userDocuments}
      userDocumentsSendPref={userDocumentsSendPref}
      onFileDrop={(files) => dispatch(addUserDocument(files))}
      onRemove={(index) => dispatch(removeUserDocument(index))}
      onSendPref={(pref, index) =>
        dispatch(setUserDocumentSendPref(index, pref))
      }
    />
  );
};
