import { createSelector } from "reselect";
import u from "updeep";

/**
 * Get the total number of configurations for a certain product ID
 * Used to show totals on step 1 table
 * @param configs
 * @param productId
 */
export function getProductConfigCount(configs, productId) {
  let count = 0;
  configs.forEach((c) => {
    if (c.productId === productId) count++;
  });
  return count;
}

/**
 * Get the currently selected config
 * @param state
 * @returns {*}
 */
export const getSelectedConfig = (state) =>
  state.universalProducts.configs[state.universalProducts.selectedConfigIndex];

/**
 * Get product name for a config index
 * Note this is memoized as it's doing more than simple object prop selection.
 * ...although tbh I'm not 100% sure it's worth it as it still re-computes for every table row.
 * @returns {Reselect.Selector<any, any>}
 * */
export const makeGetConfigProductName = () =>
  createSelector(
    [
      (state, props) =>
        state.universalProducts.configs[props.configIndex].productId,
      (state) => state.universalProducts.productSearch.response,
    ],
    (productId, productSearch) => {
      if (!Array.isArray(productSearch.products)) return false;
      return productSearch.products.find((p) => p.id === productId).name;
    }
  );

/**
 * Get list of documents associated with a config's product ID
 * @param state
 * @param configIndex { number | boolean}
 * @returns {*}
 */
export const getConfigDocuments = (state, configIndex = false) => {
  if (configIndex === false)
    configIndex = state.universalProducts.selectedConfigIndex;
  const productId = state.universalProducts.configs[configIndex].productId;
  return state.universalProducts.productDocuments[productId];
};

/**
 * Test if universal product configs are valid. Prices much be entered for each, even if they're 0.
 * @param config
 * @returns {boolean}
 */
export const getIsUniversalProductConfigValid = (config) => {
  if (
    (config.oneOffPrice === "" || parseFloat(config.oneOffPrice) === 0) &&
    (config.recurringPrice === "" || parseFloat(config.recurringPrice) === 0)
  ) {
    return false;
  }
  return true;
};

/**
 * Test if universal product has at least one document uploaded.
 * @param config
 * @returns {boolean}
 */
export const getDoesUniversalProductHaveDocuments = (config) =>
  config.userDocuments.length > 0;

/**
 * Calculate the universal component prices including discounts
 * @param properties
 * @param component_price
 * @param fetching
 */
export function getRecurringDiscountedPrice(
  properties,
  component_price,
  fetching
) {
  // Only calc if fetch happening...
  if (fetching) {
    const fullPrice = component_price.recurring_price_without_discounts;
    const discountType = properties.recurring_discount_type;
    const discountValue = properties.recurring_discount_value;

    let newPrice;
    switch (discountType) {
      case "DiscountPercentage":
        newPrice = (fullPrice / 100) * (100 - discountValue);
        break;
      case "DiscountAmount":
        newPrice = fullPrice - discountValue;
        break;
      case "SpecifyPrice":
        newPrice = discountValue || fullPrice;
        break;
      default:
        newPrice = fullPrice;
    }
    return u(
      {
        first_bill_recurring_price_with_promotions:
          parseFloat(newPrice).toFixed(2),
      },
      component_price
    );
  }

  return component_price;
}

/**
 * Calculate the universal component prices including discounts
 * @param properties
 * @param component_price
 * @param fetching
 */
export function getOneOffDiscountedPrice(
  properties,
  component_price,
  fetching
) {
  // Only calc if fetch happening...
  if (fetching) {
    const fullPrice = component_price.one_off_price_without_promotions;
    const discountType = properties.one_off_discount_type;
    const discountValue = properties.one_off_discount_value;

    let newPrice;
    switch (discountType) {
      case "DiscountPercentage":
        newPrice = (fullPrice / 100) * (100 - discountValue);
        break;
      case "DiscountAmount":
        newPrice = fullPrice - discountValue;
        break;
      case "SpecifyPrice":
        newPrice = discountValue || fullPrice;
        break;
      default:
        newPrice = fullPrice;
    }

    return u(
      {
        one_off_price_with_promotions: parseFloat(newPrice).toFixed(2),
      },
      component_price
    );
  }

  return component_price;
}
