import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { AccountSettings } from "@akj-dev/affinity-platform";

import { isHardwareOnly as isHardwareOnlySelector } from "../../../store/hardware/selectors";
import { getOrderProductCallsFetching } from "../../../store/order/selectors";
import { fetchingRemoveSpecialRates as fetchingRemoveSpecialRatesSelector } from "../../../store/wlrBroadband/selectors";
import {
  getProvisioningBundleId,
  getUploadedContractId,
  getRequiresCustomerApproval,
} from "../../../store/order/selectors";
import { getPendingContractId } from "../../../store/generateContract/selectors";
import {
  getAccountSettings,
  getIsSalesPerson,
} from "../../../store/account/selectors";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";
import { getOrderStatus } from "../../../store/order/actions";
import { HostEnv } from "../../../index";
import { MessagesSection } from "../sections/MessagesSection";
import { CompleteOrderSection } from "../sections/CompleteOrderSection";
import { ETFSection } from "../sections/ETFSection";

import { AddAppointmentStatusContainer } from "./AddAppointmentsStatus";
import { OrderViewTableContainer } from "./OrderViewTable";
import { OrderProductStatus } from "./OrderProductStatus";
export const StepSummary = () => {
  const dispatch = useDispatch();

  const order = useSelector((state: any) => state.order);
  const isRejected = useSelector(
    (state: any) => state.order.orderStatus.response.data?.status === "rejected"
  );
  const ethernetConfigurations = useSelector(
    (state: any) => state.ethernetProducts.configurations
  );
  const orderProductCallsFetching = useSelector<any, boolean>(
    getOrderProductCallsFetching
  );
  const fetchingRemoveSpecialRates = useSelector<any, boolean>(
    fetchingRemoveSpecialRatesSelector
  );
  const isHardwareOnly = useSelector<any, boolean>(isHardwareOnlySelector);
  const uploadedContractId = useSelector<any, any>(getUploadedContractId);
  const pendingContractId = useSelector<any, any>(getPendingContractId);
  const provisioningBundleId = useSelector<any, any>(getProvisioningBundleId);
  const accountSettings = useSelector<any, AccountSettings>(getAccountSettings);
  const hostEnv = useSelector<any, HostEnv>(getHostEnv);
  const isSalesPerson = useSelector<any, boolean>(getIsSalesPerson);
  const requiresCustomerApproval = useSelector<any, boolean>(
    getRequiresCustomerApproval
  );

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 4 (Summary / Generate Contract) mounted}`,
      category: "action",
    });

    // If we have products already in the order view, the data should be refreshed when component mounts
    // This is for users revisiting the order via saved draft after a commercial pricing request. TP1187
    if (order.orderStatus.response.data?.products?.length > 0) {
      dispatch(getOrderStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const approval = _.get(order.orderStatus.response, "data.approval");
  const actions = _.get(order.orderStatus.response, "data.actions");
  const reasonNotConfigured = _.get(
    order.orderStatus.response,
    "data.reason_not_configured"
  );
  const rebookExpiredReservationsStatus = _.get(
    order.rebookExpiredReservations.response,
    "status"
  );

  const contract = _.get(order.orderStatus.response, "data.contract") || {};

  // Order complete? Better stop showing those buttons if so....
  // There's multiple ways that can happen though, depending on order type and launch scenario:
  const complete = !!(
    uploadedContractId || // Contract uploaded
    pendingContractId || // Contract generated
    Object.keys(contract).length !== 0 || // Contract generated when loaded from wizardState?
    // Hardware only / MyAccount flow:
    // "Provisioned" can either be "sent for approval", which triggers the provisioning process in DC,
    // or "actually provisioned".
    provisioningBundleId ||
    (isHardwareOnly &&
      !!_.get(order.sendForApproval, "response.data.submitted"))
  );

  const fetchingOrNoData =
    orderProductCallsFetching ||
    _.get(order.orderStatus, "response.data.products", []).length < 1 ||
    fetchingRemoveSpecialRates;

  return (
    <>
      {
        // TP32603.
        isSalesPerson &&
          !reasonNotConfigured &&
          rebookExpiredReservationsStatus !== "success" && (
            <AddAppointmentStatusContainer />
          )
      }

      <OrderProductStatus hideSuccess={!fetchingOrNoData} />

      {!fetchingOrNoData && (
        <>
          <OrderViewTableContainer />

          <ETFSection order={order} />

          <MessagesSection
            orderId={order.id}
            showEthernetMessages={ethernetConfigurations.length > 0}
            requiredApproval={!!approval.required}
            hostEnv={hostEnv}
            complete={complete}
            approval={approval}
          />

          {!complete && (
            <CompleteOrderSection
              order={order}
              actions={actions}
              accountSettings={accountSettings}
              isHardwareOnly={isHardwareOnly}
              ethernetConfigurations={ethernetConfigurations}
              hostEnv={hostEnv}
              requiresCustomerApproval={requiresCustomerApproval}
              isRejected={isRejected}
            />
          )}
        </>
      )}
    </>
  );
};
