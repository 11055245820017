import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  isValidMobileNumber,
  isValidEmail,
} from "../../../../shared/utils/validation";
import { Section } from "../../../../shared/components/Section";
import { LogicMonitorIcon } from "../../../../shared/components/Icons/LogicMonitorIcon";
import { TextField, Grid, Box } from "@mui/material";
import { ChangeEvent } from "react";

interface MonitoringServiceProps {
  emailAddress: string;
  phoneNumber: string;
  handlePhoneNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleEmailAddressChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAlertPreferencesChange: (newValues: Record<string, string>) => void;
}

export const MonitoringService = ({
  emailAddress,
  phoneNumber,
  handleEmailAddressChange,
  handlePhoneNumberChange,
  handleAlertPreferencesChange,
}: MonitoringServiceProps) => {
  return (
    <Section avatar={<LogicMonitorIcon />}>
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={6}>
          <Box display="flex" alignItems="center">
            <EmailIcon style={{ marginRight: "5px" }} />
            <TextField
              variant="standard"
              data-cy="alertPrefEmail"
              label="Email Address"
              value={emailAddress}
              fullWidth
              helperText={
                emailAddress &&
                !isValidEmail(emailAddress) &&
                "Email address is not valid."
              }
              error={Boolean(emailAddress) && !isValidEmail(emailAddress)}
              onChange={handleEmailAddressChange}
              onBlur={() => handleAlertPreferencesChange({ emailAddress })}
            />
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" alignItems="center">
            <PhoneIcon style={{ marginRight: "5px" }} />
            <TextField
              variant="standard"
              data-cy="alertPrefPhone"
              label="Mobile Number"
              value={phoneNumber}
              fullWidth
              helperText={
                phoneNumber &&
                !isValidMobileNumber(phoneNumber) &&
                "Mobile number is not valid."
              }
              error={Boolean(phoneNumber) && !isValidMobileNumber(phoneNumber)}
              onChange={handlePhoneNumberChange}
              onBlur={() => handleAlertPreferencesChange({ phoneNumber })}
            />
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
};
