import u from "updeep";
import * as actionTypes from "./actionTypes";
import { addressTypes } from "./constants";

const initialState = {
  addressType: addressTypes.BILLING,
  addressId: "",
  newAddress: {},
  splitDelivery: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADDRESS_TYPE:
      return u(
        {
          addressType: action.addressType,
        },
        state
      );

    case actionTypes.SELECT_ACCOUNT_ADDRESS:
      return u(
        {
          addressId: action.index,
        },
        state
      );

    case actionTypes.SET_NEW_ADDRESS_ELEMENT:
      return u(
        {
          newAddress: {
            [action.element]: action.value,
          },
        },
        state
      );

    case actionTypes.TOGGLE_SPLIT_DELIVERY:
      return u(
        {
          splitDelivery: !state.splitDelivery,
        },
        state
      );

    default:
      return state;
  }
};

export default reducer;
