import React from "react";
import PropTypes from "prop-types";
import HomeIcon from "@mui/icons-material/Home";
import { ExpandingCard } from "@akj-dev/shared-components";

import { SiteAddress } from "../../../../shared/components/Ethernet/SiteAddress";

interface SiteInstallationAddressProps {
  prefix: "site_a" | "site_b";
  purchaseArgs: Record<string, any>;
}

export const SiteInstallationAddress = (
  props: SiteInstallationAddressProps
) => (
  <ExpandingCard
    title="Site Installation Address"
    avatar={<HomeIcon />}
    defaultExpanded={true}
  >
    <SiteAddress {...props} />
  </ExpandingCard>
);

SiteInstallationAddress.propTypes = {
  purchaseArgs: PropTypes.object.isRequired,
  prefix: PropTypes.oneOf(["site_a", "site_b"]).isRequired,
};
