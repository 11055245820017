import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import * as AccountAPI from "../../api/v1/account";
import * as LeadsAPI from "../../api/v1/leads";
import * as VodafoneAPI from "../../api/v1/vodafone";
import * as OrdersAPI from "../../api/v1/orders";
import { getAccountId, getLeadId, getOrderId } from "../order/selectors";
import { addAlertMessage, setActiveStep } from "../uiState/actions";
import { getHostEnv } from "../order/selectors/getHostEnv";
import { getNumberOfNewConnections } from "../mobile/selectors/order";
import _ from "lodash";
import { getAccountSettings, shouldProcessCreditVet } from "./selectors";
import { STEP_DELIVERY } from "../uiState/constants";
import {
  setAccountId as setAccountIdOnOrderState,
  setCustomerReference as setCustomerReferenceOnOrderState,
} from "../order/actions";

export const resetAccountState = createAction("account/reset");
export const resetAddContact = createAction("account/resetAddContact");

export const fetchAvailableHardwareCredit = createAsyncThunk(
  "account/fetchAvailableHardwareCredit",
  async (arg, { getState, dispatch }) => {
    const accountId = getAccountId(getState());
    if (accountId) {
      let credit = 0;
      try {
        const response: any = await AccountAPI.AccountHardwareCredit(accountId);
        credit = response?.data?.amount ?? 0;
      } catch (error) {
        return dispatch(addAlertMessage(error));
      }
      return credit;
    }
  }
);

export const fetchAccountSettings = createAsyncThunk(
  "account/fetchAccountSettings",
  async (arg, { getState }) => {
    const state = getState();
    const hostEnv = getHostEnv(state);
    const account = getAccountId(state);
    const lead_id = getLeadId(state);
    let query: Parameters<typeof AccountAPI.Settings>[0] | any = {};
    if (hostEnv !== "platform") {
      // @info - Platform requires account id, where as DC requires lead id
      if (account) query = { account };
      else if (lead_id) query = { lead_id };
    }
    return await AccountAPI.Settings(query);
  }
);

export const fetchBillingAddress = createAsyncThunk(
  "account/fetchBillingAddress",
  async (arg, { getState }) => {
    const state = getState();
    const accountId = getAccountId(state);
    const orderId = getOrderId(state);
    return await AccountAPI.billingAddress(accountId, orderId);
  }
);

export const uptdateBillingAddress = createAsyncThunk(
  "account/uptdateBillingAddress",
  async (
    address: Parameters<typeof AccountAPI.billingAddressPost>[1],
    { getState }
  ) => {
    const state = getState();
    const accountId = getAccountId(state);
    return await AccountAPI.billingAddressPost(accountId, address);
  }
);

export const fetchAllAccountAddresses = createAsyncThunk(
  "account/fetchAllAccountAddresses",
  async (arg, { getState }) => {
    const state = getState();
    const accountId = getAccountId(state);
    const orderId = getOrderId(state);
    return await AccountAPI.allAccountAddresses(accountId, orderId);
  }
);

export const fetchAllContacts = createAsyncThunk(
  "account/fetchAllContacts",
  async (isLeadContactSelect: boolean | undefined = false, { getState }) => {
    const state = getState();
    const accountId = getAccountId(state);
    const leadId = getLeadId(state);
    const orderId = getOrderId(state);
    // @info - TP12082
    if (isLeadContactSelect) {
      return await LeadsAPI.AllLeadContacts(leadId);
    } else {
      if (!accountId) {
        if (!leadId)
          console.error("No lead or account ID when fetching contacts.");
        return await LeadsAPI.AllLeadContacts(leadId);
      } else {
        return await AccountAPI.AllAccountContacts(accountId, orderId);
      }
    }
  }
);

export const addContact = createAsyncThunk(
  "account/addContact",
  async ({ values, lead = false }: any, { getState }) => {
    let response;
    const state: any = getState();
    const contact = {
      ...values,
      order_id: getOrderId(state),
    };
    if (lead) {
      response = await LeadsAPI.AddContact(getLeadId(state), contact);
    } else {
      response = await AccountAPI.AddContact(
        state.account.newAccountId || getAccountId(state),
        contact
      );
    }
    return response;
  }
);

export const fetchContactTypes = createAsyncThunk(
  "account/fetchContactTypes",
  async (arg, { getState }) => {
    return await AccountAPI.ContactTypes();
  }
);

export const processCreditVet = createAsyncThunk(
  "account/processCreditVet",
  async (arg, { getState, dispatch }) => {
    const state = getState();
    const account_id = getAccountId(state);
    const number_of_connections = getNumberOfNewConnections(state);

    let response;
    let retries = 3;

    while (retries > 0) {
      response = await VodafoneAPI.VodaFoneProcessCreditVet({
        account_id,
        number_of_connections,
      });

      if (response.status !== "error") {
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));

      retries--;
    }

    return response;
  }
);

export const fetchCreditVetStatus = createAsyncThunk(
  "account/fetchCreditVetStatus",
  async (arg, { getState }) => {
    const state = getState();
    const accountId = getAccountId(state);
    const numberOfConnections = getNumberOfNewConnections(state);
    return await AccountAPI.ThirdPartyBillingCreditVetStatus(
      accountId,
      numberOfConnections
    );
  }
);

export const processCreditVetAndFetchStatus = createAsyncThunk(
  "account/processCreditVetAndFetchStatus",
  async (arg, { getState, dispatch }) => {
    const actionResp = await dispatch(processCreditVet());
    const response: any = actionResp.payload;
    if (response.status === "success") dispatch(fetchCreditVetStatus());
    return response;
  }
);

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (
    params: Parameters<typeof OrdersAPI.CreateAccount>[0],
    { getState }
  ) => {
    const state = getState();
    const settings = getAccountSettings(state);
    const bank_account_sortcode = params.bank_account_sortcode.replace(
      /-/g,
      ""
    );
    const response: any = await OrdersAPI.CreateAccount({
      ...params,
      order: getOrderId(state),
      lead: getLeadId(state),
      contract_type_id: settings.contract_type_id,
      bank_account_sortcode,
    });
    return response;
  }
);

export const createAccountAndUpdate = createAsyncThunk(
  "account/createAccountAndUpdate",
  async (
    params: Parameters<typeof OrdersAPI.CreateAccount>[0],
    { getState, dispatch }
  ) => {
    const actionResp = await dispatch(createAccount(params));
    const response = actionResp.payload;
    const state = getState();
    const settings = getAccountSettings(state);
    if (response.status === "success") {
      dispatch(
        setAccountIdOnOrderState(
          _.get(response, "data.id") || _.get(response, "data.account.id")
        )
      );

      dispatch(
        setCustomerReferenceOnOrderState(
          response.data.platform_customer_reference ||
            response.data.account.platform_customer_reference
        )
      );

      if (
        settings.can_access_vf_direct === "1" &&
        shouldProcessCreditVet(state)
      ) {
        dispatch(processCreditVetAndFetchStatus());
      }

      // Refresh the list of contacts in state.
      dispatch(fetchAllContacts(false));
      dispatch(setActiveStep(STEP_DELIVERY));
    }
    return response;
  }
);
