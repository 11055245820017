import React from "react";
import DiscountIcon from "@mui/icons-material/ImportExport";
import { Slider, Box, Typography } from "@mui/material";

interface AdjustPriceProps {
  discountPercentage: number;
  updatePrice: (event: Event, value: number | number[]) => void;
}

export const AdjustPrice = ({
  discountPercentage,
  updatePrice,
}: AdjustPriceProps) => (
  <Box display="flex">
    <Box display="flex" mr={1}>
      <DiscountIcon />
      <Typography noWrap>Discount %</Typography>
    </Box>
    <Slider
      value={discountPercentage}
      min={-16.5}
      max={20}
      onChange={updatePrice}
      step={0.5}
      getAriaValueText={(value) => `${value.toFixed(1)}%`}
      valueLabelDisplay="on"
    />
  </Box>
);
