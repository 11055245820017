import React from "react";
import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { NEW_PRODUCT } from "../../../../../../store/wlrBroadband/constants";
import { Chip } from "../../../../../../shared/components/Chip/Chip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

interface MPFNoProductChangesSubRowProps {
  disabled?: boolean;
  productInstance: Record<string, any>;
  configuration: Record<string, any>;
  productInstanceType: string;
  productInstanceId: string;
  removeResign: () => void;
  onClick: () => void;
}

export const MPFNoProductChangesSubRow = ({
  disabled,
  productInstance,
  configuration,
  productInstanceId,
  productInstanceType,
  removeResign,
  onClick,
}: MPFNoProductChangesSubRowProps) => {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell />
      <TableCell colSpan={2}>{productInstance.pin}</TableCell>
      <TableCell colSpan={2}>
        <CustomCell>
          {productInstanceType === "wlr" ? (
            <PhoneIcon
              style={{
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
                marginLeft: 0,
                marginRight: 10,
              }}
            />
          ) : (
            <PublicIcon
              style={{
                color: disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.secondary,
                marginLeft: 0,
                marginRight: 10,
              }}
            />
          )}
          <div
            style={{
              ...(disabled ? { color: "#b8b7b9" } : {}),
              marginRight: 10,
            }}
          >
            {productInstanceType === "wlr"
              ? "Select WLR Product"
              : "Select Broadband Product"}
          </div>
          <ArrowForwardIcon
            style={{
              color: disabled
                ? theme.palette.text.disabled
                : theme.palette.text.secondary,
            }}
          />
        </CustomCell>
      </TableCell>
      <TableCell />
      <TableCell>-</TableCell>
      <TableCell />
      <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
        {configuration.resignProductType === NEW_PRODUCT &&
        ((productInstanceType === "wlr" && configuration.wlrProductId) ||
          (productInstanceType === "broadband" &&
            configuration.broadbandProductId)) ? (
          <Chip
            title="New Product"
            color={theme.palette.success.main}
            Icon={AutorenewIcon}
            onClick={removeResign}
            outline={!productInstanceId}
          />
        ) : (
          <Tooltip title="Resign">
            <span>
              <IconButton
                disabled={disabled}
                onClick={onClick}
                data-cy="mpfNewProductBtn"
                size="large"
              >
                <AutorenewIcon
                  style={{
                    color: disabled
                      ? theme.palette.text.disabled
                      : theme.palette.text.secondary,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};
