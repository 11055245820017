import React, { memo } from "react";
import { useDispatch } from "react-redux";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";
import { setProductQuantity } from "../../../../../../store/mobile/actions";
import { QuantityField } from "../../../../../../shared/components/QuantityField";
import { MobileProduct } from "../../../../../../shared/types/general";
import { CarrierField } from "../CarrierField";
import { MAX_QTY, QTY_THRESHOLD } from "./constants";

interface ProductTableRowProps {
  product: Pick<
    MobileProduct,
    | "network"
    | "name"
    | "dataOnly"
    | "family"
    | "id"
    | "priceBook"
    | "recurringPrice"
  >;
  configsCount: number;
  showProductFamily: boolean;
  hasReachedMaxConfigCount: boolean;
}

export const ProductTableRow = memo(
  ({
    product,
    configsCount,
    showProductFamily,
    hasReachedMaxConfigCount,
  }: ProductTableRowProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleQuantity = (quantity: number | undefined) => {
      dispatch(
        setProductQuantity({
          quantity,
          productId: product.id,
        })
      );
    };

    return (
      <>
        <Box className={classes.sm}>
          <CarrierField network={product.network} />
        </Box>
        {showProductFamily && (
          <Box className={classes.sm}>{product.family}</Box>
        )}
        <Box className={classes.product}>
          {product.name}
          {!!product.dataOnly && (
            <WifiTetheringIcon titleAccess="Data only tariff" />
          )}
        </Box>
        {product.priceBook && (
          <Box className={classes.lg}>{product.priceBook}</Box>
        )}
        <Box className={classes.md}>
          <QuantityField
            color={"secondary"}
            price={product.recurringPrice}
            label={"Monthly"}
            quantity={configsCount}
            quantityThreshold={QTY_THRESHOLD}
            setQuantity={handleQuantity}
            dataCy={"quantityField"}
            hasReachedMaxConfigCount={hasReachedMaxConfigCount}
            maxQuantity={MAX_QTY}
          />
        </Box>
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  product: {
    flexGrow: 1,
    minWidth: 400,
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
  },
  lg: {
    width: 300,
    minWidth: 300,
  },
  md: {
    width: 289,
    maxWdth: 289,
  },
  sm: {
    width: 200,
    minWidth: 200,
  },
}));
