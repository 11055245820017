import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getOrdersForAccount,
  getMoreOrdersForAccount,
} from "../../../store/order/actions";
import { OrderStatus } from "../components/OrderStatus/OrderStatus";

export const OrderStatusContainer = () => {
  const dispatch = useDispatch();

  const orders = useSelector((state: any) => state.order.ordersForAccount);

  React.useEffect(() => {
    dispatch(getOrdersForAccount());
  }, [dispatch]);

  return (
    <OrderStatus
      orders={orders}
      onLoadMore={() => dispatch(getMoreOrdersForAccount())}
      onRefresh={() => dispatch(getOrdersForAccount(true))}
    />
  );
};
