import React from "react";
import LocationIcon from "@mui/icons-material/LocationOn";
import { Grid } from "@mui/material";
import { ExpandingCard } from "@akj-dev/shared-components";

import FormTextField from "../../containers/Ethernet/EthernetOrderForm/FormTextField";
import { FormSelectFieldContainer } from "../../containers/Ethernet/EthernetOrderForm/FormSelectField";

interface LocationContainerProps {
  prefix: string;
  index: number;
  isExpanded: boolean;
  handleHeaderClick: () => void;
}

export const Location = ({
  prefix,
  index,
  isExpanded,
  handleHeaderClick,
}: LocationContainerProps) => (
  <ExpandingCard
    title="Location"
    avatar={<LocationIcon />}
    onHeaderClick={handleHeaderClick}
    expanded={isExpanded}
  >
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <FormTextField
          label="Floor *"
          param={prefix + "_site_address_floor"}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormTextField
          label="Room *"
          param={prefix + "_site_address_room"}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormSelectFieldContainer
          helperText="Mounting Type *"
          param={prefix + "_mounting_type"}
          options={{
            "Rack Mounted": "Rack Mounted",
            "Wall Mounted": "Wall Mounted",
            "Shelf Mounted": "Shelf Mounted",
            Other: "Other",
          }}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormTextField
          label="Cabinet"
          param={prefix + "_cabinet"}
          index={index}
        />
      </Grid>
      <Grid item sm={4}>
        <FormTextField label="Shelf" param={prefix + "_shelf"} index={index} />
      </Grid>
      <Grid item sm={12}>
        <FormTextField
          label="Additional install requirements"
          param={prefix + "_install_requirements"}
          hintText="e.g. Exact location for router."
          muiSettings={{ rows: 2, fullWidth: true }}
          index={index}
        />
      </Grid>
    </Grid>
  </ExpandingCard>
);
