import React from "react";

import { Grid } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import { StatusChip } from "@akj-dev/shared-components";

import { Section } from "../../../../shared/components/Section";
import { NEW_LINE } from "../../../../store/wlrBroadband/constants";

import WlrBBDynamicField from "./WlrBBDynamicField";

// This is everything belonging to category: Calling and Network Features
// Except:
//
// presentation_number
// direct_connect_number
// divert_to_number
// presentation_number & cnf_telephone_number

interface CallingFeaturesProps {
  locationType: string;
  callerDisplay: boolean;
}

export const CallingFeatures = ({
  locationType,
  callerDisplay,
}: CallingFeaturesProps) => (
  <Section
    title="Calling Features"
    avatar={<StarsIcon />}
    defaultExpanded={false}
  >
    <Grid container spacing={2}>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="bypass_number" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="choose_to_refuse" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="three_way_calling" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="anonymous_call_rejection"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="raw_call_data" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="reminder_call" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="ring_back_inhibit" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="bar_use_of_call_return"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="call_sign" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="indirect_access_call_barring"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="caller_display" />
        {!callerDisplay && locationType === NEW_LINE && (
          <StatusChip
            type="info"
            title="This service is added FOC with a new line."
            message="Only remove if customer requested"
          />
        )}
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="voice_prompt" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="change_bypass_number"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="calling_or_direct" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="called_party_answer"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="call_waiting" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="ring_back" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="withhold_number" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="inbound_call_management"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="messaging_product" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="call_barring" />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField productType="wlr" propertyName="call_diversion" />
      </Grid>
    </Grid>
  </Section>
);
