import React from "react";
import { createRoutingObject } from "@akj-dev/shared-components";
import { OrderDashboard } from "./screens/Dashboard";
import { Status } from "./screens/Status";
import Notifications from "./screens/Notifications";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { AvailabilityCheck } from "./screens/AvailabilityCheck";
import { NewOrder } from "./screens/NewOrder";

export const ROOT_PATH = "/orders";

export const getRoutes = (accountSettings: AccountSettings) =>
  createRoutingObject(
    [
      {
        path: "/",
        title: "Dashboard",
        subject: <OrderDashboard />,
      },
      ...(accountSettings.as_feature_orders_cpq === "1"
        ? [
            {
              path: "/new-order",
              title: "New Order",
              subject: <NewOrder />,
            },
          ]
        : []),
      ...(accountSettings.can_use_availablity_check_in_AO === "1"
        ? [
            {
              path: "/availability-check",
              title: "Availability Check",
              subject: <AvailabilityCheck />,
            },
          ]
        : []),
      {
        path: "/status",
        title: "Orders Status",
        subject: <Status />,
      },
      ...(accountSettings.ao_can_see_notifications_section === "1"
        ? [
            {
              path: "/notifications",
              title: "Notifications",
              subject: <Notifications />,
            },
          ]
        : []),
    ],
    ROOT_PATH
  );
