import React from "react";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { formatCurrency } from "../../../shared/utils/formatCurrency";
import { OrderViewItemRows } from "./OrderViewItemRows";

interface OrderViewTableProps {
  products: any[];
  price: any;
  totalCreditUsed: any;
}

export const OrderViewTable = ({
  price,
  products,
  totalCreditUsed,
}: OrderViewTableProps) => {
  const sortedProducts = _.sortBy(products, (product) => {
    // If product doesn't have a component, return last.
    if (!product.components[0]) return 5;
    const order: Record<string, any> = {
      mobile: 1,
      mobile_bolt_on: 2,
      hardware: 3,
      extra_services: 4,
    };
    // If product type doesn't have an order number assigned, return last.
    return order[product.components[0].module as string] || 5;
  });

  if (sortedProducts.length < 1) return null;

  return (
    <Table data-cy="OrderView">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>One-off price</TableCell>
          <TableCell>Recurring price</TableCell>
          <TableCell>Configured</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <OrderViewItemRows sortedProducts={sortedProducts} />
        <TableRow>
          <TableCell>
            <b>Total:</b>
          </TableCell>
          <TableCell data-cy="summary-price-one-off">
            <b>
              {parseFloat(totalCreditUsed) > 0
                ? formatCurrency(
                    parseFloat(price.one_off_price_with_promotions) -
                      parseFloat(totalCreditUsed)
                  )
                : formatCurrency(price.one_off_price_with_promotions)}
            </b>
          </TableCell>
          <TableCell data-cy="summary-price-recurring">
            <b>
              {formatCurrency(price.first_bill_recurring_price_with_promotions)}
            </b>
          </TableCell>
          <TableCell> </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
