import React from "react";
import Icon from "@mui/icons-material/Book";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { Grid } from "@mui/material";

export const DirectoryEnquiry = () => (
  <Section title="Directory Enquiry" avatar={<Icon />} defaultExpanded={false}>
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="directory_description"
        />
      </Grid>
      <Grid item sm={12}>
        <WlrBBDynamicField
          productType="wlr"
          propertyName="end_user_name_for_999"
        />
      </Grid>
      <Grid item sm={12}>
        <WlrBBDynamicField productType="wlr" propertyName="company" />
      </Grid>
      <Grid item sm={12}>
        <WlrBBDynamicField productType="wlr" propertyName="entry_type" />
      </Grid>
      <Grid item sm={12}>
        <WlrBBDynamicField productType="wlr" propertyName="directory_style" />
      </Grid>
      <Grid item sm={12}>
        <WlrBBDynamicField productType="wlr" propertyName="directory_suffix" />
      </Grid>
    </Grid>
  </Section>
);
