import React from "react";

import { Grid } from "@mui/material";

import { IndexedCardInfo, StatusChip } from "@akj-dev/shared-components";

import { SelectTier } from "../components/SelectTier";

interface ProductsProps {
  isProductError: boolean;
  productCount: number;
  products: any[];
  onRetryClick: () => void;
  onAddProduct: (item: any) => void;
  onRemoveProduct: (item: any) => void;
  getProductConfigCount: (itemId: string) => number;
}

export const Products = ({
  isProductError,
  productCount,
  products,
  onRetryClick,
  onAddProduct,
  onRemoveProduct,
  getProductConfigCount,
}: ProductsProps) => (
  <IndexedCardInfo index="1" title="Select devices to be monitored">
    {isProductError ? (
      <StatusChip
        type="error"
        title="Error"
        message="Something went wrong finding logic monitor products."
        retry={onRetryClick}
      />
    ) : (
      productCount && (
        <div>
          <Grid container spacing={2}>
            {products.map((item: any, index: number) => (
              <Grid key={item.id} item sm={4}>
                <SelectTier
                  name={item.name}
                  price={
                    item.price.first_bill_recurring_price_with_promotions ||
                    "0.00"
                  }
                  description={item.description || "Sample description."}
                  quantity={getProductConfigCount(item.id)}
                  index={index}
                  addProduct={() => onAddProduct(item)}
                  removeProduct={() => onRemoveProduct(item.id)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )
    )}
  </IndexedCardInfo>
);
