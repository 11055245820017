import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedBoltOnCount } from "../../../../store/mobile/selectors/boltOns";
import { setAccountBoltOnStartDate } from "../../../../store/mobile/actions";
import { BoltOns } from "../../components/Mobile/BoltOns";

export const BoltOnsContainer = () => {
  const dispatch = useDispatch();

  const boltOnCount = useSelector(
    (state) =>
      getSelectedBoltOnCount(state, 1) + getSelectedBoltOnCount(state, 2)
  );
  const resignStartDate = useSelector<any, string>(
    (state) => state.mobile.resignStartDate
  );
  const boltOnStartDate = useSelector<any, string>(
    (state) => state.mobile.boltOnStartDate
  );

  return (
    <BoltOns
      boltOnCount={boltOnCount}
      boltOnStartDate={boltOnStartDate}
      resignStartDate={resignStartDate}
      onStartDateChange={(date) =>
        dispatch(setAccountBoltOnStartDate({ date }))
      }
    />
  );
};
