import { getJSON, postJSON } from "../helpers/core";

/**
 * Gets list of available routers for specified service type
 * @param bearer
 * @param speed
 * @param technology_type - EFM, Ethernet, GEA
 * @param voice_requirement
 * @returns {Promise<*|void>}
 * @constructor
 */

export const routers = ({
  bearer,
  speed,
  technology_type,
  voice_requirement = 1,
}: any) =>
  getJSON(
    "LeasedLineQuotes/Routers",
    {
      account_type: "retail",
      bearer,
      speed,
      technology_type,
      voice_requirement,
      // summary_order_by: 'recurring',
      // min_bandwidth: TODO.... needed? @mike to confirm
    },
    false,
    true
  );

export const pricingRequest = (params: any) =>
  postJSON("LeasedLineQuotes/PricingRequest", params, false, true);

export const results = (pricingRequestId: string) =>
  getJSON(`LeasedLineQuotes/Results/${pricingRequestId}`, {}, false);

export const purchase = (params: any, selectedQuoteId: string) =>
  postJSON(`LeasedLineQuotes/Purchase/${selectedQuoteId}`, params, false, true);
