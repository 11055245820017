import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { fetchAllContacts } from "../../store/account/actions";
import { isValidEmail } from "../utils/validation";
import {
  MenuItem,
  FormHelperText,
  ListItemText,
  FormControl,
  InputLabel,
  Button,
  Select,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CreateContactContainer } from "./CreateContact";
import { StatusChip } from "@akj-dev/shared-components";
import { getContacts, getHasContacts } from "../../store/account/selectors";
import { getHostEnv } from "../../store/order/selectors/getHostEnv";

interface SelectContactProps {
  current: string;
  handleUpdate: (id: string) => void;
  errorText?: string;
}

export function SelectContact({
  current,
  handleUpdate,
  errorText,
}: SelectContactProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hostEnv = useSelector(getHostEnv);

  const contacts = useSelector(getContacts);
  const hasContacts = useSelector(getHasContacts);

  useEffect(() => {
    if (!hasContacts) dispatch(fetchAllContacts());
  }, [dispatch, hasContacts]);

  if (contacts.fetching) return <CircularProgress />;

  if (contacts.response?.status === "error")
    return <StatusChip type="error" title="Error fetching account contacts" />;

  if (_.get(contacts.response, "data.contacts"))
    return (
      <div>
        <FormControl
          fullWidth
          error={!!errorText}
          className={classes.select}
          data-cy="SelectAccountContact"
        >
          <InputLabel variant="standard">Choose account contact</InputLabel>
          <Select
            variant="standard"
            onChange={(event) => handleUpdate(event.target.value as string)}
            value={current}
          >
            {contacts.response?.data?.contacts
              // Some contacts might not have email addresses. Don't show them
              // Also, records with this "virtual_contact_source" can't be used with the contract upload endpoint on step 4.
              // They don't have the standard UUID style ID, instead having the actual account ID... as their ID.
              // Advised to filter out by Jim Butler.
              ?.filter?.(
                (contact: any) =>
                  contact &&
                  contact.email1 &&
                  contact.virtual_contact_source !== "account"
              )
              .map((contact: any) => (
                <MenuItem
                  key={contact.id}
                  value={contact.id}
                  disabled={!isValidEmail(contact.email1)}
                >
                  <ListItemText
                    primary={`${contact.full_name} - ${contact.email1}`}
                    secondary={!isValidEmail(contact.email1) && "Invalid"}
                  />
                </MenuItem>
              ))}
          </Select>
          {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
        <CreateContactContainer onSuccess={handleUpdate} />
        {hostEnv !== "my_account" && (
          <Button
            color="primary"
            className={classes.refresh}
            onClick={() => {
              dispatch(fetchAllContacts());
            }}
          >
            Refresh Contact List
          </Button>
        )}
      </div>
    );

  return null;
}

const useStyles = makeStyles((theme) => ({
  select: {
    marginBottom: theme.spacing(1),
  },
  refresh: {
    marginLeft: theme.spacing(1),
  },
}));
