import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";
import { EthernetConfigurationContainer } from "./EthernetConfiguration";
import { requestAllEthernetPrices } from "../../../../store/ethernetProducts/actions";

export const Ethernet = () => {
  const dispatch = useDispatch();

  const configurations = useSelector(
    (state: any) => state.ethernetProducts.configurations
  );

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 2 Ethernet mounted`,
      category: "action",
    });
    dispatch(requestAllEthernetPrices());
  }, [dispatch]);

  return (
    <div data-cy="Step2Ethernet">
      {configurations.map((configuration: any, i: number) => {
        return (
          <EthernetConfigurationContainer
            configuration={configuration}
            configIndex={i}
            // No stable id
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        );
      })}
    </div>
  );
};
