import React, { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { StatusChip, IndexedCardInfo } from "@akj-dev/shared-components";

import { PricingRequestTaskViewContainer } from "../containers/CommercialPricingRequest/PricingRequestTaskView";
import { AddPricingRequestContainer } from "../containers/CommercialPricingRequest/AddPricingRequest";

interface CommercialPricingRequestSectionProps {
  pricingRequest: Record<string, any>;
  loadPricingRequest: () => void;
}

export const CommercialPricingRequestSection = ({
  pricingRequest,
  loadPricingRequest,
}: CommercialPricingRequestSectionProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (pricingRequest.response.task) {
      setOpen(true);
    }
  }, [pricingRequest]);

  if (pricingRequest.fetching && !open)
    return (
      <Box my={1}>
        <CircularProgress />
      </Box>
    );

  if (pricingRequest.response.status === "error")
    return (
      <Box my={1} textAlign="left">
        <StatusChip
          type="error"
          title="Pricing request error"
          message={pricingRequest.response.message}
          retry={loadPricingRequest}
        />
      </Box>
    );

  if (!open)
    return (
      <Box my={1}>
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          Add Pricing Request for Commercial
        </Button>
      </Box>
    );

  return (
    <Box my={1}>
      <IndexedCardInfo index="A" title="Pricing Request">
        {pricingRequest.response.task ? (
          <PricingRequestTaskViewContainer />
        ) : (
          <AddPricingRequestContainer handleClose={handleClose} />
        )}
      </IndexedCardInfo>
    </Box>
  );
};
