import React, { useMemo } from "react";

import { Grid, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { IndexedTitle } from "@akj-dev/shared-components";

import { ReduxWlrLocation } from "../../types";
import { WlrLocationTypeEnum } from "../../enums";
import { ProductCard } from "../../../../../shared/components/ProductCard";

export interface WlrLocationInstallTypeProps {
  location: ReduxWlrLocation;
  contractLength: number;
  withSingleLineProducts: boolean;
  setLocationType: (type: string) => void;
}

export const WlrLocationInstallType = ({
  location,
  contractLength,
  withSingleLineProducts,
  setLocationType,
}: WlrLocationInstallTypeProps) => {
  const classes = useStyles();

  const installTypes = useMemo(() => {
    const disabled = contractLength === 3;

    const newLine = { type: WlrLocationTypeEnum.NEW_LINE };
    const transfer = { type: WlrLocationTypeEnum.TRANSFER, disabled };
    const broadband = {
      type: WlrLocationTypeEnum.BROADBAND_ONLY,
      disabled,
      title: "Existing Line",
      subtitle: "(Broadband Only)",
    };
    const fttp = { type: WlrLocationTypeEnum.NEW_FTTP, disabled };
    const sogea = { type: WlrLocationTypeEnum.NEW_SOGEA };

    let items: {
      type: WlrLocationTypeEnum;
      disabled?: boolean;
      title?: string;
      subtitle?: string;
    }[] = [];

    if (withSingleLineProducts) {
      items.push(newLine, transfer);
    }
    items.push(broadband, fttp, sogea);

    return items;
  }, [contractLength, withSingleLineProducts]);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        <IndexedTitle
          index="A"
          title="Please select required install type"
          negative={true}
        />
      </Typography>
      <Grid container spacing={2}>
        {installTypes.map(
          ({ type, disabled, title = type, subtitle = " " }) => (
            <Grid key={type} item md={2}>
              <ProductCard
                dataCy={type}
                disabled={disabled}
                checked={type === location.type}
                handleClick={() => setLocationType(type)}
                errorMessage={
                  disabled ? "Unavailable on selected contract" : ""
                }
              >
                <div>
                  <Typography>{title}</Typography>
                  <Typography className={classes.subtitle}>
                    {subtitle}
                  </Typography>
                </div>
              </ProductCard>
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles({
  subtitle: {
    fontSize: "0.75rem",
  },
});
