import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";

import { RemoteValidationErrorsContainer } from "../../containers/wlrBroadband/ConfigurationForm/RemoteValidationErrors";
import { AppointmentContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/Appointment";
import { CallingFeaturesContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/CallingFeatures";
import { RouterContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/Router";
import { WlrChangePriceAdjustmentContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/WlrChangePriceAdjustment";
import { NumberReservationContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/NumberReservation";
import { SelectedConfigsContainer } from "../../containers/wlrBroadband/ConfigurationForm/SelectedConfigs";
import { WlrBBPricingWrapper } from "../../containers/wlrBroadband/ConfigurationForm/sections/WlrBBPricingWrapper";
import { SiteVisitReasonContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/SiteVisitReason";
import { SpecialRatesContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/SpecialRates";

import { EndUserAddress } from "./EndUserAddress";
import { MultiLineOptions } from "./MultiLineOptions";
import { CareLevels } from "./CareLevels";
import { CallTariff } from "./CallTariff";
import { CallBundles } from "./CallBundles";
import { CallBundlesWlrChange } from "./CallBundlesWlrChange";
import { DirectoryEnquiry } from "./DirectoryEnquiry";
import { MPFDirectoryEnquiry } from "./MPFDirectoryEnquiry";
import { BroadbandConfiguration } from "./BroadbandConfiguration";
import { RadiusConfiguration } from "./RadiusConfiguration";
import { LineConfiguration } from "./LineConfiguration";
import { MPFVoiceOptions } from "./MPFVoiceOptions";
import { RequiredByDate } from "./RequiredByDate";
import { SelectServices } from "./SelectServices";
import { DirectoryInfo } from "./DirectoryInfo";
import { UniqueReferenceNumberField } from "./UniqueReferenceNumberField";
import { useDwsResellerEnabled } from "../../../../shared/hooks/useDwsResellerEnabled";
import { VoiceService } from "./VoiceService";
import { ONTContainer } from "./ONTContainer";

interface ConfigurationFormProps {
  isNewLine: boolean;
  isTransfer: boolean;
  isMultiLine: boolean;
  isWLTO: boolean;
  requiresWLRAppointment: boolean;
  requiresBBAppointment: boolean;
  isMPF: boolean;
  isFTTP: boolean;
  isSOGEA: boolean;
  isBTWFTTC: boolean;
  hasBroadband: boolean;
  hasLine: boolean;
  isWlrChangePossible: boolean;
  productInstance: any;
  isISDN: boolean;
  isNewProductLineResign: boolean;
  isSameProductWCLineResign: boolean;
  isNewProductBroadbandResign: boolean;
  isSameProductWCBroadbandResign: boolean;
  isUpgrade: boolean;
  uniqueReferenceType: string | null;
  targetConfigs: number[];
  closeAction: () => void;
  hasCli: boolean;
}

export const ConfigurationForm = ({
  targetConfigs,
  closeAction,
  isBTWFTTC,
  isFTTP,
  isISDN,
  isMPF,
  isMultiLine,
  isNewLine,
  isNewProductBroadbandResign,
  isNewProductLineResign,
  isSOGEA,
  isSameProductWCBroadbandResign,
  isSameProductWCLineResign,
  isTransfer,
  isWLTO,
  isWlrChangePossible,
  isUpgrade,
  requiresBBAppointment,
  requiresWLRAppointment,
  hasBroadband,
  hasLine,
  uniqueReferenceType,
  productInstance,
  hasCli,
}: ConfigurationFormProps) => {
  const isDwsResellerEnabled = useDwsResellerEnabled();

  return (
    <div data-cy="wlrBroadbandConfigurationForm">
      <Paper style={{ marginBottom: 20 }}>
        <Typography variant="h3">
          <IconButton
            data-cy="wlrBroadbandConfigurationFormBackButton"
            onClick={closeAction}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
          <span>
            {targetConfigs.length > 1
              ? `Editing ${targetConfigs.length} Product Configurations`
              : `Editing Product Configuration`}
          </span>
        </Typography>
        <SelectedConfigsContainer />
      </Paper>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <EndUserAddress
            hasBroadband={
              hasBroadband ||
              isNewProductBroadbandResign ||
              isSameProductWCBroadbandResign ||
              isFTTP ||
              isSOGEA
            }
            isNewLine={isNewLine || isNewProductLineResign}
          />
        </Grid>
        <Grid item md={6}>
          {isWlrChangePossible && (
            <div>
              <SelectServices />
              <DirectoryInfo />
              <CallBundlesWlrChange />
            </div>
          )}
          {isMultiLine && <MultiLineOptions />}
          {((hasBroadband && (isSOGEA || isBTWFTTC || isFTTP)) ||
            isUpgrade) && <SiteVisitReasonContainer />}
          {(hasBroadband ||
            isNewProductBroadbandResign ||
            isSameProductWCBroadbandResign) && (
            <RouterContainer isSOGEA={isSOGEA} isBTWFTTC={isBTWFTTC} />
          )}
          {(hasBroadband ||
            isNewProductBroadbandResign ||
            isSameProductWCBroadbandResign) &&
            isUpgrade &&
            isFTTP && <ONTContainer targetConfigs={targetConfigs} />}
          {(requiresWLRAppointment || requiresBBAppointment) &&
            !isSameProductWCBroadbandResign && <AppointmentContainer />}
          {!requiresBBAppointment &&
            (hasBroadband || isNewProductBroadbandResign) && (
              <RequiredByDate isFTTP={isFTTP} />
            )}
          {!isISDN && <CareLevels isWlrChangePossible={isWlrChangePossible} />}
          {(isNewLine ||
            isNewProductLineResign ||
            isSameProductWCLineResign ||
            isTransfer ||
            (hasLine && isWlrChangePossible)) &&
            !isSOGEA && (
              <CallTariff isWlrChangePossible={isWlrChangePossible} />
            )}
          {!isDwsResellerEnabled && <WlrBBPricingWrapper />}
          {(isNewLine || isNewProductLineResign) &&
            !isMPF &&
            !isSOGEA &&
            !isISDN && <NumberReservationContainer />}
          {isWlrChangePossible && <WlrChangePriceAdjustmentContainer />}
          {uniqueReferenceType && (
            <UniqueReferenceNumberField
              uniqueReferenceType={uniqueReferenceType}
            />
          )}
          {(isNewLine ||
            isNewProductLineResign ||
            isSameProductWCLineResign ||
            isTransfer) &&
            !isSOGEA && <CallBundles />}
          {(isNewLine || isNewProductLineResign) &&
            !isMPF &&
            !isSOGEA &&
            !isISDN && (
              <CallingFeaturesContainer targetConfig={targetConfigs[0]} />
            )}
          {(isNewLine || isNewProductLineResign) &&
            !isMPF &&
            !isSOGEA &&
            !isISDN && <DirectoryEnquiry />}
          {isMPF && <MPFDirectoryEnquiry />}
          {(hasBroadband || isNewProductBroadbandResign) && (
            <BroadbandConfiguration hasLine={hasLine} isSOGEA={isSOGEA} />
          )}
          {(hasBroadband || isNewProductBroadbandResign) && (
            <RadiusConfiguration />
          )}
          {(((isNewLine || isNewProductLineResign) &&
            !isMPF &&
            !isSOGEA &&
            !isISDN) ||
            isWLTO) && <LineConfiguration />}
          {isMPF && <MPFVoiceOptions />}
          {((hasBroadband && !isSOGEA) ||
            (hasCli && isSOGEA) ||
            (isNewProductBroadbandResign && isUpgrade)) && (
            <VoiceService targetConfigs={targetConfigs} />
          )}
        </Grid>
      </Grid>
      {targetConfigs.length < 2 && (
        <RemoteValidationErrorsContainer targetConfig={targetConfigs[0]} />
      )}
      {productInstance && (
        <SpecialRatesContainer productInstance={productInstance} />
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={closeAction}
        endIcon={<ArrowBackIcon />}
        data-cy="wlrConfigBackButton"
      >
        Back
      </Button>
    </div>
  );
};
