import React from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Chip } from "../../../../shared/components/Chip/Chip";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  SAME_PRODUCT,
} from "../../../../store/wlrBroadband/constants";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";

interface ResignTypeChipProps {
  resignProductType: string;
}

export const ResignTypeChip = ({ resignProductType }: ResignTypeChipProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();

  switch (resignProductType) {
    case NEW_PRODUCT:
      return (
        <Chip
          title="New Product"
          color={theme.palette.success.main}
          Icon={SettingsBackupRestoreIcon}
          outline={true}
          className={classes.chip}
        />
      );
    case SAME_PRODUCT_WITH_CHANGES:
      return (
        <Chip
          title={
            hostEnv === "platform" && !canAccessVfDirect
              ? "Resign"
              : "Same Product With Changes"
          }
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          outline={true}
          className={classes.chip}
        />
      );
    case SAME_PRODUCT:
      return (
        <Chip
          title="Same Product"
          color={theme.palette.info.main}
          Icon={AutorenewIcon}
          outline={true}
          className={classes.chip}
        />
      );
    default:
      return null;
  }
};

const useStyles = makeStyles({
  chip: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
