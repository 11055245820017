import React, { useCallback, memo } from "react";

import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { MAX_QTY, QTY_THRESHOLD } from "../constants";
import { QuantityField } from "../../../../shared/components/QuantityField";
import { HardwareConfiguration, HardwareProduct } from "../types";

export interface ChooseHardwareTableRowProps {
  product: Pick<HardwareProduct, "id" | "name" | "price">;
  configuration?: Pick<
    HardwareConfiguration,
    "stockLevel" | "quantity" | "daisyFreshQuantity"
  >;
  handleSetQuantity: (value: number | undefined) => void;
  handleDaisyFreshSetQuantity: (value: number | undefined) => void;
  getStockLevel: (id: number) => void;
  hasReachedMaxConfigCount: boolean;
  isDaisyFreshAvailable: boolean;
  quantityTreshold: number;
  daisyFreshQuantity: number | undefined;
  oneOffPrice: string;
}

export const ChooseHardwareTableRow = memo(
  ({
    product,
    configuration,
    isDaisyFreshAvailable,
    oneOffPrice,
    handleSetQuantity,
    handleDaisyFreshSetQuantity,
    getStockLevel,
    hasReachedMaxConfigCount,
    daisyFreshQuantity,
    quantityTreshold,
  }: ChooseHardwareTableRowProps) => {
    const classes = useStyles();

    const stockLevel = configuration?.stockLevel;
    const quantity = configuration?.quantity || 0;
    const daisyFreshPrice = product?.price?.daisyFresh;

    const handleStockLevel = useCallback(
      () => getStockLevel(product.id),
      [product.id, getStockLevel]
    );

    return (
      <>
        <Box className={classes.name}>{product.name}</Box>
        <Box className={classes.daisyFresh}>
          {isDaisyFreshAvailable ? (
            <QuantityField
              color={"primary"}
              label={"Daisy Fresh"}
              price={daisyFreshPrice.toString()}
              quantity={daisyFreshQuantity}
              quantityThreshold={quantityTreshold}
              hasReachedMaxConfigCount={hasReachedMaxConfigCount}
              maxQuantity={MAX_QTY}
              setQuantity={handleDaisyFreshSetQuantity}
              stockLevel={stockLevel}
              getStockLevel={handleStockLevel}
              allowInput={false}
              dataCy={"daisyFreshQuantityField"}
            />
          ) : (
            <Box fontStyle="italic" data-cy="notAvailableBox">
              {" "}
              Not Available
            </Box>
          )}
        </Box>
        <Box className={classes.upFront}>
          <QuantityField
            color={"primary"}
            label={"Up Front"}
            price={oneOffPrice}
            quantity={quantity}
            quantityThreshold={QTY_THRESHOLD}
            hasReachedMaxConfigCount={hasReachedMaxConfigCount}
            maxQuantity={MAX_QTY}
            setQuantity={handleSetQuantity}
            stockLevel={stockLevel}
            getStockLevel={handleStockLevel}
            dataCy={"quantityField"}
          />
        </Box>
      </>
    );
  }
);

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
  },
  daisyFresh: {
    width: 300,
    minWidth: 300,
  },
  upFront: {
    width: 280,
    minWidth: 280,
  },
}));
