import React from "react";

import { Grid } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

import { WlrLocationNewLineTable } from "../WlrLocation/NewLine/Table";
import { NEW_SOGEA } from "../../../../../store/wlrBroadband/constants";
import { WlrLocationProduct, WlrLocationProductConfig } from "../../types";
import { useDwsResellerEnabled } from "../../../../../shared/hooks/useDwsResellerEnabled";

interface SparePairsProps {
  configurations: WlrLocationProductConfig[];
  remainingSparePairs: number;
  locationIndex: number;
  lineProducts: WlrLocationProduct[];
  dcProducts: WlrLocationProduct[];
}

export const SparePairs = ({
  configurations,
  remainingSparePairs,
  locationIndex,
  lineProducts,
  dcProducts,
}: SparePairsProps) => {
  const isDwsResellerEnabled = useDwsResellerEnabled();

  return (
    <div>
      <p>{remainingSparePairs} spare pairs remaining at location.</p>
      <Grid container spacing={2}>
        {isDwsResellerEnabled ? (
          // DWS Resellers want to see all the line products returned from the search, unlike DC users
          <WlrLocationNewLineTable
            products={lineProducts}
            locationIndex={locationIndex}
            configurations={configurations}
          />
        ) : (
          // DC users should only see a single line and multi-line product. More are returned for some reason.
          <WlrLocationNewLineTable
            products={dcProducts}
            locationIndex={locationIndex}
            configurations={configurations}
          />
        )}
      </Grid>
      {configurations.find((c) => c.wlrProductId === NEW_SOGEA) && (
        <StatusChip
          type="info"
          message="This is a broadband only line which does NOT include a WLR voice service. If the customer requires a voice service, please quote for a VoIP service separately."
        />
      )}
    </div>
  );
};
