import React, { useState, ReactNode } from "react";
import styled from "styled-components";

import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LayersIcon from "@mui/icons-material/Layers";
import { TransformedService } from "../../../../../store/mobile/transformers";

/**
 * This component shows the evo services a (resignable) product instance is composed of.
 * The main `Description` field for the instance does not always match what the tariffs actually are
 * and tariffs can be made up of both voice and data components, hence this.
 *
 * Description fields have sometimes been created initially, then the service mix has been altered,
 * leading to inaccuracies... I think. @ianc advised.
 *
 */

const StyledDrillDown = styled.div`
  .titleRow {
    display: flex;
    align-items: center;
  }
  .children {
    flex: 1;
  }
  .expander {
    text-align: right;
    cursor: pointer;
  }
  .services {
    padding: 10px 0;
  }
  .service {
    color: ${(p) => p.theme.palette.text.disabled};
    margin-left: 5px;
  }
`;

interface EvoServicesDrillDownProps {
  children: ReactNode;
  transformedServices?: TransformedService[];
}

export const EvoServicesDrillDown = ({
  children,
  transformedServices,
}: EvoServicesDrillDownProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded((old) => !old);

  if (!transformedServices) {
    return null;
  }

  const filteredServices = transformedServices.filter(
    (s) => s.serviceType !== "bolt_on"
  );

  // return <pre>{JSON.stringify(filteredServices, null, 2)}</pre>;
  return (
    <StyledDrillDown>
      <div className="titleRow">
        <div className="children">{children}</div>
        {/*<div className="expander" onClick={this.toggleExpand}>*/}
        <IconButton
          onClick={toggleExpand}
          disabled={filteredServices?.length < 1}
          size="large"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        {/*</div>*/}
      </div>

      {expanded && (
        <div className="services">
          {filteredServices.map((s) => (
            <div className="service" key={s.id}>
              <LayersIcon /> {s.description}
            </div>
          ))}
        </div>
      )}
    </StyledDrillDown>
  );
};
