import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DialogContent as Content } from "./DialogContent";

export interface BulkOrderUploadProps {
  open: boolean;
  orderId: string;
  handleClose: () => void;
}

export const BulkOrderUpload = ({
  open,
  orderId,
  handleClose,
}: BulkOrderUploadProps) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
    <DialogTitle>Create Bulk Order Request</DialogTitle>
    <DialogContent>
      <Content orderId={orderId} />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
