import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ColorType } from "../types";
import { formatCurrency } from "../../../utils/formatCurrency";

export interface QuantityFieldPriceInfoProps {
  color?: ColorType;
  price?: string;
  label?: string;
}

export const QuantityFieldPriceInfo = ({
  color,
  price,
  label,
}: QuantityFieldPriceInfoProps) => {
  const classes = useStyles({ color });
  const priceNumber = parseFloat(price ?? "0");

  const showPrice = price !== undefined;
  const showLabel = label !== undefined;

  return (
    <Box className={classes.info}>
      {showPrice && (
        <Typography variant="body1">{formatCurrency(priceNumber)}</Typography>
      )}
      {showLabel && (
        <Typography className={classes.chip} variant="body1">
          {label}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles<Theme, { color?: ColorType }>((theme) => ({
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(0.5),
    width: 100,
  },
  chip: ({ color = "primary" }) => ({
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette[color].main,
  }),
}));
