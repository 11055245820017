import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { clearState } from "../../../../store/_root/actions";
import { resetOrderState } from "../../../../store/order/actions";
import { ActionButton } from "../../components/ActionButton";

/**
 * View Sales Status Button
 * Platform only
 * This needs to be a separate component as useHistory hook fails without React Router
 *
 * @constructor
 */
export const ViewSalesStatusButton: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleViewSalesStatus = () => {
    // clear root reducer
    dispatch(clearState());
    // clearing root reducer is not enough because config used by initial state will cache the order_id
    dispatch(resetOrderState());

    history.push("/sales/sales-status");
  };

  return (
    <ActionButton variant="contained" onClick={handleViewSalesStatus}>
      View Sales Status
    </ActionButton>
  );
};
