import { createSelector } from "reselect";
import _ from "lodash";
import {
  EXISTING_LINE,
  EXISTING_SOGEA,
  NEW_FTTP,
  NEW_LINE,
  NEW_PRODUCT,
  NEW_SOGEA,
  RESIGN,
  SAME_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  TRANSFER,
} from "./constants";
import {
  mapWLR3AddressToDCOrderProduct,
  hasProductInstanceAddress,
} from "../../shared/utils/addresses";
import { forwardProps } from "../../shared/utils/forwardProps";

/**
 * Get Single & Multi line products from API response.
 * These are shown on step 1 for the user to pick when it's a new install
 *
 * Standard reselect selector: https://github.com/reactjs/reselect#creating-a-memoized-selector
 *
 */
const lineSearch = (state) => state.wlrBroadband.lineSearch?.response;

export const getSingleLineProducts = createSelector(
  [lineSearch],
  (lineSearch) => {
    const products = _.get(lineSearch, "products", []);
    return products.filter(
      (p) =>
        _.get(p, "first_line_component.service_type") === "PSTN_SINGLE_LINE"
    );
  }
);

export const getMultiLineProducts = createSelector(
  [lineSearch],
  (lineSearch) => {
    const products = _.get(lineSearch, "products", []);
    return products.filter(
      (p) =>
        _.get(p, "first_line_component.service_type") === "PSTN_MULTI_LINE_AUX"
    );
  }
);

const getLineProductServiceType = (state, wlrProductId) => {
  const products = lineSearch(state)?.products;
  if (products) {
    return products.find((p) => p.id === wlrProductId)?.first_line_component
      .service_type;
  }
};

export const getIsSingleLineProduct = (state, wlrProductId) => {
  return getLineProductServiceType(state, wlrProductId) === "PSTN_SINGLE_LINE";
};

export const getIsMultiLineProduct = (state, wlrProductId) => {
  return (
    getLineProductServiceType(state, wlrProductId) === "PSTN_MULTI_LINE_AUX"
  );
};

/**
 * Get MPF broadband products.
 * This is special as it doesn't need a line like the others.
 *
 * This was asserted from supplier_product_ref but we changed as part of TP20481
 * as it doesn't make sense from the back end to transmit supplier_product_ref
 * in the context of price books because that would mean we have recursive suppliers.
 *
 * This should still work for DC users as supplier_product_ref=TTB_MPF is the same as
 * type=MPF hopefully.
 * https://akj-hq.slack.com/archives/GTFHJENFQ/p1613744457493800?thread_ts=1613743970.489900&cid=GTFHJENFQ
 *
 * It also checked mpf_response_details.mpf_available first, but a product shouldn't be
 * returned from BroadbandSearch if it's not available right....
 *
 * @type {OutputParametricSelector<unknown, unknown, *|boolean, (res: *) => (*|boolean)>}
 */
export const getMPFProducts = createSelector(
  [(state, props) => state.wlrBroadband.locations[props.locationIndex]],
  (currentLocation) => {
    const products = _.get(
      currentLocation,
      "broadbandSearch.response.products",
      []
    );
    return products.filter((p) => p.first_broadband_component?.type === "MPF");
  }
);

export const getSOGEAProducts = createSelector(
  [(state, props) => state.wlrBroadband.locations[props.locationIndex]],
  (currentLocation) => {
    const products = _.get(
      currentLocation,
      "broadbandSearch.response.products",
      []
    );
    return products.filter(
      (product) => _.get(product, "first_broadband_component.type") === "SOGEA"
    );
  }
);

export const getIsSOGEAOrFTTPUpgrade = (c) => {
  const type = _.get(
    c.broadbandProductData,
    "response.broadband.product_component_data.type"
  );
  const isUpgrade =
    c.resignProductType === "NEW_PRODUCT" &&
    c.wlrProductId === "Resign" &&
    /SOGEA|FTTP/.test(type);
  return [type, isUpgrade];
};

/**
 * Get product for transfers.
 * It should match the service_type returned by `v1/WLR3/InstallationDetails`
 * ...or one of the appropriate ones anyway. The response is more fine-grain it seems,
 * judging from the old `getLineTypeFromServiceType` fn
 *
 * TODO: Find out if all those types are actually necessary....
 *
 * Also when line_type isn "PREMIUM" it needs to be the premium WLR product instead. FB154792
 *
 * Selector is dependent on component props + there are multiple instances, so:
 * https://github.com/reactjs/reselect#sharing-selectors-with-props-across-multiple-components
 */
export const makeGetTransferProduct = () =>
  createSelector(
    [
      lineSearch,
      (state, props) => state.wlrBroadband.locations[props.locationIndex],
    ],
    (lineSearch, currentLocation) => {
      const serviceType = _.get(
        currentLocation.installationDetails.response,
        "service_type"
      );
      if (!serviceType) return false;

      const products = _.get(lineSearch, "products", []);

      if (
        currentLocation?.installationDetails?.response?.line_type === "PREMIUM"
      )
        return products.find(
          // TODO: this selector can be improved when TP26043 is done and piped through both gql and dc APIs.
          (p) => _.get(p, "name") === "WLR Single line (Premium)" // Note: service_type is still PSTN_SINGLE_LINE
        );
      let type;

      switch (serviceType) {
        case "PSTN_SINGLE_LINE":
        case "PSTN_SINGLE_LINE_RES":
        case "PSTN_SINGLE_LINE_BUS":
        case "DC_PRODUCT_SINGLE_LINE":
          type = "PSTN_SINGLE_LINE"; // this was type = 'SINGLE_LINE'
          break;
        case "PSTN_MULTI_LINE_AUX":
        case "DC_PRODUCT_MULTI_LINE":
          type = "PSTN_MULTI_LINE_AUX"; // this was type = 'MULTI_LINE'
          break;
        case "ISDN2E_SYSTEM":
        case "ISDN2E_STANDARD":
        case "DC_PRODUCT_ISDN2":
          type = "ISDN2E_SYSTEM"; // this was type = 'ISDN2_LINE'
          break;
        case "ISDN30E":
        case "DC_PRODUCT_ISDN30":
        case "WLR3_ISDN30_ETSI":
        case "ISDN30_DASS":
          type = "ISDN30E"; // this was type = 'ISDN30_LINE'
          break;
        default:
          type = false;
          console.error("Unknown WLR service type.");
      }
      return products.find(
        (p) => _.get(p, "first_line_component.service_type") === type
      );
    }
  );

/**
 * Get total number of spare pairs required for configs at location
 * Used to prevent unfulfillable orders.
 */
export const makeGetRequiredSparePairs = () =>
  createSelector(
    [
      (state) => state.wlrBroadband.configurations,
      (state, props) => props.locationIndex,
      lineSearch,
    ],
    (configurations, locationIndex, lineSearch) => {
      let total = 0;
      configurations.forEach((c) => {
        if (c.locationIndex === locationIndex) {
          // Note getIsSingleLineProduct / getIsMultiLineProduct can't be used here as full state is not available
          const products = lineSearch?.products;
          if (products) {
            const serviceType = products.find((p) => p.id === c.wlrProductId)
              ?.first_line_component.service_type;
            if (serviceType === "PSTN_SINGLE_LINE") total += 1;
            if (serviceType === "PSTN_MULTI_LINE_AUX") total += 5;
          }
        }
      });
      return total;
    }
  );

// Selectors primarily for step 2 - Configuration -------------------------------

/**
 * Get the configurations at a specific location
 * Used for things like getting the appropriate data to display in the configuration tables on step 2
 */
export const makeGetConfigsAtLocation = () =>
  createSelector(
    [
      (state, props) => props.locationIndex,
      (state) => state.wlrBroadband.configurations,
    ],
    (locationIndex, configurations) => {
      return configurations
        .map((c, i) => ({ ...c, index: i })) // original index needed for manipulation
        .filter((c) => c.locationIndex === locationIndex);
    }
  );

export const makeGetTargetConfigs = () =>
  createSelector(
    [
      (state) => state.wlrBroadband.configurations,
      (state, props) => props.targetConfigs,
    ],
    (configurations, targetConfigs) => {
      return targetConfigs.map((t) => configurations[t]);
    }
  );

/**
 * Get the instances of a specified dynamic property in product data, over specified configs.
 *
 * Used in the configuration form to build dynamic fields. We need instances across all product data to deal with bulk edit logic
 */
export const makeGetDynamicPropertyInstances = () =>
  createSelector(
    [
      (state, props) => props.targetConfigs,
      (state, props) => props.productType,
      (state, props) => props.propertyName,
      (state, props) => props.isRootProperty,
      (state) => state.wlrBroadband.configurations,
      // TODO: Using this wide a slice of state creates redraw problems... Flatten state so ProductData doesn't belong to configs
      // ....as it's the newly set values that are the root case of the update. Quick fix in place with shouldComponentUpdate
    ],
    (targetConfigs, productType, propertyName, isRootProperty, configs) => {
      let instances = targetConfigs.map((c) => {
        if (productType === "wlr") {
          return _.get(configs[c].wlrProductData.response, [
            ...(!isRootProperty ? ["line"] : []),
            "dynamic_properties",
            propertyName,
          ]);
        }

        if (productType === "broadband") {
          return _.get(configs[c].broadbandProductData.response, [
            ...(!isRootProperty ? ["broadband"] : []),
            "dynamic_properties",
            propertyName,
          ]);
        }

        return false;
      });
      return _.compact(instances);
    }
  );

export const makeGetHasProductDataError = () =>
  createSelector(
    [
      (state, props) => props.targetConfigs,
      (state) => state.wlrBroadband.configurations,
    ],
    (targetConfigs, configs) => {
      let hasProductDataError = false;
      targetConfigs.forEach((c) => {
        hasProductDataError =
          _.get(configs[c].wlrProductData.response, "status") === "error" ||
          _.get(configs[c].broadbandProductData.response, "status") === "error";
      });
      return hasProductDataError;
    }
  );

/**
 * Get an array of current values for a specific property over selected configurations.
 * Used for configuration form fields
 */
export const makeGetDynamicPropertyValues = () =>
  createSelector(
    [
      (state, props) => props.targetConfigs,
      (state, props) => props.productType,
      (state, props) => props.propertyName,
      (state) => state.wlrBroadband.configurations,
    ],
    (targetConfigs, productType, propertyName, configs) => {
      return targetConfigs.map((c) => {
        // console.log(propertyName, configs[c][`${productType}Properties`][propertyName])
        return configs[c][`${productType}Properties`][propertyName];
      });
    }
  );

export const makeGetInitialWlrChangeValues = () =>
  createSelector(
    [
      (state, props) => props.targetConfigs,
      (state, props) => props.propertyName,
      (state) => state.wlrBroadband.configurations, // TODO: split configs here to memoize correctly?
    ],
    (targetConfigs, propertyName, configs) => {
      // console.log('makeGetInitialWlrChangeValues')
      return targetConfigs.map((c) => {
        return configs[c].broadbandProductData.initialWlrChangeValues[
          propertyName
        ];
      });
    }
  );

/**
 * Find if a location is a Working Line Takeover
 *
 * WLTO is when the line in question is not on the BT network.
 * A secondary call will have been carried out to get appropriate details to take it over.
 *
 * @param wlrBroadbandState
 * @param locationIndex
 * @returns {boolean}
 */
export const getIsWLTO = (wlrBroadbandState, locationIndex) => {
  const location = wlrBroadbandState.locations[locationIndex];
  return (
    (location?.type === TRANSFER || location?.type === NEW_SOGEA) &&
    _.get(location, "wltoDetails.response.line_plant_type") === "WORKING_LINE"
  );
};

/**
 * Find out if a location is a transfer.
 *
 * @param wlrBroadbandState
 * @param locationIndex
 * @returns {boolean}
 */
export const getIsTransfer = (wlrBroadbandState, locationIndex) => {
  const location = wlrBroadbandState.locations[locationIndex];
  return location?.type === TRANSFER;
};

/**
 * Get single dynamic property value
 * Looks in the user set values then defaults back to those in the product data if none exists.
 * Not an actual Reselect selector, but it "selects stuff" hence belongs here.
 *
 * @param state
 * @param type - 'wlr' || 'broadband'
 * @param configurationIndex
 * @param propertyName
 * @returns {string}
 */
export const getDynamicPropertyValue = (
  state,
  type,
  configurationIndex,
  propertyName
) => {
  const config = state.wlrBroadband.configurations[configurationIndex];
  const userValue = config[`${type}Properties`][propertyName];
  const productDataProp = _.get(
    config[`${type}ProductData`],
    [
      "response",
      type === "wlr" ? "line" : "broadband",
      "dynamic_properties",
      propertyName,
    ],
    {}
  );
  return (
    userValue || productDataProp.current_value || productDataProp.default_value
  );
};

/**
 * Build parameters for ordering a product or doing a ProductData request from DC
 * OrderProduct/Create and GuidedSales/ProductData should be given the same parameters.
 * These are a mix of those described by dynamic_properties in ProductData, that are acted upon via
 * the configuration form, and a load of special use cases
 *
 *
 * @param state
 * @param configurationIndex
 * @param productType - wlr || broadband
 * @param quoteOnly {boolean}
 */
export const getDCOrderParams = (
  state,
  configurationIndex,
  productType,
  quoteOnly = false
) => {
  const configurations = state.wlrBroadband.configurations;
  const config = configurations[configurationIndex];
  const location = state.wlrBroadband.locations[config.locationIndex];

  let params = {};
  // Add dynamic properties in state with appropriate prefix
  const prefix = productType === "wlr" ? "line" : "broadband";
  const properties = config[`${productType}Properties`];
  let p = properties;

  for (let k in p) {
    if (p.hasOwnProperty(k)) {
      if (
        k === "call_tariff_id" ||
        k === "service_tariff_id" ||
        k === "partner_reference_number"
      ) {
        // call_tariff_id, service_tariff_id, and partner_reference_number are the only property we need that sits outside line.dynamic_properties.
        // They sit in ProductData on the root dynamic_properties.
        // This is because it's a product level property. The product is a shell around the Component
        // (that has the rest of the parameters) according to @jim
        // Hence it shouldn't be prefixed like the others
        // See isRootProperty on WlrBBDynamicField which deals with this too.
        params[k] = p[k];
      } else {
        // All other properties belong to the component and hence should be prefixed 'line-' or 'broadband-'
        params[`${prefix}-${k}`] = p[k];
      }
    }
  }

  // Add non-dynamic properties
  params.product_id = config[`${productType}ProductId`];
  params.order_id = state.order.id;
  params.account_id = state.order.accountId;
  params.lead_id = state.order.leadId;
  params.commission_type = config.commission_type;

  // This is another "product level" property:
  params.contract_length_in_months = state.wlrBroadband.contractLength;

  // If the product is a resign, add the necessary extra params, depending on the product type and if.
  // the user specified a resign start date or not.
  if (
    config.resignProductType === SAME_PRODUCT_WITH_CHANGES ||
    config.resignProductType === NEW_PRODUCT
  ) {
    if (productType === "wlr" && config.wlrProductInstanceId) {
      params["line-product_instance_id"] = config.wlrProductInstanceId;
      params["line-is_resign"] = 1;
      if (state.wlrBroadband.resignStartDate)
        params["line.required_by_date"] = state.wlrBroadband.resignStartDate;
    }
    if (productType === "broadband" && config.broadbandProductInstanceId) {
      params["broadband-product_instance_id"] =
        config.broadbandProductInstanceId;
      params["broadband-is_resign"] = 1;
      if (state.wlrBroadband.resignStartDate)
        params["bb.required_by_date"] = state.wlrBroadband.resignStartDate;
    }
    if (productType === "broadband" && config.mpfProductInstanceId) {
      params["broadband-product_instance_id"] = config.mpfProductInstanceId;
      params["broadband-is_resign"] = 1;
      if (state.wlrBroadband.resignStartDate)
        params["bb.required_by_date"] = state.wlrBroadband.resignStartDate;
    }
  }

  if (config.resignProductType === SAME_PRODUCT) {
    params.product_id = getSameProductResignProduct(state);
    params["extra_services-product_instance_id"] =
      getProductInstanceForConfiguration(state, configurationIndex).id;
    params["extra_services-is_resign"] = 1;
  }

  // If the product is normal or not a "Same Product" resign.
  if (config.resignProductType !== SAME_PRODUCT) {
    // Add current & default values in ProductData where no existing one has been set.
    //
    // While these will have been shown in the config form, they do not persist in the final order,
    // so have to be copied over. Wish I'd have known this earlier!
    // Note ProductData may not exist yet, if this is the first call going into step 2. Hence the _.get
    //
    // Also (it gets better 😫) wlr_change properties from broadband product data DON'T want to be copied over if unchanged,
    // because these are "magic" and influence a pre-existing Daisy WLR line (that the broadband will be applied to)
    const productDataProperties = _.get(
      config[`${productType}ProductData`].response,
      [prefix, "dynamic_properties"],
      {}
    );
    for (let k in productDataProperties) {
      p = productDataProperties[k];
      if (
        p.name &&
        params[`${prefix}-${p.name}`] === undefined &&
        !p.name.includes("wlr_change") &&
        !p.name.includes("commission_type")
      ) {
        params[`${prefix}-${p.name}`] = p.current_value || p.default_value;
      }
    }

    // If product is not a resign)
    if (!config.resignProductType) {
      // ....that is apart from this WLR change one, that's needed to reference the existing line we're changing.
      // It'll come back from the initial product data call, but will be needed for the OrderProduct/Create
      let pid;
      if ((pid = productDataProperties["wlr_change.product_instance_id"])) {
        params["broadband-wlr_change.product_instance_id"] = pid.current_value; // It should only ever be oon BB product data.
      }
    }

    // call_tariff_id from earlier also needs defaulting using product data:
    if (!params.call_tariff_id) {
      p = _.get(
        config.wlrProductData.response,
        "dynamic_properties.call_tariff_id",
        {}
      );
      params.call_tariff_id = p.current_value || p.default_value;
    }

    // Clone address fields.
    // These are legacy parameters (except for mobile) according to Jim.
    // They are needed to pass validation though and seem to show on the DC UI too.
    // TODO: Should these be taken from edited values in UI or doesn't it matter if they are just legacy?

    Object.assign(
      params,
      mapWLR3AddressToDCOrderProduct(location.address, "site_address_")
    );

    // Exchange code needed for any order type that has a number reservation. FB96922
    if (productType === "wlr" && location?.type === NEW_LINE) {
      params["line-css_exchange_code"] =
        location?.lineAvailability?.response.css_exchange_code;
    }

    if (
      productType === "wlr" &&
      location?.wltoDetails?.response.line_plant_type === "WORKING_LINE"
    ) {
      // Copy in Working Line Take Over fields
      // This is a line transfer type. See FB71602.
      // Installation details will only have these values if it is a WLTO
      // (from the WLR3/ValidateWorkingLineTakeOver API call)
      // Note there's more params set for WLTO in src/js/store/wlrBroadband/reducer/newConfiguration.js

      params["line-vic_code"] = location?.wltoDetails?.response.vic_code;
      params["line-existing_line_number"] =
        location?.wltoDetails?.response.existing_line_number;
      params["line-line_plant_type"] =
        location?.wltoDetails?.response.line_plant_type;
      params["line-existing_line_id"] =
        location?.wltoDetails?.response.existing_line_id;
      params["line-acquisition_method"] = "install";
    }

    if (productType === "broadband") {
      const properties = config.broadbandProperties;
      const productData = config.broadbandProductData?.response;

      const routers = _.get(
        productData,
        "broadband.dynamic_properties.router_id.available_option_details",
        {}
      );
      const selectedRouter = routers[properties.router_id];

      if (selectedRouter && selectedRouter.install_type) {
        params["broadband-bb.install_type"] =
          selectedRouter.install_type.toLowerCase();
      }

      // Send IPTV Disable when Configuring TTB FTTC
      // https://gitlab.com/akj-dev/inbox/issues/501
      if (
        _.get(
          productData,
          "broadband.product_component_data.supplier_product_ref"
        ) === "TTB_WLR_FTTC"
      ) {
        params["broadband-bb.iptvServiceType"] = "DISABLED";
      }

      if (location?.wltoDetails?.response.line_plant_type === "WORKING_LINE") {
        params["broadband-bb.accessLineId"] =
          location?.wltoDetails?.response.existing_line_id;
      }
      if (location?.wltoDetails?.response.access_line_ids) {
        const workingLines =
          location?.lineAvailability.response.working_lines.working_line;
        // Ensure workingLines is an array
        const workingLinesArray = Array.isArray(workingLines)
          ? workingLines
          : [workingLines];

        const accessLineIds =
          location.wltoDetails?.response.access_line_ids || [];

        // Find suitable working lines
        const linesWithAccessLineIDs = workingLinesArray.filter((line) => {
          return (
            (line.service_type === "MPF" ||
              line.service_type === "PSTN_SINGLE_LINE") &&
            accessLineIds.includes(line.access_line_id)
          );
        });
        // Only include ALID if 1 single or mpf line.
        if (linesWithAccessLineIDs.length === 1)
          params["broadband-bb.accessLineId"] =
            linesWithAccessLineIDs[0].access_line_id;
      }
    }

    // ONT details are for full-fibre (FTTP) installs
    const ont_reference =
      location?.lineAvailability?.response.ont_details?.ont_reference;
    if (ont_reference) params["broadband-bb.ontReference"] = ont_reference;

    // When there's a new line being installed with self-install FTTC broadband we should remove the sim_provide_ref
    // See FB127774. Apparently this ref makes the BB the "primary lead" and hence overrules the appointment made on WLR.

    // .....or maybe not now, according to @lisa. Disabled for time being. Watch this space....
    // if(
    //     _.get(config.broadbandProductData.response, 'broadband.product_component_data.type') === 'FTTC' &&
    //     getDynamicPropertyValue(state, 'broadband', configurationIndex, 'bb.install_type') === 'self'
    // ) {
    //     if(productType === 'wlr')       delete params['line-adsl_reference']
    //     if(productType === 'broadband') delete params['broadband-bb.sim_provide_ref']
    // }

    // When 0, DC validation will run in quote only mode:
    params.has_gathered_provisioning_data = quoteOnly ? "0" : "1";
  }

  return params;
};

export const wlrBBOrderProductCallsFetching = createSelector(
  [(state) => state.wlrBroadband.configurations],
  (configurations) =>
    configurations.reduce(
      (fetching, config) =>
        fetching ||
        _.get(config, "orderProduct.wlr.fetching") ||
        _.get(config, "orderProduct.broadband.fetching"),
      false
    )
);

/**
 * Check if a config's valid or not.
 * If there's a line component, check the DC validation API response.
 * If there's a broadband component, check that response too.
 * Both must be ok to pass.
 *
 * @param config
 * @returns {boolean}
 */
export const getIsConfigValid = (config) => {
  const {
    wlrProductId,
    broadbandProductId,
    validation,
    wlrValidation,
    broadbandValidation,
    broadbandProductData,
  } = config;

  let localValid = true;

  // Broadband resigns have some validation issues,
  // may need to apply this to lines in future.
  if (
    wlrProductId === RESIGN &&
    !_.isEmpty(broadbandProductData?.response) &&
    _.isEmpty(validation)
  ) {
    const { dynamic_properties } = broadbandProductData?.response.broadband;
    for (const prop in dynamic_properties) {
      if (!dynamic_properties[prop].current_value) localValid = false;
    }
  }

  for (let prop in validation) {
    if (validation[prop].length) localValid = false;
  }

  let wlrValid = true;
  if (
    wlrProductId !== EXISTING_LINE &&
    !getIsMPFLine(config) &&
    wlrProductId !== NEW_FTTP &&
    wlrProductId !== RESIGN &&
    wlrProductId !== NEW_SOGEA &&
    wlrProductId !== EXISTING_SOGEA
  )
    wlrValid = wlrValidation?.response.status === "success";

  let broadbandValid = true;
  if (broadbandProductId)
    broadbandValid = broadbandValidation?.response.status === "success";

  return localValid && wlrValid && broadbandValid;
};

/**
 * Validate all WLR+BB configs
 * Used when progressing from step 2 -> 3
 * @param state
 * @returns {boolean}
 */
export const validateWlrBroadbandConfigs = (state) => {
  const { configurations } = state.wlrBroadband;
  let valid = true;
  configurations.forEach((c) => {
    if (valid === true && c.resignProductType !== SAME_PRODUCT) {
      valid = getIsConfigValid(c);
    }
  });

  return valid;
};

/**
 * Check if a config has a Managed Install router selected
 * A managed install means a broadband engineer appointment will be necessary
 */
export const makeGetHasManagedRouter = () =>
  createSelector(
    [
      (state, props) =>
        state.wlrBroadband.configurations[props.targetConfigs[0]]
          .broadbandProperties,
      (state, props) =>
        state.wlrBroadband.configurations[props.targetConfigs[0]]
          .broadbandProductData?.response,
    ],
    (properties, productData) => {
      const routers = _.get(
        productData,
        "broadband.dynamic_properties.router_id.available_option_details",
        {}
      );
      const selectedRouter = routers[properties.router_id];
      return selectedRouter && selectedRouter.install_type === "Managed";
    }
  );

/**
 * Check if WLR Change requests are possible for this location
 * i.e. it's broadband on an existing Daisy line.
 *
 * @param state
 * @param locationIndex
 * @returns {boolean}
 */
export const getIsWlrChangePossible = (state, locationIndex) => {
  const broadbandSearchProducts = _.get(
    state.wlrBroadband.locations[locationIndex].broadbandSearch.response,
    "products",
    []
  );
  const isResign =
    _.get(state.wlrBroadband.locations[locationIndex], "type") === "Resign";
  let possible = false;
  broadbandSearchProducts.forEach((p) => {
    if (_.get(p, "first_broadband_component.wlr_change_possible") === "1")
      possible = true;
  });
  if (isResign) possible = false;
  return possible;
};

/**
 * Is a config for MPF broadband?
 *
 * Note this is a "single product" solution unlike normal FTTC / ADSL2 WLR & broadband
 * It's not the same as FTTP or SoGEA though (our other "single product" BB solutions)
 * as it's shown on the same product list as the former products. Don't know why.
 * Hence we need to decide if it's MPF this way. The logic on "Choose a Product"
 * wouldn't work otherwise.
 *
 * @param config
 * @returns {boolean}
 */
export const getIsMPFLine = (config) =>
  config.wlrProductId &&
  config.broadbandProductId &&
  config.wlrProductId === config.broadbandProductId;

/**
 * Decide if a configuration needs a WLR engineer appointment
 *
 * @returns {boolean}
 */
export const getRequiresWLRAppointment = (state, props) => {
  const config = state.wlrBroadband.configurations[props.targetConfigs[0]];
  const location = state.wlrBroadband.locations[config.locationIndex];

  return (
    (location?.type === NEW_LINE ||
      (location?.type === RESIGN &&
        config.wlrProductId &&
        config.wlrProductId !== RESIGN &&
        config.resignProductType &&
        config.resignProductType !== SAME_PRODUCT_WITH_CHANGES)) &&
    !getIsMPFLine(config) &&
    config.wlrProductId !== NEW_SOGEA
  );
};

/**
 * Decide if a configuration requires a broadband engineer appointment
 * (if not, a required by date will be shown instead)
 *
 *  Info from Lisa Strickland and Martin Wright on the logic for appointments:
 * (note "required by date" is BB only. there's no dynamic property for that on the WLR product)
 *
 * Appointments and required by dates.
 * New Line - WLR Appointment Required
 * Transfer Line – Required by date (10days after)
 * New Line & ADSL - WLR Appointment required (need to match for Sim provide)
 * Transfer Line and ADSL - required by date (10days after)
 * New Line & FTTC Self Install – WLR Appointment required
 * New Line & FTTC Managed Install – WLR and BB appointment required
 * Transfer Line & FTTC Self Install – Required by Date for both 10 days after & both to match.
 * Transfer Line & FTTC Managed Install – WLR required by date (10 Days after) BB appointment to Match.
 * New ADSL Only – BB Appointment required
 * New FTTC Self install – required by date
 * New FTTC Managed Install – Appointment required.
 *
 * ....which turned out not to be quite right. See logic below.
 * Also see logic around appointments vs required by dates on FTTC
 *
 * @returns {boolean}
 */
export const getRequiresBBAppointment = (state, props) => {
  const config = state.wlrBroadband.configurations[props.targetConfigs[0]];
  const location = state.wlrBroadband.locations[config.locationIndex];
  // const isWlrChangePossible = getIsWlrChangePossible(
  //   state,
  //   config.locationIndex
  // );

  // Is it FTTC?
  const isFTTC =
    _.get(
      config.broadbandProductData.response,
      "broadband.product_component_data.type"
    ) === "FTTC";

  // Is there an existing optical network termination thing, which there might be for FTTP
  // const isExistingONT =
  //   _.get(location?.lineAvailability.response, "ont_details.ont_reference") &&
  //   _.get(
  //     location?.lineAvailability.response,
  //     "ont_details.ont_spare_data_ports",
  //     0
  //   ) > 0;

  // Find if we're supplying a managed router...
  const routers = _.get(
    config.broadbandProductData.response,
    "broadband.dynamic_properties.router_id.available_option_details",
    {}
  );

  const hasSiteVisitReason =
    config.broadbandProductData?.response &&
    config.broadbandProductData?.response.broadband &&
    !!config.broadbandProductData?.response.broadband.dynamic_properties[
      "bb.site_visit_reason"
    ];

  // Broadband appointment is only required if one of these two options is selected.
  const hasSelectedValidSiteVisitReason =
    config.broadbandProperties["bb.site_visit_reason"] !== "NO_SITE_VISIT";

  const selectedRouter = routers[config.broadbandProperties.router_id];
  const hasManagedRouter =
    selectedRouter && selectedRouter.install_type === "Managed";

  // ...and finally put all this together to decide if we need the appointment or not
  // if (isFTTC && location?.type === NEW_LINE) return true
  // if (isFTTC && isWlrChangePossible) return true
  if (isFTTC && selectedRouter && selectedRouter.install_type === "managed")
    return true;
  if (getIsMPFLine(config) && location?.type === NEW_LINE) return true;
  // TP21765: Now use selected site visit reason to determine if bb appointment
  // is needed for FTTP.
  if (
    (config.wlrProductId === NEW_SOGEA ||
      config.wlrProductId === EXISTING_SOGEA ||
      config.wlrProductId === NEW_FTTP) &&
    hasSiteVisitReason &&
    hasSelectedValidSiteVisitReason
  )
    return true;
  if (hasManagedRouter) return true;
  if (location?.type === EXISTING_LINE) return true;
  return false;
};

/**
 * Check if broadband product is BTW FTTC. Used for determining whether to show site visit
 * reason select in step 2 configuration form.
 * https://auroratarget.tpondemand.com/entity/9860-send-no_site_visit-to-dc-when-self
 *
 * @param state
 * @param props
 * @returns {boolean}
 */
export const getIsBTWFTTC = (state, props) => {
  const config = state.wlrBroadband.configurations[props.targetConfigs[0]];
  const isBTW =
    _.get(
      config.broadbandProductData.response,
      "broadband.product_component_data.supplier"
    ) === "BT_WHOLESALE";
  const isFTTC =
    _.get(
      config.broadbandProductData.response,
      "broadband.product_component_data.type"
    ) === "FTTC";
  return isBTW && isFTTC;
};

/**
 * Return true if BB products have activcation code P
 * (means we can't supply BB on the line currently)
 * @param state
 * @param ownProps
 * @returns {boolean}
 */
export const isActivationCodeP = (state, ownProps) =>
  _.get(
    state.wlrBroadband.locations[ownProps.locationIndex],
    "broadbandSearch.response.exchange_details.reason_code"
  ) === "P";

/**
 * Returns true if a Daisy owned line is chosen at any WLR/BB location?.
 * @param state
 * @returns {boolean}
 */
export function getDaisyOwnedLineSelected(state) {
  for (const locat of state.wlrBroadband.locations) {
    if (
      _.get(locat, "installationDetails.response.ownership_confirmation") ===
      "true"
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Check if the previous product instance is linked (has the same pin) as the
 * current one. This is used in step 1, when showing the link icon.
 *
 * @param array
 * @param item
 * @param index
 */
export const isLinkedProductInstance = (array, item, index) => {
  const previousItem = array[index - 1];
  return previousItem && previousItem.pin === item.pin;
};

/**
 * Get product instances for both line and broadband.
 * Map each of these with a type (either line or broadband) for later use.
 * Sort these by pin so they can potentially be linked (see above).
 *
 * @param state
 */
export const getProductInstances = (state) => {
  const lineProductInstances = _.get(
      state.wlrBroadband,
      "lineProductInstances.response.results",
      []
    ),
    broadbandProductInstances = _.get(
      state.wlrBroadband,
      "broadbandProductInstances.response.results",
      []
    );
  const productInstances = lineProductInstances
    .concat(broadbandProductInstances)
    .filter((productInstance) => {
      if (productInstance.pin === "Pending") return false;
      // Filter out line product instances which don't have a service type.
      if (
        !productInstance.broadband &&
        productInstance.line_details &&
        !productInstance.line_details.service_type
      )
        return false;
      return true;
    })
    .map((productInstance) => {
      return {
        ...productInstance,
        ...{
          type:
            productInstance.broadband &&
            productInstance.broadband.technology_type === "MPF"
              ? "mpf"
              : productInstance.broadband
              ? "broadband"
              : "wlr",
        },
      };
    })
    .sort(function (a, b) {
      return a.pin - b.pin;
    });
  return productInstances;
};

/**
 * Calculate the existing product instance price. This is the sum of all active evo services.
 * @param evo_services
 */
export const getTotalPrice = (evo_services = []) => {
  let price = 0;
  evo_services.forEach((service) => {
    if (service.Active) price += parseFloat(service.Rate);
  });
  return price.toFixed(2);
};

/**
 * Get the product instance linked to a selected configuration.
 * @param state
 * @param index
 */
export const getProductInstanceForConfiguration = (state, index) => {
  const { productInstanceId } = state.wlrBroadband.configurations[index];
  if (!productInstanceId) return false;
  const productInstance = getProductInstances(state).find(
    (productInstance) => productInstance.id === productInstanceId
  );
  return productInstance;
};

/**
 * Get the Same Product resign product id.
 * @param state
 */
export const getSameProductResignProduct = (state) =>
  // Uses a generic resign product (the second one in the array; the
  // first one belongs to resigns for mobile).
  _.get(state.wlrBroadband, "resignProductSearch.response.products[0].id");

/**
 * Get all Same Product With Changes resign products. There is a resign product for each line and broadband type.
 * The correct one is selected based on the product instance (that we want to resign) type.
 * @param state
 */
export const getSameProductWithChangesResignProducts = (state) => {
  const lineResignProducts = _.get(
      state.wlrBroadband,
      "lineResignProductSearch.response.products",
      []
    ),
    broadbandResignProducts = _.get(
      state.wlrBroadband,
      "broadbandResignProductSearch.response.products",
      []
    );
  const resignProducts = lineResignProducts.concat(broadbandResignProducts);
  return resignProducts;
};

/**
 * Get all products used for resigns. This includes general line and broadband products,
 * as well as resign line and broadband products.
 * @param state
 * @param locationIndex
 */
export const getProducts = (state, locationIndex) => {
  const resignProducts = getSameProductWithChangesResignProducts(state),
    lineProducts = _.get(
      state.wlrBroadband,
      "lineSearch.response.products",
      []
    ), // Filter out mpf product if exists, as this is added separately to line.
    broadbandProducts = _.get(
      state.wlrBroadband.locations[locationIndex],
      "broadbandSearch.response.products",
      []
    );
  const products = resignProducts.concat(lineProducts, broadbandProducts);
  return products;
};

/**
 * Check if any configurations are resigns
 * @param configs
 */
export const hasResigns = (configs) =>
  configs.findIndex((c) => c.resignProductType) > -1;

/**
 * Returns true if any special rates are in the process of being removed. This happens after Step 3, when the order
 * product calls go through.
 * @param state
 */
export const fetchingRemoveSpecialRates = (state) => {
  return state.wlrBroadband.specialRatesToRemove.reduce(
    (fetching, specialRate) =>
      fetching || _.get(specialRate, "removeSpecialRate.fetching"),
    false
  );
};

export const getResignProductForProductInstanceServiceType = createSelector(
  [(state) => state, forwardProps],
  (state, productInstance) => {
    if (!productInstance) return false;
    const resignProduct = getSameProductWithChangesResignProducts(state).find(
      (resignProduct) => {
        if (productInstance.type === "wlr") {
          return (
            resignProduct.first_line_component.service_type ===
            productInstance.line_details.service_type
          );
        }
        if (
          productInstance.type === "broadband" ||
          productInstance.type === "mpf"
        ) {
          const hasType =
            resignProduct.components[0].type ===
            productInstance.broadband.technology_type;
          const hasSupplier =
            resignProduct.components[0].supplier ===
            productInstance.provider_name;
          const hasReference =
            resignProduct.components[0].supplier_product_ref ===
            productInstance.provider_reference;
          return hasType && hasSupplier && hasReference;
        }
        return false;
      }
    );
    return resignProduct;
  }
);

export const getLineAvailabilityForLocation = createSelector(
  [(state) => state.wlrBroadband.locations, forwardProps],
  (locations, props) => {
    const locationIndex = props.configuration.locationIndex;
    const location = locations[locationIndex];
    if (location) return location?.lineAvailability;
    return {
      fetching: false,
      response: {},
    };
  }
);

export const getBroadbandSearchForLocation = createSelector(
  [(state) => state.wlrBroadband.locations, forwardProps],
  (locations, props) => {
    const locationIndex = props.configuration.locationIndex;
    const location = locations[locationIndex];
    if (location) return location?.broadbandSearch;
    return {
      fetching: false,
      response: {},
    };
  }
);

export const getOverallBroadbandPriceForResign = createSelector(
  [
    (configuration) => configuration,
    (configuration, isRecurring) => isRecurring,
  ],
  (configuration, isRecurring) => {
    return (
      parseFloat(
        _.get(
          configuration.broadbandProductData,
          isRecurring
            ? "response.overall_price.first_bill_recurring_price_with_promotions"
            : "overall_price.one_off_price_with_promotions",
          0
        )
      ) -
      parseFloat(
        _.get(
          configuration.broadbandProductData,
          isRecurring
            ? "response.line.component_price.first_bill_recurring_price_with_promotions"
            : "line.component_price.one_off_price_with_promotions",
          0
        )
      )
    );
  }
);

export const getTagsCheckForLocation = createSelector(
  [(state) => state.wlrBroadband.locations, forwardProps],
  (locations, props) => {
    const locationIndex = props.configuration.locationIndex;
    const location = locations[locationIndex];
    if (location) return location.tagsCheck;
    return {
      fetching: false,
      response: {},
    };
  }
);

export const getProductInstancesForCli = createSelector(
  [getProductInstances, (state, cli) => cli],
  (productInstances, cli) => {
    return productInstances.filter((instance) => instance.pin === cli);
  }
);

export const getProductInstanceAddressForCli = createSelector(
  [getProductInstancesForCli],
  (productInstances) => {
    const withFullAddress = productInstances.filter((productInstance) =>
      hasProductInstanceAddress(productInstance)
    )[0];

    if (withFullAddress) return withFullAddress.address;

    const withPostCode = productInstances.filter((productInstance) =>
      hasProductInstanceAddress(productInstance, true)
    )[0];

    if (withPostCode) return withPostCode.address;

    return null;
  }
);

export const getConfigBBServiceType = (state, configIndex) => {
  const config = state.wlrBroadband.configurations[configIndex];
  const productData = config.broadbandProductData?.response;

  // This service_type is not the same thing as product data service_type....
  // I think it's the type of service the engineer provides.
  // Unlike other types, SI have given SOGEA two variations, which are SOGEA_NEW and SOGEA_EXISTING.
  // SOGEA_EXISTING is used for migrations from an existing SoGEA installation, which we can't determine atm.
  // So always use SOGEA_NEW for now. 03/03/2020 (Ella)
  const isSOGEA = productData.broadband.product_component_data.type === "SOGEA";
  const serviceType = isSOGEA
    ? "SOGEA_NEW"
    : productData.broadband.product_component_data.type;
  return `${serviceType}${
    config.broadbandProperties["bb.sim_provide_ref"] &&
    !isSOGEA &&
    !getIsMPFLine(config)
      ? "_SIM2"
      : ""
  }`;
};

export const getUnspecifiedSingleConnectivityConfigs = (state) => {
  const configs = state.wlrBroadband.configurations.filter(
    (c) =>
      (c.wlrProductId === NEW_SOGEA || c.wlrProductId === NEW_FTTP) &&
      !c.broadbandProductId
  );
  return configs;
};
