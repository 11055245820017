import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  AppSubdomains,
  getPathForCrossAppRedirect,
} from "@akj-dev/affinity-platform";
import {
  resetPacCodes,
  verifyPacCodes,
} from "../../../../../../store/mobile/actions";
import { useTargetConfigsContext } from "../../../../context/TargetConfigsContext";
import { PacVerification } from "../../../../components/Mobile/PacVerification";
import {
  PAC_ERROR,
  PAC_UNVERIFIED,
  PAC_VERIFIED,
} from "../../../../utils/pacStatuses";
import { PacErrorsEnum } from "../../../../utils/pacErrors";
import { VerifyingPac } from "../../../../components/Mobile/VerifyingPac";
import { useCanAccessVfDirect } from "../../../../../../shared/hooks/useCanAccessVfDirect";

export const PacVerificationContainer = () => {
  const dispatch = useDispatch();
  const [showVCNPACNotAllowedMessage, setShowVCNPACNotAllowedMessage] =
    useState(false);

  const { targetConfigs } = useTargetConfigsContext();

  const configs = useSelector<any, any[]>((state) => state.mobile.configs);
  const canAccessVfDirect = useCanAccessVfDirect();

  const doServerSidePACVerification = () => {
    setShowVCNPACNotAllowedMessage(false);
    dispatch(verifyPacCodes(targetConfigs));
  };

  const handleClickVerify = () => {
    if (canAccessVfDirect) {
      // additional client side check after clicking verify but before sending to server
      // VF Direct Partners cannot proceed with PAC codes starting with VCN - show a message instead
      const PACFieldInputValue = configs[targetConfigs[0]].properties["pac"];

      const regex = new RegExp(/^VCN/i);
      if (regex.test(PACFieldInputValue)) {
        return setShowVCNPACNotAllowedMessage(true);
      }
    }

    return doServerSidePACVerification();
  };

  const handleClickClear = () => {
    dispatch(resetPacCodes(targetConfigs));
  };

  // Do we need to show the input fields or the API request status?
  const isVerifyingPacs = _.find(
    configs,
    (c) => _.get(c, "pacCodeCheck.fetching") === true
  );

  // Get PAC verification status
  // ...sure there's a nicer way to do this:
  let pacStatus = PAC_UNVERIFIED;
  let validated = false;

  for (const id of targetConfigs) {
    // Are there verified PACs?
    if (configs[id].properties.pac_expiry_date) pacStatus = PAC_VERIFIED;

    // Are relevant fields not empty & validated?
    if (
      configs[id].properties.pac &&
      configs[id].properties.mobile_number &&
      configs[id].validation &&
      !configs[id].validation.mobile_number &&
      !configs[id].validation.pac
    )
      validated = true;

    // Are there errors though? These take precedence.
    if (configs[id]?.pacCodeCheck?.error) {
      pacStatus = PAC_ERROR;
    }
  }

  // grab the acquisitionMethod reported by DC alongside verification
  const acquisitionMethod =
    configs[targetConfigs[0]]?.pacCodeCheck?.verifiedAcquisitionMethod;

  const getErrorMessage = (): React.ReactNode => {
    const reason_code = _.get(
      configs[targetConfigs[0]],
      "pacCodeCheck.response.reason_code"
    );

    const error = PacErrorsEnum[reason_code as keyof typeof PacErrorsEnum];

    switch (reason_code) {
      case "ERROR_UNKNOWN":
      case "ERROR_NO_RESPONSE_FROM_MP":
        return <RaiseTicketMessage error={error} />;
      case "ERROR_NUMBER_ALREADY_ON_VF_PLATFORM":
        return <VFPlatformErrorMessage />;
      default:
        return error || "Unknown PAC is invalid for an unknown reason.";
    }
  };

  if (isVerifyingPacs)
    return <VerifyingPac configs={configs} targetConfigs={targetConfigs} />;

  return (
    <PacVerification
      isValidated={validated}
      onVerifyClick={handleClickVerify}
      onClearClick={handleClickClear}
      showVCNPACNotAllowedMessage={showVCNPACNotAllowedMessage}
      acquisitionMethod={acquisitionMethod}
      pacErrorMessage={getErrorMessage()}
      pacStatus={pacStatus}
    />
  );
};

const RaiseTicketMessage = ({ error }: { error: string }) => (
  <span>
    {error}
    <br />
    <br />
    If the problem continues, please raise an{" "}
    <a
      href={getPathForCrossAppRedirect(
        AppSubdomains.SUPPORT,
        "/support/new-ticket"
      )}
    >
      incident ticket here
    </a>
    . This will be sent to our Support Team.
  </span>
);

const VFPlatformErrorMessage = () => (
  <>
    This number is currently active on the Gemini Billing Platform so you are
    unable to process a migration through the platform. These migration requests
    are classed as Transfer Of Ownerships (TOO) so please{" "}
    <a
      href={getPathForCrossAppRedirect(
        AppSubdomains.SUPPORT,
        "/support/new-ticket"
      )}
    >
      raise a Ticket to the Giacom Service Delivery team
    </a>
    , who will manually process your request.
  </>
);
