import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

import { CreateAccountFormValues } from "@akj-dev/shared-components";

import { getAccountSettings } from "../../store/account/selectors";
import { createAccountAndUpdate } from "../../store/account/actions";
import {
  mapBroadbandToAccountProperty,
  mapBroadbandToAccountSalutation,
} from "../../store/account/utils";
import { getAccountId } from "../../store/order/selectors";

import { AccountSection } from "./sections";

export const Account = () => {
  const dispatch = useDispatch();
  const accountSettings = useSelector(getAccountSettings);
  const fetching = useSelector(
    (state: any) => state.account.createAccount.fetching
  );
  const accountId = useSelector(getAccountId);
  const [defaultValues, setDefaultValues] = useState<CreateAccountFormValues>(
    {}
  );

  /**
   * Autofill new account fields with
   *
   *  - defaults from WLR+BB config
   *
   * Ported from old redux form functionality (setNewAccountFields action)
   * TODO: Make this work for WLR only? Doesn't look like it ever did
   */
  const configs = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  useEffect(() => {
    const properties =
      configs.length > 0
        ? _.isEmpty(configs[0].broadbandProperties)
          ? configs[0].wlrProperties
          : configs[0].broadbandProperties
        : {};

    let defaults: CreateAccountFormValues = {};
    Object.entries(properties).forEach(([key, value]) => {
      const prop = mapBroadbandToAccountProperty(key);
      if (prop) {
        // TODO: Why is this TS error?
        // @ts-ignore
        defaults[prop] =
          key === "bb.contact.title"
            ? mapBroadbandToAccountSalutation(value as string)
            : value;
      }
    });

    setDefaultValues(defaults);
  }, [configs, accountSettings, accountId]);

  const handleSubmit = (params: any) => {
    if (params["birthdate"] !== "") {
      params["birthdate"] = format(params["birthdate"], "yyyy-MM-dd");
    }
    dispatch(createAccountAndUpdate(params));
  };

  return (
    <AccountSection
      accountSettings={accountSettings}
      isFetching={fetching}
      defaultValues={defaultValues}
      handleSubmit={handleSubmit}
      setDefaultValues={setDefaultValues}
    />
  );
};
