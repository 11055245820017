import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";

interface Document {
  id: string;
  name: string;
  selected: string;
}

interface DocumentsListProps {
  documents: Document[];
  selectedDocuments: string[];
  handleSelect: (value: string) => void;
}

const DocumentsList: React.FC<DocumentsListProps> = ({
  documents,
  selectedDocuments,
  handleSelect,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Send with contract? (PDF only)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((document) => (
            <TableRow key={document.id}>
              <TableCell>{document.name}</TableCell>
              <TableCell>
                <Checkbox
                  disabled={!document.name.endsWith(".pdf")}
                  checked={selectedDocuments.includes(document.name)}
                  onChange={() => handleSelect(document.name)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsList;
