import React from "react";

import { OrderAPIResponseApprovalMessages } from "../components/OrderAPIResponseApprovalMessages";
import { EthernetMessage } from "../components/EthernetMessage";
import { CompleteMessageContainer } from "../containers/CompleteMessage";
import { ApprovalRequiredInfoMessage } from "../components/ApprovalRequiredInfoMessage";

import { HostEnv } from "../../../Cpq";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";
import { RebookExpiredReservations } from "../containers/RebookExpiredReservations";
import { AddDocuments } from "../containers/AddDocuments";

interface MessagesSectionProps {
  requiredApproval: any;
  hostEnv: HostEnv;
  complete: boolean;
  orderId: any;
  showEthernetMessages: boolean;
  approval: any;
}

export const MessagesSection = ({
  requiredApproval,
  hostEnv,
  orderId,
  showEthernetMessages,
  complete,
  approval,
}: MessagesSectionProps) => {
  const canAccessVFDirect = useCanAccessVfDirect();

  return (
    <>
      {requiredApproval && canAccessVFDirect && <ApprovalRequiredInfoMessage />}

      {requiredApproval &&
        hostEnv !== "my_account" &&
        hostEnv !== "platform" && (
          <OrderAPIResponseApprovalMessages approval={approval} />
        )}

      {showEthernetMessages && <EthernetMessage orderId={orderId} />}

      <RebookExpiredReservations />
      <AddDocuments />

      {complete && <CompleteMessageContainer />}
    </>
  );
};
