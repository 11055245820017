import {
  BoardState,
  BoardActionTypes,
  REQUEST_DATA,
  SET_ERROR,
  SET_DATA,
} from "./types";

const initialState: BoardState = {
  fetching: false,
  error: undefined,
  data: [],
};

export function BoardReducer(
  state = initialState,
  action: BoardActionTypes
): BoardState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        error: undefined,
        fetching: true,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case SET_DATA: {
      return {
        ...state,
        fetching: false,
        error: undefined,
        data: action.payload,
      };
    }

    default:
      return state;
  }
}
