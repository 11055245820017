import React, { ReactNode } from "react";
import { Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { QuantityInfoType } from "../enum";

export interface QuantityFieldQuantityInfoProps {
  type: QuantityInfoType;
  children: ReactNode;
}

export const QuantityFieldQuantityInfo = ({
  type,
  children,
}: QuantityFieldQuantityInfoProps) => {
  const { info, error, success } = useStyles();
  let className = info;

  if (
    type === QuantityInfoType.STOCK_ERROR ||
    type === QuantityInfoType.QTY_ERROR
  ) {
    className = error;
  }
  if (type === QuantityInfoType.SUCCESS) {
    className = success;
  }

  return (
    <Typography className={className} variant="body1">
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  info: {
    color: theme.palette.grey[300],
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
}));
