import React from "react";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";

export interface ContractLengthSelectProps {
  contractLength: number;
  possibleContractLengths: number[];
  handleChange: (event: SelectChangeEvent<string>) => void;
  isDisabled?: boolean;
  fullWidth?: boolean;
}

export const ContractLengthSelect = ({
  contractLength,
  possibleContractLengths,
  handleChange,
  isDisabled = false,
  fullWidth = true,
}: ContractLengthSelectProps) => {
  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      <InputLabel id="contractLengthSelectField" sx={{ minWidth: 175 }}>
        Contract Length
      </InputLabel>
      <Select
        data-cy="contractLengthSelectField"
        labelId="contractLengthSelectField"
        label="Contract Length"
        value={contractLength ? contractLength.toString() : undefined}
        onChange={handleChange}
        fullWidth
        disabled={isDisabled}
        sx={{ minWidth: 175 }}
      >
        {possibleContractLengths.map((length) => (
          <MenuItem key={`length-${length}`} value={length}>
            {length} month{length > 1 && "s"}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
