import _ from "lodash";

/**
 * Get pending contract ID (following CreateAndSendEsignContract call).
 * @param state
 */
export const getPendingContractId = (state: any) =>
  _.get(state.generateContract, "contract.pending_contract_id");

export const getContactId = (state: any) => state.generateContract.contactId;
