import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleBillCapConfirmation } from "../../../../store/mobile/actions";
import {
  getBillLimitsNotSet,
  getSpendCapsNotSet,
} from "../../../../store/mobile/selectors/productConfig";
import { BillCapConfirmation } from "../../components/Mobile/BillCapConfirmation";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";

export const BillCapConfirmationContainer = () => {
  const dispatch = useDispatch();
  const canAccessVfDirect = useCanAccessVfDirect();
  const billLimitsNotSet = useSelector<any, boolean>((state) =>
    getBillLimitsNotSet(state)
  );
  const spendCapsNotSet = useSelector<any, boolean>((state) =>
    getSpendCapsNotSet(state)
  );
  const billCapConfirmed = useSelector<any, boolean>(
    (state) => state.mobile.billCapConfirmed
  );
  const billLimitsOrSpendCapsNotSet = !(billLimitsNotSet !== spendCapsNotSet);

  return (
    <BillCapConfirmation
      billCapConfirmed={billCapConfirmed}
      billLimitsOrSpendCapsNotSet={billLimitsOrSpendCapsNotSet}
      onBillCapConfirmChange={() => dispatch(toggleBillCapConfirmation())}
      vfDirectUser={canAccessVfDirect}
    />
  );
};
