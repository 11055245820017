import React from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";

type Props = {
  value?: (string | null)[];
  onSelect: (selected: string[]) => void;
  id: string;
  label: string;
};

export const OrderNumberSelector = ({ value, onSelect, id, label }: Props) => {
  const { orders } = useSelector((state: any) => state.autocomplete);
  const filterOptions = createFilterOptions({
    limit: 10,
  });

  if (!!orders.error)
    return (
      <Grid item sm={12}>
        <StatusChip type="error" message={orders.error} />
      </Grid>
    );

  return (
    <Autocomplete
      fullWidth
      multiple
      size="small"
      value={value}
      id={id}
      filterOptions={filterOptions}
      options={orders.order_numbers}
      loading={orders.loading}
      onChange={(e, option) => {
        onSelect(option as string[]);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};
