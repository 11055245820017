import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { fetchAllContacts } from "../../../../../../../store/account/actions";
import {
  updateConfigProperty,
  validateConfigProperty,
} from "../../../../../../../store/mobile/actions";
import { getAccountId } from "../../../../../../../store/order/selectors";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { BillLimits } from "../../../../../components/Mobile/BillLimits";

export const BillLimitsContainer = () => {
  const dispatch = useDispatch();

  const { targetConfigs } = useTargetConfigsContext();

  const accountId = useSelector((state) => getAccountId(state));
  const billLimit = useSelector((state: any) =>
    _.get(state.mobile.configs[targetConfigs[0]], "properties.bill_limit")
  );

  useEffect(() => {
    if (accountId) {
      dispatch(fetchAllContacts());
    }
  }, [dispatch, accountId]);

  const billLimitRequired = billLimit !== undefined && billLimit !== null;

  const onBillLimitRequiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateConfigProperty({
        propertyName: "bill_limit",
        value: event.target.checked ? "" : null,
        configIds: targetConfigs,
      })
    );

    if (!event.target.checked)
      dispatch(
        validateConfigProperty({
          propertyName: "bill_limit",
          configIds: targetConfigs,
        })
      );
  };

  return (
    <BillLimits
      onBillLimitRequiredChange={onBillLimitRequiredChange}
      billLimitRequired={billLimitRequired}
      billLimit={billLimit}
      targetConfigs={targetConfigs}
    />
  );
};
