import base64js from "base64-js";

/**
 * Encode string as Base64
 *
 * Standard atob() function doesn't fare well with non-standad characters.
 * This was causing problems with the save draft functionality.
 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
 *
 * @param str
 * @returns {string}
 */

export function Base64Encode(str: string) {
  var bytes = new TextEncoder().encode(str);
  return base64js.fromByteArray(bytes);
}

/**
 * Decode base64
 * @param str
 * @param encoding
 * @returns {string}
 * @constructor
 */
export function Base64Decode(str: string, encoding = "utf-8") {
  const decoder = new TextDecoder(encoding);
  const bytes = base64js.toByteArray(str);
  return decoder.decode(bytes);
}
