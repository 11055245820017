import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import { getOrderId } from "../order/selectors";
import { addFlashMessage, addAlertMessage } from "../uiState/actions";
import { STEP_SUMMARY } from "../uiState/constants";

export const invalidateDraft = createAction<string | undefined>(
  "drafts/invalidateDraft"
);

export const saveDraftState = createAsyncThunk(
  "drafts/saveDraftState",
  async (
    {
      skipReject = false,
      successMessage = "Draft Saved",
      successCallback = () => null,
    }: any,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state: any = getState();
    const orderId = getOrderId(state);
    const uiState = state.uiState;
    try {
      const response: any = await GuidedSalesAPI.SaveWizardState(
        orderId,
        JSON.stringify(state)
      );
      if (response.status === "success") {
        if (uiState.activeStep !== STEP_SUMMARY) {
          dispatch(addFlashMessage(successMessage));
        }
        // @hack - run callback post fulfilled action;
        setTimeout(() => {
          successCallback();
        });
        return response;
      } else {
        if (skipReject) return;
        const error = response.message;
        dispatch(addAlertMessage(error));
        rejectWithValue(error);
      }
    } catch (e: any) {
      const error = e.message;
      dispatch(addAlertMessage(error));
      return dispatch(invalidateDraft(error));
    }
  }
);
