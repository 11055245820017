import { getJSON, postJSON } from "../helpers/core";
import { postJSONBackgroundIdempotent } from "../helpers/idempotency";

/**
 * Get settings for account or leadId
 * Note this data is managed in DC at (depending what env obvs):
 * http://daisycentraltesting4.daisygroupplc.com/cgi-bin/index.cgi/Admin/AccountTags (values per account)
 * http://daisycentraltesting4.daisygroupplc.com/cgi-bin/index.cgi/Admin/Tags (global defaults)

 * @param { account: string } | { lead_id: string }
 * @returns {Promise<*>}
 * @constructor
 */
type SettingsOpts = { account: string } | { lead_id: string };
export const Settings = (query: SettingsOpts) =>
  getJSON(`PlatformSettings`, query, false);

/**
 * Get hardware credit available on the stated account
 * @param {string} accountId
 */
export const AccountHardwareCredit = (accountId: string) =>
  getJSON(`Account/AccountHardwareCredit/${accountId}`);

/**
 * Get existing account level mobile bolt-ons on an account
 * @param accountId
 * @returns {Promise<*>}
 * @constructor
 */
export const AccountLevelBoltOnsNetwork = (accountId: string) =>
  getJSON(`Account/AccountLevelBoltOnsNetwork/${accountId}`);

/**
 * Get an account's billing address
 * @param accountId
 * @param order_id
 * @returns {Promise<*>}
 */
export const billingAddress = (accountId: string, order_id?: string) =>
  getJSON(`Account/BillingAddress/${accountId}`, { order_id });

/**
 * Update an account's billing address
 * Note only elements passed as params are changed.
 *
 * Can include any combination of:
 *  - address1
 *  - address2
 *  - address3
 *  - address4
 *  - address5
 *  - country
 *  - postcode
 *
 * @param accountId
 * @param params
 * @returns {Promise<*>}
 */
export const billingAddressPost = (accountId: string, params: any) =>
  postJSON(`Account/BillingAddress/${accountId}`, {
    accept_blank_fields: 1,
    ...params,
  });

/**
 * Get all addresses saved on an account.
 * Note: Different format that billing address.
 */
export const allAccountAddresses = (accountId: string, order_id?: string) =>
  getJSON<AllAccountAddressesResponse>(
    `Account/AllAccountAddresses/${accountId}`,
    { order_id }
  );

export type DCContactAddress = {
  primary_address_building_number: string;
  primary_address_building: string;
  primary_address_street: string;
  primary_address_city: string;
  primary_address_state: string;
  primary_address_postalcode: string;
  primary_address_country: string;
};

export interface DCContact extends DCContactAddress {
  id?: string;
  authority?: string;
  email1?: string;
  type?: string;
  primary_telephone_number?: string;
  last_name?: string;
  full_name?: string;
  title?: string;
  is_billing_contact?: 0 | 1;
  primary_email_address?: string;
  phone_home?: string;
  email2?: string;
  phone_other?: string;
  date_modified?: string;
  phone_work?: string;
  nice_name?: string;
  first_name?: string;
  mobile_phone_number?: string;
  description?: string;
  cyclone_contact?: 0 | 1;
  is_virtual_contact?: 0 | 1;
  salutation?: string;
  virtual_contact_source?: string;
  date_entered?: string;
  birthdate: string;
}

export type AllAccountAddressesResponse = {
  status: string;
  data: {
    contacts: DCContact[];
  };
};

/**
 * Get all contacts saved on an account.
 */
export const AllAccountContacts = (accountId: string, order_id?: string) =>
  getJSON(`Account/AllAccountContacts/${accountId}`, { order_id });

/**
 * Get a list of possible contact types
 */
export const ContactTypes = () =>
  getJSON<ContactTypesResponse>("Account/ContactTypes");

export type ContactType = {
  label: string;
  value: string;
  allowed_actions: { [key: string]: 1 };
};

export type ContactTypesResponse = {
  status: string;
  data?: {
    types: ContactType[];
  };
};

/**
 * Create an account
 * ....a sub account from a main account only I think.
 * Also see Orders/CreateAccount for creating accounts from leads
 * @param params
 * @returns {Promise<*>}
 */
export const Create = (params: any) =>
  postJSONBackgroundIdempotent(`Account/Create`, params);

/**
 * Add a contact to an account
 *
 * This can happen when sending quotes and creating new accounts.
 *
 * Looks like `contact` params can be:
 *  - authority
 *  - salutation
 *  - first_name
 *  - last_name
 *  - primary_email_address
 *  - phone_mobile
 *  - phone_fax
 *  - primary_address_street
 *  - primary_address_city
 *  - primary_address_state
 *  - primary_address_postalcode // ANOTHER address schema?!?!
 *  - primary_address_country
 *
 * ...maybe more too?
 */
export const AddContact = (accountId: string, contact: any) =>
  postJSON<AddContactResponse>(
    `Account/AddContact/${accountId}`,
    contact,
    true,
    true
  );

export type AddContactResponse = {
  status: string;
  message?: string;
  data?: DCContact;
};

/**
 * Get available end user accounts for the authed user
 * @param limit
 * @param filter
 * @constructor
 */
export const EndUserAccounts = (limit: number, filter: string) =>
  getJSON<EndUserAccountsResponse>("/Account/EndUserAccounts", {
    limit,
    filter,
  });

export type EndUserAccountsResponse = {
  data?: {
    accounts: {
      id: string;
      name: string;
    }[];
  };
  status: string;
};

// only supports one at time of writing. endpoint will default to this.
type ThirdPartyBillingCreditVetStatusSuppliers = "VF Direct";

export const ThirdPartyBillingCreditVetStatus = (
  accountId: string,
  number_of_connections: number,
  supplier?: ThirdPartyBillingCreditVetStatusSuppliers
) =>
  getJSON<ThirdPartyBillingCreditVetStatusResponse>(
    `Account/ThirdPartyBilling/${accountId}`,
    {
      number_of_connections,
      supplier,
    },
    true,
    true
  );

export enum CreditCheckStatus {
  Refer = "Refer",
  Declined = "Declined",
  Accepted = "Accepted",
  InProgress = "In Progress",
}
export type ThirdPartyBillingCreditVetStatusResponse = {
  data?: {
    vf_credit_check_reference: string;
    // Note this is superficial without other connections taken into account (advised by Dave Thomas)
    vf_credit_check_status: CreditCheckStatus;
    vf_credit_check_value: string;
    in_flight_connections_count: string;
    as_credit_check_result: CreditCheckStatus;
    as_credit_check_volume: string;
  };
  status: "success" | "error";
};
