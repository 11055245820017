import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { ethernetPurchaseArgsValidationErrors } from "../../../../../../store/ethernetProducts/selectors";
import { Location } from "../../../../components/Ethernet/Location";

interface LocationContainerProps {
  prefix: string;
  index: number;
}

export const LocationContainer = ({
  prefix,
  index,
}: LocationContainerProps) => {
  const [expanded, setExpanded] = useState(true);

  const validationFields = [
    prefix + "_site_address_floor",
    prefix + "_site_address_room",
    prefix + "_mounting_type",
  ];

  const validationErrors = useSelector((state) =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter((field) => validationFields.includes(field));

  const showErrors = useSelector<any, boolean>((state) =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <Location
      prefix={prefix}
      index={index}
      isExpanded={forceExpanded || expanded}
      handleHeaderClick={handleHeaderClick}
    />
  );
};
