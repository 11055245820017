import React from "react";
import { CreditCheckStatus } from "../../../../api/v1/account";
import { Chip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface CreditVetIndicatorProps {
  status: CreditCheckStatus | undefined;
}

export const CreditVetIndicator = (props: CreditVetIndicatorProps) => {
  const classes = useStyles(props);
  return (
    <Chip
      className={classes.root}
      size="small"
      label={props.status}
      data-cy="CreditVetIndicator"
      variant="filled"
    />
  );
};

const getColor = (status: CreditCheckStatus | undefined) => {
  switch (status) {
    case CreditCheckStatus.Accepted:
      return "success";
    case CreditCheckStatus.Declined:
      return "error";
    case CreditCheckStatus.Refer:
      return "warning";
    case CreditCheckStatus.InProgress:
      return "secondary";
    default:
      return "info";
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props: CreditVetIndicatorProps) =>
      theme.palette[getColor(props.status)].main,
    color: (props: CreditVetIndicatorProps) =>
      theme.palette[getColor(props.status)].contrastText,
  },
}));
