import React from "react";
import { format } from "date-fns";
import { ExpandingCard } from "@akj-dev/shared-components";
import ExtensionIcon from "@mui/icons-material/Extension";
import {
  todaysDateString,
  DC_DATE_FORMAT,
  getFirstDayOfMonth,
} from "../../../../shared/utils/date";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

interface BoltOnsProps {
  boltOnCount: number;
  resignStartDate: string;
  boltOnStartDate: string;

  onStartDateChange: (date?: string | null) => void;
}

export const BoltOns = ({
  boltOnCount,
  resignStartDate,
  boltOnStartDate,
  onStartDateChange,
}: BoltOnsProps) => {
  return boltOnCount > 0 ? (
    <ExpandingCard
      defaultExpanded
      title="Bolt Ons"
      subtitle={boltOnCount + " Selected"}
      avatar={<ExtensionIcon />}
    >
      <DatePicker
        label="Add start date"
        value={boltOnStartDate || todaysDateString}
        onChange={(date: string | null) =>
          onStartDateChange(format(new Date(date as string), DC_DATE_FORMAT))
        }
        inputFormat="dd/MM/yyyy"
        minDate={resignStartDate || (getFirstDayOfMonth() as string)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
    </ExpandingCard>
  ) : (
    <div />
  );
};
