import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { getTotalCreditUsed } from "../../../store/order/selectors";
import { OrderViewTable } from "../components/OrderViewTable";

export const OrderViewTableContainer = () => {
  const orderStatus = useSelector<any, any>((state) => state.order.orderStatus);

  const products = _.get(orderStatus.response, "data.products", []);
  const price = _.get(orderStatus.response, "data.price", {});
  const totalCreditUsed = getTotalCreditUsed(products);

  return (
    <OrderViewTable
      products={products}
      price={price}
      totalCreditUsed={totalCreditUsed}
    />
  );
};
