import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { ethernetPurchaseArgsValidationErrors } from "../../../../../../store/ethernetProducts/selectors";
import { SiteContact } from "../../../../components/Ethernet/SiteContact";

interface SiteContactContainerProps {
  prefix: string;
  index: number;
}

export const SiteContactContainer = ({
  prefix,
  index,
}: SiteContactContainerProps) => {
  const [expanded, setExpanded] = useState(true);

  const validationFields = [
    prefix + "_site_address_company",
    prefix + "_site_contact_name",
    prefix + "_site_contact_phone",
  ];

  const validationErrors = useSelector((state) =>
    ethernetPurchaseArgsValidationErrors(state, index)
  ).filter((field) => validationFields.includes(field));

  const showErrors = useSelector<any, boolean>((state) =>
    state.ethernetProducts.configurationIndexesSubmitted.includes(index)
  );

  const forceExpanded = showErrors && validationErrors.length > 0;

  const handleHeaderClick = () => {
    if (forceExpanded) {
      return;
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (forceExpanded) {
      setExpanded(true);
    }
  }, [forceExpanded]);

  return (
    <SiteContact
      expanded={expanded}
      prefix={prefix}
      index={index}
      handleHeaderClick={handleHeaderClick}
    />
  );
};

SiteContactContainer.propTypes = {
  index: PropTypes.number.isRequired,
  prefix: PropTypes.oneOf(["site_a", "site_b"]).isRequired,
};
