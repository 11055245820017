import React from "react";

import { IndexedCardInfo } from "@akj-dev/shared-components";
import { ReactNode } from "react";
import { Box } from "@mui/material";

interface TableSectionProps {
  cardIndex: number;
  tableComponent: ReactNode;
  title: string;
}

export const TableSection = ({
  cardIndex,
  tableComponent,
  title,
}: TableSectionProps) => (
  <IndexedCardInfo index={cardIndex} title={title}>
    <Box>{tableComponent}</Box>
  </IndexedCardInfo>
);
