import { WLRBroadbandState } from "../types";
import { newLocation } from "./newLocation";

const initialState: WLRBroadbandState = {
  locations: [newLocation],

  contractLength: null,

  configurations: [],

  specialRatesToRemove: [],

  resignStartDate: false,

  lineProductInstances: {
    fetching: false,
    response: {},
  },

  broadbandProductInstances: {
    fetching: false,
    response: {},
  },

  resignProductSearch: {
    fetching: false,
    response: {},
  },

  lineResignProductSearch: {
    fetching: false,
    response: {},
  },

  broadbandResignProductSearch: {
    fetching: false,
    response: {},
  },

  lineSearch: {
    fetching: false,
    response: false,
  },
};

export default initialState;
