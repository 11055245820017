import React, { useEffect, useState } from "react";
import { Check, Delete } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";

type Props = {
  id: number;
  onClick: (id: number) => void;
};

const Component: React.FunctionComponent<Props> = ({ id, onClick }) => {
  const [showConfirmationButton, setShowConfirmationButton] = useState(false);
  const [loading, setLoading] = useState(false);

  // Show back delete button after 3 seconds
  // if user didn't clicked confirm
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfirmationButton(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [showConfirmationButton]);

  const handleConfirm = () => {
    onClick(id);
    setLoading(true);
  };

  if (loading) {
    return (
      <Button
        color="secondary"
        disabled
        startIcon={<CircularProgress size={20} />}
      >
        Deleting
      </Button>
    );
  }

  if (showConfirmationButton) {
    return (
      <Button
        color="success"
        onClick={handleConfirm}
        startIcon={<Check />}
        size="medium"
      >
        Confirm
      </Button>
    );
  }
  return (
    <Button
      color="error"
      onClick={() => setShowConfirmationButton(true)}
      startIcon={<Delete />}
      size="medium"
    >
      Delete
    </Button>
  );
};

export default Component;
