import { kongAxios } from "../../../core/kongAxios";

/**
 * Search sub-accounts belonging to the authenticated user matching %name%
 *
 * @param name
 * @param limit
 * @constructor
 */
export const accountAutoComplete = (name: string, limit = 10) =>
  kongAxios.get<AutoCompleteResponse>(
    "/ExternalServices/v1/Account/AutoComplete",
    {
      params: {
        name,
        limit,
      },
    }
  );

export type AccountAutocompleteResult = {
  name: string;
  id: string;
};
export type AutoCompleteResponse = {
  status?: string;
  message?: string;
  data?: {
    results: AccountAutocompleteResult[];
  };
};
