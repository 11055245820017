import React from "react";

import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";

interface ResignTableHeaderProps {
  checked: boolean;
  onCheckedChange: () => void;
}

export const ResignsTableHeader = ({
  checked,
  onCheckedChange,
}: ResignTableHeaderProps) => (
  <TableHead>
    <TableRow>
      <TableCell style={{ width: 30 }}>
        <Checkbox
          color="secondary"
          onChange={onCheckedChange}
          checked={checked}
        />
      </TableCell>
      <TableCell>CLI</TableCell>
      <TableCell />
      <TableCell colSpan={3}>Product</TableCell>
      <TableCell>Price</TableCell>
      <TableCell>End Date</TableCell>
      <TableCell className="u-text-right" style={{ minWidth: 120 }}>
        Action
      </TableCell>
    </TableRow>
  </TableHead>
);
