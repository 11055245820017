import React from "react";
import { useSelector } from "react-redux";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { EthernetOrderConfirmation } from "../../components/Ethernet/OrderConfirmation";

interface EthernetContainerProps {
  cardIndex: string;
}

export const EthernetContainer = ({ cardIndex }: EthernetContainerProps) => {
  const configurations = useSelector<any, Record<string, any>[]>(
    (state) => state.ethernetProducts.configurations
  );

  return (
    <IndexedCardInfo index={cardIndex} title="Ethernet">
      {configurations.map((configuration, i) => (
        <EthernetOrderConfirmation
          configuration={configuration}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        />
      ))}
    </IndexedCardInfo>
  );
};
