import { PayloadAction } from "@reduxjs/toolkit";

import {
  requestOrderProduct,
  receiveOrderProduct,
  requestOrderBoltOn,
  receiveOrderBoltOn,
  requestHardwareCredit,
  receiveHardwareCredit,
} from "../actions";

export const orderExtraReducers = (builder: any) => {
  builder
    .addCase(
      requestOrderProduct,
      (
        state: any,
        action: PayloadAction<{ configIndex: number; isUpdate: boolean }>
      ) => {
        const { configIndex, isUpdate } = action.payload;
        state.configs[configIndex].orderProduct = {
          fetching: true,
          isUpdate,
          response: {},
        };
      }
    )
    .addCase(
      receiveOrderProduct,
      (
        state: any,
        action: PayloadAction<{ configIndex: number; response: any }>
      ) => {
        const { configIndex, response } = action.payload;
        state.configs[configIndex].orderProduct.fetching = false;
        state.configs[configIndex].orderProduct.response = response;
      }
    )
    .addCase(
      requestOrderBoltOn,
      (state: any, action: PayloadAction<{ productId: any }>) => {
        const { productId } = action.payload;
        state.orderBoltOn[productId] = {
          fetching: true,
          response: {},
        };
      }
    )
    .addCase(
      receiveOrderBoltOn,
      (
        state: any,
        action: PayloadAction<{ productId: any; response: any }>
      ) => {
        const { productId, response } = action.payload;
        state.orderBoltOn[productId].fetching = false;
        state.orderBoltOn[productId].response = response;
      }
    )
    .addCase(
      requestHardwareCredit,
      (state: any, action: PayloadAction<{ isUpdate: any }>) => {
        const { isUpdate } = action.payload;
        state.hardwareCredit = {
          fetching: true,
          isUpdate,
          response: {},
        };
      }
    )
    .addCase(
      receiveHardwareCredit,
      (state: any, action: PayloadAction<{ response: any }>) => {
        const { response } = action.payload;
        state.hardwareCredit.fetching = false;
        state.hardwareCredit.response = response;
      }
    );
};
