import React from "react";
import PropTypes from "prop-types";
import download from "downloadjs";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Cancel from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { SalesStatusWrapper } from "./SalesStatusWrapper";
import MUIDataTable from "mui-datatables";
import { TableFilterAutocomplete } from "./TableFilterAutocomplete";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getPathForCrossAppRedirect } from "@akj-dev/affinity-platform";
import { formatCurrency } from "../../../helpers/formatCurrency";
import { SalesStatusPdfBlob } from "../../../api/v1/affinity";
import TextField from "@mui/material/TextField";

export function Table({
  dispatch,
  title,
  rows,
  page,
  count,
  initialLoadComplete,
  loading,
  loadingTableData,
  options,
  rowsPerPage,
  sort,
  filters,
  errors,
  getQuoteIdLink,
  showOpportunityCount,
  hidePriceColumns,
  hideDownloadColumn,
  hideEmailColumn,
  hideOpportunityNameColumn,
  description,
  status,
  onDeleteOpportunity,
  showDeleteOpportunity,
}) {
  // order of filterList array is important
  // the order must be the same as the columns array
  let filterList = [
    filters.quoteId, // 0
    filters.opportunityName, // 1
    filters.customerEmail, // 2
    filters.customerName, // 3
    filters.endCustomerAccountNo, // 4
    filters.createdAtTimestamp, // 5
    filters.createdByName, // 6
    filters.opportunityCount, // 7
  ];

  const tableOptions = {
    responsive: "standard",
    textLabels: {
      body: {
        noMatch: initialLoadComplete
          ? "No matching records found."
          : "Preparing data...",
      },
    },
    serverSide: true,
    serverSideFilterList: filterList,
    onFilterChange: (column, filterData, type) => {
      // @TODO - would be nice to recreate the filter object from the data layer here
      // and dispatch to data layer, rather than updating each data layer filter by key
      // however WATCH OUT for edge case around date filters which can be eg. [empty, <number>].

      // global filter reset clicked
      if (type === "reset") {
        dispatch({ type: "resetFilters" });
      }

      // filter chip cleared
      if (type === "chip") {
        let filters = [];
        if (column === "createdByTimestamp") {
        }
        dispatch({
          type: "setFiltersByKey",
          payload: {
            key: column,
            filters,
          },
        });
      }

      if (column === "opportunityCount") {
        dispatch({
          type: "setFiltersByKey",
          payload: { key: "opportunityCount", filters: filterData[4] },
        });
      }

      // filter changed
      if (type === "custom") {
        switch (column) {
          case "quoteId":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "quoteId", filters: filterData[0] },
            });
            break;
          case "opportunityName":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "opportunityName", filters: filterData[1] },
            });
            break;
          case "customerEmail":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "customerEmail", filters: filterData[2] },
            });
            break;
          case "customerName":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "customerName", filters: filterData[3] },
            });
            break;
          case "endCustomerAccountNo":
            dispatch({
              type: "setFiltersByKey",
              payload: {
                key: "endCustomerAccountNo",
                filters: filterData[4],
              },
            });
            break;
          case "createdAtTimestamp":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "createdAtTimestamp", filters: filterData[5] },
            });
            break;
          case "createdByName":
            dispatch({
              type: "setFiltersByKey",
              payload: { key: "createdByName", filters: filterData[6] },
            });
            break;
          default:
            break;
        }
      }
    },
    customFilterDialogFooter: () =>
      errors.optionData && (
        <Box marginTop={1}>
          <Typography color="error" variant="caption">
            There was a problem fetching filter options. Please try refreshing
            the page.
          </Typography>
        </Box>
      ),
    // visual
    setTableProps: () => ({
      size: "small",
    }),
    // feature toggles
    download: false,
    print: false,
    rowHover: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    // pagination
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 15, 30],
    count: count,
    page: page - 1, // pages are 0 indexxed in this library but API is 1 indexxed
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          dispatch({ type: "setPage", payload: tableState.page + 1 }); // pages are 0 indexxed in this library but API is 1 indexxed
          break;
        case "changeRowsPerPage":
          dispatch({ type: "setLimit", payload: tableState.rowsPerPage });
          break;
        default:
          break;
      }
    },
    // sort
    onColumnSortChange: (changedColumn, direction) => {
      let order = "desc";
      if (direction === "ascending") {
        order = "asc";
      }

      dispatch({ type: "setSort", payload: [changedColumn, order] });
    },
  };

  const getSortDirection = (columnName) =>
    sort[0] === columnName ? sort[1] : "none";

  const formatDateForRender = (date) =>
    format(fromUnixTime(date), "dd/MM/yyyy HH:mm");

  const columns = [
    {
      name: "quoteId",
      label: "ID",
      options: {
        customBodyRender: (val) => {
          const quoteIdLink = getQuoteIdLink(val, status);
          if (!quoteIdLink) return val;
          return (
            <Link
              to={quoteIdLink.to}
              aria-label={quoteIdLink.tooltip}
              rel="noopener noreferrer"
            >
              {val}
              {quoteIdLink.tooltip && (
                <Tooltip title={quoteIdLink.tooltip} arrow placement="right">
                  <Box
                    style={{ transform: "translateY(2px)" }}
                    color="primary.main"
                    fontSize="inherit"
                    marginLeft="4px"
                  >
                    <LaunchIcon color="inherit" fontSize="inherit" />
                  </Box>
                </Tooltip>
              )}
            </Link>
          );
        },
        sortDirection: getSortDirection("quoteId"),
        filterList: filterList[0],
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              disabled={loadingTableData}
              label="ID"
              id="quote-id-filter"
              options={options.quoteId.map((id) => id && id.toString())}
              value={filterList[index][0] && filterList[index][0].toString()}
              onChange={(event, selected) => {
                onChange(selected ? [parseInt(selected)] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "opportunityName",
      label: "Opportunity Name",
      options: {
        display: hideOpportunityNameColumn ? "excluded" : "true",
        filter: !hideOpportunityNameColumn,
        sort: false,
        sortDirection: getSortDirection("opportunityName"),
        filterType: "custom",
        filterList: filterList[1],
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              label="Opportunity Name"
              id="opportunity-name-filter"
              options={options.opportunityName}
              value={filterList[index][0]}
              onChange={(event, selected) => {
                onChange(selected ? [selected] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "customerEmail",
      label: "Email",
      options: {
        display: hideEmailColumn ? "excluded" : "true",
        filter: !hideEmailColumn,
        sortDirection: getSortDirection("customerEmail"),
        filterList: filterList[2],
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              disabled={loadingTableData}
              label="Email"
              id="email-filter"
              options={options.customerEmail}
              value={filterList[index][0]}
              onChange={(event, selected) => {
                onChange(selected ? [selected] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "customerName",
      label: "Company Name",
      options: {
        sortDirection: getSortDirection("customerName"),
        filterType: "custom",
        filterList: filterList[3],
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              disabled={loadingTableData}
              label="Company Name"
              id="customer-name-filter"
              options={options.customerName}
              value={filterList[index][0]}
              onChange={(event, selected) => {
                onChange(selected ? [selected] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "endCustomerAccountNo",
      label: "Company Reference",
      options: {
        filterType: "custom",
        filterList: filterList[4],
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              disabled={loadingTableData}
              label="Company Reference"
              id="customer-reference-filter"
              options={options.endCustomerAccountNo}
              value={filterList[index][0]}
              onChange={(event, selected) => {
                onChange(selected ? [selected] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "createdAtTimestamp",
      label: "Date & Time",
      options: {
        sortDirection: getSortDirection("createdAtTimestamp"),
        customBodyRender: (value) => formatDateForRender(value),
        filterType: "custom",
        filterList: filterList[5],
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <Box display="flex" justifyContent="space-between">
              <Box marginRight={0.5}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      disabled={loadingTableData}
                      size="small"
                      id="date-from"
                    />
                  )}
                  disabled={loadingTableData}
                  maxDate={
                    filterList[index][1]
                      ? fromUnixTime(filterList[index][1])
                      : fromUnixTime(options.createdAtTimestamp[1])
                  }
                  inputFormat="dd/MM/yyy"
                  label="From"
                  value={
                    filterList[index][0]
                      ? fromUnixTime(filterList[index][0])
                      : null
                  }
                  onChange={(date) => {
                    filterList[index][0] = getUnixTime(date);
                    onChange(filterList[index], index, column);
                  }}
                />
              </Box>
              <Box marginLeft={0.5}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      disabled={loadingTableData}
                      size="small"
                      id="date-to"
                    />
                  )}
                  disabled={loadingTableData}
                  minDate={
                    filterList[index][0]
                      ? fromUnixTime(filterList[index][0])
                      : fromUnixTime(parseInt(options.createdAtTimestamp[0]))
                  }
                  inputFormat="dd/MM/yyy"
                  label="To"
                  value={
                    filterList[index][1]
                      ? fromUnixTime(filterList[index][1])
                      : null
                  }
                  onChange={(date) => {
                    filterList[index][1] = getUnixTime(date);
                    onChange(filterList[index], index, column);
                  }}
                />
              </Box>
            </Box>
          ),
        },
        customFilterListOptions: {
          // the chip
          render: (v) => {
            const from = fromUnixTime(v[0] || options.createdAtTimestamp[0]);
            const to = fromUnixTime(v[1] || options.createdAtTimestamp[1]);
            return [
              `${format(from, "dd/MM/yyyy")} - ${format(to, "dd/MM/yyyy")}`,
            ];
          },
        },
      },
    },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        sortDirection: getSortDirection("createdByName"),
        filterList: filterList[6],
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => (
            <TableFilterAutocomplete
              disabled={loadingTableData}
              label="Created By"
              id="created-by-name-filter"
              options={options.createdByName}
              value={filterList[index][0]}
              onChange={(event, selected) => {
                onChange(selected ? [selected] : [], index, column);
              }}
            />
          ),
        },
      },
    },
    {
      name: "opportunityCount",
      label: "No. of Opportunities",
      options: {
        display: showOpportunityCount ? "true" : "excluded",
        filter: showOpportunityCount,
        filterList: filterList[4],
        customBodyRender: (val, tableMeta) => (
          <a
            href={getPathForCrossAppRedirect(
              "sales",
              `/sales/status/opportunities?lead_id=${tableMeta.rowData[0]}`
            )}
          >
            {val}
          </a>
        ),
      },
    },
    {
      name: "oneOffPrice",
      label: "One off price",
      options: {
        display: hidePriceColumns ? "excluded" : "true",
        filter: false,
        sort: false,
        sortDirection: getSortDirection("oneOffPrice"),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "recurringPrice",
      label: "Recurring Price",
      options: {
        display: hidePriceColumns ? "excluded" : "true",
        filter: false,
        sort: false,
        sortDirection: getSortDirection("recurringPrice"),
        customBodyRender: (value) => formatCurrency(value),
      },
    },
    {
      name: "pdfId",
      label: "Download",
      options: {
        display: hideDownloadColumn ? "excluded" : "true",
        filter: false,
        sort: false,
        customBodyRender: (val) =>
          val ? (
            <Box
              style={{ cursor: "pointer" }}
              display="flex"
              justifyContent="center"
              color="text.primary"
              onClick={async () => {
                try {
                  const response = await SalesStatusPdfBlob(val);
                  const blob = await response.file.blob();

                  let filename;
                  if (!response.filename) {
                    filename =
                      rows.find((row) => row.pdfId.indexOf(val) > -1).quoteId ||
                      "untitled";
                  } else {
                    filename = response.filename;
                  }

                  download(blob, filename, blob.type);
                } catch (e) {
                  console.error("error attempting to download pdf " + val, e);
                }
              }}
            >
              <CloudDownloadIcon fontSize="small" color="inherit" />
            </Box>
          ) : null,
      },
    },
    {
      name: "delete",
      label: "Delete",
      options: {
        display: showDeleteOpportunity ? "true" : "exclude",
        filter: false,
        sort: false,
        customBodyRender: (_, { rowIndex }) => {
          const { quoteId } = rows[rowIndex];
          return (
            <IconButton
              onClick={() => onDeleteOpportunity(quoteId)}
              size="large"
            >
              <Cancel />
            </IconButton>
          );
        },
      },
    },
  ];

  return (
    <SalesStatusWrapper
      error={
        (errors.tableData || errors.endCustomerAccounts) &&
        "There was a problem fetching table data.  Please try again."
      }
      loading={loading}
      title={title}
      description={description}
    >
      <Box width="100%" sx={styles.root}>
        <MUIDataTable
          title={
            <Box display="flex" alignItems="center">
              <Box marginRight={1}>
                <Typography variant="subtitle1">{title}</Typography>
              </Box>
              {loadingTableData && <CircularProgress size={24} />}
            </Box>
          }
          data={rows}
          columns={columns}
          options={tableOptions}
        />
      </Box>
    </SalesStatusWrapper>
  );
}

Table.propTypes = {
  initialLoadComplete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingTableData: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    tableData: PropTypes.bool,
    optionData: PropTypes.bool,
    endCustomerAccounts: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  endCustomerAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  filters: PropTypes.shape({
    opportunityName: PropTypes.arrayOf(PropTypes.string).isRequired,
    customerEmail: PropTypes.arrayOf(PropTypes.string).isRequired,
    customerName: PropTypes.arrayOf(PropTypes.string).isRequired,
    createdAtTimestamp: PropTypes.arrayOf(PropTypes.number).isRequired,
    quoteId: PropTypes.arrayOf(PropTypes.number).isRequired,
    createdByName: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  options: PropTypes.shape({
    customerEmail: PropTypes.arrayOf(PropTypes.string),
    opportunityName: PropTypes.arrayOf(PropTypes.string),
    customerName: PropTypes.arrayOf(PropTypes.string),
    createdAtTimestamp: PropTypes.arrayOf(PropTypes.number),
    quoteId: PropTypes.arrayOf(PropTypes.number),
    createdByName: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      quoteId: PropTypes.number.isRequired,
      opportunityName: PropTypes.string,
      customerName: PropTypes.string.isRequired,
      customerEmail: PropTypes.string.isRequired,
      createdAtTimestamp: PropTypes.number.isRequired,
      createdByName: PropTypes.string.isRequired,
      oneOffPrice: PropTypes.string,
      recurringPrice: PropTypes.string,
      pdfId: PropTypes.string,
    })
  ).isRequired,
  getQuoteIdLink: PropTypes.func.isRequired,
  hidePriceColumns: PropTypes.bool,
  hideDownloadColumn: PropTypes.bool,
  hideEmailColumn: PropTypes.bool,
  hideOpportunityNameColumn: PropTypes.bool,
  onDeleteOpportunity: PropTypes.func,
  showDeleteOpportunity: PropTypes.func,
  showOpportunityCount: PropTypes.bool,
  status: PropTypes.string,
};

const styles = {
  root: {
    "& a": {
      display: "flex",
      color: "primary.main",
      textDecoration: "none",
    },
  },
};

export default Table;
