import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useMemo,
} from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { HardwareDeliveryRow } from "../../components/Hardware/DeliveryRow";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import {
  setDeliveryAddressId,
  setDeliveryAddressType,
} from "../../../../store/mobile/actions";
import { SplitDeliveryMobileSIMSplitList } from "./SplitDeliveryMobileSIMSplitList";
import { CollapseRow } from "../../components/Hardware/CollapseRow";

interface MobileSIMListProps {
  splitEditId: string;
  setSplitEditId: Dispatch<SetStateAction<string>>;
  mobileConfigs: any;
}

/**
 * Mobile SIM delivery options.
 *
 * Mobile SIMs are counted as hardware so need delivery options like handsets.
 * They're a completely different thing as far as the FE is concerned though,
 * and we can't refactor this currently as there's other refactoring in flight
 * and the business won't authorize the work.
 * Really by the delivery step, the CPQ should be operating on the DC order
 * itself, with products already added.
 * That would make this an easy thing to do. See TP60325
 * For now though....
 */
export const SplitDeliveryMobileSIMList = ({
  splitEditId,
  setSplitEditId,
  mobileConfigs,
}: MobileSIMListProps) => {
  const dispatch = useDispatch();
  const productData = useSelector((state: any) => state.mobile.productData);

  const productIds = useMemo(
    () => [
      ...new Set(
        mobileConfigs
          // eslint-disable-next-line eqeqeq
          .filter((config: any) => config.properties.is_sim_required == 1)
          .map((config: any) => config.productId)
      ),
    ],
    [mobileConfigs]
  );

  const handleChangeAddressType =
    (configIndexes: number[]) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setDeliveryAddressType(event.target.value, configIndexes));
      setSplitEditId("");
    };

  const handleSelectAccountAddress =
    (configIndexes: number[]) => (event: any, child: ReactNode) => {
      dispatch(
        setDeliveryAddressId((child as ReactElement).props.value, configIndexes)
      );
    };

  const handleClickExistingAddressSelect = (configIndexes: number[]) => () => {
    dispatch(setDeliveryAddressType(addressTypes.EXISTING, configIndexes));
  };

  return (
    <>
      {productIds.map((productId: any) => {
        const configIndexes = mobileConfigs
          .map((c: any, i: number) => (c.productId === productId ? i : ""))
          .filter(String);
        const firstConfig = mobileConfigs[configIndexes[0]];
        const productName = productData[productId]?.response?.product_name;
        const split = !configIndexes.every((i: number) =>
          _.isEqual(firstConfig.delivery, mobileConfigs[i].delivery)
        );

        return [
          <HardwareDeliveryRow
            key={productId}
            productId={productId}
            quantity={configIndexes.length}
            split={split}
            splitEditId={splitEditId}
            setSplitEditId={setSplitEditId}
            productName={productName}
            addressType={firstConfig.delivery.addressType}
            addressId={firstConfig.delivery.addressId}
            handleChangeAddressType={handleChangeAddressType(configIndexes)}
            handleClickExistingAddressSelect={handleClickExistingAddressSelect(
              configIndexes
            )}
            handleSelectAccountAddress={handleSelectAccountAddress(
              configIndexes
            )}
          />,
          <CollapseRow
            visible={productId === splitEditId}
            key={`${productId}-split`}
          >
            <SplitDeliveryMobileSIMSplitList
              productId={productId}
              productName={productName}
            />
          </CollapseRow>,
        ];
      })}
    </>
  );
};
