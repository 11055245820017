import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addProduct,
  removeProduct,
  fetchProductSearch,
} from "../../../../store/monitoringService/actions";
import {
  getIndexOfProductConfig,
  getProductConfigCount,
  getSortedProducts,
} from "../../../../store/monitoringService/selectors";
import { Products } from "../components/Products";

export const ProductsContainer = () => {
  const dispatch = useDispatch();

  const products = useSelector(
    (state: any) => state.monitoringService.productSearch
  );

  const productCount = useSelector<any, number>(
    (state) =>
      (state.monitoringService.productSearch.response.products || []).length
  );
  const configs = useSelector((state: any) => state.monitoringService.configs);

  useEffect(() => {
    if (productCount < 1) {
      dispatch(fetchProductSearch());
    }
  }, [dispatch, productCount]);

  return (
    <Products
      isProductError={products.response.status === "error"}
      productCount={productCount}
      products={getSortedProducts(products.response.products || [])}
      onRetryClick={() => dispatch(fetchProductSearch())}
      onAddProduct={(item) =>
        dispatch(
          addProduct({
            productId: item.id,
            oneOffPrice: item.price.one_off_price_with_promotions,
            recurringPrice:
              item.price.first_bill_recurring_price_with_promotions,
          })
        )
      }
      onRemoveProduct={(itemId) =>
        dispatch(removeProduct(getIndexOfProductConfig(configs, itemId)))
      }
      getProductConfigCount={(itemId) => getProductConfigCount(configs, itemId)}
    />
  );
};
