import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  resetQuote,
  generateQuote as generateQuoteAction,
} from "../../../../../../store/quote/actions";
import { addAllProductsToOrder } from "../../../../../../store/order/actions";
import { STEP_SUMMARY } from "../../../../../../store/uiState/constants";
import { GenerateQuote } from "../../../../components/GenerateQuote";

import { QuoteWLRValidationErrorsContainer } from "./QuoteWLRValidationErrors";
import { QuoteContactStatusContainer } from "./QuoteContactStatus";

interface GenerateQuoteProps {
  open: boolean;
  handleClose: () => void;
}

export const GenerateQuoteContainer = ({
  open,
  handleClose,
}: GenerateQuoteProps) => {
  const dispatch = useDispatch();

  const generateQuote = useSelector((state: any) => state.quote.generateQuote);
  const quoteContactId = useSelector(
    (state: any) => state.quote.quoteContactId
  );
  const activeStep = useSelector((state: any) => state.uiState.activeStep);
  const wlrConfigCount = useSelector(
    (state: any) => state.wlrBroadband.configurations.length
  );

  const doGenerateQuote = async () => {
    // Only add or update products on order if we're not already at the summary.
    // Because of DC performance issues, even updating is too slow. Hence this.
    if (activeStep !== STEP_SUMMARY) {
      await dispatch(addAllProductsToOrder(true));
    }
    await dispatch(generateQuoteAction());
  };

  const closeAndReset = () => {
    dispatch(resetQuote());
    handleClose();
  };

  const quoteId = _.get(generateQuote.response, "data.quote_id");

  return (
    <GenerateQuote
      open={open}
      quoteId={quoteId}
      quoteContactId={quoteContactId}
      generateQuote={generateQuote}
      closeAndReset={closeAndReset}
      doGenerateQuote={doGenerateQuote}
      handleClose={handleClose}
    >
      {!!wlrConfigCount && <QuoteWLRValidationErrorsContainer />}
      <QuoteContactStatusContainer />
    </GenerateQuote>
  );
};
