import { trackEvent } from "@redux-beacon/google-analytics";
import { trackSimpleEvent } from "../../shared/utils/reduxBeacon";
import {
  addEthernetConfiguration,
  removeEthernetConfiguration,
  setEthernetConfigType,
} from "./actions";

const gaEventsMap = {
  ...trackSimpleEvent(addEthernetConfiguration.type),
  ...trackSimpleEvent(removeEthernetConfiguration.type),
  [setEthernetConfigType.type]: trackEvent(
    (action) => ({
      category: "ethernet",
      action: setEthernetConfigType.type,
      label: action?.payload?.productType,
    }),
    "gtm1"
  ),
};

export default gaEventsMap;
