import React from "react";
import _ from "lodash";

import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { ExpandingCard } from "@akj-dev/shared-components";

import { getAddressLine } from "../../../../shared/utils/addresses";
import { RESIGN } from "../../../../store/wlrBroadband/constants";
import { ResignsConfigurationTableContainer } from "../../containers/wlrBroadband/ResignsConfigurationTable";
import { getIsMPFLine } from "../../../../store/wlrBroadband/selectors";

import { ConfigTableRow } from "./ConfigTableRow";
import { useSelector } from "react-redux";

interface WlrBroadbandProps {
  targetConfigs: number[];
  configurations: any[];
  locations: any[];
  configCount: number;
  toggleAll: () => void;
  openForm: () => void;
  toggleSingle: (index: number) => void;
  editAction: (index: number) => void;
  retryAction: () => void;
  deleteAction: (index: number) => void;
  deleteWlrAction: (index: number) => void;
  showCommissionTypeSelection: boolean;
}

export const WlrBroadband = ({
  targetConfigs,
  locations,
  configurations,
  configCount,
  openForm,
  toggleAll,
  toggleSingle,
  editAction,
  retryAction,
  deleteAction,
  deleteWlrAction,
  showCommissionTypeSelection,
}: WlrBroadbandProps) => {
  const classes = useStyles();
  const isOneMonthContract =
    useSelector((state: any) => state.wlrBroadband.contractLength) === 1;
  return (
    <div>
      <Typography variant="h3">Configure your products</Typography>
      <Box my={1} style={{ opacity: targetConfigs.length < 2 ? 0.5 : 1 }}>
        <span className="label">Bulk Actions</span>
        <IconButton
          onClick={openForm}
          disabled={targetConfigs.length < 2}
          data-cy="wlrBroadbandBulkEditButton"
          size="large"
        >
          <EditIcon />
        </IconButton>
      </Box>
      {configurations.filter((config: any) => config.productInstanceId).length >
        0 && (
        <ResignsConfigurationTableContainer
          configCount={configCount}
          checked={targetConfigs.length === configurations.length}
          toggleAll={toggleAll}
          toggleAction={(index) => toggleSingle(index)}
          editAction={editAction}
          deleteAction={deleteAction}
          retryAction={retryAction}
        />
      )}
      {locations.map((location: any, locationIndex: number) => {
        if (location.type === RESIGN || _.isEmpty(location.address)) {
          return null;
        }

        return (
          <ExpandingCard
            title={getAddressLine(location.address) || "New Location"}
            subtitle={location.type}
            avatar={<HomeIcon />}
            // eslint-disable-next-line react/no-array-index-key
            key={locationIndex}
            defaultExpanded
          >
            <div className={classes.configs}>
              <Table
                size="small"
                data-cy="wlrBroadbandStep2Table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        color="secondary"
                        checked={targetConfigs.length === configurations.length}
                        onChange={toggleAll}
                        data-cy="wlrBroadbandToggleAll"
                      />
                    </TableCell>
                    <TableCell align="left">Product</TableCell>
                    <TableCell align="center">Total One Off Price</TableCell>
                    <TableCell align="center">Total Recurring Price</TableCell>
                    <TableCell align="center">Broadband</TableCell>
                    {showCommissionTypeSelection && (
                      <TableCell align="center">
                        <p>Commission</p>
                        <p>Up Front &nbsp; Residual </p>
                      </TableCell>
                    )}
                    <TableCell align="center">Configured?</TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {configurations.map((c: any, configIndex: number) => {
                    if (c.locationIndex !== locationIndex) return false;
                    return (
                      <ConfigTableRow
                        // eslint-disable-next-line react/no-array-index-key
                        key={configIndex}
                        c={c}
                        configIndex={configIndex}
                        checked={targetConfigs.indexOf(configIndex) !== -1}
                        toggleAction={() => toggleSingle(configIndex)}
                        editAction={() => editAction(configIndex)}
                        deleteAction={() => deleteWlrAction(configIndex)}
                        retryAction={retryAction}
                        isMPFLine={getIsMPFLine(c)}
                        showCommissionTypeSelection={
                          showCommissionTypeSelection
                        }
                        isOneMonthContract={isOneMonthContract}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </ExpandingCard>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles({
  configs: {
    overflowX: "auto",
  },
  table: {
    tableLayout: "fixed",
    minWidth: 1030,
  },
});
