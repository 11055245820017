import React from "react";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { BillingAddressContainer } from "./BillingAddress";

interface BillingAddressSectionContainerProps {
  cardIndex: string;
}
export const BillingAddressSectionContainer = ({
  cardIndex,
}: BillingAddressSectionContainerProps) => (
  <IndexedCardInfo index={cardIndex} title="Billing Address">
    <BillingAddressContainer />
  </IndexedCardInfo>
);
