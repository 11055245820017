import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../../store/uiState/actions";
import { Box } from "@mui/material";
import {
  STEP_CHOOSE,
  STEP_SUMMARY,
  STEP_ACCOUNT,
} from "../../../store/uiState/constants";
import { CancelSaleButton } from "../../../shared/containers/CancelSaleButton";
import {
  getBackButtonDisabled,
  getNextButtonDisabled,
  getSaveAndExitButtonDisabled,
} from "../../../store/uiState/selectors";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";
import { getLeadNumber } from "../../../store/order/selectors";

import { Footer } from "../../../shared/components/Footer";

/**
 * Main footer nav for all but "Choose" step of the CPQ
 * Choose step define its own footer because of sub-step navigation
 * @returns {JSX.Element|*}
 */
export function StepNavFooter() {
  const dispatch = useDispatch();
  const hostEnv = useSelector(getHostEnv);
  const leadNumber = useSelector(getLeadNumber);

  const activeStep = useSelector((state: any) => state.uiState.activeStep);
  const steps = useSelector<any, string[]>((state) => state.uiState.steps);
  const backButtonDisabled = useSelector<any, boolean>(getBackButtonDisabled);
  const nextButtonDisabled = useSelector<any, boolean>(getNextButtonDisabled);
  const nextButtonHidden = activeStep === STEP_ACCOUNT;
  const hasCompletedCreateAccount = useSelector<any, boolean>(
    (state) => state.account.createAccount.response?.status === "success"
  );
  const saveExitButtonDisabled = useSelector<any, boolean>(
    getSaveAndExitButtonDisabled
  );

  const doStep = (i: number) => {
    let availableSteps = steps;
    if (activeStep !== STEP_ACCOUNT && hasCompletedCreateAccount) {
      availableSteps = steps.filter((step) => step !== STEP_ACCOUNT);
    }
    const nextStep = availableSteps[availableSteps.indexOf(activeStep) + i];
    if (nextStep) dispatch(setActiveStep(nextStep));
  };

  if (activeStep === STEP_SUMMARY) {
    return hostEnv !== "dc" && !saveExitButtonDisabled ? (
      <CancelSaleButton />
    ) : null;
  }

  const NavButtonLabels: { [index: string]: any } = {
    "uiState.STEP_CUSTOMISE": {
      Prev: "Product Catalogue",
      Next: leadNumber ? "Account Creation" : "Delivery",
    },
    "uiState.STEP_ACCOUNT": {
      Prev: "Customise",
      Next: "Summary",
    },
    "uiState.STEP_DELIVERY": {
      Prev: "Customise",
      Next: "Summary",
    },
  };

  return (
    <Box mt={1}>
      {activeStep !== STEP_CHOOSE && (
        <Footer
          onBack={() => doStep(-1)}
          onContinue={() => doStep(1)}
          backDisabled={backButtonDisabled}
          continueDisabled={nextButtonDisabled}
          continueHidden={nextButtonHidden}
          shouldDisplayCancelSaleButton={
            hostEnv !== "dc" && !saveExitButtonDisabled
          }
          LeftButtonText={
            (NavButtonLabels[activeStep] &&
              NavButtonLabels[activeStep]["Prev"]) ||
            "Previous"
          }
          RightButtonText={
            (NavButtonLabels[activeStep] &&
              NavButtonLabels[activeStep]["Next"]) ||
            "Next"
          }
        />
      )}
    </Box>
  );
}
