import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCreditVetStatus,
  processCreditVetAndFetchStatus,
} from "../../../../store/account/actions";
import { CreditVetStatus } from "../../components/CreditVetStatus/CreditVetStatus";

export const CreditVetStatusContainer = () => {
  const dispatch = useDispatch();

  const fetching = useSelector(
    (state: any) =>
      state.account.creditVetProcess?.fetching ||
      state.account.creditVetStatus?.fetching
  );

  const hasCreditVetProcessError = useSelector(
    (state: any) => state.account.creditVetProcess?.response.status === "error"
  );

  const hasCreditVetProcessSuccess = useSelector(
    (state: any) =>
      state.account.creditVetProcess?.response.status === "success"
  );

  const hasCreditVetStatusError = useSelector(
    (state: any) => state.account.creditVetStatus?.response.status === "error"
  );

  const hasError = hasCreditVetProcessError || hasCreditVetStatusError;

  const status = useSelector(
    (state: any) =>
      state.account.creditVetStatus?.response?.data?.as_credit_check_result
  );

  useEffect(() => {
    if (hasCreditVetProcessSuccess) dispatch(fetchCreditVetStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefreshClick = () => {
    if (!fetching) {
      if (!hasCreditVetProcessSuccess)
        dispatch(processCreditVetAndFetchStatus());
      if (hasCreditVetProcessSuccess) dispatch(fetchCreditVetStatus());
    }
  };

  return (
    <CreditVetStatus
      hasError={hasError}
      fetching={fetching}
      status={status}
      handleRefreshClick={handleRefreshClick}
    />
  );
};
