import React from "react";

import { Box, Typography } from "@mui/material";

import { SelectDate } from "../components/SelectDate";
import { SelectOrderType } from "../components/SelectOrderType";
import { DashboardFilters } from "../types";

type Props = {
  filters: DashboardFilters;
  updateFilters: (filtersUpdate: Partial<DashboardFilters>) => void;
};

export const Dashboard: React.FunctionComponent<Props> = ({
  children,
  filters,
  updateFilters,
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        marginY={1}
      >
        <Typography variant="h2">Dashboard</Typography>
        <SelectDate
          selected={filters.created_after}
          onSelect={(val) =>
            updateFilters({
              created_after: val,
            })
          }
        />
      </Box>
      <Box marginTop={1.5} marginBottom={1.5}>
        <SelectOrderType
          selected={filters.order_type}
          onSelect={(val) => updateFilters({ order_type: val })}
        />
      </Box>
      {children}
    </>
  );
};
