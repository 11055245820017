import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Shipping } from "../../components/Mobile/Shipping";
import {
  updateShippingMethodInState,
  fetchShippingMethods,
} from "../../../../store/shipping/actions";

interface ShippingContainerProps {
  cardIndex: string;
}

export const ShippingContainer = ({ cardIndex }: ShippingContainerProps) => {
  const dispatch = useDispatch();

  const shipping = useSelector((state: any) => state.shipping);

  useEffect(() => {
    dispatch(fetchShippingMethods());
  }, [dispatch]);

  return (
    <Shipping
      cardIndex={cardIndex}
      shipping={shipping}
      onShippingMethodChange={(newMethod) =>
        dispatch(updateShippingMethodInState(newMethod))
      }
    />
  );
};
