import React from "react";
import { StatusChip } from "@akj-dev/shared-components";
import Icon from "@mui/icons-material/Settings";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

export const LineConfiguration = () => (
  <Section title="Line Configuration" avatar={<Icon />} defaultExpanded={false}>
    <WlrBBDynamicField
      productType="wlr"
      propertyName="installation_type"
      margin
    />
    <WlrBBDynamicField productType="wlr" propertyName="product_type" margin />
    <WlrBBDynamicField productType="wlr" propertyName="redcare_installation" />
    <StatusChip
      type="info"
      title="Warning:"
      message="Selecting Redcare will remove any existing broadband product"
    />
    <WlrBBDynamicField productType="wlr" propertyName="nr_extra_extensions" />
    <WlrBBDynamicField productType="wlr" propertyName="termination_type" />
    <WlrBBDynamicField productType="wlr" propertyName="cps_required" />
  </Section>
);
