import React from "react";
import { StatusChip } from "@akj-dev/shared-components";
import _ from "lodash";

interface AddAppointmentStatusChipProps {
  request: Record<string, any>;
  hideSuccess?: boolean;
  productType: string;
  retry: () => void;
}

export function AddAppointmentStatusChip({
  request,
  hideSuccess,
  productType,
  retry,
}: AddAppointmentStatusChipProps) {
  if (request.fetching)
    return (
      <StatusChip
        type="loading"
        title={`Adding ${productType} appointment.`}
        message="Please wait..."
        hasMarginBottom
      />
    );

  if (request.response?.status === "error")
    return (
      <StatusChip
        type="error"
        title="Sorry"
        message={`Something went wrong reserving your ${productType} appointment.`}
        hasMarginBottom
        retry={retry}
      />
    );

  if (request.response?.status === "success" && !hideSuccess)
    return (
      <StatusChip
        type="success"
        title={`${_.capitalize(productType)} Appointment Reserved`}
        message={`Reference: ${_.get(
          request.response,
          "appointment_reference"
        )}`}
        hasMarginBottom
      />
    );

  return <div />;
}
