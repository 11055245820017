import React from "react";

import { TableCell, TableRow } from "@mui/material";

import { StatusChip } from "@akj-dev/shared-components";

interface LineProductSelectorStatusRowProps {
  type: "error" | "success" | "info" | "loading";
  message: string;
  title?: string;
}

export const LineProductSelectorStatusRow = ({
  type,
  message,
  title,
}: LineProductSelectorStatusRowProps) => {
  const defaultTitle = type === "error" ? "Error" : "Please wait";

  return (
    <TableRow>
      <TableCell colSpan={9}>
        <StatusChip
          type={type}
          title={title || defaultTitle}
          message={message}
        />
      </TableCell>
    </TableRow>
  );
};
