import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAccountBoltOn } from "../../../../../store/mobile/actions";

import {
  makeGetAvailableAccountBoltOns,
  makeGetExistingAccountBoltOn,
} from "../../../../../store/mobile/selectors/boltOns";
import { BoltOnSelectField } from "../../components/BoltOnTable/BoltOnSelectField";

interface BoltOnSelectFieldContainerProps {
  boltOnType: string;
  serviceProviderId: string;
}
export const BoltOnSelectFieldContainer = ({
  boltOnType,
  serviceProviderId,
}: BoltOnSelectFieldContainerProps) => {
  const dispatch = useDispatch();

  const selectedBoltOn = useSelector<any, string>(
    (state) => state.mobile.selectedBoltOns[serviceProviderId][boltOnType]
  );

  const availableBoltOns = useSelector((state) =>
    //@ts-ignore
    makeGetAvailableAccountBoltOns()(state, { serviceProviderId, boltOnType })
  );
  const existingBoltOn = useSelector((state) =>
    //@ts-ignore
    makeGetExistingAccountBoltOn()(state, { serviceProviderId, boltOnType })
  );

  return (
    <BoltOnSelectField
      existingBoltOn={existingBoltOn}
      availableBoltOns={availableBoltOns}
      selectedBoltOn={selectedBoltOn}
      onChangeBoltOn={(event) =>
        dispatch(
          setAccountBoltOn({
            serviceProviderId,
            boltOnType,
            value: event.target.value,
          })
        )
      }
    />
  );
};
