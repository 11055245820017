import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText, Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import FieldContainer from "./WlrBBDynamicField/FieldContainer";

interface SelectSiteVisitReasonProps {
  property: Record<string, any>;
  propertyValidation: string;
  propertyValue: string;
  menuItems: { value: string; label: string }[];
  onSiteVisitReasonChange: (event: SelectChangeEvent<string>) => void;
}

export const SelectSiteVisitReason = ({
  property,
  propertyValidation,
  propertyValue,
  menuItems,
  onSiteVisitReasonChange,
}: SelectSiteVisitReasonProps) => (
  <FieldContainer>
    <FormControl fullWidth error={Boolean(propertyValidation)} margin="normal">
      <InputLabel variant="standard">{`${property.label}${
        property.is_required ? " *" : ""
      }`}</InputLabel>
      <Select
        variant="standard"
        value={propertyValue || ""}
        onChange={onSiteVisitReasonChange}
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.value} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
      {propertyValidation && (
        <FormHelperText>{propertyValidation}</FormHelperText>
      )}
    </FormControl>
  </FieldContainer>
);
