import _ from "lodash";
import u from "updeep";
import { getProductDataForConfig } from "./productConfig";

/**
 * Get pricing rule ID for a mobile configuration
 * @param state
 * @param configId
 * @returns {*}
 */
function getPricingRuleId(state, configId) {
  const productData = getProductDataForConfig(state, configId);
  return _.get(productData, "mobile.component_price.pricing_rule_id", false);
}
/**
 * Test whether selected config IDs have identical enough pricing rules to apply bulk discounts.
 * Inside mobile.component_price they must have matching:
 *  - allow_custom_discount
 *  - maximum_recurring_discount_percent
 *  - minimum_recurring_price
 *
 * @param state
 * @param configIds
 * @returns {boolean}
 */

export function getHasMatchingPricingRules(state, configIds) {
  const first = getPricingRuleId(state, configIds[0]);
  return configIds.every(
    (configId) => getPricingRuleId(state, configId) === first
  );
}

/**
 * Calculate the mobile component price including discounts
 * ...that will be returned from DC after a new discount value has been applied by the user.
 * This is used to show UI immediately after changing pricing sliders etc. while the response
 * is still loading.
 * @param properties
 * @param component_price
 * @param fetching
 */
export function getOptimisticComponentPrice(
  properties,
  component_price,
  fetching
) {
  // Only calc if fetch happening...
  if (fetching) {
    const fullPrice = component_price.recurring_price_without_discounts;
    // component_price.recurring_price_without_promotions = '';
    const discountType = properties.recurring_discount_type;
    const discountValue = properties.recurring_discount_value;

    let newPrice;
    switch (discountType) {
      case "DiscountPercentage":
        newPrice = (fullPrice / 100) * (100 - discountValue);
        break;
      case "DiscountAmount":
        newPrice = fullPrice - discountValue;
        break;
      case "SpecifyPrice":
        newPrice = discountValue;
        break;
      default:
        newPrice = fullPrice;
    }

    return u(
      {
        first_bill_recurring_price_with_promotions:
          parseFloat(newPrice).toFixed(2),
      },
      component_price
    );
  }

  return component_price;
}

/**
 * Does this product data describe a product with dynamic pricing?
 * @param state
 * @param productId
 * @returns {boolean}
 */
export function getIsDynamicProduct(state, productId) {
  return !!_.get(
    state,
    `mobile.productData["${productId}"].response.mobile.component_price.pricing_scheme_details`
  );
}
