import React from "react";
import { useSelector } from "react-redux";

import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { WlrLocationProductConfig } from "../../../types";
import { WlrLocationTypeEnum } from "../../../enums";
import { BroadbandProductSelectContainer } from "../BroadbandProductSelect";

export interface BroadbandSelectProps {
  locationIndex: number;
  productId: WlrLocationTypeEnum | string;
  broadbandProductId?: string;
}

export const BroadbandSelectContainer = ({
  locationIndex,
  productId,
  broadbandProductId = "",
}: BroadbandSelectProps) => {
  const classes = useStyles();

  const contractLength = useSelector<any, number>(
    (state) => state.wlrBroadband.contractLength
  );

  const configurations = useSelector<any, WlrLocationProductConfig[]>(
    (state) => state.wlrBroadband.configurations ?? []
  ).map((config, configId) => ({ ...config, configId }));

  const productConfigurations = configurations.filter(
    (c) => c.wlrProductId === productId && c.locationIndex === locationIndex
  );

  return (
    <div className={classes.root}>
      <BroadbandProductSelectContainer
        label="Add broadband"
        broadbandProductId={broadbandProductId}
        wlrProductId={productId}
        configId={productConfigurations[0].configId}
        contractLength={contractLength}
        locationIndex={locationIndex}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));
