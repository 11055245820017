import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export function TableFilterAutocomplete({
  label,
  onChange,
  options,
  value,
  id,
  ...props
}) {
  return (
    <Autocomplete
      size="small"
      id={id}
      options={options ? options.filter((o) => o) : []}
      value={value || null}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
      {...props}
    />
  );
}

TableFilterAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string,
};
