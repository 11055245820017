import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import DropZone, { ImageFile, DropFilesEventHandler } from "react-dropzone";
import { useTheme } from "@mui/material/styles";

interface FileDropProps {
  message: JSX.Element | string;
  maxSize?: number;
  onDrop: DropFilesEventHandler;
  accept?: string;
}

export const FileDrop = ({
  onDrop,
  message,
  maxSize,
  ...rest
}: FileDropProps) => {
  const theme = useTheme();

  const onDropRejected = (files: ImageFile[]) => {
    if (maxSize && files[0].size > maxSize) {
      alert("File is too big.");
    }
  };

  return (
    <StyledDropZone
      onDrop={onDrop}
      activeClassName="dropZoneActive"
      disablePreview
      maxSize={maxSize || undefined}
      onDropRejected={onDropRejected}
      data-cy="FileUpload"
      {...rest}
    >
      <DownloadIcon
        htmlColor={theme.palette.success.main}
        style={{ display: "block", margin: "auto", width: 40, height: 40 }}
      />
      {message} or <span className="choose">choose a file</span>
      {maxSize && (
        <span className="maxSize">Maximum file size {maxSize / 1000000}MB</span>
      )}
    </StyledDropZone>
  );
};

const StyledDropZone = styled(DropZone)`
  width: 100%;
  padding: 50px 20px;
  border: 2px dashed ${(p) => p.theme.palette.primary.main};
  transition: background-color 0.2s;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  .choose {
    display: block;
    font-weight: bold;
    transition: color 0.2s;
  }
  &:hover .choose {
    color: ${(p) => p.theme.palette.primary.main};
  }
  &.dropZoneActive {
    background: #dbe6f3;
  }
  .maxSize {
    font-style: italic;
  }
`;

FileDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  message: PropTypes.node,
};
