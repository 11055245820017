import React from "react";
import Icon from "@mui/icons-material/Redeem";

import { Section } from "../../../../shared/components/Section";

import WlrBBDynamicField from "./WlrBBDynamicField";

export const CallBundles = () => {
  return (
    <Section title="Call Bundles" avatar={<Icon />} defaultExpanded={false}>
      <WlrBBDynamicField
        productType="wlr"
        propertyName="fixed_line_call_bundle_id"
        margin
      />
      <WlrBBDynamicField
        productType="wlr"
        propertyName="mobile_call_bundle_id"
        margin
      />
    </Section>
  );
};
