import React from "react";
import { useSelector } from "react-redux";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { getHardwareCount } from "../../../store/hardware/selectors";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";

import { Mobile } from "./Mobile";
import { Hardware } from "./Hardware";
import { WlrBroadband } from "./wlrBroadband";
import { Ethernet } from "./Ethernet/index";
import { UniversalProducts } from "./UniversalProducts/index";
import { MonitoringService } from "./MonitoringService/index";
import { Box } from "@mui/material";
import { DeclinedCreditVetWarningContainer } from "../../Wizard/containers/DeclinedCreditVetWarningContainer";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";

export const StepCustomise = () => {
  const hostEnv = useSelector((state) => getHostEnv(state));
  const hardwareOpen = useSelector((state) => getHardwareCount(state) > 0);
  const mobileOpen = useSelector<any, boolean>(
    (state) => state.mobile.configs.length > 0
  );
  const wlrBroadbandOpen = useSelector<any, boolean>(
    (state) => state.wlrBroadband.configurations.length > 0
  );
  const ethernetOpen = useSelector<any, boolean>(
    (state) => state.ethernetProducts.configurations.length > 0
  );
  const universalProductsOpen = useSelector<any, boolean>(
    (state) => state.universalProducts.configs.length > 0
  );
  const monitoringServiceProductsOpen = useSelector<any, boolean>(
    (state) => state.monitoringService.configs.length > 0
  );
  const canAccessVfDirect = useCanAccessVfDirect();

  const isMyAccount = hostEnv === "my_account";
  let indexNumber = 0;

  return (
    <div>
      {!isMyAccount && mobileOpen && (
        <Box>
          {canAccessVfDirect && <DeclinedCreditVetWarningContainer />}
          <IndexedCardInfo index={++indexNumber} title="Mobile">
            <Mobile />
          </IndexedCardInfo>
        </Box>
      )}

      {hardwareOpen && (
        <IndexedCardInfo index={++indexNumber} title="Hardware">
          <Hardware />
        </IndexedCardInfo>
      )}

      {!isMyAccount && wlrBroadbandOpen && (
        <IndexedCardInfo index={++indexNumber} title="Lines, Calls & Broadband">
          <WlrBroadband />
        </IndexedCardInfo>
      )}

      {!isMyAccount && ethernetOpen && (
        <IndexedCardInfo index={++indexNumber} title="Ethernet">
          <Ethernet />
        </IndexedCardInfo>
      )}

      {!isMyAccount && universalProductsOpen && (
        <IndexedCardInfo index={++indexNumber} title="Other Products">
          <UniversalProducts />
        </IndexedCardInfo>
      )}

      {!isMyAccount && monitoringServiceProductsOpen && (
        <IndexedCardInfo index={++indexNumber} title="Monitoring as a Service">
          <MonitoringService />
        </IndexedCardInfo>
      )}
    </div>
  );
};
