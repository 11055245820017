import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ReduxProductsResponse,
  ReduxFetchType,
} from "../../../../../store/_root/types";
import { fetchMobileSearch } from "../../../../../store/mobile/actions";
import { ReduxMobileProductType } from "../../../../../shared/types/general";
import { getAccountSettings } from "../../../../../store/account/selectors";
import { useMemo } from "react";
import { transformMobileProductForDisplay } from "../../utils";
import { AccountSettings } from "@akj-dev/affinity-platform";
import { TariffTable } from "../../components/Tariff/Table";
import { MobileProductConfig } from "../../../../../shared/types/MobileProductConfig";
import { getHostEnv } from "../../../../../store/order/selectors/getHostEnv";

export const TariffTableContainer = () => {
  const dispatch = useDispatch();
  const { contractLengthInMonthsAllProducts, contractLengthInMonthsUIFilter } =
    useSelector((state: any) => state.mobile);
  const settings = useSelector<any, AccountSettings>(getAccountSettings);
  const hostEnv = useSelector(getHostEnv);

  const mobileSearch = useSelector<
    any,
    ReduxFetchType<ReduxProductsResponse<ReduxMobileProductType>>
  >((state) => state.mobile.mobileSearch);
  const configs = useSelector<any, MobileProductConfig[]>(
    (state) => state.mobile.configs ?? []
  );

  useEffect(() => {
    if (!mobileSearch?.response?.status) {
      dispatch(fetchMobileSearch(settings.as_request_dynamic_products === "1"));
    }
  }, [dispatch, mobileSearch, settings.as_request_dynamic_products]);

  const configsCount = configs.filter((config) => !config.resignId).length;

  const withO2 = settings.as_can_select_o2_products === "1";
  const canRestrictContractTerms =
    settings.can_restrict_contract_terms_by_product === "1" && hostEnv !== "dc";

  const supplierProducts = useMemo(
    () =>
      (mobileSearch?.response?.products || [])
        .filter(
          (product) =>
            (!canRestrictContractTerms && !contractLengthInMonthsUIFilter) ||
            (!canRestrictContractTerms &&
              parseInt(product.minimum_contract_length_in_months) ===
                contractLengthInMonthsUIFilter) ||
            (canRestrictContractTerms &&
              product.available_contract_lengths.includes(
                contractLengthInMonthsAllProducts.toString()
              ))
        )
        .filter((product) => !product.first_mobile_component.is_data_only_voice)
        .filter(
          (product) =>
            withO2 || product.first_mobile_component.supplier !== "O2"
        )
        .map(transformMobileProductForDisplay),
    [
      mobileSearch,
      withO2,
      contractLengthInMonthsUIFilter,
      contractLengthInMonthsAllProducts,
      canRestrictContractTerms,
    ]
  );

  const mobileError =
    mobileSearch?.response?.status === "error" &&
    !mobileSearch.fetching &&
    mobileSearch.response.message;

  const showLoader = mobileSearch?.fetching;

  return (
    <TariffTable
      showLoader={showLoader}
      mobileError={mobileError}
      configs={configs}
      configsCount={configsCount}
      products={supplierProducts}
      canSeeProductFamily={settings.as_can_see_product_family === "1"}
      withO2={withO2}
      retryRequestMobileSearch={() => dispatch(fetchMobileSearch(true))}
    />
  );
};
