import { getJSON } from "../helpers";

interface AccountAutocompleteAPIResponse {
  name: string;
  id: number;
  billing_account_number: number;
}

export function getAccountAutocomplete(query: string) {
  return getJSON<AccountAutocompleteAPIResponse[]>("/Account/AutoComplete", {
    name: query,
    with_ban: 1,
  }).then((results) =>
    results.map((result) => ({
      name: result.name,
      id: result.billing_account_number.toString(),
    }))
  );
}
