export function parseSavedStoreState(state: any) {
  try {
    delete state.steps;
    delete state.invalidFields;
    delete state.contractLengths;
    delete state.quote;
    delete state.mobileHardwareCredits;
    delete state.mobileHardware;
    delete state.accountSelect;

    state.ethernetProducts.configurations.forEach((c: any) => {
      c.purchaseIsRequesting = false;
    });
    state.wlrBroadband.locations.forEach((l: any) => {
      l.cli.validating = false;
      l.lineAvailability.fetching = false;
      l.installationDetails.fetching = false;
      l.broadbandSearch.fetching = false;
      l.numbers.fetching = false;
    });
    state.wlrBroadband.configurations.forEach((c: any) => {
      c.wlrProductData.fetching = false;
      c.broadbandProductData.fetching = false;
      c.wlrAppointments.fetching = false;
      c.addWlrAppointment.fetching = false;
      c.broadbandAppointments.fetching = false;
      c.addBroadbandAppointment.fetching = false;
      c.numberReservation.fetching = false;
      c.wlrValidation.fetching = false;
      c.broadbandValidation.fetching = false;
    });
    state.drafts.isFetching = false;
    state.uiState.showFinalStepWarning = false;
    state.account.contacts.fetching = false;
    state.account.creditVetProcess.fetching = false;
  } catch (e: any) {
    console.log("Could not parseSavedStoreState:", e.message, state);
  }
  return state;
}
