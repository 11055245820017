import React, { ReactNode } from "react";

import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { DCContact } from "../../../../api/v1/account";
import {
  dcAccountAddressToString,
  dcContactToString,
} from "../../utils/helpers";

export interface ExistingAddressSelectProps {
  handleSelectAccountAddress: (event: any, child: ReactNode) => void;
  /**
   * This is either a contact UUID or an array index when it's an accountAddresses, that has no unique identifier.
   * Yes, this is very messy.
   */
  addressId: number | string;
  disabled?: boolean;
  hasAddresses: boolean;
  accountAddresses: Record<string, any>[];
  filteredAccountContacts: DCContact[];
}

export const ExistingAddressSelect = ({
  addressId,
  disabled,
  accountAddresses,
  filteredAccountContacts,
  hasAddresses,
  handleSelectAccountAddress,
}: ExistingAddressSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth disabled={disabled || !hasAddresses}>
      <InputLabel variant="standard">
        {hasAddresses ? "Choose existing address" : "No existing addresses"}
      </InputLabel>
      <Select
        variant="standard"
        data-cy="ExistingAddressSelect"
        value={addressId}
        onChange={handleSelectAccountAddress}
      >
        {filteredAccountContacts.length > 0 && (
          <MenuItem disabled>
            <Typography variant="overline">Account Contacts</Typography>
          </MenuItem>
        )}
        {filteredAccountContacts.map((address) => (
          <MenuItem value={address.id} key={address.id}>
            <Box display="flex" alignItems="center">
              <div className={classes.addressMeta}>
                {dcContactToString(address)}
              </div>
              {address.authority && (
                <Chip
                  label={address.authority}
                  size="small"
                  variant="outlined"
                />
              )}
            </Box>
          </MenuItem>
        ))}
        {accountAddresses.length > 0 && (
          <MenuItem disabled>
            <Typography variant="overline">Previous Addresses</Typography>
          </MenuItem>
        )}
        {accountAddresses.map((address: any, i: number) => (
          // Note: No ID is returned from endpoint, so no stable key
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem value={i} key={i}>
            {dcAccountAddressToString(address)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles({
  addressMeta: {
    marginRight: 6,
  },
});
