import React from "react";
import Icon from "@mui/icons-material/Person";

import { Section } from "../../../../shared/components/Section";

import { SelectSiteVisitReasonContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/SiteVisitReason/SelectSiteVisitReason";

export const SiteVisitReason = () => (
  <Section
    title="Site Visit Reason"
    avatar={<Icon />}
    data-cy="siteVisitReasonSection"
  >
    <SelectSiteVisitReasonContainer
      productType="broadband"
      propertyName="bb.site_visit_reason"
    />
  </Section>
);
