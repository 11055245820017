import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { selectContactForContract } from "../../../store/generateContract/actions";
import { createContract } from "../../../store/generateContract/actions";
import { sendForApproval } from "../../../store/order/actions";
import { getAccountSettings } from "../../../store/account/selectors";
import { GenerateContract } from "../components/GenerateContract";

export const GenerateContractContainer = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const approvalRequired = useSelector<any, boolean>((state) =>
    _.get(state.order.orderStatus.response, "data.approval.required")
  );
  const contactId = useSelector<any, string>(
    (state) => state.generateContract.contactId
  );
  const fetchingContract = useSelector<any, boolean>(
    (state) => state.generateContract.generatingContract
  );
  const settings = useSelector((state) => getAccountSettings(state));

  const generateAndApprove = () => {
    dispatch(createContract());
    if (
      approvalRequired &&
      settings.requires_external_approval_for_external_user_orders === "0"
    )
      dispatch(sendForApproval());
  };

  return (
    <GenerateContract
      isDialogOpen={open}
      fetching={fetchingContract}
      contactId={contactId}
      onSelectContactForContract={(id) =>
        dispatch(selectContactForContract(id))
      }
      generateAndApprove={generateAndApprove}
      toggleDialog={() => setOpen((old) => !old)}
    />
  );
};
