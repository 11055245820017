import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dashboard } from "./components/Dashboard";

import { Status } from "../common/Status";
import { Overview } from "./components/Overview";
import { useQueryParams } from "use-query-params";

import { isValidDashboardDateString } from "./helpers/isValidDashboardDateString";
import {
  DateString,
  OrderType,
  orderTypes,
  ParsedQueryParam,
} from "../../types";
import { RootState } from "../../store/rootReducer";
import { requestOverviewData } from "../../store/overview/actions";
import { dateStringSubMonth, toDateString } from "../../helpers/dates";

export function OrderDashboard() {
  const data = useSelector((state: RootState) => state.overview.data);
  const isLoading = useSelector((state: RootState) => state.overview.fetching);
  const error = useSelector((state: RootState) => state.overview.error);
  const dispatch = useDispatch();

  const [filters, updateFilters] = useQueryParams({
    order_type: {
      encode: (val: OrderType) => val,
      decode: (input: ParsedQueryParam) =>
        orderTypes.find((type) => type === input),
    },
    created_after: {
      encode: (val: DateString) => val,
      decode: (input: ParsedQueryParam) => {
        if (typeof input === "string" && isValidDashboardDateString(input)) {
          try {
            return toDateString(input);
          } catch (e) {
            return dateStringSubMonth;
          }
        }
        return dateStringSubMonth;
      },
    },
  });

  useEffect(() => {
    dispatch(requestOverviewData(filters));
  }, [filters, dispatch]);

  return (
    <Dashboard filters={filters} updateFilters={updateFilters}>
      <Status loading={isLoading} error={error}>
        <Overview filters={filters} data={data} />
      </Status>
    </Dashboard>
  );
}
