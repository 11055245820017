import { requestReservedNumbersList } from "../actions";

export const reservedNumbersExtraReducers = (builder: any) => {
  builder
    .addCase(requestReservedNumbersList.pending, (state: any, action: any) => {
      const network = action.meta.arg;
      if (!state.reservedNumbersSearch[network]) {
        state.reservedNumbersSearch[network] = {};
      }
      state.reservedNumbersSearch[network].fetching = true;
    })
    .addCase(
      requestReservedNumbersList.fulfilled,
      (state: any, action: any) => {
        const network = action.meta.arg;
        const response = action.payload;
        if (!state.reservedNumbersSearch[network]) {
          state.reservedNumbersSearch[network] = {};
        }
        state.reservedNumbersSearch[network].fetching = false;
        state.reservedNumbersSearch[network].response = response;
      }
    );
};
