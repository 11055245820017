import account from "../account/reducer";
import quote from "../quote/reducer";
import generateContract from "../generateContract/reducer";
import supportCase from "../supportCase/reducer";
import uiState from "../uiState/reducer";
import { combineReducers } from "redux";
import drafts from "../drafts/reducer";
import shipping from "../shipping/reducer";
import ethernetProducts from "../ethernetProducts/reducer";
import hardwareDelivery from "../hardwareDelivery/reducer";
import order from "../order/reducer";
import wlrBroadband from "../wlrBroadband/reducer";
import hardwareProducts from "../hardware/products/reducer";
import hardwareConfigurations from "../hardware/configurations/reducer";
import hardwareOrders from "../hardware/hardwareOrders/reducer";
import mobile from "../mobile/reducer";
import universalProducts from "../universalProducts/reducer";
import monitoringService from "../monitoringService/reducer";
import { parseSavedStoreState } from "./utils";
import { clearState, loadWizardState } from "./actions";

const storeSlices = combineReducers({
  account,
  drafts,
  quote,
  generateContract,
  supportCase,
  mobile,
  hardwareDelivery,
  order,
  shipping,
  uiState,
  ethernetProducts,
  wlrBroadband,
  hardwareProducts,
  hardwareConfigurations,
  hardwareOrders,
  universalProducts,
  monitoringService,
});

const reducer = (state: any, action: any) => {
  if (action.type === clearState.type) {
    state = undefined;
  }
  if (action.type === loadWizardState.type) {
    state = parseSavedStoreState(action.payload);
  }
  return storeSlices(state, action);
};

export default reducer;
