import React, { CSSProperties } from "react";
import { MobileProduct } from "../../../../../shared/types/general";
import { Box, Button, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CarrierField } from "../Tariff/CarrierField";
import WifiTetheringIcon from "@mui/icons-material/WifiTethering";

interface ResignProductSelectTableRowProps {
  index: number;
  style: CSSProperties;
  data: {
    products: MobileProduct[];
    allConfigsCount: number;
    showProductFamily: boolean;
    setResignProduct: (id: string) => void;
  };
}

export const ResignProductSelectTableRow = ({
  index,
  style,
  data,
}: ResignProductSelectTableRowProps) => {
  const classes = useStyles();
  const product = data.products[index];

  const handleSelect = () => data.setResignProduct(product.id);

  return (
    <Box data-cy={product.name} className={classes.root} style={style}>
      <Box className={classes.sm}>
        <CarrierField network={product.network} />
      </Box>
      {data.showProductFamily && (
        <Box className={classes.sm}>{product.family}</Box>
      )}
      <Box className={classes.product}>
        {product.name}
        {!!product.dataOnly && (
          <Box ml={1}>
            <WifiTetheringIcon titleAccess="Data only tariff" />
          </Box>
        )}
      </Box>
      <Box className={classes.priceSelect}>
        <Typography variant="body1">£{product.recurringPrice}</Typography>
        <Box ml={1}>
          <Button
            onClick={handleSelect}
            variant="outlined"
            className={classes.priceSelectBtn}
          >
            Select
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#f8f7f9",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}`,
  },
  // TODO: dry? - from ProductTableRow
  product: {
    flexGrow: 1,
    minWidth: 250,
    whiteSpace: "normal",
    paddingRight: theme.spacing(1),
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },

  lg: {
    width: 300,
    minWidth: 300,
  },
  priceSelect: {
    width: 250,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceSelectBtn: {
    width: 194,
  },
  sm: {
    width: 200,
    minWidth: 200,
  },
}));
