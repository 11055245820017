import React from "react";
import { useTargetConfigsContext } from "../../context/TargetConfigsContext";
import Icon from "@mui/icons-material/Star";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { StatusChip } from "@akj-dev/shared-components";

interface UniqueReferenceNumberFieldProps {
  uniqueReferenceType: string;
}

export const UniqueReferenceNumberField = ({
  uniqueReferenceType,
}: UniqueReferenceNumberFieldProps) => {
  const { targetConfigs } = useTargetConfigsContext();

  if (targetConfigs.length > 1)
    return (
      <StatusChip
        type="info"
        title="Unique reference number cannot be bulk edited"
        message="Please edit configurations individually"
      />
    );

  const productType = uniqueReferenceType === "Line" ? "wlr" : "broadband";
  return (
    <Section
      title="Unique Reference Number"
      avatar={<Icon />}
      defaultExpanded={true}
    >
      <WlrBBDynamicField
        productType={productType}
        propertyName="partner_reference_number"
        margin
      />
    </Section>
  );
};
