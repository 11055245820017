import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectConfig } from "../../../../../store/universalProducts/actions";

import { UniversalProductsConfigForm } from "../../../components/UniversalProducts/ConfigForm";

export const ConfigFormContainer = () => {
  const dispatch = useDispatch();

  const configIndex = useSelector<any, number>(
    (state) => state.universalProducts.selectedConfigIndex
  );

  const goBack = () => dispatch(selectConfig(false));

  return (
    <UniversalProductsConfigForm goBack={goBack} configIndex={configIndex} />
  );
};
