import { DCContact, DCContactAddress } from "../../../api/v1/account";

/**
 * Convert a DC Contact address to a string
 * ....although unsure if all these fields are available. Different mix in different parts of DC
 */
export const dcContactToString = (address: DCContact) => {
  return `${[address.first_name, address.last_name]
    .filter(Boolean)
    .join(" ")} - ${[
    address.primary_address_building_number,
    address.primary_address_building,
    address.primary_address_street,
    address.primary_address_city,
    address.primary_address_state,
    address.primary_address_postalcode,
    address.primary_address_country,
  ]
    .filter(Boolean)
    .join(", ")}`;
};

/**
 * Convert a DC Account Address (i.e. previous delivery address history) to a string
 */
export const dcAccountAddressToString = (address: any) => {
  return [
    address.building,
    address.street,
    address.locale,
    address.post_town,
    address.region,
    address.postcode,
    address.country,
  ]
    .filter(Boolean)
    .join(", ");
};

/**
 * Get if a contact has a valid delivery address
 * Addresses fields are optional on contacts in DC, but not when they're copied
 * across to an OrderProduct call for delivery, hence we need to validate on the
 * FE
 *
 * See in DC:
 * /lib/acc4billing_app/lib/order_products.pm
 * sub has_installation_address
 *
 * Note this validation is duped in the components/CreateContact/ form but data
 * already exists that doesn't follow these rules.
 *
 * @param address
 */
export function isValidDeliveryContactAddress(address: DCContactAddress) {
  return !!(
    (address.primary_address_building ||
      address.primary_address_building_number) &&
    address.primary_address_city &&
    address.primary_address_postalcode &&
    address.primary_address_country
  );
}
