import { EventDefinition } from "redux-beacon";
import { trackEvent } from "@redux-beacon/google-analytics";

/**
 * Simple Event map element for Redux Beacon tracking.
 * @param actionType
 * @returns {{}}
 */
export const trackSimpleEvent = (
  actionType: string
): { [x: string]: EventDefinition } => ({
  [actionType]: trackEvent(
    () => ({
      category: actionType.split(".")[0],
      action: actionType,
    }),
    "gtm1"
  ),
});
