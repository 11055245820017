import React from "react";
import styled from "styled-components";
import _ from "lodash";

import { Spinner } from "../Spinner";

interface PriceMetaProps {
  priceType: "RECURRING" | "ONE_OFF";
  fetching: boolean;
  componentPrice: Record<string, any>;
}

export const PriceMeta = ({
  componentPrice,
  priceType,
  fetching,
}: PriceMetaProps) => {
  let data = [];

  if (priceType === "RECURRING") {
    const priceWithPromo =
      componentPrice.first_bill_recurring_price_with_promotions; // ....and discounts
    const priceWithoutPromo = componentPrice.recurring_price_without_promotions; // ....but still with discounts
    const priceWithoutDiscounts =
      componentPrice.recurring_price_without_discounts;
    data.push({
      title: "Recurring Price",
      value: `£${priceWithPromo}`,
      subValue:
        (priceWithPromo !== priceWithoutPromo ||
          priceWithoutPromo !== priceWithoutDiscounts) &&
        `£${priceWithoutDiscounts} without discount`,
    });

    if (componentPrice.promotion_id) {
      const promo = _.find(
        componentPrice.pricing_scheme_details,
        (s) => s.promotion_id === componentPrice.promotion_id
      );

      // A promo ID can exist, with no data on said promo, hence this.
      // API logic thing probably... This is around @robert.moore's non-dynamic product work for the platform.
      if (promo) {
        data.push({
          title: "Promotion",
          value: promo.promotion_name,
        });
        data.push({
          title: "Promotion Length",
          value: `${promo.promotion_length_in_months} months`,
        });
      }
    }
  }

  if (priceType === "ONE_OFF") {
    const priceWithPromo = componentPrice.one_off_price_with_promotions;
    const priceWithoutPromo = componentPrice.one_off_price_without_promotions;
    // Note: there's also one_off_price_without_discounts... which one is it?
    data.push({
      title: "One off price",
      value: `£${priceWithPromo}`,
      subValue:
        priceWithPromo !== priceWithoutPromo &&
        `£${priceWithoutPromo} without discount`,
    });
  }

  return (
    <Outer data-cy="PriceMeta">
      {fetching && (
        <Fetching>
          <Spinner color="white" />
        </Fetching>
      )}
      <table>
        <thead>
          <tr>
            {data.map((e) => (
              <th key={e.title}>{e.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {data.map((e) => (
              <td key={e.value}>
                {e.value}
                <span>{e.subValue}</span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </Outer>
  );
};

const Outer = styled.div`
  position: relative;
  background: ${(p) => p.theme.palette.info.main};
  border-radius: 5px;
  color: white;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead > tr {
    background: rgba(255, 255, 255, 0.25);
    text-align: left;
    font-size: 0.9em;
  }
  th,
  td {
    padding: 8px 10px;
  }
  th {
    font-weight: normal;
  }
  td {
    font-weight: bold;
  }
  span {
    opacity: 0.6;
    font-size: 0.9em;
    font-weight: normal;
    margin-left: 5px;
  }
`;

const Fetching = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
`;
