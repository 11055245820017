import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  getIsMultiLineProduct,
  getProducts,
} from "../../../../../store/wlrBroadband/selectors";
import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { MPFNoProductChangesRow } from "../../components/Resigns/MPFProductInstance/MPFNoProductChangesRow";
import { MPFProductChangesRow } from "../../components/Resigns/MPFProductInstance/MPFProductChangesRow";
import { BroadbandProductSelectorContainer } from "../../containers/Resigns/BroadbandProductSelector";
import { RowActionsContainer } from "../../containers/Resigns/RowActions";

import { MPFProductBaseProps } from "./model";
import { MPFBroadbandProductOverview } from "./MPFBroadbandProductOverview";
import { MPFLineProductOverview } from "./MPFLineProductOverview";

export const MPFProduct = ({
  configuration,
  isChecked,
  productInstance,
  setNoChanges,
  setChanges,
  toggleRow,
  removeResign,
}: MPFProductBaseProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const products = useSelector((state: any) =>
    getProducts(state, configuration.locationIndex)
  );

  const isMultiLineProduct = useSelector<any, boolean>((state) =>
    getIsMultiLineProduct(state, configuration.wlrProductId)
  );

  const onRemoveResign = () => {
    removeResign();
    setIsOpen(false);
  };

  const resignProduct = products.find(
    (product: any) => product.id === configuration.broadbandProductId
  );

  return (
    <>
      {configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
      resignProduct ? (
        <MPFProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          resignProduct={resignProduct}
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              removeResign={onRemoveResign}
              hasProductId={configuration.broadbandProductId}
              resignProduct={resignProduct}
            />
          }
        />
      ) : (
        <MPFNoProductChangesRow
          isChecked={isChecked}
          toggleRow={toggleRow}
          productInstance={productInstance}
          actions={
            <RowActionsContainer
              configuration={configuration}
              productInstance={productInstance}
              setChanges={(resignProductType: string) => {
                if (resignProductType === NEW_PRODUCT) {
                  setIsOpen((old) => !old);
                } else {
                  setChanges(SAME_PRODUCT_WITH_CHANGES);
                }
              }}
              setNoChanges={setNoChanges}
              removeResign={onRemoveResign}
            />
          }
        />
      )}
      {isOpen && (
        <>
          <MPFLineProductOverview
            configuration={configuration}
            productInstance={productInstance}
            allProducts={products}
            setChanges={() => setChanges(NEW_PRODUCT)}
            removeResign={onRemoveResign}
          />
          <MPFBroadbandProductOverview
            configuration={configuration}
            productInstance={productInstance}
            allProducts={products}
            isMultiLineProduct={isMultiLineProduct}
            setChanges={() => setChanges(NEW_PRODUCT)}
            removeResign={onRemoveResign}
          />
        </>
      )}
      {configuration.resignProductType === SAME_PRODUCT_WITH_CHANGES &&
        !configuration.broadbandProductId && (
          <BroadbandProductSelectorContainer
            configuration={configuration}
            productInstance={productInstance}
          />
        )}
    </>
  );
};
