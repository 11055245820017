import React from "react";
import { Chip } from "@mui/material";

type Props = {
  onDelete?: () => void;
  value: string;
};

export const FilterChip = ({ onDelete, value }: Props) => {
  return (
    <Chip onDelete={onDelete} size="small" sx={styles.root} label={value} />
  );
};

const styles = {
  root: {
    marginRight: 1,
    marginTop: 1,
  },
};
