import React from "react";

import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { IndexedCardInfo } from "@akj-dev/shared-components";

interface ShippingContainerProps {
  cardIndex: string;
  shipping: Record<string, any>;
  onShippingMethodChange: (newMethod: number) => void;
}

export const Shipping = ({
  cardIndex,
  shipping,
  onShippingMethodChange,
}: ShippingContainerProps) => {
  const createShippingName = (shipping: Record<string, any>) =>
    `${shipping.name} - £${shipping.price.one_off_price_with_promotions} + VAT`;

  const options = () => {
    let optionsArray = [
      {
        text: "Standard - Free",
        value: 0,
      },
    ];
    if (shipping.products)
      shipping.products.forEach((product: Record<string, any>) => {
        if (product.name === "Before 10:30AM Delivery by DPD") {
          optionsArray.push({
            text: createShippingName(product),
            value: product.id,
          });
        }
      });
    return optionsArray;
  };

  return (
    <IndexedCardInfo index={cardIndex} title="Shipping">
      {shipping.isFetchingShipping ? (
        <CircularProgress />
      ) : (
        <FormControl fullWidth>
          <InputLabel variant="standard">Choose shipping Method</InputLabel>
          <Select
            variant="standard"
            value={shipping.chosenProductId}
            onChange={(event) =>
              onShippingMethodChange(event.target.value as number)
            }
          >
            {options().map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </IndexedCardInfo>
  );
};
