import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as WlrBroadbandAPI from "../../../api/v1/wlrBroadband";
import {
  hasProductInstanceAddress,
  hasLocationAddress,
} from "../../../shared/utils/addresses";
import { getAccountSettings } from "../../account/selectors";
import { getAccountId } from "../../order/selectors";
import {
  RESIGN,
  SAME_PRODUCT_WITH_CHANGES,
  NEW_PRODUCT,
  BROADBAND_ONLY,
  NEW_FTTP,
  TRANSFER,
  NEW_SOGEA,
} from "../constants";
import {
  getProductInstanceAddressForCli,
  getProductInstances,
} from "../selectors";
import {
  doBroadbandSearchForResign,
  fetchLineAvailabilityForResign,
} from "./resigns";

export const addLocation = createAction("wlrBroadband/addLocation");
export const removeLocation = createAction<number>(
  "wlrBroadband/removeLocation"
);
export const removeProductsForLocation = createAction<number>(
  "wlrBroadband/removeProductsForLocation"
);

export const setLocationType = createAction(
  "wlrBroadband/setLocationType",
  (index: number, type: string) => ({
    payload: { index, type },
  })
);

export const setLocationAddress = createAction(
  "wlrBroadband/setLocationAddress",
  (index: number, address: any, isResign: boolean = false) => ({
    payload: { index, address, isResign },
  })
);

export const setLocationCli = createAction(
  "wlrBroadband/setLocationCli",
  (index: number, cli: string) => ({
    payload: { index, cli },
  })
);

export const toggleSogeaTermsConfirmation = createAction<number>(
  "wlrBroadband/toggleSogeaTermsConfirmation"
);

export const addResignLocation = createAction<any>(
  "wlrBroadband/addResignLocation"
);

export const removeResignLocation = createAction<number>(
  "wlrBroadband/removeResignLocation"
);

export const addResignLocationWithSearches = createAsyncThunk(
  "wlrBroadband/addResignLocationWithSearches",
  async (
    { productInstance, resignProductType }: any,
    { getState, dispatch }
  ) => {
    const state: any = getState();
    const { pin } = productInstance;
    const index = state.wlrBroadband.locations.findIndex(
      (location: any) => location.type === RESIGN && location.cli.value === pin
    );
    const location = state.wlrBroadband.locations[index];
    if (index === -1) {
      let instance = productInstance;

      if (!hasProductInstanceAddress(productInstance)) {
        //@ts-ignore
        const address = getProductInstanceAddressForCli(state, pin);
        if (address) {
          instance = {
            ...productInstance,
            address,
          };
        }
      }
      dispatch(addResignLocation(instance));
    }
    if (
      resignProductType === SAME_PRODUCT_WITH_CHANGES ||
      resignProductType === NEW_PRODUCT
    ) {
      const hasPostcode =
        hasLocationAddress({ location, isPostcodeOnly: true }) ||
        hasProductInstanceAddress(productInstance, true);
      if (!hasPostcode) {
        dispatch(fetchTagsCheck(pin));
      }

      if (
        resignProductType === NEW_PRODUCT &&
        (hasLocationAddress({ location }) ||
          hasProductInstanceAddress(productInstance))
      ) {
        dispatch(fetchLineAvailabilityForResign(productInstance));
        dispatch(doBroadbandSearchForResign(productInstance));
      }

      const productInstances = getProductInstances(state);
      if (
        resignProductType === SAME_PRODUCT_WITH_CHANGES &&
        productInstance.type === "wlr" &&
        productInstances.filter((item: any) => item.pin === productInstance.pin)
          .length === 1
      ) {
        dispatch(doBroadbandSearchForResign(productInstance));
      }
    }
  }
);

export const fetchLineAvailability = createAsyncThunk(
  "wlrBroadband/fetchLineAvailability",
  async (locationIndex: number, { getState, rejectWithValue }) => {
    const state: any = getState();
    const address = state.wlrBroadband.locations[locationIndex].address;
    const account = getAccountId(state);

    if (!address.cssDatabaseCode || !address.addressReference) {
      rejectWithValue({
        status: "error",
        message: "Please choose an address first.",
      });
    }
    return await WlrBroadbandAPI.lineAvailability(
      address.cssDatabaseCode,
      address.addressReference,
      account
    );
  }
);

export const fetchInstallationDetails = createAsyncThunk(
  "wlrBroadband/fetchInstallationDetails",
  async (locationIndex: number, { getState, dispatch, rejectWithValue }) => {
    const state: any = getState();
    const location = state.wlrBroadband.locations[locationIndex];
    const account = getAccountId(state);

    if (!location.address.postcode || !location.cli) {
      rejectWithValue({
        status: "error",
        message: "Please choose an address and CLI first.",
      });
    }

    const response = await WlrBroadbandAPI.installationDetails(
      location.address.postcode,
      location.cli.value,
      null,
      account
    );

    if (
      response.message ===
        "There was a problem accessing the service: Telephone number is not a working line. This indicates either the line has ceased or it has been exported to another operator (e.g. Virgin Media)." ||
      response.message ===
        "Directory Number entered not present. This means the number is not on the BT network (e.g. a Virgin Media line)." ||
      // We need line access id for new sogea connections for voice service. #tp130251
      location.type === NEW_SOGEA
    ) {
      dispatch(validateWorkingLineTakeOver(locationIndex));
    }

    return response;
  }
);

export const validateWorkingLineTakeOver = createAsyncThunk(
  "wlrBroadband/validateWorkingLineTakeOver",
  async (locationIndex: number, { getState, dispatch }) => {
    const state: any = getState();
    const location = state.wlrBroadband.locations[locationIndex];
    const response = await WlrBroadbandAPI.validateWorkingLineTakeOver(
      location.cli.value,
      location.address.addressReference,
      location.address.cssDatabaseCode,
      getAccountId(state),
      true
    );
    // @hack - run post fulfillment
    setTimeout(() => {
      if (getAccountSettings(state).dws_reseller_enabled !== "1") {
        dispatch(doBroadbandSearch({ locationIndex, noCli: true }));
      }
    });
    return response;
  }
);

export const doBroadbandSearch = createAsyncThunk(
  "wlrBroadband/doBroadbandSearch",
  async ({ locationIndex, noCli = false }: any, { getState }) => {
    const state: any = getState();
    const location = state.wlrBroadband.locations[locationIndex];
    const response = await WlrBroadbandAPI.broadbandSearch(
      state.order.accountId,
      location.address.addressReference,
      location.address.cssDatabaseCode,
      location.address.postcode,
      (location.type === TRANSFER ||
        location.type === BROADBAND_ONLY ||
        location.type === NEW_FTTP) &&
        !noCli &&
        location.cli.value,
      state.wlrBroadband.contractLength
    );
    return response;
  }
);

export const fetchAvailableNumbers = createAsyncThunk(
  "wlrBroadband/fetchAvailableNumbers",
  async (locationIndex: number, { getState }) => {
    const state: any = getState();
    const location = state.wlrBroadband.locations[locationIndex];
    const account = getAccountId(state);
    return await WlrBroadbandAPI.findNumbersAtAddress(
      location.address.addressReference,
      location.address.cssDatabaseCode,
      account
    );
  }
);

export const fetchTagsCheck = createAsyncThunk(
  "wlrBroadband/fetchTagsCheck",
  async (pin: any, { getState, dispatch }) => {
    const state: any = getState();
    const accountId = getAccountId(state);
    const response = await WlrBroadbandAPI.getTagsCheck(accountId, pin);
    // @todo - setLocationAddress()
    return response;
  }
);
