export function getIndividualTotalPrice(products: any[]) {
  let price = 0;
  products.forEach((product) => {
    if (product?.pricing?.product)
      price += parseFloat(
        product.pricing.product.promotion_id
          ? product.pricing.product.recurring_price_without_promotions
          : product.pricing.product.first_bill_recurring_price_with_promotions
      );
  });
  return price.toFixed(2);
}
