import React from "react";
import _ from "lodash";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { ERROR } from "../../../../shared/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertItem = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  .icon {
    width: 40px;
    height: 24px;
    flex-shrink: 0;
  }
`;

interface AlertMessagesProps {
  alertMessages: { id: number; message: string; type: string; title: string }[];
  removeAlertMessages: () => void;
}

export const AlertMessages = ({
  alertMessages,
  removeAlertMessages,
}: AlertMessagesProps) => {
  const title = _.uniq(alertMessages.map((m) => m.title)).join(" / ");

  return (
    <Dialog
      open={alertMessages.length > 0}
      onClose={removeAlertMessages}
      TransitionComponent={Transition}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {alertMessages.map((alert, key) => (
          <AlertItem key={alert.id}>
            <div className="icon">
              {alert.type === ERROR ? (
                <ErrorIcon color="error" />
              ) : (
                <InfoIcon color="secondary" />
              )}
            </div>
            <div dangerouslySetInnerHTML={{ __html: alert.message }} />
          </AlertItem>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={removeAlertMessages}
          data-cy="AlertMessageConfirm"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
