import React from "react";
import Icon from "@mui/icons-material/Redeem";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

export const CallBundlesWlrChange = () => (
  <Section
    title="Call Bundles (Existing Line)"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.fixed_line_call_bundle_id"
    />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.mobile_call_bundle_id"
    />
  </Section>
);
