import { PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";

import {
  requestMobileProductData,
  requestMobilePricingData,
  fetchCliBoltOnProducts,
  updateConfigProperty,
  arrayUpdateConfigProperty,
  setMobileProductDiscount,
  setDeliveryAddressType,
  setDeliveryAddressId,
  removeConfig,
  clearConfig,
  toggleBillCapConfirmation,
  toggleDwsTermsConfirmation,
  resetPacState,
  resetStacState,
  setAdditionalBundle,
  chooseCliBoltOn,
  fetchCliTopUpBoltOnProducts,
} from "../actions";
import { getMinimumPortDate } from "../selectors/productConfig";

import { DC_DATE_FORMAT } from "../../../shared/utils/date";
import { NEW_SIM, PRE_DISPATCHED_SIM } from "../constants";

interface ArrayUpdateConfigPropertyPayload {
  propertyName: string;
  values: string | string[];
  configIds: number[];
}

export const productConfigExtraReducers = (builder: any) => {
  builder
    .addCase(requestMobileProductData.pending, (state: any, action: any) => {
      const requestConfigs = state.configs.filter(
        (c: any) => c.productId === action.meta.arg
      );
      for (const config of requestConfigs) {
        const configId = state.configs.findIndex((c: any) => c === config);
        state.configs[configId] = {
          ...state.configs[configId],
          productData: {
            fetching: true,
            response: false,
          },
        };
        state.productData[action.meta.arg] = {
          fetching: true,
          response: false,
        };
      }
    })
    .addCase(requestMobileProductData.fulfilled, (state: any, action: any) => {
      const product_id = action.meta.arg;

      const receiveConfigs = state.configs.filter(
        (c: any) => c.productId === product_id
      );
      receiveConfigs.forEach((config: any) => {
        if (config.productId === product_id) {
          if (!config.productData) {
            config.productData = { fetching: false, response: null };
          }
          config.productData.fetching = false;
          config.productData.response = action.payload;
        }
      });

      if (!state.productData[product_id]) {
        state.productData[product_id] = { fetching: false, response: null };
      }
      state.productData[product_id].fetching = false;
      state.productData[product_id].response = action.payload;
    })
    .addCase(requestMobilePricingData.pending, (state: any, action: any) => {
      for (const id of action.meta.arg.configIds) {
        const productId = state.configs[id].productId;

        if (state.configs[id]) {
          state.configs[id].productData.fetching = true;
        }

        if (state.productData[productId]) {
          state.productData[productId].fetching = true;
        }
      }
    })
    .addCase(requestMobilePricingData.fulfilled, (state: any, action: any) => {
      action.payload.configIds.forEach((configId: number, i: number) => {
        const response = action.payload.responses[i];
        const productId = state.configs[configId].productId;

        state.configs[configId] = {
          ...state.configs[configId],
          productData: {
            fetching: false,
            response: response,
          },
        };

        state.productData[productId] = {
          ...state.productData[productId],
          fetching: false,
          response: response,
        };
      });
    })
    .addCase(fetchCliBoltOnProducts.pending, (state: any, action: any) => {
      const productId = action.meta.arg;
      if (!state.cliBoltOnSearch[productId]) {
        state.cliBoltOnSearch[productId] = {};
      }

      state.cliBoltOnSearch[productId].fetching = true;
      state.cliBoltOnSearch[productId].response = null;
    })
    .addCase(fetchCliBoltOnProducts.fulfilled, (state: any, action: any) => {
      const productId = action.meta.arg;
      if (!state.cliBoltOnSearch[productId]) {
        state.cliBoltOnSearch[productId] = {};
      }
      state.cliBoltOnSearch[productId].fetching = false;
      state.cliBoltOnSearch[productId].response = action.payload;
    })
    .addCase(fetchCliTopUpBoltOnProducts.pending, (state: any, action: any) => {
      const { productId } = action.meta.arg;
      if (!state.cliBoltOnSearch[productId]) {
        state.cliBoltOnSearch[productId] = {};
      }
      state.cliBoltOnSearch[productId].fetching = true;
    })
    .addCase(
      fetchCliTopUpBoltOnProducts.fulfilled,
      (state: any, action: any) => {
        const { productId, response } = action.payload;
        // Stop this blowing up when the request fails.
        // TODO: Yes this should probably have proper error handling, but it didn't in the first place so....
        if (!response || !response.products) return;

        if (!state.cliBoltOnSearch[productId]) {
          state.cliBoltOnSearch[productId] = {
            response: { products: [] },
          };
        }

        if (!state.cliBoltOnSearch[productId].response.products) {
          state.cliBoltOnSearch[productId].response.products = [];
        }

        state.cliBoltOnSearch[productId].response.products.push(
          ...response.products
        );
        state.cliBoltOnSearch[productId].fetching = false;
      }
    )
    .addCase(
      updateConfigProperty,
      (
        state: any,
        action: PayloadAction<{
          propertyName: string;
          value: any;
          configIds: number[];
          isVfDirect?: boolean;
        }>
      ) => {
        const { propertyName, value, configIds, isVfDirect } = action.payload;

        configIds.forEach((id) => {
          const config = state.configs[id];
          if (!config) return;

          // Extra logic stuff depending on the field:
          // Currently just port date.
          // We auto-populate that with different lead times depending on what's going on with the SIM.
          let port_date;
          let additional_properties = {};

          switch (propertyName) {
            case "is_sim_required":
              port_date = format(
                getMinimumPortDate(
                  value === "1" ? NEW_SIM : PRE_DISPATCHED_SIM,
                  !isVfDirect
                ),
                DC_DATE_FORMAT
              );
              break;

            case "acquisition_method":
              if (value === "port/mig") {
                additional_properties = {
                  stac: null,
                  old_mobile_number: null,
                  stac_expiry_date: null,
                  activation_date: null,
                };
                const { properties } = config;
                port_date = format(
                  getMinimumPortDate(
                    properties.sim_is_buffer === "1" ||
                      properties.is_sim_required === "0"
                      ? PRE_DISPATCHED_SIM
                      : NEW_SIM,
                    !isVfDirect
                  ),
                  DC_DATE_FORMAT
                );
              } else {
                additional_properties = {
                  pac: null,
                  mobile_number: null,
                  pac_expiry_date: null,
                  port_date: undefined,
                  activation_date: format(new Date(), DC_DATE_FORMAT),
                };
              }
              break;
            case "sim_is_buffer":
              port_date = format(
                getMinimumPortDate(
                  value === "1" ? PRE_DISPATCHED_SIM : NEW_SIM,
                  !isVfDirect
                ),
                DC_DATE_FORMAT
              );
              break;
            case "sim_type":
              additional_properties = {
                sim_is_buffer: value === "esim" ? "0" : "1",
              };
              break;

            default:
              break;
          }

          // don't update service_visual_voicemail for non-o2 products
          if (
            propertyName !== "service_visual_voicemail" ||
            config.productData.response.mobile.product_component_data
              .supplier === "O2"
          ) {
            config.properties = {
              ...config.properties,
              [propertyName]: value,
              ...(port_date && { port_date }),
              ...additional_properties,
            };
          }
        });
      }
    )
    // Apply an array of values to multiple mobile configurations.
    // For the PAC & Mobile No. bulk entry requirements
    .addCase(
      arrayUpdateConfigProperty,
      (state: any, action: PayloadAction<ArrayUpdateConfigPropertyPayload>) => {
        let i = 0;
        const { values, configIds, propertyName } = action.payload;
        for (const id of configIds) {
          if (state.configs[id]) {
            state.configs[id].properties = {
              ...state.configs[id].properties,
              [propertyName]: values[i]
                ? values[i].replace(/,/, "").trimStart()
                : values[i],
            };
            if (i < values.length - 1) {
              i++;
            } else {
              break;
            }
          }
        }
      }
    )
    // Apply product discounts to multiple configurations
    // Similar to the one in src/js/store/wlrBroadband/reducer/actions/configurations.js
    // TODO: Perhaps we can unify this.
    .addCase(
      setMobileProductDiscount,
      (
        state: any,
        action: PayloadAction<{
          configIds: number[];
          discountType: string | null;
          value: number | null;
        }>
      ) => {
        const { configIds, discountType, value } = action.payload;
        const productDiscountUpdate: { [key: number]: any } = {};

        configIds.forEach((i) => {
          const minPrice =
            state.configs[i]?.productData?.response?.mobile?.component_price
              ?.minimum_recurring_price || 0;
          const isValidPrice =
            !value || value >= parseFloat(minPrice as string);
          productDiscountUpdate[i] = {
            properties: {
              ...(discountType !== null && {
                recurring_discount_type: discountType,
              }),
              ...((discountType === "DiscountPercentage" || isValidPrice) && {
                recurring_discount_override: !value ? 0 : 1,
                recurring_discount_value: value,
              }),
            },
          };
        });

        // Merge productDiscountUpdate into the state
        for (const [key, update] of Object.entries(productDiscountUpdate)) {
          if (state.configs[Number(key)]) {
            state.configs[Number(key)].properties = {
              ...state.configs[Number(key)].properties,
              ...update.properties,
            };
          } else {
            state.configs[Number(key)] = update;
          }
        }
      }
    )
    .addCase(
      setDeliveryAddressType,
      (
        state: any,
        action: PayloadAction<{ configIndexes: any; addressType: string }>
      ) => {
        action.payload.configIndexes.forEach((index: any) => {
          state.configs[index].delivery = {
            ...state.configs[index].delivery,
            addressType: action.payload.addressType,
          };
        });
      }
    )
    .addCase(
      setDeliveryAddressId,
      (
        state: any,
        action: PayloadAction<{ configIndexes: any; addressId: string }>
      ) => {
        action.payload.configIndexes.forEach((index: any) => {
          if (!state.configs[index]) {
            state.configs[index] = {};
          }
          state.configs[index].delivery = {
            ...state.configs[index].delivery,
            addressId: action.payload.addressId,
          };
        });
      }
    );
  builder
    .addCase(
      chooseCliBoltOn,
      (
        state: any,
        action: PayloadAction<{
          configId: number;
          boltOnType: string | undefined;
          boltOnId: string | number;
          slotId?: string;
        }>
      ) => {
        const { configId, slotId, boltOnType, boltOnId } = action.payload;
        const key = slotId || boltOnType;

        if (!boltOnId) {
          if (state.configs[configId]?.selectedCliBoltOns) {
            key && delete state.configs[configId].selectedCliBoltOns[key];
          }
        } else {
          state.configs[configId] = {
            ...state.configs[configId],
            selectedCliBoltOns: {
              ...state.configs[configId].selectedCliBoltOns,
              ...(key && { [key]: { id: boltOnId, type: boltOnType } }),
            },
          };
        }
      }
    )
    .addCase(
      removeConfig.fulfilled,
      (state: any, action: PayloadAction<string[]>) => {
        const configIndexes = action.payload;

        state.configs = state.configs.filter(
          (_: any, index: number) => !configIndexes.includes(index.toString())
        );
      }
    )
    .addCase(clearConfig, (state: any) => {
      state.configs = [];
    })
    // Converted this but can't find any reference to TOGGLE_RESERVED_NUMBERS
    // addCase(toggleReservedNumbers, (state:any) => {
    //   state.showReservedNumbers = !state.showReservedNumbers;
    // })
    .addCase(toggleBillCapConfirmation, (state: any) => {
      state.billCapConfirmed = !state.billCapConfirmed;
    })
    .addCase(toggleDwsTermsConfirmation, (state: any) => {
      state.dwsTermsAccepted = !state.dwsTermsAccepted;
    })
    .addCase(
      resetPacState,
      (state: any, action: PayloadAction<{ configId: number }>) => {
        const { configId } = action.payload;
        state.configs[configId].properties = {
          ...state.configs[configId].properties,
          pac_expiry_date: null,
        };
        state.configs[configId].pacCodeCheck = {
          fetching: false,
          response: {},
          error: false,
        };
      }
    )
    .addCase(
      resetStacState,
      (state: any, action: PayloadAction<{ configId: number }>) => {
        const { configId } = action.payload;
        state.configs[configId].properties = {
          ...state.configs[configId].properties,
          stac_expiry_date: null,
        };
        state.configs[configId].stacCodeCheck = {
          fetching: false,
          response: {},
          error: false,
        };
      }
    )
    .addCase(
      setAdditionalBundle,
      (
        state: any,
        action: PayloadAction<{ bundleId: string; configId: number }>
      ) => {
        const { bundleId, configId } = action.payload;
        if (!state.configs[configId]) {
          state.configs[configId] = {};
        }
        state.configs[configId].additionalBundle = bundleId;
      }
    );
};
