import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { rebookExpiredReservations } from "../../../store/order/actions";
import { StatusChip } from "@akj-dev/shared-components";
import _ from "lodash";

interface RebookExpiredReservationsProps {}

export const RebookExpiredReservations: React.FC<
  RebookExpiredReservationsProps
> = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(
    (state: any) => state.order.rebookExpiredReservations.fetching
  );
  const response = useSelector(
    (state: any) => state.order.rebookExpiredReservations.response
  );
  const orderStatus = useSelector((state: any) => state.order.orderStatus);
  const reasonsNotConfigured = _.get(
    orderStatus.response,
    "data.reasons_not_configured"
  );

  const reasonNotConfigured =
    reasonsNotConfigured?.no_expired_line_appointments ||
    reasonsNotConfigured?.no_expired_broadband_appointments;
  if (reasonNotConfigured) {
    if (fetching)
      return (
        <Box ml={1}>
          <CircularProgress color="inherit" />
        </Box>
      );
    if (response.status === "success") {
      return (
        <Box my={1}>
          <StatusChip
            type="success"
            title="Success"
            message="Expired appointments have been successfully rebooked."
          />
        </Box>
      );
    }
    if (response.status === "error")
      return (
        <Box my={1}>
          <StatusChip
            type="error"
            title="Rebooking appointment error"
            message={response.message}
            retry={() => dispatch(rebookExpiredReservations())}
          />
        </Box>
      );
    return (
      <Box my={1}>
        <StatusChip
          type="error"
          message={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {Object.keys(reasonNotConfigured).map((key) => (
                <Box key={key} mr={1}>
                  {reasonNotConfigured[key]}
                </Box>
              ))}{" "}
              <Box>
                <Button
                  color="inherit"
                  variant="outlined"
                  endIcon={<CalendarToday />}
                  onClick={() => dispatch(rebookExpiredReservations())}
                >
                  Rebook
                </Button>
              </Box>
            </Box>
          }
        />
      </Box>
    );
  }
  return null;
};
