import React, { useState, MouseEvent } from "react";
import styled from "styled-components";

import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import GroupIcon from "@mui/icons-material/Group";
import RedeemIcon from "@mui/icons-material/Redeem";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PublicIcon from "@mui/icons-material/Public";
import { useTheme } from "@mui/material/styles";

import {
  getAddressLine,
  mapAndFormatCallBundleOrSelectServices,
  mapToLocationAddress,
} from "../../../../../shared/utils/addresses";
import { useBroadbandLocationByPin } from "../../hooks/useBroadbandLocation";

const Row = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const Name = styled.div`
  font-weight: bold;
  margin-left: 8px;
`;

const Description = styled.div`
  margin-left: 8px;
`;

const Price = styled.div`
  border: 1px solid ${(p) => p.theme.palette.success.main};
  border-radius: 5px;
  color: ${(p) => p.theme.palette.success.main};
  font-weight: bold;
  font-size: 12px;
  margin-left: 8px;
  padding: 5px;
`;

interface MoreInformationPopoverProps {
  productInstance: Record<string, any>;
}

export const MoreInformationPopover = ({
  productInstance: { address, line_details, specials, pin },
}: MoreInformationPopoverProps) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const location = useBroadbandLocationByPin(pin);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  const callBundle =
    line_details?.call_bundle &&
    mapAndFormatCallBundleOrSelectServices(line_details.call_bundle);
  const selectServices =
    line_details?.select_services &&
    mapAndFormatCallBundleOrSelectServices(line_details.select_services);
  const hasSpecialRates = specials?.tariffs || specials?.numbers;
  const id = isOpen ? "moreInformationPopover" : undefined;

  return (
    <>
      <IconButton
        color="primary"
        aria-describedby={id}
        onClick={handleClick}
        size="large"
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleRequestClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box m={1}>
          {address && (
            <Row>
              <HomeIcon style={{ color: theme.palette.text.disabled }} />
              <Name>Address</Name>
              <Description>
                {getAddressLine(
                  location ? location.address : mapToLocationAddress(address)
                ) || "None"}
              </Description>
            </Row>
          )}
          {line_details && (
            <>
              {line_details.call_tariff && (
                <Row>
                  <SettingsPhoneIcon
                    style={{ color: theme.palette.text.disabled }}
                  />
                  <Name>Call Tariff</Name>
                  <Description>
                    {line_details.call_tariff.service_description || "None"}
                  </Description>
                  {line_details.call_tariff.price && (
                    <Price>
                      &pound;
                      {parseFloat(line_details.call_tariff.price).toFixed(2)}
                    </Price>
                  )}
                </Row>
              )}
              {line_details.care_level && (
                <Row>
                  <GroupIcon style={{ color: theme.palette.text.disabled }} />
                  <Name>Care Level</Name>
                  <Description>
                    {line_details.care_level.service_description || "None"}
                  </Description>
                  {line_details.care_level.price && (
                    <Price>
                      &pound;
                      {parseFloat(line_details.care_level.price).toFixed(2)}
                    </Price>
                  )}
                </Row>
              )}
              {line_details.bb_service && (
                <Row>
                  <PublicIcon style={{ color: theme.palette.text.disabled }} />
                  <Name>Broadband Rental</Name>
                  <Description>
                    {line_details.bb_service.service_description || "None"}
                  </Description>
                  {line_details.bb_service.price && (
                    <Price>
                      &pound;
                      {parseFloat(line_details.bb_service.price).toFixed(2)}
                    </Price>
                  )}
                </Row>
              )}
              {callBundle && (
                <Row>
                  <RedeemIcon style={{ color: theme.palette.text.disabled }} />
                  <Name>Call Bundle</Name>
                  <Description>{callBundle.description || "None"}</Description>
                  {callBundle.price && (
                    <Price>
                      &pound;{parseFloat(callBundle.price).toFixed(2)}
                    </Price>
                  )}
                </Row>
              )}
              {selectServices && (
                <Row>
                  <RedeemIcon style={{ color: theme.palette.text.disabled }} />
                  <Name>Calling Features</Name>
                  <Description>
                    {selectServices.description || "None"}
                  </Description>
                  {selectServices.price && (
                    <Price>
                      &pound;{parseFloat(selectServices.price).toFixed(2)}
                    </Price>
                  )}
                </Row>
              )}
            </>
          )}
          <Row>
            <InsertChartIcon style={{ color: theme.palette.text.disabled }} />
            <Name>Special Rates</Name>
            {hasSpecialRates ? (
              <CheckCircleIcon
                style={{ color: theme.palette.success.main, marginLeft: 8 }}
              />
            ) : (
              <CancelIcon
                style={{ color: theme.palette.error.main, marginLeft: 8 }}
              />
            )}
          </Row>
        </Box>
      </Popover>
    </>
  );
};
