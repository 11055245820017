import React from "react";
import { Alert } from "@akj-dev/shared-components";
import { Box } from "@mui/material";

export const ApprovalRequiredInfoMessage = () => (
  <Box mt={1} mb={1}>
    <Alert
      type="warning"
      message="Your order requires approval. Please proceed with generating an E-Sign Contract. The contract will only be sent to the customer once the order has been approved"
    />
  </Box>
);
