import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CommissionType,
  MobileConfigTableData,
  MobileProductConfig,
  TypeOfConnection,
} from "../../../../../shared/types/MobileProductConfig";
import { MobileConfigTable } from "../../../components/Mobile/MobileConfigTable";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import {
  removeConfig,
  updateConfigProperty,
} from "../../../../../store/mobile/actions";
import { validateAllConfigProperties } from "../../../../../store/mobile/actions";
import { transformMobileConfig } from "./helpers";
import { CircularProgress } from "@mui/material";
import { useMobileConfigTableDisplayPrefs } from "../../../../../shared/hooks/useMobileConfigTableDisplayPrefs";
import { useCanAccessVfDirect } from "../../../../../shared/hooks/useCanAccessVfDirect";

export const MobileConfigTableContainer = () => {
  const { setTargetConfigs } = useTargetConfigsContext();
  const canAccessVFDirect = useCanAccessVfDirect();

  const dispatch = useDispatch();
  const configs = useSelector((state: any) => state.mobile.configs);
  const productData = useSelector((state: any) => state.mobile.productData);
  const contractLength = useSelector(
    (state: any) => state.mobile.contractLengthInMonthsAllProducts
  );
  const productInstances = useSelector(
    (state: any) => state.mobile.productInstances
  );
  const cliBoltOnSearch = useSelector(
    (state: any) => state.mobile.cliBoltOnSearch
  );

  const displayPrefs = useMobileConfigTableDisplayPrefs(true);

  const fetching = useMemo(() => {
    let fetching = false;
    Object.entries(productData).forEach((e: any) => {
      if (e[1].fetching) fetching = true;
    });
    if (productInstances.fetching) fetching = true;
    Object.entries(cliBoltOnSearch).forEach((e: any) => {
      if (e[1].fetching) fetching = true;
    });

    return fetching;
  }, [productData, cliBoltOnSearch, productInstances]);

  // Form check when product data is received
  useEffect(() => {
    if (Object.keys(productData).length && !fetching)
      dispatch(validateAllConfigProperties(canAccessVFDirect));
  }, [productData, fetching, dispatch, canAccessVFDirect]);

  /**
   * Build table display data
   * This would be better achieved lower down in the store itself and with some modifications to DC responses, but there
   * are too many dependencies for that to be practical within the time / budget we have for now.
   */
  const groupedConfigs = useMemo(() => {
    let result: MobileConfigTableData = {};
    configs.forEach((config: MobileProductConfig, index: number) => {
      // Product data related to this config
      const configProductData = config.productData?.response || {};

      // Resign product instance for this config
      const resign = config.resignId
        ? productInstances.response.results?.find(
            (r: { id: string }) => r.id === config.resignId
          )
        : {};

      // Resign without change? This means lots of exceptions to the rules
      const isResignWithoutChanges =
        config.resignType === TypeOfConnection.RESIGN_WITHOUT_CHANGES;

      // Group configs by product ID
      result[config.productId] = {
        name: configProductData.product_name || resign.name_for_resign,
        network: isResignWithoutChanges
          ? resign.network.name
          : configProductData.mobile?.product_component_data.supplier,
        contractLength: contractLength,

        // Properties that can be different per config
        configs: {
          ...result[config.productId]?.configs,
          [index]: transformMobileConfig(
            config,
            productInstances,
            cliBoltOnSearch
          ),
        },
      };
    });
    return result;
  }, [configs, cliBoltOnSearch, productInstances, contractLength]);

  const handleEditConfigs = (configIndexes: string[]) => {
    setTargetConfigs(configIndexes.map((c) => parseInt(c)));
  };

  const handleDeleteConfigs = (configIndexes: string[]) => {
    dispatch(removeConfig({ configIds: configIndexes }));
  };

  const handleCommissionSelect = (configIndexes: string[]) => {
    const configIds = configIndexes.map((index) => parseInt(index, 10));

    dispatch(
      updateConfigProperty({
        propertyName: "commission_type",
        value:
          configs[configIds[0]].properties.commission_type ===
          CommissionType.RESIDUAL
            ? CommissionType.UPFRONT
            : CommissionType.RESIDUAL,
        configIds,
      })
    );
  };

  if (fetching) return <CircularProgress />;

  return (
    <MobileConfigTable
      displayPrefs={displayPrefs}
      data={groupedConfigs}
      onEditConfigs={handleEditConfigs}
      onDeleteConfigs={handleDeleteConfigs}
      onCommissionSelect={handleCommissionSelect}
    />
  );
};
