export enum EthernetTypeEnum {
  ETHERNET_PRODUCT_MANAGED = "ETHERNET_PRODUCT_MANAGED",
  ETHERNET_PRODUCT_EFM = "ETHERNET_PRODUCT_EFM",
  ETHERNET_PRODUCT_GEA = "ETHERNET_PRODUCT_GEA",
  ETHERNET_PRODUCT_PTP = "ETHERNET_PRODUCT_PTP",
}

export const ethernetProductSpec: { [key: string]: any } = {
  [EthernetTypeEnum.ETHERNET_PRODUCT_MANAGED]: {
    product: "Managed Interconnect",
    site_b_type: "Ethernet",
  },
  [EthernetTypeEnum.ETHERNET_PRODUCT_EFM]: {
    product: "Managed Interconnect",
    site_b_type: "EFM",
  },
  [EthernetTypeEnum.ETHERNET_PRODUCT_GEA]: {
    product: "Managed Interconnect",
    site_b_type: "GEA",
  },
  [EthernetTypeEnum.ETHERNET_PRODUCT_PTP]: {
    product: "Point to Point",
    site_b_type: "Ethernet",
  },
};
