import React from "react";
import _ from "lodash";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { SmallStatus } from "../../../../../shared/components/SmallStatus";
import {
  ALB_NOT_REQUIRED,
  KEEP_CURRENT_ALB,
  REMOVE_EXISTING_ALB,
} from "../../../../../store/mobile/constants";

interface BoltOnSelectFieldProps {
  selectedBoltOn: string;
  existingBoltOn: Record<string, any>;
  availableBoltOns: Record<string, any>[];
  onChangeBoltOn: (event: SelectChangeEvent<string>) => void;
}

const BoltOnStatus = ({
  selectedBoltOn,
  existingBoltOn,
}: Pick<BoltOnSelectFieldProps, "existingBoltOn" | "selectedBoltOn">) => {
  if (selectedBoltOn === REMOVE_EXISTING_ALB)
    return (
      <SmallStatus type="error">
        {existingBoltOn.description} will be removed
      </SmallStatus>
    );

  if (selectedBoltOn !== KEEP_CURRENT_ALB)
    return (
      <SmallStatus type="info">
        {existingBoltOn.description} will be replaced
      </SmallStatus>
    );

  return null;
};

export const BoltOnSelectField = ({
  selectedBoltOn,
  existingBoltOn,
  availableBoltOns,
  onChangeBoltOn,
}: BoltOnSelectFieldProps) => (
  <div>
    <FormControl variant="outlined" fullWidth>
      <Select onChange={onChangeBoltOn} value={selectedBoltOn}>
        {existingBoltOn && (
          <MenuItem value={KEEP_CURRENT_ALB}>
            <span>
              <b>Current bolt on:</b> {existingBoltOn.description}
            </span>
          </MenuItem>
        )}
        <MenuItem
          value={existingBoltOn ? REMOVE_EXISTING_ALB : ALB_NOT_REQUIRED}
        >
          Not Required
        </MenuItem>
        {availableBoltOns.map((product) => {
          const price = _.get(
            product,
            ["price", "first_bill_recurring_price_with_promotions"],
            false
          );
          return (
            <MenuItem value={product.id} key={product.id}>
              {product.name + (price ? " - £" + price : "")}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>

    {existingBoltOn && (
      <BoltOnStatus
        selectedBoltOn={selectedBoltOn}
        existingBoltOn={existingBoltOn}
      />
    )}
  </div>
);
