import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfigProperty } from "../../../../../../../store/mobile/actions";
import { ResellerBillingNotification } from "../../../../../components/Mobile/ResellerBillingNotification";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";

export const ResellerBillingNotificationContainer = () => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();

  const { bill_limit, additional_notification } = useSelector(
    (state: any) => state.mobile.configs
  )[targetConfigs[0]]?.properties;

  useEffect(() => {
    let addNotif, preference;
    if (bill_limit === "" || bill_limit === undefined) {
      addNotif = "0";
      preference = "";
    } else {
      addNotif = "1";
      preference = "email";
    }
    dispatch(
      updateConfigProperty({
        propertyName: "additional_notification",
        value: addNotif,
        configIds: targetConfigs,
      })
    );
    dispatch(
      updateConfigProperty({
        propertyName: "notification_preference",
        value: preference,
        configIds: targetConfigs,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResellerBillingNotification
      additionalNotification={additional_notification}
    />
  );
};
