import React from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { StatusChip } from "@akj-dev/shared-components";

interface AutoPopulateContactProps {
  fetchingContact: boolean;
  isContactError: boolean;
  hasContacts: boolean;
  selectedIndex: number;
  contacts: { nice_name: string }[];
  onFillDetails: (index: number) => void;
  onContactSelect: (index: number) => void;
}

export const AutoPopulateContact = ({
  isContactError,
  fetchingContact,
  hasContacts,
  selectedIndex,
  contacts,
  onFillDetails,
  onContactSelect,
}: AutoPopulateContactProps) => {
  const classes = useStyles();

  if (fetchingContact)
    return (
      <Box mb={1}>
        <StatusChip
          title="Searching for contacts"
          message="Please wait..."
          type="loading"
        />
      </Box>
    );

  if (isContactError)
    return (
      <Box mb={1}>
        <StatusChip
          title="Error fetching lead contacts"
          message="Please contact support"
          type="error"
        />
      </Box>
    );

  if (!hasContacts) return null;

  return (
    <Box my={1}>
      <Paper className={classes.paper}>
        <Box display="flex" p={1} alignItems="center">
          <Typography variant="h5">Fill with existing details:</Typography>

          <FormControl className={classes.formControl}>
            <InputLabel variant="standard">
              Select a contact on this lead
            </InputLabel>
            <Select
              value={selectedIndex}
              variant="standard"
              onChange={(event) =>
                onContactSelect(event.target.value as number)
              }
            >
              {contacts.map((contact: any, i: number) => (
                // No stable identity
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem value={i} key={`${contact.nice_name}-${i}`}>
                  {contact.nice_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="secondary"
            endIcon={<HowToRegIcon />}
            onClick={() => onFillDetails(selectedIndex)}
            disabled={selectedIndex === -1}
            data-cy="AutoPopulateButton"
          >
            Fill details
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.grey[50],
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    minWidth: 300,
  },
}));
