import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/browser";

import { Box, Grid } from "@mui/material";

import { IndexedCardInfo } from "@akj-dev/shared-components";

import { useDwsResellerEnabled } from "../../../shared/hooks/useDwsResellerEnabled";
import { useCanAccessVfDirect } from "../../../shared/hooks/useCanAccessVfDirect";
import { getHardwareCount } from "../../../store/hardware/selectors";
import { getMobileSIMsRequired } from "../../../store/mobile/selectors/delivery";

import { HardwareDelivery } from "./Hardware";
import { EthernetContainer } from "./Ethernet/index";
import { WlrBroadbandContainer } from "./WlrBroadband";
import { ContactsContainer } from "./Contacts";
import { ShippingContainer } from "./Mobile/Shipping";
import { TrackingDetailsContainer } from "./Mobile/TrackingDetails";
import { OrderContainer } from "./Order";
import { BillingAddressSectionContainer } from "./BillingAddressSection";
import { NewOrderContainer } from "./NewOrder";
import { DeclinedCreditVetWarningContainer } from "../../Wizard/containers/DeclinedCreditVetWarningContainer";

const cardIndexes = ["A", "B", "C", "D", "E", "F", "G"];

export const StepDeliveryContainer = () => {
  let cardIndex = -1;

  const orderId = useSelector((state: any) => state.order.id);
  const mobileSIMsRequired = useSelector<any, boolean>(getMobileSIMsRequired);
  const hardwareProductCount = useSelector<any, number>(getHardwareCount);
  const ethernetProductCount = useSelector<any, number>(
    (state) => state.ethernetProducts.configurations.length
  );
  const wlrBroadbandProductCount = useSelector<any, number>(
    (state) => state.wlrBroadband.configurations.length
  );

  const dwsResellerEnabled = useDwsResellerEnabled();
  const canAccessVfDirect = useCanAccessVfDirect();

  useEffect(() => {
    Sentry.addBreadcrumb({
      message: `Step 3 (Delivery Details) mounted}`,
      category: "action",
    });
  }, []);

  return (
    <Box>
      {canAccessVfDirect && <DeclinedCreditVetWarningContainer />}
      <IndexedCardInfo
        index={1}
        title="Please fill out the delivery information"
      >
        {!orderId ? (
          <NewOrderContainer />
        ) : (
          <Grid container spacing={2}>
            {hardwareProductCount || mobileSIMsRequired ? (
              <>
                <Grid item sm={12}>
                  <HardwareDelivery cardIndex={cardIndexes[++cardIndex]} />
                </Grid>
                <Grid item sm={12}>
                  <ShippingContainer cardIndex={cardIndexes[++cardIndex]} />
                </Grid>
                <Grid item sm={12}>
                  <TrackingDetailsContainer
                    cardIndex={cardIndexes[++cardIndex]}
                  />
                </Grid>
              </>
            ) : (
              <Grid item sm={12}>
                <BillingAddressSectionContainer
                  cardIndex={cardIndexes[++cardIndex]}
                />
              </Grid>
            )}
            {wlrBroadbandProductCount > 0 && (
              <Grid item sm={12}>
                <WlrBroadbandContainer cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {ethernetProductCount > 0 && (
              <Grid item sm={12}>
                <EthernetContainer cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {!dwsResellerEnabled && (
              <Grid item sm={12}>
                <ContactsContainer cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
            {!dwsResellerEnabled && (
              <Grid item sm={12}>
                <OrderContainer cardIndex={cardIndexes[++cardIndex]} />
              </Grid>
            )}
          </Grid>
        )}
      </IndexedCardInfo>
    </Box>
  );
};
