import React from "react";
import Icon from "@mui/icons-material/Stars";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { Grid } from "@mui/material";

// These all have the category "Select Services (Existing Line)" in product data.
// Could just pull them programatically, but there may well be exceptions...

// TODO: "current_value": "0",
export const SelectServices = () => (
  <Section
    title="Select Services (Existing Line)"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <Grid container spacing={2}>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.raw_call_data"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.change_bypass_number"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.ring_back_inhibit"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.anonymous_call_rejection"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.called_party_answer"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.call_waiting"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.voice_prompt"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.bar_use_of_call_return"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.reminder_call"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.ring_back"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.three_way_calling"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.extra_1471"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.bypass_number"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.call_sign"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.choose_to_refuse"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.indirect_access_call_barring"
        />
      </Grid>
      <Grid item md={6}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.caller_display"
        />
      </Grid>
      <Grid item md={6} />

      <Grid item md={12}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.messaging_product"
        />
      </Grid>
      <Grid item md={12}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.withhold_number"
        />
      </Grid>
      <Grid item md={12}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.call_diversion"
        />
      </Grid>
      <Grid item md={12}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.call_barring"
        />
      </Grid>
      <Grid item md={12}>
        <WlrBBDynamicField
          productType="broadband"
          propertyName="wlr_change.inbound_call_management"
        />
      </Grid>
    </Grid>
  </Section>
);
