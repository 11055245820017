import { createSelector } from "reselect";
import { RootState } from "../rootReducer";
import { groupBy } from "lodash";
import { orderStatuses } from "../../types";

export const selectOrdersGroupedByStatus = createSelector(
  (state: RootState) => state.board.data,
  (orders) => {
    const validOrders = orders.filter((order) =>
      orderStatuses.includes(order.status_category)
    );
    return groupBy(validOrders, "status_category");
  }
);
