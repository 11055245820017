import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Theme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import { VirtualTable } from "../../../../../../shared/components/VirtualTable";
import { VirtualTableSortOrder } from "../../../../../../shared/components/VirtualTable/enums";
import { ActiveSort } from "../../../../../../shared/components/VirtualTable/types";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState,
} from "../../../../../../shared/components/VirtualTable/utils";
import { CarrierFilterEnum, DataFilterEnum } from "./constants/enums";
import { carrierFilterOptions, dataFilterOptions, MAX_QTY } from "./constants";
import { ProductTableRenderRow } from "./RenderRow";
import { ItemsSelected } from "../../../../../../shared/components/ItemsSelected";
import { MobileProduct } from "../../../../../../shared/types/general";
import { generateTableHeaders } from "./utils/generateTableHeaders";
import { MobileProductConfig } from "../../../../../../shared/types/MobileProductConfig";

interface ProductTableProps {
  configsCount: number;
  products: MobileProduct[];
  configs: MobileProductConfig[];
  isReseller: boolean;
  withCarrierFilter?: boolean;
  withDataFilter?: boolean;
  showProductFamily?: boolean;
}

export const ProductTable = ({
  configsCount,
  products,
  configs,
  isReseller,
  withCarrierFilter = true,
  withDataFilter = true,
  showProductFamily = false,
}: ProductTableProps) => {
  const classes = useStyles();
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter,
  } = useDebouncedState();
  const [carrierFilter, setCarrierFilter] = useState<CarrierFilterEnum>(
    CarrierFilterEnum.ALL
  );
  const [dataFilter, setDataFilter] = useState<DataFilterEnum>(
    DataFilterEnum.ALL
  );

  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: showProductFamily ? "family" : "name",
    order: VirtualTableSortOrder.ASC,
  });

  const handleSort = useMemo(
    () => handleActiveSort(setActiveSort),
    [setActiveSort]
  );

  const filteredProducts = useMemo(
    () =>
      products
        .map((product) => ({
          ...product,
          configsCount: configs.filter(
            (c) => c.productId === product.id && !c.resignId
          ).length,
        }))
        .filter((product) => {
          const filterByValue = showProductFamily
            ? `${product.name} ${product.family} £${product.recurringPrice}`
            : product.name;

          const isMatchingProductFilter =
            filterByValue
              .toLowerCase()
              .indexOf(debouncedProductFilter.toLowerCase()) !== -1 ||
            product.configsCount > 0;

          const isMatchingCarrierFilter =
            carrierFilter === CarrierFilterEnum.ALL ||
            carrierFilter.toLowerCase() === product.network?.toLowerCase();

          const isMatchingDataFilter =
            dataFilter === DataFilterEnum.ALL ||
            (dataFilter === DataFilterEnum.DATA && product.dataOnly) ||
            (dataFilter === DataFilterEnum.VOICE && !product.dataOnly);

          return (
            isMatchingProductFilter &&
            isMatchingCarrierFilter &&
            isMatchingDataFilter
          );
        })
        .sort(sortVirtualTableData(activeSort)),
    [
      products,
      configs,
      debouncedProductFilter,
      carrierFilter,
      dataFilter,
      showProductFamily,
      activeSort,
    ]
  );

  const productsCount = filteredProducts.length;

  return (
    <Box className={classes.root}>
      {withDataFilter && (
        <Box>
          {dataFilterOptions.map(({ id, label }) => (
            <Button
              className={
                dataFilter === id
                  ? classes.activeDataFilter
                  : classes.dataFilter
              }
              onClick={() => setDataFilter(id)}
              data-cy={`${id.toLowerCase()}`}
              key={id}
            >
              {label}
            </Button>
          ))}
        </Box>
      )}
      <Box className={classes.searchBox}>
        <TextField
          data-cy={"tableSearch"}
          className={classes.search}
          variant="outlined"
          name="filter"
          value={productFilter}
          onChange={handleProductFilter}
          placeholder={`Search ${productsCount} Available Products`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {!!configsCount && (
          <ItemsSelected maxQuantity={MAX_QTY} configsCount={configsCount} />
        )}
      </Box>
      {withCarrierFilter && (
        <FormControl variant="standard" component="fieldset">
          <RadioGroup
            aria-label="carrierFilter"
            row
            name="carrierFilter"
            value={carrierFilter}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCarrierFilter(event.target.value as CarrierFilterEnum);
            }}
          >
            {carrierFilterOptions.map(({ id, label }) => (
              <FormControlLabel
                value={id}
                key={id}
                control={<Radio />}
                label={label}
                data-cy={`${id.toLowerCase()}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
      <VirtualTable
        withScrollButton={false}
        headers={generateTableHeaders(isReseller, showProductFamily)}
        activeSort={activeSort}
        handleSort={handleSort}
        data={{
          products: filteredProducts,
          allConfigsCount: configsCount,
          showProductFamily,
        }}
        tableSize={500}
        itemSize={80}
        itemCount={filteredProducts.length}
        RenderTableRow={ProductTableRenderRow}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataFilter: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(1),
  },
  activeDataFilter: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1),
  },
}));
