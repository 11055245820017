import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { open, close, screenshot, invalid, update, create } from "./actions";

const supportCase = createSlice({
  name: "supportCase",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(open.fulfilled, (state) => {
        state.open = true;
      })
      .addCase(close, (state) => {
        state.open = false;
      })
      .addCase(screenshot, (state, action) => {
        state.screenshot = action.payload;
      })
      .addCase(update, (state, action) => {
        const target = action.payload;
        state.invalid = false;
        // @ts-ignore
        state[target.name] = target.value;
      })
      .addCase(invalid, (state) => {
        state.invalid = true;
      })
      .addCase(create.pending, (state) => {
        state.loading = true;
      })
      .addCase(create.rejected, (state) => {
        state.loading = false;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.open = true;
        state.loading = false;
        state.caseDetails = action.payload;
      });
  },
});

export default supportCase.reducer;
