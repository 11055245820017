import { all, call } from "redux-saga/effects";
import { watchRequestOverviewData } from "./overview/sagas";
import { watchRequestDetailById } from "./detail/sagas";
import { watchRequestNotesDataById, watchRequestPostNote } from "./notes/sagas";
import { watchRequestBoard } from "./board/sagas";

import * as autocomplete from "./autocomplete/sagas";
import * as newnotification from "./newnotification/sagas";
import * as notifications from "./notifications/sagas";

export function* rootSaga() {
  yield all([
    call(watchRequestOverviewData),
    call(watchRequestDetailById),
    call(watchRequestNotesDataById),
    call(watchRequestPostNote),
    call(watchRequestBoard),

    call(autocomplete.watchSearchAccounts),
    call(autocomplete.watchSearchUsers),
    call(autocomplete.watchGetOrderNumbers),

    call(newnotification.watchCreate),

    call(notifications.watchFetch),
    call(notifications.watchRemove),
    call(notifications.watchSetStatus),
  ]);
}
