import React, { useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlertPreferences } from "../../../../store/monitoringService/actions";
import { MonitoringService } from "../../components/MonitoringService";

const MonitoringServiceContainer = () => {
  const dispatch = useDispatch();

  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const alertPreferences = useSelector(
    (state: any) => state.monitoringService.alertPreferences
  );

  const handleEmailAddress = (event: ChangeEvent<HTMLInputElement>) =>
    setEmailAddress(event.target.value);

  const handlePhoneNumber = (event: ChangeEvent<HTMLInputElement>) =>
    setPhoneNumber(event.target.value);

  const handleAlertPreferencesChange = (newValues: Record<string, string>) => {
    dispatch(
      setAlertPreferences({
        ...alertPreferences,
        ...newValues,
      })
    );
  };

  return (
    <MonitoringService
      emailAddress={emailAddress}
      phoneNumber={phoneNumber}
      handleAlertPreferencesChange={handleAlertPreferencesChange}
      handlePhoneNumberChange={handlePhoneNumber}
      handleEmailAddressChange={handleEmailAddress}
    />
  );
};

export { MonitoringServiceContainer as MonitoringService };
