import React from "react";
import Icon from "@mui/icons-material/Group";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

interface CareLevelsProps {
  isWlrChangePossible: boolean;
}

export const CareLevels = ({ isWlrChangePossible }: CareLevelsProps) => (
  <Section title="Care Levels" avatar={<Icon />}>
    {isWlrChangePossible && (
      <WlrBBDynamicField
        productType="broadband"
        propertyName="wlr_change.care_level"
      />
    )}
    <WlrBBDynamicField productType="wlr" propertyName="care_level" margin />
    <WlrBBDynamicField
      productType="broadband"
      propertyName="bb.care_level"
      margin
    />
  </Section>
);
