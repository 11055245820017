import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ResignConfigTableRow } from "../../containers/wlrBroadband/ResignsConfigurationTable/ResignConfigTableRow";
import { ConfigTableRow } from "./ConfigTableRow";

interface SelectedConfigsProps {
  standardConfigs: any[];
  resignConfigs: any[];
  showCommissionType: boolean;
  getIsMPFLine: (config: any) => boolean;
  getSelectedProductData?: () => void;
}

export const SelectedConfigs = ({
  standardConfigs,
  resignConfigs,
  showCommissionType,
  getIsMPFLine,
  getSelectedProductData,
}: SelectedConfigsProps) => (
  <Table>
    {standardConfigs.length > 0 && (
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell>Total One Off Price</TableCell>
          <TableCell>Total Recurring Price</TableCell>
          <TableCell>Broadband</TableCell>
          {showCommissionType && (
            <TableCell align="center">
              <p>Commission</p>
              <p>Up Front &nbsp; Residual </p>
            </TableCell>
          )}
          <TableCell>Configured?</TableCell>
        </TableRow>
      </TableHead>
    )}
    <TableBody>
      {standardConfigs.map((c: any, i: number) => (
        <ConfigTableRow
          c={c}
          configIndex={i}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          retryAction={getSelectedProductData}
          isMPFLine={getIsMPFLine(c)}
        />
      ))}
    </TableBody>
    {resignConfigs.length > 0 && (
      <TableHead>
        <TableRow>
          <TableCell>CLI</TableCell>
          <TableCell />
          <TableCell>Product</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Resign Type</TableCell>
          {showCommissionType && (
            <TableCell align="center">
              <p>Commission</p>
              <p>Up Front &nbsp; Residual </p>
            </TableCell>
          )}
          <TableCell>Configured?</TableCell>
        </TableRow>
      </TableHead>
    )}
    <TableBody>
      {resignConfigs.map((c: any, i: number) => (
        <ResignConfigTableRow
          configuration={c}
          index={i}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          retryAction={getSelectedProductData}
          showCommissionType={showCommissionType}
        />
      ))}
    </TableBody>
  </Table>
);
