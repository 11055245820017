import React, { ReactNode, ChangeEvent } from "react";
import styled from "styled-components";

import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PublicIcon from "@mui/icons-material/Public";
import { useTheme } from "@mui/material/styles";

import { getTotalPrice } from "../../../../../../store/wlrBroadband/selectors";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

const CustomArrowForwardIcon = styled(ArrowForwardIcon)`
  color: ${(p) => p.theme.palette.text.disabled};
  margin-left: 10px;
  margin-right: 10px;
`;

interface MPFProductChangesRowProps {
  isChecked: boolean;
  productInstance: Record<string, any>;
  resignProduct: Record<string, any>;
  actions: ReactNode;
  toggleRow: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const MPFProductChangesRow = ({
  isChecked,
  productInstance,
  resignProduct,
  actions,
  toggleRow,
}: MPFProductChangesRowProps) => {
  const theme = useTheme();

  const isResignProduct = resignProduct.components[0].is_resign;
  const resignProductName = resignProduct.name_for_resign || resignProduct.name;
  const resignProductPrice =
    resignProduct.price.first_bill_recurring_price_with_promotions;

  return (
    <TableRow selected={isChecked}>
      <TableCell>
        <Checkbox color="secondary" onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <TableCell>{productInstance.pin}</TableCell>
      <TableCell />
      <TableCell colSpan={2}>
        <CustomCell>
          <PublicIcon
            style={{ color: theme.palette.grey[500], marginRight: 10 }}
          />
          <div>{productInstance.name_for_resign || productInstance.name}</div>
          <CustomArrowForwardIcon />
          <PublicIcon
            style={{ color: theme.palette.grey[500], marginRight: 10 }}
          />
          <div>{resignProductName}</div>
        </CustomCell>
      </TableCell>
      <TableCell />
      <TableCell>
        &pound;
        {isResignProduct
          ? getTotalPrice(productInstance.evo_services)
          : resignProductPrice}
      </TableCell>
      <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
      <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
        {actions}
      </TableCell>
    </TableRow>
  );
};
