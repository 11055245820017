import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";

interface PopoverProps {
  title?: string;
  position?: string;
  children: ReactNode;
}

export const Popover = ({ title, position, children }: PopoverProps) => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  return (
    <Outer>
      <InfoIcon
        onMouseEnter={show}
        onMouseLeave={hide}
        htmlColor={theme.palette.info.main}
        style={{ cursor: "pointer" }}
      />
      {isVisible && (
        <Pop position={position || ""}>
          {title && <h4>{title}</h4>}
          {children}
        </Pop>
      )}
    </Outer>
  );
};

const Outer = styled.div`
  position: relative;
  display: inline;
  margin-left: 10px;
`;

const Pop = styled.div<{ position: string }>`
  position: absolute;
  z-index: 100;
  top: -12px;
  ${(p) => {
    switch (p.position) {
      case "left":
        return `
            right:30px;
          `;
      default:
        return `
            left:30px;
          `;
    }
  }}
  width: 300px;
  background: ${(p) => p.theme.palette.info.main};
  padding: 10px;
  border-radius: 5px;
  color: white;
  box-shadow: 0 0 5px 3px white;
  h4 {
    margin-top: 0;
  }
  :after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    ${(p) => {
      switch (p.position) {
        case "left":
          return `
              right:-7px;
              transform: rotate(180deg); 
            `;
        default:
          return `
              left:-7px;
            `;
      }
    }}
    top: 10px;
    border-right-color: ${(p) => p.theme.palette.info.main};
    border-width: 7px 7px 7px 0;
  }
`;
