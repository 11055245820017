import { kongAxios } from "../../core/kongAxios";

export const SalesStatusQuotes = ({
  page = 1,
  limit = 10,
  sortBy,
  order = "asc",
  filters = {},
  orderSource,
}: any) =>
  kongAxios("ExternalServices/v1/Affinity/Sales/Quotes", {
    params: {
      _page: page,
      _limit: limit,
      _sort: sortBy,
      _order: order,
      order_source: orderSource,
      ...filters,
    },
    // TODO: Probably shouldn't return only .data but need compatibility with existing component for now
  }).then((response) => response.data);

export const SalesStatusDateRange = (filters: any) =>
  kongAxios(`ExternalServices/v1/Affinity/Sales/Quotes/DateRange`, {
    params: { filters },
  }).then((response) => response.data);

export const SalesStatusEndCustomerAccounts = (status: any) =>
  kongAxios(
    `ExternalServices/v1/Affinity/Sales/Quotes/Options/endCustomerAccountNo`,
    {
      params: { status },
    }
  ).then((response: any) =>
    response.data.map((account: any) => ({
      displayName: account.displayName,
      value: account.value,
    }))
  );

export const SalesStatusOptions = (filters = {}, option: any) =>
  kongAxios(`ExternalServices/v1/Affinity/Sales/Quotes/Options/${option}`, {
    params: { filters },
  }).then((response) => response.data);

export const SalesStatusPdfBlob = (url: string) => {
  return kongAxios("ExternalServices/v1/Affinity/Sales/Quotes/PDF/" + url, {
    headers: {
      pragma: "no-cache",
      "cache-control": "no-cache",
    },
    responseType: "blob",
  }).then((response) => {
    let filename;
    const disposition =
      response.headers && response.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /^attachment; filename="(.+)"$/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1];
      }
    }

    return {
      filename: filename,
      file: response,
    };
  });
};
