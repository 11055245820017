import { AccountState } from "store/account/types";

const initialState: AccountState = {
  availableHardwareCredit: 0,
  isFetchingAvailableHardwareCredit: false,
  boltOns: {},

  settings: {
    fetching: true,
    response: {},
  },

  billingAddress: {
    fetching: false,
    response: {},
  },

  accountAddresses: {
    fetching: false,
    response: {},
    selectedIndex: false,
  },

  contacts: {
    fetching: false,
    response: {},
    selectedId: false,
  },
  orderContactId: false,

  contactTypes: {
    fetching: false,
    response: {},
  },

  addContact: {
    fetching: false,
    response: {},
  },

  creditVetProcess: {
    fetching: false,
    response: {},
  },

  creditVetStatus: {
    fetching: false,
    response: {},
  },

  createAccount: {
    fetching: false,
    response: {},
  },
  newAccountId: false,
  accountId: false,
};

export default initialState;
