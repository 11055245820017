// ORDER CARD

import React from "react";
import { format, fromUnixTime } from "date-fns";
import { Card, Divider, Typography, Box, Theme, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { getIconByProductType } from "../../../../helpers/getIconByProductType";
import { getMuiThemePaletteColorByOrderType } from "../../../../helpers/getMuiThemePaletteColorByOrderType";
import { getAcquisitionMethodDisplayLabel } from "../../../../helpers/getOrderStatusDisplayLabel";
import { Order } from "../../../../types";

type Props = {
  order: Order;
};

export const OrderCard = ({
  order: {
    order_type,
    order_product_category,
    order_number,
    customer_company_name,
    cust_reference,
    billing_account,
    created_by_for_display,
    creation_date_timestamp,
    platform_customer_reference,
    components_acquisition_method,
    id,
  },
}: Props) => {
  let muiThemePaletteColor = getMuiThemePaletteColorByOrderType(order_type);

  const Icon = getIconByProductType(order_product_category);

  const styles = {
    root: {
      width: "100%",
      "& a": {
        textDecoration: "none",
      },
    },
    card: {
      borderBottomWidth: (theme: Theme) => theme.shape.borderRadius,
      borderBottomColor: (theme: Theme) =>
        theme.palette[muiThemePaletteColor].main,
      borderBottomStyle: "solid",
      padding: 1,
      paddingBottom: 0,
      textTransform: "capitalize",
      "& .MuiTypography-root": {
        fontSize: 13,
      },
      "&:hover": {
        boxShadow: (theme: Theme) => theme.shadows[12],
      },
    },
  };

  return (
    <Box sx={styles.root}>
      <Link
        to={(location) => ({ ...location, search: `?view=detail&id=${id}` })}
      >
        <Card sx={styles.card} elevation={3}>
          <Box display="flex" marginBottom={0.25}>
            <Box flex={1}>
              <Typography
                component="div"
                noWrap
                color="textSecondary"
                sx={{ flex: 1 }}
              >
                {order_product_category}
              </Typography>
            </Box>
            <Box marginLeft={0.5}>
              <Typography
                component="div"
                align="right"
                color="textSecondary"
                sx={{ marginLeft: 0.5 }}
              >
                #{order_number ?? id}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" marginBottom={0.25}>
            <Box flex={1} style={{ maxWidth: "100%" }}>
              <Typography
                component="div"
                noWrap
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                {customer_company_name}
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            display="flex"
            marginBottom={0.25}
          >
            <Grid item xs={7} zeroMinWidth>
              <Typography noWrap>{cust_reference}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                component="div"
                align="right"
                color="textSecondary"
                sx={{ marginLeft: 0.5 }}
              >
                #{platform_customer_reference ?? billing_account}
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" marginBottom={0.25}>
            <Box flex={1} style={{ maxWidth: "100%" }}>
              <Typography
                component="div"
                noWrap
                color="textSecondary"
                style={{ fontSize: 14 }}
                sx={{ flex: 1 }}
              >
                {getAcquisitionMethodDisplayLabel(
                  components_acquisition_method
                )}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" marginBottom={0.25}>
            <Box flex={1} style={{ maxWidth: "100%" }}>
              <Typography
                component="div"
                noWrap
                color="textSecondary"
                style={{ fontSize: 14 }}
                sx={{ flex: 1 }}
              >
                {created_by_for_display?.name}
              </Typography>
            </Box>
          </Box>
          <Box marginTop={0.5}>
            <Divider />
          </Box>

          <Box
            display="flex"
            marginTop={0.5}
            marginBottom={0.5}
            justifyContent="space-between"
          >
            <Box color={`${muiThemePaletteColor}.main`} display="flex">
              <Icon
                fontSize="small"
                style={{ marginTop: -3, marginRight: 4 }}
                data-testid="order-card-icon"
              />
              <Typography component="div" noWrap color="inherit">
                {order_type}
              </Typography>
            </Box>
            <Typography
              component="div"
              align="right"
              style={{ fontWeight: 600 }}
            >
              {formatDate(creation_date_timestamp)}
            </Typography>
          </Box>
        </Card>
      </Link>
    </Box>
  );
};

function formatDate(timestamp: number): string {
  try {
    return format(fromUnixTime(timestamp), "dd MMM ''yy");
  } catch (e) {
    return "";
  }
}
