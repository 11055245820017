import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "@akj-dev/affinity-platform";

import { approveOrRejectApproval } from "../../../store/order/actions";
import { ApproveQuote } from "../components/ApproveQuote";

/**
 * Approve Quote button
 * This is a platform only function, available when user has relevant permission
 * Used for Love Energy. TP9701
 *
 * @constructor
 */
export const ApproveQuoteContainer = () => {
  const { userRole } = useAuth();
  const dispatch = useDispatch();
  const fetching = useSelector(
    (state: any) => state.order.approveApproval.fetching
  );
  const response = useSelector(
    (state: any) => state.order.orderStatus.response
  );

  return userRole?.affinity_sales_can_approve_quotes ? (
    <ApproveQuote
      isFetching={fetching}
      isError={response.status === "error"}
      approveOrRejectApproval={(approve) =>
        dispatch(approveOrRejectApproval(approve))
      }
    />
  ) : null;
};
