import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as OrdersAPI from "../../api/v1/orders";
import { getAccountId, getOrderId } from "../order/selectors";
import { addAlertMessage } from "../uiState/actions";
import { getContactId } from "./selectors";

export const selectContactForContract = createAction<string>(
  "generateContract/selectContactForContract"
);

export const createContract = createAsyncThunk(
  "generateContract/createContract",
  async (arg, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const contactId = getContactId(state);
    const accountId = getAccountId(state);
    const orderId = getOrderId(state);

    const reject = (error: any) => {
      dispatch(addAlertMessage(error));
      rejectWithValue(error);
    };
    try {
      const response: any = await OrdersAPI.createAndSendEsignContract(
        accountId,
        orderId,
        contactId
      );
      if (response.status === "success") {
        return response.data;
      } else {
        reject(response.message);
      }
    } catch (error) {
      reject(error);
    }
  }
);
