import React from "react";
import { parseISO, format } from "date-fns";

import Checkbox from "@mui/material/Checkbox";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Section } from "../../../../shared/components/Section";
import { NUMBER, TARIFF } from "../../../../store/wlrBroadband/constants";
import { DC_DATE_FORMAT } from "../../../../shared/utils/date";

interface SpecialRatesProps {
  numbers: any[];
  tariffs: any[];
  getIsDisabled: (itemId: string) => boolean;
  onSpecialRateToRemove: (itemId: string, type: string) => void;
}

export const SpecialRates = ({
  numbers,
  tariffs,
  getIsDisabled,
  onSpecialRateToRemove,
}: SpecialRatesProps) => (
  <Section title="Special Rates" avatar={<InsertChartIcon />}>
    <Table data-cy="wlrBroadbandSRTable">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>CLI</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Rate</TableCell>
          <TableCell>Setup</TableCell>
          <TableCell>Remainder Setup</TableCell>
          <TableCell>Min Charge</TableCell>
          <TableCell>Charge Cap</TableCell>
          <TableCell>Initial Rating</TableCell>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
          <TableCell>Remove</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tariffs && (
          <>
            <TableRow>
              <TableCell colSpan={12}>Special Charge Codes</TableCell>
            </TableRow>
            {Object.values(tariffs).map((item: any) => (
              <TableRow key={`tariff-${item.Id}`}>
                <TableCell>{item.ChargeCodeDestination}</TableCell>
                <TableCell>{item.CLI}</TableCell>
                <TableCell>{item.RateTypeName}</TableCell>
                <TableCell>{item.Peak || "0.00"}</TableCell>
                <TableCell>{item.SetupPeak || "0.00"}</TableCell>
                <TableCell>{item.RemainderSetupPeak || "0.00"}</TableCell>
                <TableCell>{item.PeakMinCharge || "0.00"}</TableCell>
                <TableCell>{item.PeakMaxCharge || "0.00"}</TableCell>
                <TableCell>{item.InitialPeriodPeakRate || "0.00"}</TableCell>
                <TableCell>
                  {item.FromDate &&
                    format(parseISO(item.FromDate), DC_DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {item.ToDate && format(parseISO(item.ToDate), DC_DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    color="secondary"
                    data-cy="wlrBroadbandSRTariffCheckbox"
                    onChange={() => onSpecialRateToRemove(item.Id, TARIFF)}
                    checked={!!getIsDisabled(item.Id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
        {numbers && (
          <>
            <TableRow>
              <TableCell colSpan={12}>Special Numbers</TableCell>
            </TableRow>
            {Object.values(numbers).map((item: any) => (
              <TableRow key={`number-${item.Id}`}>
                <TableCell>{item.PIN}</TableCell>
                <TableCell>{item.CLI}</TableCell>
                <TableCell>{item.RateTypeName}</TableCell>
                <TableCell>{item.Peak || "0.00"}</TableCell>
                <TableCell>{item.SetupPeak || "0.00"}</TableCell>
                <TableCell>{item.RemainderSetupPeak || "0.00"}</TableCell>
                <TableCell>{item.PeakMinCharge || "0.00"}</TableCell>
                <TableCell>{item.PeakMaxCharge || "0.00"}</TableCell>
                <TableCell>{item.InitialPeriodPeakRate || "0.00"}</TableCell>
                <TableCell>
                  {item.FromDate &&
                    format(parseISO(item.FromDate), DC_DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  {item.ToDate && format(parseISO(item.ToDate), DC_DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  <Checkbox
                    color="secondary"
                    data-cy="wlrBroadbandSRNumberCheckbox"
                    onChange={() => onSpecialRateToRemove(item.Id, NUMBER)}
                    checked={!!getIsDisabled(item.Id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </>
        )}
      </TableBody>
    </Table>
  </Section>
);
