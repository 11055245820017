import React from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { Section } from "../../../../shared/components/Section";
import { CliBoltOnSlotSelectFieldContainer } from "../../containers/Mobile/ConfigurationForm/sections/CliBoltOns/CliBoltOnSlotSelectField";
import { CliTopUpBoltOnSelectFieldContainer } from "../../containers/Mobile/ConfigurationForm/sections/CliBoltOns/CliTopUpBoltOnSelectField";
import { CliBoltOnSelectFieldContainer } from "../../containers/Mobile/ConfigurationForm/sections/CliBoltOns/CliBoltOnSelectField";
import { Typography } from "@mui/material";
import { BoltOnChip } from "../../../StepChoose/Mobile/components/Resigns/BoltOnChip";
import { CliBoltOnFiltered } from "./CliBoltOnFiltered";
import { ResignBoltOnsBulkConfig } from "./ResignBoltOnsBulkConfig";

interface CliBoltOnsProps {
  mode?: "slot" | "type";
  targetConfigs: number[];
  existingResignBoltOns: any;
  allSameProducts: boolean;
}

export const CliBoltOns = ({
  targetConfigs,
  mode = "type",
  existingResignBoltOns,
  allSameProducts,
}: CliBoltOnsProps) => (
  <Section title="Bolt Ons" avatar={<AddBoxIcon />} data-cy="BoltOnsConfig">
    {!allSameProducts && <CliBoltOnFiltered />}
    {mode === "slot" ? (
      Array.from(Array(8)).map((e, i) => (
        <CliBoltOnSlotSelectFieldContainer
          // eslint-disable-next-line react/no-array-index-key
          key={`bolt-on-slot-${i}`}
          slotId={String(i + 1)}
        />
      ))
    ) : (
      <>
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="voice"
          title="International Minutes"
        />
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="roaming_voice"
          title="Worldwide Roaming Minutes"
        />
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="text"
          title="International SMS"
        />
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="data"
          title="Data"
        />
        <CliTopUpBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="data"
          title="Data Top Up"
        />
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="roaming_data"
          title="Worldwide Roaming Data"
        />
        <CliBoltOnSelectFieldContainer
          targetConfigs={targetConfigs}
          boltOnType="additional_bundle_2"
          title="Non Geographic Numbers"
        />
      </>
    )}
    {existingResignBoltOns.length > 0 && targetConfigs.length > 1 && (
      <ResignBoltOnsBulkConfig />
    )}
    {existingResignBoltOns.length > 0 && targetConfigs.length === 1 && (
      <>
        <Typography variant="h4">Previous bolt-ons</Typography>
        {existingResignBoltOns.map((boltOn: any) => (
          <BoltOnChip
            title={boltOn.description}
            price={boltOn.rate}
            key={boltOn.description}
          />
        ))}
      </>
    )}
  </Section>
);
