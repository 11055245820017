import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setBroadbandProduct,
  setOtherConfiguration,
} from "../../../../store/wlrBroadband/actions/configurations";
import { WlrLocationTypeEnum } from "../enums";
import { ChooseSingleProduct } from "../components/ChooseSingleProduct";
import { WlrLocationBroadbandProduct } from "../types";

interface ChooseSingleProductContainerProps {
  locationIndex: number;
  productType: WlrLocationTypeEnum.NEW_SOGEA | WlrLocationTypeEnum.NEW_FTTP;
}

export const ChooseSingleProductContainer = ({
  locationIndex,
  productType,
}: ChooseSingleProductContainerProps) => {
  const dispatch = useDispatch();

  const broadbandSearch = useSelector(
    (state: any) => state.wlrBroadband.locations[locationIndex]?.broadbandSearch
  );

  const lineAvailability = useSelector(
    (state: any) =>
      state.wlrBroadband.locations[locationIndex]?.lineAvailability
  );

  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );

  const firstConfigId = configurations.findIndex(
    (c: any) => c.locationIndex === locationIndex
  );

  const products = useMemo(
    () =>
      broadbandSearch.response?.products?.filter(
        (p: WlrLocationBroadbandProduct) =>
          p.first_broadband_component.type ===
          (productType === WlrLocationTypeEnum.NEW_FTTP ? "FTTP" : "SOGEA")
      ),
    [broadbandSearch, productType]
  );

  const selectedProductId = configurations[firstConfigId]?.broadbandProductId;

  // Set the generic placeholder product for SOGEA on mount
  useEffect(() => {
    if (products?.length && !selectedProductId) {
      dispatch(
        setOtherConfiguration({
          locationIndex,
          product: { id: productType },
        })
      );
    }
  }, [dispatch, locationIndex, products, selectedProductId, productType]);

  // Set the actual connectivity product
  // SOGEA & FTTP are one per location it seems, so find the first config...
  const handleSelectProduct = (productId: string) => {
    dispatch(setBroadbandProduct(firstConfigId, productId));
  };

  return (
    <ChooseSingleProduct
      lineAvailability={lineAvailability}
      broadbandSearch={broadbandSearch}
      products={products}
      selectedProductId={selectedProductId}
      onSelectProduct={handleSelectProduct}
      productType={productType}
    />
  );
};
