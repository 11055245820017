import React, { ElementType } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

interface ChipProps {
  color?: string;
  outline?: boolean;
  noWrap?: boolean;
  onClick?: () => void;
  Icon: ElementType;
  title?: string | JSX.Element;
  className?: string;
}

const ChipComponent = ({ className, Icon, title, onClick }: ChipProps) => {
  return (
    <div className={className} onClick={onClick}>
      <Icon color="inherit" />
      <div style={{ paddingLeft: 5 }}>{title}</div>
    </div>
  );
};

ChipComponent.propTypes = {
  Icon: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  outline: PropTypes.bool,
  noWrap: PropTypes.bool,
  onClick: PropTypes.func,
};

export const Chip = styled(ChipComponent)<ChipProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 5px 5px 5px 0;
  padding: 2px 8px 2px 2px;
  background: ${(props) => (props.outline ? "#fff" : props.color)};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => (props.outline ? props.color : "#fff")};
  max-width: 260px;
  font-size: 12px;
  ${(props) => props.noWrap && "white-space: nowrap;"}
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
    font-weight: bold;
  `}
`;
