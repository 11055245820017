import React from "react";
import * as DOMPurify from "dompurify";
import { Box, Typography } from "@mui/material";
import { format, fromUnixTime } from "date-fns";
import { ViewMessage } from "./ViewMessage";

type Props = {
  createdBy: string;
  timestamp: number;
  subject: string;
  content: any;
};

export const Comment: React.FunctionComponent<Props> = ({
  createdBy,
  timestamp,
  subject,
  content,
}) => {
  const isEmail = subject !== "Note added in Affinity Orders";
  let formattedDateTime = "";
  try {
    formattedDateTime = format(fromUnixTime(timestamp), "HH:mm - dd/MM/yyy");
  } catch (e) {
    console.warn(e);
  }

  // Needed to remove whitespace
  let _html = content;
  try {
    _html = isEmail
      ? content.split("</head>")[1].replaceAll("<br/>", "").replace("\n", "")
      : content;
  } catch (e) {
    console.warn(e);
  }

  // Removes header & footer images for overview
  const extractContent = (html: string) => {
    var span = document.createElement("span");
    span.innerHTML = html;
    span.querySelectorAll("a img").forEach((el) => el.remove());
    span.getElementsByClassName("mcnImageContentContainer").item(0)?.remove();
    return span.querySelector("table")?.innerHTML || html;
  };

  return (
    <Box display="flex" flex={1}>
      <Box flexBasis="125px" flexShrink={0} marginRight={2}>
        <Typography variant="h5">{createdBy}</Typography>
        <Typography variant="subtitle2"> {formattedDateTime}</Typography>
      </Box>

      <Box width={"100%"} marginRight={2}>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {subject}
        </Typography>
        {_html && (
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(extractContent(_html) || _html),
            }}
          />
        )}
      </Box>

      {isEmail && (
        <Box>
          <ViewMessage title={subject}>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(_html) }}
            />
          </ViewMessage>
        </Box>
      )}
    </Box>
  );
};
