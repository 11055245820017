import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

import ChangeIcon from "./ChangeIcon";
import FieldContainer from "./FieldContainer";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DC_DATE_FORMAT } from "../../../../../shared/utils/date";

// TODO: Check the minDate logic is ok. Nothing needs to be set in the past from the property value & property value always exists.

const DateField = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  showWlrChangeIcon,
  propertyValidation,
  recalculatePrices,
  validateConfig,
  disabled,
  minDate,
  maxDate,
  shouldDisableDate,
}) => {
  return (
    <FieldContainer>
      <DatePicker
        inputFormat={"dd/MM/yyyy"}
        value={propertyValue}
        label={`${dynamicProperty.label}${
          dynamicProperty.is_required ? " *" : ""
        }`}
        disabled={disabled}
        helperText={propertyValidation}
        error={!!propertyValidation}
        onChange={(date) => {
          setProperty(format(date, DC_DATE_FORMAT));
          recalculatePrices();
          validateConfig();
        }}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
      {showWlrChangeIcon && <ChangeIcon />}
    </FieldContainer>
  );
};

DateField.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DateField;
