import React from "react";

import { StatusChip } from "@akj-dev/shared-components";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface ServiceTariffProps {
  serviceTariffId: Record<string, any>;
  configServiceTariffId: string;
  setServiceTariff: (newTariff: string) => void;
}

export const ServiceTariff = ({
  serviceTariffId,
  configServiceTariffId,
  setServiceTariff,
}: ServiceTariffProps) => {
  if (serviceTariffId)
    return (
      <FormControl fullWidth margin="normal">
        <InputLabel variant="standard">Service Tariff</InputLabel>
        <Select
          variant="standard"
          value={
            configServiceTariffId ||
            parseInt(serviceTariffId.current_value) ||
            serviceTariffId.default_value
          }
          onChange={(event) => setServiceTariff(event.target.value as string)}
        >
          {serviceTariffId.available_options.map((option: string) => (
            <MenuItem value={option} key={option}>
              {serviceTariffId.available_option_map[option]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

  return (
    <StatusChip
      type="error"
      title="Product data error."
      message="No service tariff ID available."
    />
  );
};
