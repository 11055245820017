import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./state";
import {
  generateQuote,
  resetQuote,
  setGenerateQuoteFetching,
  setQuoteContact,
} from "./actions";

const quote = createSlice({
  name: "quote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setQuoteContact, (state, action) => {
        state.quoteContactId = action.payload;
      })
      .addCase(generateQuote.pending, (state, action: PayloadAction<any>) => {
        state.generateQuote.fetching = true;
      })
      .addCase(generateQuote.fulfilled, (state, action: PayloadAction<any>) => {
        state.generateQuote = {
          fetching: false,
          response: action.payload,
        };
      })
      .addCase(setGenerateQuoteFetching, (state) => {
        state.generateQuote.fetching = true;
      })
      .addCase(resetQuote, (state, action) => {
        state.generateQuote = initialState.generateQuote;
      });
  },
});

export default quote.reducer;
