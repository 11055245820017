import { clearState } from "./actions";
import { resetOrderState } from "../order/actions";
import { useHistory } from "react-router-dom";
import { saveDraftState } from "../drafts/actions";
import { useDispatch } from "react-redux";

/**
 * Save draft and reset the store for a new order.
 */
export function useSaveDraftAndStartNewOrder() {
  const dispatch = useDispatch();
  const history = useHistory();
  return async () => {
    return dispatch(
      saveDraftState({
        successMessage: "Order Saved",
        successCallback: () => {
          // clear root reducer
          dispatch(clearState());
          // clearing root reducer is not enough because config used by initial state will cache the order_id
          dispatch(resetOrderState());
          // Navigate up a level which will be where the CPQ launched from in all current scenarios
          history.push(".");
        },
      })
    );
  };
}
