import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getOrderContact } from "../../../../store/account/selectors";
import {
  getAccountId,
  getApprovalSubmitted,
  getOrderId,
  getProvisioningBundleId,
  getUploadedContractId,
} from "../../../../store/order/selectors";
import { getHostEnv } from "../../../../store/order/selectors/getHostEnv";
import { getPendingContractId } from "../../../../store/generateContract/selectors";
import { doOrderCompleteCallback } from "./utils/doOrderCompleteCallback";
import { CompleteMessage } from "../../components/CompleteMessage";
import { ViewSalesStatusButton } from "./ViewSalesStatusButton";
import { useCanAccessVfDirect } from "../../../../shared/hooks/useCanAccessVfDirect";

/**
 * Message shown when order / sale / whatever is complete
 *
 * - The action is different depending on where AS has been launched from.
 * - The message is different depending on what type of order has been made
 *
 * @constructor
 */
export const CompleteMessageContainer = () => {
  const orderContact: any = useSelector(getOrderContact);
  const accountId: string = useSelector(getAccountId);
  const orderId: string = useSelector(getOrderId);
  const pendingContractId = useSelector(getPendingContractId);
  const uploadedContractId = useSelector(getUploadedContractId);
  const provisioningBundleId = useSelector(getProvisioningBundleId);
  const approvalRequired = useSelector(
    (state: any) => state.order.orderStatus.response.data?.approval?.required
  );
  const approvalSubmitted = useSelector(getApprovalSubmitted);
  const baseUrl = useSelector<any, string>((state) => state.order?.baseUrl);
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();
  const customerName = useSelector((state: any) => state.order.customerName);

  useEffect(() => {
    doOrderCompleteCallback();
  }, []);

  return (
    <CompleteMessage
      accountId={accountId}
      hostEnv={hostEnv}
      baseUrl={baseUrl}
      pendingContractId={pendingContractId}
      uploadedContractId={uploadedContractId}
      orderId={orderId}
      orderContact={orderContact}
      provisioningBundleId={provisioningBundleId}
      approvalRequired={approvalRequired}
      approvalSubmitted={approvalSubmitted}
      canAccessVfDirect={canAccessVfDirect}
      customerName={customerName}
      platformButton={<ViewSalesStatusButton />}
    />
  );
};
