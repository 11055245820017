import { useAuth } from "@akj-dev/affinity-platform";
import { useQuery } from "react-query";
import {
  wlr3AddressSearch,
  WLR3AddressSearchResponse,
} from "../api/availabilityCheckApi";

/**
 * Lookup addresses at a postcode
 * @param postcode
 */
export function useAddressSearch(postcode: string) {
  const { accountMeta } = useAuth();
  return useQuery<WLR3AddressSearchResponse, Error>(
    ["addressSearch", postcode],
    async () => {
      const response = await wlr3AddressSearch(postcode, false, accountMeta.id);
      if (response.data.status === "error") throw Error(response.data.message);
      return response.data;
    },
    {
      enabled: false,
      retry: false,
    }
  );
}
