import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material";

import { SmallStatus } from "../SmallStatus";
import { useCanAccessVfDirect } from "../../hooks/useCanAccessVfDirect";
import { useAccountSettings } from "../../hooks/useAccountSettings";
import * as ProductAPI from "../../../api/v1/product";

import { ContractLengthSelect } from "./Select";
import { format } from "date-fns";
import { DC_DATE_FORMAT, todaysDateString } from "../../utils/date";
import { BrowserDateField, StatusChip } from "@akj-dev/shared-components";
import { getHostEnv } from "../../../store/order/selectors/getHostEnv";

interface ContractLengthSectionProps {
  daisyFreshAvailableForContractLength: boolean;
  contractCoTerminus: boolean;
  contractLength: number;
  contractFilterLength: number;
  toggleCoTerminus: () => void;
  handleContractLengthInputChange: (newValue: number, date?: string) => void;
  handleNumberOfMonthsChange: (newValue: string | 0) => void;
}

export const ContractLengthSection = ({
  daisyFreshAvailableForContractLength,
  contractCoTerminus,
  contractLength,
  contractFilterLength,
  toggleCoTerminus,
  handleContractLengthInputChange,
  handleNumberOfMonthsChange,
}: ContractLengthSectionProps) => {
  const tomorrow = new Date(todaysDateString).setDate(new Date().getDate() + 1);
  const [endDate, setEndDate] = useState<string>("");
  const accountSettings = useAccountSettings();
  const canAccessVfDirect = useCanAccessVfDirect();

  const hostEnv = useSelector(getHostEnv);
  const canSetCoterminus =
    accountSettings.as_can_create_coterminus_contract === "1";
  const canAccessNewCoterminus =
    accountSettings.as_can_access_new_coterminus === "1";
  const restrictContractTerms =
    accountSettings.can_restrict_contract_terms_by_product === "1";

  const [length, setLength] = useState<number[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    async function getContractLengths(type: string) {
      setLoading(true);
      const response = await ProductAPI.availableContractLengths(type);
      if (response.status === "success") {
        setLength(response.terms.map(Number));
        setLoading(false);
      } else {
        setError(response.message);
        setLoading(false);
      }
    }

    restrictContractTerms && hostEnv !== "dc"
      ? getContractLengths("Mobile").catch((e) => setError(e))
      : canAccessVfDirect
      ? setLength([1, 12, 18, 24, 36])
      : setLength([1, 3, 12, 24, 36, 48, 60, 72]);
  }, [restrictContractTerms, hostEnv, canAccessVfDirect]);

  // For 'Co Terminus'.
  const min = 0;

  const handleSetEndDate = (date: string) => {
    setEndDate(date);
    handleContractLengthInputChange(0, date);
  };

  return (
    <>
      {canSetCoterminus && (
        <Grid item md={4} lg={3}>
          <FormControlLabel
            control={
              <Switch
                onChange={toggleCoTerminus}
                checked={contractCoTerminus}
              />
            }
            label="Co Terminus"
            labelPlacement="start"
          />
        </Grid>
      )}
      {(canAccessVfDirect || !contractCoTerminus) && (
        <Grid item md={3}>
          {error && <StatusChip message={error} type="error" />}
          {!loading && length && (
            <ContractLengthSelect
              possibleContractLengths={length}
              contractLength={contractFilterLength || contractLength}
              handleChange={(event: SelectChangeEvent<string>) => {
                const value = parseInt(event.target.value, 10);
                if (!isNaN(value)) {
                  handleContractLengthInputChange(value);
                } else {
                  // Handle invalid number case
                  handleContractLengthInputChange(0); // or any default value
                }
              }}
              isDisabled={loading}
            />
          )}
          {loading && (
            <StatusChip
              type="loading"
              title="Please wait"
              message="Checking Contract Length Availability"
            />
          )}
        </Grid>
      )}
      {contractCoTerminus && (
        <Grid item md={4} lg={3} xl={2}>
          {!canAccessVfDirect && canAccessNewCoterminus ? (
            <BrowserDateField
              value={endDate}
              label="End Date"
              fullWidth={true}
              onChange={(date: string) => handleSetEndDate(date)}
              minDate={format(tomorrow, DC_DATE_FORMAT)}
              onBlur={() => {
                const endDateAsDate = new Date(endDate).getTime();
                if (endDateAsDate < tomorrow)
                  handleSetEndDate(format(tomorrow, DC_DATE_FORMAT));
              }}
            />
          ) : (
            <TextField
              type="number"
              label="Number of Months"
              value={contractLength}
              onChange={(event) => {
                const { value } = event.target;
                handleNumberOfMonthsChange(!value ? min : value);
              }}
              fullWidth
              variant="outlined"
              style={{
                minWidth: 150,
              }}
              inputProps={{ min }}
            />
          )}
        </Grid>
      )}
      {contractCoTerminus && (
        <Grid item md={12} xl={4}>
          <SmallStatus type="info">
            <span>
              Entering a value in Number of Months will override the contract
              length for all products selected
            </span>
          </SmallStatus>
        </Grid>
      )}
      {accountSettings.as_daisy_fresh_enabled === "1" &&
        contractLength > 0 &&
        !daisyFreshAvailableForContractLength && (
          <Grid item md={4}>
            <SmallStatus type="info">
              <span>
                You must select a <b>24 - 36 month</b> contract length to use
                daisy fresh.
              </span>
            </SmallStatus>
          </Grid>
        )}
    </>
  );
};
