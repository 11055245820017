import { useSelector } from "react-redux";

import { AccountSettings } from "@akj-dev/affinity-platform";

import { getAccountSettings } from "../../store/account/selectors";

export const useDwsResellerEnabled = () => {
  const accountSettings = useSelector<any, AccountSettings>(getAccountSettings);

  return accountSettings.dws_reseller_enabled === "1";
};
