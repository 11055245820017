import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { invalidateDraft, saveDraftState } from "./actions";

const drafts = createSlice({
  name: "drafts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveDraftState.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(saveDraftState.rejected, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.error = action.payload;
      })
      .addCase(saveDraftState.fulfilled, (state) => {
        state.isFetching = false;
        state.didInvalidate = false;
      })
      .addCase(invalidateDraft, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = true;
        state.error = action.payload;
      });
  },
});

export default drafts.reducer;
