import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAccountId } from "../../../../../store/order/selectors";
import {
  fetchAccountBoltOns,
  fetchBoltOnSearch,
} from "../../../../../store/mobile/actions";
import { getAccountSettings } from "../../../../../store/account/selectors";
import { ReduxAccountSettingsEnum } from "../../../../../shared/enums";
import { BoltOnTable } from "../../components/BoltOnTable";
import { ReduxBoltOnTableType } from "../../types";

export const BoltOnTableContainer = () => {
  const dispatch = useDispatch();
  const { accountId, boltOnSearch, accountBoltOns } = useSelector<
    any,
    ReduxBoltOnTableType
  >((state) => ({
    accountId: getAccountId(state),
    boltOnSearch: state.mobile.boltOnSearch,
    accountBoltOns: state.mobile.accountBoltOns,
    selectedBoltOns: state.mobile.selectedBoltOns,
  }));
  const settings = useSelector<
    any,
    { [k in ReduxAccountSettingsEnum]: "0" | "1" }
  >(getAccountSettings);

  const withO2 = settings.as_can_select_o2_products === "1";

  const providers = useMemo(() => {
    const localProviders = [{ id: "2", label: "Vodafone" }];

    if (withO2) {
      localProviders.push({ id: "1", label: "O2" });
    }

    return localProviders;
  }, [withO2]);

  const dataLoaded =
    boltOnSearch?.response?.status === "success" &&
    accountBoltOns?.response?.status === "success";

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchBoltOnSearch());
      accountId && dispatch(fetchAccountBoltOns());
    }
  }, [accountId, dispatch, dataLoaded]);

  const boltOnErrorMessage =
    boltOnSearch?.response?.status === "error" &&
    boltOnSearch.response?.message;
  const accountBoltOnsErrorMessage =
    accountBoltOns?.response?.status === "error" &&
    accountBoltOns.response?.message;

  const errorMessage = boltOnErrorMessage || accountBoltOnsErrorMessage;

  const showLoader = boltOnSearch.fetching || accountBoltOns.fetching;

  return (
    <BoltOnTable
      showLoader={showLoader}
      errorMessage={errorMessage}
      providers={providers}
      onRetry={() => dispatch(fetchAccountBoltOns())}
    />
  );
};
