import { ReduxAccountSettingsEnum } from "../../shared/enums";
import { STEP_CHOOSE } from "./constants";
import { UIState } from "./types";
import { getSteps } from "./utils";

const initialState: UIState = {
  flashMessages: [],
  alertMessages: [],
  stepOneWlrOpen: false,
  stepOneBBOpen: false,
  stepOneMobileOpen: false,
  stepOneHardwareOpen: false,
  stepOneEthernetOpen: false,
  stepOneWlrBroadbandOpen: false,
  stepOneUniversalProductsOpen: false,
  stepOneMonitoringServiceOpen: false,
  stepTwoHardwareOpen: false,
  stepTwoBoltOnsOpen: false,
  orderStatusOpen: false,
  activeStep: STEP_CHOOSE,
  steps: getSteps(false),
  visited: [true],
  showFinalStepWarning: false,
  finalStepWarningShown: false,
  productTypes: [],
  activeSubStepChoose: ReduxAccountSettingsEnum.SELECT,
  chooseHardwareConfig: {},
  showMobileResigns: false,
  showWlrResigns: false,
};

export default initialState;
