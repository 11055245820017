import React from "react";
import { Typography, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export interface ChipInfoProps {
  label: string;
  href?: string;
}

export const ChipInfo = ({ label, href }: ChipInfoProps) => {
  const classes = useStyles();

  return href ? (
    <a
      className={classes.href}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  ) : (
    <Typography className={classes.chip} variant="body1">
      {label}
    </Typography>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    marginLeft: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(1),
  },
  href: {
    padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
    marginLeft: theme.spacing(0.5),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
    textDecoration: "none",
  },
}));
