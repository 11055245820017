import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { toggleStepOneMonitoringServiceProducts } from "../../../store/uiState/actions";

import { ProductsContainer } from "./containers/Products";

export const MonitoringServiceProducts = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleStepOneMonitoringServiceProducts());
  }, [dispatch]);

  return <ProductsContainer />;
};
