import React from "react";
import { CreditCheckStatus } from "../../../../api/v1/account";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

interface StatusHelperIconProps {
  hasError: boolean;
  creditCheckStatus: CreditCheckStatus | undefined;
}

export const StatusHelperIcon = ({
  hasError,
  creditCheckStatus,
}: StatusHelperIconProps) => {
  let message = null;
  if (hasError) {
    message =
      "Please refresh the Credit Vet request. If this error continues, please contact the Giacom Support Team.";
  } else if (creditCheckStatus === CreditCheckStatus.Refer) {
    message =
      "The Credit request has been referred. You can submit this order to raise a request to Giacom for review.";
  } else if (creditCheckStatus === CreditCheckStatus.Declined) {
    message =
      "The Credit request has been declined. Unfortunately you cannot complete this sale. Please contact Giacom for further details.";
  }

  if (message) {
    return (
      <Tooltip placement="right" title={message}>
        <IconButton aria-label="status info" size="small">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  }
  return null;
};
