import React, { ReactNode } from "react";

import { Button, Box, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeIcon from "@mui/icons-material/Home";

import { ExpandingCard } from "@akj-dev/shared-components";

import { getAddressLine } from "../../../../../shared/utils/addresses";
import { Spinner } from "../../../../../shared/components/Spinner";
import { ReduxWlrLocation } from "../../types";
import { WlrLocationInstallType } from "../../components/WlrLocation/WlrLocationInstallType";
import { WlrLocationFindLocation } from "../../components/WlrLocation/WlrLocationFindLocation";

export interface WlrLocationProps {
  location: ReduxWlrLocation;
  locationIndex: number;
  open: boolean;
  lineSearchFetching: boolean;
  contractLength: number;
  configurations: Record<string, any>[];
  productContainer: ReactNode;
  withSingleLineProducts: boolean;
  saveAddress: (address: any) => void;
  handleLocationCli: (index: number, newCli: string) => void;
  handleLocationType: (type: string) => void;
  handleLocationOpened: (i: number) => void;
  handleResignOpened: () => void;
  onRemoveLocation: (index: number) => void;
  onRemoveAllProducts: () => void;
}

export const WlrLocation = ({
  location,
  locationIndex,
  open,
  lineSearchFetching,
  contractLength,
  configurations,
  productContainer,
  withSingleLineProducts,
  handleLocationOpened,
  handleResignOpened,
  handleLocationCli,
  handleLocationType,
  saveAddress,
  onRemoveAllProducts,
  onRemoveLocation,
}: WlrLocationProps) => (
  <ExpandingCard
    title={getAddressLine(location.address) || "New Location"}
    subtitle={location.type}
    avatar={lineSearchFetching ? <Spinner /> : <HomeIcon />}
    data-cy={`Location-${locationIndex}`}
    expanded={open}
    onHeaderClick={() => {
      handleResignOpened();
      handleLocationOpened(locationIndex);
    }}
    headerAction={
      <Button
        color="primary"
        startIcon={<DeleteIcon />}
        onClick={(e) => {
          e.stopPropagation();
          onRemoveLocation(locationIndex);
        }}
      >
        Remove This Location
      </Button>
    }
  >
    {open && (
      <>
        <WlrLocationInstallType
          contractLength={contractLength}
          location={location}
          setLocationType={handleLocationType}
          withSingleLineProducts={withSingleLineProducts}
        />
        <WlrLocationFindLocation
          location={location}
          locationIndex={locationIndex}
          handleLocationCli={handleLocationCli}
          saveAddress={saveAddress}
        />

        <Grid item>{productContainer}</Grid>

        <Box display="flex" justifyContent="space-between" mt={1}>
          {configurations.find(
            (config: any) => config.locationIndex === locationIndex
          ) && (
            <Button
              color="primary"
              variant="outlined"
              onClick={onRemoveAllProducts}
            >
              Remove All Products
            </Button>
          )}
        </Box>
      </>
    )}
  </ExpandingCard>
);
