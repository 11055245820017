import React from "react";
import styled, { keyframes } from "styled-components";

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const Outer = styled.svg`
  .path {
    fill: none;
    stroke: #fff;
    stroke-width: 3;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  .circ {
    stroke-dasharray: 130;
    stroke-dashoffset: 130;
    animation: ${draw} 1.5s ease-out forwards;
  }
  .tick {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: ${draw} 2s ease-out forwards;
  }
`;
export const CheckCircleAnimated = () => {
  return (
    <Outer
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 37"
      width="100"
      height="100"
    >
      <path
        className="circ path"
        d="
M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
      />
      <polyline
        className="tick path"
        points="
11.6,20 15.9,24.2 26.4,13.8 "
      />
    </Outer>
  );
};
