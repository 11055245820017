import React from "react";

import Button from "@mui/material/Button";
import WorkIcon from "@mui/icons-material/Work";
import { Box } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MoreIcon from "@mui/icons-material/More";
import RefreshIcon from "@mui/icons-material/Refresh";
import makeStyles from "@mui/styles/makeStyles";

import { ExpandingCard, StatusChip } from "@akj-dev/shared-components";

import { SortableTable } from "../../../../shared/components/SortableTable";

import { OrderStatusChip } from "./OrderStatusChip";
import { formatCurrency } from "../../../../shared/utils/formatCurrency";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

interface OrderStatusProps {
  orders: Record<string, any>;
  onLoadMore: () => void;
  onRefresh: () => void;
}

export const OrderStatus = ({
  orders,
  onLoadMore,
  onRefresh,
}: OrderStatusProps) => {
  const classes = useStyles();

  if (orders.response.status === "error")
    return (
      <StatusChip
        type="error"
        title="Error retrieving orders for account"
        message={orders.response.message}
      />
    );

  if (orders.response.orders)
    return (
      <Box mt={2}>
        <ExpandingCard
          title="Your Orders"
          subtitle={`${orders.response.orders.length} total`}
          avatar={<WorkIcon />}
          defaultExpanded
        >
          <SortableTable
            limitHeight
            columns={[
              {
                header: "Order ID",
                accessor: "id",
              },
              {
                header: "Status",
                accessor: "status",
              },
              {
                header: "Date Placed",
                accessor: "date_modified",
              },
              {
                header: "One off price",
                accessor: "price.one_off_price_with_promotions",
              },
              {
                header: "Recurring price",
                accessor: "price.first_bill_recurring_price_with_promotions",
              },
            ]}
            data={orders.response.orders}
            renderRow={(data) => (
              <TableRow key={data.id}>
                <TableCell>{data.id}</TableCell>
                <TableCell>
                  <OrderStatusChip status={data.status} />
                </TableCell>
                <TableCell>{data.date_modified}</TableCell>
                <TableCell>
                  {formatCurrency(data.price.one_off_price_with_promotions)}
                </TableCell>
                <TableCell>
                  {formatCurrency(
                    data.price.first_bill_recurring_price_with_promotions
                  )}
                </TableCell>
              </TableRow>
            )}
          />
          <Button
            variant="contained"
            endIcon={<MoreIcon />}
            disabled={orders.fetching}
            onClick={onLoadMore}
            className={classes.button}
          >
            Load more
          </Button>
          <Button
            variant="contained"
            endIcon={<RefreshIcon />}
            disabled={orders.fetching}
            onClick={onRefresh}
            className={classes.button}
          >
            Refresh
          </Button>
        </ExpandingCard>
      </Box>
    );

  return null;
};
