import React from "react";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CancelSaleButton } from "../containers/CancelSaleButton";

export interface ActionsProps {
  continueDisabled?: boolean;
  backDisabled?: boolean;
  continueHidden?: boolean;
  backHidden?: boolean;
  onContinue?: () => void;
  onBack?: () => void;
  shouldDisplayCancelSaleButton?: boolean;
  LeftButtonText?: String;
  RightButtonText?: String;
}

export const Footer = ({
  continueDisabled,
  backDisabled,
  continueHidden,
  backHidden,
  shouldDisplayCancelSaleButton,
  onContinue,
  onBack,
  LeftButtonText,
  RightButtonText,
}: ActionsProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <div>
      {!backHidden && (
        <Button
          color="primary"
          variant="outlined"
          size="large"
          startIcon={<ArrowBackIcon />}
          disabled={backDisabled}
          onClick={onBack}
          data-cy="StepNavFooterBack"
        >
          {LeftButtonText || "Previous"}
        </Button>
      )}

      {shouldDisplayCancelSaleButton && <CancelSaleButton />}
    </div>

    {!continueHidden && (
      <Button
        color="primary"
        variant="contained"
        size="large"
        endIcon={<ArrowForwardIcon />}
        disabled={continueDisabled}
        onClick={onContinue}
        data-cy="StepNavFooterNext"
      >
        {RightButtonText || "Next"}
      </Button>
    )}
  </Box>
);
