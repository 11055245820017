import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState from "./state";
import {
  clearConfig,
  getStockLevel,
  setFields,
  setQuantity,
  toggleExpand,
} from "./actions";
import _ from "lodash";
import { addressTypes } from "../../hardwareDelivery/constants";

const configurations = createSlice({
  name: "hardwareConfigurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setQuantity.fulfilled, (state, action: PayloadAction<any>) => {
        const { product, quantity, daisyFreshQuantity, commissionType } =
          action.payload;
        if (quantity === 0 && daisyFreshQuantity === 0) {
          return _.omit(state, product.id);
        } else {
          state[product.id] = {
            quantity: quantity,
            product: product,
            rows: Array(quantity + daisyFreshQuantity).fill({
              addressType: addressTypes.BILLING,
              addressId: "",
            }),
            daisyFreshQuantity: daisyFreshQuantity,
            commission_type: commissionType,
          };
        }
      })
      .addCase(toggleExpand, (state, action) => {
        const productId = action.payload;
        state[productId].expanded = !state[productId]["expanded"];
      })
      .addCase(setFields, (state, action) => {
        const { payload } = action;
        // TODO: This action type is too generic. We're ending up with too much BL outside the reducer.
        // TODO: Avoid cloneDeep here. Performance.
        let rows = _.cloneDeep(_.get(state[payload.productId], "rows", []));

        // Prevent negative credit values
        if (payload.fields.credit_used < 0) payload.fields.credit_used = 0;

        if (payload.index === undefined) {
          const quantity =
            state[payload.productId].quantity +
            (state[payload.productId].daisyFreshQuantity || 0);
          // Set the fields for all configurations of current product
          for (let i = 0; i < quantity; i++) {
            rows[i] = {
              ...rows[i],
              ...payload.fields,
            };
          }
        } else {
          // Set the fields just for a particular configuration of current product
          rows[payload.index] = {
            ...rows[payload.index],
            ...payload.fields,
          };
        }
        state[payload.productId].rows = rows;
      })
      .addCase(getStockLevel.pending, (state, action) => {
        const productId = action.meta.arg;
        state[productId] = {
          ...state[productId],
          stockLevel: {
            fetching: true,
          },
        };
      })
      .addCase(getStockLevel.fulfilled, (state, action: PayloadAction<any>) => {
        const { productId, response } = action.payload;
        state[productId].stockLevel = {
          fetching: false,
          response,
        };
      })
      .addCase(clearConfig, (state, action) => {
        return initialState;
      });
  },
});

export default configurations.reducer;
