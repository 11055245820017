import React from "react";
import { EthernetOrderForm } from "../../../components/Ethernet/OrderForm";

interface EthernetOrderFormContainerProps {
  index: number;
  configuration: Record<string, any>;
}

export const EthernetOrderFormContainer = (
  props: EthernetOrderFormContainerProps
) => <EthernetOrderForm {...props} />;
