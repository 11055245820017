import { SaleStepEnum } from "../enums";
import { STEP_SUMMARY } from "../../store/uiState/constants";

export const isStepDisabled = (step: SaleStepEnum, state: any) => {
  const { activeStep, steps, visited } = state;
  const activeIndex = steps.findIndex((s: SaleStepEnum) => s === activeStep);
  const stepIndex = steps.findIndex((s: SaleStepEnum) => s === step);
  const isVisited = !!visited[stepIndex - 1];

  if (
    (!isVisited && stepIndex > activeIndex + 1) ||
    (activeStep === STEP_SUMMARY && step !== STEP_SUMMARY)
  )
    return true;
  return false;
};
