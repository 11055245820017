import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { StatusChip } from "@akj-dev/shared-components";
import { UploadUniversalDocumentsContainer } from "../containers/UploadUniversalDocuments";
import _ from "lodash";

interface AddDocumentsProps {}

export const AddDocuments: React.FC<AddDocumentsProps> = () => {
  const orderStatus = useSelector((state: any) => state.order.orderStatus);
  const reasonsNotConfigured = _.get(
    orderStatus.response,
    "data.reasons_not_configured.has_universal_product_documents"
  );
  if (reasonsNotConfigured) {
    return (
      <Box my={1}>
        <StatusChip
          type="info"
          message={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {Object.keys(reasonsNotConfigured).map((key) => (
                <Box key={key} mr={1}>
                  {reasonsNotConfigured[key]}
                </Box>
              ))}
              <Box>
                <UploadUniversalDocumentsContainer />
              </Box>
            </Box>
          }
        />
      </Box>
    );
  }
  return null;
};
