import React, { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  useQueryParams,
  encodeArray,
  withDefault,
  ArrayParam,
  StringParam,
} from "use-query-params";

import { Board } from "./components/Board";
import { Filters } from "./components/Filters";
import { Orders } from "./components/Orders";
import { useSelector, useDispatch } from "react-redux";

import { Status } from "../../common/Status";
import { selectOrdersGroupedByStatus } from "../../../store/board/selectors";
import { RootState } from "../../../store/rootReducer";
import { dateStringSubMonth, toDateString } from "../../../helpers/dates";
import {
  decodePermittedArrayParam,
  decodeDateStringParam,
} from "../../../helpers/queryParsing";
import { requestBoardData } from "../../../store/board/actions";
import {
  OrderType,
  ParsedQueryParam,
  orderTypes,
  DateString,
  ProductType,
  productTypes,
  OrderStatus,
  orderStatuses,
  AcquisitionMethod,
  acquisitionMethods,
} from "../../../types";
import { requestOrderNumbers } from "../../../store/autocomplete/actions";

export const OrderStatusBoard = () => {
  const dispatch = useDispatch();
  const groupedOrders = useSelector(selectOrdersGroupedByStatus);
  const fetchingOrders = useSelector(
    (state: RootState) => state.board.fetching
  );
  const error = useSelector((state: RootState) => state.board.error);

  const [filters, updateFilters] = useQueryParams({
    order_type: {
      encode: (val: OrderType[]) => encodeArray(val),
      decode: (input: ParsedQueryParam) =>
        decodePermittedArrayParam(input, orderTypes.slice()),
    },
    created_after: withDefault(
      {
        encode: (val: DateString) => val,
        decode: decodeDateStringParam,
      },
      dateStringSubMonth
    ),
    created_before: withDefault(
      {
        encode: (val: DateString) => val,
        decode: decodeDateStringParam,
      },
      toDateString(new Date())
    ),
    product_type: {
      encode: (val: ProductType) => val,
      decode: (input: ParsedQueryParam) =>
        productTypes.find((type) => type === input),
    },
    cust_reference: StringParam,
    order_status: {
      encode: (val: OrderStatus[]) => encodeArray(val),
      decode: (input: ParsedQueryParam) =>
        decodePermittedArrayParam(input, orderStatuses.slice()),
    },
    order_owner_id: StringParam,
    order_numbers: withDefault(ArrayParam, []),
    account: StringParam,
    billing_references: withDefault(ArrayParam, []),
    sales_person_id: StringParam,
    clis: withDefault(ArrayParam, []),
    partner_references: withDefault(ArrayParam, []),
    acquisition_methods: {
      encode: (val: AcquisitionMethod[]) => encodeArray(val),
      decode: (input: ParsedQueryParam) =>
        decodePermittedArrayParam(input, acquisitionMethods.slice()),
    },
  });

  const [dateFilters, updateDateFilters] = useQueryParams({
    created_after: withDefault(
      {
        encode: (val: DateString) => val,
        decode: decodeDateStringParam,
      },
      dateStringSubMonth
    ),
    created_before: withDefault(
      {
        encode: (val: DateString) => val,
        decode: decodeDateStringParam,
      },
      toDateString(new Date())
    ),
  });

  const resetFilters = () => {
    let newFilters: { [key: string]: any } = {};

    for (let filter in filters) {
      newFilters[filter] = undefined;
    }

    updateDateFilters(newFilters);
    updateFilters(newFilters);
  };

  useEffect(() => {
    dispatch(requestOrderNumbers(dateFilters));
  }, [dispatch, dateFilters]);

  useDeepCompareEffect(() => {
    dispatch(requestBoardData(filters));
  }, [filters]);

  return (
    <Board
      filterMenu={
        <Filters
          filters={filters}
          updateFilters={updateFilters}
          onReset={resetFilters}
        />
      }
    >
      <Status loading={fetchingOrders} error={error}>
        <Orders orders={groupedOrders} />
      </Status>
    </Board>
  );
};
