import React from "react";
import { ChevronRight } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";

interface ExpanderIconProps {
  expanded?: boolean;
  "data-cy"?: string;
}

export const ExpanderIcon = ({ expanded, ...other }: ExpanderIconProps) => {
  const classes = useStyles();
  return (
    <ChevronRight
      className={`${classes.expanderIcon} ${expanded ? classes.expanded : ""}`}
      {...other}
    />
  );
};

export const useStyles = makeStyles({
  expanderIcon: {
    transition: "transform .3s",
    transform: "rotate(0deg)",
  },
  expanded: {
    transform: "rotate(90deg)",
  },
});
