import React, { ChangeEvent, ReactNode } from "react";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { MoreInformationPopover } from "../MoreInformationPopover";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import { getTotalPrice } from "../../../../../../store/wlrBroadband/selectors";
import { LinkIcon } from "../../../../../../shared/components/Icons/LinkIcon";
import { useTheme } from "@mui/material/styles";

const CustomCell = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

interface NoProductChangesRowProps {
  productInstance: Record<string, any>;
  isChecked: boolean;
  isLinkedProductInstance: boolean;
  actions: ReactNode;
  toggleRow: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const NoProductChangesRow = ({
  isChecked,
  toggleRow,
  productInstance,
  isLinkedProductInstance,
  actions,
}: NoProductChangesRowProps) => {
  const theme = useTheme();

  const { evo_services } = productInstance;

  return (
    <>
      <TableRow selected={isChecked}>
        <TableCell>
          <Checkbox
            color="secondary"
            onChange={toggleRow}
            checked={isChecked}
          />
        </TableCell>
        <TableCell>{productInstance.pin}</TableCell>
        <TableCell>{isLinkedProductInstance && <LinkIcon />}</TableCell>
        <TableCell colSpan={2}>
          <CustomCell>
            {productInstance.broadband ? (
              <PublicIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            ) : (
              <PhoneIcon
                style={{ color: theme.palette.grey[500], marginRight: 10 }}
              />
            )}
            <div>{productInstance.name_for_resign || productInstance.name}</div>
          </CustomCell>
        </TableCell>
        <TableCell>
          <MoreInformationPopover productInstance={productInstance} />
        </TableCell>
        <TableCell>&pound;{getTotalPrice(evo_services)}</TableCell>
        <TableCell className="u-nowrap">
          {productInstance.end_date_ymd}
        </TableCell>
        <TableCell className="u-text-right" style={{ paddingRight: 0 }}>
          {actions}
        </TableCell>
      </TableRow>
    </>
  );
};
