import React from "react";

import { Chip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import makeStyles from "@mui/styles/makeStyles";

import { ResultsEntity } from "../../../../../api/v1/product";

interface CliCellProps {
  productInstance: ResultsEntity;
}

export const CliCell = ({ productInstance }: CliCellProps) => {
  const classes = useStyles();

  return (
    <TableCell>
      {productInstance.pin}
      {productInstance.resign_status === "full-term-renewable" && (
        <Chip
          label="Full Term Renewal"
          size="small"
          color="secondary"
          variant="filled"
          className={classes.chip}
        />
      )}
      {productInstance.resign_status === "in-life-renewable" && (
        <Chip
          label="In-Life Renewal"
          size="small"
          color="secondary"
          variant="filled"
          className={classes.chip}
        />
      )}
      {productInstance.resign_status === "not-renewable" && (
        <Chip
          label="Not Renewable"
          size="small"
          color="default"
          variant="filled"
          className={classes.chip}
        />
      )}
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  chip: {
    marginLeft: theme.spacing(1),
  },
}));
