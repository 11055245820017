import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { search } from "../../../../store/hardware/products/actions";
import { getDaisyFreshAllowedAmount } from "../../../../store/mobile/selectors/daisyFresh";
import { ActiveSort } from "../../../../shared/components/VirtualTable/types";
import { VirtualTableSortOrder } from "../../../../shared/components/VirtualTable/enums";
import {
  handleActiveSort,
  sortVirtualTableData,
  useDebouncedState,
} from "../../../../shared/components/VirtualTable/utils";
import { ReduxHardwareTableType } from "../types";
import { ChooseHardwareTable } from "../components/Table";

export const ChooseHardwareTableContainer = () => {
  const dispatch = useDispatch();
  const {
    hardwareProducts,
    hardwareConfigurations,
    hasConnections,
    contractLengthInMonthsAllProducts,
    daisyFreshHardwareTotal,
    daisyFreshAllowedAmount,
  } = useSelector<any, ReduxHardwareTableType>((state) => ({
    hardwareProducts: state.hardwareProducts,
    hardwareConfigurations: state.hardwareConfigurations,
    hasConnections: (state.mobile?.configs?.length ?? 0) > 0,
    contractLengthInMonthsAllProducts:
      state.mobile.contractLengthInMonthsAllProducts,
    daisyFreshHardwareTotal: state.mobile.daisyFreshAmounts.hardwareTotal,
    daisyFreshAllowedAmount: getDaisyFreshAllowedAmount(state.mobile),
  }));
  const {
    state: productFilter,
    debouncedState: debouncedProductFilter,
    handleState: handleProductFilter,
  } = useDebouncedState();
  const [activeSort, setActiveSort] = useState<ActiveSort>({
    sortIndex: "name",
    order: VirtualTableSortOrder.ASC,
  });

  const handleSort = useMemo(
    () => handleActiveSort(setActiveSort),
    [setActiveSort]
  );

  const fetching = hardwareProducts.fetching;
  const hardwareProductsResponse = hardwareProducts.response;
  const status = hardwareProductsResponse?.status;
  const error = hardwareProductsResponse?.message;
  const productsCount = hardwareProductsResponse?.products?.length;
  const configsCount = Object.values(hardwareConfigurations ?? {})
    .map((item: any) => item?.quantity ?? 0)
    .reduce((a: number, b: number) => a + b, 0);

  const filteredProducts = useMemo(() => {
    const products = hardwareProductsResponse?.products ?? [];

    return products
      .filter(
        ({ name }: { name: string }) =>
          name.toLowerCase().indexOf(debouncedProductFilter.toLowerCase()) !==
          -1
      )
      .sort(({ name: nameA = "" }, { name: nameB = "" }) =>
        nameA.localeCompare(nameB)
      );
  }, [hardwareProductsResponse?.products, debouncedProductFilter]);

  const sortedProducts = useMemo(() => {
    return filteredProducts.sort(sortVirtualTableData(activeSort));
  }, [filteredProducts, activeSort]);

  useEffect(() => {
    if (status !== "success") {
      dispatch(search());
    }
  }, [status, dispatch]);

  return (
    <ChooseHardwareTable
      showLoader={fetching}
      error={error}
      productFilter={productFilter}
      productsCount={productsCount ?? 0}
      configsCount={configsCount}
      hasConnections={hasConnections}
      activeSort={activeSort}
      filteredProductsCount={sortedProducts.length}
      handleProductFilter={handleProductFilter}
      handleSort={handleSort}
      tableData={{
        products: sortedProducts,
        hasConnections,
        configsCount,
        hardwareConfigurations,
        contractLengthInMonthsAllProducts,
        daisyFreshHardwareTotal,
        daisyFreshAllowedAmount,
      }}
      onRetry={() => dispatch(search())}
    />
  );
};
