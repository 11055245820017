import initialState, { UPBlankConfig } from "./state";
import { createSlice } from "@reduxjs/toolkit";
import {
  addProduct,
  addUserDocument,
  uploadDocument,
  fetchProductDocuments,
  fetchProductSearch,
  orderProduct,
  removeProduct,
  removeUserDocument,
  selectConfig,
  setConfigProperty,
  setUserDocumentSendPref,
  clearConfig,
  setProductDiscount,
  setPricingScheme,
  removeConfig,
} from "./actions";
import { UPConfig } from "./types";

const universalProducts = createSlice({
  name: "universalProducts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductSearch.pending, (state) => {
        state.productSearch.fetching = true;
      })
      .addCase(fetchProductSearch.fulfilled, (state, action) => {
        state.productSearch.fetching = false;
        state.productSearch.response = action.payload;
      })
      .addCase(addProduct, (state, action) => {
        // if it's a dynamic product look up recurring and one-off prices.
        const product = state.productSearch.response.products.find(
          (product: any) => product.id === action.payload
        );

        const recurringPrice =
          product.price.first_bill_recurring_price_with_promotions || "";
        const oneOffPrice = product.price.one_off_price_with_promotions || "";

        const hasValidPrices =
          parseFloat(oneOffPrice) > 0 || parseFloat(recurringPrice) > 0;

        state.configs.push({
          ...UPBlankConfig,
          recurringPrice,
          oneOffPrice,
          hasValidPrices,
          productId: action.payload,
        });
      })
      .addCase(removeProduct, (state, action) => {
        state.configs = state.configs.filter(
          (c) => c.productId !== action.payload
        );
      })
      .addCase(selectConfig, (state, action) => {
        state.selectedConfigIndex = action.payload;
      })
      .addCase(removeConfig, (state, action) => {
        state.configs.splice(action.payload, 1);
      })
      .addCase(clearConfig, (state) => {
        return initialState;
      })
      .addCase(fetchProductDocuments.pending, (state, action) => {
        const productId = action.meta.arg as any;
        state.productDocuments[productId] = {
          fetching: true,
          response: {},
        };
      })
      .addCase(fetchProductDocuments.rejected, (state, action) => {
        const productId = action.meta.arg as any;
        state.productDocuments[productId].fetching = false;
      })
      .addCase(fetchProductDocuments.fulfilled, (state, action) => {
        const productId = action.meta.arg as any;
        state.productDocuments[productId].fetching = false;
        state.productDocuments[productId].response = action.payload;
      })
      .addCase(addUserDocument, (state, action) => {
        const configIndex = state.selectedConfigIndex as number;
        if (typeof configIndex !== "number") return;
        const config = state.configs[configIndex];
        config.userDocuments.push(action.payload);
        config.userDocumentsSendPref.push(false);
      })
      .addCase(removeUserDocument, (state, action) => {
        const configIndex = state.selectedConfigIndex as number;
        const docIndex = action.payload;
        if (typeof configIndex !== "number" || typeof docIndex !== "number")
          return;
        const config = state.configs[configIndex];
        config.userDocuments = config.userDocuments.filter(
          (_, i) => i !== docIndex
        );
        config.userDocumentsSendPref = config.userDocumentsSendPref.filter(
          (_, i) => i !== docIndex
        );
      })
      .addCase(setUserDocumentSendPref, (state, action) => {
        const configIndex = state.selectedConfigIndex as number;
        const { index, pref } = action.payload;
        if (typeof configIndex !== "number" || typeof index !== "number")
          return;
        const config = state.configs[configIndex];
        config.userDocumentsSendPref[index] = pref;
      })
      .addCase(setConfigProperty, (state, action) => {
        const configIndex = state.selectedConfigIndex as number;
        if (typeof configIndex !== "number") return;
        const { property, value } = action.payload;
        const config: UPConfig = state.configs[configIndex];
        // this fixes a typescript typing error, might be a better solution
        // once we revisit fixing types across the repos.
        (config as any)[property] = value;
      })
      .addCase(orderProduct.pending, (state, action) => {
        const { configIndex, isUpdate } = action.meta.arg as any;
        const config: UPConfig = state.configs[configIndex];
        config.orderProduct = {
          fetching: true,
          isUpdate,
          response: {},
        };
      })
      .addCase(orderProduct.rejected, (state, action) => {
        const { configIndex } = action.meta.arg as any;
        const config = state.configs[configIndex];
        config.orderProduct.fetching = false;
      })
      .addCase(orderProduct.fulfilled, (state, action) => {
        const { configIndex } = action.meta.arg as any;
        const config = state.configs[configIndex];
        config.orderProduct.fetching = false;
        config.orderProduct.response = action.payload;
      })
      .addCase(uploadDocument.pending, (state, action) => {
        const { configIndex, docIndex } = action.meta.arg as any;
        if (typeof configIndex !== "number" || typeof docIndex !== "number")
          return;
        const config = state.configs[configIndex];
        config.userDocumentsUpload[docIndex] = { fetching: true };
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        const { configIndex, docIndex } = action.meta.arg as any;
        if (typeof configIndex !== "number" || typeof docIndex !== "number")
          return;
        const config = state.configs[configIndex];
        config.userDocumentsUpload[docIndex].fetching = false;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        const { configIndex, docIndex } = action.meta.arg as any;
        if (typeof configIndex !== "number" || typeof docIndex !== "number")
          return;
        const config = state.configs[configIndex];
        config.userDocumentsUpload[docIndex] = {
          fetching: false,
          response: action.payload,
        };
      })
      .addCase(setProductDiscount, (state, action) => {
        const { targetConfigs, discountType, priceType, value, fullPrice } =
          action.payload;
        targetConfigs.forEach((i: number) => {
          const config: UPConfig = state.configs[i];
          let newPrice: any;
          // if (priceType === "recurring") prevPrice = config.recurringPrice;
          // if (priceType === "one_off") prevPrice = config.oneOffPrice;
          switch (discountType) {
            case "DiscountPercentage":
              newPrice = (fullPrice / 100) * (100 - value);
              break;
            case "DiscountAmount":
              newPrice = fullPrice - value;
              break;
            case "SpecifyPrice":
              newPrice = value ? value : fullPrice;
              break;
            default:
              newPrice = fullPrice;
          }

          if (priceType === "recurring") config.recurringPrice = newPrice;
          if (priceType === "one_off") config.oneOffPrice = newPrice;
          config.universalProductProperties = {
            ...state.configs[i]["universalProductProperties"],
            ...(discountType !== null && {
              [`${priceType}_discount_type`]: discountType,
            }),
            [`${priceType}_discount_override`]: value === false ? 0 : 1,
            [`${priceType}_discount_value`]: value,
          };
        });
      })
      .addCase(setPricingScheme, (state, action) => {
        const { selectedConfigIndex, scheme } = action.payload;
        const config = state.configs[selectedConfigIndex];

        config.pricingScheme = scheme;
        // reset the config prices.
        const product = state.productSearch.response.products.find(
          (product: any) => product.id === config.productId
        );
        const pricingScheme = product.pricing_scheme_details[scheme];
        if (pricingScheme) {
          config.recurringPrice =
            pricingScheme.recurring_price_without_promotions;
          config.oneOffPrice = pricingScheme.one_off_price_with_promotions;
        }
        // reset the dynamic discount options in the UI.
        config.universalProductProperties = {};
      });
  },
});

export default universalProducts.reducer;
