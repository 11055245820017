import React from "react";
import { useSelector } from "react-redux";
import * as actions from "../../../../../../store/supportCase/actions";
import { SupportCase } from "../../../../components/Options/SupportCase";

export const SupportCaseContainer = () => {
  const supportCase = useSelector<any, any>((state) => state.supportCase);
  const open = !!supportCase?.open;
  const loading = !!supportCase?.loading;

  if (!supportCase.open) {
    return null;
  }

  return (
    <SupportCase
      loading={loading}
      open={open}
      supportCase={supportCase}
      actions={actions}
    />
  );
};
