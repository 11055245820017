import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import ChangeIcon from "./ChangeIcon";
import FormControlLabel from "@mui/material/FormControlLabel";

const Outer = styled.div`
  padding: 20px 0 0 0;
`;
const Price = styled.span`
  font-size: 0.9em;
  font-weight: bold;
  padding-left: 10px;
`;

const PseudoBoolean = ({
  dynamicProperty,
  setProperty,
  propertyValue,
  showWlrChangeIcon,
  recalculatePrices,
}) => {
  // Like standard select fields, these can have pricing data.
  // Only seems to be recurring price for now (MPF Voice Options)
  let price = _.get(
    dynamicProperty,
    "available_option_details.TRUE.recurring_price_with_discounts",
    0
  );
  if (parseFloat(price) === 0) price = false;

  return (
    <Outer>
      <FormControlLabel
        label={
          <span>
            {dynamicProperty.label}
            {dynamicProperty.is_required && " *"}
            {price && <Price>£{price} / month</Price>}
            {showWlrChangeIcon && <ChangeIcon style={{ marginBottom: -8 }} />}
          </span>
        }
        control={
          <Checkbox
            color="secondary"
            onChange={(e, isInputChecked) => {
              setProperty(isInputChecked ? "TRUE" : "FALSE");
              recalculatePrices();
            }}
            checked={propertyValue === "TRUE"}
          />
        }
      />
    </Outer>
  );
};

PseudoBoolean.propTypes = {
  dynamicProperty: PropTypes.object.isRequired,
  propertyValue: PropTypes.oneOf(["TRUE", "FALSE"]),
};

export default PseudoBoolean;
