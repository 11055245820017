import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { provisionOrder, sendForApproval } from "../../../store/order/actions";

import { ProvisionOrderButton } from "../components/ProvisionOrderButton";

/**
 * The Affinity Classic, MyAccount and Hardware only flow.
 */
export const ProvisionOrderButtonContainer = () => {
  const dispatch = useDispatch();
  const approval = useSelector((state: any) =>
    _.get(state.order.orderStatus.response, "data.approval", {})
  );
  const provision = useSelector((state: any) => state.order.provision);
  const sendingForApproval = useSelector<any, boolean>(
    (state) => state.order.sendForApproval.fetching
  );

  const fetching = sendingForApproval || provision.fetching;

  return (
    <ProvisionOrderButton
      isError={provision.response.status === "error"}
      fetching={fetching}
      errorMessage={provision.response.message}
      onPlaceOrderClick={() => {
        if (approval.required) {
          dispatch(sendForApproval());
        } else {
          dispatch(provisionOrder());
        }
      }}
    />
  );
};
