import React, { FocusEvent } from "react";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Grid, InputAdornment } from "@mui/material";

import { TextFieldLowUpdate } from "./TextFieldLowUpdate";
import { SliderLowUpdate } from "./SliderLowUpdate";
import { isValidMonetaryAmount } from "../../utils/validation";

interface DiscountFieldsProps {
  discountType: string;
  discountValue: number;
  priceType: string;
  minPrice: string;
  maxPercent: number;
  fullPrice: string;
  scheme?: string;
  setDiscount: (
    priceType: string,
    discountType: string | null,
    discountValue: string | number | null,
    fullPrice: any,
    scheme: string | undefined
  ) => void;
}
export const DiscountFields = ({
  discountType,
  discountValue,
  priceType,
  setDiscount,
  minPrice,
  fullPrice,
  maxPercent,
  scheme,
}: DiscountFieldsProps) => {
  let title = "Discount";
  if (priceType === "recurring") title = "Recurring Discount";
  if (priceType === "activation_fee.one_off") title = "Activation Fee Discount";

  const maxDiscount = parseFloat(fullPrice) - parseFloat(minPrice);

  return (
    <>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel variant="standard">{title}</InputLabel>
            <Select
              variant="standard"
              value={discountType || false}
              onChange={(event) =>
                setDiscount(
                  priceType,
                  event.target.value as string,
                  null,
                  fullPrice,
                  scheme
                )
              }
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="DiscountPercentage">
                Discount Percentage
              </MenuItem>
              <MenuItem value="DiscountAmount">Discount Amount</MenuItem>
              <MenuItem value="SpecifyPrice">Specify Price</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {discountType === "SpecifyPrice" && (
          <Grid item sm={6}>
            <TextFieldLowUpdate
              label="Specify Price"
              value={discountValue || ""}
              onBlur={(event: FocusEvent<HTMLInputElement>) => {
                if (
                  minPrice &&
                  parseFloat(event.target.value) < parseFloat(minPrice)
                )
                  return;

                setDiscount(
                  priceType,
                  "SpecifyPrice",
                  event.target.value,
                  fullPrice,
                  scheme
                );
              }}
              validate={(value: string) => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";

                if (minPrice && parseFloat(value) < parseFloat(minPrice))
                  return `Price too low. Minimum is £${minPrice}`;

                return "";
              }}
              type="number"
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {discountType === "DiscountAmount" && (
          <Grid item sm={6}>
            <TextFieldLowUpdate
              label="Discount Amount"
              value={discountValue || ""}
              onBlur={(event: FocusEvent<HTMLInputElement>) =>
                setDiscount(
                  priceType,
                  "DiscountAmount",
                  event.target.value,
                  fullPrice,
                  scheme
                )
              }
              validate={(value: string) => {
                if (value && !isValidMonetaryAmount(value))
                  return "Please enter a valid amount.";

                if (parseFloat(value) > maxDiscount)
                  return `Discount too high. Maximum is £${maxDiscount.toFixed(
                    2
                  )}`;

                return "";
              }}
              type="number"
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
      {discountType === "DiscountPercentage" && (
        <Grid item sm={12}>
          <SliderLowUpdate
            min={0}
            max={maxPercent}
            step={1}
            value={discountValue || 0}
            onDragStop={(value: number) => {
              setDiscount(
                priceType,
                "DiscountPercentage",
                value,
                fullPrice,
                scheme
              );
            }}
            data-cy="discountSlider"
          />
        </Grid>
      )}
    </>
  );
};
