import React from "react";

import { Grid, Typography } from "@mui/material";

import { IndexedTitle } from "@akj-dev/shared-components";

import { InstallationDetails } from "../../components/InstallationDetails";
import { WlrLocationProductCardContainer } from "../../containers/WlrLocation/ProductCard";

interface TransferProductSectionProps {
  locationIndex: number;
  installationDetails: Record<string, any>;
  transferProduct: Record<string, any>;
  mpfProducts: Record<string, any>[];
  multiLineProducts: Record<string, any>[];
  stopDaisyLineOrdersEnabled: boolean;
  isActivationCodeP: boolean;
  onSetOtherConfiguration: (
    locationIndex: number,
    product: Record<string, any>
  ) => void;
}

export const TransferProductSection = ({
  locationIndex,
  installationDetails,
  transferProduct,
  mpfProducts,
  multiLineProducts,
  stopDaisyLineOrdersEnabled,
  isActivationCodeP,
  onSetOtherConfiguration,
}: TransferProductSectionProps) => (
  <Grid container spacing={2}>
    <Grid item sm={12}>
      <Typography variant="h4" gutterBottom>
        Current Line
      </Typography>
      <InstallationDetails
        installationDetails={installationDetails.response}
        stopDaisyLineOrdersEnabled={stopDaisyLineOrdersEnabled}
      />
    </Grid>
    <Grid item sm={12}>
      <Typography variant="h4" gutterBottom>
        <IndexedTitle
          index="C"
          title="Choose transfer product"
          withMargin={true}
          negative={true}
        />
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={6}>
          <WlrLocationProductCardContainer
            locationIndex={locationIndex}
            productId={transferProduct.id}
            title={transferProduct.name}
            subtitle={`from £${transferProduct.price.first_bill_recurring_price_with_promotions} per line (RRP)`}
            handleClick={() =>
              onSetOtherConfiguration(locationIndex, transferProduct)
            }
          />
        </Grid>

        {mpfProducts.length > 0 &&
          !isActivationCodeP && ( // TODO: What's the deal with MPF transfers for DWP Pricebooks? Show all here too as above? What about service type matching?
            <Grid item sm={6}>
              <WlrLocationProductCardContainer
                locationIndex={locationIndex}
                productId={mpfProducts[0].id}
                title={mpfProducts[0].name}
                subtitle={`from £${mpfProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
                handleClick={() =>
                  onSetOtherConfiguration(locationIndex, mpfProducts[0])
                }
              />
            </Grid>
          )}

        {(installationDetails.response.service_type === "PSTN_MULTI_LINE_AUX" ||
          installationDetails.response.service_type ===
            "DC_PRODUCT_MULTI_LINE") && (
          <Grid item sm={12}>
            <WlrLocationProductCardContainer
              locationIndex={locationIndex}
              productId={multiLineProducts[0].id}
              title={multiLineProducts[0].name}
              subtitle={`from £${multiLineProducts[0].price.first_bill_recurring_price_with_promotions} per line (RRP)`}
              handleClick={() =>
                onSetOtherConfiguration(locationIndex, multiLineProducts[0])
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);
