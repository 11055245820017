import React, { useMemo } from "react";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MobileProduct } from "../../../../../../shared/types/general";
import { CSSProperties } from "react";
import { ProductTableRow } from "./Row";
import { MAX_QTY } from "./constants";

interface ProductTableRenderRowProps {
  index: number;
  style: CSSProperties;
  data: {
    products: MobileProduct[];
    allConfigsCount: number;
    showProductFamily: boolean;
  };
}

export const ProductTableRenderRow = ({
  index,
  style,
  data,
}: ProductTableRenderRowProps) => {
  const classes = useStyles();
  const { allConfigsCount, products, showProductFamily } = data;
  const product = products[index];

  const memoizedProduct = useMemo(
    () => ({
      id: product.id,
      name: product.name,
      family: product.family,
      dataOnly: product.dataOnly,
      network: product.network,
      recurringPrice: product.recurringPrice,
      priceBook: product.priceBook,
    }),
    [
      product.dataOnly,
      product.family,
      product.id,
      product.name,
      product.network,
      product.priceBook,
      product.recurringPrice,
    ]
  );

  return (
    <Box data-cy={product.name} className={classes.root} style={style}>
      <ProductTableRow
        product={memoizedProduct}
        configsCount={product.configsCount!}
        hasReachedMaxConfigCount={MAX_QTY < allConfigsCount}
        showProductFamily={showProductFamily}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    padding: `0 ${theme.spacing(1.5)}`,
    overflowX: "hidden",
  },
}));
