import React, { useCallback, useMemo } from "react";
import { Select, SelectChangeEvent, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface QuantityFieldSelectProps {
  disabled?: boolean;
  quantity?: number;
  showError: boolean;
  quantityThreshold: number;
  maxQuantity: number;
  setQuantity: (v: number | undefined) => void;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  allowInput: boolean;
}

export const QuantityFieldSelect = ({
  disabled,
  showError,
  quantity,
  quantityThreshold,
  maxQuantity,
  setQuantity,
  setShowInput,
  allowInput,
}: QuantityFieldSelectProps) => {
  const classes = useStyles();

  const options = useMemo(
    () => Array.from(Array(quantityThreshold ?? maxQuantity).keys()),
    [quantityThreshold, maxQuantity]
  );

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      const newValue = parseInt(e.target.value as string);

      if (!allowInput || newValue !== quantityThreshold) {
        setQuantity(newValue);
      } else {
        setQuantity(undefined);
        setShowInput(true);
      }
    },
    [quantityThreshold, setQuantity, setShowInput, allowInput]
  );

  const showThreshold = !!quantityThreshold && quantityThreshold < maxQuantity;

  return (
    <Select
      native
      className={classes.field}
      variant="outlined"
      error={showError}
      disabled={disabled}
      value={quantity}
      onChange={handleSelectChange}
    >
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      {showThreshold && (
        <option value={quantityThreshold}>{`${quantityThreshold}${
          allowInput ? "+" : ""
        }`}</option>
      )}
    </Select>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    minWidth: theme.spacing(5),
  },
}));
