import _ from "lodash";
import {
  call,
  delay,
  put,
  takeLeading,
  takeLatest,
  takeEvery,
  select,
  all,
} from "redux-saga/effects";
import * as ProductAPI from "../../api/v1/product";
import * as AccountAPI from "../../api/v1/account";
import * as GuidedSalesAPI from "../../api/v1/guidedSales";
import * as MobileAPI from "../../api/v1/mobile";
import * as SIMsAPI from "../../api/v1/sim";
import * as MobileProvisioningAPI from "../../api/v1/mobileProvisioning";
import { getAccountId, getOrderId } from "../order/selectors";
import * as actionTypes from "./actionTypes";
import { getMobileDCOrderParams } from "./selectors/order";
import {
  getConfigProperties,
  getProductComponentData,
  getProductDataForConfig,
} from "./selectors/productConfig";
import { getAccountSettings } from "../account/selectors";
import {
  receivePacVerification,
  setPacVerificationError,
} from "./legacy/actionCreators";

/**
 * Get available mobile products
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchMobileSearch(action) {
  const accountId = yield select(getAccountId);
  const leadId = yield select((state) => state.order.leadId);
  const is_dynamic = action.isDynamic ? 1 : 0;
  const response = yield call(
    ProductAPI.MobileSearch,
    accountId,
    is_dynamic,
    leadId
  );
  yield put({ type: actionTypes.RECEIVE_MOBILE_SEARCH, response });
}

export function* watchMobileSearch() {
  yield takeLeading(actionTypes.REQUEST_MOBILE_SEARCH, fetchMobileSearch);
}

/**
 * Get available account level bolt-ons
 * @returns {IterableIterator<*>}
 */
function* fetchBoltOnSearch() {
  const accountId = yield select(getAccountId);
  const response = yield call(ProductAPI.AccountBoltOnSearch, accountId);
  yield put({ type: actionTypes.RECEIVE_BOLT_ON_SEARCH, response });
}

export function* watchBoltOnSearch() {
  yield takeLeading(actionTypes.REQUEST_BOLT_ON_SEARCH, fetchBoltOnSearch);
}

/**
 * Get available CLI-level bolt-ons for a certain product
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchCliBoltOnProducts({ productId }) {
  const accountId = yield select(getAccountId);
  const accountSettings = yield select(getAccountSettings);

  const products = yield select((state) =>
    _.get(state.mobile.cliBoltOnSearch[productId], "response.products")
  );
  if (!products) {
    const response = yield ProductAPI.MobileBoltOnSearch(
      "cli",
      productId,
      accountId,
      false, // Is top up bolt on
      1,
      accountSettings.category_id_cli_bolt_ons,
      accountSettings.can_access_vf_direct === "1" ? 1 : undefined
    );
    yield put({
      type: actionTypes.RECEIVE_CLI_BOLT_ON_PRODUCTS,
      productId,
      response,
    });
    // If we successfully receive products from the above search, then
    // initiate the search to find the top up bolt ons and append these.
    // These have to be searched for separately.
    // See: https://auroratarget.tpondemand.com/entity/6400-data-top-ups.
    if (response.status === "success") {
      yield put({
        type: actionTypes.REQUEST_CLI_TOP_UP_BOLT_ON_PRODUCTS,
        productId,
        response,
      });
    }
  }
}

export function* watchCliBoltOnProducts() {
  yield takeEvery(
    actionTypes.REQUEST_CLI_BOLT_ON_PRODUCTS,
    fetchCliBoltOnProducts
  );
}

/**
 * Get available CLI-level top-up bolt-ons for a certain product
 * These have to be fetched separately to 'normal' cli bolt ons.
 * See: https://auroratarget.tpondemand.com/entity/6400-data-top-ups
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
function* fetchCliTopUpBoltOnProducts({ productId }) {
  const accountId = yield select(getAccountId);
  const accountSettings = yield select(getAccountSettings);
  const response = yield ProductAPI.MobileBoltOnSearch(
    "cli",
    productId,
    accountId,
    true, // Is top up bolt on
    1,
    accountSettings.category_id_cli_bolt_ons,
    accountSettings.can_access_vf_direct === "1" ? 1 : undefined
  );
  yield put({
    type: actionTypes.RECEIVE_CLI_TOP_UP_BOLT_ON_PRODUCTS,
    productId,
    response,
  });
}

export function* watchCliTopUpBoltOnProducts() {
  yield takeEvery(
    actionTypes.REQUEST_CLI_TOP_UP_BOLT_ON_PRODUCTS,
    fetchCliTopUpBoltOnProducts
  );
}

/**
 * Get Resign-able product instances.
 * There can be a load of these, so we get the first 10 ordered by end date,
 * to give the user something to have a crack at, then lazy load the rest page by page.
 *
 * @param page
 * @returns {IterableIterator<*>}
 */
function* fetchProductInstances({ page = 1 }) {
  const accountId = yield select(getAccountId);
  const settings = yield select(getAccountSettings);

  const servicesType =
    settings.can_access_vf_direct === "1"
      ? "third_party_billing_services"
      : "live_evo_services";

  const params = {
    with: servicesType,
    is_resignable: 1,
    sort: "contract_end_date",
  };
  const response = yield call(
    ProductAPI.ProductInstance,
    "Mobile",
    accountId,
    page,
    params
  );
  yield put({ type: actionTypes.RECEIVE_PRODUCT_INSTANCES, response });

  // If there's a next page, do the above again.
  const nextPage = _.get(response, "pagination.next_page");
  if (nextPage && nextPage > page) {
    yield put({ type: actionTypes.REQUEST_PRODUCT_INSTANCES, page: nextPage });
  }
}

export function* watchProductInstances() {
  yield takeLatest(
    actionTypes.REQUEST_PRODUCT_INSTANCES,
    fetchProductInstances
  );
}

/**
 * Find the product used to do resigns without change.
 * @returns {IterableIterator<*>}
 */
function* fetchResignProductSearch() {
  const response = yield call(ProductAPI.search, {
    has_extra_services: 1,
    extra_services_type: "resign",
  });
  yield put({ type: actionTypes.RECEIVE_RESIGN_PRODUCT, response });
}

export function* watchResignProductSearch() {
  yield takeLeading(
    actionTypes.REQUEST_RESIGN_PRODUCT,
    fetchResignProductSearch
  );
}

/**
 * Get existing account level mobile bolt-ons on an account
 * @returns {IterableIterator<*>}
 */
function* fetchAccountBoltOns() {
  const accountId = yield select(getAccountId);
  const response = yield call(AccountAPI.AccountLevelBoltOnsNetwork, accountId);
  yield put({ type: actionTypes.RECEIVE_ACCOUNT_BOLT_ONS, response });
}

export function* watchAccountBoltOns() {
  yield takeLeading(actionTypes.REQUEST_ACCOUNT_BOLT_ONS, fetchAccountBoltOns);
}

/**
 * Get mobile product data required for configs (going into step 2)
 * @param productId
 * @returns {IterableIterator<*>}
 */
function* fetchMobileProductData({ productId }) {
  const accountId = yield select(getAccountId);
  const orderId = yield select(getOrderId);
  const response = yield call(
    GuidedSalesAPI.productData,
    accountId,
    productId,
    false,
    {},
    orderId
  );
  yield put({
    type: actionTypes.RECEIVE_MOBILE_PRODUCT_DATA,
    productId,
    response,
  });
}

export function* watchMobileProductData() {
  yield takeEvery(
    actionTypes.REQUEST_MOBILE_PRODUCT_DATA,
    fetchMobileProductData
  );
}

/**
 * Get pricing data for single mobile config.
 * @see fetchMobilePricingData
 * @param configId
 * @param accountId
 * @returns {IterableIterator<*>}
 */
function* fetchSingleMobilePricingData(configId, accountId) {
  const { params, productId } = yield select((state) =>
    getMobileDCOrderParams(state, configId, true)
  );
  const orderId = yield select(getOrderId);
  return yield call(
    GuidedSalesAPI.productData,
    accountId,
    productId,
    false,
    params,
    orderId
  );
}
/**
 * Get pricing data for mobile configs (single, or multiple when we're bulk editing)
 * (for when pricing has been altered in step 2)
 * Note this has do be done as one action, so takeLatest() can cancel a batch of stale calls.
 * @param configIds {Array}
 * @returns {IterableIterator<*>}
 */
function* fetchMobilePricingData({ configIds }) {
  const accountId = yield select(getAccountId);
  const responses = yield all(
    configIds.map((configId) =>
      fetchSingleMobilePricingData(configId, accountId)
    )
  );
  yield put({
    type: actionTypes.RECEIVE_MOBILE_PRICING_DATA,
    configIds,
    responses,
  });
}

export function* watchMobilePricingData() {
  // TODO:Use debounce() here when we have client side discount value computation
  yield takeLatest(
    actionTypes.REQUEST_MOBILE_PRICING_DATA,
    fetchMobilePricingData
  );
}

/**
 * Get existing dynamic property values for resigns.
 * @see requestAllResignPropertyValues()
 * @param productId
 * @param resignId
 * @param configId
 * @returns {IterableIterator<*>}
 */
function* fetchResignPropertyValues({ productId, resignId, configId }) {
  const accountId = yield select(getAccountId);
  const orderId = yield select(getOrderId);
  const response = yield call(
    GuidedSalesAPI.productData,
    accountId,
    productId,
    resignId,
    {},
    orderId
  );
  yield put({
    type: actionTypes.RECEIVE_RESIGN_PROPERTY_VALUES,
    configId,
    response,
  });
}

export function* watchResignPropertyValues() {
  yield takeEvery(
    actionTypes.REQUEST_RESIGN_PROPERTY_VALUES,
    fetchResignPropertyValues
  );
}

/**
 * Find the Daisy Fresh product
 * @returns {IterableIterator<*>}
 */
function* fetchDaisyFreshSearch() {
  const accountId = yield select(getAccountId);
  // Note: Service provider ID (second arg) is irrelevant.
  // There are separate products for Voda and O2, but both do the same thing
  const response = yield call(ProductAPI.daisyFreshSearch, accountId, 2);
  yield put({
    type: actionTypes.RECEIVE_DAISY_FRESH_SEARCH,
    response,
  });
}

export function* watchDaisyFreshSearch() {
  yield takeLeading(
    actionTypes.REQUEST_DAISY_FRESH_SEARCH,
    fetchDaisyFreshSearch
  );
}

/**
 * Fetch Hardware Credit product
 * @returns {IterableIterator<*>}
 */
function* fetchHardwareCreditProductSearch() {
  const accountId = yield select(getAccountId);
  const response = yield call(ProductAPI.hardwareCreditSearch, accountId);
  yield put({ type: actionTypes.RECEIVE_HARDWARE_CREDIT_PRODUCT, response });
}

export function* watchHardwareCreditProductSearch() {
  yield takeLeading(
    actionTypes.REQUEST_HARDWARE_CREDIT_PRODUCT,
    fetchHardwareCreditProductSearch
  );
}

/**
 * Check a PAC code is valid and retrieve the acquisition method
 * @param configId
 * @returns {IterableIterator<*>}
 */
function* fetchCheckPacCode({ configId }) {
  const { mobile_number, pac } = yield select(
    (state) => state.mobile.configs[configId].properties
  );

  const product = yield select((state) =>
    getProductDataForConfig(state, configId)
  );

  // TODO: Get this API response fixed. Currently responds "ok" when there's errors so doesn't get caught in API base.
  // if(response.result.errors.length > 0) dispatch(addAlertMessage(new Error(response.result.errors[0].message)))

  const maxRetries = 5;
  let retries = 0;

  while (retries < maxRetries) {
    try {
      const checkPacCodeResponse = yield call(
        MobileAPI.checkPacCode,
        mobile_number,
        (pac || "").toUpperCase() // API needs uppercase. ...do PACs have letters though? FB158884
      );

      if (checkPacCodeResponse.status === "error") throw checkPacCodeResponse; // wat

      const newSupplier = product.mobile.product_component_data.supplier;
      const { no_code, sp_code } = checkPacCodeResponse?.result;
      const acquisitionMethodResponse = yield call(
        MobileAPI.acquisitionMethod,
        newSupplier.toLowerCase(),
        no_code,
        sp_code
      );

      if (!acquisitionMethodResponse?.acquisition_method) {
        throw new Error(
          "Missing or unexpected acquisition method API response"
        );
      }

      yield put(
        receivePacVerification(
          checkPacCodeResponse,
          acquisitionMethodResponse.acquisition_method,
          configId
        )
      );

      return;
    } catch (e) {
      if (retries === 4 || e.reason_code !== "ERROR_UNKNOWN") {
        yield put(setPacVerificationError(configId, e));
        console.error(e);
        return;
      }
      retries++;
      yield delay(1000);
    }
  }
}

export function* watchCheckPacCode() {
  yield takeEvery(actionTypes.REQUEST_PAC_VERIFICATION, fetchCheckPacCode);
}

/**
 * Check a STAC code is valid
 * @param configId
 * @returns {IterableIterator<*>}
 */
function* fetchCheckStacCode({ configIndex }) {
  const { stac, old_mobile_number } = yield select(
    (state) => state.mobile.configs[configIndex].properties
  );

  const response = yield call(
    MobileAPI.checkStacCode,
    (stac || "").toUpperCase(), // API needs uppercase. FB158884
    old_mobile_number
  );
  yield put({
    type: actionTypes.RECEIVE_STAC_VERIFICATION,
    response,
    configIndex,
  });
}

export function* watchCheckStacCode() {
  yield takeEvery(actionTypes.REQUEST_STAC_VERIFICATION, fetchCheckStacCode);
}

/**
 * Check a SIM serial # is valid
 * @param configId
 * @returns {IterableIterator<*>}
 */
function* fetchSimVerification({ configId }) {
  const { sim_buffer_serial } = yield select((state) =>
    getConfigProperties(state, configId)
  );
  const { supplier } = yield select((state) =>
    getProductComponentData(state, configId)
  );
  const response = yield call(
    SIMsAPI.isValidSIMNumber,
    sim_buffer_serial,
    supplier
  );
  yield put({
    type: actionTypes.RECEIVE_SIM_VERIFICATION,
    configId,
    response,
  });
}

export function* watchSimVerification() {
  yield takeEvery(actionTypes.REQUEST_SIM_VERIFICATION, fetchSimVerification);
}

/**
 * Get Mobile Bars Compatibility
 * Looks at current bars selection and sees what else can be selected according to the endpoint.
 * @param configId
 * @returns {IterableIterator<*>}
 */
function* fetchBarsCompatibility({ configId }) {
  const { properties } = yield select(
    (state) => state.mobile.configs[configId]
  );
  const { supplier } = yield select((state) =>
    getProductComponentData(state, configId)
  );
  const currentBars = Object.keys(properties).filter(
    (k) => k.includes("bar_") && properties[k] == 1 // eslint-disable-line eqeqeq
  );
  const response = yield call(
    MobileProvisioningAPI.BarsCompatibilitiesForNetwork,
    supplier,
    currentBars
  );
  yield put({
    type: actionTypes.RECEIVE_BARS_COMPATIBILITY,
    configId,
    response,
  });
}

export function* watchBarsCompatibility() {
  yield takeLatest(
    actionTypes.REQUEST_BARS_COMPATIBILITY,
    fetchBarsCompatibility
  );
}

/**
 * Get reserved numbers list
 * @param network
 * @returns {IterableIterator<*>}
 */
function* fetchReservedNumbersList({ network }) {
  const accountId = yield select(getAccountId);
  const response = yield call(
    MobileAPI.reservedNumbersList,
    accountId,
    network
  );
  yield put({
    type: actionTypes.RECEIVE_RESERVED_NUMBERS_LIST,
    response,
    network,
  });
}

export function* watchReservedNumbersList() {
  yield takeLeading(
    actionTypes.REQUEST_RESERVED_NUMBERS_LIST,
    fetchReservedNumbersList
  );
}
