import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  doAddWlrAppointment,
  fetchWlrAppointments,
  setWlrAppointment,
} from "../../../../../../../store/wlrBroadband/actions/configurations";
import { getIsSalesPerson } from "../../../../../../../store/account/selectors";
import { ChooseWlrAppointment } from "../../../../../components/wlrBroadband/ChooseWlrAppointment";

interface ChooseWlrAppointmentContainerProps {
  targetConfig: number;
}

export const ChooseWlrAppointmentContainer = ({
  targetConfig,
}: ChooseWlrAppointmentContainerProps) => {
  const dispatch = useDispatch();

  const appointments = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig].wlrAppointments
  );
  const addWlrAppointment = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig].addWlrAppointment
  );
  const isSalesPerson = useSelector(getIsSalesPerson);

  useEffect(() => {
    if (
      !_.get(appointments.response, "appointments", []).length &&
      !appointments.fetching
    ) {
      dispatch(fetchWlrAppointments(targetConfig));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChooseWlrAppointment
      isSalesPerson={isSalesPerson}
      appointments={appointments}
      addWlrAppointment={addWlrAppointment}
      onAddAppointment={() => dispatch(doAddWlrAppointment(targetConfig))}
      onRetry={() => dispatch(fetchWlrAppointments(targetConfig))}
      onChooseWlrAppointment={(event) =>
        dispatch(
          setWlrAppointment(targetConfig, event.target.value, isSalesPerson)
        )
      }
    />
  );
};
