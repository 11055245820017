import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { CheckIcon } from "@akj-dev/shared-components";
import ErrorIcon from "@mui/icons-material/Error";

import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

type Props = {
  productDescription: React.ReactNode;
  oneOffPrice: string;
  recurringPrice: string | JSX.Element;
  isConfigured: boolean;
  indent: boolean;
};

export const OrderViewProductRow = ({
  productDescription,
  oneOffPrice,
  recurringPrice,
  isConfigured,
  indent = false,
}: Props) => (
  <TableRow>
    <TableCell>
      {indent && (
        <SubdirectoryArrowRightIcon
          style={{
            verticalAlign: "middle",
            marginLeft: indent ? 40 : 0,
            marginRight: 10,
          }}
        />
      )}
      {productDescription}
    </TableCell>
    <TableCell>{oneOffPrice}</TableCell>
    <TableCell>{recurringPrice}</TableCell>
    <TableCell>
      {isConfigured ? <CheckIcon /> : <ErrorIcon color="error" />}
    </TableCell>
  </TableRow>
);
