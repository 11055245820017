import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAvailableNonTopUpCliBoltOns,
  getAvailableCliTopUpBoltOns,
  getAllSelectedCliBoltOnIds,
} from "../../../../../../../store/mobile/selectors/productConfig";
import { chooseCliBoltOn } from "../../../../../../../store/mobile/actions";
import { CliTopUpBoltOnSelectField } from "../../../../../components/Mobile/CliTopUpBoltOnSelectField";

interface CliTopUpBoltOnSelectFieldProps {
  targetConfigs: number[];
  title: string;
  boltOnType: string;
}

export const CliTopUpBoltOnSelectFieldContainer = ({
  targetConfigs,
  title,
  boltOnType,
}: CliTopUpBoltOnSelectFieldProps) => {
  const dispatch = useDispatch();
  const selectedCliBoltOn = useSelector((state) =>
    getAllSelectedCliBoltOnIds(state, { boltOnType, targetConfigs })
  );
  const availableCliBoltOns = useSelector((state) =>
    getAvailableNonTopUpCliBoltOns(state, { boltOnType, targetConfigs })
  );
  const availableCliTopUpBoltOns = useSelector((state) =>
    getAvailableCliTopUpBoltOns(state, { targetConfigs })
  ) as any;
  const hasSelectedCliBoltOn = !!availableCliBoltOns.find((boltOn: any) =>
    selectedCliBoltOn.includes(boltOn.id)
  );

  return (
    <CliTopUpBoltOnSelectField
      title={title}
      boltOnType={boltOnType}
      selectedCliBoltOn={selectedCliBoltOn}
      hasSelectedCliBoltOn={hasSelectedCliBoltOn}
      availableCliTopUpBoltOns={availableCliTopUpBoltOns}
      onSelectChange={(event) => {
        targetConfigs.forEach((targetConfig) =>
          dispatch(
            chooseCliBoltOn(targetConfig, boltOnType, event.target.value)
          )
        );
      }}
    />
  );
};
