import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { LowPriorityInfoChip } from "../../../../shared/components/Chip/LowPriorityInfoChip";
import FormControlLabel from "@mui/material/FormControlLabel";

interface BillCapConfirmationProps {
  billLimitsOrSpendCapsNotSet: boolean;
  billCapConfirmed: boolean;
  vfDirectUser: boolean;
  onBillCapConfirmChange: () => void;
}

export const BillCapConfirmation = ({
  billCapConfirmed,
  billLimitsOrSpendCapsNotSet,
  onBillCapConfirmChange,
  vfDirectUser,
}: BillCapConfirmationProps) => (
  <>
    {billLimitsOrSpendCapsNotSet && (
      <LowPriorityInfoChip>
        <FormControlLabel
          label={
            vfDirectUser
              ? "I confirm I have offered a Spend Cap for out of bundle spend."
              : "I confirm I have offered a Mobile Bill Cap for out of bundle spend."
          }
          control={
            <Checkbox
              color="secondary"
              checked={billCapConfirmed}
              onChange={onBillCapConfirmChange}
              data-cy="billCapConfirmed"
            />
          }
        />
      </LowPriorityInfoChip>
    )}
  </>
);
