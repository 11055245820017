import React from "react";

import Icon from "@mui/icons-material/SwapVert";

import { Section } from "../../../../shared/components/Section";
import { WlrChangePriceFieldContainer } from "../../containers/wlrBroadband/ConfigurationForm/sections/WlrChangePriceAdjustment/WlrChangePriceField";

import WlrBBDynamicField from "./WlrBBDynamicField";

interface WlrChangePriceAdjustmentProps {
  careLevelChangeEnabled: boolean;
  careLevelDiscountEnabled: boolean;
}

export const WlrChangePriceAdjustment = ({
  careLevelChangeEnabled,
  careLevelDiscountEnabled,
}: WlrChangePriceAdjustmentProps) => (
  <Section
    title="WLR Change Price Adjustment"
    avatar={<Icon />}
    defaultExpanded={false}
  >
    <WlrBBDynamicField
      productType="broadband"
      propertyName="wlr_change.wlr_service_price"
    />
    {(careLevelChangeEnabled || careLevelDiscountEnabled) && (
      <WlrChangePriceFieldContainer
        propertyNamePrefix="wlr_change.care_level.recurring"
        label="Care Level Discount (total price)"
      />
    )}
  </Section>
);
