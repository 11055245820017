import React from "react";
import { useSelector } from "react-redux";

import {
  getIsWlrChangePossible,
  getRequiresBBAppointment,
  getRequiresWLRAppointment,
  getIsBTWFTTC,
  getIsMPFLine,
  getBroadbandSearchForLocation,
  getIsSOGEAOrFTTPUpgrade,
} from "../../../../../store/wlrBroadband/selectors";
import {
  NEW_FTTP,
  NEW_LINE,
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
  TRANSFER,
} from "../../../../../store/wlrBroadband/constants";
import {
  EXISTING_SOGEA,
  NEW_SOGEA,
  RESIGN,
} from "../../../../../store/wlrBroadband/constants";
import { getProductInstances } from "../../../../../store/wlrBroadband/selectors";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import { ConfigurationForm } from "../../../components/wlrBroadband/ConfigurationForm";

interface ConfigurationFormOwnProps {
  closeAction: () => void;
}

export const ConfigurationFormContainer = ({
  closeAction,
}: ConfigurationFormOwnProps) => {
  const { targetConfigs } = useTargetConfigsContext();

  const findInTargets = (test: (config: any) => boolean) =>
    targetConfigs.some((config) => test(configurations[config]));

  const state = useSelector((state: any) => state);
  const configurations = useSelector(
    (state: any) => state.wlrBroadband.configurations
  );
  const firstConfiguration = configurations[targetConfigs[0]];
  const locations = useSelector((state: any) => state.wlrBroadband.locations);
  const productInstances = useSelector(getProductInstances);
  const productInstance = productInstances.find(
    (product: any) =>
      product.id === configurations[targetConfigs[0]].productInstanceId
  );
  const requiresWLRAppointment = useSelector((state: any) =>
    getRequiresWLRAppointment(state, { targetConfigs })
  );
  const requiresBBAppointment = useSelector((state: any) =>
    getRequiresBBAppointment(state, { targetConfigs })
  );
  const broadbandSearch = useSelector((state: any) =>
    getBroadbandSearchForLocation(state, {
      configuration: configurations[targetConfigs[0]],
    })
  );
  const isNewLine = findInTargets(
    (c) => locations[c.locationIndex].type === NEW_LINE
  );
  const isTransfer = findInTargets(
    (c) => locations[c.locationIndex].type === TRANSFER
  );
  const isWLTO = findInTargets(
    (c) =>
      locations[c.locationIndex].wltoDetails?.response.line_plant_type ===
      "WORKING_LINE"
  );
  const isMultiLine = findInTargets(
    (c) =>
      c.wlrProductData?.response?.line?.product_component_data?.service_type ===
      "PSTN_MULTI_LINE_AUX"
  );
  const isMPF = findInTargets((c) => getIsMPFLine(c));
  const hasBroadband = findInTargets(
    (c) => locations[c.locationIndex].type !== RESIGN && c.broadbandProductId
  );
  const isWlrChangePossible = findInTargets((c) =>
    getIsWlrChangePossible(state, c.locationIndex)
  );

  const [upgradeType, isUpgrade] = getIsSOGEAOrFTTPUpgrade(firstConfiguration);

  const isFTTP = findInTargets(
    (c) =>
      locations[c.locationIndex].type === NEW_FTTP ||
      productInstance?.broadband?.technology_type === "FTTP" ||
      upgradeType === "FTTP"
  );
  const isSOGEA = findInTargets(
    (c) =>
      c.wlrProductId === NEW_SOGEA ||
      c.wlrProductId === EXISTING_SOGEA ||
      upgradeType === "SOGEA"
  );

  const isBTWFTTC = getIsBTWFTTC(state, { targetConfigs });
  const isISDN =
    productInstance &&
    productInstance.type === "wlr" &&
    productInstance.line_details &&
    (productInstance.line_details.service_type === "ISDN30E" ||
      productInstance.line_details.service_type === "ISDN2E_SYSTEM");
  const hasLine = findInTargets(
    (c) => c.wlrProductId && c.wlrProductId !== RESIGN
  );
  const isNewProductLineResign = findInTargets(
    (c) =>
      locations[c.locationIndex].type === RESIGN &&
      c.wlrProductId &&
      c.wlrProductId !== RESIGN &&
      c.resignProductType === NEW_PRODUCT
  );
  const isSameProductWCLineResign = findInTargets(
    (c) =>
      locations[c.locationIndex].type === RESIGN &&
      c.wlrProductId &&
      c.wlrProductId !== RESIGN &&
      c.resignProductType === SAME_PRODUCT_WITH_CHANGES
  );
  const isNewProductBroadbandResign = findInTargets(
    (c) =>
      locations[c.locationIndex].type === RESIGN &&
      ((c.broadbandProductId && c.resignProductType === NEW_PRODUCT) ||
        (broadbandSearch?.response?.products?.find(
          (product: any) => product.id === c.broadbandProductId
        ) &&
          c.resignProductType === SAME_PRODUCT_WITH_CHANGES))
  );
  const isSameProductWCBroadbandResign = findInTargets(
    (c) =>
      locations[c.locationIndex].type === RESIGN &&
      c.broadbandProductId &&
      c.resignProductType === SAME_PRODUCT_WITH_CHANGES
  );
  const uniqueReferenceType =
    (firstConfiguration.wlrProductData?.response?.line?.dynamic_properties[
      "partner_reference_number"
    ] ||
      firstConfiguration.broadbandProductData?.response?.broadband
        ?.dynamic_properties["partner_reference_number"])?.["category_name"] ??
    null;

  const hasCli = findInTargets(
    (c) => locations[c.locationIndex].cli.value !== ""
  );

  return (
    <ConfigurationForm
      isBTWFTTC={isBTWFTTC}
      isFTTP={isFTTP}
      isISDN={isISDN}
      isMPF={isMPF}
      isMultiLine={isMultiLine}
      isNewLine={isNewLine}
      isNewProductBroadbandResign={isNewProductBroadbandResign}
      isNewProductLineResign={isNewProductLineResign}
      isSOGEA={isSOGEA}
      isSameProductWCBroadbandResign={isSameProductWCBroadbandResign}
      isSameProductWCLineResign={isSameProductWCLineResign}
      isTransfer={isTransfer}
      isWLTO={isWLTO}
      isWlrChangePossible={isWlrChangePossible}
      isUpgrade={isUpgrade}
      productInstance={productInstance}
      hasBroadband={hasBroadband}
      hasLine={hasLine}
      uniqueReferenceType={uniqueReferenceType}
      requiresBBAppointment={requiresBBAppointment}
      requiresWLRAppointment={requiresWLRAppointment}
      targetConfigs={targetConfigs}
      hasCli={hasCli}
      closeAction={closeAction}
    />
  );
};
