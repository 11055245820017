import { NEW_LINE } from "../constants";

export const newLocation = {
  type: NEW_LINE,
  address: {},
  sogeaTermsAccepted: false,
  cli: {
    value: "",
    validating: false,
    validation: {},
  },

  lineAvailability: {
    fetching: false,
    response: {},
  },

  installationDetails: {
    fetching: false,
    response: {},
  },

  wltoDetails: {
    fetching: false,
    response: {},
  },

  broadbandSearch: {
    fetching: false,
    response: {},
  },

  numbers: {
    fetching: false,
    response: {},
  },

  tagsCheck: {
    fetching: false,
    response: {},
  },
};
