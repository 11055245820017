import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StatusChip } from "@akj-dev/shared-components";
import {
  doNumberReservation,
  setNumberReservationType,
  setReservedNumber,
} from "../../../../../../../store/wlrBroadband/actions/configurations";
import { useTargetConfigsContext } from "../../../../../context/TargetConfigsContext";
import { NumberReservation } from "../../../../../components/wlrBroadband/NumberReservation";

export const NumberReservationContainer = () => {
  const dispatch = useDispatch();

  const { targetConfigs } = useTargetConfigsContext();

  const numberReservation = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfigs[0]].numberReservation
  );

  if (targetConfigs.length > 1)
    return (
      <StatusChip
        type="info"
        title="Number reservations cannot be bulk edited"
        message="Please edit configurations individually"
      />
    );

  return (
    <NumberReservation
      numberReservation={numberReservation}
      onReserveNowClick={() => dispatch(doNumberReservation(targetConfigs[0]))}
      onSetNumberReservationType={(value) =>
        dispatch(setNumberReservationType(targetConfigs[0], value))
      }
      onSetReservedNumber={(value) =>
        dispatch(setReservedNumber(targetConfigs[0], value))
      }
    />
  );
};
