import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pricing } from "../../../../../../shared/containers/Pricing";
import {
  fetchProductData,
  setProductDiscount,
  setProductProperty,
  setPricingScheme,
} from "../../../../../../store/wlrBroadband/actions/configurations";
import { useTargetConfigsContext } from "../../../../context/TargetConfigsContext";

export const WlrBBPricingWrapper = () => {
  const dispatch = useDispatch();
  const { targetConfigs } = useTargetConfigsContext();

  const config = useSelector(
    (state: any) => state.wlrBroadband.configurations[targetConfigs[0]]
  );

  const wlrProductData = config.wlrProductData;
  const broadbandProductData = config.broadbandProductData;
  const wlrProperties = config.wlrProperties;
  const broadbandProperties = config.broadbandProperties;

  const updateProductData = (productType: string) =>
    dispatch(
      fetchProductData({ configurationIndex: targetConfigs[0], productType })
    );

  const setWlrPricingScheme = (scheme: string) => {
    dispatch(setPricingScheme(targetConfigs[0], "wlr", scheme));
    updateProductData("wlr");
  };

  const setBBPricingScheme = (scheme: string) => {
    dispatch(setPricingScheme(targetConfigs[0], "broadband", scheme));
    updateProductData("broadband");
  };

  const setWlrDiscount = (
    priceType: "one_off" | "recurring",
    discountType: "DiscountPercentage" | "DiscountAmount" | "SpecifyPrice",
    discountValue: string | number
  ) => {
    dispatch(
      setProductDiscount(
        [targetConfigs[0]],
        "wlr",
        priceType,
        discountType,
        discountValue
      )
    );
    updateProductData("wlr");
  };

  const setBBDiscount = (
    priceType: "one_off" | "recurring",
    discountType: "DiscountPercentage" | "DiscountAmount" | "SpecifyPrice",
    discountValue: string | number
  ) => {
    dispatch(
      setProductDiscount(
        [targetConfigs[0]],
        "broadband",
        priceType,
        discountType,
        discountValue
      )
    );
    updateProductData("broadband");
  };

  const setWlrServiceTariff = (tariff: string) => {
    dispatch(
      setProductProperty([targetConfigs[0]], "wlr", "service_tariff_id", tariff)
    );
    updateProductData("wlr");
  };

  const setBBServiceTariff = (tariff: string) => {
    dispatch(
      setProductProperty(
        [targetConfigs[0]],
        "broadband",
        "service_tariff_id",
        tariff
      )
    );
    updateProductData("broadband");
  };

  return (
    <>
      <Pricing
        title="WLR Pricing"
        productData={wlrProductData}
        configProperties={wlrProperties}
        setPricingScheme={setWlrPricingScheme}
        setDiscount={setWlrDiscount}
        setServiceTariff={setWlrServiceTariff}
      />
      <Pricing
        title="Broadband Pricing"
        productData={broadbandProductData}
        configProperties={broadbandProperties}
        setPricingScheme={setBBPricingScheme}
        setDiscount={setBBDiscount}
        setServiceTariff={setBBServiceTariff}
      />
    </>
  );
};
