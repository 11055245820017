import {
  BroadbandSearchResponse,
  DCProduct,
} from "../api/availabilityCheckApi";
import { CalcType } from "../AvailabilityCheck";

type GenericBBSpeeds = {
  upload: {
    max: string;
    min: string;
  };
  download: {
    max: string;
    min: string;
    guaranteed: string;
  };
};

export function getSpeeds(
  results: BroadbandSearchResponse,
  product: DCProduct,
  calcType: CalcType
): GenericBBSpeeds {
  const speeds =
    results.broadband_rates[calcType][
      product.first_broadband_component.supplier_product_ref
    ][product.first_broadband_component.supplier_product_subtype || "ALL"];

  const productType = product.first_broadband_component.type;

  if (productType === "FTTC" || productType === "SOGEA") {
    return {
      upload: {
        max: speeds.range_a_top_upstream,
        min: speeds.range_b_bottom_upstream,
      },
      download: {
        max: speeds.range_a_top_downstream,
        min: speeds.range_b_bottom_downstream,
        guaranteed: speeds.minimum_guaranteed_speed,
      },
    };
  }

  // The other types: FTTP, SMPF, MPF
  return {
    upload: {
      max: speeds.max_upspeed,
      min: "?",
    },
    download: {
      max: speeds.max_range,
      min: speeds.min_range,
      guaranteed: speeds.minimum_guaranteed_speed,
    },
  };
}
