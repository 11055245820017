import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WifiIcon from "@mui/icons-material/Wifi";
import SettingsPowerIcon from "@mui/icons-material/SettingsPower";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { ReduxAccountSettingsEnum } from "../../shared/enums";
import { ProductTypeCardType } from "./types";

export const mobile: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.MOBILE,
  label: "Mobile",
  dataCy: "product-type-mobile",
  Icon: PhoneIphoneIcon,
};

export const wlrBroadband: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.BROADBAND,
  label: "Lines, Calls & Broadband",
  dataCy: "product-type-broadband",
  Icon: WifiIcon,
};

export const hardware: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.HARDWARE,
  label: "Hardware",
  dataCy: "product-type-hardware",
  Icon: SettingsPowerIcon,
};

export const ethernet: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.ETHERNET,
  label: "Ethernet",
  dataCy: "product-type-ethernet",
  Icon: SettingsEthernetIcon,
};

export const monitoring: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.MONITORING,
  label: "Monitoring",
  dataCy: "product-type-monitoring",
  Icon: ShowChartIcon,
};

export const universal: ProductTypeCardType = {
  id: ReduxAccountSettingsEnum.UNIVERSAL,
  label: "Universal",
  dataCy: "product-type-universal",
  Icon: FormatAlignJustifyIcon,
};
