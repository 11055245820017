import { useDispatch, useSelector } from "react-redux";
import React from "react";
import * as Sentry from "@sentry/browser";
import { StepNavFooter } from "./containers/StepNavFooter";
import { OrderStatusContainer } from "./containers/OrderStatus";
import { FlashMessages } from "./components/Messages/FlashMessages";
import { AlertMessages } from "./components/Messages/AlertMessages";
import {
  STEP_ACCOUNT,
  STEP_CHOOSE,
  STEP_CUSTOMISE,
  STEP_DELIVERY,
  STEP_SUMMARY,
} from "../../store/uiState/constants";
import { Header } from "./containers/Header";
import { Choose } from "../StepChoose";
import { Customise } from "../StepCustomise";
import { Account } from "../StepAccount";
import { Delivery } from "../StepDelivery";
import { Summary } from "../StepSummary";
import {
  confirmFinalStepWarning,
  hideFinalStepWarning,
  removeAlertMessages,
  removeFlashMessageById,
  setActiveStep,
} from "../../store/uiState/actions";
import { FinalStepContainer } from "./containers/FinalStepContainer";

function ActiveStep({ activeStep }: any) {
  switch (activeStep) {
    case STEP_CHOOSE:
      return <Choose />;
    case STEP_CUSTOMISE:
      return <Customise />;
    case STEP_ACCOUNT:
      return <Account />;
    case STEP_DELIVERY:
      return <Delivery />;
    case STEP_SUMMARY:
      return <Summary />;
    default:
      return <div />;
  }
}

export const Wizard: React.FC = () => {
  const dispatch = useDispatch();

  const {
    showFinalStepWarning,
    activeStep,
    steps,
    alertMessages,
    flashMessages,
    orderStatusOpen,
  } = useSelector((state: any) => ({
    showFinalStepWarning: state.uiState.showFinalStepWarning,
    activeStep: state.uiState.activeStep,
    steps: state.uiState.steps,
    orderStatusOpen: state.uiState.orderStatusOpen,
    alertMessages: state.uiState.alertMessages,
    flashMessages: state.uiState.flashMessages,
  }));

  React.useEffect(() => {
    Sentry.addBreadcrumb({
      message: "GS UI Initialized",
      category: "action",
    });
  }, []);

  const onClose = (id: string | number) => dispatch(removeFlashMessageById(id));

  const handleFinalStepConfirm = () => {
    dispatch(confirmFinalStepWarning());
    const nextStep = steps[steps.indexOf(activeStep) + 1];
    if (nextStep) {
      dispatch(setActiveStep(nextStep));
    }
  };

  const handleFinalStepCancel = () => {
    dispatch(hideFinalStepWarning());
  };

  return (
    // Class name used to provide "autoscrolling" functionallity while going through
    // the CPQ process. Class name used in packages/affinity-sales/src/store/uiState/actions.js
    // See https://auroratarget.tpondemand.com/entity/43510-auto-scroll-to-top-of-page
    // for more information.
    <div className="WizardClass">
      <Header />
      <FlashMessages flashMessages={flashMessages} onClose={onClose} />
      <AlertMessages
        alertMessages={alertMessages}
        removeAlertMessages={() => dispatch(removeAlertMessages())}
      />
      <FinalStepContainer
        show={showFinalStepWarning}
        handleConfirm={handleFinalStepConfirm}
        handleCancel={handleFinalStepCancel}
      />

      {
        // @ts-ignore
        orderStatusOpen && <OrderStatusContainer />
      }
      <ActiveStep activeStep={activeStep} />
      <StepNavFooter />
    </div>
  );
};
