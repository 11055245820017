import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { TrackingDetail } from "../../components/Mobile/TrackingDetails";
import { setTrackingDetails } from "../../../../store/order/trackingDetailsActions";
import {
  isValidEmail,
  isValidMobileNumber,
} from "../../../../shared/utils/validation";

interface TrackingDetailsContainerProps {
  cardIndex: string;
}

export const TrackingDetailsContainer = ({
  cardIndex,
}: TrackingDetailsContainerProps) => {
  const dispatch = useDispatch();

  const trackingDetails = useSelector(
    (state: any) => state.order.trackingDetails
  );

  const validEmail = isValidEmail(trackingDetails);
  const validMobile = isValidMobileNumber(trackingDetails);

  return (
    <TrackingDetail
      cardIndex={cardIndex}
      validEmail={validEmail}
      validMobile={validMobile}
      error={trackingDetails && !validEmail && !validMobile}
      trackingDetails={trackingDetails}
      onTrackingDetailsChange={(value) => dispatch(setTrackingDetails(value))}
    />
  );
};
