import React from "react";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { Price } from "./Price";

export interface PricesProps {
  monthlyPrice: number;
  upFrontPrice: number;
}

export const Prices = ({ monthlyPrice, upFrontPrice }: PricesProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.prices}>
      <Price label="Per Month" price={monthlyPrice} />
      <AddIcon className={classes.plus} />
      <Price label="Up Front" price={upFrontPrice} />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  prices: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1.625),
  },
  plus: {
    color: theme.palette.primary.main,
    margin: "0 5px",
  },
}));
