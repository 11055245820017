import React from "react";
import _ from "lodash";

import { StatusChip } from "@akj-dev/shared-components";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface ETFMethodProps {
  fetching: boolean;
  etfMethod: string;
  validETFMethods: string[];
  updateETFMethod: (event: SelectChangeEvent<string>) => void;
}
export const ETFMethod = ({
  fetching,
  etfMethod,
  validETFMethods,
  updateETFMethod,
}: ETFMethodProps) => {
  if (fetching) {
    return (
      <StatusChip
        type="loading"
        title="Updating ETF Method."
        message="Please wait..."
      />
    );
  }

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel variant="standard">Choose an ETF Method</InputLabel>
        <Select variant="standard" value={etfMethod} onChange={updateETFMethod}>
          {validETFMethods.map((m) => (
            <MenuItem key={m} value={m}>
              {_.capitalize(m)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
