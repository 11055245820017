import React, { useState, ChangeEvent } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { Box, InputProps, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StatusChip } from "@akj-dev/shared-components";
import { useTargetConfigsContext } from "../../../context/TargetConfigsContext";
import {
  updateConfigProperty,
  validateConfigProperty,
} from "../../../../../store/mobile/actions";
import { NonUniformIndicator } from "../../../components/Mobile/NonUniformIndicator";
import { DC_DATE_FORMAT } from "../../../../../shared/utils/date";
import { useCanAccessVfDirect } from "../../../../../shared/hooks/useCanAccessVfDirect";

interface BulkDynamicFieldProps {
  propertyName: string;
  disabled?: boolean;
  afterUpdate?: () => void; // Action to fire after input change. For BarsCompatibilities currently
  availableOptionsMap?: Record<string, any>; // Override options coming back from DC
  minDate?: string;
  maxDate?: string;
  shouldDisableDate?: (date: string) => boolean;
  customSelectSortComparator?: (a: string, b: string) => number;
  floatingLabelText?: string;
  helperText?: string;
  InputProps?: InputProps;
  /* Some dynamic properties only appear for certain products.
  Set this to suppress warnings when they're not available */
  optional?: boolean;
}

export const BulkDynamicField = ({
  propertyName,
  disabled,
  floatingLabelText,
  minDate,
  maxDate,
  shouldDisableDate,
  availableOptionsMap,
  helperText,
  InputProps,
  customSelectSortComparator,
  afterUpdate = () => false,
  optional,
}: BulkDynamicFieldProps) => {
  const dispatch = useDispatch();
  const [showNonUniform, setShowNonUniform] = useState(true);
  const { targetConfigs } = useTargetConfigsContext();
  const canAccessVFDirect = useCanAccessVfDirect();

  const config = useSelector(
    (state: any) => state.mobile.configs[targetConfigs[0]]
  );
  const currentValue = config.properties[propertyName];

  // Get the property info. Name, available options etc.
  const dynamicProperty = useSelector(
    (state: any) =>
      state.mobile.productData[config.productId]?.response.mobile
        .dynamic_properties[propertyName]
  );

  // Get any validation message
  const validation = _.get(config.validation, propertyName);

  // Test if properties across selected configuration IDs are uniform.
  const nonUniform = useSelector((state: any) =>
    (() => {
      for (const id in targetConfigs) {
        if (
          currentValue !==
          state.mobile.configs[targetConfigs[id]].properties[propertyName]
        )
          return true;
      }
      return false;
    })()
  );

  const hideNonUniformWarning = () => {
    if (!disabled) {
      setShowNonUniform(false);
      // TODO: Use refs or something to bring focus to input element on reveal
    }
  };

  if (!dynamicProperty) {
    if (optional) {
      return null;
    } else {
      return (
        <Box mb={1}>
          <StatusChip
            type="error"
            message={`Unknown dynamic property ${propertyName}`}
          />
        </Box>
      );
    }
  }

  // Test if properties across selected configuration IDs are uniform.
  // If not, display a multiple property warning

  if (
    nonUniform &&
    showNonUniform &&
    propertyName !== "service_visual_voicemail" // i don't like how it looks for this case (:
  ) {
    return (
      <NonUniformIndicator
        label={floatingLabelText || dynamicProperty.label}
        action={hideNonUniformWarning}
      />
    );
  }

  // Set common properties for all field types
  const commonProps = {
    name: dynamicProperty.name,
    value: currentValue || "",
    onChange: (event: ChangeEvent<{ name: string; value: any }>) => {
      dispatch(
        updateConfigProperty({
          propertyName,
          value: event.target.value,
          configIds: targetConfigs,
          isVfDirect: canAccessVFDirect,
        })
      );
      afterUpdate();
    },
    label:
      (floatingLabelText || dynamicProperty.label) +
      (dynamicProperty.is_required === 1 ? " *" : ""),
    disabled: disabled || dynamicProperty.is_editable !== 1,
    fullWidth: true,
    helperText: validation || helperText,
    error: !!validation,
    InputProps,
  };

  if (dynamicProperty.is_text) {
    // TODO: Speed improvement. Maybe handle this the same as ethernet with a sub component storing state locally,
    // then saving it to Redux state only onBlur... thus requiring a lot less computation.
    return (
      <TextField
        variant="standard"
        {...commonProps}
        onBlur={() => {
          dispatch(
            validateConfigProperty({ propertyName, configIds: targetConfigs })
          );
        }}
        margin="normal"
      />
    );
  }

  if (dynamicProperty.is_select) {
    let options;

    const sortComparator =
      customSelectSortComparator ||
      ((a: string, b: string) => a.localeCompare(b));

    if (availableOptionsMap) {
      options = availableOptionsMap;
    } else {
      options = dynamicProperty.available_option_map;
    }

    return (
      <TextField
        variant="standard"
        {...commonProps}
        onBlur={() => {
          dispatch(
            validateConfigProperty({ propertyName, configIds: targetConfigs })
          );
        }}
        margin="normal"
        select
      >
        {Object.entries(options)
          .sort(([, a], [, b]) => sortComparator(a as string, b as string))
          .map((entry) => {
            const [key, value] = [entry[0], entry[1]];
            return (
              <MenuItem key={key} value={key}>
                {value as string}
                {propertyName === "commission_sacrifice" && <span>.00</span>}
              </MenuItem>
            );
          })}
      </TextField>
    );
  }

  if (dynamicProperty.is_decimal) {
    let message = "";
    switch (propertyName) {
      case "requested_funding_advance":
        message = "For any pre-approved bonus";
        break;
      case "requested_funding_commission":
        message = "For any request of extra funding";
        break;
    }

    return (
      <Tooltip title={message} placement="bottom" arrow>
        <TextField
          variant="standard"
          {...commonProps}
          type="text"
          onChange={(event) => {
            const value = event.target.value
              .replace(/[^\d.]/g, "")
              .replace(/[.](?=.*[.])/g, "");
            dispatch(
              updateConfigProperty({
                propertyName,
                value,
                configIds: targetConfigs,
                isVfDirect: canAccessVFDirect,
              })
            );
            afterUpdate();
          }}
          onBlur={(event) => {
            // handle inputs to 1 decimal place like "0.5" that can cause DC to get upset.
            // do it on blur so it doesn't interfere with user input as they are typing
            const value = event.target.value;
            if (parseFloat(value) > 0) {
              dispatch(
                updateConfigProperty({
                  propertyName,
                  value: parseFloat(value).toFixed(2),
                  configIds: targetConfigs,
                  isVfDirect: canAccessVFDirect,
                })
              );
            }

            dispatch(
              validateConfigProperty({ propertyName, configIds: targetConfigs })
            );
          }}
          margin="normal"
        />
      </Tooltip>
    );
  }

  if (dynamicProperty.is_boolean) {
    // Mobile is_boolean properties are different from other places
    // (they come from various sources according to @jim)
    // They *should* have available_option_map with true and false values
    // (that are different for different fields...sometime string "yes" and "no")
    // but might not always, in which case the should default to 0 and 1.
    // Why is nothing simple?

    const checkedVal = _.get(
      dynamicProperty,
      "available_option_map.checked",
      1
    );
    const uncheckedVal = _.get(
      dynamicProperty,
      "available_option_map.unchecked",
      0
    );

    // The weird DC/Perl '0' vs 0 thing again...
    // eslint-disable-next-line eqeqeq
    const toggled = currentValue == checkedVal;

    let message = "";
    switch (propertyName) {
      case "service_visual_voicemail":
        message = "This will be set for O2 products only.";
        break;
      case "service_5g":
        message =
          "To enable 5G services, the device and SIM also need to be 5G compatible.";
        break;
      case "take_advance":
        message =
          "By default you will receive an advance for applicable sales. Toggle this to choose not to receive an advance.";
        break;
    }

    return (
      <Tooltip title={message} placement="right">
        <FormControlLabel
          control={
            <Switch
              onChange={(event) => {
                dispatch(
                  updateConfigProperty({
                    propertyName,
                    value: event.target.checked ? checkedVal : uncheckedVal,
                    configIds: targetConfigs,
                    isVfDirect: canAccessVFDirect,
                  })
                );
                dispatch(
                  validateConfigProperty({
                    propertyName,
                    configIds: targetConfigs,
                  })
                );
                afterUpdate();
              }}
              checked={toggled}
              name={dynamicProperty.name}
              color="primary"
            />
          }
          label={commonProps.label}
        />
      </Tooltip>
    );
  }

  if (dynamicProperty.is_date) {
    return (
      <DatePicker
        inputFormat={"dd/MM/yyyy"}
        {...commonProps}
        onChange={(date: any) => {
          dispatch(
            updateConfigProperty({
              propertyName,
              value: format(date, DC_DATE_FORMAT),
              configIds: targetConfigs,
              isVfDirect: canAccessVFDirect,
            })
          );
          afterUpdate();
        }}
        minDate={minDate}
        maxDate={maxDate}
        shouldDisableDate={shouldDisableDate}
        views={["year", "month", "day"]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            inputProps={{
              ...params.inputProps,
              readOnly: true,
            }}
          />
        )}
      />
    );
  }

  return <div>Error: Unknown field type.</div>;
};
