import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  resetUploadStatus,
  setContractMeta,
  uploadContract,
} from "../../../../store/order/actions";
import { UploadSignedContractContent } from "../../components/UploadSignedContract/Content";

interface ContentProps {
  closeDialog: () => void;
}
export const UploadSignedContractContentContainer = ({
  closeDialog,
}: ContentProps) => {
  const dispatch = useDispatch();

  const uploadSending = useSelector(
    (state: any) => state.order.contractUpload.sending
  );
  const uploadResponse = useSelector(
    (state: any) => state.order.contractUpload.response
  );
  const receivedAt = useSelector(
    (state: any) => state.order.contractUpload.receivedAt
  );
  const signedAt = useSelector(
    (state: any) => state.order.contractUpload.signedAt
  );
  const file = useSelector((state: any) => state.order.contractUpload.file);

  const contractId = _.get(uploadResponse, "data.contract_id", false);

  return (
    <UploadSignedContractContent
      contractId={contractId}
      file={file}
      signedAt={signedAt}
      receivedAt={receivedAt}
      uploadResponse={uploadResponse}
      uploadSending={uploadSending}
      closeDialog={closeDialog}
      onChangeMeta={(property, value) =>
        dispatch(setContractMeta(property, value))
      }
      onResetUploadStatus={() => dispatch(resetUploadStatus())}
      onUploadContract={() => dispatch(uploadContract())}
    />
  );
};
