import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as LeasedLineQuotesAPI from "../../api/v1/leasedLineQuotes";
import { addAlertMessage } from "../uiState/actions";
import { STEP_CUSTOMISE } from "../uiState/constants";
import { EthernetTypeEnum } from "./constants";

// Async

export const fetchAvailableRouters = createAsyncThunk(
  "ethernetProducts/fetchAvailableRouters",
  async (configIndex: number, { getState, rejectWithValue }) => {
    const state: any = getState();
    const config = state.ethernetProducts.configurations[configIndex];
    if (config.availableRouters.fetching) rejectWithValue("");
    let geaSpeed;
    if (config.site_b_type === "GEA") {
      geaSpeed = config.site_b_bearer === "40" ? "10" : "20";
    }
    const response = await LeasedLineQuotesAPI.routers({
      bearer: config.site_b_bearer,
      speed: config.speed || geaSpeed,
      technology_type: config.site_b_type,
    });
    return { configIndex, response };
  }
);

export const requestAllEthernetPrices = createAsyncThunk(
  "ethernetProducts/requestAllEthernetPrices",
  async (arg, { getState, dispatch }) => {
    const state: any = getState();
    const requests = state.ethernetProducts.configurations.map(
      (c: any, i: number) => {
        if (c.selectedQuoteId) return true;
        if (c.pricingRequestStatus !== false) return true;
        return dispatch(
          fetchEthernetPricingRequest({ config: c, configIndex: i })
        );
      }
    );
    return await Promise.all(requests);
  }
);

export const fetchEthernetPricingRequest = createAsyncThunk(
  "ethernetProducts/fetchEthernetPricingRequest",
  async (
    { config, configIndex }: any,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state: any = getState();
    const params = {
      account_id: state.order.accountId,
      customer_quote_reference: `GuidedSales${state.order.id}`,
      markup_policy_name:
        state.account?.settings?.response?.settings
          ?.ethernet_markup_policy_name || "retail",
      product: config.product,

      dont_price_the_router: false,
      router_product_id: config.selectedRouterId,

      backup_line: config.backup_line,
      backup_broadband: config.backup_broadband,
      backup_broadband_technology: config.backup_broadband_technology,

      site_a_bearer: false,

      ...(config.type === EthernetTypeEnum.ETHERNET_PRODUCT_PTP && {
        site_a_building: config.purchaseArgs.site_a_site_address_building,
        site_a_street: config.purchaseArgs.site_a_site_address_street,
        site_a_post_town: config.purchaseArgs.site_a_site_address_post_town,
        site_a_postcode: config.purchaseArgs.site_a_site_address_postcode,
        site_a_country: config.purchaseArgs.site_a_site_address_country,
        site_a_uprn: config.site_a_uprn,
        site_a_cli: false,
        site_a_interconnect: false,
        site_a_type: false,
      }),

      site_b_building: config.purchaseArgs.site_b_site_address_building,
      site_b_street: config.purchaseArgs.site_b_site_address_street,
      site_b_post_town: config.purchaseArgs.site_b_site_address_post_town,
      site_b_postcode: config.purchaseArgs.site_b_site_address_postcode,
      site_b_country: config.purchaseArgs.site_b_site_address_country,
      site_b_uprn: config.site_b_uprn,

      site_b_bearer: config.site_b_bearer,
      site_b_cli: config.site_b_cli,
      site_b_type: config.site_b_type,
      site_b_address_reference: false,
      source_ref: false,
      speed:
        config.speed ||
        (config.site_b_type === "GEA" &&
          (config.site_b_bearer === "40" ? "10" : "20")),
      site_a_cabinet: false,
      site_a_shelf: false,
      site_b_cabinet: false,
      site_b_shelf: false,
      external_source_name: "Guided Sales",
    };

    try {
      const result: any = await LeasedLineQuotesAPI.pricingRequest(params);
      // @hack - run post fulfilled action;
      setTimeout(() => {
        const promiseInfo = { pending: false };
        const intervalId = setInterval(() => {
          const state: any = getState();
          const config = state.ethernetProducts.configurations[configIndex];
          const status = config.pricingRequestStatus;
          const activeStep = state.uiState.activeStep;

          if (["partial_results", "complete", "error"].indexOf(status) > -1) {
            clearInterval(intervalId);
          } else if (activeStep !== STEP_CUSTOMISE || config.selectedQuoteId) {
            clearInterval(intervalId);
          } else {
            if (!promiseInfo.pending) {
              dispatch(
                fetchEthernetPricingResults({ configIndex, promiseInfo })
              );
            }
          }
        }, 5000);
      });
      return { configIndex, pricingRequestId: result.id };
    } catch (error) {
      dispatch(addAlertMessage(error));
      rejectWithValue({ configIndex, status: "failed" });
    }
  }
);

export const fetchEthernetPricingResults = createAsyncThunk(
  "ethernetProducts/fetchEthernetPricingResults",
  async (
    { configIndex, promiseInfo }: any,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state: any = getState();
    try {
      promiseInfo.pending = true;
      const config = state.ethernetProducts.configurations[configIndex];
      let result: any = await LeasedLineQuotesAPI.results(
        config.pricingRequestId
      );
      promiseInfo.pending = false;
      if (result.request) {
        dispatch(
          updateEthernetPricingRequestStatus(
            configIndex,
            result.request.status,
            result.providers
          )
        );
        return { configIndex, results: result.results };
      } else if (result.status) {
        dispatch(addAlertMessage(result.message));
        rejectWithValue(result.message);
      } else {
        rejectWithValue(null);
      }
    } catch (error) {
      dispatch(addAlertMessage(error));
      rejectWithValue(error);
    }
  }
);

export const requestAllEthernetPurchases = createAsyncThunk(
  "ethernetProducts/requestAllEthernetPurchases",
  async (arg, { getState, dispatch }) => {
    const state: any = getState();
    const configurations = state.ethernetProducts.configurations;
    configurations.forEach((config: any, configIndex: number) => {
      if (!config.purchaseResponse.order_id) {
        dispatch(fetchEthernetPurchase({ config, configIndex }));
      }
    });
  }
);

export const fetchEthernetPurchase = createAsyncThunk(
  "ethernetProducts/fetchEthernetPurchase",
  async ({ config, configIndex }: any, { getState, rejectWithValue }) => {
    const state: any = getState();
    try {
      const params = {
        account: state.order.accountId,
        order_id: state.order.id,
        placed_via: "guided_sales",
        speed: config.technology === "Fibre Flex 200+" ? "200+" : config.speed,
        backup_line: config.backup_line,
        backup_broadband: config.backup_broadband,
        backup_broadband_technology: config.backup_broadband_technology,
        ...config.purchaseArgs,
      };
      const response = await LeasedLineQuotesAPI.purchase(
        params,
        config.selectedQuoteId
      );
      return { configIndex, response };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// Sync

export const addEthernetConfiguration = createAction(
  "ethernetProducts/addEthernetConfiguration"
);

export const removeEthernetConfiguration = createAction<number>(
  "ethernetProducts/removeEthernetConfiguration"
);

export const clearEthernetConfiguration = createAction(
  "ethernetProducts/clearEthernetConfiguration"
);

export const updateEthernetConfiguration = createAction(
  "ethernetProducts/updateEthernetConfiguration",
  (configIndex: number, field: any, value: any) => ({
    payload: { configIndex, field, value },
  })
);

export const setEthernetConfigAddress = createAction(
  "ethernetProducts/setEthernetConfigAddress",
  (configIndex: number, wlr3Address: any, addressPrefix: string) => ({
    payload: { configIndex, wlr3Address, addressPrefix },
  })
);

export const setEthernetConfigType = createAction(
  "ethernetProducts/setEthernetConfigType",
  (configIndex: number, productType: any) => ({
    payload: { configIndex, productType },
  })
);

export const updateEthernetPricingRequestStatus = createAction(
  "ethernetProducts/updateEthernetPricingRequestStatus",
  (configIndex, status, providersStatus) => ({
    payload: { configIndex, status, providersStatus },
  })
);

export const selectEthernetQuote = createAction(
  "ethernetProducts/selectEthernetQuote",
  (configIndex, quoteId, technology, accountName) => ({
    payload: { configIndex, quoteId, technology, accountName },
  })
);

export const deselectEthernetQuote = createAction<number>(
  "ethernetProducts/deselectEthernetQuote"
);

export const updateEthernetCustomerData = createAction(
  "ethernetProducts/updateEthernetCustomerData",
  (configIndex, key, value) => ({
    payload: { configIndex, key, value },
  })
);

export const setEthernetConfigPurchaseArgsSubmitted = createAction<number>(
  "ethernetProducts/setEthernetConfigPurchaseArgsSubmitted"
);
