import React from "react";
import PropTypes from "prop-types";

import { InlinePriceIndicator } from "./InlinePriceIndicator";

interface InlinePriceMetaProps {
  productData: Record<string, any>;
  priceType: "ONE_OFF" | "RECURRING";
}

export const InlinePriceMeta = ({
  productData,
  priceType,
}: InlinePriceMetaProps) => {
  const component =
    productData.line || productData.broadband || productData.mobile;

  if (!component) return null; // i.e. the product data hasn't loaded yet.

  if (
    component.component_price.promotion_id ||
    component.component_price.one_off_discount_type ||
    component.component_price.recurring_discount_type
  ) {
    // TODO: This should have a popover on step 2, when we'll have product data and hence full info on discounts etc.
    return <InlinePriceIndicator />;
  }

  return null;
};

InlinePriceMeta.propTypes = {
  productData: PropTypes.object.isRequired,
  priceType: PropTypes.oneOf(["ONE_OFF", "RECURRING"]),
};
