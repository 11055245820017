import React, { ReactNode } from "react";

import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ProductTitle } from "../../../../../shared/components/MobileProduct/ProductTitle";
import { getNonGenericInstanceName } from "../../../../../store/mobile/selectors/resigns";
import { BoltOnChip } from "./BoltOnChip";
import { CliCell } from "./CliCell";
import { EvoServicesDrillDown } from "./EvoServicesDrillDown";
import { TransformedResult } from "../../../../../store/mobile/transformers";
import { getProductInstanceBoltOns } from "../../../../../store/mobile/selectors/resigns";
import { formatCurrency } from "../../../../../shared/utils/formatCurrency";
import { Network } from "../../../../../shared/types/MobileProductConfig";

interface NoChangesRowProps {
  isChecked: boolean;
  productInstance: TransformedResult;
  actions: ReactNode;
  toggleRow: () => void;
  className: string;
}

export const NoChangesRow = ({
  isChecked,
  toggleRow,
  productInstance,
  actions,
  className,
}: NoChangesRowProps) => {
  if (!productInstance) {
    return null;
  }

  const boltOns = getProductInstanceBoltOns(productInstance);

  return (
    <TableRow selected={isChecked} className={className}>
      <TableCell>
        <Checkbox color="secondary" onChange={toggleRow} checked={isChecked} />
      </TableCell>
      <CliCell productInstance={productInstance} />
      <TableCell colSpan={2}>
        <EvoServicesDrillDown
          transformedServices={productInstance.transformedServices}
        >
          <ProductTitle network={productInstance.network.name as Network}>
            {getNonGenericInstanceName(productInstance)}
          </ProductTitle>
        </EvoServicesDrillDown>
      </TableCell>
      <TableCell>
        {boltOns?.length
          ? boltOns.map((boltOn: any) => (
              <BoltOnChip
                title={boltOn.description}
                price={boltOn.rate}
                key={boltOn.description}
              />
            ))
          : "None"}
      </TableCell>
      <TableCell>{formatCurrency(productInstance.computedPrice)}</TableCell>
      <TableCell className="u-nowrap">{productInstance.end_date_ymd}</TableCell>
      <TableCell align="right" padding="none">
        {actions}
      </TableCell>
    </TableRow>
  );
};
