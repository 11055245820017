import React from "react";
import styled from "styled-components";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ErrorIcon from "@mui/icons-material/Error";
import {
  TextField,
  Grid,
  Typography,
  useTheme,
  InputAdornment,
} from "@mui/material";
import { ExpandingCard } from "@akj-dev/shared-components";

import { Spinner } from "../../../../shared/components/Spinner";

const ChargeStatement = styled.div`
  font-size: 16px;
  color: ${(p) => p.theme.palette.primary.main};
  margin: 0 10px;
`;

interface DaisyFreshProps {
  contractLengthInMonthsAllProducts: number;
  hardwareCredits: number;
  terminationFees: number;
  etf: number;
  perLineAmount: number;
  limitExceeded: boolean;
  allowedAmount: string;
  isFetching: boolean;
  onDaisyFreshHardwareCreditsChange: (value: string) => void;
  onDaisyFreshTerminationFees: (value: string) => void;
  onDaisyFreshEtf: (value: string) => void;
}

export const DaisyFresh = ({
  contractLengthInMonthsAllProducts,
  hardwareCredits,
  terminationFees,
  etf,
  limitExceeded,
  perLineAmount,
  allowedAmount,
  isFetching,
  onDaisyFreshHardwareCreditsChange,
  onDaisyFreshTerminationFees,
  onDaisyFreshEtf,
}: DaisyFreshProps) => {
  const theme = useTheme();

  const totalAmount = (hardwareCredits + terminationFees + etf).toFixed(2);

  return (
    <ExpandingCard
      title={"Daisy Fresh"}
      subtitle={"£" + totalAmount}
      avatar={
        isFetching ? (
          <Spinner />
        ) : limitExceeded ? (
          <ErrorIcon color="error" />
        ) : (
          <AccountBalanceIcon data-cy="daisyFreshAPIOK" />
        )
      }
      data-cy="DaisyFresh"
    >
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <TextField
            variant="standard"
            type="number"
            label="Hardware Credits"
            value={hardwareCredits || ""}
            onChange={(event) =>
              onDaisyFreshHardwareCreditsChange(event.target.value)
            }
            fullWidth={true}
            disabled={isFetching}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            variant="standard"
            type="number"
            label="Termination Fees"
            value={terminationFees || ""}
            onChange={(event) =>
              onDaisyFreshTerminationFees(event.target.value)
            }
            fullWidth={true}
            disabled={isFetching}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <TextField
            variant="standard"
            type="number"
            label="Remaining Contract Charges"
            value={etf || ""}
            onChange={(event) => onDaisyFreshEtf(event.target.value)}
            fullWidth={true}
            disabled={isFetching}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm={3}>
          <Typography variant="h3" style={{ marginTop: "34px" }}>
            {" "}
            = <b>£{totalAmount}</b> total
          </Typography>
          {limitExceeded && (
            <Grid
              item
              sm={12}
              style={{
                marginTop: "30px",
                color: theme.palette.secondary.main,
                fontSize: "16px",
              }}
            >
              Max: <b>£{allowedAmount}</b> (excluding remaining contractual
              charges)
            </Grid>
          )}
        </Grid>
        {perLineAmount > 0 && (
          <Grid item sm={12}>
            <ChargeStatement>
              <b>£{perLineAmount}</b> will be charged monthly, per mobile number
              for the <b>{contractLengthInMonthsAllProducts} month</b> term of
              the contract.
            </ChargeStatement>
          </Grid>
        )}
      </Grid>
    </ExpandingCard>
  );
};
