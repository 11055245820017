import React from "react";
import { Box, List, Typography, ListItem, Divider, Theme } from "@mui/material";
import { OrderCard } from "./OrderCard";
import { getOrderStatusDisplayLabel } from "../../../../helpers/getOrderStatusDisplayLabel";
import { OrderStatus, Order, orderStatuses } from "../../../../types";

const styles = {
  list: {
    flex: 1,
    minWidth: "216px",
    maxWidth: "400px",
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  divider: {
    margin: (theme: Theme) => `0 ${theme.spacing(1)}`,
  },
};

type Props = {
  orders: Partial<{ [key in OrderStatus]: Order[] }>;
};

export const Orders = ({ orders }: Props) => {
  if (Object.keys(orders).length === 0) {
    return <Typography>No matching records found.</Typography>;
  }

  // create entries for all OrderStatus types even if they are not present in the data store
  // so we can have a column for each
  const orderEntries: [string, Order[] | undefined][] = Object.entries(
    orderStatuses.reduce(
      (prev, acc: OrderStatus) => ({
        ...prev,
        [acc]: orders[acc],
      }),
      {}
    )
  );

  return (
    <Box display="flex" marginBottom={2} paddingBottom={2} overflow="auto">
      {orderEntries
        .sort(compareGroupedOrders)
        .map(([status, orderProps], i, arr) => (
          <React.Fragment key={status}>
            <List sx={styles.list} data-cy="status-column">
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography variant="h3">
                  {getOrderStatusDisplayLabel(status as OrderStatus)}
                </Typography>
                <Typography variant="h3" data-cy="status-column-total">
                  {" "}
                  {orderProps ? orderProps.length : 0}
                </Typography>
              </Box>
              {orderProps
                ?.sort(
                  (a, b) =>
                    b.creation_date_timestamp - a.creation_date_timestamp
                )
                .map((order) => (
                  <ListItem key={order.id} disableGutters>
                    <OrderCard order={order} />
                  </ListItem>
                ))}
            </List>
            {i < arr.length - 1 && (
              <Divider
                sx={styles.divider}
                orientation="vertical"
                flexItem
                light
              />
            )}
          </React.Fragment>
        ))}
    </Box>
  );
};

function compareGroupedOrders(
  a: [string, Order[] | undefined],
  b: [string, Order[] | undefined]
): number {
  const sortOrder: OrderStatus[] = [
    "committed",
    "in_progress",
    "attention",
    "delayed",
    "complete",
    "cancelled",
  ];

  return (
    sortOrder.findIndex((status) => status === a[0]) -
    sortOrder.findIndex((status) => status === b[0])
  );
}
