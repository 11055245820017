import React from "react";

import { Box } from "@mui/material";
import { StatusChip } from "@akj-dev/shared-components";
import { AccountSettings } from "@akj-dev/affinity-platform";

import { UploadSignedContractContainer } from "../containers/UploadSignedContract";
import { ProvisionOrderButtonContainer } from "../containers/ProvisionOrderButton";

import { GenerateContractContainer } from "../containers/GenerateContract";
import { ApproveQuoteContainer } from "../containers/ApproveQuote";

import { CommercialPricingRequest } from "../containers/CommercialPricingRequest";
import { HostEnv } from "../../../index";
import { UploadSupportingDocumentsContainer } from "../containers/UploadSupportingDocuments";

interface CompleteOrderSectionProps {
  requiresCustomerApproval: boolean;
  accountSettings: AccountSettings;
  ethernetConfigurations: any[];
  hostEnv: HostEnv;
  order: Record<string, any>;
  actions: Record<string, any>;
  isHardwareOnly: boolean;
  isRejected: boolean;
}

export const CompleteOrderSection = ({
  requiresCustomerApproval,
  accountSettings,
  ethernetConfigurations,
  order,
  isHardwareOnly,
  hostEnv,
  actions,
  isRejected,
}: CompleteOrderSectionProps) => {
  return (
    <Box mb={2} textAlign="right" data-cy="completeOrderActions">
      {accountSettings.can_upload_supporting_documents === "1" && (
        <UploadSupportingDocumentsContainer />
      )}
      {requiresCustomerApproval ? (
        <StatusChip
          type="info"
          message={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box mr={1}>Sale requires approval</Box>
              {!order.sendForApproval.fetching && <ApproveQuoteContainer />}
            </Box>
          }
        />
      ) : null}

      {isRejected ? (
        <Box mb={1}>
          <StatusChip
            type="error"
            message={<Box>The order has been rejected.</Box>}
          />
        </Box>
      ) : null}

      {hostEnv === "my_account" ||
      isHardwareOnly ||
      accountSettings.as_provision_only === "1" ? (
        <ProvisionOrderButtonContainer />
      ) : (
        !requiresCustomerApproval && (
          <>
            {ethernetConfigurations.length < 1 &&
              !order.sendForApproval.fetching &&
              (actions.upload_signed_contract?.ok ? (
                <UploadSignedContractContainer />
              ) : (
                actions.upload_signed_contract?.reason &&
                accountSettings.dc_allow_upload_contract === "1" && (
                  <Box mb={1}>
                    <StatusChip
                      type="info"
                      message={
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box mr={1}>
                            Can't Upload Contract:{" "}
                            {actions.upload_signed_contract?.reason}
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                )
              ))}
            {accountSettings.as_generate_contract === "1" &&
              (actions.generate_and_send_contract?.ok ? (
                <GenerateContractContainer />
              ) : (
                actions.generate_and_send_contract?.reason && (
                  <StatusChip
                    type="info"
                    message={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box mr={1}>
                          Can't Send E-Sign Contract:{" "}
                          {actions.generate_and_send_contract?.reason}
                        </Box>
                      </Box>
                    }
                  />
                )
              ))}
            {accountSettings.as_enable_commercial_request === "1" && (
              <CommercialPricingRequest />
            )}
          </>
        )
      )}
    </Box>
  );
};
