import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { createContract, selectContactForContract } from "./actions";

const generateContract = createSlice({
  name: "generateContract",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectContactForContract, (state, action) => {
        state.contactId = action.payload;
      })
      .addCase(createContract.pending, (state) => {
        state.generatingContract = true;
      })
      .addCase(createContract.rejected, (state, action) => {
        state.generatingContract = false;
        state.error = action.payload;
      })
      .addCase(createContract.fulfilled, (state, action) => {
        state.generatingContract = false;
        state.contract = action.payload;
        state.openRedirectDialog = true;
      });
  },
});

export default generateContract.reducer;
