import React from "react";
import _ from "lodash";

import { ETFMethodContainer } from "../containers/ETFMethod";

interface ETFSectionProps {
  order: Record<string, any>;
}

export const ETFSection = ({ order }: ETFSectionProps) => {
  return _.get(order.orderStatus.response, "data.valid_etf_methods", [])
    .length > 0 ? (
    <ETFMethodContainer />
  ) : null;
};
