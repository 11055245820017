import { MonitoringServiceConfig, MonitoringServiceState } from "./types";

const initialState: MonitoringServiceState = {
  alertPreferences: {
    emailAddress: "",
    phoneNumber: "",
  },
  productSearch: {
    fetching: false,
    response: {},
  },
  configs: [],
};

export const blankConfig: MonitoringServiceConfig = {
  productId: false,
  oneOffPrice: "",
  recurringPrice: "",
  orderProduct: {
    fetching: false,
    response: {},
  },
};

export default initialState;
