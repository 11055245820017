import React, { ReactElement, ReactNode } from "react";
import { Table, TableBody } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setFields } from "../../../../store/hardware/configurations/actions";
import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import { HardwareDeliverySplitRow } from "../../components/Hardware/DeliverySplitRow";

interface SplitDeliveryHardwareSplitListProps {
  productId: string;
  productName: string;
}

export const SplitDeliveryHardwareSplitList = ({
  productId,
  productName,
}: SplitDeliveryHardwareSplitListProps) => {
  const dispatch = useDispatch();
  const rows = useSelector(
    (state: any) => state.hardwareConfigurations[productId].rows
  );

  const handleChangeAddressType =
    (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setFields(
          productId,
          {
            addressType: event.target.value,
            addressId: "",
          },
          i
        )
      );
    };

  const handleClickExistingAddressSelect = (i: number) => () => {
    dispatch(
      setFields(
        productId,
        {
          addressType: addressTypes.EXISTING,
        },
        i
      )
    );
  };

  const handleSelectAccountAddress =
    (i: number) => (event: any, child: ReactNode) =>
      dispatch(
        setFields(
          productId,
          {
            addressId: (child as ReactElement).props.value,
          },
          i
        )
      );

  return (
    <Table>
      <TableBody>
        {rows.map((row: any, i: number) => {
          return (
            <HardwareDeliverySplitRow
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              addressType={row.addressType}
              addressId={row.addressId}
              handleChangeAddressType={handleChangeAddressType(i)}
              handleClickExistingAddressSelect={handleClickExistingAddressSelect(
                i
              )}
              handleSelectAccountAddress={handleSelectAccountAddress(i)}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
