import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CallingFeatures } from "../../../../components/wlrBroadband/CallingFeatures";

// This is everything belonging to category: Calling and Network Features
// Except:
//
// presentation_number
// direct_connect_number
// divert_to_number
// presentation_number & cnf_telephone_number

interface CallingFeaturesContainerProps {
  targetConfig: number;
}

export const CallingFeaturesContainer = ({
  targetConfig,
}: CallingFeaturesContainerProps) => {
  const config = useSelector(
    (state: any) => state.wlrBroadband.configurations[targetConfig]
  );

  const locationType = useSelector<any, string>(
    (state) => state.wlrBroadband.locations[config.locationIndex].type
  );

  return (
    <CallingFeatures
      callerDisplay={!!config.wlrProperties.caller_display}
      locationType={locationType}
    />
  );
};

CallingFeaturesContainer.propTypes = {
  targetConfig: PropTypes.number.isRequired,
};
