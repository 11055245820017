import React from "react";
import { useSelector } from "react-redux";

import { RemoteValidationErrors } from "../../../components/wlrBroadband/RemoteValidationErrors";

interface RemoteValidationErrorsContainerProps {
  targetConfig: number;
}

export const RemoteValidationErrorsContainer = ({
  targetConfig,
}: RemoteValidationErrorsContainerProps) => {
  const wlrValidation = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig]?.wlrValidation?.response
  );
  const wlrValidationQuote = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig]?.wlrValidationQuote
        ?.response
  );
  const broadbandValidation = useSelector(
    (state: any) =>
      state.wlrBroadband.configurations[targetConfig]?.broadbandValidation
        ?.response
  );

  if (
    wlrValidation?.status === "error" ||
    wlrValidationQuote?.status === "error" ||
    broadbandValidation?.status === "error"
  ) {
    return (
      <RemoteValidationErrors
        wlrValidation={wlrValidation}
        wlrValidationQuote={wlrValidationQuote}
        broadbandValidation={broadbandValidation}
      />
    );
  }

  return null;
};
