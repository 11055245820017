import {
  NotesState,
  NotesActionTypes,
  REQUEST_DATA,
  INSERT_NOTES,
  SET_ERROR,
  INSERT_NOTE,
  SET_SUBMIT_NOTE_ERROR,
  REQUEST_POST_NOTE,
  SET_DRAFT_FORM_DATA,
} from "./types";

const initialState: NotesState = {
  notes: {},
  fetchingIds: [],
  errors: [],
  draftNotes: {},
  submittingIds: [],
};

export function notesReducer(
  state = initialState,
  action: NotesActionTypes
): NotesState {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        fetchingIds: state.fetchingIds.concat([action.payload]),
        errors: [],
      };
    case SET_ERROR:
      return {
        ...state,
        fetchingIds: clearFetchingById(state, action.payload.orderId),
        errors: state.errors.concat([
          { ...action.payload.error, orderId: action.payload.orderId },
        ]),
      };
    case INSERT_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.orderId]: action.payload.notes,
        },
        fetchingIds: clearFetchingById(state, action.payload.orderId),
        errors: [],
      };
    case INSERT_NOTE:
      const noteToUpdate = state.notes[action.payload.orderId] || [];
      return {
        ...state,
        notes: {
          [action.payload.orderId]: [...noteToUpdate, action.payload.note],
        },
        draftNotes: {
          ...state.draftNotes,
          [action.payload.orderId]: undefined,
        },
        errors: [],
        submittingIds: clearSubmittingById(state, action.payload.orderId),
      };

    case SET_SUBMIT_NOTE_ERROR:
      return {
        ...state,
        errors: state.errors.concat({
          ...action.payload.error,
          orderId: action.payload.orderId,
        }),
        submittingIds: clearSubmittingById(state, action.payload.orderId),
      };

    case REQUEST_POST_NOTE:
      return {
        ...state,
        submittingIds: state.submittingIds.concat([action.payload.orderId]),
        errors: [],
      };

    case SET_DRAFT_FORM_DATA:
      return {
        ...state,
        draftNotes: {
          ...state.draftNotes,
          [action.payload.orderId]: action.payload.content,
        },
      };

    default:
      return state;
  }
}

function clearFetchingById(state: NotesState, orderId: string) {
  return state.fetchingIds.filter((id) => id !== orderId);
}

function clearSubmittingById(state: NotesState, orderId: string) {
  return state.submittingIds.filter((id) => id !== orderId);
}
