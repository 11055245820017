import { call, put, select, takeLatest } from "redux-saga/effects";

import * as actions from "./actionTypes";
import * as api from "../../api/notifications";
import { getErrorMessage } from "@akj-dev/affinity-platform";

function* fetch(): any {
  try {
    const filters = yield select((state) => state.notifications.filters);
    const response = yield call(api.paginate, filters);

    yield put({ type: actions.FETCH_OK, payload: response });
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error: "There was a problem fetching notifications. Please try again.",
      },
    });

    console.log("fetch notifications error:", getErrorMessage(error));
  }
}

function* remove(action: any) {
  try {
    yield call(api.remove, action.payload.id);

    yield call(fetch);
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error:
          "There was a problem when removing the notification. Please try again.",
      },
    });

    console.log("remove notification error:", getErrorMessage(error));
  }
}

function* setStatus(action: any) {
  try {
    yield call(api.setStatus, action.payload);

    yield call(fetch);
  } catch (error) {
    yield put({
      type: actions.FETCH_FAILED,
      payload: {
        error:
          "There was a problem when setting status for notification. Please try again.",
      },
    });

    console.log("notifications setStatus error:", getErrorMessage(error));
  }
}

export function* watchFetch() {
  yield takeLatest(actions.FETCH, fetch);
}

export function* watchRemove() {
  yield takeLatest(actions.REMOVE, remove);
}

export function* watchSetStatus() {
  yield takeLatest(actions.SET_STATUS, setStatus);
}
