import React from "react";
import PropTypes from "prop-types";
import HomeIcon from "@mui/icons-material/Home";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";
import { Grid } from "@mui/material";

/**
 * End user address.
 * DC has differing address field schemas so this gets rather nasty...
 * WLR + ISDN fields:
 *
 *     company
 *     contact_email
 *     contact_name
 *     contact_telephone
 *     county
 *     dependentlocality
 *     dependentthoroughfarename
 *     locality
 *     organisationname
 *     pobox
 *     postcode
 *     posttown
 *     premises

 * Broadband fields:

 *     bb.address.addressReference
 *     bb.address.cssDatabaseCode
 *     bb.address.postcode
 *     bb.address.postTown
 *     bb.address.premisesName
 *     bb.address.thoroughfareName
 *     bb.address.thoroughfareNumber
 *     bb.contact.businessName
 *     bb.contact.contactEmail
 *     bb.contact.contactForename
 *     bb.contact.contactSurname
 *     bb.contact.contactTelephone
 *     bb.contact.title

 * ...which also need to be copied over to

 *     bb.businessDirectory.*
 *     bb.residentialDirectory.*

 *     Then there's also globally:

 *     site_address_building
 *     site_address_street
 *     site_address_locale
 *     site_address_post_town
 *     site_address_region
 *     site_address_postcode
 *     site_address_country

 * ...which are legacy, but are still used for validation.

 * All these different formats need to be combined, hence this and logic in the reducer.

 * Properties in {hasBroadband} sections get copied to their WLR equivalents when applicable in the reducer.
 * If the config is broadband only, these copies will sit harmlessly in state and do nothing.

 * Properties in {isNewLine} sections don't have broadband equivalents and are only required for new line installs. i.e. Not "like for like".
 *
 */

interface EndUserAddressProps {
  hasBroadband: boolean;
  isNewLine: boolean;
}

export const EndUserAddress = ({
  hasBroadband,
  isNewLine,
}: EndUserAddressProps) => (
  <Section title="End User Address" avatar={<HomeIcon />}>
    <h4>Contact</h4>

    {hasBroadband ? (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.contact.businessName"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.contact.contactTelephone"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.contact.title"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.contact.contactForename"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.contact.contactSurname"
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <WlrBBDynamicField productType="wlr" propertyName="company" />
        </Grid>
        <Grid item md={12}>
          <WlrBBDynamicField productType="wlr" propertyName="contact_name" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="contact_telephone"
          />
        </Grid>
      </Grid>
    )}

    <h4>Address</h4>
    {hasBroadband ? (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.address.premisesName"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.address.thoroughfareNumber"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.address.thoroughfareName"
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="premisesname" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="thoroughfarenumber"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="thoroughfarename"
          />
        </Grid>
      </Grid>
    )}
    {isNewLine && (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="dependentthoroughfarename"
          />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="pobox" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField productType="wlr" propertyName="locality" />
        </Grid>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="dependentlocality"
          />
        </Grid>
      </Grid>
    )}
    {hasBroadband ? (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.address.postTown"
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <WlrBBDynamicField productType="wlr" propertyName="posttown" />
        </Grid>
      </Grid>
    )}
    {isNewLine && (
      <Grid container spacing={2}>
        <Grid item md={12}>
          <WlrBBDynamicField productType="wlr" propertyName="county" />
        </Grid>
      </Grid>
    )}
    {hasBroadband ? (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="broadband"
            propertyName="bb.address.postcode"
            disabled
          />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WlrBBDynamicField
            productType="wlr"
            propertyName="postcode"
            disabled
          />
        </Grid>
      </Grid>
    )}
  </Section>
);

EndUserAddress.propTypes = {
  hasBroadband: PropTypes.bool,
  isNewLine: PropTypes.bool,
};
