import React, { useCallback, useEffect, useRef } from "react";
import { OutlinedInput, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

interface QuantityFieldInputProps {
  disabled?: boolean;
  showError: boolean;
  inputValue: number | undefined;
  setInputValue: React.Dispatch<React.SetStateAction<number | undefined>>;
  showInput: boolean;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  handleBlur: () => void;
}

export const QuantityFieldInput = ({
  disabled,
  showError,
  inputValue,
  setInputValue,
  showInput,
  setShowInput,
  handleBlur,
}: QuantityFieldInputProps) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setShowInput(true);

      const numberValue = parseInt(e.target.value);
      if (isNaN(numberValue)) {
        setInputValue(undefined);
      } else {
        setInputValue(numberValue);
      }
    },
    [setInputValue, setShowInput]
  );

  useEffect(() => {
    if (showInput) {
      inputRef.current?.focus();
    }
  }, [showInput]);

  return (
    <OutlinedInput
      className={classes.field}
      error={showError}
      type="number"
      disabled={disabled}
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      inputProps={{ ref: inputRef, min: 0 }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: theme.spacing(5),
  },
}));
