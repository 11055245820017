import { getJSON, postJSON } from "../helpers";

export interface NoteAPIResponse {
  description: string;
  date_modified_timestamp: number;
  created_by_for_display: string;
  content_as_html: string;
  description_as_html: string;
  name: string;
  subject: string;
  id: string;
}

export function getNotesByOrderId(id: string | number) {
  return getJSON<NoteAPIResponse[]>("/Affinity/Orders/Notes/" + id);
}

export function postNoteByOrderId(id: string | number, content: string) {
  return postJSON<NoteAPIResponse>("/Affinity/Orders/AddNote/" + id, {
    content,
  });
}
