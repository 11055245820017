import React, {
  createContext,
  SyntheticEvent,
  useContext,
  useState,
} from "react";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export interface SnackbarMessage {
  message: string;
  key: number;
}

interface addSnackbar {
  (message: string, variant: "info" | "loading" | "error"): void;
}

interface ISnackbarContext {
  addSnackbar: addSnackbar;
}

const SnackbarContext = createContext<ISnackbarContext>(null as any);
export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider: React.FC = ({ children }) => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const addSnackbar: addSnackbar = (message, variant) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (
    event: Event | SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <SnackbarContext.Provider value={{ addSnackbar }}>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <>
            {/*<Button color="secondary" size="small" onClick={handleClose}>*/}
            {/*  UNDO*/}
            {/*</Button>*/}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ padding: 0.5 }}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </>
        }
        TransitionProps={{
          onExited: handleExited,
        }}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
