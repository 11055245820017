import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  resetUploadStatus,
  setDocumentsMeta,
  uploadDocuments,
} from "../../../../store/order/actions";
import { UploadSupportingDocumentsContent } from "../../components/UploadSupportingDocuments/Content";

interface ContentProps {
  closeDialog: () => void;
}
export const UploadSupportingDocumentsContentContainer = ({
  closeDialog,
}: ContentProps) => {
  const dispatch = useDispatch();

  const uploadSending = useSelector(
    (state: any) => state.order.documentsUpload.sending
  );
  const uploadResponse = useSelector(
    (state: any) => state.order.documentsUpload.response
  );
  const files = useSelector((state: any) => state.order.documentsUpload.files);

  return (
    <UploadSupportingDocumentsContent
      files={files}
      uploadResponse={uploadResponse}
      uploadSending={uploadSending}
      closeDialog={closeDialog}
      onChangeMeta={(property, value) =>
        dispatch(setDocumentsMeta(property, value))
      }
      onResetUploadStatus={() => dispatch(resetUploadStatus())}
      onUploadDocuments={() => dispatch(uploadDocuments())}
    />
  );
};
