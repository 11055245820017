import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import * as ProductAPI from "../../api/v1/product";
import { getAccountId } from "../order/selectors";
import { addAlertMessage } from "../uiState/actions";

export const updateShippingMethodInState = createAction<string | number>(
  "shipping/updateShippingMethodInState"
);

export const fetchShippingMethods = createAsyncThunk(
  "shipping/fetchShippingMethods",
  async (arg, { dispatch, getState, rejectWithValue }) => {
    const state: any = getState();
    if (state.shipping.products.length) {
      rejectWithValue("");
    }
    try {
      const data: any = await ProductAPI.shippingSearch(getAccountId(state));
      return data.products;
    } catch (e) {
      dispatch(addAlertMessage(e));
      return [];
    }
  }
);
