import React from "react";
import { StatusChip } from "@akj-dev/shared-components";
import _ from "lodash";

interface SaleProductStatusChipProps {
  request: Record<string, any>;
  hideSuccess: boolean;
  title: string;
  retry?: () => void;
}

export function SaleProductStatusChip({
  request,
  title,
  retry,
  hideSuccess,
}: SaleProductStatusChipProps) {
  if (!request) return null;

  if (request.fetching)
    return (
      <StatusChip
        type="loading"
        title={
          request.isUpdate
            ? `Updating ${title} on sale.`
            : `Adding ${title} to sale.`
        }
        message="Please wait..."
        hasMarginBottom
      />
    );

  if (request.response?.status === "error")
    return (
      <StatusChip
        type="error"
        title={`Error adding ${title} to sale.`}
        message={request.response.message}
        retry={retry}
        hasMarginBottom
      />
    );

  if (request.response?.status === "success" && !hideSuccess)
    return (
      <StatusChip
        type="success"
        title={`${_.capitalize(title)} successfully ${
          request.isUpdate ? "updated on" : "added to"
        } sale.`}
        message={_.get(request.response, "data.name")}
        hasMarginBottom
      />
    );

  return null;
}
