import React from "react";
import Icon from "@mui/icons-material/SettingsApplications";
import { Section } from "../../../../shared/components/Section";
import WlrBBDynamicField from "./WlrBBDynamicField";

interface BroadbandConfigurationProps {
  hasLine: boolean;
  isSOGEA: boolean;
}

export const BroadbandConfiguration = ({
  hasLine,
  isSOGEA,
}: BroadbandConfigurationProps) => {
  return (
    <Section
      title="Broadband Configuration"
      avatar={<Icon />}
      defaultExpanded={false}
    >
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.cust_reference"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.stabilityOption"
        margin
      />
      <WlrBBDynamicField
        productType="broadband"
        propertyName="bb.fast_track_requested"
        margin
      />
      {hasLine && !isSOGEA && (
        <WlrBBDynamicField
          productType="broadband"
          propertyName="bb.sim_provide_ref"
          margin
        />
      )}
    </Section>
  );
};
