import React from "react";
import { TableCell, TableRow } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { addressTypes } from "../../../../store/hardwareDelivery/constants";
import {
  ExistingAddressSelectContainer,
  ExistingAddressSelectProps,
} from "../../containers/Hardware/ExistingAddressSelect";

import { AddressOptions, AddressOptionsProps } from "./AddressOptions";

interface HardwareDeliverySplitRowProps {
  addressType: addressTypes;
  addressId: string;
  handleChangeAddressType: AddressOptionsProps["handleChangeAddressType"];
  handleClickExistingAddressSelect: () => void;
  handleSelectAccountAddress: ExistingAddressSelectProps["handleSelectAccountAddress"];
}

export const HardwareDeliverySplitRow = ({
  addressType,
  handleChangeAddressType,
  handleClickExistingAddressSelect,
  handleSelectAccountAddress,
  addressId,
}: HardwareDeliverySplitRowProps) => {
  const classes = useStyles();

  return (
    <TableRow data-cy="SplitEditRow">
      <TableCell align="right" colSpan={2}>
        <AddressOptions
          addressType={addressType}
          handleChangeAddressType={handleChangeAddressType}
        />
      </TableCell>
      <TableCell className={classes.addressSelectCell}>
        <div
          className={classes.addressSelect}
          onClick={handleClickExistingAddressSelect}
        >
          <ExistingAddressSelectContainer
            disabled={addressType !== addressTypes.EXISTING}
            handleSelectAccountAddress={handleSelectAccountAddress}
            addressId={addressId}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles(() => ({
  addressSelect: {
    width: 400,
    marginLeft: "auto",
  },
  addressSelectCell: {
    width: 400,
  },
}));
