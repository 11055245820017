import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddAppointmentStatusChip } from "../components/AddAppointmentStatusChip";
import {
  doAddBroadbandAppointment,
  doAddWlrAppointment,
} from "../../../store/wlrBroadband/actions/configurations";

interface AddAppointmentsStatusContainerProps {
  hideSuccess?: boolean;
}

export const AddAppointmentStatusContainer = ({
  hideSuccess,
}: AddAppointmentsStatusContainerProps) => {
  const dispatch = useDispatch();

  const wlrBBConfigs = useSelector<any, any[]>(
    (state) => state.wlrBroadband.configurations
  );

  return (
    <div>
      {
        // Add wlr appointment status
        wlrBBConfigs.map((config, index) =>
          config.addWlrAppointment ? (
            <AddAppointmentStatusChip
              request={config.addWlrAppointment}
              hideSuccess={hideSuccess}
              // eslint-disable-next-line react/no-array-index-key
              key={`wlr${index}`}
              productType="wlr"
              retry={() => dispatch(doAddWlrAppointment(index))}
            />
          ) : null
        )
      }
      {
        // Add broadband appointment status
        wlrBBConfigs.map((config, index) =>
          config.addBroadbandAppointment ? (
            <AddAppointmentStatusChip
              request={config.addBroadbandAppointment}
              hideSuccess={hideSuccess}
              // eslint-disable-next-line react/no-array-index-key
              key={`broadband${index}`}
              productType="broadband"
              retry={() => dispatch(doAddBroadbandAppointment(index))}
            />
          ) : null
        )
      }
    </div>
  );
};
