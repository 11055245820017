import { useCallback, useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { VirtualTableSortOrder } from "./enums";
import { ActiveSort } from "./types";
import { isNaN } from "formik";

export const sortVirtualTableData =
  (activeSort: ActiveSort) => (objA: any, objB: any) => {
    const { sortIndex, order } = activeSort;
    const isAsc = order === VirtualTableSortOrder.ASC;

    const a = get(objA, sortIndex);
    const b = get(objB, sortIndex);

    const undefA = a === undefined;
    const undefB = b === undefined;

    // Undefined handling
    if (undefA && undefB) {
      return 0;
    }
    if (undefA) {
      return -1;
    }
    if (undefB) {
      return 1;
    }

    // number and stringified number handling

    const numberA = parseFloat(a);
    const numberB = parseFloat(b);

    const nanA = isNaN(numberA);
    const nanB = isNaN(numberB);

    if (!nanA && !nanB) {
      return isAsc ? numberA - numberB : numberB - numberA;
    }

    // string handling

    // I have to manually retype `a` and `b` because of stupid TS
    const stringA = a as number | string;
    const stringB = b as number | string;

    if (typeof stringA === "string" && typeof stringB === "string") {
      return isAsc ? a.localeCompare(b) : -a.localeCompare(b);
    }

    return 0;
  };

export const handleActiveSort =
  (setActiveSort: React.Dispatch<React.SetStateAction<ActiveSort>>) =>
  (newSortIndex: string) => {
    setActiveSort(({ sortIndex, order }) => {
      const sameIndex = sortIndex === newSortIndex;

      if (sameIndex) {
        return {
          sortIndex,
          order:
            order === VirtualTableSortOrder.ASC
              ? VirtualTableSortOrder.DESC
              : VirtualTableSortOrder.ASC,
        };
      } else {
        return {
          sortIndex: newSortIndex,
          order: VirtualTableSortOrder.ASC,
        };
      }
    });
  };

export const useDebouncedState = (props?: {
  debounce?: number;
  defaultState?: string;
}) => {
  const { debounce = 500, defaultState = "" } = props ?? {};
  const [state, setState] = useState(defaultState);
  const [debouncedState, setDebouncedState] = useState(defaultState);

  const handleState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setState(event.target.value);
    },
    [setState]
  );

  // debounce of a state
  useEffect(() => {
    let stop = false;

    setTimeout(() => {
      if (!stop) {
        setDebouncedState(state);
      }
    }, debounce);

    return () => {
      stop = true;
    };
  }, [setDebouncedState, state, debounce]);

  return { state, debouncedState, handleState };
};

export const useTableHeight = (
  itemCount: number,
  itemSize: number,
  tableSize: number
) =>
  useMemo(() => {
    if (itemCount === 0) {
      return "fit-content";
    }

    const dataSize = itemCount * itemSize + 48;

    if (dataSize < tableSize) {
      return dataSize;
    }

    return tableSize;
  }, [itemCount, itemSize, tableSize]);
