import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { StatusChip } from "@akj-dev/shared-components";

import {
  fetchDaisyFreshSearch,
  fetchHardwareCreditProductSearch,
  setDaisyFreshLimitExceeded,
  setDaisyFreshEtf,
  setDaisyFreshHardwareCredits,
  setDaisyFreshTerminationFees,
} from "../../../../store/mobile/actions";
import {
  getDaisyFreshAllowedAmount,
  getDaisyFreshPerLineAmount,
  productDataIsFetching as productDataIsFetchingSelector,
} from "../../../../store/mobile/selectors/daisyFresh";

import { DaisyFresh } from "../../components/Mobile/DaisyFresh";

export const DaisyFreshContainer = () => {
  const dispatch = useDispatch();

  const daisyFreshSearch = useSelector(
    (state: any) => state.mobile.daisyFreshSearch
  );
  const hardwareCreditProductSearch = useSelector(
    (state: any) => state.mobile.hardwareCreditProductSearch
  );
  const { hardwareCredits, terminationFees, etf, limitExceeded } = useSelector(
    (state: any) => state.mobile.daisyFreshAmounts
  );
  const contractLengthInMonthsAllProducts = useSelector(
    (state: any) => state.mobile.contractLengthInMonthsAllProducts
  );
  const perLineAmount = useSelector<any, number>((state) =>
    getDaisyFreshPerLineAmount(state).toFixed(2)
  );
  const allowedAmount = useSelector<any, string>((state) =>
    getDaisyFreshAllowedAmount(state.mobile).toFixed(2)
  );
  const productDataIsFetching = useSelector<any, boolean>((state) =>
    productDataIsFetchingSelector(state)
  );

  useEffect(() => {
    dispatch(setDaisyFreshLimitExceeded());
  }, [dispatch]);

  if (
    !daisyFreshSearch.fetching &&
    daisyFreshSearch.response.status === "error"
  )
    return (
      <StatusChip
        type="error"
        title="Error finding Daisy Fresh Product"
        retry={() => dispatch(fetchDaisyFreshSearch())}
      />
    );

  if (
    !hardwareCreditProductSearch.fetching &&
    hardwareCreditProductSearch.response.status === "error"
  )
    return (
      <StatusChip
        type="error"
        title="Error finding Hardware Credit Product"
        retry={() => dispatch(fetchHardwareCreditProductSearch())}
      />
    );

  const anythingFetching =
    daisyFreshSearch.fetching ||
    hardwareCreditProductSearch.fetching ||
    productDataIsFetching;

  return (
    <DaisyFresh
      hardwareCredits={hardwareCredits}
      terminationFees={terminationFees}
      etf={etf}
      isFetching={anythingFetching}
      allowedAmount={allowedAmount}
      contractLengthInMonthsAllProducts={contractLengthInMonthsAllProducts}
      limitExceeded={limitExceeded}
      perLineAmount={perLineAmount}
      onDaisyFreshEtf={(value) => dispatch(setDaisyFreshEtf(value))}
      onDaisyFreshHardwareCreditsChange={(value) =>
        dispatch(setDaisyFreshHardwareCredits(value))
      }
      onDaisyFreshTerminationFees={(value) =>
        dispatch(setDaisyFreshTerminationFees(value))
      }
    />
  );
};
