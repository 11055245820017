import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetUploadDocuments } from "../../../../store/order/actions";
import { UploadSupportingDocuments } from "../../components/UploadSupportingDocuments";
import { UploadSupportingDocumentsContentContainer } from "./Content";

export const UploadSupportingDocumentsContainer = () => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);

  const sendForApprovalFetching = useSelector(
    (state: any) => state.order.sendForApproval.fetching
  );
  const approveApprovalFetching = useSelector(
    (state: any) => state.order.approveApproval.fetching
  );

  const openDialog = () => {
    dispatch(resetUploadDocuments());
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <UploadSupportingDocuments
      showDialog={showDialog}
      openDialog={openDialog}
      closeDialog={closeDialog}
      isUploadButtonDisabled={
        sendForApprovalFetching || approveApprovalFetching
      }
      contentChildren={
        <UploadSupportingDocumentsContentContainer closeDialog={closeDialog} />
      }
    />
  );
};
