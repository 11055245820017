import { useMemo } from "react";

export const useTableHeaders = (hasConnection: boolean) =>
  useMemo(
    () => [
      {
        label: "Product",
        sortIndex: "name",
      },
      {
        label: "Daisy Fresh",
        sortIndex: "price.daisyFresh",
        width: 300,
      },
      {
        label: "Pay Up Front",
        sortIndex: `price.${hasConnection ? "oneOffWithConnection" : "oneOff"}`,
        width: 280,
      },
    ],
    [hasConnection]
  );
