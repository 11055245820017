import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
  info: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface FlashMessagesProps {
  flashMessages: { id: string | number; message: string }[];
  onClose: (id: string | number) => void;
}

export function FlashMessages({ flashMessages, onClose }: FlashMessagesProps) {
  const classes = useStyles();

  // TODO: The logic to close these needs refactoring.
  //  Removing the components immediately means transition animations are ignored.
  //  See https://material-ui.com/components/snackbars/#consecutive-snackbars
  return (
    <div>
      {flashMessages.length
        ? flashMessages.map((flashMessage) => (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              autoHideDuration={6000}
              open={true}
              onClose={() => onClose(flashMessage.id)}
              key={flashMessage.id}
            >
              <SnackbarContent
                className={classes.info}
                message={
                  <Box display="flex" alignItems="center">
                    <Box mr={1}>
                      <InfoIcon />
                    </Box>
                    {flashMessage.message}
                  </Box>
                }
                action={
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={() => onClose(flashMessage.id)}
                    size="large"
                  >
                    <CloseIcon />
                  </IconButton>
                }
              />
            </Snackbar>
          ))
        : null}
    </div>
  );
}
