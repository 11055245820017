import { EthernetTypeEnum } from "../../../store/ethernetProducts/constants";
import { ReduxFetchType } from "../../../store/_root/types";

export interface ReduxEthernetConfiguration {
  product: string;
  type: EthernetTypeEnum;
  orderType?: OrderType;
  speed: string;
  site_a_uprn: any;
  site_b_uprn: any;
  site_b_type: string;
  site_b_bearer: string;
  site_b_cli: string;
  purchaseArgs?: {
    site_a_site_address_postcode?: string;
    site_b_site_address_postcode?: string;
  };
  availableRouters: ReduxFetchType<AvailableRoutersResponse>;
  selectedRouterId: string;
  backup_line: boolean;
  backup_broadband: boolean;
  backup_broadband_technology: string;
  backup_broadband_provider: boolean;
  pricingRequestId: boolean;
  pricingRequestStatus: boolean;
}

export enum OrderType {
  NEW = "NEW",
  RENEWAL = "RENEWAL",
}

export interface AvailableRoutersResponse {
  results: {
    all: AvailableRouter[];
    primary: AvailableRouter;
  };
}

export interface AvailableRouter {
  id: string;
  name: string;
  description?: string;
  suitable_for_adsl_fttc_backup?: "1" | "0";
  prices: {
    one_off: string;
    recurring: string;
  };
}
