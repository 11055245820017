import u from "updeep";

/**
 * Generate a reasonably random string of characters
 * For passwords and such.
 * See https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 * @returns {string}
 */
export const getRandomString = (length = 9) =>
  Math.random().toString(36).substring(2, length);

export const removeConfigForLocationIndex = (
  configs: any[],
  locationIndex: number
) => {
  return u.reject(configs, (c: any) => c.locationIndex === locationIndex);
};
