import * as Sentry from "@sentry/browser";
import initialState from "./state";
import { createSlice } from "@reduxjs/toolkit";
import { getNextIdFromArrayOfObjectsWithIdAsProperty, getSteps } from "./utils";
import { ERROR } from "../../shared/constants";
import { initCpq } from "../_root/actions";
import {
  addAlertMessage,
  addFlashMessage,
  confirmFinalStepWarning,
  hideFinalStepWarning,
  removeAlertMessages,
  removeFlashMessageById,
  resetUiState,
  setHardwareConfig,
  setProductTypes,
  setShowMobileResigns,
  setShowWlrResigns,
  setStep,
  setSubStepChoose,
  showFinalStepWarning,
  toggleOrderStatus,
  toggleStepOneEthernet,
  toggleStepOneHardware,
  toggleStepOneMobile,
  toggleStepOneMonitoringServiceProducts,
  toggleStepOneUniversalProducts,
  toggleStepOneWlrBroadband,
} from "./actions";

const uiState = createSlice({
  name: "uiState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Root
      .addCase(initCpq, (state, action) => {
        const { leadId } = action.payload;
        state.steps = getSteps(!!leadId);
      })
      .addCase(resetUiState, (state) => {
        state = initialState;
      })
      .addCase(setProductTypes, (state, action) => {
        state.productTypes = action.payload;
      })
      .addCase(setHardwareConfig, (state, action) => {
        state.chooseHardwareConfig = {
          ...state.chooseHardwareConfig,
          ...action.payload,
        };
      })

      // Messaging
      .addCase(addAlertMessage, (state, action) => {
        let alert = action.payload;
        const type = ERROR;
        const title = "Error";
        const nextId = getNextIdFromArrayOfObjectsWithIdAsProperty(
          state.alertMessages
        );
        if (alert instanceof Error) {
          Sentry.captureException(alert);
          console.error(alert);
        }
        const newMessage = { id: nextId, message: alert, type, title };
        state.alertMessages.push(newMessage);
      })
      .addCase(removeAlertMessages, (state, action) => {
        state.alertMessages = [];
      })
      .addCase(addFlashMessage, (state, action) => {
        const nextId = getNextIdFromArrayOfObjectsWithIdAsProperty(
          state.flashMessages
        );
        const newMessage = { id: nextId, message: action.payload };
        state.flashMessages.push(newMessage);
      })
      .addCase(removeFlashMessageById, (state, action) => {
        state.flashMessages = state.flashMessages.filter(function (
          flashMessage
        ) {
          return flashMessage.id !== action.payload;
        });
      })
      .addCase(showFinalStepWarning, (state) => {
        state.showFinalStepWarning = true;
      })
      .addCase(hideFinalStepWarning, (state) => {
        state.showFinalStepWarning = false;
      })
      .addCase(confirmFinalStepWarning, (state) => {
        state.showFinalStepWarning = false;
        state.finalStepWarningShown = true;
      })

      // Wizard
      .addCase(setStep, (state, action) => {
        const step = action.payload;
        const stepIndex = state.steps.indexOf(step);
        state.activeStep = step;
        state.visited[stepIndex] = true;
      })
      .addCase(setSubStepChoose, (state, action) => {
        state.activeSubStepChoose = action.payload;
      })
      .addCase(toggleStepOneMobile, (state) => {
        state.stepOneMobileOpen = !state.stepOneMobileOpen;
      })
      .addCase(toggleStepOneHardware, (state) => {
        state.stepOneHardwareOpen = !state.stepOneHardwareOpen;
      })
      .addCase(toggleStepOneEthernet, (state) => {
        state.stepOneEthernetOpen = !state.stepOneEthernetOpen;
      })
      .addCase(toggleStepOneWlrBroadband, (state) => {
        state.stepOneWlrBroadbandOpen = !state.stepOneWlrBroadbandOpen;
      })
      .addCase(toggleStepOneUniversalProducts, (state) => {
        state.stepOneUniversalProductsOpen =
          !state.stepOneUniversalProductsOpen;
      })
      .addCase(toggleStepOneMonitoringServiceProducts, (state) => {
        state.stepOneMonitoringServiceOpen =
          !state.stepOneMonitoringServiceOpen;
      })
      .addCase(toggleOrderStatus, (state) => {
        state.orderStatusOpen = !state.orderStatusOpen;
      })
      .addCase(setShowMobileResigns, (state) => {
        state.showMobileResigns = !state.showMobileResigns;
      })
      .addCase(setShowWlrResigns, (state) => {
        state.showWlrResigns = !state.showWlrResigns;
      });
  },
});

export default uiState.reducer;
