import React, { useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { StatusChip } from "@akj-dev/shared-components";
import PhoneIcon from "@mui/icons-material/Phone";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Theme, useTheme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { ChooseAddressDialog } from "@akj-dev/shared-components";

import {
  NEW_PRODUCT,
  SAME_PRODUCT_WITH_CHANGES,
} from "../../../../../store/wlrBroadband/constants";
import { NoAddressErrorAmend } from "./NoAddressErrorAmend";
import { hasLocationAddress } from "../../../../../shared/utils/addresses";
import { LineProductSelectorStatusRow } from "../../components/Resigns/LineProductSelectorStatusRow";
import {
  WLR3Address,
  WLR3AddressSearch,
} from "../../../../../api/v1/addressSearch";

interface LineProductSelectorProps {
  configuration: Record<string, any>;
  productInstance: Record<string, any>;
  location: Record<string, any>;
  updateProductInstance: Record<string, any>;
  lineAvailability: Record<string, any>;
  resignProduct: Record<string, any>;
  tagsCheck: Record<string, any>;
  products: Record<string, any>[];
  setResignProduct: (
    productInstance: Record<string, any>,
    resignProduct: Record<string, any>,
    resignProductType: string
  ) => void;
  onSetAddress: (address: WLR3Address) => void;
}

export const LineProductSelector = ({
  configuration,
  productInstance,
  tagsCheck,
  updateProductInstance,
  location,
  lineAvailability,
  resignProduct,
  products,
  setResignProduct,
  onSetAddress,
}: LineProductSelectorProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (tagsCheck.fetching) {
    return (
      <LineProductSelectorStatusRow
        type="loading"
        title="Please wait"
        message="Checking Tags"
      />
    );
  }
  if (tagsCheck?.response.status === "error") {
    return (
      <LineProductSelectorStatusRow
        type="error"
        title="Error"
        message={tagsCheck?.response.message}
      />
    );
  }
  if (updateProductInstance.fetching) {
    return (
      <LineProductSelectorStatusRow
        type="loading"
        title="Please wait"
        message="Updating Product Instance"
      />
    );
  }

  if (updateProductInstance?.response.status === "error") {
    return (
      <TableRow>
        <TableCell colSpan={9}>
          <StatusChip
            type="error"
            title="Error"
            message={updateProductInstance?.response.message}
          />
        </TableCell>
      </TableRow>
    );
  }

  // This field is required to be able to select an address.
  const hasPostcode = hasLocationAddress({ location, isPostcodeOnly: true });
  // These are the fields required to be able to do a line availability and broadband search.
  if (!hasPostcode) {
    return <NoAddressErrorAmend onSetAddress={onSetAddress} />;
  }
  if (hasPostcode && !hasLocationAddress({ location })) {
    const { address } = location;
    return (
      <>
        <TableRow>
          <TableCell colSpan={9}>
            <StatusChip
              type="error"
              title="Error"
              message="This product instance doesn't have a full address. Please select an address to continue with
              the resign process."
              link={
                <div
                  className={classes.action}
                  onClick={() => setIsDialogOpen(true)}
                >
                  Select an address
                </div>
              }
            />
          </TableCell>
        </TableRow>
        <ChooseAddressDialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          postcode={address.postcode}
          setAddress={onSetAddress}
          api={WLR3AddressSearch}
        />
      </>
    );
  }
  return (
    <TableRow>
      <TableCell colSpan={9}>
        <div className={classes.outer}>
          <div style={{ flex: 1 }}>
            <div className={classes.headerCtr}>
              <AutorenewIcon style={{ color: "#fff", marginRight: 10 }} />
              <b style={{ color: "#fff" }}>Select product</b>
            </div>
            <Table style={{ backgroundColor: "#fff" }}>
              <TableBody>
                {resignProduct &&
                  configuration.resignProductType ===
                    SAME_PRODUCT_WITH_CHANGES && (
                    <TableRow data-cy="wlrBroadbandResignLineSameRow">
                      <TableCell>
                        <div className={classes.productCtr}>
                          <PhoneIcon
                            style={{
                              color: theme.palette.grey[500],
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontWeight: "bold" }}>
                            {resignProduct.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{resignProduct.description}</TableCell>
                      <TableCell>
                        &pound;
                        {
                          resignProduct.price
                            .first_bill_recurring_price_with_promotions
                        }
                      </TableCell>
                      <TableCell>
                        <div style={{ float: "right" }}>
                          <Checkbox
                            color="secondary"
                            onChange={() =>
                              setResignProduct(
                                productInstance,
                                resignProduct,
                                SAME_PRODUCT_WITH_CHANGES
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                {configuration.resignProductType === NEW_PRODUCT &&
                  lineAvailability?.response.status === "success" &&
                  products?.length > 0 &&
                  products.map((product: any) => (
                    <TableRow
                      key={product.id}
                      data-cy="wlrBroadbandResignLineNewRow"
                    >
                      <TableCell>
                        <div className={classes.productCtr}>
                          <PhoneIcon
                            style={{
                              color: theme.palette.grey[500],
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontWeight: "bold" }}>
                            {product.name}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell>
                        &pound;
                        {
                          product.price
                            .first_bill_recurring_price_with_promotions
                        }
                      </TableCell>
                      <TableCell>
                        <div style={{ float: "right" }}>
                          <Checkbox
                            color="secondary"
                            onChange={() =>
                              setResignProduct(
                                productInstance,
                                product,
                                NEW_PRODUCT
                              )
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {lineAvailability.fetching && (
          <StatusChip
            type="loading"
            title="Please wait"
            message="Checking Line & Broadband Availability"
          />
        )}
        {lineAvailability?.response.status === "error" && (
          <StatusChip
            type="error"
            title="Error"
            message={lineAvailability?.response.message}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  outer: {
    backgroundColor: theme.palette.grey[500],
    display: "flex",
    marginBottom: 10,
    marginTop: 10,
    padding: 10,
  },
  productCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1,
  },
  headerCtr: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    paddingBottom: 16,
  },
  action: {
    marginLeft: 10,
    color: "inherit",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
