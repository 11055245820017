import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { StatusChip } from "@akj-dev/shared-components";

interface AddressCorrectProps {
  address?: Record<string, any>;
  prefix: string;
  open: boolean;
  updateAddress: (address?: Record<string, any>) => void;
}

export const AddressCorrect = ({
  address: initialAddress,
  prefix,
  open,
  updateAddress,
}: AddressCorrectProps) => {
  const [address, setAddress] = useState(initialAddress);

  useEffect(() => {
    setAddress(initialAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fields = [
    { key: "site_address_building", label: "Building", required: true },
    { key: "site_address_street", label: "Street", required: true },
    { key: "site_address_locale", label: "Locale" },
    { key: "site_address_post_town", label: "Post Town", required: true },
    { key: "site_address_region", label: "Region" },
    { key: "site_address_postcode", label: "Postcode", required: true },
    { key: "site_address_country", label: "Country", required: true },
  ];

  return (
    <Dialog open={open}>
      <DialogTitle>Address Correction</DialogTitle>
      <DialogContent>
        <StatusChip
          type="error"
          title="Low quality address"
          message="Please enter the required fields highlighted."
        />
        <Grid container spacing={2}>
          {fields.map((field) => {
            const key = `${prefix}${field.key}`;
            const value = address && address[key];

            return (
              <Grid item sm={6} key={key}>
                <TextField
                  label={field.label}
                  fullWidth
                  value={value || ""}
                  helperText={
                    field.required && !value && "This field is required."
                  }
                  error={field.required && !value}
                  onChange={(event) =>
                    setAddress({ [key]: event.target.value })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          endIcon={<CheckCircleIcon />}
          onClick={() => updateAddress(address)}
        >
          Correct
        </Button>
      </DialogActions>
    </Dialog>
  );
};
