import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import styled from "styled-components";

import Button from "@mui/material/Button";

import { StatusChip } from "@akj-dev/shared-components";

import { LowPriorityInfoChip } from "../../../shared/components/Chip/LowPriorityInfoChip";
import {
  fetchBillingAddress,
  uptdateBillingAddress,
} from "../../../store/account/actions";
import { AddressEntryFields } from "../components/AddressEntryFields";
import { getValidForResign } from "../../../store/account/selectors";

const DEFAULT_ADDRESS = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  address5: "",
  postcode: "",
  country: "",
};

const FIELDS = Object.keys(DEFAULT_ADDRESS);

/**
 * Show billing address with option to correct a low quality one
 * Note: There's a similar thing in src/js/containers/StepChoose/Ethernet/sections/AddressCorrect.js
 * where we correct low quality addresses generated from a postcode lookup at the start of the Ethernet process.
 */
export const BillingAddressContainer = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState({});

  const fetching = useSelector<any, boolean>(
    (state) => state.account.billingAddress?.fetching
  );
  const status = useSelector<any, string>(
    (state) => state.account.billingAddress?.response?.status
  );
  const billingAddress = useSelector(
    (state: any) => state.account.billingAddress?.response?.data
  );
  const errorMessage = useSelector<any, string>(
    (state) => state.account.billingAddress?.response?.message
  );
  const validForResign = useSelector<any, number>(getValidForResign);

  useEffect(() => {
    if (status !== "success") {
      dispatch(fetchBillingAddress());
    }
  }, [dispatch, status]);

  const updateAddressField = (field: string, value: string) =>
    setAddress((old) => ({ ...old, [field]: value }));

  const updateAddress = () => {
    dispatch(uptdateBillingAddress(address));
  };

  return (
    <>
      {status === "error" && (
        <MarginWrapper>
          <StatusChip
            type="error"
            title="Billing Address Error"
            message={errorMessage}
          />
        </MarginWrapper>
      )}
      {validForResign === 0 ? (
        <>
          <MarginWrapper>
            <StatusChip
              type="error"
              title="Low quality billing address"
              message="Please update before proceeding with order."
            />
          </MarginWrapper>
          <MarginWrapper>
            <AddressEntryFields
              existingAddress={billingAddress}
              newAddress={address}
              fields={FIELDS}
              disabled={fetching}
              updateField={updateAddressField}
            />
          </MarginWrapper>
          <Button
            variant="contained"
            color="primary"
            disabled={_.isEmpty(address) || fetching}
            onClick={updateAddress}
            data-cy="BillingAddressUpdateButton"
          >
            Update
          </Button>
        </>
      ) : (
        <LowPriorityInfoChip>
          {FIELDS.map((field) => (
            <div key={field}>{_.get(billingAddress, field)}</div>
          ))}
        </LowPriorityInfoChip>
      )}
    </>
  );
};

const MarginWrapper = styled.div`
  margin: 16px 0;
`;
