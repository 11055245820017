import React, { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { ResultsEntity } from "../../../../../api/v1/product";
import { MobileResignsTable } from "../../components/Resigns/MobileResignsTable";
import {
  fetchResignProductSearch,
  fetchProductInstances,
  removeResign,
  setResignProduct,
  setResignType,
} from "../../../../../store/mobile/actions";
import {
  RESIGN_WITH_CHANGES,
  RESIGN_WITHOUT_CHANGES,
} from "../../../../../store/mobile/constants";
import { ReduxMobileProductType } from "../../../../../shared/types/general";

export const ResignsTableContainer = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<number[]>([]);
  const [bulkSelectVisible, setBulkSelectVisible] = useState(false);
  const [filterState, setFilterState] = useState("");

  const mobileSearch = useSelector<any, any>(
    (state) => state.mobile.mobileSearch
  );
  const configs = useSelector<any, any>((state) => state.mobile.configs);
  const productInstances = useSelector<any, any>(
    (state) => state.mobile.productInstances
  );
  const resignProductSearch = useSelector<any, any>(
    (state) => state.mobile.resignProductSearch
  );
  const { contractLengthInMonthsUIFilter } = useSelector(
    (state: any) => state.mobile
  );

  const unrestrictInLifeProducts = useSelector(
    (state: any) => state.order.unrestrict
  );

  useEffect(() => {
    if (!productInstances.response.results) {
      dispatch(fetchProductInstances({}));
    }
    if (!resignProductSearch.response.products) {
      dispatch(fetchResignProductSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isChecked = (id: number) => selected.includes(id);

  const allChecked = () =>
    selected.length ===
    _.get(productInstances.response, "results", [])?.filter(
      (r: any) => r.resign_status !== "not-renewable"
    ).length;

  const toggleRow = (id: number) => {
    const i = selected.indexOf(id);
    if (i > -1) {
      setSelected([...selected.slice(0, i), ...selected.slice(i + 1)]);
    } else {
      setSelected([...selected, id]);
    }
  };

  const toggleAllRows = () => {
    setSelected(
      allChecked()
        ? []
        : _.get(productInstances.response, "results", [])
            ?.filter((r: any) => r.resign_status !== "not-renewable")
            .map((_: unknown, i: number) => i)
    );
  };

  const deselectAll = () => setSelected([]);

  const showBulkSelect = () => setBulkSelectVisible(true);

  const hideBulkSelect = () => {
    setBulkSelectVisible(false);
    setSelected([]);
  };

  const setFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterState(e.target.value);
  };

  const doFilter = (resign: ResultsEntity) => {
    if (!filterState) {
      return true;
    }

    const match = (e: string) =>
      e.toLowerCase().includes(filterState.toLowerCase());

    if (match(resign.pin) || match(resign.name) || match(resign.end_date_ymd))
      return true;

    return false;
  };

  const sortByNetwork = (products: any[], network: Record<string, any>) => {
    if (!products) {
      return products;
    }

    return [...products]
      .filter((p) => !p.first_mobile_component.is_data_only_voice)
      .sort((p) =>
        p.first_mobile_component.supplier === network.name ? -1 : 1
      );
  };

  const bulkSelectByCLI = (text: string, results: Record<string, any>[]) => {
    const array = text.split(/,|[, ]|\n/);
    let newSelected: number[] = [];
    results.forEach((item, index) => {
      const pin = item.pin;
      if (array.find((item) => item === pin)) {
        newSelected.push(index);
      }
    });

    setSelected(newSelected);
  };

  const results = productInstances.response?.results || [];

  const selectedInstanceIds = selected.map((i) => results[i].id);

  const products = useMemo(
    () =>
      (mobileSearch?.response?.products || []).filter(
        (product: ReduxMobileProductType) =>
          !contractLengthInMonthsUIFilter ||
          parseInt(product.minimum_contract_length_in_months) ===
            contractLengthInMonthsUIFilter
      ),
    [mobileSearch, contractLengthInMonthsUIFilter]
  );

  /**
   * VF Direct minimum price logic for in-life renewals bulk edit - TP46680
   * Also see per-instance logic further down
   */
  let bulkSelectedMinPrice = 0;
  if (selectedInstanceIds.length) {
    let prices: number[] = [];
    selectedInstanceIds.forEach((instanceId) => {
      const instance = results.find((r: any) => r.id === instanceId);
      if (instance?.resign_status === "in-life-renewable") {
        const tariffPrice = instance.transformedServices.find(
          (service: any) => service.serviceType === "voice"
        )?.rate;
        prices.push(tariffPrice);
      }
    });
    if (prices.length) {
      bulkSelectedMinPrice = Math.max(...prices);
    }
  }

  return (
    <MobileResignsTable
      bulkSelectedMinPrice={bulkSelectedMinPrice}
      productInstances={productInstances}
      filterState={filterState}
      selected={selected}
      mobileSearch={mobileSearch}
      resignProductSearch={resignProductSearch}
      bulkSelectVisible={bulkSelectVisible}
      configs={configs}
      selectedInstanceIds={selectedInstanceIds}
      doFilter={doFilter}
      toggleRow={toggleRow}
      isChecked={isChecked}
      getProductsByNetwork={(productInstance) =>
        sortByNetwork(products, productInstance.network)
      }
      allChecked={allChecked()}
      toggleAllRows={toggleAllRows}
      bulkSelectByCLI={(text) => bulkSelectByCLI(text, results)}
      showBulkSelect={showBulkSelect}
      setFilter={setFilter}
      retryRequestProductInstances={() => dispatch(fetchProductInstances({}))}
      retryRequestResignProduct={() => dispatch(fetchResignProductSearch())}
      doResignWithoutChanges={() => {
        hideBulkSelect();
        dispatch(
          setResignType({
            resignIds: selectedInstanceIds,
            resignType: RESIGN_WITHOUT_CHANGES,
          })
        );
        setSelected([]);
      }}
      setResignType={(
        productInstanceId: string,
        withChanges: boolean,
        shouldDeselectAll = false
      ) => {
        if (shouldDeselectAll) {
          deselectAll();
        }

        dispatch(
          setResignType({
            resignIds: [productInstanceId],
            resignType: withChanges
              ? RESIGN_WITH_CHANGES
              : RESIGN_WITHOUT_CHANGES,
          })
        );
      }}
      removeResign={(resignIds: string[], shouldHideBulkSelect = false) => {
        if (shouldHideBulkSelect) {
          hideBulkSelect();
        }
        dispatch(removeResign({ resignIds: resignIds }));
      }}
      setResignProduct={(
        resignIds: string[],
        id: string | false,
        shouldHideBulkSelect = false
      ) => {
        dispatch(setResignProduct({ resignIds, productId: id }));

        if (shouldHideBulkSelect) {
          hideBulkSelect();
        }
      }}
      products={products}
      unrestrictInLifeProducts={unrestrictInLifeProducts}
    />
  );
};
