import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReplayIcon from "@mui/icons-material/Replay";
import BlockIcon from "@mui/icons-material/Block";
import { BulkSelectPopover } from "../../../../../../shared/components/BulkSelectPopover";
import { getHostEnv } from "../../../../../../store/order/selectors/getHostEnv";
import { useCanAccessVfDirect } from "../../../../../../shared/hooks/useCanAccessVfDirect";

interface ResignsTableActionsProps {
  infoLabel: string;
  disabled: boolean;
  doResignWithoutChanges: () => void;
  doResignNone: () => void;
  bulkSelectByCLI: (value: string) => void;
}

export const ResignsTableActions = ({
  infoLabel,
  disabled,
  doResignWithoutChanges,
  doResignNone,
  bulkSelectByCLI,
}: ResignsTableActionsProps) => {
  const classes = useStyles();
  const hostEnv = useSelector(getHostEnv);
  const canAccessVfDirect = useCanAccessVfDirect();

  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.info}>{infoLabel}</Typography>
      </Box>
      <Box className={classes.actions}>
        {[
          {
            label:
              hostEnv === "platform" && !canAccessVfDirect
                ? "Resign"
                : "Resign Without Changes",
            Icon: ReplayIcon,
            show: !!doResignWithoutChanges,
            handleClick: doResignWithoutChanges,
          },
          {
            label: "Do Not Resign",
            Icon: BlockIcon,
            show: !!doResignNone,
            handleClick: doResignNone,
          },
        ]
          .filter(({ show }) => show)
          .map(({ label, Icon, handleClick }) => (
            <Button
              key={label}
              color="primary"
              variant="text"
              size="large"
              disabled={disabled}
              onClick={handleClick}
              startIcon={<Icon />}
            >
              {label}
            </Button>
          ))}
        <BulkSelectPopover bulkSelectByCLI={bulkSelectByCLI} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  info: {
    color: theme.palette.grey[300],
  },
  actions: {},
}));
