import { CarrierFilterEnum, DataFilterEnum } from "./enums";

export const carrierFilterOptions = [
  {
    id: CarrierFilterEnum.ALL,
    label: "All Products",
  },
  {
    id: CarrierFilterEnum.VODAFONE,
    label: "Vodafone",
  },
  {
    id: CarrierFilterEnum.O2,
    label: "O2",
  },
];

export const dataFilterOptions = [
  {
    id: DataFilterEnum.ALL,
    label: "All Products",
  },
  {
    id: DataFilterEnum.VOICE,
    label: "Mobile Voice",
  },
  {
    id: DataFilterEnum.DATA,
    label: "Mobile Broadband",
  },
];

export const MAX_QTY = 100;
export const QTY_THRESHOLD = 10;
