import { useEffect } from "react";

import { ReduxFetchType } from "../../../../store/_root/types";
import { StockLevelResponse } from "../types";

interface StockLevelHandlerProps {
  getStockLevel?: () => void;
  stockLevel?: ReduxFetchType<StockLevelResponse>;
  quantity?: number;
}

/**
 * A Simple custom useEffect for handling a stockLevel
 */
export const useStockLevelHandler = ({
  quantity,
  stockLevel,
  getStockLevel,
}: StockLevelHandlerProps) => {
  useEffect(() => {
    if (!stockLevel && !!quantity) {
      getStockLevel?.();
    }
  }, [stockLevel, quantity, getStockLevel]);
};
