import { createSlice } from "@reduxjs/toolkit";
import {
  addProduct,
  clearConfig,
  fetchProductSearch,
  orderProduct,
  removeProduct,
  setAlertPreferences,
} from "./actions";
import initialState, { blankConfig } from "./state";

const monitoringService = createSlice({
  name: "monitoringService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductSearch.pending, (state) => {
        state.productSearch.fetching = true;
      })
      .addCase(fetchProductSearch.rejected, (state) => {
        state.productSearch.fetching = false;
      })
      .addCase(fetchProductSearch.fulfilled, (state, action) => {
        state.productSearch.fetching = false;
        state.productSearch.response = action.payload;
      })
      .addCase(setAlertPreferences, (state, action) => {
        state.alertPreferences = action.payload as any;
      })
      .addCase(addProduct, (state, action) => {
        const { productId, oneOffPrice, recurringPrice } = action.payload;
        state.configs.push({
          ...blankConfig,
          productId,
          oneOffPrice,
          recurringPrice,
        });
      })
      .addCase(removeProduct, (state, action) => {
        state.configs = state.configs.filter(
          (_, index) => index !== action.payload
        );
      })
      .addCase(orderProduct.pending, (state, action) => {
        const { configIndex, isUpdate } = action.meta.arg;
        const config = state.configs[configIndex];
        if (config) {
          config.orderProduct.fetching = true;
          config.orderProduct.isUpdate = isUpdate;
        }
      })
      .addCase(orderProduct.rejected, (state, action) => {
        const { configIndex } = action.meta.arg;
        const config = state.configs[configIndex];
        if (config) config.orderProduct.fetching = false;
      })
      .addCase(orderProduct.fulfilled, (state, action) => {
        const { configIndex } = action.meta.arg;
        const config = state.configs[configIndex];
        if (config) {
          config.orderProduct.fetching = false;
          config.orderProduct.response = action.payload;
        }
      })
      .addCase(clearConfig, (state, action) => {
        return initialState;
      });
  },
});

export default monitoringService.reducer;
