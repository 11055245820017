import React from "react";
import { Box, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getNetworkIcon } from "../../utils";

export interface CarrierFieldProps {
  network: string;
}

export const CarrierField = ({ network }: CarrierFieldProps) => {
  const classes = useStyles();
  const avatar = getNetworkIcon(network);

  return (
    <Box className={classes.root}>
      <Box className={classes.avatar}>
        {avatar && <img src={avatar} width="32" alt="" />}
      </Box>
      <Typography variant={"body1"}>{network}</Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1),
  },
}));
