import React from "react";
import { Box, Button, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AscIcon from "@mui/icons-material/ExpandLess";
import DescIcon from "@mui/icons-material/ExpandMore";
import { VirtualTableHeaderProps } from "./types";
import { VirtualTableSortOrder } from "./enums";

export const VirtualTableHeader = ({
  header,
  activeSort,
  handleSort,
}: VirtualTableHeaderProps) => {
  const classes = useStyles();
  const { label, sortIndex, width, minWidth, disabled } = header;
  const { sortIndex: activeSortIndex, order } = activeSort;
  const handleClick = () => handleSort(sortIndex);

  let EndIcon;
  if (sortIndex === activeSortIndex) {
    if (order === VirtualTableSortOrder.ASC) {
      EndIcon = AscIcon;
    } else {
      EndIcon = DescIcon;
    }
  }

  return (
    <Box
      style={
        width
          ? { width, minWidth: minWidth || width }
          : { flexGrow: 1, minWidth }
      }
    >
      {disabled ? (
        <Typography className={classes.text}>{label}</Typography>
      ) : (
        <Button
          className={classes.button}
          endIcon={EndIcon && <EndIcon />}
          onClick={handleClick}
        >
          <Typography className={classes.text}>{label}</Typography>
        </Button>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: -theme.spacing(0.5),
  },
  text: {
    fontWeight: "bold",
  },
}));
