import React from "react";

import { ReduxWlrLocation, WlrLocationBroadbandProduct } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { setBroadbandProduct } from "../../../../../store/wlrBroadband/actions/configurations";
import {
  BroadbandProductSelect,
  BaseBroadbandProductSelectProps,
} from "../../components/WlrLocation/BroadbandProductSelect";

export interface BroadbandProductSelectContainerProps
  extends BaseBroadbandProductSelectProps {
  locationIndex: number;
}

export const BroadbandProductSelectContainer = ({
  locationIndex,
  ...rest
}: BroadbandProductSelectContainerProps) => {
  const dispatch = useDispatch();

  // Location
  const location = useSelector<any, ReduxWlrLocation>(
    (state) => state.wlrBroadband.locations[locationIndex]
  );

  const broadbandProducts: WlrLocationBroadbandProduct[] =
    location.broadbandSearch?.response?.products ?? [];

  const handleBroadbandProduct = (configId: number, broadbandId: string) =>
    dispatch(setBroadbandProduct(configId, broadbandId));

  return (
    <BroadbandProductSelect
      {...rest}
      broadbandProducts={broadbandProducts}
      onAddBroadbandChange={handleBroadbandProduct}
    />
  );
};
