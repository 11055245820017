import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { RESIGN_WITH_CHANGES } from "../../../../../store/mobile/constants";
import { RowActions } from "../../components/Resigns/RowActions";
import { SelectedChangesRow } from "../../components/Resigns/SelectedChangesRow";
import { NoChangesRow } from "../../components/Resigns/NoChangesRow";
import { StatusChip } from "@akj-dev/shared-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  InputAdornment,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import SearchIcon from "@mui/icons-material/Search";
import { ResignsTableActions } from "./ResignsTableActions";
import { Loader } from "../../../../../shared/components/Loader";
import { ResignProductSelector } from "./ResignProductSelector";
import { ReduxMobileProductType } from "../../../../../shared/types/general";
import { TransformedResult } from "../../../../../store/mobile/transformers";

interface ResignsTableProps {
  productInstances: Record<string, any>;
  resignProductSearch: Record<string, any>;
  mobileSearch: Record<string, any>;
  bulkSelectVisible: boolean;
  selected: number[];
  filterState: string;
  allChecked: boolean;
  configs: any[];
  bulkSelectedMinPrice: number;
  selectedInstanceIds: string[];
  getProductsByNetwork: (productInstance: TransformedResult) => any[];
  showBulkSelect: () => void;
  isChecked: (index: number) => boolean;
  toggleRow: (index: number) => void;
  toggleAllRows: () => void;
  setFilter: (event: any) => void;
  retryRequestProductInstances: () => void;
  retryRequestResignProduct: () => void;
  setResignProduct: (
    resignIds: string[],
    id: string | false,
    shouldHideBulkSelect?: boolean
  ) => void;
  doResignWithoutChanges: () => void;
  bulkSelectByCLI: (text: string) => void;
  removeResign: (resignIds: string[], shouldHideBulkSelect?: boolean) => void;
  doFilter: (item: TransformedResult) => boolean;
  setResignType: (
    productInstanceId: string,
    withChanges: boolean,
    shouldDeselectAll?: boolean
  ) => void;
  products: any[];
  unrestrictInLifeProducts: string;
}

export const MobileResignsTable = ({
  productInstances,
  resignProductSearch,
  mobileSearch,
  bulkSelectVisible,
  selected,
  filterState,
  allChecked,
  bulkSelectedMinPrice,
  configs,
  selectedInstanceIds,
  getProductsByNetwork,
  showBulkSelect,
  toggleAllRows,
  toggleRow,
  isChecked,
  setFilter,
  retryRequestProductInstances,
  retryRequestResignProduct,
  setResignProduct,
  doResignWithoutChanges,
  bulkSelectByCLI,
  removeResign,
  setResignType,
  doFilter,
  products,
  unrestrictInLifeProducts,
}: ResignsTableProps) => {
  const classes = useStyles();

  const results =
    productInstances?.response?.results?.filter(
      (r: any) => r.resign_status !== "not-renewable"
    ) || [];

  if (productInstances?.response?.status === "error")
    return (
      <StatusChip
        type="error"
        title="Error"
        message={productInstances?.response?.message}
        retry={retryRequestProductInstances}
      />
    );

  if (
    productInstances.fetching &&
    !productInstances?.response.results?.length
  ) {
    return <Loader />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.searchBox}>
          <TextField
            data-cy={"resignTableSearch"}
            className={classes.search}
            variant="outlined"
            name="filter"
            value={filterState}
            onChange={setFilter}
            placeholder={`Search ${results.length} Available Resigns`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <ResignsTableActions
          infoLabel={`${selected.length} Selected / ${results.length} Available`}
          disabled={selected.length < 2}
          doResignWithChanges={showBulkSelect}
          doResignWithoutChanges={doResignWithoutChanges}
          doResignNone={() => removeResign(selectedInstanceIds, true)}
          bulkSelectByCLI={bulkSelectByCLI}
        />
      </Box>
      {bulkSelectVisible && (
        <ResignProductSelector
          minPrice={
            unrestrictInLifeProducts !== "1" ? bulkSelectedMinPrice - 5 : 0
          }
          fetching={mobileSearch.fetching}
          products={products}
          setResignProduct={(id: string) =>
            setResignProduct(selectedInstanceIds, id, true)
          }
        />
      )}
      <Box className={classes.tableBox}>
        <Table
          size="small"
          data-cy="mobileResignsTable"
          className={classes.table}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 30 }}>
                <Checkbox onChange={toggleAllRows} checked={allChecked} />
              </TableCell>
              <TableCell>CLI</TableCell>
              <TableCell colSpan={2}>Product</TableCell>
              <TableCell>Bolt-ons / Selected Product</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell className="u-text-right" style={{ minWidth: 120 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((productInstance: TransformedResult, i: number) => {
              const config =
                configs.find((c: any) => c.resignId === productInstance.id) ||
                {};

              if (!doFilter(productInstance)) return null;

              // Find PI tariff price to pass to <ResignProductSelector>
              const tariffPrice = productInstance.transformedServices.find(
                (service) => service.serviceType === "voice"
              )?.rate;
              const renewable =
                productInstance.resign_status !== "not-renewable";
              if (
                config.resignType === RESIGN_WITH_CHANGES &&
                config.productId
              ) {
                return (
                  <SelectedChangesRow
                    key={productInstance.id}
                    isChecked={isChecked(i)}
                    toggleRow={() => toggleRow(i)}
                    productInstance={productInstance}
                    newProduct={mobileSearch?.response?.products.find(
                      (p: ReduxMobileProductType) => p.id === config.productId
                    )}
                    actions={
                      renewable && (
                        <RowActions
                          config={config}
                          resetProduct={() =>
                            setResignProduct([productInstance.id], false)
                          }
                        />
                      )
                    }
                  />
                );
              }
              return [
                <NoChangesRow
                  className={
                    config.resignType === RESIGN_WITH_CHANGES
                      ? classes.resignWithChangesInstance
                      : ""
                  }
                  key={productInstance.id}
                  isChecked={isChecked(i)}
                  toggleRow={() => toggleRow(i)}
                  productInstance={productInstance}
                  actions={
                    renewable && (
                      <RowActions
                        config={config}
                        setChanges={() =>
                          setResignType(productInstance.id, true, true)
                        }
                        setNoChanges={() =>
                          setResignType(productInstance.id, false)
                        }
                        resetType={() => removeResign([productInstance.id])}
                        disabled={selected.length > 1}
                      />
                    )
                  }
                />,
                config.resignType === RESIGN_WITH_CHANGES && (
                  <TableRow key={`${productInstance.id}_sel`}>
                    <TableCell
                      colSpan={8}
                      className={classes.resignWithChangesSelect}
                    >
                      <ResignProductSelector
                        minPrice={
                          // For VF Direct, in-life renewable contracts can't be resigned to a product any more than £5 less than the original
                          // TP46680 .... we should really be doing this kind of logic on the backend, but it was decided not to due to time.
                          productInstance.resign_status ===
                            "in-life-renewable" &&
                          unrestrictInLifeProducts !== "1" // change this to check for unrestrict
                            ? // @ts-ignore API model error
                              tariffPrice - 5
                            : 0
                        }
                        fetching={mobileSearch.fetching}
                        products={getProductsByNetwork(productInstance)}
                        setResignProduct={(id: string) =>
                          setResignProduct([productInstance.id], id)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ),
              ];
            })}
          </TableBody>
        </Table>
      </Box>
      {productInstances.fetching && (
        <Box className={classes.loadMore}>
          <Box mr={1}>
            <CircularProgress />
          </Box>
          <Typography variant="subtitle1">Loading more...</Typography>
        </Box>
      )}
      {!resignProductSearch.fetching &&
        resignProductSearch?.response.status === "error" && (
          <StatusChip
            type="error"
            title="Error finding resign product"
            message={resignProductSearch?.response.message}
            retry={retryRequestResignProduct}
          />
        )}
      {!resignProductSearch.fetching &&
        resignProductSearch?.response.status === "success" &&
        resignProductSearch?.response.products.length < 1 && (
          <StatusChip
            type="error"
            title="Error finding resign product"
            message="No products returned from Daisy Central."
            retry={retryRequestResignProduct}
          />
        )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableBox: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    display: "table",
    width: "100%",
    whiteSpace: "nowrap",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    minWidth: "400px",
    marginBottom: theme.spacing(1),
  },
  loadMore: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[300],
    display: "flex",
    alignItems: "center",
  },
  resignWithChangesSelect: {
    padding: `${theme.spacing(1, 0)} !important`,
  },
  resignWithChangesInstance: {
    "&> td": {
      borderBottom: "none",
    },
  },
}));
